import React from 'react';
import PropTypes from 'prop-types';
import { ContestActions } from 'scripts/common/logic/contest/contest';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { get } from 'lodash';
import { buildPath, getCurrentURL, getHostName } from 'scripts/common/utils/location_helper';
import { ContestRootRoutes } from 'scripts/apps/routes';

const mapDispatchToProps = dispatch => bindActionCreators({
  convertContestShortName: ContestActions.convertContestShortName,
}, dispatch);

@withRouter
@connect(null, mapDispatchToProps)
class ContestShortNameConverter extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    convertContestShortName: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const shortName = get(this.props, 'match.params.shortName', '')
      || getHostName().replace(/.contest.wejudge.net|/, '');
    this.props.convertContestShortName({
      shortName,
      onSuccess: (cid) => {
        if (cid > 0) {
          const { history } = this.props;
          history.push(buildPath(
            ContestRootRoutes.HOME,
            { contestId: cid }
          ));
        } else {
          this.redirectToMasterWebServer();
        }
      },
      onError: () => {
        this.redirectToMasterWebServer();
      },
    });
  }

  redirectToMasterWebServer = () => {
    const path = getCurrentURL();
    window.location.href = get(process.env, 'MASTER_DOMAIN') + path;
  };

  render() {
    return null;
  }
}

export default ContestShortNameConverter;
