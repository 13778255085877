import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { List, Avatar, Tooltip, message } from 'antd';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { AccountActions } from 'scripts/common/logic/account/action';
import { WeJudgeActions } from 'scripts/common/logic/wejudge/action';
import { showModal } from 'scripts/common/widgets/modal';
import AccountPicker from 'scripts/apps/account/widgets/account_picker';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { buildAvatarPath } from 'scripts/common/utils/location_helper';
import PermissionInfoModal from './dialog/permission_info';

const mapDispatchToProps = dispatch => bindActionCreators({
  getAccountEntities: AccountActions.getAccountEntities,
  getCourseEntity: CourseActions.getCourseEntity,
  addCourseAssistant: CourseActions.addCourseAssistant,
  viewWarnMessage: WeJudgeActions.viewWarnMessage,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  delCourseAssistant: CourseActions.delCourseAssistant,
  modifyCourse: CourseActions.modifyCourse,
}, dispatch);

const mapStateToProps = (state, props) => {
  const assistants = get(props.course, 'assistants', []);
  return {
    assistants,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class AssistantListView extends React.PureComponent {
  static propTypes = {
    addCourseAssistant: PropTypes.func,
    getAccountEntities: PropTypes.func,
    course: CoursePropTypes.isRequired,
    assistants: PropTypes.arrayOf(AccountPropTypes),
    modifyCourse: PropTypes.func,
    delCourseAssistant: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    viewWarnMessage: PropTypes.func,
    courseId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    assistants: [],
    modifyCourse: noop,
    delCourseAssistant: noop,
    getAccountEntities: noop,
    addCourseAssistant: noop,
    viewSuccessMessage: noop,
    viewWarnMessage: noop,
  };

  onPermissionCheck = (id) => {
    const modalBox = showModal(PermissionInfoModal, {
      width: 760,
      permissions: get(this.props.course, 'permissions', {}),
      assistantId: id,
      onOk: (modal, state) => {
        this.props.modifyCourse({
          courseId: this.props.courseId,
          permissions: state.permissions,
          onSuccess: () => {
            this.props.viewSuccessMessage({
              message: '修改助教权限成功',
            });
            modalBox.close();
          },
        });
        return false;
      },
    });
  };

  handleAddAssistant = assistant => new Promise((resolve, reject) => {
    const hide = message.loading('请求中，请稍等', 3000);
    this.props.addCourseAssistant({
      courseId: this.props.courseId,
      ids: [assistant.id],
      onSuccess: (e) => {
        hide();
        const  status = get(e[0], 'status', 0);
        if (status === 0) {
          this.props.viewSuccessMessage({
            message: '添加成功',
          });
        } else if (status === 1) {
          this.props.viewWarnMessage({
            message: '添加失败',
          });
        }
        resolve();
      },
      onError: () => {
        hide();
        reject();
      },
    });
  });

  handleDelClick = (id) => {
    const hide = message.loading('请求中，请稍等', 3000);
    this.props.delCourseAssistant({
      courseId: this.props.courseId,
      ids: [id],
      onSuccess: (e) => {
        hide();
        const  status = get(e[0], 'status', 0);
        if (status === 0) {
          this.props.viewSuccessMessage({
            message: '移除成功',
          });
        } else if (status === 1) {
          this.props.viewWarnMessage({
            message: '移除失败',
          });
        }
      },
      onError: () => {
        hide();
      },
    });
  };

  renderAssistatnsListItem = (item) => {
    return (
      <>
        <List.Item.Meta
          avatar={<Avatar
            icon={<UserOutlined />}
            size={32}
            src={buildAvatarPath(get(item, 'avator'))}
            style={{ marginRight: 8 }}
          />}
          title={<Tooltip title={get(item, 'nickname', '')}>
            {get(item, 'realname', '')}({get(AccountRoleEnum.enumValueOf(get(item, 'role', 0)), 'desc', '未知')})
          </Tooltip>}
          description={get(item, 'email', '')}
        />
      </>
    );
  };

  render() {
    return (<>
      <div className="assistants-list">
        <List
          header="课程助教"
          bordered
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={this.props.assistants.filter(item => !!item)}
          renderItem={(item, index) => {
            if (!item) return null;
            return <List.Item
              key={get(item, 'id', '') || `account_${index}`}
              actions={[
                <a onClick={() => this.onPermissionCheck(`${get(item, 'id', '')}`)}>权限</a>,
                <a onClick={() => this.handleDelClick(get(item, 'id', ''))}>移除</a>
              ]}
            >
              {this.renderAssistatnsListItem(item)}
            </List.Item>;
          }}
        />
      </div>
      <AccountPicker
        style={{ width: '100%', marginTop: 16 }}
        excludeAccountIds={this.props.assistants.map(_item => get(_item, 'id'))}
        handleSelected={this.handleAddAssistant}
      />
    </>);
  }
}

export default AssistantListView;
