import React from 'react';
import PropTypes from 'prop-types';
import {
  get, set, isEqual, isEmpty, noop, isArray 
} from 'lodash';
import updeep from 'updeep';
import { debounce } from 'scripts/common/utils/functional';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { indexToChar } from 'scripts/common/utils/unit';
import { AsgnProblemPropTypes } from 'scripts/common/prop-types/asgn';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import ObjectiveProblemView from 'scripts/apps/problem/widgets/objective_problem';
import DataKeys from 'scripts/common/constants/data_keys';
import { AsgnSolutionSelectors, AsgnSolutionActions } from 'scripts/common/logic/education/asgn';
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Button } from 'antd';

const AUTOMATIC_SUBMIT_TIME = 5000;

@connect((state) => {
  const solutions =    AsgnSolutionSelectors.getAsgnProblemSolutions(DataKeys.NORMAL_PROBLEMS)(state)();
  return {
    solutions,
  };
}, dispatch => bindActionCreators({
  submitNormalProblemSolutions: AsgnSolutionActions.submitNormalProblemSolutions,
  viewWarnMessage: WeJudgeActions.viewWarnMessage,
}, dispatch))
class AsgnNormalProblemsView extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    reportSubmitted: PropTypes.bool,
    solutions: PropTypes.shape({}),
    problemDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    submitNormalProblemSolutions: PropTypes.func,
    viewWarnMessage: PropTypes.func,
  };

  static defaultProps = {
    solutions: {},
    reportSubmitted: false,
    viewWarnMessage: noop,
    submitNormalProblemSolutions: noop,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(prevState.oldSolutions, nextProps.solutions)) {
      const newSolutions = {};
      Object.keys(nextProps.solutions).forEach((key) => {
        const item = nextProps.solutions[key];
        newSolutions[key] = {
          answer: get(item, 'records', null),
        };
      });
      return {
        oldSolutions: nextProps.solutions,
        solutions: JSON.parse(JSON.stringify(updeep(newSolutions, prevState.solutions))),
      };
    }
    return null;
  }

  state = {
    oldSolutions: {},
    solutions: {},
    submitting: false,
    submitMsg: '',
    submitAction: 'automatic',
    submitResult: [],
  };

  doSubmit = (action = 'automatic') => () => {
    if (this.state.submitting || this.props.reportSubmitted) return;
    const oldSolutions = this.props.solutions;
    const newSolutions = this.state.solutions;
    const answers = [];
    Object.keys(newSolutions).forEach((pid) => {
      const nt = newSolutions[pid];
      const ot = get(oldSolutions, pid, null);
      const na = get(nt, 'answer');
      const oa = get(ot, 'records');
      if (ot && isEqual(na, oa)) return;
      if (na === null || na === undefined || (isArray(na) && isEmpty(na))) return;
      answers.push({
        id: pid,
        answer: na,
      });
    });
    if (isEmpty(answers)) {
      this.setState({
        submitMsg: '',
        submitAction: action,
      });
      if (action !== 'automatic') {
        this.props.viewWarnMessage({
          message: '当前没有可以保存的项目',
        });
      }
      return;
    }
    this.setState({
      submitMsg: '',
      submitAction: action,
      submitting: true,
    }, () => {
      const { submitNormalProblemSolutions } = this.props;
      submitNormalProblemSolutions({
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        answers,
        onSuccess: (result) =>  {
          this.setState({
            submitMsg: action === 'automatic' ? '自动保存成功' : '保存成功',
            submitResult: result,
          });
        },
        onError: () =>  {
          this.setState({
            submitMsg: '保存失败',
            submitResult: {},
          });
        },
        onComplete: () => {
          this.setState({
            submitting: false,
          });
        },
      });
    });
  };

  doSubmitAutomatic = debounce(this.doSubmit(), AUTOMATIC_SUBMIT_TIME);

  handleUserAnswer = problemId => (answer) => {
    const solutions = { ...this.state.solutions };
    const t = get(solutions, problemId, {});
    t.answer = answer;
    set(solutions, problemId, t);
    this.setState({
      solutions,
    }, () => { this.doSubmitAutomatic(); });
  };

  renderProblem = (item, last = false) => {
    return (<React.Fragment key={item.id}>
      <ObjectiveProblemView
        isShowOk={false}
        problemMode={this.props.reportSubmitted ? 'view_answers' : 'solving'}
        onSelectChanged={this.handleUserAnswer(item.id)}
        solution={get(this.state.solutions, item.id, {})}
        problemItem={item}
        problemNumber={indexToChar(get(item, 'order', 0))}
      />
      {!last && <Divider />}
    </React.Fragment>);
  };

  renderSubmitMessage = () => {
    let s = 0;
    let e = 0;
    const { submitResult } = this.state;
    Object.keys(submitResult).forEach((key) => {
      const item = submitResult[key];
      if (item === 0) s++;
      else e++;
    });
    return <span>{this.state.submitMsg !== '保存失败' ? `${this.state.submitMsg}，成功${s}个，失败${e}个` : '保存失败，请稍后重试'}</span>;
  };

  render() {
    return (
      <div className="normal_problems_view">
        <div className="problems_area">
          {this.props.problemDatas.map((item, key) => this.renderProblem(
            item,
            key + 1 === this.props.problemDatas.length
          ))}
        </div>
        <div className="submit_btn">
          <div>
            <Button
              disabled={this.props.reportSubmitted}
              loading={this.state.submitting}
              onClick={this.doSubmit('manual')}
            >
              保存答案
            </Button>
            <span className="message_text">
              {this.state.loading && this.state.submitAction === 'automatic' && <span><LoadingOutlined /> 自动保存中...</span>}
              {this.state.submitMsg && this.renderSubmitMessage()}
            </span>
          </div>
          <Button
            type="primary"
          >
            确定答案
          </Button>
        </div>
      </div>
    );
  }
}

export default AsgnNormalProblemsView;
