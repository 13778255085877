import React from 'react';
import PropTypes from 'prop-types';
import { DeleteTwoTone } from '@ant-design/icons';
import {
  Button, Modal, Tag, Empty, Spin 
} from 'antd';
import 'styles/education/course/course_sider_list.scss';
import AnnouncementView from 'scripts/apps/education/course/widgets/announcement_view';
import { showModal } from 'scripts/common/widgets/modal';
import { bindActionCreators } from 'redux';
import { get, isEmpty, noop } from 'lodash';
import { CourseActions, CourseSelectors } from 'scripts/common/logic/education/course';
import { PaginationParams } from 'scripts/common/constants/global';
import { connect } from 'react-redux';
import { AnnouncementPropTypes } from 'scripts/common/prop-types/course';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { CourseAnnouncementLevelEnum } from 'scripts/common/enums/course';

const mapDispatchToProps = dispatch => bindActionCreators({
  getCourseAnnouncementsList: CourseActions.getCourseAnnouncementsList,
  deleteCourseAnnouncement: CourseActions.deleteCourseAnnouncement,
}, dispatch);

const mapStateToProps = (state) => {
  const announcements = CourseSelectors.getCourseAnnouncementsList(state);
  const pager = CourseSelectors.courseAnnouncementsPagerSelector(state);
  return {
    announcements,
    paginationParams: (!pager || isEmpty(pager)) ? PaginationParams : pager,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class CourseNoticeList extends React.PureComponent {
  static propTypes = {
    canManage: PropTypes.bool,
    courseId: PropTypes.number.isRequired,
    announcements: PropTypes.arrayOf(AnnouncementPropTypes),
    getCourseAnnouncement: PropTypes.func,
    getCourseAnnouncementsList: PropTypes.func,
    deleteCourseAnnouncement: PropTypes.func,
  };

  static defaultProps = {
    canManage: false,
    announcements: [],
    getCourseAnnouncement: noop,
    getCourseAnnouncementsList: noop,
    deleteCourseAnnouncement: noop,
  };

  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.props.getCourseAnnouncementsList({
      courseId: this.props.courseId,
      query: {
        limit: 9999,
        page: 1,
      },
      onComplete: () => {
        this.setState({
          isLoading: false,
        });
      },
    });
  }

  handleNewDialog = () => {
    showModal(AnnouncementView, {
      mode: 'new',
      courseId: this.props.courseId,
      listQuery: {
        limit: 9999,
        page: 1,
      },
    });
  };

  handleOpenDialog = item => () => {
    showModal(AnnouncementView, {
      mode: 'view',
      announcement: item,
      announcementId: item.id,
      courseId: this.props.courseId,
      hasManageCoursePermission: this.props.canManage,
    });
  };

  handleDelete = item => () => {
    Modal.confirm({
      title: '操作确认',
      okType: 'danger',
      content: '确定要删除这条公告吗？',
      onOk: () => {
        this.props.deleteCourseAnnouncement({
          courseId: this.props.courseId,
          announcementId: item.id,
          query: {
            limit: 9999,
            page: 1,
          },
        });
      },
    });
  };

  renderLevelTag = (item) => {
    if (get(item, 'level') === CourseAnnouncementLevelEnum.URGENT.value) {
      return <Tag color="red">紧急</Tag>;
    } if (get(item, 'level') === CourseAnnouncementLevelEnum.SECONDARY.value) {
      return <Tag color="blue">重要</Tag>;
    }
    return null;
  };

  renderNoticeList = () => {
    if (this.state.isLoading) return <Spin tip="加载中" />;
    if (isEmpty(this.props.announcements)) {
      return <Empty description="暂无公告信息" />;
    }
    return this.props.announcements.map((item) => {
      if (!item) return null;
      return (
        <div
          key={get(item, 'id')}
          className="notice_list_item" 
          onClick={this.handleOpenDialog(item)}
        >
          <div className="item_title">
            <span className="title">
              {get(item, 'title')}
            </span>
            <div className="item_tag">
              {this.renderLevelTag(item)}
            </div>
          </div>
          <div className="item_content">
            <RichtextContext
              textMode
              truncateLength={30}
              content={get(item, 'content')}
            />
          </div>
          <div className="item_footer">
            <span className="footer_time">
              {formatTimeFromNow(get(item, 'create_time'))}
            </span>
            <span className="footer_manage">
              {this.props.canManage && <>
                <DeleteTwoTone twoToneColor="#eb2f96" onClick={this.handleDelete(item)} />
              </>}
            </span>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div className="course_notice_list">
        {this.props.canManage && <div className="notice_list_button">
          <Button type="primary" onClick={this.handleNewDialog}>发布新公告</Button>
        </div>}
        {this.renderNoticeList()}
      </div>
    );
  }
}

export default CourseNoticeList;
