import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  set, get, truncate, isArray, isNumber, isBoolean, isEqual, noop 
} from 'lodash';

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  QuestionOutlined,
  UndoOutlined
} from '@ant-design/icons';

import {
  Form,
  Row,
  Col,
  Table,
  Tag,
  Divider,
  Button,
  Drawer,
  InputNumber,
  Switch,
  Spin,
  Badge,
  Popover,
  Upload,
} from 'antd';
import { AsgnProblemPropTypes, AsgnPropTypes, AsgnReportPropTypes } from 'scripts/common/prop-types/asgn';
import { formatTime, timeUsedFormatter } from 'scripts/common/utils/time_formatter';
import { indexToChar } from 'scripts/common/utils/unit';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import { buildResourcePath, openLink } from 'scripts/common/utils/location_helper';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { debounce } from 'scripts/common/utils/functional';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';
import RichtextContext from 'scripts/common/widgets/richtext_context';

import JudgeDrawerView from '../judge_status/judge_drawer_view';

class AsgnReportWidgets extends React.PureComponent {
  static propTypes = {
    asgn: AsgnPropTypes.isRequired,
    report: AsgnReportPropTypes.isRequired,
    teacherMode: PropTypes.bool,
    normalSolutions: PropTypes.shape({}).isRequired,
    codeSolutions: PropTypes.shape({}).isRequired,
    codeProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    objectiveProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    onCheckupClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onDelClick: PropTypes.func,
    onResubmitClick: PropTypes.func,
    onPrevClick: PropTypes.func,
    reportIdIndex: PropTypes.number,
    spinning: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    teacherMode: false,
    onCheckupClick: noop,
    onNextClick: noop,
    onDelClick: noop,
    onResubmitClick: noop,
    onPrevClick: noop,
    reportIdIndex: 0,
  };

  state = {
    showCheckupDrawer: false,
    checkup: {},
    report: null,
    drawerVisible: false,
    problemId: null,
    fileList: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.teacherMode && !isEqual(nextProps.report, prevState.report)) {
      const { report } = nextProps;
      let fileList = [];
      if (get(nextProps.report, 'attachments', []) !== 0) {
        fileList = get(nextProps.report, 'attachments', []).map((item, index) => {
          return {
            uid: index.toString(),
            name: item.name + item.suffix,
            status: 'done',
            url: item.storage_path,
          };
        });
      }
      return {
        ...prevState,
        checkup: {
          finally_score: get(report, 'teacher_check', false) ? get(report, 'finally_score', 0) : get(report, 'judge_score', 0),
          teacher_remark: get(report, 'teacher_remark', ''),
          excellent: get(report, 'excellent', false),
          public_answer: get(report, 'public_answer', false),
        },
        report: nextProps.report,
        fileList,
      };
    }
    return null;
  }

  CODE_SOL_COLUMNS = [
    {
      dataIndex: 'number',
      title: '题号',
      width: 60,
      align: 'center',
    },
    {
      dataIndex: 'problem',
      title: '题目名称',
    },
    {
      dataIndex: 'solution',
      title: '通过/提交/判罚',
      width: 140,
      align: 'center',
    },
    {
      dataIndex: 'timeuse',
      title: '最少时间',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'memusd',
      title: '最少内存',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'length',
      title: '最短代码',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'realTime',
      title: '真实用时',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'score',
      title: '得分',
      width: 120,
      align: 'center',
    }
  ];

  NORMAL_SOLUTION_COLUMN = [
    {
      dataIndex: 'number',
      title: '题号',
      width: 60,
      align: 'center',
    },
    {
      dataIndex: 'problem',
      title: '题目名称',
    },
    {
      dataIndex: 'answer',
      title: '学生回答',
      width: 300,
    },
    {
      dataIndex: 'correct',
      title: '正确？',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'standard',
      title: '标准答案',
      width: 120,
      align: 'center',
    },
    {
      dataIndex: 'score',
      title: '得分',
      width: 120,
      align: 'center',
    }
  ];

  RESULT_COLUMNS = [
    {
      dataIndex: 'submit_time',
      title: '报告提交时间',
    },
    {
      dataIndex: 'accepted',
      title: '代码通过数量',
      align: 'center',
    },
    {
      dataIndex: 'submission',
      title: '提交代码数量',
      align: 'center',
    },
    {
      dataIndex: 'solved_counter',
      title: '解决题目总数',
      align: 'center',
    },
    {
      dataIndex: 'judge_score',
      title: '系统评分',
      align: 'center',
    },
    {
      dataIndex: 'finally_score',
      title: '最终得分',
      align: 'center',
    },
    {
      dataIndex: 'teacher',
      title: '批阅老师',
      align: 'center',
    },
    {
      dataIndex: 'remark',
      title: '备注',
      align: 'center',
    }
  ];

  changeCheckupDrawerVisible = (visible = false) => () => {
    this.setState({
      showCheckupDrawer: visible,
    });
  };

  onCheckupClick = (value) => {
    this.props.onCheckupClick(value);
    this.setState({
      ...this.state,
      showCheckupDrawer: false,
    });
  };

  handleInputChange = (key, value) => {
    this.setState(set({ checkup: { ...this.state.checkup } }, `checkup.${key}`, value));
  };

  handleEditorChange = key => debounce((value) => {
    this.setState(set({ checkup: { ...this.state.checkup } }, `checkup.${key}`, value));
  }, INPUT_CHANGE_DEBOUNDCE_TIME);

  handleMDownload = () => {
    const url = `${process.env.API_HOST}/education/courses/${this.props.asgn.course.id}/asgns/${this.props.asgn.id}/reports/${this.props.report.id}/download`;
    return openLink(url);
  };

  renderAnswer = (problem, answer) => {
    const ptype = ProblemTypeEnum.enumValueOf(get(problem, 'problem_type', 0));
    switch (ptype) {
      case ProblemTypeEnum.SINGLE:
        if (isNumber(answer)) return indexToChar(answer);
        return '---';
      case ProblemTypeEnum.MULTIPLE:
        if (isArray(answer)) {
          const t = [...answer];
          t.sort((a, b) => a > b);
          return t.map(item => indexToChar(item)).join('、');
        }
        return '---';
      case ProblemTypeEnum.TRUE_OR_FALSE:
        if (isBoolean(answer)) return answer ? '正确' : '错误';
        return '---';
      default:
        return '---';
    }
  };

  renderResultTable = () => {
    const { report } = this.props;
    return [{
      key: report.id,
      submit_time: get(report, 'submit_time', 0) ? formatTime(get(report, 'submit_time', 0), 'LONG_DATETIME') : null,
      accepted: get(report, 'accepted', 0),
      submission: get(report, 'submission', 0),
      solved_counter: get(report, 'solved_counter', 0) || '---',
      finally_score: get(report, 'teacher_check', false) ? get(report, 'finally_score', 0) : '---',
      judge_score: get(report, 'judge_score', 0) || '---',
      teacher: get(report, 'teacher.realname', '') || '---',
      remark: <span>
        {get(report, 'excellent', false) && <Tag color="blue">公开作业</Tag>}
        {get(report, 'public_answer', false) && <Tag color="green">优秀作业</Tag>}
      </span>,
    }];
  };

  renderCodeSolutionNumber = (value, key) => {
    if (key === 'best_time') {
      if (value === -1 || value === '---') {
        return '---';
      } if (value === 0) {
        return '< 1';
      }
      return value;
    } 
    if (value === '---' || value === -1 || value === 0) {
      return '---';
    }
    return value;
  }

  renderCodeSolutions = () => {
    const { codeSolutions, codeProblemsDatas } = this.props;
    return codeProblemsDatas.map((problem) => {
      const solution = get(codeSolutions, problem.id, {});
      return {
        key: problem.id,
        number: indexToChar(get(problem, 'order', 0)),
        problem: truncate(get(problem, 'problem.title', ''), { length: 30 }),
        solution: `${get(solution, 'accepted', '---')} / ${get(solution, 'submission', '---')} / ${get(solution, 'penalty', '---')}`,
        timeuse: `${this.renderCodeSolutionNumber(get(solution, 'best_time', '---'), 'best_time')} MS`,
        memusd: `${this.renderCodeSolutionNumber(get(solution, 'best_memory', '---'), 'best_memory')} KB`,
        length: `${this.renderCodeSolutionNumber(get(solution, 'best_code_size', '---'), 'best_code_size')} Bytes`,
        realTime: `${timeUsedFormatter(get(solution, 'used_time_real', 0))}`,
        score: get(solution, 'score', 0),
        problemId: get(problem, 'problem.id', ''),

      };
    });
  };

  renderCorrectAnswer =(problem) => {
    if (problem.problem_type === 4) {
      return String.fromCharCode(parseInt(problem.options.correct_answer, 10) + 64);
    } if (problem.problem_type === 6) {
      return problem.options.correct_answer === 0 ? '错误' : '正确';
    }
    let answer = '';
    if (problem.options.correct_answer) {
      problem.options.correct_answer.forEach((item) => {
        answer += String.fromCharCode(parseInt(item, 10) + 64);
        answer += ' ';
      });
    }
    return answer;
  };

  renderNormalSolutions = () => {
    const { normalSolutions, objectiveProblemsDatas } = this.props;
    return objectiveProblemsDatas.map((problem) => {
      const solution = get(normalSolutions, problem.id, {});
      const correct = (get(solution, 'correct', 0)  ? <CheckOutlined style={{ color: '#52c41a' }} /> : <CloseOutlined style={{ color: '#f5222d' }} />);
      return {
        key: problem.id,
        number: indexToChar(get(problem, 'order', 0)),
        problem: truncate(get(problem, 'problem.title', ''), { length: 30 }),
        answer: this.renderAnswer(get(problem, 'problem', {}), get(solution, 'records', {})),
        correct: get(solution, 'correct', 0)  === null ? <QuestionOutlined style={{ color: '#faad14' }} /> : correct,
        standard: this.renderCorrectAnswer(problem.problem),
        score: get(solution, 'score') || '---',
      };
    });
  };

  renderPopConfirmContent = () => {
    const { fileList } = this.state;
    const props = {
      onDownload: (file) => {
        const vpath = file.url;
        const url = buildResourcePath(vpath, true);
        return openLink(url);
      },
      onPreview: (file) => {
        const vpath = file.url;
        const url = buildResourcePath(vpath, true);
        return openLink(url);
      },
      fileList,
    };
    return (
      <div style={{ height: '200px', width: '300px' }}>
        <Upload {...props}>
          <Button onClick={this.handleMDownload}>
            一键下载
          </Button>
        </Upload>
      </div>
    );
  }

  renderBadgeCount = () => {
    return this.state.fileList.length;
  };

  render() {
    const { asgn, report } = this.props;

    let nextClickType = false;
    let prevClickType = false;
    if (this.props.reportIdIndex === 2) {
      prevClickType = true;
      nextClickType = true;
    } else if (this.props.reportIdIndex === 0) {
      prevClickType = true;
    } else if (this.props.reportIdIndex === 1) {
      nextClickType = true;
    }
    return (
      <div className="student_report_view">

        <div className="report_body">
          <Spin spinning={this.props.spinning} size="large" tip="实验报告生成中">
            <div className="report_title">
              <h1>{get(asgn, 'course.school.name', '')}《{get(asgn, 'course.name')}》课程实验报告</h1>
            </div>
            <Row gutter={8} className="report_base_info">
              <Col span={6}>
                <strong>实验名称：</strong><span className="report_asgn_name">&nbsp;{get(asgn, 'title')}&nbsp;</span>
              </Col>
              <Col className="middle-align" span={6}>
                <strong>学号：</strong><span className="report_asgn_name">&nbsp;{get(report, 'student_info.code')}&nbsp;</span>
              </Col>
              <Col className="middle-align" span={6}>
                <strong>姓名：</strong><span className="report_asgn_name">&nbsp;{get(report, 'student_info.realname')}&nbsp;</span>
              </Col>
              <Col className="right-align" span={6}>
                <strong>创建时间：</strong><span className="report_asgn_name">&nbsp;{formatTime(get(asgn, 'create_time', 0), 'LONG_DATETIME')}&nbsp;</span>
              </Col>
            </Row>
            <div className="report_text_info">
              <div className="text_info_title">实验感想</div>
              <RichtextContext>{get(report, 'impressions', '') || '无' }</RichtextContext>
            </div>
            {get(report, 'teacher_check', false) && <div className="report_text_info">
              <div className="text_info_title">老师评语</div>
              <RichtextContext>{get(report, 'teacher_remark', '') || '无' }</RichtextContext>
            </div>}
            <Table
              className="result_table"
              onRow={(record) => {
                return {
                  onClick: () => {
                    this.setState({
                      ...this.state,
                      problemId: record.key,
                      drawerVisible: true,
                    });
                  },
                // 点击行调用抽屉
                };
              }}
              size="middle"
              title={() => <strong>代码题完成情况</strong>}
              bordered
              columns={this.CODE_SOL_COLUMNS}
              dataSource={this.renderCodeSolutions()}
              pagination={false}
            />
            <Table
              className="result_table"
              size="middle"
              title={() => <strong>非代码题完成情况</strong>}
              bordered
              columns={this.NORMAL_SOLUTION_COLUMN}
              dataSource={this.renderNormalSolutions()}
              pagination={false}
            />
            <Table
              className="result_table"
              size="middle"
              bordered
              columns={this.RESULT_COLUMNS}
              dataSource={this.renderResultTable()}
              pagination={false}
            />
          </Spin>
        </div>
        <Drawer
          title="评测历史列表"
          placement="right"
          closable={false}
          width={600}
          courseId={this.props.asgn.course.id}
          asgnId={this.props.asgn.id}
          destroyOnClose
          onClose={() => this.setState({
            ...this.state,
            problemId: null,
            drawerVisible: false,
          })}
          visible={this.state.drawerVisible}
        >
          <JudgeDrawerView
            authorId={get(this.props, 'report.author.id', '')}
            problemId={this.state.problemId}
            courseId={get(this.props, 'asgn.course.id', '')}
            asgnId={this.props.asgn.id}
          />
        </Drawer>

        {this.props.teacherMode && <div className={classnames('report_checkup', { })}>
          <div className="control_btns">
            <div className="left_btns">
              <Button type="primary" onClick={this.changeCheckupDrawerVisible(true)}><EditOutlined /> 批改作业</Button>
              <Button
                disabled={nextClickType}
                onClick={() => this.props.onNextClick(this.props.report.id)}
              >
                <ArrowDownOutlined /> 下一个
              </Button>
              <Button
                disabled={prevClickType}
                onClick={() => this.props.onPrevClick(this.props.report.id)}
              >
                <ArrowUpOutlined /> 上一个
              </Button>
            </div>
            <div className="right_btns">
              <Popover
                placement="top"
                title="查看附件"
                trigger="click"
                content={this.renderPopConfirmContent()}
              >
                <Badge
                  count={this.renderBadgeCount()}
                  style={{ backgroundColor: '#52c41a' }}
                >
                  <Button><DownloadOutlined />查看附件</Button>
                </Badge>
              </Popover>
              <Button
                onClick={() => this.props.onResubmitClick(this.props.report.id)}
              >
                <UndoOutlined /> 重置作业状态
              </Button>
              <Button type="danger" onClick={() => this.props.onDelClick(this.props.report.id)}><DeleteOutlined /> 删除实验报告</Button>
            </div>
          </div>
          {this.state.showCheckupDrawer && <Drawer
            title="批改作业"
            placement="bottom"
            height="550"
            maskClosable={false}
            onClose={this.changeCheckupDrawerVisible(false)}
            visible={this.state.showCheckupDrawer}
          >
            <Form.Item>
              <Row gutter={8}>
                <Col span={8}>
                  最终得分：
                  <InputNumber
                    value={get(this.state.checkup, 'finally_score', 0)}
                    min={0}
                    max={get(asgn, 'full_score', 0)}
                    onChange={(value) => { this.handleInputChange('finally_score', value); }}
                  />
                </Col>
                <Col span={8}>
                  <Switch
                    checked={get(this.state.checkup, 'excellent', 0)}
                    onChange={(checked) => { this.handleInputChange('excellent', checked); }}
                  /> 优秀作业
                </Col>
                <Col span={8}>
                  <Switch
                    checked={get(this.state.checkup, 'public_answer', 0)}
                    onChange={(checked) => { this.handleInputChange('public_answer', checked); }}
                  /> 公开答案
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <MarkdownEditor
                height="20em"
                defaultValue={this.state.checkup.teacher_remark}
                onChange={this.handleEditorChange('teacher_remark')}
                placeholder="请输入评语，可以为空"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={() => this.onCheckupClick(this.state)}>保存批改</Button>
            </Form.Item>
          </Drawer>}
        </div>}
      </div>
    );
  }
}

export default AsgnReportWidgets;
