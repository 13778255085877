import React from 'react';
import 'styles/education/course/course_sider_list.scss';

import PropTypes from 'prop-types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Empty, Tag, Button, Modal, Pagination, Spin 
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NoteSelectors, NoteActions } from 'scripts/common/logic/education/note';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import { get, noop } from 'lodash';
import { showModal } from 'scripts/common/widgets/modal';
import CourseNoteDialog from './course_note_dialog';

const mapDispatchToProps = dispatch => bindActionCreators({
  getNoteList: NoteActions.getNoteList,
  delNote: NoteActions.delNote,
  getNote: NoteActions.getNote,
}, dispatch);

const mapStateToProps = (state, props) => {
  const noteList = NoteSelectors.getNotesList(props.courseId)(state);
  return {
    noteList,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class CourseNoteList extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    getNote: PropTypes.func,
    delNote: PropTypes.func,
    getNoteList: PropTypes.func,
    noteList: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    getNote: noop,
    delNote: noop,
    getNoteList: noop,
    noteList: [],
  };

  state = {
    page: 1,
    isLoading: true,
  };

  componentDidMount() {
    this.getNotesList(this.state.page);
  }

  getNotesList = (page) => {
    this.setState({
      isLoading: true,
    }, () => {
      this.props.getNoteList({
        params: {
          page,
          limit: 10,
          courseId: this.props.courseId,
        },
        onComplete: () => {
          this.setState({
            isLoading: false,
          });
        },
      });
    });
  };

  showNote = (item, edit = false) => () => {
    showModal(CourseNoteDialog, {
      edit,
      note: item,
      courseId: this.props.courseId,
      onNoteEdited: () => {
        this.getNotesList(this.state.page);
      },
    });
  };

  handleDelNote = (item) => {
    Modal.confirm({
      okType: 'danger',
      title: '操作警告',
      content: '您确认要删除该笔记？此操作不能撤销！',
      onOk: () => {
        this.props.delNote({
          noteId: item.id,
          onSuccess: () => {
            this.getNotesList(this.state.page);
          },
        });
      },
    });
  };

  render() {
    const { noteList = [] } = this.props;
    return (
      <div className="course_note_list">
        <div className="note_list_button">
          <Button type="primary" onClick={this.showNote(null, true)}>新建笔记</Button>
        </div>
        <Spin spinning={this.state.isLoading}>
          {noteList.length ? <>
            {noteList.map((item) => {
              return (
                <div className="note_list_item" key={get(item, 'id')}>
                  <div className="item_content">
                    <div className="title">
                      <a onClick={this.showNote(item, false)}>
                        {item.title}
                      </a>
                    </div>
                    <div className="extra">
                      <div>
                        #{item.id}, {formatTimeFromNow(item.update_time)}
                      </div>
                      <div className="action">
                        <EditOutlined onClick={this.showNote(item, true)} />
                        <DeleteOutlined onClick={() => this.handleDelNote(item)} />
                      </div>
                    </div>
                  </div>
                  <div className="item_footer" />
                </div>
              );
            })}
            <div className="pagination">
              <Pagination size="small" />
            </div>
          </> : <Empty description="暂无笔记" />}
        </Spin>
      </div>
    );
  }
}

export default CourseNoteList;
