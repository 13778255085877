import React from 'react';
import PropTypes from 'prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import classnames from 'classnames';

export const BSGroup = (props) => {
  return <div className="settings-group">
    <div className="group-meta">
      <Typography.Title level={4}>{props.title}</Typography.Title>
      <Typography.Paragraph type="secondary">{props.desc}</Typography.Paragraph>
    </div>
    <div className="group-content">
      {props.children}
    </div>
  </div>;
};

BSGroup.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
};

export const BSListItem = (props) => {
  return (
    <div className="list-item">
      <div className="title">{props.title} {props.tip && <Tooltip title={props.tip}><QuestionCircleOutlined /></Tooltip>}</div>
      <div className={classnames('content', { 'align-right': props.align === 'right' })}>
        {props.children}
      </div>
    </div>
  );
};

BSListItem.propTypes = {
  title: PropTypes.string.isRequired,
  tip: PropTypes.string,
  align: PropTypes.string,
};

BSListItem.defaultProps = {
  align: 'left',
  tip: '',
};
