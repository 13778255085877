import 'styles/problem/widgets/problem_edit.scss';

import React from 'react';
import {
  Form, Input, Divider, Radio, Rate, Tooltip
} from 'antd';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import PropTypes from 'prop-types';
import {
  noop, set, isEqual, get
} from 'lodash';
import { BulbFilled } from '@ant-design/icons';
import { ProblemDifficultyEnum } from 'scripts/common/enums/problem';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { debounce } from 'scripts/common/utils/functional';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

const RadioGroup = Radio.Group;

class TofProblemEditView extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes,
    onSave: PropTypes.func,
    onChange: PropTypes.func,
    mode: PropTypes.string,

  };

  static defaultProps = {
    mode: 'edit',
    problem: {
      title: '',
      description: '',
      explain: '',
      options: {
        correct_answer: true,
      },
      hint: '',
    },
    onSave: noop,
    onChange: noop,
  };

  state= {
    problem: {
      title: this.props.problem.title,
      description: this.props.problem.description,
      explain: this.props.problem.explain,
      options: {
        correct_answer: true,
      },
      hint: this.props.problem.hint,
    },
  };

  componentDidUpdate(prevProp, prevState) {
    if (!isEqual(prevState.problem, this.state.problem)) {
      this.props.onChange(this.state.problem);
    }
  }

  handleRadioChange = ({ target }) => {
    let correct = false;
    if (target.value === 0) {
      correct = true;
    }
    this.setState(set({ problem: { ...this.state.problem } }, 'problem.options.correct_answer', correct));
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState(set({ problem: { ...this.state.problem } }, `problem.${keyName}`, target.value));
  };

  handleEditorChange = keyName => debounce((value) => {
    this.setState(set({
      problem: { ...this.state.problem },
    }, `problem.${keyName}`, value));
  }, INPUT_CHANGE_DEBOUNDCE_TIME);

  render() {
    const { problem } = this.state;
    return (<>
      <Form className="tof_problem_editor">
        <div className="left_sider">
          <Form.Item>
            <Input
              key="title"
              value={problem.title}
              onChange={this.handleInputChange('title')}
              maxLength={140}
              placeholder="题目名称"
            />
          </Form.Item>
          <Form.Item>
            <Divider orientation="left">
              <span className="item_title">题目描述</span>
            </Divider>
            <MarkdownEditor
              height="15em"
              defaultValue={get(problem, 'description', '')}
              onChange={this.handleEditorChange('description')}
              placeholder="请输入题目描述，可以为空"
            />
          </Form.Item>
          <Form.Item>
            <Divider orientation="left">
              <span className="item_title">答案解析</span>
            </Divider>
            <MarkdownEditor
              height="10em"
              defaultValue={get(problem, 'explain', '')}
              onChange={this.handleEditorChange('explain')}
              placeholder="请输入题目答案解析，可以为空"
            />
          </Form.Item>
        </div>
        <div className="right_sider">
          <Form.Item style={{ textAlign: 'center' }}>
            <Rate
              character={({ index }) => <Tooltip
                title={get(ProblemDifficultyEnum.enumValueOf(index + 1), 'desc')}
              >
                <BulbFilled />
              </Tooltip>}
              value={problem.difficulty}
              onChange={(value) => { this.handleInputChange('difficulty')({ target: { value } }); }}
            />
          </Form.Item>
          <Form.Item>
            <Divider orientation="left">
              <span className="item_title">答案选项</span>
            </Divider>
            <RadioGroup
              value={this.state.problem.options.correct_answer === true ? 0 : 1}
              onChange={this.handleRadioChange}
              style={{ width: '100%' }}
            >
              <Form.Item>
                <Radio
                  value={0}
                >
                  正确
                </Radio>
              </Form.Item>
              <Form.Item>
                <Radio
                  value={1}
                >
                  错误
                </Radio>
              </Form.Item>
            </RadioGroup>
          </Form.Item>
        </div>
      </Form>
    </>);
  }
}
export default TofProblemEditView;
