import 'styles/education/course/contest_statistics.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Table } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';

import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { CourseActions, CourseSelectors } from 'scripts/common/logic/education/course';

const LOADING_STATUS_KEY = 'course_contest_statistics';

const mapDispatchToProps = dispatch => bindActionCreators({
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getCourseStatisticContestRank: CourseActions.getCourseStatisticContestRank,
}, dispatch);

const mapStateToProps = (state, props) => {
  const rankList = CourseSelectors.courseStatisticContestRank(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const contestId = parseInt(get(props, 'match.params.contestId', 0), 10);
  return {
    isLoading,
    contestId,
    rankList,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class CourseContestStatisticsView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getCourseStatisticContestRank({
      courseId: nextProps.courseId,
      contestId: nextProps.contestId,
      onError: () => {
        window.history.go(-1);
      },
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    isLoading: PropTypes.bool,
    courseId: PropTypes.number.isRequired,
    contestId: PropTypes.number,
    rankList: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    isLoading: false,
    rankList: [],
    contestId: 0,
  };

  state = {
    fetched: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.fetched) {
      CourseContestStatisticsView.fetchList(nextProps);
      return {
        fetched: true,
      };
    }
    return null;
  }

  renderStudentList = () => {
    const dataSource = this.props.rankList.map((item) => {
      return {
        code: item.code,
        realname: item.realname,
        solved: get(item, 'solved', 0),
        rank: get(item, 'joined', false) ? get(item, 'ranking') : '未参与',
      };
    });

    const studentColumns = [{
      title: '学号',
      dataIndex: 'code',
    }, {
      title: '姓名',
      dataIndex: 'realname',
    }, {
      title: '通过题数',
      dataIndex: 'solved',
    }, {
      title: '排行',
      dataIndex: 'rank',
    }];
    return <Table
      pagination={false}
      dataSource={dataSource}
      columns={studentColumns}
      bordered
    />;
  };

  render() {
    return (
      <div className="course-contest-statistics">
        {this.renderStudentList()}
      </div>
    );
  }
}

export default CourseContestStatisticsView;
