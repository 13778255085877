import 'styles/contest/manager/contest_settings.scss';

import React from 'react';
import moment from 'moment';
import {
  isEqual, get, set, indexOf 
} from 'lodash';
import PropTypes from 'prop-types';
import { TrophyOutlined } from '@ant-design/icons';
import {
  Checkbox,
  Button,
  List,
  Select,
  Input,
  DatePicker,
  Switch,
  InputNumber,
  Tabs,
  Table,
  Alert,
  message
} from 'antd';
import { formatTime } from 'scripts/common/utils/time_formatter';
import JudgeLanguageCheckBox from 'scripts/common/widgets/language_checkbox';
import { ContestProblemPropTypes, ContestPropTypes } from 'scripts/common/prop-types/contest';
import { withRouter, Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { bindActionCreators } from 'redux';
import { ContestActions } from 'scripts/common/logic/contest/contest';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import ContestManagePermissionCheck from 'scripts/apps/contest/manage_permission';
import { ContestTypeEnum, RegisterTypeEnum, ScheduleTypeEnum } from 'scripts/common/enums/contest';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { JudgeFlagEnum } from 'scripts/common/enums/judge';
import { ContestProblemSelectors } from 'scripts/common/logic/contest/problem';
import { ContestRankListActions } from 'scripts/common/logic/contest/actions';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import AccountPicker from 'scripts/apps/account/widgets/account_picker';
import  { BSListItem, BSGroup } from './widgets/base_settings_components';
import AccountFields from './widgets/account_fields/index';

const { Option } = Select;
const { TabPane } = Tabs;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const mapDispatchToProps = dispatch => bindActionCreators({
  editContest: ContestActions.editContest,
  delRankCache: ContestRankListActions.delRankCache,
}, dispatch);

const mapStateToProps = (state) => {
  return {
    problemDatas: ContestProblemSelectors.problemListData(state),
  };
};

@ContestLoginRequire
@ContestManagePermissionCheck()
@withRouter
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class ContestManageView extends React.Component {
  static propTypes = {
    contest: ContestPropTypes,
    editContest: PropTypes.func.isRequired,
    problemDatas: PropTypes.arrayOf(ContestProblemPropTypes),
    delRankCache: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contest: {},
    problemDatas: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'contest'), get(prevState, 'contestRaw'))) {
      return {
        contest: get(nextProps, 'contest'),
        contestRaw: get(nextProps, 'contest'),
      };
    }
    return null;
  }

  state = {
    contest: this.props.contest,
    contestRaw: null,
    changed: false,
    userWhiteListEditor: 'list',
    enrollWhiteListText: '',
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  unmounted = false;

  CC_PB_COLUMNS = [{
    dataIndex: 'order',
    title: '题号',
  }, {
    dataIndex: 'title',
    title: '标题',
  }, {
    dataIndex: 'options',
    title: '启用查重',
  }];

  AWARDS_COLUMNS = [{
    dataIndex: 'name',
    title: '奖项',
    width: '50%',
  }, {
    dataIndex: 'total',
    title: '人数',
    width: '50%',
  }];

  AWARDS_ITEMS = [{
    key: 'gold',
    name: '金奖',
    default: '10%',
  }, {
    key: 'silver',
    name: '银奖',
    default: '20%',
  }, {
    key: 'bronze',
    name: '铜奖',
    default: '30%',
  }];

  onSubmitClick = () => {
    this.props.editContest({
      contest: this.state.contest,
      contestId: this.props.contest.id,
      onSuccess: () => {
        this.setState({
          changed: false,
        });
      },
    });
  };

  handleDateChange = keyName => (date) => {
    if (keyName !== 'enroll') {
      const start = date[0].format('X');
      const end = date[1].format('X');
      const cfg = set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, 'start_time', parseFloat(start));
      this.setState({
        changed: true,
        contest: set({ ...cfg }, 'end_time', parseFloat(end)),
      }, () => {
      });
    } else {
      const start = date[0].format('X');
      const end = date[1].format('X');
      const cfg = set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, 'configs.enroll_start_time', parseFloat(start));
      this.setState({
        changed: true,
        contest: set({ ...cfg }, 'configs.enroll_end_time', parseFloat(end)),
      });
    }
  };

  handleSingleDateChange = (date) => {
    const start = date.format('X');
    const end = date.clone().add(5, 'hours').format('X');
    const cfg = set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, 'start_time', parseFloat(start));
    this.setState({
      changed: true,
      contest: set({ ...cfg }, 'end_time', parseFloat(end)),
    });
  };

  handleBannedOpenTimeChange = (date) => {
    const start = date.format('X');
    const cfg = set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, 'configs.banned_time_open_at', parseFloat(start));
    this.setState({
      changed: true,
      contest: cfg,
    });
  };

  handleInputChangeBySender = (keyName, cnf = false)  => ({ target }) => {
    if (cnf) {
      this.setState({
        changed: true,
        contest: set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, `configs.${keyName}`, target.value),
      });
    } else {
      this.setState({
        changed: true,
        contest: set({ ...this.state.contest }, keyName, target.value),
      });
    }
  };

  handleInputChangeByValue = (keyName, cnf = false)  => (value) => {
    if (cnf) {
      if (keyName === 'account_expire_time') {
        this.setState({
          changed: true,
          contest: set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, `configs.${keyName}`, value * 3600 * 24),
        });
      } else {
        this.setState({
          changed: true,
          contest: set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, `configs.${keyName}`, value),
        });
      }
    } else if (keyName === 'account_expire_time') {
      this.setState({
        changed: true,
        contest: set({ ...this.state.contest }, keyName, value * 3600 * 24),
      });
    } else {
      let item = set({ ...this.state.contest }, keyName, value);
      if (keyName === 'schedule_type' && value === ScheduleTypeEnum.FIVE_HOURS.value) {
        const end = moment(this.state.contest.start_time * 1000).add(5, 'hours').format('X');
        item = set(item, 'end_time', parseFloat(end));
      }
      this.setState({
        changed: true,
        contest: item,
      });
    }
  };

  handlePercentNumberChange = (keyName, cnf = false)  => (value) => {
    if (cnf) {
      this.setState({
        changed: true,
        contest: set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, `configs.${keyName}`, value / 100.0),
      });
    } else {
      this.setState({
        changed: true,
        contest: set({ ...this.state.contest }, keyName, value / 100),
      });
    }
  };

  handleArrayItemChangeByCheckbox = (keyName, value) => ({ target }) => {
    const arr = JSON.parse(JSON.stringify(get(this.state.contest, `configs.${keyName}`, [])));
    const idx = arr.indexOf(value);
    if (target.checked) {
      (idx < 0) && arr.push(value);
    } else {
      (idx > -1) && arr.splice(idx, 1);
    }
    this.setState({
      changed: true,
      contest: set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, `configs.${keyName}`, arr),
    });
  };

  handleArrayItemChangeBySwitch = (keyName, value) => (checked) => {
    const arr = JSON.parse(JSON.stringify(get(this.state.contest, `configs.${keyName}`, [])));
    const idx = arr.indexOf(value);
    if (checked) {
      (idx < 0) && arr.push(value);
    } else {
      (idx > -1) && arr.splice(idx, 1);
    }
    this.setState({
      changed: true,
      contest: set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, `configs.${keyName}`, arr),
    });
  };

  handleEditorChange = (text) => {
    this.setState({
      changed: true,
      contest: set(this.state.contest, 'description', text),
    });
  };

  handleInputChangeAccountFields = (list) => {
    const ret = {};
    list.forEach((item) => {
      ret[item.name] = item;
    });
    this.setState({
      changed: true,
      contest: set(
        { ...JSON.parse(JSON.stringify(this.state.contest)) },
        'configs.account_fields',
        ret
      ),
    });
  };

  handleRankCacheClick = () => {
    this.props.delRankCache({
      contestId: this.props.contest.id,
    });
  };

  handleAddWhiteListUser = user => new Promise((resolve, reject) => {
    const email = get(user, 'account');
    const whitelist = [...get(this.state, 'contest.enroll_whitelist', [])];
    if (email && !whitelist.includes(email)) {
      whitelist.push(email);
      this.setState({
        changed: true,
        contest: set(this.state.contest, 'enroll_whitelist', whitelist),
      });
      resolve();
    } else {
      message.error(email ? 'Email已存在' : '请输入Email');
      reject();
    }
  });

  handleRemoveWhiteListUser = email => () => {
    const whitelist = [...get(this.state, 'contest.enroll_whitelist', [])];
    whitelist.splice(indexOf(whitelist, email), 1);
    this.setState({
      changed: true,
      contest: set(this.state.contest, 'enroll_whitelist', whitelist),
    });
  };

  handleUserWhiteListEditorSwitch = mode => () => {
    const ret = {};
    if (mode === 'textarea') {
      ret.enrollWhiteListText = get(this.state, 'contest.enroll_whitelist', []).join('\n');
    } else {
      const whitelist = Array.from(new Set(this.state.enrollWhiteListText.split('\n').filter(item => !!item)));
      ret.contest = set(this.state.contest, 'enroll_whitelist', whitelist);
    }
    this.setState({
      userWhiteListEditor: mode,
      ...ret,
    });
  };

  renderAwards = () => {
    return this.AWARDS_ITEMS.map((item) => {
      const customAwards = get(this.state, 'contest.configs.custom_awards', false);
      return {
        key: item.key,
        name: <><TrophyOutlined /> {item.name}</>,
        total: customAwards ? <InputNumber
          min={0}
          step={1}
          value={get(this.state, `contest.configs.awards_${item.key}`, 0)}
          onChange={this.handleInputChangeByValue(`awards_${item.key}`, true)}
        /> : item.default,
      };
    });
  };

  renderBasicInfoGroup = () => {
    const fiveHoursCombat = get(this.state, 'contest.schedule_type', 1) === ScheduleTypeEnum.FIVE_HOURS.value;
    return <BSGroup title="比赛信息" desc="设置比赛的基本信息">
      <div className="settings-list">
        <BSListItem title="比赛名称">
          <Input
            value={get(this.state, 'contest.title', '')}
            onChange={this.handleInputChangeBySender('title')}
          />
        </BSListItem>

        <BSListItem title="主办方">
          <Input
            value={get(this.state, 'contest.sponsor', '')}
            onChange={this.handleInputChangeBySender('sponsor')}
          />
        </BSListItem>
        <BSListItem title="比赛赛制">
          <Select
            className="full-size-input"
            placeholder="请选择赛制"
            value={get(this.state, 'contest.schedule_type', 1)}
            onChange={this.handleInputChangeByValue('schedule_type')}
          >
            {enumMap(ScheduleTypeEnum, (e) => {
              return <Option key={e.value} value={e.value}>{e.desc}</Option>;
            })}
          </Select>
        </BSListItem>
        <BSListItem title={fiveHoursCombat ? '比赛开始时间' : '比赛时间'}>
          {fiveHoursCombat ? <DatePicker
            style={{ width: '100%' }}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: moment('00:00:00', 'HH:mm:ss'),
            }}
            defaultValue={moment(formatTime(get(this.state, 'contest.start_time', 0), 'LONG_DATETIME'), dateFormat)}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={this.handleSingleDateChange}
          /> : <DatePicker.RangePicker
            style={{ width: '100%' }}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
            }}
            value={[
              moment(formatTime(get(this.state, 'contest.start_time', 0) || moment().format('X'), 'LONG_DATETIME'), dateFormat),
              moment(formatTime(get(this.state, 'contest.end_time', 0) || moment().format('X'), 'LONG_DATETIME'), dateFormat)
            ]}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={this.handleDateChange('contest')}
          />}
        </BSListItem>
        {fiveHoursCombat && <BSListItem title="封榜开放时间" desc="如果不设置，或者这个时间比结束时间早，则会使用结束时间">
          <DatePicker
            style={{ width: '100%' }}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: moment('00:00:00', 'HH:mm:ss'),
            }}
            defaultValue={moment(formatTime(get(this.state, 'contest.configs.banned_time_open_at', 0), 'LONG_DATETIME'), dateFormat)}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={this.handleBannedOpenTimeChange}
          />
        </BSListItem>}
        <BSListItem title="参赛形式">
          <Select
            className="full-size-input"
            placeholder="请选择参赛形式"
            value={get(this.state, 'contest.team_type', 1)}
            onChange={this.handleInputChangeByValue('team_type')}
          >
            {enumMap(RegisterTypeEnum, (e) => {
              return <Option key={e.value} value={e.value}>{e.desc}</Option>;
            })}
          </Select>
        </BSListItem>
        <BSListItem title="比赛类型">
          <Select
            className="full-size-input"
            placeholder="请选择比赛类型"
            value={get(this.state, 'contest.contest_type', 1)}
            onChange={this.handleInputChangeByValue('contest_type')}
          >
            {enumMap(ContestTypeEnum, (e) => {
              return <Option key={e.value} value={e.value}>{e.desc}</Option>;
            })}
          </Select>
        </BSListItem>
        <BSListItem title="短地址">
          <Input
            addonBefore="https://contest.wejudge.net/"
            value={get(this.state, 'contest.short_name', '')}
            onChange={this.handleInputChangeBySender('short_name')}
            style={{ marginTop: 4 }}
          />
        </BSListItem>
      </div>
    </BSGroup>;
  };

  renderEnrollInfoGroup = () => {
    const enrollWhiteList = get(this.state, 'contest.enroll_whitelist', []);
    return <BSGroup title="登录报名设置" desc="启用并配置报名功能">
      <div className="settings-list">
        <BSListItem title="启用报名功能" align="left">
          <Switch
            checked={get(this.state, 'contest.configs.enroll', false)}
            onChange={this.handleInputChangeByValue('enroll', true)}
          />
        </BSListItem>
        {get(this.state, 'contest.configs.enroll', false) && <BSListItem title="报名组队时间">
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
            }}
            value={[
              moment(formatTime(get(this.state, 'contest.configs.enroll_start_time', 0) || moment().format('X'), 'LONG_DATETIME'), dateFormat),
              moment(formatTime(get(this.state, 'contest.configs.enroll_end_time', 0) || moment().format('X'), 'LONG_DATETIME'), dateFormat)
            ]}
            format="YYYY-MM-DD HH:mm:ss"
            onChange={this.handleDateChange('enroll')}
            disabled={!get(this.state, 'contest.configs.enroll', false)}
          />
        </BSListItem>}
        <BSListItem title="启用支付功能(beta)" align="left">
          <Switch
            checked={get(this.state, 'contest.enroll_need_pay', false)}
            onChange={this.handleInputChangeByValue('enroll_need_pay')}
          />
        </BSListItem>
        <BSListItem title="Portal认证" align="left">
          <Switch
            checked={get(this.state, 'contest.configs.register_portal', false)}
            onChange={this.handleInputChangeByValue('register_portal', true)}
          />
        </BSListItem>
        {get(this.state, 'contest.configs.register_portal', false) && <>
          <BSListItem title="Portal接口地址">
            <Input
              value={get(this.state, 'contest.configs.register_portal_api', '')}
              onChange={this.handleInputChangeBySender('configs.register_portal_api')}
            />
          </BSListItem>
          <BSListItem title="Portal接口令牌">
            <Input
              value={get(this.state, 'contest.configs.register_portal_token', '')}
              onChange={this.handleInputChangeBySender('configs.register_portal_token')}
            />
          </BSListItem>
        </>}
        <BSListItem title="登入限制(WeJudge账号)" align="left" tip="当使用WeJudge账号进行登录报名时，只有白名单内的用户可以登入，其他人将被拒绝。">
          <Switch
            checked={get(this.state, 'contest.enroll_use_whitelist', false)}
            onChange={this.handleInputChangeByValue('enroll_use_whitelist')}
          />
        </BSListItem>
        {get(this.state, 'contest.enroll_use_whitelist', false) && <BSListItem title="WeJudge账号白名单">
          {this.state.userWhiteListEditor === 'textarea' ? <Input.TextArea
            value={this.state.enrollWhiteListText}
            placeholder="请输入WeJudge账号的邮箱，以换行隔开"
            rows={10}
            onChange={({ target }) => {
              this.setState({
                enrollWhiteListText: target.value,
              });
            }}
          /> : <List
            className="enroll_whitelist"
            size="small"
            header={`当前选择了${enrollWhiteList.length}人`}
            footer={<AccountPicker
              style={{ width: '100%' }}
              handleSelected={this.handleAddWhiteListUser}
            />}
            bordered
            dataSource={enrollWhiteList}
            renderItem={item => <List.Item
              key={item}
              actions={[<a key="remove" onClick={this.handleRemoveWhiteListUser(item)}>移除</a>]}
            >{item}</List.Item>}
          />}
          <a
            onClick={this.handleUserWhiteListEditorSwitch(this.state.userWhiteListEditor === 'textarea' ? 'list' : 'textarea')}
          >
            {this.state.userWhiteListEditor === 'textarea' ? '保存' : '文本方式编辑'}
          </a>
        </BSListItem>}
        <BSListItem title="赛后允许选手登录">
          <InputNumber
            min={0}
            step={1}
            value={get(this.state, 'contest.configs.account_expire_time', 0) / 3600 / 24}
            onChange={this.handleInputChangeByValue('account_expire_time', true)}
          />&nbsp;&nbsp;天内选手可以登录（0表示不限制）
        </BSListItem>
      </div>
    </BSGroup>;
  };

  renderFunctionalGroup = () => {
    return <BSGroup title="功能设置" desc="一些比赛相关功能的设置">
      <div className="settings-list">
        <BSListItem title="可用语言">
          <JudgeLanguageCheckBox
            language={get(this.state, 'contest.code_language', 0)}
            onChange={this.handleInputChangeBySender('code_language')}
          />
        </BSListItem>
        <BSListItem title="罚时时间（秒）">
          <InputNumber
            min={0}
            step={86400}
            className="full-size-input"
            value={get(this.state, 'contest.configs.penalty_time', 1200)}
            onChange={this.handleInputChangeByValue('penalty_time', true)}
          />
        </BSListItem>
        <BSListItem title="罚时项目">
          {enumMap(JudgeFlagEnum, (e) => {
            if (e.value < 1 || e.value > 7) return null;
            return <Checkbox
              key={e.value}
              className="settings-checkbox"
              onChange={this.handleArrayItemChangeByCheckbox('penalty_flags', e.value)}
              checked={get(this.state.contest, 'configs.penalty_flags', []).indexOf(e.value) > -1}
            >
              {e.desc}({e.abbr})
            </Checkbox>;
          })}
        </BSListItem>
        <BSListItem title="资料打印服务" align="left">
          <Switch
            checked={get(this.state, 'contest.configs.enable_printer_queue', false)}
            onChange={this.handleInputChangeByValue('enable_printer_queue', true)}
          />
        </BSListItem>
        <BSListItem title="赛后公开查重结果" align="left">
          <Switch
            checked={get(this.state, 'contest.configs.public_cross_check', false)}
            onChange={this.handleInputChangeByValue('public_cross_check', true)}
          />
        </BSListItem>
        {get(this.state, 'contest.configs.public_cross_check', false) && <BSListItem title="公开阈值">
          查重率&nbsp;≥&nbsp;
          <InputNumber
            value={get(this.state, 'contest.configs.public_cross_ratio_gte', false) * 100}
            min={0}
            max={100}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            onChange={this.handlePercentNumberChange('public_cross_ratio_gte', true)}
          />
        </BSListItem>}
        <BSListItem title="忽略格式错误(PE)" align="left">
          <Switch
            checked={get(this.state, 'contest.ignore_pe', false)}
            onChange={this.handleInputChangeByValue('ignore_pe', false)}
          />
        </BSListItem>
        <BSListItem title="数据令牌" tip="用于比赛只读数据获取，如气球机等">
          <Input
            value={get(this.state, 'contest.configs.access_token', 0)}
            onChange={this.handleInputChangeBySender('access_token', true)}
          />
        </BSListItem>
      </div>
    </BSGroup>;
  };

  renderRankAwardGroup = () => {
    return <BSGroup title="排行榜设置" desc="设置排行榜、奖牌信息等">
      <div className="settings-list">
        <BSListItem title="自定义奖牌设置" align="left">
          <Switch
            checked={get(this.state, 'contest.configs.custom_awards', false)}
            onChange={this.handleInputChangeByValue('custom_awards', true)}
          />
        </BSListItem>
        <BSListItem title="奖牌设置">
          <Table
            bordered
            size="small"
            columns={this.AWARDS_COLUMNS}
            dataSource={this.renderAwards()}
            pagination={false}
          />
          {!get(this.state, 'contest.configs.custom_awards', false) && <Alert
            type="info"
            message="排行榜显示规则须知"
            description={<span>
              根据上述默认设置，以100人参赛为例，可以得到以下准确数字：
              <ul style={{ margin: '.5em 0 .5em 16px' }}>
                <li>金奖：第1-10名，10人。</li>
                <li>银奖：第11-30名，20人。</li>
                <li>铜奖：第31-60名，30人。</li>
              </ul>
              如果不满足你的需求，你可以自定义具体每个奖项的人数。
            </span>}
            style={{ marginTop: 16 }}
          />}
        </BSListItem>
        <BSListItem title="排行榜缓存" align="left">
          <Button type="primary" onClick={this.handleRankCacheClick}>重建</Button>
        </BSListItem>
      </div>
    </BSGroup>;
  };

  render() {
    if (!this.state.contest) return null;
    return (
      <div className="contest_settings_view">
        <Prompt message="未保存的改动将要丢失，是否继续？" when={this.state.changed} />
        <Tabs
          className="settings_layout"
          animated={false}
          tabBarExtraContent={<Button
            className="save_btn"
            type="primary"
            onClick={this.onSubmitClick}
          >
            保存设置
          </Button>}
        >
          <TabPane tab="基本设置" key="basic">
            <div className="contest-manage-basic-layer">
              {this.renderBasicInfoGroup()}
              {this.renderEnrollInfoGroup()}
              {this.renderFunctionalGroup()}
              {this.renderRankAwardGroup()}
            </div>
          </TabPane>
          <TabPane tab="自定义报名信息" key="account_fields">
            <AccountFields
              contest={this.props.contest}
              accountFields={get(this.props.contest, 'configs.account_fields', {})}
              onChangeSubmit={this.handleInputChangeAccountFields}
            />
          </TabPane>
          <TabPane tab="自定义首页" key="home_page">
            <MarkdownEditor
              height="100%"
              onChange={this.handleEditorChange}
              defaultValue={get(this.state.contest, 'description')}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ContestManageView;
