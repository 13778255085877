import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CloseOutlined,
  FileExcelOutlined,
  RollbackOutlined,
  UserAddOutlined
} from '@ant-design/icons';

import {
  Table, Button, Modal, Input 
} from 'antd';
import { bindActionCreators } from 'redux';
import {
  get, pick, isEqual, noop 
} from 'lodash';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import { showModal } from 'scripts/common/widgets/modal';
import { StudentsActions } from 'scripts/common/logic/education/students/action';
import { StudentsSelectors } from 'scripts/common/logic/education/students/selector';
import { WeJudgeActions } from 'scripts/common/logic/wejudge/action';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationParams } from 'scripts/common/constants/global';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { StudentPropTypes } from 'scripts/common/prop-types/account';
import AddStudentModal from 'scripts/apps/education/course/widgets/dialog/add_student';
import { importStudentExcel } from 'scripts/common/utils/xlsx_importer';
import { ManagementRoutes } from 'scripts/apps/routes';
import { AccountRoleEnum } from 'scripts/common/enums/account';

const mapDispatchToProps = dispatch => bindActionCreators({
  getSchoolStudentsList: StudentsActions.getSchoolStudentsList,
  delStudent: StudentsActions.delStudent,
  addStudent: StudentsActions.addStudent,
  editStudent: StudentsActions.editStudent,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  viewErrorMessage: WeJudgeActions.viewErrorMessage,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const studentDatas = StudentsSelectors.getSchoolStudentsList(state);
  const account = AccountSelectors.getLoginAccount(state);
  const pager = StudentsSelectors.schoolStudentsListViewPagerSelector(state);
  const queryset = getQuery(props.location);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page', 'code', 'realname']),
  };
  const currentRoutePath = matchPath(
    location.pathname,
    {
      path: ManagementRoutes.SCHOOL_LIST,
      exact: true,
    }
  ) ? ManagementRoutes.SCHOOL_LIST : ManagementRoutes.STUDENT_MANAGEMENT;
  const schoolId = get(account, 'role') === AccountRoleEnum.ADMINISTRATOR.value
    ? parseInt(get(match, 'params.schoolId', 0), 10)
    : parseInt(get(account, 'school.id'), 10);
  return {
    schoolId,
    studentDatas,
    currentRoutePath,
    queryParams,
    paginationParams: pager || props.paginationParams,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class StudentManagementView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.getSchoolStudentsList({
      sid: nextProps.schoolId,
      query: {
        ...pick(nextProps.queryParams, ['limit', 'page', 'realname', 'code']),
      },
      onComplete: () => {

      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    getSchoolStudentsEntities: PropTypes.func,
    studentDatas: PropTypes.arrayOf(StudentPropTypes),
    hideBack: PropTypes.bool,
    delStudent: PropTypes.func,
    schoolId: PropTypes.number,
    addStudent: PropTypes.func,
    editStudent: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    viewErrorMessage: PropTypes.func,
    paginationParams: PaginationPropTypes,
  };

  static defaultProps = {
    hideBack: false,
    schoolId: 0,
    getSchoolStudentsEntities: noop,
    studentDatas: [],
    delStudent: noop,
    addStudent: noop,
    editStudent: noop,
    viewSuccessMessage: noop,
    viewErrorMessage: noop,
    paginationParams: { ...PaginationParams },
  };

  state= {
    students: [],
    studentSelectedKeys: [],
    hasFetchStudentList: false,
    queryParams: {},
    searchRealNameValue: '',
    searchCodeValue: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchStudentList || !isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams }  = nextProps;
      StudentManagementView.fetchList(nextProps);
      return {
        hasFetchStudentList: true,
        queryParams,
      };
    }
    return null;
  }

  StudentsColumns=[{
    title: 'ID',
    dataIndex: 'id',
    width: 100,
  }, {
    title: '学号',
    dataIndex: 'code',
  }, {
    title: '真实姓名',
    dataIndex: 'realname',
  }, {
    title: '绑定',
    dataIndex: 'binded',
    width: 80,
    align: 'center',
  }, {
    title: '操作',
    dataIndex: 'action',
    width: 100,
    align: 'center',
  }];

  //导入学生
  inputRef = null;

  handleDeleteSingleStudent = (item) => {
    Modal.confirm({
      title: '移除学生',
      content: <>
        <div>您确定要移除该名学生吗？这将会丢失这个学生的学习记录。</div>
        <div>学号：{item.code}</div>
        <div>真实姓名：{item.realname}</div>
      </>,
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        this.handleDelStudent([item.id]);
      },
    });
  };

  handleDeleteStudents = () => {
    Modal.confirm({
      title: '移除学生',
      content: <>
        <div>您确定要移除这{this.state.studentSelectedKeys.length}个学生信息吗？这将会丢失这个学生的学习记录。</div>
      </>,
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        this.handleDelStudent(this.state.studentSelectedKeys);
      },
    });
  };

  handleEditStudent = (item) => {
    const modalBox = showModal(AddStudentModal, {
      width: 350,
      student: item,
      mode: 'edit',
      onOk: (modal, state) => {
        this.props.editStudent({
          schoolId: this.props.schoolId,
          studentId: item.id,
          code: state.student.code,
          realname: state.student.realname,
          resetBind: state.student.resetBind,
          onSuccess: (result) => {
            StudentManagementView.fetchList(this.props);
            if (result) {
              this.props.viewSuccessMessage({
                message: '修改成功',
              });
              modalBox.close();
            }
          },
        });
      },
    });
  };

  handleAddStudentClick = () => {
    const modalBox = showModal(AddStudentModal, {
      width: 350,
      mode: 'add',
      onOk: (modal, state) => {
        this.props.addStudent({
          schoolId: this.props.schoolId,
          students_info: [{ code: state.student.code, realname: state.student.realname }],
          onSuccess: (result) => {
            StudentManagementView.fetchList(this.props);
            if (result) {
              this.props.viewSuccessMessage({
                message: '添加成功',
              });
              modalBox.close();
            }
          },
        });
      },
    });
  };

  handleDelStudent = (item) => {
    this.props.delStudent({
      schoolId: this.props.schoolId,
      ids: item,
      onSuccess: () => {
        this.props.viewSuccessMessage({
          message: '删除成功',
        });
        StudentManagementView.fetchList(this.props);
        this.setState({
          studentSelectedKeys: [],
        });
      },
    });
  };

  handleXlsxChange = (e) => {
    const getResult = (data) => {
      this.props.addStudent({
        schoolId: this.props.schoolId,
        students_info: data,
        onSuccess: (result) => {
          StudentManagementView.fetchList(this.props);
          if (result) {
            this.props.viewSuccessMessage({
              message: '添加成功',
            });
          }
        },
      });
    };
    if (e.target.files.length > 0) {
      importStudentExcel(e.target.files)
        .then(data => getResult(data))
        .catch((ex) => {
          this.props.viewErrorMessage({
            message: '解析Excel文件失败',
          });
        });
    }
  };

  handleXlsxUpload = () => {
    this.inputRef.click();
  };

  handleStudentListSelectChange = (selectedKeys) => {
    this.setState({
      ...this.state,
      studentSelectedKeys: selectedKeys,
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      { sid: this.props.schoolId },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'code', 'realname']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleStudentSearch = key => (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      null,
      match.params,
      value === '' ? {
        ...pick(this.state.queryParams, ['limit']),
      } : {
        ...pick(this.state.queryParams, ['limit']),
        [key]: value,
      }
    ));
  };

  handleBack = () => {
    const { history, match } = this.props;
    history.push(buildPath(
      ManagementRoutes.SCHOOL_LIST,
      null,
      match.params,
      {}
    ));
  };

  renderStudentList = () => {
    const studentList = [...this.props.studentDatas];
    return studentList.map((item) => {
      if (!item) return null;
      return {
        key: get(item, 'id', ''),
        id: get(item, 'id', ''),
        realname: get(item, 'realname', ''),
        code: get(item, 'code', ''),
        binded: get(item, 'master')
          ? <CheckCircleTwoTone twoToneColor="#52c41a" />
          : <CloseCircleTwoTone twoToneColor="#f5222d" />,
        action: <div><a style={{ marginLeft: '4px' }} onClick={() => this.handleEditStudent(item)}>设置</a>
          <a style={{ marginLeft: '4px' }} onClick={() => this.handleDeleteSingleStudent(item)}>删除</a></div>,
      };
    });
  };

  render() {
    const selectionSettings = {
      getCheckboxProps: (record) => { return record.key === 'new' ? { disabled: true } : {}; },
      selectedRowKeys: this.state.studentSelectedKeys,
      onChange: this.handleStudentListSelectChange,
    };
    const studentDatas = this.renderStudentList();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 1),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.handlePageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    return (
      <div className="management-student-view">
        <div className="student-action-bar">
          <div className="left-sider">
            <input
              type="file"
              name="excel-file"
              onChange={this.handleXlsxChange}
              style={{ display: 'none' }}
              ref={(node) => { this.inputRef = node; }}
            />
            {!this.props.hideBack && <Button
              onClick={this.handleBack}
            >
              <RollbackOutlined /> 返回列表
            </Button>}
          </div>
          <div className="right-sider">
            <Button.Group>
              <Button
                type="primary"
                onClick={this.handleAddStudentClick}
              >
                <UserAddOutlined /> 增加学生
              </Button>
              <Button
                onClick={this.handleXlsxUpload}
              >
                <FileExcelOutlined /> 导入学生
              </Button>
              <Button
                type="danger"
                disabled={this.state.studentSelectedKeys.length <= 0}
                onClick={this.handleDeleteStudents}
              >
                <CloseOutlined /> 移除学生
              </Button>
            </Button.Group>
          </div>
        </div>
        <div className="student-filter-bar">
          <Input.Search
            placeholder="按学号搜索"
            style={{ width: 200 }}
            value={this.state.searchCodeValue}
            onChange={({ target }) => { this.setState({ searchCodeValue: target.value }); }}
            onSearch={this.handleStudentSearch('code')}
          />
          <Input.Search
            placeholder="按真实姓名搜索"
            style={{ width: 200 }}
            value={this.state.searchRealNameValue}
            onChange={({ target }) => { this.setState({ searchRealNameValue: target.value }); }}
            onSearch={this.handleStudentSearch('realname')}
          />
          <a href="/static/excel-template/import_students.xlsx" style={{ marginLeft: '16px' }}>学生模板</a>
        </div>
        <div className="student-list">
          <Table
            bordered
            size="middle"
            dataSource={studentDatas}
            columns={this.StudentsColumns}
            rowSelection={selectionSettings}
            pagination={optPagination}
          />
        </div>
      </div>
    );
  }
}

export default StudentManagementView;
