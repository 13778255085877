import { get } from 'lodash';
import {
  Axis, Chart, Coord, Geom, Label, Legend, Tooltip, View 
} from 'bizcharts';
import React from 'react';
import Slider from 'bizcharts-plugin-slider';
import PropTypes from 'prop-types';
import DataSet from '@antv/data-set';
import { JudgeFlagEnum, JudgeLanguageEnum } from 'scripts/common/enums/judge';

const { DataView } = DataSet;

const FLAGS = [
  JudgeFlagEnum.AC,
  JudgeFlagEnum.PE,
  JudgeFlagEnum.WA,
  JudgeFlagEnum.TLE,
  JudgeFlagEnum.MLE,
  JudgeFlagEnum.OLE,
  JudgeFlagEnum.RE,
  JudgeFlagEnum.CE,
  {
    value: 'other',
    desc: '其他',
    color: '#595959',
    abbr: 'Other',
  }
].reverse();

export const JudgeStatusPieChart = ({ data }) => {
  const dv = new DataView();
  dv.source(data).transform({
    type: 'percent',
    field: 'count',
    dimension: 'lang',
    as: 'percent',
  });
  const dvinside = new DataView();
  dvinside.source(data).transform({
    type: 'percent',
    field: 'count',
    dimension: 'flag',
    as: 'percent',
  });
  const cols = {
    percent: {
      formatter: (val) => {
        return `${(val * 100).toFixed(2)}%`;
      },
    },
  };
  const colors = (item) => {
    const flag = parseInt(item, 10);
    return get(JudgeFlagEnum.enumValueOf(flag), 'color', '#bfbfbf');
  };
  return <Chart
    height={320}
    width={480}
    data={dv}
    scale={cols}
    padding={[10, 10, 10, 10]}
  >
    <span className="main-title" style={{ fontWeight: 'bolder', fontSize: 16 }}>
      评测状态统计
    </span>
    <Coord type="theta" radius={0.5} />
    <Axis name="percent" />
    <Tooltip
      showTitle={false}
      itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
    />
    <Geom
      type="intervalStack"
      position="percent"
      color="lang"
      tooltip={[
        'lang*count*percent',
        (lang, count, percent) => {
          const p = `${count} (${(percent * 100).toFixed(2)}%)`;
          return {
            name: get(JudgeLanguageEnum.enumValueOf(lang), 'desc', '未知'),
            value: p,
          };
        }
      ]}
      style={{
        lineWidth: 1,
        stroke: '#fff',
      }}
      select={false}
    >
      <Label
        content="percent"
        offset={-10}
      />
    </Geom>
    <View data={dvinside} scale={cols}>
      <Coord type="theta" radius={0.75} innerRadius={0.5 / 0.75} />
      <Geom
        type="intervalStack"
        position="percent"
        color={[
          'flag',
          colors
        ]}
        tooltip={[
          'flag*count*percent',
          (flag, count, percent) => {
            const p = `${count} (${(percent * 100).toFixed(2)}%)`;
            return {
              name: flag === 'other' ? '其他' : get(JudgeFlagEnum.enumValueOf(parseInt(flag, 10)), 'desc', '未知'),
              value: p,
            };
          }
        ]}
        style={{
          lineWidth: 1,
          stroke: '#fff',
        }}
        select={false}
      >
        <Label
          content="flag"
          formatter={(flag) => {
            return flag === 'other' ? '其他' : get(JudgeFlagEnum.enumValueOf(parseInt(flag, 10)), 'abbr', '未知');
          }}
        />
      </Geom>
    </View>
  </Chart>;
};

JudgeStatusPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

let chart = null;

export const JudgeStatusTimeLineChart = ({ timeline, timeDuration, width }) => {
  const SixtySeconds = 10 * 60 * 1000;
  const ds = new DataSet({
    state: {
      start: get(timeDuration, 'start', 0) * SixtySeconds,
      end: get(timeDuration, 'end', 0) * SixtySeconds,
    },
  });

  const onChange = (obj) => {
    const { startValue, endValue } = obj;
    ds.setState('start', startValue);
    ds.setState('end', endValue);
  };

  const dv = ds.createView('origin').source(timeline);
  dv.transform({
    type: 'map',
    callback(obj) {
      return {
        ...obj,
        time: obj.time * SixtySeconds,
      };
    },
  }).transform({
    type: 'filter',
    callback(obj) {
      return obj.time >= ds.state.start && obj.time <= ds.state.end;
    },
  });

  const scale = {
    time: {
      type: 'time',
      tickCount: 8,
      mask: 'M/DD H:mm',
    },
  };
  FLAGS.filter(i => i.value !== 'total').forEach((flag) => {
    scale[flag.value] = {
      type: 'linear',
      alias: flag.desc,
    };
  });
  return <div>
    <Chart
      height={280}
      width={width}
      data={dv}
      padding={[40, 80, 40, 80]}
      scale={scale}
      onGetG2Instance={(g2Chart) => {
        g2Chart.animate(false);
        chart = g2Chart;
      }}
    >
      <div className="main-title" style={{ textAlign: 'center' }}>
        评测时间线(10分钟)
      </div>
      <Axis />
      {FLAGS.map(flag => <Axis key={flag.value} visible={flag.value === 0} name={flag.value} />)}
      <Tooltip />
      <Legend
        custom
        clickable={false}
        position="top"
        onClick={(ev) => {
          if (ev) return;   // 暂时关闭
          const { item, checked } = ev;
          const { value } = item;
          const geoms = chart.getAllGeoms();
          for (let i = 0; i < geoms.length; i++) {
            const geom = geoms[i];
            if (geom.getYScale().field === value) {
              if (checked) {
                geom.show();
              } else {
                geom.hide();
              }
            }
          }
        }}
        items={FLAGS.filter(i => i.value !== 'total').map((flag) => {
          return {
            value: flag.abbr,
            marker: {
              symbol: 'circle',
              fill: flag.color,
              radius: 5,
            },
          };
        })}
      />
      {FLAGS.map((flag) => {
        return <Geom
          type="areaStack"
          key={flag.value}
          position={`time*${flag.value}`}
          color={flag.color}
          opacity={0.85}
          shape="smooth"
          hide={flag.value === 'total'}
        />;
      })}
    </Chart>
    <div>
      <Slider
        width={width - 40}
        height={26}
        start={ds.state.start}
        end={ds.state.end}
        xAxis="time"
        yAxis="total"
        scales={{
          time: {
            type: 'time',
            tickCount: 10,
            mask: 'M/DD H:mm',
          },
        }}
        data={dv}
        backgroundChart={{
          type: 'line',
        }}
        onChange={onChange}
      />
    </div>
  </div>;
};

JudgeStatusTimeLineChart.propTypes = {
  timeline: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeDuration: PropTypes.shape({}).isRequired,
  width: PropTypes.number.isRequired,
};
