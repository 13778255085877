import React from 'react';
import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { Switch, Route } from 'react-router-dom';
import { NoticeRoutes } from 'scripts/apps/routes';
import NoticeView from './notice_view';

import { WeJudge404NotFoundComponent } from '../wejudge/not_found_page';

const NoticeApp = () => {
  return (
    <BaseLayout>
      <Switch>
        <Route path={`${NoticeRoutes.LIST}`} />
        <Route path={`${NoticeRoutes.NOTICE_VIEW}`} component={NoticeView} />
        <Route component={WeJudge404NotFoundComponent} />
      </Switch>
    </BaseLayout>
  );
};

export default NoticeApp;
