import 'styles/problem/management.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { get } from 'lodash';
import {
  CodeOutlined,
  DashboardOutlined,
  DatabaseOutlined, DeleteOutlined,
  EditOutlined,
  RocketOutlined
} from '@ant-design/icons';
import {
  Route, Switch, Link, matchPath, Redirect, withRouter 
} from 'react-router-dom';
import { CollectionProblemViewRoutes } from 'scripts/apps/routes';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import { CollectionProblemPropTypes }  from 'scripts/common/prop-types/collection';
import CodeProblemEditView from './code_problem_edit';
import JudgeConfig from './judge_config';
import DemoCasesEditorView from './demo_cases';
import TestCasesEditorView from './test_cases';
import DeleteProblemView from './delete';
import SpecialJudgeEditor from './special_judge';

@withRouter
class CodeProblemManagementView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    problem: ProblemPropTypes.isRequired,
    collectionProblem: CollectionProblemPropTypes.isRequired,
    collectionId: PropTypes.string.isRequired,
  };

  menuItems = [
    {
      key: 'edit',
      title: '题目内容',
      path: CollectionProblemViewRoutes.MANAGEMENT_EDIT,
      icon: <EditOutlined />,
    },
    {
      key: 'judge',
      title: '评测设置',
      path: CollectionProblemViewRoutes.MANAGEMENT_JUDGE,
      icon: <DashboardOutlined />,
    },
    ...(get(this.props.problem, 'problem_type', 0) === ProblemTypeEnum.CODE_FILL.value ? [{
      key: 'demo_cases',
      title: '填空数据',
      path: CollectionProblemViewRoutes.MANAGEMENT_DEMOCASES,
      icon: <CodeOutlined />,
    }] : []),
    {
      key: 'test_cases',
      title: '测试数据',
      path: CollectionProblemViewRoutes.MANAGEMENT_TESTCASES,
      icon: <DatabaseOutlined />,
    },
    {
      key: 'special_judge',
      title: '特殊评测',
      path: CollectionProblemViewRoutes.MANAGEMENT_SPECIAL_JUDGE,
      icon: <RocketOutlined />,
    },
    {
      key: 'delete',
      title: '删除题目',
      path: CollectionProblemViewRoutes.MANAGEMENT_DELETE,
      icon: <DeleteOutlined />,
    }
  ];

  matchSubItemKey = () => {
    return get(matchPath(this.props.location.pathname, {
      path: CollectionProblemViewRoutes.MANAGEMENT_SUBITEMS,
    }), 'params.subItemName', 'edit');
  };

  renderMenuItem = ({
    key,
    icon,
    title,
    path,
  }) => (
    <Menu.Item key={key}>
      <Link
        to={buildPath(path, null, this.props.match.params)}
      >
        {icon} {title}
      </Link>
    </Menu.Item>
  );

  render() {
    const subItemKey = [this.matchSubItemKey()];
    return (<div className="code-problem-management-view">
      <Menu
        defaultSelectedKeys={subItemKey}
      >
        {this.menuItems.map(item => this.renderMenuItem(item))}
      </Menu>
      <div className="content-view">
        <Switch>
          <Route
            path={CollectionProblemViewRoutes.MANAGEMENT_EDIT}
            render={props => <CodeProblemEditView
              problem={this.props.problem}
              collectionId={this.props.collectionId}
              {...props}
            />}
            exact
          />
          <Route
            path={CollectionProblemViewRoutes.MANAGEMENT_JUDGE}
            render={props => <JudgeConfig
              problem={this.props.problem}
              collectionProblem={this.props.collectionProblem}
              collectionId={this.props.collectionId}
              {...props}
            />}
            exact
          />
          <Route
            path={CollectionProblemViewRoutes.MANAGEMENT_TESTCASES}
            render={props => <TestCasesEditorView
              problem={this.props.problem}
              collectionId={this.props.collectionId}
              {...props}
            />}
            exact
          />
          {get(this.props.problem, 'problem_type', 0) === ProblemTypeEnum.CODE_FILL.value && <Route
            path={CollectionProblemViewRoutes.MANAGEMENT_DEMOCASES}
            render={props => <DemoCasesEditorView
              problem={this.props.problem}
              collectionId={this.props.collectionId}
              {...props}
            />}
            exact
          />}
          <Route
            path={CollectionProblemViewRoutes.MANAGEMENT_SPECIAL_JUDGE}
            render={props => <SpecialJudgeEditor
              problem={this.props.problem}
              collectionId={this.props.collectionId}
              {...props}
            />}
            exact
          />
          <Route
            path={CollectionProblemViewRoutes.MANAGEMENT_DELETE}
            render={props => <DeleteProblemView
              problem={this.props.problem}
              collectionId={this.props.collectionId}
              {...props}
            />}
            exact
          />
          <Route
            render={() => <Redirect
              to={buildPath(
                CollectionProblemViewRoutes.MANAGEMENT_EDIT,
                null,
                this.props.match.params
              )}
            />}
          />
        </Switch>
      </div>
    </div>);
  }
}

export default CodeProblemManagementView;
