import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { AccountRoutes } from 'scripts/apps/routes';
import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { buildPath } from 'scripts/common/utils/location_helper';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';
import LoginView from './auth/login';
import RegisterView from './auth/register';
import VerificationView from './email/email_verification';
import ForgetPasswordView from './auth/forget_password';
import AccountSpaceView from './space/index';

const AccountAPP = () => (
  <BaseLayout>
    <Switch>
      <Route path={AccountRoutes.AUTH.LOGIN} component={LoginView} />
      <Route path={AccountRoutes.AUTH.FORGET} component={LoginView} />
      <Route path={AccountRoutes.AUTH.WECHAT} component={LoginView} />
      <Route path={AccountRoutes.AUTH.REGISTER} component={RegisterView} />
      <Route path={AccountRoutes.SPACE.SUBITEMS} component={AccountSpaceView} />
      <Route
        path={AccountRoutes.DASHBOARD.INDEX}
        render={() => <Redirect to={buildPath(AccountRoutes.SPACE.INDEX, { accountId: 'my' })} />}
      />
      <Route path={AccountRoutes.EMAIL.VERIFICATION} component={VerificationView} />
      <Route path={AccountRoutes.EMAIL.FORGET_PASSWORD} component={ForgetPasswordView} />
      <Route component={WeJudge404NotFoundComponent} />
    </Switch>
  </BaseLayout>
);

export default AccountAPP;
