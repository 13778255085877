import 'styles/collection/code_problem.scss';

import React from 'react';
import { isEmpty, get, noop } from 'lodash';
import PropTypes from 'prop-types';
import {
  ClockCircleOutlined,
  FileTextOutlined,
  SettingOutlined
} from '@ant-design/icons';
import {
  Menu, Tag, PageHeader, Button, Row, Tooltip, Descriptions
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localStorage from 'localStorage';
import DataKeys from 'scripts/common/constants/data_keys';
import {
  Route, Switch, matchPath, Redirect, withRouter, Link 
} from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { CollectionProblemViewRoutes, CollectionViewsRoutes } from 'scripts/apps/routes';
import { buildPath } from 'scripts/common/utils/location_helper';
import {
  CollectionProblemSolutionsActions,
  CollectionProblemsSelectors,
  CollectionProblemActions,
  CollectionSelectors,
  JudgeStatusActions
} from 'scripts/common/logic/collection';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { CollectionProblemPropTypes, CollectionPropTypes } from 'scripts/common/prop-types/collection';
import CodeProblemBody from 'scripts/apps/problem/widgets/code_problem_body';
import SubmitCodeView from 'scripts/apps/collection/problems/submit_code_view';
import JudgeStatusView from 'scripts/apps/collection/judge_status/judge_status_list';
import Solutions from 'scripts/apps/widgets/answer/solutions_overall.jsx';
import CommentList from 'scripts/apps/widgets/comment/comment.jsx';
import Markdown from 'scripts/apps/widgets/answer/markdown_list.jsx';
import SolutionsTags from 'scripts/apps/widgets/answer/solutions_tags.jsx';
import CodeProblemManagementView from 'scripts/apps/problem/manage';
import { createPermissionChecker, isWeJudgeUUID } from 'scripts/common/utils/validator';
import {
  ProblemTypeEnum,
  ProblemPermissionEnum,
  ProblemDifficultyEnum
} from 'scripts/common/enums/problem';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import VerticalSplitContainer from 'scripts/common/widgets/split_view';
import { SpecialJudgeEnum } from 'scripts/common/enums/judge';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import CenterSpin from 'scripts/common/widgets/center-spin';

const LOADING_STATUS_KEY = 'collection_code_problem_view';
@LoginRequire
@withRouter
@connect((state, props) => {
  const { match } = props; 
  const problemId = get(match.params, 'problemId', '');
  const collectionId = get(match.params, 'collectionId', '');
  const collectionProblem =    CollectionProblemsSelectors.getCollectionProblemEntity(state)(problemId);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  let clQuerys = localStorage.getItem(DataKeys.LOCAL_STORAGE.COLLECTION_PROBLEMS_LIST_QUERYS);
  try {
    clQuerys =      JSON.parse(clQuerys);
  } catch (e) {
    clQuerys = {};
  }
  const problemPermission = createPermissionChecker(get(collectionProblem, 'problem.permissions_calculated', {}));
  const collection = CollectionSelectors.getCollectionEntity(state)(collectionId);
  const canManageProblem = problemPermission(ProblemPermissionEnum.MANAGE);
  const canJudgeSubmit = problemPermission(ProblemPermissionEnum.SUBMIT);
  return {
    clQuerys,
    collection,
    problemId,
    isLoading,
    collectionId,
    collectionProblem,
    canManageProblem,
    canJudgeSubmit,
  };
}, dispatch => bindActionCreators({
  problemSolutions: CollectionProblemSolutionsActions.getCollectionProblemSolutionList,
  submitCode: JudgeStatusActions.submitCode,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getCollectionProblemEntity: CollectionProblemActions.getCollectionProblemEntity,
  getCollectionProblem: CollectionProblemActions.getCollectionProblem,
  markLikes: CollectionProblemSolutionsActions.markSolutionLikes,
}, dispatch))
class CodeProblemView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    isLoading: PropTypes.bool,
    clQuerys: PropTypes.shape({}),
    collection: CollectionPropTypes,
    problemId: PropTypes.string.isRequired,
    collectionId: PropTypes.string.isRequired,
    getCollectionProblem: PropTypes.func.isRequired,
    setLoadingStatus: PropTypes.func.isRequired,
    submitCode: PropTypes.func.isRequired,
    getCollectionProblemEntity: PropTypes.func.isRequired,
    canManageProblem: PropTypes.bool.isRequired,
    canJudgeSubmit: PropTypes.bool.isRequired,
    markLikes: PropTypes.func,
    collectionProblem: CollectionProblemPropTypes,
  };

  static defaultProps = {
    clQuerys: {},
    markLikes: noop,
    collection: null,
    isLoading: true,
    collectionProblem: null,
  };

  state = {
    hasFetchEntity: false,
    toShow: false,
    details: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const backToList = () => {
      nextProps.history.push(buildPath(
        CollectionViewsRoutes.PROBLEMS_LIST,
        null,
        nextProps.match.params,
        nextProps.clQuerys
      ));
    };
    if (!isWeJudgeUUID(nextProps.problemId)) {
      backToList();
    }
    if (!prevState.hasFetchEntity && !nextProps.collectionProblem) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getCollectionProblem({
        collectionId: nextProps.collectionId,
        id: nextProps.problemId,
        onError: () => {
          backToList();
        },
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        hasFetchEntity: true,
      };
    }
    if (!isEmpty(get(nextProps.collectionProblem, 'problem'))) {
      const problemType = ProblemTypeEnum.enumValueOf(get(nextProps.collectionProblem, 'problem.problem_type', 0));
      if (!problemType
        || !(problemType === ProblemTypeEnum.CODE || problemType === ProblemTypeEnum.CODE_FILL)) {
        backToList();
      }
    }
    return null;
  }

  menuItems = [
    {
      key: 'content',
      title: '题目内容',
      path: CollectionProblemViewRoutes.CONTENT,
      icon: <FileTextOutlined />,
    },
    // {
    //   key: 'submit',
    //   title: '提交评测',
    //   path: CollectionProblemViewRoutes.SUBMIT_CODE,
    //   icon: 'code-o',
    // },
    {
      key: 'judge_status',
      title: '我的提交',
      path: CollectionProblemViewRoutes.JUDGE_STATUS,
      icon: <ClockCircleOutlined />,
    }
    // {
    //   key: 'statistics',
    //   title: '统计数据',
    //   path: CollectionProblemViewRoutes.STATISTICS,
    //   icon: 'line-chart',
    // },
    // {
    //   key: 'management',
    //   title: '管理题目',
    //   path: CollectionProblemViewRoutes.MANAGEMENT,
    //   icon: 'setting',
    // },
    // {
    //   key: 'sharelist',
    //   title: '题解',
    //   path: CollectionProblemViewRoutes.SHARELIST,
    //   icon: <EditOutlined />,
    // },
    // {
    //   key: 'tags',
    //   title: '标签管理',
    //   path: CollectionProblemViewRoutes.SOLUTIONSTAGS,
    //   icon: <SettingOutlined />,
    // }
  ];

  judgeStatusView = React.createRef();

  matchSubItemKey = () => {
    return get(matchPath(this.props.location.pathname, {
      path: CollectionProblemViewRoutes.SUBITEMS,
    }), 'params.subItemName', 'content');
  };

  menuItemFilter = (item) => {
    if (item.key === 'submit' && !this.props.canJudgeSubmit) {
      return false;
    } if (item.key === 'management' && !this.props.canManageProblem) {
      return false;
    } if (item.key === 'tags' && !this.props.canManageProblem) {
      return false;
    }
    return true;
  };

  backToList = () => {
    const subItemKey = this.matchSubItemKey();
    if (subItemKey === 'management') {
      this.props.history.push(buildPath(
        CollectionProblemViewRoutes.CONTENT,
        null,
        this.props.match.params
      ));
      return;
    }
    this.props.history.push(buildPath(
      CollectionViewsRoutes.PROBLEMS_LIST,
      null,
      this.props.match.params,
      this.props.clQuerys
    ));
  };

  toShowSolutiondetails = (item) => {
    this.setState({
      toShow: true,
      details: item,
    });
  }

  toCloseSolutiondetails = () => {
    this.setState({
      toShow: false,
    });
  }

  handleGotoManagement = () => {
    this.props.history.push(buildPath(
      CollectionProblemViewRoutes.MANAGEMENT,
      null,
      this.props.match.params
    ));
  };

  handleProblemEntityChange = (problemId) => {
    this.props.getCollectionProblemEntity({
      collectionId: this.props.collectionId,
      id: problemId,
    });
  };

  handleReceivedNewJudgeStatus = () => {
    const isJudgeStatusList = matchPath(
      window.location.pathname,
      CollectionProblemViewRoutes.JUDGE_STATUS
    );
    if (isJudgeStatusList && this.judgeStatusView && this.judgeStatusView.current) {
      this.judgeStatusView.current.refreshList && this.judgeStatusView.current.refreshList();
    }
  };

  renderMenuItem = ({
    key,
    icon,
    title,
    path,
  }) => (
    <Menu.Item key={key}>
      <Link
        to={buildPath(path, null, this.props.match.params)}
      >
        {icon} {title}
      </Link>
    </Menu.Item>
  );

  renderRouterView = () => {
    const { collectionProblem } = this.props;
    const problem = get(collectionProblem, 'problem', {});
    return <Switch>
      <Route
        path={CollectionProblemViewRoutes.CONTENT}
        render={() => {
          return <CodeProblemBody
            problem={problem}
            canManageProblem={this.props.canManageProblem}
            onProblemEntityChange={this.handleProblemEntityChange}
          />;
        }}
        exact
      />
      <Route
        path={CollectionProblemViewRoutes.JUDGE_STATUS}
        render={(props) => {
          return <JudgeStatusView
            {...props}
            columnStyle="minimal"
            collectionId={this.props.collectionId}
            problemId={problem.id}
            filterOwner
            ref={this.judgeStatusView}
          />;
        }}
        exact
      />
      <Route
        path={CollectionProblemViewRoutes.SHARELIST}
        render={
          () => {
            return <Solutions 
              {...this.props} 
              showDetails={this.toShowSolutiondetails}
            />;
          }
        }
        exact
      />
      <Route
        path={CollectionProblemViewRoutes.SOLUTIONSTAGS}
        render={
          () => {
            return (<SolutionsTags />);
          }
        }
        exact
      /> 
    </Switch>;
  };

  renderManagementView = () => {
    const { collectionProblem } = this.props;
    const problem = get(collectionProblem, 'problem', {});
    return <Switch>
      {this.props.canManageProblem && <Route
        path={CollectionProblemViewRoutes.MANAGEMENT}
        render={props => <CodeProblemManagementView
          {...props}
          problem={problem}
          collectionProblem={collectionProblem}
          collectionId={this.props.collectionId}
        />}
      />}
      <Route
        render={() => <Redirect
          to={buildPath(CollectionProblemViewRoutes.CONTENT, null, this.props.match.params)}
        />}
      />
    </Switch>;
  };

  renderProblemView = () => {
    const { collectionProblem } = this.props;
    const subItemKey = this.matchSubItemKey();
    const problem = get(collectionProblem, 'problem', {});
    const options = get(problem, 'options', {});
    const problemType = ProblemTypeEnum.enumValueOf(get(problem, 'problem_type'));
    const tags = [];
    if (!get(problem, 'available', false)) {
      tags.push(<Tag key="available" color="#ff7875">评测未启用</Tag>);
    }
    tags.push(<Tag key="number" color="grey">#{get(problem, 'number', '0')}</Tag>);
    tags.push(<Tag key="color" color={problemType.color}>{problemType.desc}</Tag>);
    return (
      <div className="wejudge_code_problems">
        <PageHeader
          onBack={this.backToList}
          title={get(problem, 'title', '')}
          tags={tags}
          extra={this.props.canManageProblem ? [
            <Button
              key="manage"
              type="primary"
              onClick={this.handleGotoManagement}
            >
              <SettingOutlined /> 管理题目
            </Button>
          ] : []}
        >
          <Row className="pager-extra-info" type="flex">
            <Descriptions size="small" column={5}>
              <Descriptions.Item label="评测模式">
                {get(SpecialJudgeEnum.enumValueOf(get(options, 'judge_options.special_judge', 0)), 'desc')}
              </Descriptions.Item>
              <Descriptions.Item label="题目难度">
                <Tooltip title={get(ProblemDifficultyEnum.enumValueOf(get(problem, 'difficulty', 0)), 'desc')}>
                  {get(ProblemDifficultyEnum.enumValueOf(get(problem, 'difficulty', 0)), 'desc')}
                </Tooltip>
              </Descriptions.Item>
              <Descriptions.Item label="发布者">
                {get(problem, 'author.nickname')}
              </Descriptions.Item>
              <Descriptions.Item label="创建时间">
                {formatTimeFromNow(problem.create_time)}
              </Descriptions.Item>
              <Descriptions.Item label="更新时间">
                {formatTimeFromNow(problem.update_time)}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </PageHeader>
        {subItemKey === 'management' ? this.renderManagementView() : <VerticalSplitContainer
          left={<>
            <Menu selectedKeys={[subItemKey]} mode="horizontal">
              {this.menuItems.filter(this.menuItemFilter).map(item => this.renderMenuItem(item))}
            </Menu>
            {this.renderRouterView()}
          </>}
          right={subItemKey === 'solution' ?  this.state.toShow ? <CommentList
            {...this.props}
            details={this.state.details}  
            toShowSolutiondetails={this.toCloseSolutiondetails}
            markSolutionLikes={this.props.markLikes}
          /> 
            : <Markdown {...this.props} /> : <SubmitCodeView
              collectionId={this.props.collectionId}
              problem={problem}
              collection={this.props.collection}
              collectionProblem={collectionProblem}
              languageMask={collectionProblem.code_language}
              onReceivedNewJudgeStatus={this.handleReceivedNewJudgeStatus}
            />}
        />}
      </div>
    );
  };

  render() {
    const { collectionProblem } = this.props;
    const problem = get(collectionProblem, 'problem', {});
    return !isEmpty(collectionProblem) && !isEmpty(problem) ? this.renderProblemView()
      : (<CenterSpin size="large" tip="加载中..." spinning={this.props.isLoading} />);
  }
}

export default CodeProblemView;
