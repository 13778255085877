import React from 'react';
import {
  Row, Col, Card, Avatar, Divider 
} from 'antd';
import DevCPPIcon from 'images/icons/devcpp.jpg';
import CodeRunnerIcon from 'images/icons/coderunner.jpg';
import VsCodeIcon from 'images/icons/vscode.png';
import JetBrainsIcon from 'images/icons/jetbrains.jpeg';
import SublimeIcon from 'images/icons/sublime.jpeg';
import EclipseIcon from 'images/icons/eclipse.jpeg';

const { Meta } = Card;

class HelperToolsView extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  render() {
    return <div className="helper-tools-view">
      <Divider orientation="left">推荐IDE</Divider>
      <div className="ides-panel">
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <Meta
                avatar={<Avatar src={VsCodeIcon} shape="square" size="large" />}
                title="Visual Studio Code"
                description="Visual Studio Code是一个为构建和调试现代web和云应用程序而重新定义和优化的代码编辑器。Visual Studio Code是自由的免费的软件，可以在任何您最喜欢的平台上使用，如：Linux、macOS和Windows。"
              />
            </Card>

          </Col>
          <Col span={8}>
            <Card>
              <Meta
                avatar={<Avatar src={DevCPPIcon} shape="square" size="large"  />}
                title="Dev C++ 5.10"
                description="Windows下很好用的C语言/C++开发环境，自带编译器，适合初学者使用。"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Meta
                avatar={<Avatar src={CodeRunnerIcon} shape="square" size="large"  />}
                title="CodeRunner 2.11"
                description="MacOS下一个非常好用的软件，苹果电脑的同学可以下载。仅学习交流，请支持正版。"
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <Meta
                avatar={<Avatar src={JetBrainsIcon} shape="square" size="large" />}
                title="JetBrains"
                description="JetBrains是来自捷克的一家尖端软件供应商，专门开发智能开发工具，包括领先的Java IDE IntelliJ IDEA和Kotlin编程语言。JetBrains全家桶覆盖了WeJudge所能够提供的所有评测语言，并且对于学生，使用学校邮箱认证还能免费使用这些IDE全家桶哦！"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Meta
                avatar={<Avatar src={SublimeIcon} shape="square" size="large" />}
                title="Sublime Text 3"
                description="Sublime Text 3是用于代码、标记和散文的高级文本编辑器。你会喜欢上它简洁的用户界面，非凡的功能和惊人的性能。收费软件，请支持正版哦！"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Meta
                avatar={<Avatar src={EclipseIcon} shape="square" size="large" />}
                title="Eclipse"
                description="Eclipse 是一个开放源代码的、基于Java的可扩展开发平台的老牌IDE，而且还是免费的哦。"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>;
  }
}

export default HelperToolsView;
