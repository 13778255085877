import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleTwoTone, UserOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { KEY_CODES } from 'scripts/common/constants/keycodes';

class ForgetPassword extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    // changePage: PropTypes.func.isRequired,
    doSendEmailForget: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    username: '',
    pendingSubmit: false,
    sended: false,
  };

  handleUserNameChange = (sender) => {
    const username = sender.target.value;
    this.setState({
      username,
    });
  };

  handleSendEmail = () => {
    this.setState({ pendingSubmit: true });
    const {
      protocol,
      hostname,
      port,
    } = window.location;
    this.props.doSendEmailForget({
      email: this.state.username,
      host: `${protocol}//${hostname}${port ? `:${port}` : ''}`,
      onSuccess: () => {
        this.setState({
          sended: true,
        });
      },
      onError: () => {
        this.setState({
          pendingSubmit: false,
        });
      },
    });
  };

  handleEnterSubmit = (e) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      this.handleSendEmail();
    }
  };

  render() {
    return this.state.sended ? <div className="forget-password">
      <div className="success-msg">
        <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 64 }} />
        <h4>已发送一封重置密码验证邮件至您的邮箱，请您注意查收!</h4>
      </div>
    </div> : <div className="forget-password">
      <Input
        className="input"
        prefix={<UserOutlined />}
        placeholder="Email"
        size="large"
        tabIndex={1}
        value={this.state.username}
        onChange={this.handleUserNameChange}
        onKeyDown={this.handleEnterSubmit}
      />
      <Button
        disabled={this.state.pendingSubmit}
        loading={this.state.pendingSubmit}
        size="large"
        type="primary"
        onClick={this.handleSendEmail}
        className="action-btn"
        tabIndex={5}
      >
        发送邮件
      </Button>
    </div>;
  }
}

export default ForgetPassword;
