import React from 'react';
import { Modal, Alert } from 'antd';
import CodeLanguageSelector from 'scripts/common/widgets/code_language';
import PropTypes from 'prop-types';

class TestCasesMaker extends React.PureComponent {
  static propTypes = {
    onOk: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    language: 1,
  };

  onOk = (model, state) => {
    if (this.state.isLoading) return;
    this.setState({
      isLoading: true,
    }, () => {
      this.props.onOk(model, state);
    });
  };

  // 切换语言
  handleLanguageSelectorChange = (value) => {
    this.setState({
      language: parseInt(value, 10),
    });
  };

  render() {
    return (<Modal
      wrapClassName="test-cases-maker-dialog"
      {...this.props}
      onOk={this.onOk}
      title="自动生成测试数据"
    >
      <div style={{ marginBottom: 24 }}>
        <CodeLanguageSelector
          language={this.state.language}
          onChange={this.handleLanguageSelectorChange}
        />
      </div>
      <Alert
        message="此操作前，请确保已经设置过测试数据的【输入】，并选择一个已经设置过答案的编程语言，系统将运行该答案代码获取测试数据的【输出】。启用了特殊评测的题目不支持自动生成数据。"
      />
    </Modal>);
  }
}

export default TestCasesMaker;
