import 'styles/problem/widgets/problem_edit.scss';

import React from 'react';
import {  noop } from 'lodash';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import AsgnInfoEditor from 'scripts/apps/education/asgn/widgets/asgn_editor';

export class AddAsgnModal extends React.PureComponent {
  state= {
    asgn: {
      title: '',
      description: '',
      full_score: 0,
      code_language: 0,
      hide_student_code: false,
      hide_problem_title: false,
      public_answer_at: 0,
      access_control: {
        black_list: [],  //[xx, xx, xx, xx] xxx是int类型的学生的id
        groups: [{
          students: 1,
          start_time: 0,
          end_time: 0,
        }],
        course_changing: {},
        arrangement: {},
      },
    },
  };

  render() {
    return (
      <Modal
        wrapClassName="add-problem-dialog"
        {...this.props}
        title="发布作业"
      >
        <AsgnInfoEditor
          task={this.state.asgn}
          handleChange={(a) => { this.setState({ asgn: a }); }}
        />
      </Modal>
    );
  }
}
