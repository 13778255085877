import 'styles/education/course/contest_statistics.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ContestListTable from 'scripts/apps/contest/contest_list/contest_list_table';
import {
  get, isEqual, pick
} from 'lodash';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { ContestActions, ContestSelectors } from 'scripts/common/logic/contest';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationParams } from 'scripts/common/constants/global';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { Input } from 'antd';
import { CourseRoutes } from 'scripts/apps/routes';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';

const LOADING_STATUS_KEY = 'course_contest_statistics_list';

const mapDispatchToProps = dispatch => bindActionCreators({
  getContestList: ContestActions.getContestList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const pager = ContestSelectors.contestsListViewPagerSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page', 'key']),
  };
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  return {
    isLoading,
    paginationParams: pager || props.paginationParams,
    queryParams,
    contestsList: ContestSelectors.contestsListViewSelector(state),
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CourseContestStatistics extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);

    const query = pick(
      nextProps.queryParams,
      ['limit', 'page', 'key']
    );
    nextProps.getContestList({
      query,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    isLoading: PropTypes.bool,
    contestsList: PropTypes.arrayOf(ContestPropTypes),
    queryParams: PropTypes.shape({}),
    paginationParams: PaginationPropTypes,
  };

  static defaultProps = {
    isLoading: false,
    paginationParams: { ...PaginationParams },
    queryParams: {},
    contestsList: [],
  };

  state = {
    keyword: get(this.props, 'queryParams.key', ''),
    queryParams: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), prevState.queryParams)) {
      const { queryParams } = nextProps;
      CourseContestStatistics.fetchList(nextProps);
      return {
        keyword: get(nextProps, 'queryParams.key', ''),
        queryParams,
      };
    }
    return null;
  }

  handleContestClick = (contestId) => () => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.STATISTICS_CONTEST_VIEW,
      {
        contestId,
      },
      match.params
    ));
  };

  handleContestSearch = (keyword) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.STATISTICS_CONTEST_LIST,
      {},
      match.params,
      {
        ...pick(this.state.queryParams, ['limit']),
        key: keyword,
      }
    ));
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.STATISTICS_CONTEST_LIST,
      {},
      match.params,
      {
        ...pick(this.props.queryParams, ['key']),
        limit: pageSize,
        page,
      }
    ));
  };

  render() {
    return (
      <div className="course-contest-statistics">
        <div className="statistics-action-bar">
          <Input.Search
            style={{ width: '320px' }}
            value={this.state.keyword}
            onChange={(e) => this.setState({ keyword: e.target.value })}
            onSearch={this.handleContestSearch}
          />
        </div>
        <ContestListTable
          type="single"
          paginationParams={this.props.paginationParams}
          isLoading={this.props.isLoading}
          onContestClick={this.handleContestClick}
          contestList={this.props.contestsList}
          queryParams={this.state.queryParams}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

export default CourseContestStatistics;
