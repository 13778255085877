import Markdowm from 'scripts/common/widgets/mdeditor';
import PropTypes from 'prop-types';
import {  noop, set } from 'lodash';
import { RocketOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { PureComponent } from 'react';
import Tabselect from './tags_selector';

class ReEditor extends PureComponent {
    static propTypes = {
      collectionId: PropTypes.string.isRequired,
      problemId: PropTypes.string.isRequired,
      adjustProblemSolution: PropTypes.func, 
      handleCancel: PropTypes.func,
      solution: PropTypes.PropTypes.shape({
        tags: PropTypes.arrayOf(PropTypes.shape({
          language: PropTypes.bool,
          id: PropTypes.string,
          name: PropTypes.string,
        })),
        id: PropTypes.number,
        content: PropTypes.string,
        title: PropTypes.string,
      }),
      fetchTagsList: PropTypes.func,
      TagsList: PropTypes.shape({}),
    };

      static defaultProps = {
        handleCancel: noop,
        fetchTagsList: noop,
        adjustProblemSolution: noop,
        solution: {},
        TagsList: {},
      };
     
      state ={
        solution: {
          tags: this.props.solution.tags,
          content: this.props.solution.content,
          title: this.props.solution.title,
        },
        tagsList: this.props.solution.tags,
      }

      componentWillReceiveProps(nextProps) {
        const data = {
          ...this.state.solution,
          content: nextProps.solution.content,
          title: nextProps.solution.title,
          tags: nextProps.solution.tags.id, 
        };
        this.setState({
          solution: data,
          tagsList: nextProps.solution.tags,
        });
      }

      confirm() {
        Modal.confirm({
          mask: false,
          title: 'WEJUDGE',
          content: '确认修改题解吗',
          okText: '确认',
          cancelText: '取消',
          onOk: () => this.handleSubmitBtn(),
        });
      }

      setTabsTitle = (title) => {
        this.setState(set({ solution: { ...this.state.solution } }, 'solution.title', title));
      }

      setTabs = (tabs) => {
        const id = tabs.map((arr) => { return arr.id; });
        this.setState(set({ solution: { ...this.state.solution } }, 'solution.tags', id));
        this.setState({
          tagsList: tabs,
        });
      }

       handleEditorSoultion = (text) => {
         this.setState(set({ solution: { ...this.state.solution } }, 'solution.content', text));
       }

       handleSubmitBtn() {
         this.props.adjustProblemSolution({
           solution: this.state.solution,
           collectionId: this.props.collectionId,
           problemId: this.props.problemId,
           solutionId: this.props.solution.id,
         }); 
         this.props.handleCancel();
       }

       render() {   
         return (
           <div>
             <Tabselect
               selectTabs={this.setTabs} 
               TabsValue={this.state.tagsList}
               TitleValue={this.state.solution.title}
               setTitle={this.setTabsTitle}
               Tab={Object.values(this.props.TagsList)}
               fetchTagsList={this.props.fetchTagsList}
             />
             <div className="problem_answer_editor">
               <Markdowm
                 onChange={this.handleEditorSoultion} 
                 defaultValue={this.props.solution.content}
                 height="60vh"
               />
               <div className="footer_control_bar">
                 <Button
                   type="primary"
                   onClick={() => this.confirm()}
                 >
                   <RocketOutlined />修改题解
                 </Button>
               </div>
             </div>
           </div>
         );
       }
}
export default ReEditor;
