import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, debounce } from 'lodash';
import {
  Modal, Select, Tag, notification 
} from 'antd';
import update from 'immutability-helper';
import { tagColorHash } from 'scripts/common/utils/tag_hash';
import { INPUT_SEARCH_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

class ProblemTagsFilterDialog extends React.PureComponent {
  static propTypes = {
    collectionId: PropTypes.string.isRequired,
    searchTags: PropTypes.func,
    getTagsList: PropTypes.func,
    defaultKeys: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    searchTags: noop,
    getTagsList: noop,
    defaultKeys: [],
  };

  state = {
    tagsCounter: [],
    tagSuggest: [],
    selectedKeys: this.props.defaultKeys,
    searchText: '',
  };

  componentDidMount() {
    this.props.getTagsList({
      collectionId: this.props.collectionId,
      onSuccess: (tags) => {
        this.setState({
          tagsCounter: tags,
        });
      },
    });
  }

  findTags = debounce(() => {
    this.props.searchTags({
      problemId: '0',
      standalone: true,
      keyword: this.state.searchText,
      onSuccess: (tags) => {
        this.setState({
          tagSuggest: tags,
        });
      },
    });
  }, INPUT_SEARCH_DEBOUNDCE_TIME);

  handleSearch = (key) => {
    this.setState({
      searchText: key,
    }, () => {
      this.findTags();
    });
  };

  handleChange = (keys) => {
    if (keys.length > 10) {
      notification.error({ message: '最多只能筛10个标签' });
      return;
    }
    this.setState({
      selectedKeys: keys,
    });
  };

  handleAddTag = tag => () => {
    const { selectedKeys = [] } = this.state;
    if (selectedKeys.length >= 10) {
      notification.error({ message: '最多只能筛10个标签' });
      return;
    }
    const rel = selectedKeys.find(item => item === tag.name);
    if (!rel) {
      this.setState(update(this.state, {
        selectedKeys: {
          $push: [tag.name],
        },
      }));
    }
  };

  render() {
    const {
      selectedKeys = [],
      tagsCounter = [],
      tagSuggest = [],
    } = this.state;
    return <Modal
      className="collection-tag-filter-dialog"
      title="按标签筛选"
      {...this.props}
      width={720}
    >
      <div>
        <Select
          allowClear
          mode="tags"
          showSearch
          showArrow={false}
          size="large"
          autoFocus
          style={{ width: '100%' }}
          value={selectedKeys}
          filterOption={false}
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          notFoundContent={null}
        >
          {tagSuggest.map((tag) => {
            return <Select.Option
              value={tag.name}
              key={tag.id}
            >
              {tag.name}
            </Select.Option>;
          })}
        </Select>
        <div className="tags_counter_container">
          {tagsCounter.map((tag) => {
            return <Tag
              onClick={this.handleAddTag(tag)}
              color={tagColorHash(tag.name)}
              key={tag.id}
            >
              {tag.name}({get(tag, 'count', 0)})
            </Tag>;
          })}
        </div>
      </div>
    </Modal>;
  }
}

export default ProblemTagsFilterDialog;
