import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { noop } from 'lodash';
import { Modal, Checkbox, Alert } from 'antd';
import { showModal } from 'scripts/common/widgets/modal';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';

class DelProblemModal extends React.PureComponent {
  static propTypes ={
    problemId: PropTypes.number.isRequired,
    collectionId: PropTypes.string.isRequired,
  };

  state = {
    agree: false,
  };

  renderMessage = () => {
    return (<ul className="message_ul">
      <li>题目信息将会被永久删除，无法恢复。</li>
      <li>所有与该题目关联的做题信息、统计、评测数据等都将会被连带删除。</li>
      <li>题目ID不再存在，新增题目也将不能再使用当前的题目ID。</li>
      <li>测试数据、运行数据等存放于服务器文件系统的内容将不会被删除。</li>
      <li>当前操作仅能由管理员或题目发布者执行。</li>
    </ul>);
  };

  render() {
    return (
      <Modal
        wrapClassName="del-problem-dialog"
        {...this.props}
        title="删除题目"
        okButtonProps={{ disabled: !this.state.agree }}
      >
        <div className="delete_problem_view">
          <Alert
            message="你应该清楚这个操作的风险！"
            description={this.renderMessage()}
            type="error"
            iconType="info-circle-o"
            showIcon
          />
          <div className="control">
            <Checkbox
              style={{ marginLeft: 8 }}
              disabled={this.state.agree}
              checked={this.state.agree}
              onChange={({ target }) => { this.setState({ agree: target.checked }); }}
            >我已阅读须知</Checkbox>
          </div>

        </div>
      </Modal>
    );
  }
}

export default DelProblemModal;
