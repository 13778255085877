import 'styles/contest/manager/fields.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, set } from 'lodash';
import {
  Form, Button, Input, InputNumber, Switch, Table, Typography, message, Row, Col 
} from 'antd';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import EnrollPreview from './enroll_preview';

const AccountFields = (props) => {
  const {
    accountFields,
    onChangeSubmit,
  } = props;

  let changeList = [];
  // 转换数组
  Object.values(accountFields).forEach((item) => {
    changeList.push(item);
  });
  // 执行排序
  changeList = changeList.sort((a, b) => {
    return a.index < b.index ? -1 : 1;
  });

  const [list, setList] = useState(changeList);
  useEffect(() => {
    onChangeSubmit(list);
  }, [list]);

  const handleDelClick = index => () => {
    setList(list.filter((_, idx) =>  index !== idx));
  };

  const handleAddClick = index => () => {
    setList([...list, {
      index,
      name: `新字段${index}`,
      default: '',
      placename: '',
      placeholder: '',
      regex: '',
      min_length: 0,
      max_length: 0,
      require: false,
      available: true,
    }]);
  };

  const handleTextFieldChange = (index, keyName) => ({ target }) => {
    const raw = JSON.parse(JSON.stringify(list));
    set(raw, `${index}.${keyName}`, target.value);
    setList(raw);
  };

  const handleValueFieldChange = (index, keyName) => (value) => {
    const raw = JSON.parse(JSON.stringify(list));
    set(raw, `${index}.${keyName}`, value);
    setList(raw);
  };

  const conflectCheck = (itemIndex, arrIndex) => () => {
    const curName = get(list[arrIndex], 'name', '');
    if (!curName) {
      // 没有填写的时候直接移除这个字段
      handleDelClick(arrIndex)();
    }
    const conf = list.find(item => item.name === curName && item.index !== itemIndex);
    if (conf) {
      // 字段名冲突的时候也直接移除
      handleDelClick(arrIndex)();
      message.error('字段名重复，不允许添加');
    }
  };

  const COLUMNS = [
    {
      title: '#',
      dataIndex: 'index',
      width: 60,
    },
    {
      title: '字段名',
      dataIndex: 'name',
      width: 180,
    },
    {
      title: '必填？',
      dataIndex: 'required',
      align: 'center',
      width: 80,
    },
    {
      title: '启用？',
      dataIndex: 'available',
      align: 'center',
      width: 80,
    },
    // {
    //   title: '验证规则',
    //   dataIndex: 'regex',
    //   align: 'center',
    //   width: 140,
    // },
    {
      title: '最大长度(0表示不限制)',
      dataIndex: 'length',
      align: 'center',
      width: 140,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      align: 'center',
      width: 60,
    }
  ];

  const renderTable = () => {
    const datas = list.map((item, index) => {
      if (!item) return null;
      const key = get(item, 'name', '');
      return {
        key: index,
        index: get(item, 'index', 0),
        name: <Input
          placeholder="字段名(8个字内)"
          disabled={key === '教练名' || key === '学校名'}
          value={get(item, 'name')}
          onBlur={conflectCheck(get(item, 'index', 0), index)}
          onChange={handleTextFieldChange(index, 'name')}
          maxLength={8}
        />,
        required: <Switch
          onChange={handleValueFieldChange(index, 'require')}
          checked={get(item, 'require', false)}
        />,
        available: <Switch
          onChange={handleValueFieldChange(index, 'available')}
          checked={get(item, 'available', true)}
        />,
        // regex: get(item, 'default'),
        length: <>
          <InputNumber
            min={0}
            value={get(item, 'max_length')}
            placeholder="0表示不限"
            onChange={handleValueFieldChange(index, 'max_length')}
          />
        </>,
        extra_fields: <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="替换名">
                <Input
                  placeholder="用于替换字段的名称"
                  value={get(item, 'placename')}
                  maxLength={8}
                  onChange={handleTextFieldChange(index, 'placename')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="占位文字">
                <Input
                  placeholder="不设置的话将使用默认的"
                  value={get(item, 'placeholder')}
                  maxLength={64}
                  onChange={handleTextFieldChange(index, 'placeholder')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="默认值">
                <Input
                  placeholder="默认值(64字内)"
                  value={get(item, 'default')}
                  maxLength={64}
                  onChange={handleTextFieldChange(index, 'default')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="正则约束">
                <Input
                  placeholder="约束字段内容"
                  value={get(item, 'regex')}
                  onChange={handleTextFieldChange(index, 'regex')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>,
        operation: (key === '教练名' || key === '学校名')
          ? null : <Button type="danger" size="small" onClick={handleDelClick(index)}>删除</Button>,
      };
    });

    datas.push({
      key: 'craete',
      name: <Button onClick={handleAddClick(list.length + 1)} type="primary">新增</Button>,
    });
    return datas;
  };

  return (
    <div className="contest-manage-account-fields">
      <div className="contest-account-fields-list">
        <Table
          size="middle"
          bordered
          columns={COLUMNS}
          dataSource={renderTable()}
          pagination={false}
          expandedRowRender={(record) => {
            return record.key === 'create' ? null : record.extra_fields;
          }}
        />
      </div>
      <div className="contest-account-fields-preview">
        <div className="preview-title">
          <Typography.Title level={3}>表单预览</Typography.Title>
        </div>
        <EnrollPreview contest={props.contest} accountFields={list} />
      </div>
    </div>
  );
};

AccountFields.propTypes = {
  contest: ContestPropTypes.isRequired,
  accountFields: PropTypes.shape({}).isRequired,
  onChangeSubmit: PropTypes.func.isRequired,
};

export default AccountFields;
