import 'styles/account/space.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import {
  Spin, Menu
} from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountPropTypes, AuthPropTypes } from 'scripts/common/prop-types/account';
import { RepositoryPropType } from 'scripts/common/prop-types/repository';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { AccountActions, AccountSelectors } from 'scripts/common/logic/account';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { RepositoryAction, RepositorySelectors } from 'scripts/common/logic/repository';
import { bindActionCreators } from 'redux';
import {
  withRouter, Switch, Route, Redirect 
} from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import DataKeys from 'scripts/common/constants/data_keys';
import AccountInfoCard from 'scripts/apps/account/space/widgets/info_card';
import MyRepositoryCard from 'scripts/apps/account/space/widgets/repo_card';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { AccountRoutes } from 'scripts/apps/routes';
import { buildPath } from 'scripts/common/utils/location_helper';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';
import AccountSpaceHomeView from './home';
import AccountSpaceCourseView from './courses';
import SpaceProfileView from './profile';
import AccountSpaceSafetyView from './safety';
import SpaceCodeProblemsSolutionsView from './code_solutions';

const mapStateToProps = (state, props) => {
  const { match } = props;
  const accountId = get(match, 'params.accountId', 'my');
  const subPageKey = get(match, 'params.subItemKey', 'index');
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(
    state, DataKeys.LOADING_STATUS_KEY.ACCOUNT_SPACE_VIEW
  );
  if (accountId === 'my') {
    const courseList = AccountSelectors.getDashboardCourseList(state) || [];
    const backlogList = AccountSelectors.getDashboardBacklogList(state) || [];
    const myRepository = RepositorySelectors.getMyRepositoryEntity(state);
    const account = get(props, 'auth.account', null);
    return {
      account,
      isLoading,
      accountId,
      subPageKey,
      courseList,
      backlogList,
      myRepository,
      signInDaysDatas: AccountSelectors.getSignInDatas(state),
    };
  }
  const account = AccountSelectors.getAccountEntity(state)(accountId);
  return {
    guest: true,
    account,
    accountId,
    subPageKey,
    isLoading,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getDashboardData: AccountActions.getDashboardData,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getAccountEntity: AccountActions.getAccountEntity,
  getAccountInfo: AccountActions.getAccountInfo,
  getAccountSignIn: AccountActions.getAccountSignIn,
  doAccountSignIn: AccountActions.doAccountSignIn,
  viewErrorMessage: WeJudgeActions.viewErrorMessage,
  createRepository: RepositoryAction.createRepository,
}, dispatch);

@LoginRequire
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AccountSpaceView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    guest: PropTypes.bool,
    auth: AuthPropTypes.isRequired,
    account: AccountPropTypes,
    myRepository: RepositoryPropType,
    accountId: PropTypes.string,
    backlogList: PropTypes.arrayOf(PropTypes.shape({})),
    courseList: PropTypes.arrayOf(CoursePropTypes),
    isLoading: PropTypes.bool,
    subPageKey: PropTypes.string,
    getDashboardData: PropTypes.func,
    setLoadingStatus: PropTypes.func,
    getAccountSignIn: PropTypes.func,
    doAccountSignIn: PropTypes.func,
    getAccountInfo: PropTypes.func,
  };

  static defaultProps = {
    guest: false,
    account: null,
    myRepository: null,
    courseList: null,
    accountId: 'my',
    backlogList: [],
    isLoading: false,
    subPageKey: 'index',
    getDashboardData: noop,
    setLoadingStatus: noop,
    getAccountSignIn: noop,
    doAccountSignIn: noop,
    getAccountInfo: noop,
  };

  state = {
    hasFetchDashboard: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchDashboard && get(nextProps.auth, 'account')) {
      nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.ACCOUNT_SPACE_VIEW, true);
      if (nextProps.accountId !== 'my') {
        nextProps.getAccountInfo({
          accountId: nextProps.accountId,
        });
      } else {
        nextProps.getAccountEntity({
          accountId: get(nextProps.auth, 'account.id'),
        });
      }
      nextProps.getDashboardData({
        onComplete: () => {
          nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.ACCOUNT_SPACE_VIEW, false);
        },
      });
      return {
        hasFetchDashboard: true,
      };
    }
    return null;
  }

  handleTabChange = ({ key }) => {
    this.props.history.push(buildPath(AccountRoutes.SPACE.SUBITEMS, {
      accountId: this.props.accountId,
      subItemKey: key,
    }));
  };

  render() {
    const { account } =  this.props;
    if (!account && this.props.isLoading) {
      return <div className="account-space-view">
        <Spin tip="载入中" className="loading-spin" />
      </div>;
    }
    if (!account) {
      return <div style={{ width: '100%' }}>
        <WeJudge404NotFoundComponent noBorder />
      </div>;
    }
    const isStudent = get(account, 'role') === AccountRoleEnum.STUDENT.value;
    return <div className="account-space-view">
      <div className="sapce-left-wrap">
        <AccountInfoCard
          account={this.props.account}
        />
        {account && !isStudent && !this.props.guest && <MyRepositoryCard
          account={this.props.account}
          myRepository={this.props.myRepository}
        />}
      </div>
      <div className="sapce-right-wrap">
        <Menu
          mode="horizontal"
          selectedKeys={[this.props.subPageKey]}
          onSelect={this.handleTabChange}
        >
          {!this.props.guest && <Menu.Item key="index">首页</Menu.Item>}
          {!this.props.guest && <Menu.Item key="courses">课程</Menu.Item>}
          {/*<Menu.Item key="notes">笔记</Menu.Item>*/}
          <Menu.Item key="code_solution">解题</Menu.Item>
          {!this.props.guest && <Menu.Item key="profile">设置</Menu.Item>}
          {!this.props.guest && <Menu.Item key="safety">安全</Menu.Item>}
          {/*<Menu.Item key="payment_order">订单</Menu.Item>*/}
        </Menu>
        <Switch>
          {!this.props.guest && <Route
            path={AccountRoutes.SPACE.INDEX}
            render={(props) => {
              return <AccountSpaceHomeView
                {...props}
                account={account}
                backlogList={this.props.backlogList}
              />;
            }}
            exact
          />}
          {!this.props.guest && <Route
            path={AccountRoutes.SPACE.COURSES}
            render={(props) => {
              return <AccountSpaceCourseView
                {...props}
                account={account}
                courseList={this.props.courseList}
              />;
            }}
          />}
          <Route
            path={AccountRoutes.SPACE.CODE_SOL}
            render={(props) => {
              return <SpaceCodeProblemsSolutionsView
                {...props}
                account={account}
              />;
            }}
          />
          {!this.props.guest && <Route
            path={AccountRoutes.SPACE.PROFILE}
            render={(props) => {
              return <SpaceProfileView
                {...props}
                account={account}
              />;
            }}
          />}
          {!this.props.guest && <Route
            path={AccountRoutes.SPACE.SAFETY}
            render={(props) => {
              return <AccountSpaceSafetyView
                {...props}
                account={account}
              />;
            }}
          />}
          <Route
            path={AccountRoutes.SPACE.HOME}
            render={() => <Redirect
              to={buildPath(
                this.props.guest ? AccountRoutes.SPACE.CODE_SOL : AccountRoutes.SPACE.INDEX,
                { accountId: this.props.accountId }
              )}
            />}
          />
          <Route
            render={() => <Redirect
              to={buildPath(
                this.props.guest ? AccountRoutes.SPACE.CODE_SOL : AccountRoutes.SPACE.INDEX,
                { accountId: this.props.accountId }
              )}
            />}
          />
        </Switch>
      </div>
    </div>;
  }
}

export default AccountSpaceView;
