/* eslint-disable react/prop-types */
import 'styles/collection/comment/comment.scss';
import React, { Component, Fragment } from 'react';
import {
  DeleteTwoTone, LikeOutlined,
  LikeTwoTone,
  MessageOutlined,
  RollbackOutlined,
  SettingOutlined
} from '@ant-design/icons';

import {
  Typography,
  Spin,
  Tag,
  Divider,
  Button,
  Avatar,
  Modal,
  List,
  Comment,
  Popover
} from 'antd';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import PropTypes from 'prop-types';
import Markdowm from 'scripts/common/widgets/mdeditor';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildAvatarPath } from 'scripts/common/utils/location_helper';
import { noop, get, set } from 'lodash';
import { formatTime } from 'scripts/common/utils/time_formatter';
import {
  SolutionCommentsActions,
  SolutionCommentsSelectors,
  // CommentChildSelectors,
  ProblemSolutionSelectors

} from 'scripts/common/logic/collection';

const { Title, Paragraph } = Typography;

@connect((state, props) => {
  const { auth } = props;
  const { account }  = auth;
  const { entities } = state;
  const { comments } = entities;
  const { accounts } = entities;
  const Solutions = ProblemSolutionSelectors
    .getProblemsSolutionsListData(state)
    .filter((item) => { return item.id === props.details.id; }); 
  const CommentsList = SolutionCommentsSelectors
    .getSolutionCommentsListData(state).filter((item) => { return item.parent === null; });
  const ChildList = SolutionCommentsSelectors
    .getSolutionCommentsListData(state).filter((item) => { return item.parent !== null; });
  const commentPagesTotal = SolutionCommentsSelectors
    .problemSolutionCommentsListViewPagerSelector(state).total;
  return {
    commentPagesTotal,
    Solutions,
    account,
    ChildList,
    CommentsList,
    accounts,
    comments,
  };
}, dispatch => bindActionCreators({
  getChlidList: SolutionCommentsActions.getCommentsChlidList,
  addComment: SolutionCommentsActions.putCommentOn,
  getCommentsList: SolutionCommentsActions.getSolutionCommentsList,
  delCommentItem: SolutionCommentsActions.delCommentItem,
}, dispatch))

class CommentList extends Component {
  static propTypes = {
    CommentsList: PropTypes.arrayOf(PropTypes.object),
    ChildList: PropTypes.arrayOf(PropTypes.object),
    accounts: PropTypes.shape({}),
    comments: PropTypes.shape({}),
    details: PropTypes.shape({}),
    delCommentItem: PropTypes.func,
    getChlidList: PropTypes.func,
    toShowSolutiondetails: PropTypes.func,
    addComment: PropTypes.func,
    getCommentsList: PropTypes.func,
    markSolutionLikes: PropTypes.func,
    commentPagesTotal: PropTypes.number,
  };

  static defaultProps = {
    commentPagesTotal: 0,
    ChildList: [],
    CommentsList: [],
    accounts: {},
    comments: {},
    details: {},
    delCommentItem: noop,
    getChlidList: noop,
    getCommentsList: noop,
    toShowSolutiondetails: noop,
    addComment: noop,
    markSolutionLikes: noop,
  };

  state = {
    pageSize: 10,
    current: 1,
    isLoading: true,
    chilLoading: true,
    chilLoadingStatus: true,
    comments: {
      parent: 0,
      childContent: '',
      content: '',
      at_user: '',

    },
    selCommentEdituser: '',
    selCommentEditId: null,
    showParentId: null,
    showEditor: false,
    showChild: false,
    isLoadingChild: false,
  };
 
  componentWillMount() {
    this.props.getCommentsList({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
      query: {
        limit: this.state.pageSize,
        page: this.state.current,
      },
    });
  }

  componentWillReceiveProps() {
    // if (nextProps.ChildList.length !== 0 || this.state.count === 1) {
    //   this.setState({
    //     isLoadingChild: false,
    //     count: 1,
    //   });
    // } 
    const savComments =  { content: localStorage.getItem('Fathcontent') };
    const savId = parseInt(localStorage.getItem('commentId'), 10);
    const commentId = this.props.details.id;
    if (savComments.content && (savId === commentId)) {
      this.setState({
        comments: savComments,
      }); 
    } else {
      this.setState({
        comments: '',
      });
    }
    if (!this.state.chilLoading) {
      this.setState({
        chilLoadingStatus: false,
        chilLoading: true,
      });
    }
    this.setState({
      isLoading: false,
    });
  }

  shouldComponentUpdate() {
    const author =  (this.props.CommentsList).map((item) => { return item.author; });
    if ((author).indexOf(undefined) === -1) {
      return true;
    }
    return false;
  }

  changePageSize(pageSize) {
    this.setState({
      pageSize,
    });
    this.props.getCommentsList({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
      query: {
        limit: pageSize,
        page: this.state.current,
      },
    });
  }

  changePage(current, pageSize) {
    this.setState({
      current,
    });
    this.props.getCommentsList({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
      query: {
        limit: pageSize,
        page: current,
      },
    });
  }
 
  confirm() {
    Modal.confirm({
      title: 'WEJUDGE',
      content: '确认发布评论吗',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.handleSubmitBtn(),
    });
  }

  confirmDel(item, e) {
    e.stopPropagation();
    Modal.confirm({
      title: 'WEJUDGE',
      content: '确认删除评论吗',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.handleDelBtn(item),
    });
  }

  confirmChild() {
    Modal.confirm({
      title: 'WEJUDGE',
      content: '确认回复该评论吗',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.handleSubmitChildBtn(),
    });
  }

  closeDetails() {
    this.props.toShowSolutiondetails();
  }

  markLike(isAgree) {
    this.props.markSolutionLikes({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
      if: isAgree,
    });
    this.props.problemSolutions({
      collectionId: this.props.collectionId,
      id: this.props.problemId,
    });
    this.props.getCommentsList({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
      query: {
        limit: this.state.pageSize,
        page: this.state.current,
      },
    });
  }
  
  showEditorBtn(item, e) {
    e.stopPropagation();
    const judge = this.state.showEditor;
    this.setState({
      selCommentEdituser: null,
      selCommentEditId: item.id,
      showEditor: !judge,
    });
  }

  showCommentChlid(item, e) {
    e.stopPropagation();
    
    const judge = this.state.showChild;
    this.setState({
      showParentId: item.id,
      showChild: !judge,
    });
    if (judge) {
      this.setState({
        chilLoadingStatus: true,
      });
    } else {
      const promiseComment = new Promise((resolve) => {
        resolve(this.props.getChlidList({
          collectionId: this.props.collectionId,
          problemId: this.props.problemId,
          solutionId: this.props.details.id,
          query: {
            parent: item.id,
            limit: this.state.pageSize,
            page: this.state.current,
          },
        }));
      });
      promiseComment.then(this.setState({
        chilLoading: false,
      })); 
    }
  }

  handleEditorComments = (text) => {
    this.setState(set({ comments: { ...this.state.comments } }, 'comments.content', text));
    localStorage.setItem('Fathcontent', text);
    localStorage.setItem('commentId', String(this.props.details.id));
  }

  handleEditorChildComments = (text) => {
    this.setState(set({ comments: { ...this.state.comments } }, 'comments.childContent', text));
    localStorage.setItem('Chilcontent', text);
  }

  handleDelBtn(item) {
    this.props.delCommentItem({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
      evaluateId: item.id,
    });
  }

  handleSubmitChildBtn() {
    this.props.addComment({
      sContent: this.state.comments.childContent,
      user: this.state.selCommentEdituser,
      parentContent: this.state.selCommentEditId,
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
    });
    this.setState(set({ comments: { ...this.state.comments } }, 'comments.childContent', ''));
  }

  handleSubmitBtn() {
    this.props.addComment({
      sContent: this.state.comments.content,
      user: null,
      parentContent: null,
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: this.props.details.id,
    });
    this.setState(set({ comments: { ...this.state.comments } }, 'comments.content', ''));
    localStorage.clear();
  }
 
  render() {
    const { details } = this.props;
    const { account } = this.props;
    const isAgree = get(this.props.Solutions[0], 'is_agree', false);
    const solutionAgree = get(this.props.Solutions[0], 'agree', '');
    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: false,
      showTotal: () => `共${this.props.commentPagesTotal}条`,
      pageSize: this.state.pageSize,
      current: this.state.current,
      total: this.props.commentPagesTotal,
      onChange: current => this.changePage(current),
    };
    return (this.state.isLoading) ? <div className="comment-loading-spining "><Spin tip="加载中" size="large"  /></div>
      : <>
        <div className="all-page-solutions-comments"> 
          <div className="list-scrollbars" style={{  backgroundColor: 'white' }}>
            <div
              className="details-close-button "
              style={{ marginRight: 15, marginTop: 8 }}
            >
              <Button size="small" onClick={() => this.closeDetails()}>关闭
              </Button></div>
            <Typography>
              <Title level={4} style={{  marginLeft: 5 }}>
                <Avatar
                  src={details.author.avator ? buildAvatarPath(get(details.author, 'avator')) : null} 
                  style={{  marginRight: 15 }}
                />{details.title}</Title>
              <div style={{ marginLeft: 5, marginTop: 10 }} />
              {<span style={{ marginRight: 20 }}>发布者：{details.author.nickname}</span>}
              {Object.values(details.tags).filter((array) => {
                return array.language === true;
              }).map((arr) => { 
                return <Tag color="green" key={arr.name}>{arr.name}</Tag>; 
              })}
              {Object.values(details.tags).filter((array) => {
                return array.language !== true;
              }).map((arr) => { 
                return <Tag color="blue" key={arr.name}>{arr.name}</Tag>; 
              })}
              <div className="solution-time-details"> 发布时间：
                <span className="updatetime-details">{formatTime(get(details, 'create_time', 0), 'LONG_DATETIME')}</span>
                最后更新时间：<span>{formatTime(get(details, 'update_time', 0), 'LONG_DATETIME')}</span></div>
              <Divider />
              <Paragraph>
                <div className="solutions-details-content"> 
                  <RichtextContext content={details.content} />
                </div>
              </Paragraph>
            </Typography>
            <List
              className="comment-list"
              pagination={paginationProps}
              header={<div>
                <Divider orientation="left">{`${this.props.commentPagesTotal} 评论`}</Divider>
                <div className="details-close-button " style={{ marginLeft: 15, marginRight: 15 }}>
                  <Button
                    size="small"
                    onClick={() => this.confirm()}
                  >
                    评论
                  </Button>
                  <Markdowm
                    height="13vh"
                    onChange={this.handleEditorComments}
                    defaultValue={this.state.comments.content}
                  />
                </div>
              </div>}
              itemLayout="horizontal"
              dataSource={this.props.CommentsList}
              renderItem={item => (
                <List.Item
                  className="comments-parent"
                  extra={(item.author.id === account.id || account.role === 99) ? <Popover
                    className="comments-setting"
                    style={{ marginRight: 5 }} 
                    content={<span onClick={e => this.confirmDel(item, e)}>删除</span>}
                    title="管理"
                  >
                    <SettingOutlined />
                  </Popover> : null}
                >
                  <div className="ant-solutions-comment-list-replay">
                    <Comment
                      author={<div
                        className="comment-author"
                      ><span className="author-name">发布者: {item.author.nickname}</span>
                        <span className="comments-publish-time">{formatTime(get(item, 'create_time', 0), 'LONG_DATETIME')}</span></div>}
                      avatar={<Avatar
                        src={item.author.avator ? buildAvatarPath(get(item.author, 'avator')) : null} 
                        style={{  marginRight: 10 }}
                      />}
                      content={<RichtextContext>{item.content}</RichtextContext>}
                      actions={[<span className="review-reply"><MessageOutlined /> 
                        <span onClick={e => this.showCommentChlid(item, e)}>{this.state.showChild 
                  && this.state.showParentId === item.id ? '收起回复' : (this.props.ChildList)
                            .filter((chilItem) => { return chilItem.parent === item.id; })
                            .length !== 0 ? `查看${(this.props.ChildList)
                              .filter((chilItem) => { return chilItem.parent === item.id; }).length}回复` : '暂无回复 '}</span></span>,
                        <span className="reply-comment" style={{  marginRight: '20vw' }}><RollbackOutlined onClick={e => this.showEditorBtn(item.id, e)} /><span 
                          onClick={e => this.showEditorBtn(item, e)}
                        >{this.state.showEditor 
                    && this.state.showParentId === item.id  ? '收起' : '回复评论'}</span></span>, <span>{
                  ((this.state.selCommentEditId === item.id && this.state.showEditor) 
                    ? <div className="details-close-button "> <Button
                      size="small"
                      onClick={() => this.confirmChild()}
                    >
                      评论
                    </Button>
                      <Markdowm
                        height="10vh" 
                        onChange={this.handleEditorChildComments}
                        defaultValue={this.state.comments.childContent}
                      /></div> : null)
}</span>, ((this.state.showParentId === item.id
                         && this.state.showChild)
                      ? (this.state.isLoadingChild ? <Spin /> : <Spin spinning={this.state.chilLoadingStatus}><div style={{ minWidth: '40vw' }}><List
                        pagination={{ pageSize: 5 }}
                        itemLayout="horizontal"
                        dataSource={(this.props.ChildList).filter((chilItem) => {
                          return chilItem.parent === item.id;
                        })}
                        renderItem={itemChild => (
                          <List.Item extra={(item.author.id === account.id || account.role === 99) 
                            ? <DeleteTwoTone
                              twoToneColor="#eb2f96"
                              onClick={e => this.confirmDel(itemChild, e)}
                              style={{ marginRight: 5 }}
                            />
                            : null}
                          >
                            <List.Item.Meta
                              avatar={<Avatar
                                src={itemChild.author.avator ? buildAvatarPath(get(itemChild.author, 'avator')) : null} 
                                style={{  marginRight: 10 }}
                              />}
                              title={<div className="comment-chilauthor-containter">
                                <span className="comment-chilauthor-nickname">{`${itemChild.author.nickname} @${item.author.nickname}：`}</span>
                                <div className="comments-publish-time-ofchil"><small>
                                  {formatTime(get(itemChild, 'create_time', 0), 'LONG_DATETIME')}</small></div></div>}
                              description={<RichtextContext>{itemChild.content}</RichtextContext>}
                            />
                          </List.Item>
                        
                        )}
                      /> </div></Spin>) : null)]}
                    />
          
                  </div>
                </List.Item>
              )}
            />
      
          </div>
          <div className="comments-bottom-BarContainer">
            <div className="comment-bottom-item-Container"> 
              <span onClick={() => this.markLike(isAgree)}>
                {isAgree ? <span>
                  <LikeTwoTone style={{ marginRight: 8 }} />{ `${solutionAgree} 点赞`}</span>
                  : <span>
                    <LikeOutlined style={{ marginRight: 8 }} /> {solutionAgree} 点赞
                  </span>}
              </span>
            </div>
          </div>
        </div>
      </>;
  }
}
export default CommentList;
