import 'styles/education/asgn/asgn_problem.scss';

import React from 'react';
import { isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import {
  ClockCircleOutlined,
  CodeOutlined,
  FileTextOutlined,
  SettingOutlined
} from '@ant-design/icons';
import {
  Menu, Tag, PageHeader, Button, Descriptions, Tooltip, Row
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localStorage from 'localStorage';
import DataKeys from 'scripts/common/constants/data_keys';
import {
  Route, Switch, matchPath, withRouter, Link 
} from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AsgnRoutes, AsgnProblemRoutes, CollectionProblemViewRoutes } from 'scripts/apps/routes';
import { buildPath, openLink } from 'scripts/common/utils/location_helper';
import { AsgnProblemsSelectors, AsgnsSelectors } from 'scripts/common/logic/education/asgn/selectors';
import { AsgnProblemActions } from 'scripts/common/logic/education/asgn/actions';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { AsgnProblemPropTypes, AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import CodeProblemBody from 'scripts/apps/problem/widgets/code_problem_body';
import {
  ProblemTypeEnum,
  ProblemPermissionEnum,
  ProblemDifficultyEnum
} from 'scripts/common/enums/problem';
import CenterSpin from 'scripts/common/widgets/center-spin';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import { createPermissionChecker } from 'scripts/common/utils/validator';
import SubmitCodeView from 'scripts/apps/education/asgn/problems/submit_code_view';
import JudgeStatusView from 'scripts/apps/education/asgn/judge_status/judge_status_view';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';
import { AsgnSolutionActions } from 'scripts/common/logic/education/asgn/actions/solution';
import { AsgnSolutionSelectors } from 'scripts/common/logic/education/asgn/selectors/solution';
import VerticalSplitContainer from 'scripts/common/widgets/split_view';
import { SpecialJudgeEnum } from 'scripts/common/enums/judge';
import CorrectAnswerView from './correct_answer_view';
import CorrectAnswerList from './correct_answer_list';

const LOADING_STATUS_KEY = 'asgn_code_problem_view';

@LoginRequire
@withRouter
@connect((state, props) => {
  const { match } = props;
  const problemId =  parseInt(get(match.params, 'problemId', 0), 10);
  const courseId =  parseInt(get(match.params, 'courseId', 0), 10);
  const asgnId = parseInt(get(match.params, 'asgnId', 0), 10);
  const asgn = AsgnsSelectors.getAsgnEntity(state)(asgnId);
  const asgnProblem =    AsgnProblemsSelectors.getAsgnProblemEntity(state)(problemId);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const asgnViewReferer = localStorage.getItem(DataKeys.LOCAL_STORAGE.ASGN_VIEW_REFERER)
    || buildPath(AsgnRoutes.OVERVIEW, null, match.params);
  const problemPermission = createPermissionChecker(get(asgnProblem, 'problem.permissions_calculated', {}));
  const canJudgeSubmit = problemPermission(ProblemPermissionEnum.SUBMIT);
  const solutionCreaterMapping = AsgnSolutionSelectors.getSolutionCreaterMapping(state);

  return {
    asgnViewReferer,
    courseId,
    problemId,
    isLoading,
    asgnId,
    asgnProblem,
    canJudgeSubmit,
    asgn,
    solutionCreaterMapping,
  };
}, dispatch => bindActionCreators({
  // submitCode: JudgeStatusActions.submitCode,
  createSolution: AsgnSolutionActions.createSolution,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getAsgnProblem: AsgnProblemActions.getAsgnProblem,
  getAsgnEntity: AsgnActions.getAsgnEntity,
}, dispatch))
class CodeProblemView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    isLoading: PropTypes.bool,
    asgnViewReferer: PropTypes.string.isRequired,
    problemId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    getAsgnProblem: PropTypes.func.isRequired,
    setLoadingStatus: PropTypes.func.isRequired,
    getAsgnEntity: PropTypes.func.isRequired,
    // submitCode: PropTypes.func.isRequired,
    canJudgeSubmit: PropTypes.bool.isRequired,
    asgnProblem: AsgnProblemPropTypes,
    asgn: AsgnPropTypes,
    createSolution: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isLoading: true,
    asgnProblem: null,
    asgn: null,
  };

  state = {
    hasFetchEntity: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const backToList = () => {
      nextProps.history.push(nextProps.asgnViewReferer);
    };
    if (!prevState.hasFetchEntity && !nextProps.asgnProblem) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);

      if (!nextProps.asgn) {
        nextProps.getAsgnEntity({
          id: nextProps.asgnId,
          courseId: nextProps.courseId,
          onError: () => {
            backToList();
          },
        });
      }
      nextProps.getAsgnProblem({
        asgnId: nextProps.asgnId,
        courseId: nextProps.courseId,
        id: nextProps.problemId,
        onError: () => {
          backToList();
        },
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        hasFetchEntity: true,
      };
    }
    if (nextProps.asgn && nextProps.asgn.status.role === 'student'
     && nextProps.solutionCreaterMapping !== get(nextProps, 'asgnProblem.id', '')) {
      nextProps.createSolution({
        id: get(nextProps, 'asgnProblem.id', 0),
        cid: nextProps.courseId,
        aid: nextProps.asgnId,
      });
    }
    if (!isEmpty(get(nextProps.asgnProblem, 'problem'))) {
      const problemType = ProblemTypeEnum.enumValueOf(get(nextProps.asgnProblem, 'problem.problem_type', 0));
      if (!problemType
        || !(problemType === ProblemTypeEnum.CODE || problemType === ProblemTypeEnum.CODE_FILL)) {
        backToList();
      }
    }
    return null;
  }

  menuItems = [
    {
      key: 'content',
      title: '题目内容',
      path: AsgnProblemRoutes.CONTENT,
      icon: <FileTextOutlined />,
    },
    // {
    //   key: 'submit',
    //   title: '提交评测',
    //   path: AsgnProblemRoutes.SUBMIT_CODE,
    //   icon: 'code-o',
    // },
    {
      key: 'judge_status',
      title: '我的提交',
      path: AsgnProblemRoutes.JUDGE_STATUS,
      icon: <ClockCircleOutlined />,
    },
    {
      key: 'correct_answer',
      title: '参考答案',
      path: AsgnProblemRoutes.CORRECT_ANSWER,
      icon: <CodeOutlined />,
    }
  ];

  judgeStatusView = React.createRef();

  backToList = () => {
    this.props.history.push(this.props.asgnViewReferer);
  };

  matchSubItemKey = () => {
    return get(matchPath(this.props.location.pathname, {
      path: AsgnProblemRoutes.SUBITEMS,
    }), 'params.subItemName', 'content');
  };

  menuItemFilter = (item) => {
    if (item.key === 'submit' && !this.props.canJudgeSubmit) return false;
    return true;
  };

  handleReceivedNewJudgeStatus = () => {
    const isJudgeStatusList = matchPath(
      window.location.pathname,
      AsgnProblemRoutes.JUDGE_STATUS
    );
    if (isJudgeStatusList && this.judgeStatusView && this.judgeStatusView.current) {
      this.judgeStatusView.current.refreshList && this.judgeStatusView.current.refreshList();
    }
  };

  handleGotoManagement = () => {
    const path = buildPath(
      CollectionProblemViewRoutes.MANAGEMENT_EDIT,
      null,
      {
        collectionId: get(this.props.asgnProblem.source, 'collection_id', ''),
        problemId: get(this.props.asgnProblem.source, 'collection_item', ''),
      }
    );
    openLink(path, true);
  };

  renderMenuItem = ({
    key,
    icon,
    title,
    path,
  }) => (
    <Menu.Item key={key}>
      <Link
        to={buildPath(path, null, this.props.match.params)}
      >
        {icon} {title}
      </Link>
    </Menu.Item>
  );

  renderLeftLayoutRouterView = () => {
    const { asgnProblem } = this.props;
    const problem = get(asgnProblem, 'problem', {});
    return <Switch>
      <Route
        path={AsgnProblemRoutes.CONTENT}
        render={() => {
          return <CodeProblemBody
            problem={problem}
            canManageProblem={false}
          />;
        }}
        exact
      />
      <Route
        path={AsgnProblemRoutes.JUDGE_STATUS}
        render={props => <JudgeStatusView
          {...props}
          size="minimal"
          courseId={this.props.courseId}
          asgnId={this.props.asgnId}
          problemId={this.props.problemId}
          filterOwner
          ref={this.judgeStatusView}
        />}
        exact
      />
      <Route
        path={AsgnProblemRoutes.CORRECT_ANSWER}
        render={() => <CorrectAnswerList
          problemId={problem.id}
          asgn={this.props.asgn}
          asgnProblem={asgnProblem}
        />}
      />
    </Switch>;
  };

  renderRightLayoutRouterView = () => {
    const { asgnProblem } = this.props;
    const problem = get(asgnProblem, 'problem', {});
    return <Switch>
      <Route
        path={AsgnProblemRoutes.CORRECT_ANSWER_DETAIL}
        render={() => <CorrectAnswerView
          asgnProblem={this.props.asgnProblem}
          problemId={problem.id}
          asgn={this.props.asgn}
        />}
        exact
      />
      <Route
        render={() => <SubmitCodeView
          key="submit-code"
          courseId={this.props.courseId}
          asgnId={this.props.asgnId}
          asgnProblemId={this.props.asgnProblem.id}
          problem={problem}
          asgn={this.props.asgn}
          asgnProblem={this.props.asgnProblem}
          onReceivedNewJudgeStatus={this.handleReceivedNewJudgeStatus}
        />}
      />
    </Switch>;
  };

  renderProblemView = () => {
    const { asgnProblem } = this.props;
    const subItemKey = this.matchSubItemKey();
    const problem = get(asgnProblem, 'problem', {});
    const options = get(problem, 'options', {});
    const problemType = ProblemTypeEnum.enumValueOf(get(problem, 'problem_type'));
    const tags = [];
    const canManageProblem = get(this.props, 'asgn.status.role', '') === 'manage'
      && get(this.props.asgnProblem.source, 'collection_id', null);
    if (!get(problem, 'available', false)) {
      tags.push(<Tag key="available" color="#ff7875">评测未启用</Tag>);
    }
    const extraBtns = [
    //   <Button
    //     key="problem_list"
    //     type="default"
    //   >
    //     <Icon type="unordered-list" /> 题目列表
    //   </Button>
    ];
    if (canManageProblem) {
      extraBtns.push(<Button
        key="manage"
        type="primary"
        onClick={this.handleGotoManagement}
      >
        <SettingOutlined /> 管理题目
      </Button>);
    }
    tags.push(<Tag key="number" color="grey">#{get(problem, 'number', '0')}</Tag>);
    tags.push(<Tag key="color" color={problemType.color}>{problemType.desc}</Tag>);
    return <div className="wejudge_code_problems asgn_code_problem_view">
      <PageHeader
        onBack={this.backToList}
        title={get(problem, 'title', '')}
        tags={tags}
        extra={extraBtns}
      >
        <Row className="pager-extra-info" type="flex">
          <Descriptions size="small" column={5}>
            <Descriptions.Item label="评测模式">
              {get(SpecialJudgeEnum.enumValueOf(get(options, 'judge_options.special_judge', 0)), 'desc')}
            </Descriptions.Item>
            <Descriptions.Item label="题目难度">
              <Tooltip title={get(ProblemDifficultyEnum.enumValueOf(get(problem, 'difficulty', 0)), 'desc')}>
                {get(ProblemDifficultyEnum.enumValueOf(get(problem, 'difficulty', 0)), 'desc')}
              </Tooltip>
            </Descriptions.Item>
            <Descriptions.Item label="类型">
              {get(asgnProblem, 'require', false) ? '必做' : '选做'}
            </Descriptions.Item>
            <Descriptions.Item label="分值">
              {get(asgnProblem, 'score', 0)}
            </Descriptions.Item>
            <Descriptions.Item label="严格判题">
              <Tooltip title={get(asgnProblem, 'strict', false) ? '格式错误将扣分' : '格式错误也视作评测通过'}>
                {get(asgnProblem, 'strict', false) ? '启用' : '停用'}
              </Tooltip>
            </Descriptions.Item>
          </Descriptions>
        </Row>
      </PageHeader>
      <VerticalSplitContainer
        left={<>
          <Menu selectedKeys={[subItemKey]} mode="horizontal">
            {this.menuItems.filter(this.menuItemFilter).map(item => this.renderMenuItem(item))}
          </Menu>
          {this.renderLeftLayoutRouterView()}
        </>}
        right={this.renderRightLayoutRouterView()}
      />
    </div>;
  };

  render() {
    const { asgnProblem } = this.props;
    const problem = get(asgnProblem, 'problem', {});
    return !isEmpty(asgnProblem) && !isEmpty(problem) ? this.renderProblemView()
      : (<CenterSpin size="large" tip="加载中..." spinning={this.props.isLoading} />);
  }
}

export default CodeProblemView;
