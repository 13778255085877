import React from 'react';
import { get } from 'lodash';
import { Modal, Table, Alert } from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CollectionPropTypes } from 'scripts/common/prop-types/collection';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { CollectionSelectors } from 'scripts/common/logic/collection/selectors/collection';
import { CollectionActions } from 'scripts/common/logic/collection/actions/colleciton';

const mapDispatchToProps = dispatch => bindActionCreators({
  getCollectionsList: CollectionActions.getCollectionsList,
}, dispatch);

const mapStateToProps = (state) => {
  const pager = CollectionSelectors.collectionsListViewPagerSelector(state);
  const collections = CollectionSelectors.collectionsListData(state);
  return {
    paginationParams: pager,
    collections,
  };
};

@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class ProblemControlModal extends React.PureComponent {
  static propTypes = {
    collections: PropTypes.arrayOf(CollectionPropTypes),
    type: PropTypes.string.isRequired,
    paginationParams: PaginationPropTypes.isRequired,
  };

  static defaultProps = {
    collections: [],
  };

  state = {
    target_collection_id: '',
    hasFetchCollection: false,
    paginationParams: {
      limit: 10,
      page: 1,
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchCollection) {
      nextProps.getCollectionsList({
        query: {
          ...prevState.paginationParams,
        },
      });
      return {
        ...prevState,
        hasFetchCollection: true,
      };
    }
    return null;
  }

  onRadioChange= (selectedRowKeys) => {
    if (selectedRowKeys.length >= 1) {
      this.setState({
        ...this.state,
        target_collection_id: selectedRowKeys[0],
      });
    }
  };

  onPageChange = (page) => {
    this.setState({
      hasFetchCollection: false,
      paginationParams: {
        page,
        limit: 10,
      },
    });
  };

  renderListData = () => {
    return this.props.collections.map((item) => {
      if (!item) return {};
      return {
        key: get(item, 'id', ''),
        title: get(item, 'title', ''),
        author: get(item, 'author.nickname', ''),
      };
    });
  };

  renderDeleteAlertMessage = () => {
    return (<ul style={{ padding: 0 }}>
      <li>你将要移除的是所选题目与当前题库的【关联】</li>
      <li>如果所选题目的本体只有唯一的题库关联，则移除操作无效</li>
      <li>移除关联会导致题目在当前题库中的评测记录、统计数据永久丢失！</li>
      <li>如需要删除题目本体请到对应题目的【管理】页面进行操作。</li>
      <li>当前操作仅能由管理员或题库创建者执行。</li>
    </ul>);
  };

  renderDesc = (type) => {
    switch (type) {
      case 'move':
        return <p>移动操作将<strong>所选题目和题库</strong>的【关联】移动到<strong>目标题库</strong>，评测记录和统计数据依然会保留。</p>;
      case 'copy':
        return <p>
          复制操作将根据<strong>所选题目</strong>的内容，完整的在<strong>目标题库</strong>中创建一个全新的题目。
          这个新的题目可以自由修改，<strong>不会影响</strong>当前所选的题目。
        </p>;
      case 'clone':
        return <p>
          克隆操作将创建<strong>所选题目</strong>和<strong>目标题库</strong>的【关联】，
          因此在目标题库对当前题目的【关联】项进行修改会直接影响源题目的内容。
        </p>;
      default:
        return '';
    }
  };

  render() {
    const optPagination = {
      current: this.state.paginationParams.page,
      pageSize: this.state.paginationParams.limit,
      showQuickJumper: true,
      showSizeChanger: false,
      onChange: this.onPageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    const datas = this.renderListData();
    const columns = [{
      title: '题库名称',
      dataIndex: 'title',
    }, {
      title: '创建者',
      dataIndex: 'author',
      width: 200,
      align: 'center',
    }];
    let title = '';
    const { type } = this.props;
    if (this.props.type === 'move') {
      title = '移动题目到指定题库';
    } else if (this.props.type === 'remove') {
      title = '从当前题库移除题目';
    } else if (this.props.type === 'copy') {
      title = '复制题目到指定题库';
    } else if (this.props.type === 'clone') {
      title = '镜像题目到指定题库';
    }
    return (<div>
      {type !== 'remove' && <Modal
        {...this.props}
        title={title}
        width={720}
      >
        <Alert
          message="操作说明"
          description={this.renderDesc(type)}
          type="info"
          showIcon
        />
        <br />
        <Table
          size="small"
          bordered
          columns={columns}
          dataSource={datas}
          rowSelection={{
            type: 'radio',
            onChange: this.onRadioChange,
            selectedRowKeys: [this.state.target_collection_id],
          }}
          pagination={optPagination}
        />
      </Modal>}
      {type === 'remove' && <Modal
        {...this.props}
        title={title}
      >
        <Alert
          message="这个操作存在风险！"
          description={this.renderDeleteAlertMessage()}
          type="error"
          showIcon
        />
      </Modal>}
    </div>
    );
  }
}

export default ProblemControlModal;
