import 'styles/education/asgn/asgn_problem_list.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { get, noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import localStorage from 'localStorage';
import { CollectionRoutes } from 'scripts/apps/routes';
import DataKeys from 'scripts/common/constants/data_keys';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AsgnProblemPropTypes, AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { CourseActions } from 'scripts/common/logic/education/course';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AsgnProblemActions } from 'scripts/common/logic/education/asgn/actions';
import { AsgnJudgeStatusActions } from 'scripts/common/logic/education/asgn/actions/judge_status';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import { AsgnSolutionSelectors } from 'scripts/common/logic/education/asgn/selectors/solution';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';
import { AsgnSolutionActions } from 'scripts/common/logic/education/asgn/actions/solution';

import ProblemsListTable from './problems_list_table';
import AsgnNormalProblemsView from './normal_problem_view';

const mapStateToProps = (state) => {
  const author = AccountSelectors.getLoginAccount(state);
  const authorId = get(author, 'id', '');
  const codeProblemSolutionData =    AsgnSolutionSelectors.getAsgnProblemSolutions(DataKeys.CODE_PROBLEMS)(state)();
  return {
    authorId,
    codeProblemSolutionData,
  };
};

@connect(mapStateToProps, dispatch => bindActionCreators({
  getCodeProblemSolutions: AsgnSolutionActions.getCodeProblemSolutions,
  getNormalProblemSolutions: AsgnSolutionActions.getNormalProblemSolutions,
  getCourseProblemSelected: CourseActions.getCourseProblemSelected,
  modifyAsgnProblem: AsgnProblemActions.modifyAsgnProblem,
  rejudgeAsgnProblem: AsgnJudgeStatusActions.rejudgeAsgnProblem,
  delAsgnProblem: AsgnProblemActions.delAsgnProblem,
  changeAsgnProblemOrder: AsgnProblemActions.changeAsgnProblemOrder,
  recalculationAsgn: AsgnActions.recalculationAsgn,
}, dispatch))
class AsgnProblemsListContent extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    asgn: AsgnPropTypes,
    asgnId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    groupName: PropTypes.string.isRequired,
    authodId: PropTypes.string,
    reportSubmitted: PropTypes.bool,
    isManage: PropTypes.bool,
    problemDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    onProblemClick: PropTypes.func,
    getCourseProblemSelected: PropTypes.func,
    hasFetchList: PropTypes.func.isRequired,
    recalculationAsgn: PropTypes.func,
    changeAsgnProblemOrder: PropTypes.func,
    codeProblemSolutionData: PropTypes.shape(),
  };

  static defaultProps = {
    asgn: null,
    onProblemClick: noop,
    getCourseProblemSelected: noop,
    reportSubmitted: false,
    isManage: false,
    recalculationAsgn: noop,
    changeAsgnProblemOrder: noop,
    codeProblemSolutionData: {},
    authodId: '',
  };

  state = {
    // hasFetchProblemSolutions: false,
    fetching: false,
    newIdOrder: [],
    isSaving: false,
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (!prevState.hasFetchProblemSolutions) {
  //     nextProps.getCodeProblemSolutions({
  //       courseId: nextProps.courseId,
  //       asgnId: nextProps.asgnId,
  //       authorId: nextProps.authorId,
  //       onComplete: () => {
  //         nextProps.getNormalProblemSolutions({
  //           courseId: nextProps.courseId,
  //           asgnId: nextProps.asgnId,
  //           authorId: nextProps.authorId,
  //           onComplete: () => {
  //
  //           },
  //         });
  //       },
  //     });
  //     return {
  //       hasFetchProblemSolutions: true,
  //     };
  //   }
  //   return null;
  // }

  plistRef = React.createRef();

  gotoColleciton = () => {
    const dpids = [];
    this.props.problemDatas.forEach((item) => {
      const pid = get(item, 'problem.id');
      pid && dpids.push(pid);
    });
    this.setState({
      fetching: true,
    }, () => {
      this.props.getCourseProblemSelected({
        courseId: this.props.courseId,
        onSuccess: (pids = []) => {
          // 选题模式开启逻辑
          localStorage.setItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING, JSON.stringify({
            module: 'asgn',
            payloads: {
              asgnId: this.props.asgnId,
              courseId: this.props.courseId,
            },
            selectedProblemIds: pids,       // 已选过的题目ID（会高亮显示，可以重复选）
            disabledProblemsIds: dpids,     // 禁止选择的题目ID（当前模块已存在的题目，不能重复选择）
          }));
          this.props.history.push(buildPath(CollectionRoutes.LIST));
        },
        onError: () => {
          this.setState({ fetching: false });
        },
      });
    });
  };

  handleRecalculationAsgnClick = () => {
    Modal.confirm({
      okText: '确认',
      title: '重算数据',
      cancelText: '取消',
      okType: 'primary',
      content: '确认要重算数据吗',
      onOk: () => {
        this.props.recalculationAsgn({
          courseId: this.props.courseId,
          asgnId: this.props.asgnId,
        });
      },
    });
  };

  handleProblemOrderChange = (idList) => {
    this.setState({
      newIdOrder: idList,
    });
  };

  handleProblemOrderReset = () => {
    this.setState({
      newIdOrder: [],
    });
    this.plistRef.current.handleResetProblemOrder();
  };

  handleSaveProblemOrder = () => {
    if (this.state.isSaving) return;
    this.setState({
      isSaving: true,
    }, () => {
      this.props.changeAsgnProblemOrder({
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemIds: this.state.newIdOrder,
        onSuccess: () => {
          this.setState({
            newIdOrder: [],
          });
        },
        onComplete: () => {
          this.setState({
            isSaving: false,
          });
        },
      });
    });
  };

  renderView() {
    if (this.props.groupName === 'objective_problems') {
      return (<AsgnNormalProblemsView
        asgnId={this.props.asgnId}
        courseId={this.props.courseId}
        problemDatas={this.props.problemDatas}
        reportSubmitted={this.props.reportSubmitted}
      />);
    }
    return (<div className="asgn_problems_view">
      {this.props.isManage && <div className="list_control">
        <div className="left-sider">
          <Button onClick={this.gotoColleciton} loading={this.state.fetching} type="primary">去题库选题</Button>
        </div>
        <div className="right-sider">
          {this.state.newIdOrder.length > 0 && <Button.Group>
            <Button
              type="primary"
              onClick={this.handleSaveProblemOrder}
              loading={this.state.isSaving}
            >
              保存排序
            </Button>
            <Button
              type="default"
              onClick={this.handleProblemOrderReset}
            >
              放弃变更
            </Button>
          </Button.Group>}
          {this.state.newIdOrder.length === 0
            && <Button onClick={this.handleRecalculationAsgnClick}>数据重算</Button>}
        </div>
      </div>}
      <ProblemsListTable
        ref={this.plistRef}
        isManage={this.props.isManage}
        problemDatas={this.props.problemDatas}
        asgn={this.props.asgn}
        asgnId={this.props.asgnId}
        courseId={this.props.courseId}
        onProblemClick={this.props.onProblemClick}
        onProblemOrderChange={this.handleProblemOrderChange}
        hasFetchList={this.props.hasFetchList}
        codeProblemSolutionData={this.props.codeProblemSolutionData}
      />
    </div>);
  }

  render() {
    return this.renderView();
  }
}

export default AsgnProblemsListContent;
