import 'styles/contest/support/notice.scss';
import React from 'react';
import { Modal, Empty } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { formatTime } from 'scripts/common/utils/time_formatter';
import classnames from 'classnames';
import RichtextContext from 'scripts/common/widgets/richtext_context';

class ContestNoticeModal extends React.PureComponent {
  static propTypes = {
    noticesData: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    noticesData: [],
  };

  state = {
    currentNoticeId: get(this.props.noticesData, '0.id', -1),
  };

  onNoticeClick = item => () => {
    this.setState({
      currentNoticeId: item.id,
    });
  };

  renderList = () => {
    return this.props.noticesData.map((item) => {
      return <div
        key={item.id}
        onClick={this.onNoticeClick(item)}
        className={classnames('notice-card', { 'notice-card-hover': item.id === this.state.currentNoticeId })}
      >
        <div className="title">
          {item.title}
        </div>
        <div className="timer">
          {formatTime(get(item, 'create_time', 0), 'SHORT_DATETIME')}
        </div>
      </div>;
    });
  };

  renderContent = () => {
    const target = this.props.noticesData.filter(item => item.id === this.state.currentNoticeId);
    if (target.length > 0) {
      const item = target[0];
      return <div className="notice-content">
        <div className="header">
          {item.title}
        </div>
        <div className="content">
          <RichtextContext>
            {item.content}
          </RichtextContext>
        </div>
        <div className="meta">
          创建时间： {formatTime(get(item, 'create_time', 0), 'SHORT_DATETIME')}
          {(get(item, 'create_time', 0) !== get(item, 'update_time', 0)) && `；修改时间： ${formatTime(get(item, 'update_time', 0), 'SHORT_DATETIME')}`}
        </div>
      </div>;
    }
    return <div />;
  };

  render() {
    return (
      <Modal
        className="contest-notice-modal"
        title="比赛公告"
        {...this.props}
        footer={null}
      >
        {this.props.noticesData.length > 0 ? <>
          <div className="notice-outer-list">
            <div className="notice-inner-list">
              {this.renderList()}
            </div>
          </div>
          <div className="contest-notice-content">
            {this.renderContent()}
          </div>
        </> : <div style={{ marginTop: 16, width: '100%' }}>
          <Empty className="contest-empty-notice" description="暂无公告" />
        </div>}
      </Modal>
    );
  }
}

export default ContestNoticeModal;
