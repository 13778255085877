import React from 'react';
import PropTypes from 'prop-types';
import { noop, get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubmitCodeProblemWidget from 'scripts/apps/problem/judge_status/submit_code_problem';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { AsgnJudgeStatusActions, AsgnJudgeStatusSelectors } from 'scripts/common/logic/education/asgn';
import { showModal } from 'scripts/common/widgets/modal';
import { createJudgeResultModal } from 'scripts/apps/widgets/judge_status/dialog';
import { AsgnProblemPropTypes, AsgnPropTypes } from 'scripts/common/prop-types/asgn';

const JudgeResultModal = createJudgeResultModal(AsgnJudgeStatusActions, AsgnJudgeStatusSelectors, 'Asgn');

const mapDispatchToProps = dispatch => bindActionCreators({
  submitAsgnCode: AsgnJudgeStatusActions.submitAsgnCode,
  getJudgeStatusEntity: AsgnJudgeStatusActions.getAsgnJudgeStatusEntity,
}, dispatch);
@connect(null, mapDispatchToProps)
class SubmitCodeView extends React.PureComponent {
  static propTypes = {
    asgn: AsgnPropTypes,
    problem: ProblemPropTypes.isRequired,
    asgnProblem: AsgnProblemPropTypes.isRequired,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    asgnProblemId: PropTypes.number.isRequired,
    submitAsgnCode: PropTypes.func,
    getJudgeStatusEntity: PropTypes.func,
    onReceivedNewJudgeStatus: PropTypes.func,      // 当有新的提交并且有评测结果时触发
  };

  static defaultProps = {
    asgn: null,
    submitAsgnCode: noop,
    getJudgeStatusEntity: noop,
    onReceivedNewJudgeStatus: noop,
  };

  state = {};

  showJudgeStatusModel = (status) => {
    const { courseId, asgnId } = this.props;
    showModal(JudgeResultModal, {
      judgeStatusId: get(status, 'id'),
      apiPayloads: {
        courseId,
        asgnId,
      },
    });
  };

  getLanguageMask = () => {
    if (this.props.asgn && this.props.asgnProblem) {
      const plang = get(this.props.asgnProblem, 'code_language', -1);
      if (plang < 0) {
        return get(this.props.asgn, 'code_language', 0);
      }
      return plang;
    }
    return 0;
  };

  render() {
    const { courseId, asgnId, asgnProblemId } = this.props;
    return (<SubmitCodeProblemWidget
      problem={this.props.problem}
      languageMask={this.getLanguageMask()}
      submitCode={(payload) => {
        this.props.submitAsgnCode({
          ...payload,
          courseId,
          asgnId,
          asgnProblemId,
        });
      }}
      getJudgeStatusEntity={(payloads) => {
        this.props.getJudgeStatusEntity({
          ...payloads,
          courseId,
          asgnId,
        });
      }}
      onShowJudgeStatusModel={this.showJudgeStatusModel}
      getJudgeStatusEntitySelector={AsgnJudgeStatusSelectors.getJudgeStatusEntity}
      onReceivedNewJudgeStatus={this.props.onReceivedNewJudgeStatus}
    />
    );
  }
}
export default SubmitCodeView;
