import 'styles/widgets/video-react.css';

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  LoadingSpinner,
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  // CurrentTimeDisplay,
  // TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton
} from 'video-react';
import moment from 'moment';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import { LessonSelectors } from 'scripts/common/logic/education/lesson';
import { LessonVideoPropTypes } from 'scripts/common/prop-types/course';

const mapStateToProps = (state, props) => {
  const videoData = LessonSelectors.getVideoEntity(state)(get(props, 'lesson.video.id'));
  return {
    videoData,
  };
};

const VideoPlayer = (props) => {
  const {
    videoData,
    lessonId,
    getLessonVideo,
    courseId,
  } = props;
  if (!videoData) { return null; }

  const [videoUrl, setVideoUrl] = useState('');
  const [hasChange, setHasChange] = useState(false);

  useEffect(() => {
    if (videoData) {
      const pickcode = get(videoData, 'token.pick_code', '');
      const fileHash = get(videoData, 'token.file_hash', '');
      setVideoUrl(`${process.env.RESOURCE_SERVICE}/download/${fileHash}?pickcode=${pickcode}`);
      setHasChange(true);
    }
  }, [lessonId, videoData, hasChange]);

  return (
    <Player
      playInline
      preload="auto"
      src={videoUrl}
      poster={`data:image/jpeg;base64,${get(videoData, 'meta.video_thumb_img', '')}`}
      fluid={false}
      width="100%"
      height="100%"
      // startTime={}
    >
      <ControlBar>
        <ReplayControl seconds={10} order={1.1} />
        <ForwardControl seconds={30} order={1.2} />
        <VolumeMenuButton vertical order={7.1} />
        <PlaybackRateMenuButton rates={[2, 1.5, 1.25, 1, 0.75, 0.5]} order={7.2} />
      </ControlBar>
      <BigPlayButton position="center" />
      <LoadingSpinner  />
    </Player>
  );
};

VideoPlayer.propTypes = {
  lessonId: PropTypes.string.isRequired,
  lesson: PropTypes.shape({}).isRequired,
  videoData: LessonVideoPropTypes,
  getLessonVideo: PropTypes.func.isRequired,
  courseId: PropTypes.string.isRequired,
};

VideoPlayer.defaultProps = {
  videoData: null,
};

export default connect(mapStateToProps, null)(VideoPlayer);
