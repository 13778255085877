import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  get, noop, isEqual, debounce 
} from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Select } from 'antd';
import { connect } from 'react-redux';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { ProblemTagsActions } from 'scripts/common/logic/problem/actions';
import { ProblemsTagsSelectors } from 'scripts/common/logic/problem/selectors';
import { tagColorHash } from 'scripts/common/utils/tag_hash';
import { INPUT_SEARCH_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

const mapStateToProps = (state) => {
  const tagsSearch = ProblemsTagsSelectors.problemTagsSearchResultSelector(state);
  return {
    tagsSearch,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  bindTags: ProblemTagsActions.bindTags,
  removeTags: ProblemTagsActions.removeTags,
  searchTags: ProblemTagsActions.searchTags,
  emptySearchTags: ProblemTagsActions.emptySearchTags,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
class ProblemTagsView extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes.isRequired,
    onProblemEntityChange: PropTypes.func.isRequired,
    canManageProblem: PropTypes.bool.isRequired,
    bindTags: PropTypes.func,
    removeTags: PropTypes.func,
    searchTags: PropTypes.func,
    emptySearchTags: PropTypes.func,
    tagsSearch: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    bindTags: noop,
    searchTags: noop,
    removeTags: noop,
    emptySearchTags: noop,
    tagsSearch: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(prevState.problemOld, nextProps.problem)) {
      return {
        problemOld: nextProps.problem,
        tags: nextProps.problem.tags,
      };
    }
    return null;
  }

  state = {
    tags: [],
    isSending: false,
    problemOld: null,
    addTagValue: '',
    addVisible: false,
  };

  selectRef = React.createRef();

  fetchKeysAPI = () => {
    if (!this.state.addTagValue) return;
    const problemId = get(this.props.problem, 'id', '');
    this.props.searchTags({
      problemId,
      keyword: this.state.addTagValue,
    });
  };

  fetchKeys = debounce(this.fetchKeysAPI, INPUT_SEARCH_DEBOUNDCE_TIME);

  handleAddInputChange = (key) => {
    if (this.state.isSending) return;
    this.setState({
      isSending: true,
    }, () => {
      const problemId = get(this.props.problem, 'id', '');
      this.props.bindTags({
        problemId,
        tagId: key,
        onSuccess: () => {
          this.setState({
            addTagValue: '',
            addVisible: false,
          });
          this.props.onProblemEntityChange(problemId);
        },
        onComplete: () => {
          this.setState({
            isSending: false,
          });
        },
      });
    });
  };

  handleRemoveTag = key => () => {
    if (this.state.isSending) return;
    this.setState({
      isSending: true,
    }, () => {
      const problemId = get(this.props.problem, 'id', '');
      this.props.removeTags({
        problemId,
        tagId: key,
        onSuccess: () => {
          this.setState({
            addTagValue: '',
            addVisible: false,
          });
          this.props.emptySearchTags();
          this.props.onProblemEntityChange(problemId);
        },
        onComplete: () => {
          this.setState({
            isSending: false,
          });
        },
      });
    });
  };

  handleAddInputSearch = (key) => {
    this.setState({
      addTagValue: key,
    }, () => {
      this.fetchKeys();
    });
  };

  handleShowAddInput = () => {
    this.setState({
      addVisible: true,
    }, () => {
      try {
        const dom = findDOMNode(this.selectRef.current)
          .getElementsByClassName('ant-select-search__field');
        const inputBox = dom[0];
        if (!inputBox) return;
        setTimeout(() => {
          inputBox.focus();
          const evt = document.createEvent('MouseEvents');
          evt.initEvent('click', true, true);
          inputBox.dispatchEvent(evt);
        }, 200);
      } catch (e) { console.log(e); }
    });
  };

  handleAddInputBlur = () => {
    this.props.emptySearchTags();
    this.setState({
      addTagValue: '',
      addVisible: false,
    });
  };

  render() {
    const { canManageProblem } = this.props;
    const { addVisible, addTagValue, tags = [] } = this.state;
    const emptyTag = (!canManageProblem ? '无' : '');
    return (
      <div className="info_item horizontal" style={{ fontWeight: 500 }}>
        <div className="item_left">
          标签
        </div>
        <div className="item_right_multiline tags_view">
          {tags.length ? tags.map((item) => {
            return <Tag
              color={tagColorHash(item.name)}
              closable={canManageProblem}
              onClose={this.handleRemoveTag(item.id)}
              key={item.id}
            >
              {item.name}
            </Tag>;
          }) : emptyTag}
          {canManageProblem && <>
            {addVisible ? <Select
              showSearch
              showArrow={false}
              size="small"
              ref={this.selectRef}
              autoFocus
              style={{ width: 78 }}
              value={addTagValue}
              filterOption={false}
              onSearch={this.handleAddInputSearch}
              onChange={this.handleAddInputChange}
              onSelect={this.handleAddInputChange}
              onBlur={this.handleAddInputBlur}
              notFoundContent={null}
            >
              {this.state.addTagValue && <Select.Option
                key="add"
                value={this.state.addTagValue}
              >
                {this.state.addTagValue}
              </Select.Option>}
              {this.props.tagsSearch.map((item) => {
                return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>;
              })}
            </Select> : <Tag
              className="add-tag"
              onClick={this.handleShowAddInput}
            >
              <PlusOutlined /> 增加
            </Tag>}
          </>}
        </div>
      </div>
    );
  }
}

export default ProblemTagsView;
