import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { ContestRoutes } from 'scripts/apps/routes';
import JudgeStatusView from './list';
import JudgeSolutionsView from './solutions';

const { Content } = Layout;

const JudgeStatusApp = () => (
  <Content className="contest-layout-content">
    <Switch>
      <Route path={ContestRoutes.JUDGE_STATUS.LIST} component={JudgeStatusView} />
      <Route path={ContestRoutes.JUDGE_STATUS.SOLUTIONS} component={JudgeSolutionsView} />
    </Switch>
  </Content>
);

export default JudgeStatusApp;
