import React from 'react';
import {
  Form, Divider, Drawer, Input, Button, Table, Switch, Popconfirm, message, Select 
} from 'antd';
import { get, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  ContestOrganizationActions,
  ContestOrganizationSelectors
} from 'scripts/common/logic/contest/organization';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { connect } from 'react-redux';
import { ContestAccountPropTypes, ContestPropTypes } from 'scripts/common/prop-types/contest';

const mapDispatchToProps = dispatch => bindActionCreators({
  addOrganization: ContestOrganizationActions.addOrganization,
  editOrganization: ContestOrganizationActions.editOrganization,
  leaveOrganization: ContestOrganizationActions.leaveOrganization,
  delOrganization: ContestOrganizationActions.delOrganization,
  joinOrganization: ContestOrganizationActions.joinOrganization,
  transferOrganizationLeader: ContestOrganizationActions.transferOrganizationLeader,
}, dispatch);
const mapStateToProps = (state, props) => {
  const teamId = get(props, 'teamId', null);
  return {
    team: teamId ? ContestOrganizationSelectors.getContestOrganizationEntity(state)(teamId) : {
      nickname: '',
      password: '',
      public: true,
      schoolname: '',
      coachname: '',
      slogan: '',
    },
  };
};

@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class TeamDrawer extends React.PureComponent {
  static propTypes = {
    manage: PropTypes.bool,
    status: PropTypes.shape({}),
    visible: PropTypes.bool.isRequired,
    detail: PropTypes.bool,
    team: PropTypes.shape({}),
    delOrganization: PropTypes.func.isRequired,
    editOrganization: PropTypes.func.isRequired,
    addOrganization: PropTypes.func.isRequired,
    leaveOrganization: PropTypes.func.isRequired,
    joinOrganization: PropTypes.func.isRequired,
    transferOrganizationLeader: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    emitRefeshList: PropTypes.func.isRequired,
    account: ContestAccountPropTypes,
    contest: ContestPropTypes,
    teamId: PropTypes.number,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    manage: false,
    teamId: null,
    detail: false,   //编辑信息
    account: {},
    contest: {},
    status: {},
    team: {
      nickname: '',
      password: '',
      public: true,
      schoolname: '',
      coachname: '',
      slogan: '',
      group_id: null,
    },
    teamGroups: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'team'), prevState.teamRaw)) {
      return {
        team: get(nextProps, 'team'),
        teamRaw: get(nextProps, 'team'),
      };
    }
    return null;
  }

  state = {
    joinPwd: '',
    team: {},
    teamRaw: null,
  };

  MEMBER_COLUMN = [{
    title: '姓名',
    key: 'realname',
    dataIndex: 'realname',
  }, {
    title: '性别',
    key: 'sex',
    dataIndex: 'sex',
    width: 60,
  }, {
    title: '身份',
    key: 'role',
    dataIndex: 'role',
    width: 80,
  }];

  MEMBER_MGR_COLUMN = [{
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 200,
  }];

  getGroupName = (gid) => {
    const rel = this.props.teamGroups.find(item => get(item, 'id') === gid);
    return get(rel, 'name', '未知');
  };

  handleSubmitClick = () => {
    if (!get(this.state.team, 'group_id')) {
      message.error('请先选择分组');
      return;
    }
    if (this.props.detail) {
      this.props.editOrganization({
        team: this.state.team,
        contestId: this.props.contest.id,
        teamId: this.props.teamId,
        onSuccess: () => {},
      });
    } else {
      this.props.addOrganization({
        team: this.state.team,
        contestId: this.props.contest.id,
        onSuccess: () => {
          this.props.emitRefeshList();
          this.props.onClose();
        },
      });
    }
  };

  handleDelTeam = () => {
    this.props.delOrganization({
      contestId: this.props.contest.id,
      teamId: this.props.teamId,
      onSuccess: () => {
        this.props.emitRefeshList();
        this.props.onClose();
      },
    });
  };

  handleJoinClick = () => {
    this.props.joinOrganization({
      contestId: this.props.contest.id,
      teamId: this.state.team.id,
      password: this.state.joinPwd,
    });
  };

  handleLeaveOrganization = (account) => {
    this.props.leaveOrganization({
      contestId: this.props.contest.id,
      teamId: this.state.team.id,
      accountId: account ? account.id : null,
    });
  };

  handleTransferLeader = (account) => {
    this.props.transferOrganizationLeader({
      contestId: this.props.contest.id,
      teamId: this.state.team.id,
      accountId: account.id,
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      team: {
        ...this.state.team,
        [keyName]: target.value,
      },
    });
  };

  handleSwitchChange = (checked) => {
    this.setState({
      team: {
        ...this.state.team,
        public: checked,
      },
    });
  };

  handleGroupChanged = (selectedKeys) => {
    this.setState({
      team: {
        ...this.state.team,
        group_id: selectedKeys,
      },
    });
  };

  renderMemberData = (team) => {
    const isLeaderLogined = get(this.props.account, 'id') === get(this.props.team, 'leader.id');
    return get(team, 'members', []).map((item) => {
      const isLeader = get(item, 'id') === get(this.props.team, 'leader.id');
      return {
        key: get(item, 'id', ''),
        realname: get(item, 'realname', ''),
        sex: get(item, 'sex') === 0 ? '女' : '男',
        action: ((isLeaderLogined && get(item, 'id') !== get(this.props.account, 'id') && !this.props.manage)) ? <>
          <Popconfirm title="确定要执行这个操作吗？你将失去对这个队伍的管理权限。" onConfirm={() => this.handleTransferLeader(item)}>
            <Button size="small">设为队长</Button>
          </Popconfirm>
          <Popconfirm title="确定要执行这个操作吗？" onConfirm={() => this.handleLeaveOrganization(item)}>
            <Button size="small" type="danger" style={{ marginLeft: 8 }}>请出退伍</Button>
          </Popconfirm>
        </> : '---',
        role: isLeader ? <strong style={{ color: '#f00' }}>队长</strong> : '队员',
      };
    });
  };

  render() {
    const isLeader = get(this.props.account, 'id') === get(this.props.team, 'leader.id');
    const iamInTeam = get(this.props.team, 'members', []).filter(v => get(v, 'id') === get(this.props.account, 'id')).length > 0;
    const { team = {} } = this.state;
    const formItemLayout = {
      labelCol: {
        sm: { span: 4 },
      },
      wrapperCol: {
        sm: { span: 20 },
      },
    };
    return (
      <Drawer
        title={this.props.detail ? '队伍详情' : '创建队伍'}
        width={552}
        visible={this.props.visible}
        onClose={this.props.onClose}
      >
        <Form {...formItemLayout}>
          {this.props.detail && <>
            <Table
              bordered
              size="small"
              columns={[
                ...this.MEMBER_COLUMN,
                ...(isLeader ? this.MEMBER_MGR_COLUMN : [])
              ]}
              dataSource={this.renderMemberData(this.props.team)}
              pagination={false}
            />
            <Divider />
          </>}
          {(isLeader  || this.props.manage || !this.props.detail) ? <>
            <Form.Item label="队伍名称" required>
              <Input
                required
                placeholder="队伍名称"
                value={get(team, 'nickname', '')}
                onChange={this.handleInputChange('nickname')}
              />
            </Form.Item>
            <Form.Item label="队伍口号">
              <Input
                placeholder="队伍口号"
                value={get(team, 'slogan', '')}
                onChange={this.handleInputChange('slogan')}
              />
            </Form.Item>
            <Form.Item label="所属分组" required>
              <Select
                showSearch
                placeholder="请选择分组"
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                optionFilterProp="children"
                value={get(team, 'group_id')}
                onChange={this.handleGroupChanged}
              >
                {this.props.teamGroups.map((group) => {
                  const cap = get(group, 'capacity', 0);
                  return <Select.Option key={get(group, 'id')} value={get(group, 'id')}>
                    {get(group, 'name')} {cap > 0 ? `(最多${cap}队)` : ''}
                  </Select.Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item label="教练名称">
              <Input
                placeholder="教练名称"
                value={get(team, 'coachname', '')}
                onChange={this.handleInputChange('coachname')}
              />
            </Form.Item>
            <Form.Item label="公开队伍" required>
              <Switch checked={get(team, 'public', false)} onChange={this.handleSwitchChange} />
            </Form.Item>
            <Form.Item label="入队密码">
              <Input.Password
                placeholder={this.props.detail ? '留空则不会有任何改动' : '入队密码'}
                disabled={get(team, 'public', false)}
                value={get(team, 'password')}
                onChange={this.handleInputChange('password')}
              />
            </Form.Item>

            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >
              {this.props.detail && <>
                {(isLeader || this.props.manage) && <Popconfirm
                  title="确定要解散队伍吗？队伍信息将被永久删除！"
                  okType="danger"
                  onConfirm={this.handleDelTeam}
                >
                  <Button type="danger">{this.props.manage ? '删除队伍' : '解散队伍'}</Button>
                </Popconfirm>}
              </>}
              <Button
                onClick={this.handleSubmitClick}
                type="primary"
                style={{ marginLeft: 16 }}
              >
                {this.props.detail ? '保存' : '确定'}
              </Button>
            </div>
          </> : <>
            <Form.Item label="队伍名称">
              {get(team, 'nickname')}
            </Form.Item>
            <Form.Item label="所属分组">
              {this.getGroupName(get(team, 'group_id'))}
            </Form.Item>
            <Form.Item label="队伍口号">
              {get(team, 'slogan')}
            </Form.Item>
            <Form.Item label="教练名称">
              {get(team, 'coachname')}
            </Form.Item>
            <Form.Item label="公开队伍">
              {get(team, 'public') ? '是' : '否'}
            </Form.Item>
            <Divider />
            {!iamInTeam && !get(team, 'public') && !get(team, 'full') && <Form.Item label="入队密码">
              <Input.Password
                value={this.state.joinPwd}
                onChange={(e) => { this.setState({ joinPwd: e.target.value }); }}
              />
            </Form.Item>}
            {iamInTeam ? <Popconfirm
              title="确定要离开队伍吗？"
              onConfirm={this.handleLeaveOrganization}
            >
              <Button type="danger" block>退出队伍</Button>
            </Popconfirm> : <Button
              block
              disabled={
                ((!get(team, 'public') && this.state.joinPwd === ''))
                || get(this.props.status, 'joined', false)
                || get(team, 'full')
              }
              type="primary"
              onClick={this.handleJoinClick}
            >
              {get(team, 'full') ? '队伍已满员' : '加入队伍'}
            </Button>}
          </>}
        </Form>
      </Drawer>
    );
  }
}

export default TeamDrawer;
