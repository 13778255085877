import 'styles/account/login_form_widget.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar, Button, Input, Switch 
} from 'antd';
import Vaptcha from 'scripts/common/widgets/vaptcha';
import { buildPath, buildResourcePath, getQuery } from 'scripts/common/utils/location_helper';
import { get, noop } from 'lodash';
import { AccountRoutes, ContestRootRoutes } from 'scripts/apps/routes';
import { withRouter } from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountSelectors } from 'scripts/common/logic/account';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { WeChatActions } from 'scripts/common/logic/wechat';
import { KEY_CODES } from 'scripts/common/constants/keycodes';

//登录页面组件
const mapStateToProps = (state, props) => {
  if (props.module === 'contest') {
    const account = AccountSelectors.getLoginAccount(state) || {};
    const status = AccountSelectors.getLoginStatus(state);
    return {
      wejudgeAccountFetched: get(status, 'fetched', false),
      wejudgeAccountLogined: get(status, 'logined', false),
      wejudgeAccount: account,
    };
  }
  return {};
};

@withRouter
@connect(mapStateToProps, dispatch => bindActionCreators({
  getWechatQrconnectStatus: WeChatActions.getWechatQrconnectStatus,
}, dispatch))
class LoginForm extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    doLogin: PropTypes.func.isRequired,
    changePage: PropTypes.func,
    module: PropTypes.string,
    payloads: PropTypes.shape({}),
    wejudgeAccountFetched: PropTypes.bool,
    wejudgeAccountLogined: PropTypes.bool,
    getWechatQrconnectStatus: PropTypes.func,
    wejudgeAccount: PropTypes.shape({ }),
  };

  static defaultProps = {
    changePage: noop,
    getWechatQrconnectStatus: noop,
    module: '',
    wejudgeAccountFetched: false,
    wejudgeAccountLogined: false,
    wejudgeAccount: {},
    payloads: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      pendingSubmit: false,
      username: '',
      password: '',
      remember_me: false,
      requireVapcha: false,
      vapchaUserId: '',
      vaptchaPayloads: {},
      backToPath: true,
      wejudgeAccountFetched: false,
      viewFastLoginForm: props.module === 'contest' && props.wejudgeAccountLogined,
    };
    this.getWXInfoStatus();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.module === 'contest' && !prevState.wejudgeAccountFetched && nextProps.wejudgeAccountFetched) {
      return {
        wejudgeAccountFetched: nextProps.wejudgeAccountFetched,
        viewFastLoginForm: nextProps.module === 'contest' && nextProps.wejudgeAccountLogined,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.username !== this.state.username) {
      this.setState({     // eslint-disable-line
        requireVapcha: false,
      });
    }
  }

  getWXInfoStatus = () => {
    this.props.getWechatQrconnectStatus({
      onSuccess: (QRInfos) => {
        this.setState({
          wxOpenId: get(QRInfos, 'openid', ''),
          wxUserInfo: QRInfos,
        });
      },
    });
  };

  gotoNormalLogin = () => {
    this.setState({
      viewFastLoginForm: false,
    });
  };

  handleUserNameChange = (sender) => {
    const username = sender.target.value;
    this.setState({
      username,
    });
  };

  handlePasswordChange = (sender) => {
    this.setState({
      password: sender.target.value,
    });
  };

  handleRMChange = (checked) => {
    this.setState({
      remember_me: checked,
    });
  };

  handleBTPChange = (checked) => {
    this.setState({
      backToPath: !checked,
    });
  };

  handleRedirect = () => {
    // 强制触发刷新
    const query = getQuery(this.props.location);
    if (this.state.backToPath) {
      window.location.href = get(query, 'from', '');
    } else {
      window.location.href = buildPath(AccountRoutes.DASHBOARD.INDEX);
    }
  };

  handleRedirectContest =() => {
    // 强制触发刷新
    window.location.href = buildPath(
      ContestRootRoutes.HOME,
      this.props.payloads
    );
  };

  handleLogin = () => {
    const { vaptchaPayloads = {} } = this.state;
    this.setState({ pendingSubmit: true });
    this.props.doLogin({
      username: this.state.viewFastLoginForm
        ? this.props.wejudgeAccount.email.trim() : this.state.username.trim(),
      password: this.state.password,
      remember_me: this.state.remember_me,
      ...this.props.payloads,
      ...vaptchaPayloads,
      onSuccess: this.props.module === 'contest' ? this.handleRedirectContest : this.handleRedirect,
      onError: (e) => {
        this.setState({ pendingSubmit: false, vaptchaPayloads: {} });
        if (get(e, 'payload.action') === 'require_verification') {
          this.setState({
            requireVapcha: true,
            vapchaUserId: get(e, 'payload.user_id', ''),
          });
        }
      },
    });
  };

  handleValidate = (payloads) => {
    this.setState({
      requireVapcha: false,
      vaptchaPayloads: payloads,
    });
  };

  handleEnterSubmit = (e) => {
    if (e.keyCode === KEY_CODES.ENTER) {
      this.handleLogin();
    }
  };

  renderNormalForm = () => {
    return (
      <>
        <Input
          className="input"
          prefix={<UserOutlined />}
          placeholder="Email"
          size="large"
          tabIndex={1}
          value={this.state.username}
          onChange={this.handleUserNameChange}
          onKeyDown={this.handleEnterSubmit}
        />
        <Input.Password
          className="input"
          prefix={<LockOutlined />}
          placeholder="登录密码"
          size="large"
          tabIndex={2}
          onChange={this.handlePasswordChange}
          value={this.state.password}
          onKeyDown={this.handleEnterSubmit}
        />
        {!this.props.module && <div className="more">
          <div className="remember-me">
            <Switch
              tabIndex={3}
              onChange={this.handleRMChange}
              checked={this.state.remember_me}
            /> 记住登录&nbsp;&nbsp;
            <Switch
              tabIndex={4}
              onChange={this.handleBTPChange}
              checked={!this.state.backToPath}
            /> 前往仪表盘
          </div>
          <a className="forget-password" onClick={() => this.props.changePage('forget')}>忘记密码？</a>
        </div>}
        {this.state.requireVapcha && <Vaptcha
          userId={this.state.vapchaUserId}
          onValidate={this.handleValidate}
        />}
        <Button
          loading={this.state.pendingSubmit}
          disabled={this.state.requireVapcha}
          size="large"
          type="primary"
          onClick={this.handleLogin}
          className="action-btn"
          tabIndex={5}
        >
          登录
        </Button>
      </>
    );
  };

  renderFastLoginForm = () => {
    if (!this.props.wejudgeAccountLogined) return null;
    if (this.props.module === 'contest') {
      return (
        <div className="fast-login">
          <Avatar
            className="user-avator"
            icon={<UserOutlined />}
            size={64}
            src={buildResourcePath(get(this.props.wejudgeAccount, 'avator'))}
          />
          <div className="meta">
            <div className="nickname">{this.props.wejudgeAccount.nickname}</div>
            <div className="email">{this.props.wejudgeAccount.email}</div>
          </div>
          <div className="action-bar">
            <Button size="large" onClick={this.handleLogin} type="primary" className="action-btn">快速登录</Button>
            <Button size="large" onClick={this.gotoNormalLogin} className="action-btn">使用其他账号登录</Button>
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    return <div className="login-form">
      {this.state.wxOpenId && <div className="wechat_connect_user_info">
        <img className="headimg" width={32} height={32} src={get(this.state.wxUserInfo, 'headimgurl')} alt="" />
        微信用户 <span className="nickname">{get(this.state.wxUserInfo, 'nickname')} </span> 待绑定
      </div>}
      {!this.state.viewFastLoginForm ? this.renderNormalForm() : this.renderFastLoginForm()}
    </div>;
  }
}

export default LoginForm;
