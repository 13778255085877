import React from 'react';
import { get, noop, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import { LessonPropTypes } from 'scripts/common/prop-types/course';

import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { put } from 'redux-saga/effects';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';

class LessonDescEditor extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.string.isRequired,
    lesson: LessonPropTypes.isRequired,
    editLesson: PropTypes.func,
  };

  static defaultProps = {
    editLesson: noop,
  };

  state = {
    description: '',
    currentLesson: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(prevState.currentLesson, 'id'), get(nextProps.lesson, 'id'))) {
      return {
        description: get(nextProps.lesson, 'description', ''),
        currentLesson: nextProps.lesson,
      };
    }
    return null;
  }

  handleDescClick = () => {
    this.props.editLesson({
      description: this.state.description,
      courseId: this.props.courseId,
      lessonId: this.props.lessonId,
      onSuccess: () => {
        message.success('修改课堂信息成功');
      },
    });
  };

  handleEditorChange = (text) => {
    this.setState({
      description: text,
    });
  };

  render() {
    return (
      <div className="group">
        <div className="header">
          <div className="title">课堂介绍<span className="subtitle">当你的课堂没有视频和课件时，我将会显示哦！</span></div>
          <div className="extra_btn">
            <Button type="primary" onClick={this.handleDescClick}>
              保存
            </Button>
          </div>
        </div>
        <MarkdownEditor
          onChange={this.handleEditorChange}
          defaultValue={this.state.description}
        />
      </div>
    );
  }
}

export default LessonDescEditor;
