import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ManagementActions, ManagementSelectors } from 'scripts/common/logic/management';
import { Select, Table, Tag, Menu } from 'antd';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { JudgeFlagEnum, JudgeLanguageEnum } from 'scripts/common/enums/judge';
import { get, isArray } from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import { AntTableMiddleOutsideHeight } from 'scripts/common/constants/table';
import { buildPath, getQuery } from 'scripts/common/utils/location_helper';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { ManagementRoutes } from 'scripts/apps/routes';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';

const TYPE_MAPPING = {
  asgn: '教学',
  contest: '比赛',
  collection: '题库',
};

const STATUS_COLUMNS = [
  {
    title: '模块',
    width: 80,
    dataIndex: 'module_name',
    align: 'center',
  },
  {
    title: 'ID',
    width: 80,
    dataIndex: 'id',
    align: 'center',
  }, {
    title: '题目',
    dataIndex: 'problem',
    align: 'center',
  }, {
    title: '评测状态',
    width: 140,
    dataIndex: 'flag',
    align: 'center',
  }, {
    title: '提交者',
    dataIndex: 'author',
    align: 'center',
  }, {
    title: '最大运行时长',
    width: 120,
    dataIndex: 'exe_time',
    align: 'center',
  }, {
    title: '最高内存占用',
    width: 120,
    dataIndex: 'exe_mem',
    align: 'center',
  }, {
    title: '代码长度',
    width: 100,
    dataIndex: 'code_length',
    align: 'center',
  }, {
    title: '代码语言',
    width: 100,
    dataIndex: 'code_lang',
    align: 'center',
  }, {
    title: '提交时间',
    width: 100,
    dataIndex: 'update_time',
    align: 'center',
  }
];

const JudgeQueueView = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const queryset = getQuery(location);

  const [isLoading, setIsLoading] = useState(false);
  const [tableHeight, setTtableHeight] = useState(0);

  const [moduleType, setModuleType] = useState(match.params?.type || 'all');
  const [filterFlag, setFilterFlag] = useState(queryset?.flag || '-3');
  const [pageQuery, setPageQuery] = useState({
    page: queryset?.page || 1,
    limit: queryset?.limit || 40,
  });

  const judgeStatusList = useSelector(
    state => ManagementSelectors.monitor.judgeQueueListViewSelector(state)
  );
  const paginationParams = useSelector(
    state => ManagementSelectors.monitor.judgeQueueListViewPageSelector(state)
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(ManagementActions.getMonitorJudgeQueueList({
      type: moduleType,
      query: {
        ...pageQuery,
        flag: filterFlag,
      },
      onComplete: () => {
        setIsLoading(false);
      },
    }));
  }, [moduleType, pageQuery, filterFlag]);

  const handlePageChange = (page, pageSize) => {
    history.push(buildPath(
      ManagementRoutes.MONITOR.JUDGE_QUEUE_WITH_TYPE,
      {},
      match.params,
      {
        flag: filterFlag,
        limit: pageSize,
        page,
      }
    ));
    setPageQuery({ page, limit: pageSize });
  };

  const handleFilterFlagChange = (value) => {
    history.push(buildPath(
      ManagementRoutes.MONITOR.JUDGE_QUEUE_WITH_TYPE,
      {},
      match.params,
      {
        flag: filterFlag,
      }
    ));
    setPageQuery({ ...pageQuery, page: 0 });
    setFilterFlag(value);
  };

  const handleTypeChange = ({ key }) => {
    history.push(buildPath(
      ManagementRoutes.MONITOR.JUDGE_QUEUE_WITH_TYPE,
      {
        type: key,
      },
      match.params
    ));
    setPageQuery({ ...pageQuery, page: 0 });
    setFilterFlag('-3');
    setModuleType(key);
  };

  const handleContainerHeightChange = (width, height) => {
    setTtableHeight(height);
  };

  const optPagination = {
    current: get(paginationParams, 'page', 1),
    pageSize: get(paginationParams, 'limit', 40),
    showQuickJumper: true,
    showSizeChanger: true,
    pageSizeOptions: ['20', '40', '60'],
    onChange: handlePageChange,
    total: get(paginationParams, 'total', 40),
  };

  const renderListData = useCallback(() => {
    if (!isArray(judgeStatusList)) return [];
    const canViewMeta = (item) => {
      const flag = get(item, 'flag');
      return flag === JudgeFlagEnum.AC.value || flag === JudgeFlagEnum.PE.value
        || flag === JudgeFlagEnum.WA.value || flag === JudgeFlagEnum.OLE.value;
    };

    const renderJudgeFlag = (flag) => {
      const flagEnum = JudgeFlagEnum.enumValueOf(flag);
      return <Tag color={get(flagEnum, 'color')}>{get(flagEnum, 'desc', '未知')}</Tag>;
    };

    const renderExeTime = (item) => {
      if (canViewMeta(item)) {
        const exetime = get(item, 'exe_time', 0);
        return `${exetime} MS`;
      }
      return '---';
    };

    const renderExeMem = (item) => {
      if (canViewMeta(item)) {
        const exemem = get(item, 'exe_mem', 0);
        return `${exemem} KB`;
      }
      return '---';
    };
    return judgeStatusList.map((item) => {
      if (!item) return {};
      return {
        key: get(item, 'id', ''),
        id: get(item, 'id', ''),
        module_name: get(TYPE_MAPPING, get(item, 'type', ''), '未知'),
        problem: get(item, 'problem_id', ''),
        author: get(item, 'author_id', ''),
        flag: renderJudgeFlag(get(item, 'flag')),
        exe_time: renderExeTime(item),
        exe_mem: renderExeMem(item),
        code_length: `${get(item, 'code_length', 0)}B`,
        code_lang: get(JudgeLanguageEnum.enumValueOf(get(item, 'code_lang', -100)), 'desc', '未知'),
        update_time: formatTimeFromNow(get(item, 'create_time', 0)),
      };
    });
  }, [judgeStatusList]);

  return <>
    <div className="filter-bar">
      <div className="type-bar">
        <Menu onClick={handleTypeChange} selectedKeys={[moduleType]} mode="horizontal">
          <Menu.Item key="collection">题库</Menu.Item>
          <Menu.Item key="asgn">教学</Menu.Item>
          <Menu.Item key="contest">比赛</Menu.Item>
          <Menu.Item key="all">全站（较慢）</Menu.Item>
        </Menu>
      </div>
      <div className="flag-bar">
        <Select value={filterFlag} onChange={handleFilterFlagChange} style={{ width: '250px' }}>
          <Select.Option value="-3">不限评测状态</Select.Option>
          {enumMap(JudgeFlagEnum, item => (
            <Select.Option key={get(item, 'value', '').toString()} value={get(item, 'value', '').toString()}>{get(item, 'desc', '')}({get(item, 'abbr', '')})</Select.Option>
          ), item => item.value > -3)}
        </Select>
      </div>
    </div>
    <div className="table-main">
      <ReactResizeDetector handleHeight onResize={handleContainerHeightChange} />
      <Table
        loading={isLoading}
        className="judge_status_list_table"
        size="middle"
        dataSource={isLoading ? [] : renderListData()}
        pagination={isLoading ? false : optPagination}
        columns={STATUS_COLUMNS}
        scroll={{
          y: tableHeight - AntTableMiddleOutsideHeight,
          scrollToFirstRowOnChange: true,
        }}
        bordered
      />
    </div>
  </>;
};

JudgeQueueView.propTypes = {};
JudgeQueueView.defaultProps = {};

export default JudgeQueueView;
