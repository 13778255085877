import React from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Table, Rate, Tag } from 'antd';
import PropTypes from 'prop-types';
import { get, noop, truncate } from 'lodash';
import { buildPath, getCurrentURL } from 'scripts/common/utils/location_helper';
import { filterHTML } from 'scripts/common/utils/filter';
import { indexToChar } from 'scripts/common/utils/unit';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { CourseRoutes, AsgnRoutes, AsgnProblemRoutes } from 'scripts/apps/routes';
import { AsgnReportPropTypes } from 'scripts/common/prop-types/asgn';
import { withRouter } from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

@withRouter
class ReportListTable extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    reportDatas: PropTypes.arrayOf(AsgnReportPropTypes).isRequired,
    onSelectorChanged: PropTypes.func.isRequired,
    studentsList: PropTypes.arrayOf({}),
  };

  static defaultProps = {
    studentsList: [],
  };

  COLUMNS = [{
    title: '#ID',
    dataIndex: 'id',
    width: 60,
  }, {
    title: '学号',
    dataIndex: 'code',
  }, {
    title: '姓名',
    dataIndex: 'realname',
  }, {
    title: '最后更新时间',
    dataIndex: 'update_time',
  }, {
    title: '完成（通过/提交）',
    dataIndex: 'ratio',
  }, {
    title: '自动评分',
    dataIndex: 'judge_score',
  }, {
    title: '最终成绩',
    dataIndex: 'finally_score',
  }, {
    title: '备注',
    dataIndex: 'remark',
    width: 80,
  }];

  rowSelection = {
    onChange: this.props.onSelectorChanged,
  };

  renderListData = () => {
    const dontFinished = [];

    const reportDatas = this.props.reportDatas.map((item) => {
      if (!item) return null;
      return {
        key: get(item, 'id', ''),
        id: get(item, 'id', ''),
        code: get(item, 'student_info.code'),
        realname: get(item, 'student_info.realname'),
        update_time: formatTime(item.update_time, 'LONG_DATETIME'),
        ratio: <div>{get(item, 'solved_counter', 0)} ( {get(item, 'accepted', 0)} / {get(item, 'submission', 0)} )</div>,
        judge_score: get(item, 'judge_score', 0),
        finally_score: get(item, 'finally_score', 0),
        remark: item.teacher_check ? <div><CheckCircleTwoTone twoToneColor="#52c41a" /> 已批阅</div> : <div>未批阅</div>,
      };
    }).filter(item => !!item);

    this.props.studentsList.forEach((item) => {
      if (item) {
        dontFinished.push({
          key: `${get(item, 'id', '')}`,
          id: <div>-</div>,
          code: get(item, 'code'),
          realname: get(item, 'realname'),
          update_time: <div>-</div>,
          ratio: <div>-</div>,
          judge_score: <div>-</div>,
          finally_score: <div>-</div>,
          remark: <div>未访问</div>,
        });
      }
    });
    return [
      ...dontFinished,
      ...reportDatas
    ];
  };

  render() {
    const datas = this.renderListData();
    return (<Table
      size="middle"
      rowSelection={this.rowSelection}
      columns={this.COLUMNS}
      dataSource={datas}
      pagination={false}
      onRow={(record) => {
        return {
          style: { cursor: 'pointer' },
          onClick: () => {
            const { history, match } = this.props;
            history.push(buildPath(
              AsgnRoutes.CHECKUP_TEACHER,
              {
                ...match.params,
                reportId: record.id,
              }
            ));
          },       // 双击行
        };
      }}
    />);
  }
}
export default ReportListTable;
