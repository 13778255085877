import 'styles/education/asgn/management.scss';

import React from 'react';
import PropTypes from 'prop-types';
import  moment  from 'moment';
import {
  get, noop, set, isNaN 
} from 'lodash';
import {
  Form, Card, Input, Row, Col, Switch, DatePicker, Slider, InputNumber
} from 'antd';
import JudgeLanguageCheckBox from 'scripts/common/widgets/language_checkbox';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { nowTime } from 'scripts/common/utils/time_formatter';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { debounce } from 'scripts/common/utils/functional';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

class AsgnInfoEditor extends React.PureComponent {
  static propTypes = {
    asgn: AsgnPropTypes,
    handleChange: PropTypes.func,
  };

  static defaultProps = {
    asgn: {
      title: '',
      description: '',
      full_score: 100,
      code_language: 0,
      options: {
        hide_problem_title: false,
        hide_student_code: false,
        public_answer: false,
        code_problem_multi_submit: true,
        objective_problem_multi_submit: false,
      },
    },
    handleChange: noop,
  };

  state = {
    asgn: JSON.parse(JSON.stringify(this.props.asgn)),
  };

  onDateChange = (date) => {
    const value = parseFloat(date.format('X'));
    this.handleInputChange('ptions.public_answer_at')({
      target: {
        value,
      },
    });
  };

  onScoreChange = (value) => {
    if (isNaN(value)) {
      return;
    }
    this.handleInputChange('full_score')({
      target: {
        value,
      },
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState(set({
      asgn: { ...this.state.asgn },
    }, `asgn.${keyName}`, target.value), () => {
      this.props.handleChange(this.state.asgn);
    });
  };

  handleEditorChange = keyName => debounce((value) => {
    this.setState(set({
      asgn: { ...this.state.asgn },
    }, `asgn.${keyName}`, value), () => {
      this.props.handleChange(this.state.asgn);
    });
  }, INPUT_CHANGE_DEBOUNDCE_TIME);

  render() {
    const { asgn } = this.state;
    return (
      <div className="asgn_setting_editor">
        <Form.Item>
          <Input
            key="title"
            value={get(asgn, 'title', '')}
            onChange={this.handleInputChange('title')}
            placeholder="作业名称"
          />
        </Form.Item>
        <Form.Item>
          <MarkdownEditor
            height="20em"
            defaultValue={get(asgn, 'description', '')}
            onChange={this.handleEditorChange('description')}
            placeholder="作业内容"
          />
        </Form.Item>
        <Form.Item>
          <Row gutter={16}>
            <Col span={8}>
              <Card title="最高分数" style={{ height: 225 }}>
                <div className="full_score_box">
                  <div className="left_side">
                    <Slider
                      min={0}
                      max={200}
                      onChange={this.onScoreChange}
                      value={get(asgn, 'full_score', 0)}
                      step={1}
                    />
                  </div>
                  <div className="right_side">
                    <InputNumber
                      min={0}
                      max={200}
                      step={10}
                      value={get(asgn, 'full_score', 0)}
                      onChange={this.onScoreChange}
                    />
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className="setting-card" title="公开答案" style={{ height: 225 }}>
                <div className="item">
                  <Switch
                    onChange={(checked) => { this.handleInputChange('options.public_answer')({ target: { value: checked } }); }}
                    checked={get(asgn, 'options.public_answer', false)}
                  /> 开启
                </div>
                <div className="item">
                  <Switch
                    disabled={!get(asgn, 'options.public_answer', false)}
                    onChange={(checked) => { this.handleInputChange('options.public_answer_at')({ target: { value: checked ? -1 : moment(nowTime().unix() / 1000) } }); }}
                    checked={get(asgn, 'options.public_answer_at', 0) < 0}
                  /> 自动选择时间
                </div>
                <div className="item">
                  <DatePicker
                    showTime
                    disabled={!get(asgn, 'options.public_answer', false) || get(asgn, 'options.public_answer_at', 0) < 0}
                    format="YYYY-MM-DD HH:mm:ss"
                    value={get(asgn, 'options.public_answer_at', 0) < 0 ? null : moment(get(asgn, 'options.public_answer_at', 0) * 1000)}
                    onChange={this.onDateChange}
                  />
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card className="setting-card" title="开关选项" style={{ height: 225 }}>
                <div className="item">
                  <Switch
                    onChange={(checked) => { this.handleInputChange('options.hide_problem_title')({ target: { value: checked } }); }}
                    checked={get(asgn, 'options.hide_problem_title', false)}
                  /> 隐藏代码题标题
                </div>
                <div className="item">
                  <Switch
                    onChange={(checked) => { this.handleInputChange('options.hide_student_code')({ target: { value: checked } }); }}
                    checked={get(asgn, 'options.hide_student_code', false)}
                  /> 学生不能查看自己的代码
                </div>
                <div className="item">
                  <Switch
                    onChange={(checked) => { this.handleInputChange('options.code_problem_multi_submit')({ target: { value: checked } }); }}
                    checked={get(asgn, 'options.code_problem_multi_submit', true)}
                  /> 允许代码题重复提交
                </div>
                <div className="item">
                  <Switch
                    onChange={(checked) => { this.handleInputChange('options.objective_problem_multi_submit')({ target: { value: checked } }); }}
                    checked={get(asgn, 'options.objective_problem_multi_submit', false)}
                  /> 允许客观题重复提交
                </div>
              </Card>
            </Col>
          </Row>
        </Form.Item>
        <Card title="可用评测语言">
          <JudgeLanguageCheckBox
            language={get(asgn, 'code_language', 0)}
            onChange={this.handleInputChange('code_language')}
          />
        </Card>
      </div>
    );
  }
}
export default AsgnInfoEditor;
