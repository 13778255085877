import React from 'react';
import PropTypes from 'prop-types';
import { List, Typography } from 'antd';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { AsgnProblemPropTypes, AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { ProblemEntitySelectors } from 'scripts/common/logic/problem/selectors';
import { JudgeLanguageEnum } from 'scripts/common/enums/judge';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AsgnProblemRoutes } from 'scripts/apps/routes';

const mapStateToProps = (state, props) => {
  const problem = ProblemEntitySelectors.getProblemEntity(state)(props.problemId);
  const answerCases = problem.options.judge_options.answer_cases;
  const demoCases = problem.options.judge_options.demo_cases;
  const match = matchPath(props.location.pathname, {
    path: AsgnProblemRoutes.CORRECT_ANSWER_DETAIL,
  });
  let lang = '0';
  if (match) {
    lang = get(match.params, 'lang', '0');
  }
  return {
    matchLang: lang,
    answerCases,
    problem,
    demoCases,
  };
};

@withRouter
@connect(mapStateToProps, null)
class CorrectAnswerList extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    asgn: AsgnPropTypes,
    matchLang: PropTypes.string,
    asgnProblem: AsgnProblemPropTypes.isRequired,
    answerCases: PropTypes.shape(),
    demoCases: PropTypes.shape(),
    problem: ProblemPropTypes,
    hasFetchAnswerCases: PropTypes.bool,
  };

  static defaultProps = {
    matchLang: 0,
    asgn: null,
    answerCases: {},
    demoCases: {},
    problem: {},
    hasFetchAnswerCases: false,
  };

  state = {
    language: 1,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchAnswerCases) {
      return {
        ...prevState,
        hasFetchAnswerCases: true,
      };
    }
    return null;
  }

  getLanguageMask = () => {
    if (this.props.asgn && this.props.asgnProblem) {
      const plang = get(this.props.asgnProblem, 'code_language', -1);
      if (plang < 0) {
        this.setState({
          hasFetchAnswerCases: false,
        });
        return get(this.props.asgn, 'code_language', 0);
      }
      this.setState({
        hasFetchAnswerCases: false,

      });
      return plang;
    }
    this.setState({
      hasFetchAnswerCases: false,
    });
    return 0;
  };

  renderItem = (key) => {
    const answer = this.props.answerCases[key];
    const language = JudgeLanguageEnum.enumValueOf(parseInt(key, 10));
    const empty = isEmpty(answer);
    return <List.Item>
      <Typography.Text disabled={empty} strong={this.props.matchLang === key}>
        {empty ? get(language, 'desc') : <a
          onClick={() => {
            this.props.history.push(buildPath(
              AsgnProblemRoutes.CORRECT_ANSWER_DETAIL,
              {
                lang: key,
              },
              this.props.match.params
            ));
          }}
        >
          {get(language, 'desc')}
        </a>}
      </Typography.Text>
    </List.Item>;
  };

  render() {
    const langKeys = Object.keys(this.props.answerCases);
    return (<>
      <div className="correct_answer_list">
        请选择查看对应语言的答案：
        <List
          dataSource={langKeys}
          renderItem={this.renderItem}
        />
      </div>
    </>
    );
  }
}

export default CorrectAnswerList;
