import React from 'react';
import { ClusterOutlined, MailFilled, UserOutlined } from '@ant-design/icons';
import {
  Avatar, Row, Col, Statistic, Tooltip 
} from 'antd';
import { truncate, get } from 'lodash';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { buildAvatarPath } from 'scripts/common/utils/location_helper';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';

class AccountInfoCard extends React.PureComponent {
  static propTypes = {
    account: AccountPropTypes,
  };

  static defaultProps = {
    account: null,
  };

  renderRoleFlag = (role) => {
    const roleEnum = AccountRoleEnum.enumValueOf(role);
    return get(roleEnum, 'desc', '');
  };

  renderMetaItems = () => {
    const { account } = this.props;
    const isEmailValidated = get(account, 'email_validated', false);
    const metas = [
      {
        key: 'role',
        icon: <UserOutlined />,
        body: this.renderRoleFlag(get(account, 'role', 0)),
      },
      {
        key: 'email',
        icon: <MailFilled />,
        body: isEmailValidated ? get(account, 'email') : <span style={{ color: 'red', cursor: 'pointer' }}>
          {get(account, 'email')}{!get(account, 'email_validated') && '(未验证)'}
        </span>,
      },
      {
        key: 'last_login',
        icon: <ClusterOutlined />,
        body: <Tooltip title="最近登录时间">{formatTimeFromNow(get(this.props.account, 'last_login_time'))}</Tooltip>,
      }
    ];
    return metas.map((item) => {
      return <div className="meta-item" key={item.key}>
        <div className="icon">{item.icon}</div>
        <div className="body">{item.body}</div>
      </div>;
    });
  };

  render() {
    const { account } = this.props;

    return (
      <div className="account-info-card-wrap">
        <div className="avatar-wrap">
          <Avatar size={96} icon={<UserOutlined />} src={get(account, 'avator') ? buildAvatarPath(get(account, 'avator')) : null} />
        </div>
        <div className="card-wrap">
          <div className="header">
            <div className="header-title">
              {get(account, 'nickname', '')}
            </div>
            <div className="header-motto">
              {truncate(get(account, 'motto'), { length: 60 }) || '这个人很懒，啥也没写┑(￣Д ￣)┍'}
            </div>
          </div>
          <div className="statistic">
            <Row gutter={16} type="flex" justify="space-around">
              <Col>
                <Statistic
                  title="课程"
                  value={get(account, 'counter.courses_learned', 0)}
                />
              </Col>
              <Col>
                <Statistic
                  title="解题"
                  value={get(account, 'counter.code_accepted', 0)}
                  suffix={`/ ${get(account, 'counter.code_visited', 0)}`}
                />
              </Col>
              <Col>
                <Statistic
                  title="提交"
                  value={get(account, 'counter.judge_status_accepted', 0)}
                  suffix={`/ ${get(account, 'counter.judge_status_submission', 0)}`}
                />
              </Col>
            </Row>
          </div>
          <div className="meta">
            {this.renderMetaItems()}
          </div>
        </div>
      </div>
    );
  }
}

export default AccountInfoCard;
