import 'styles/helper/desc.scss';
import React from 'react';
import { BackTop, Typography } from 'antd';

import DuibiImg from 'images/helper/duibi.png';
import Mooc1 from 'images/helper/MOOC1.png';
import Mooc2 from 'images/helper/MOOC2.png';

const { Title, Paragraph, Text } = Typography;

class DescView extends React.PureComponent {
  state = {};

  render() {
    return (
      <div className="desc_view" id="desc_view">
        <div className="left_content">
          <BackTop visibilityHeight={100} />
          <Typography>
            <Title level={2}>WeJudge</Title>
            <Paragraph>
              <Text strong>
                WeJudge 是一个将传统的实时在线评测融入到MOOC当中，构建一个MOOC教学、实践练习、学科竞赛的多维度辅助教学平台。
              </Text>
            </Paragraph>
            <Paragraph>
              在拥有在线实时代码评测功能的前提下，增加在线的教学管理系统，包含批改作业、视频教学、在线问答、在线比赛等丰富功能。
              平台面向不仅支持程序设计类课程，通过提供容纳客观题（如选择题等）的题库，支持各类学科使用，
              由此能够真正做到在线代码评测与MOOC的创新结合，填补了现有的MOOC平台与在线代码评测平台之间的空缺。
              以云计算技术架构作为基础，建立一个弹性的、支持大规模并发访问的系统。
            </Paragraph>
            <Paragraph>
              系统还在不断迭代构建完善中。你可以<Text strong>免费</Text>使用这个系统，
              但是只能开通学生账号，用于练习代码、参与课程和比赛等。
            </Paragraph>
            <Paragraph>
              若想开通教师账号，请加入工作Q群咨询：640029344 &nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="//shang.qq.com/wpa/qunwpa?idkey=13a444db457b41236807d9dd5fd1a11fd8b24407fbbfa7e1a9706291e001504f"
                alt="工作群"
              >
                <img border="0" src="//pub.idqqimg.com/wpa/images/group.png" alt="WeJudge 工作群" title="WeJudge 工作群" />
              </a>
            </Paragraph>
            <Title level={3}>MOOC功能</Title>
            <img alt="功能1" src={Mooc1} />
            <img alt="功能1" src={Mooc2} />
            <Title level={3}>功能对比</Title>
            <img src={DuibiImg} alt="慕课平台和传统OJ于本系统的对比" height={600} width={800} />
          </Typography>
        </div>
      </div>
    );
  }
}

export default DescView;
