import 'styles/widgets/judge_status.scss';

import React from 'react';
import {
  Input, Switch, Select, Space, Button
} from 'antd';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { JudgeFlagEnum } from 'scripts/common/enums/judge';
import { indexToChar } from 'scripts/common/utils/unit';
import { ReloadOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;

class JudgeStatusFilter extends React.PureComponent {
  static propTypes = {
    problemId: PropTypes.string,
    onProblem: PropTypes.func,
    onNameSearch: PropTypes.func,
    onAuthorSearch: PropTypes.func,
    onOrderClick: PropTypes.func,
    onFlagClick: PropTypes.func,
    onOwnerClick: PropTypes.func,
    type: PropTypes.string,
    onProblemOrderClick: PropTypes.func,
    problemList: PropTypes.arrayOf(PropTypes.shape({})),
    authorKw: PropTypes.string,
    problemKw: PropTypes.string,
    problemOrder: PropTypes.string,
    flag: PropTypes.string,
    orderAsc: PropTypes.bool,
    filterOwner: PropTypes.bool,
    showOwner: PropTypes.bool,
    hideSearch: PropTypes.bool,
    onReload: PropTypes.func,
  };

  static defaultProps = {
    problemId: '',
    onProblem: noop,
    onNameSearch: noop,
    onAuthorSearch: noop,
    onOrderClick: noop,
    onFlagClick: noop,
    onOwnerClick: noop,
    onReload: noop,
    type: 'normal',
    onProblemOrderClick: noop,
    problemList: [],
    authorKw: '',
    problemKw: '',
    flag: 'all',
    problemOrder: 'all',
    orderAsc: false,
    filterOwner: false,
    showOwner: false,
    hideSearch: false,
  };

  state = {
    authorKw: this.props.authorKw,
    problemKw: this.props.problemKw,
    orderAsc: this.props.orderAsc,
    filterOwner: this.props.filterOwner,
  };

  handleSearchTextChange = key => (e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  handleSwitchChange = (key, cb) => (checked) => {
    this.setState({
      [key]: checked,
    }, () => {
      cb(checked);
    });
  };

  render() {
    return (
      <div className="judge_status_filter_bar">
        <Space className="left-side">
          {this.props.problemId === '' && this.props.type !== 'select_problem_order' && <div className="item">
            <Search
              placeholder="按题目名称或ID搜索"
              onSearch={this.props.onNameSearch}
              value={this.state.problemKw}
              onChange={this.handleSearchTextChange('problemKw')}
            />
          </div>}
          {!this.props.hideSearch && <div className="item">
            <Search
              placeholder="按提交者搜索"
              onSearch={this.props.onAuthorSearch}
              value={this.state.authorKw}
              onChange={this.handleSearchTextChange('authorKw')}
            />
          </div>}
          {this.props.type === 'select_problem_order' && <div className="item">
            <Select
              defaultValue="all"
              onChange={this.props.onProblemOrderClick}
              style={{ width: '200px' }}
              value={this.props.problemOrder}
            >
              <Option value="all">不限题目</Option>
              {this.props.problemList.map((item) => {
                return <Option key={get(item, 'order')} value={get(item, 'order', '').toString()}>题目 {indexToChar(get(item, 'order', 0))}</Option>;
              })}
            </Select>
          </div>}
          <div className="item">
            <Select value={this.props.flag} onChange={this.props.onFlagClick} style={{ width: '250px' }}>
              <Option value="all">不限评测状态</Option>
              {enumMap(JudgeFlagEnum, item => (
                <Option key={get(item, 'value')} value={get(item, 'value', '').toString()}>{get(item, 'desc', '')}({get(item, 'abbr', '')})</Option>
              ), item => item.value > -3)}
            </Select>
          </div>
          <div className="item">
            <Switch
              checked={this.state.orderAsc}
              checkedChildren="正序排列"
              unCheckedChildren="正序排列"
              onChange={this.handleSwitchChange('orderAsc', this.props.onOrderClick)}
            />
          </div>
          {this.props.showOwner &&  <div className="item">
            <Switch
              checked={this.state.filterOwner}
              checkedChildren="仅看自己"
              unCheckedChildren="仅看自己"
              onChange={this.handleSwitchChange('filterOwner', this.props.onOwnerClick)}
            />
          </div>}
        </Space>
        <Space className="right-side">
          <Button type="primary" onClick={this.props.onReload}><ReloadOutlined /> 刷新列表</Button>
        </Space>
      </div>
    );
  }
}

export default JudgeStatusFilter;
