import 'styles/widgets/weixin_payment_dialog.scss';

import React, { useEffect, useState } from 'react';
import { Modal, Statistic, Result } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';

let worker = null;

const WeixinPaymentQrcodeDialog = (props) => {
  const delayTime = 6000;
  const [qrdata, setQrdata] = useState('');
  const [isFinished, setIsFinished] = useState(false);
  const [status, setStatus] = useState(0);
  const [errmsg, setErrmsg] = useState('');

  useEffect(() => {
    QRCode.toDataURL(props.url)
      .then((ret) => {
        setQrdata(ret);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props.url]);
  useEffect(() => {
    const watcher = () => {
      props.getEnrollPayment({
        contestId: props.contestId,
        id: props.orderId,
        onSuccess: (ret) => {
          const f = get(ret, 'finished', false);
          const s = get(ret, 'status', 0);
          setIsFinished(f);
          setStatus(s);
          setErrmsg(get(ret, 'msg', ''));
          if (f) {
            if (s === 1) {
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
            return;
          }
          worker = setTimeout(watcher, delayTime);
        },
        onError: () => {
          worker = setTimeout(watcher, delayTime);
        },
      });
    };
    if (!worker) {
      worker = setTimeout(watcher, delayTime);
    }
  });

  return <Modal
    {...props}
    className="weixin_payment_qrcode_dialog"
    title="微信支付"
    width={520}
    footer={false}
    onCancel={() => {
      if (worker) clearTimeout(worker);
      props.onCancel();
    }}
  >
    <h3 className="qrcode-title">{props.name}</h3>
    <Statistic prefix="￥" value={props.price} />
    {isFinished ? <div className="qrcode-pay-status">
      {status === 1 ? <Result
        status="success"
        title="支付成功"
        subTitle="感谢您对本次比赛的支持，祝您比赛顺利！"
      /> : <Result
        status="error"
        title={errmsg}
        subTitle="请重新发起支付"
      />}
    </div> : <img src={qrdata} alt="支付二维码" />}
  </Modal>;
};

WeixinPaymentQrcodeDialog.propTypes = {
  url: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  contestId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  getEnrollPayment: PropTypes.func.isRequired,
};

export default WeixinPaymentQrcodeDialog;
