import 'styles/education/lesson/lesson_modal.scss';

import React from 'react';
import {
  Modal, Input, Radio, DatePicker 
} from 'antd';
import PropTypes from 'prop-types';
import  moment  from 'moment';
import { formatTime } from 'scripts/common/utils/time_formatter';
import LessonSelectTree from 'scripts/apps/education/lesson/widgets/lesson_select_tree';
import { LessonPropTypes } from 'scripts/common/prop-types/course';

const MAX_FOLDER_DEEP = 1;

class LessonModal extends React.PureComponent {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    lessonList: PropTypes.arrayOf(LessonPropTypes),
    lesson: LessonPropTypes,
    deep: PropTypes.number,
    root: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    lesson: {},
    lessonList: [],
    deep: 0,
  };

  state = {
    type: this.props.mode === 'edit' ? (this.props.lesson.is_group ? 'group' : 'lesson') : ((this.props.deep >= MAX_FOLDER_DEEP) ? 'lesson' : 'group'),
    title: this.props.mode === 'edit' ? this.props.lesson.title : '',
    visible_time: this.props.mode === 'edit' ? this.props.lesson.visible_time : null,
    parent: this.props.mode === 'edit' ? this.props.lesson.parent : this.props.lesson.id,
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      [keyName]: target.value,
    });
  };

  handleDateChange = (value) => {
    this.setState({
      visible_time: value ? value.format('X') : null,
    });
  };

  handleParentChange = ({ parent }) => {
    this.setState({
      parent,
    });
  };

  render() {
    return (
      <Modal
        width={520}
        {...this.props}
        title={this.props.mode === 'edit' ? '修改课堂' : '创建课堂'}
        className="lesson_modal"
      >
        {!this.props.root && <div className="lesson_modal_item">
          <div className="item_title">节点类型：</div>
          <div className="item_content">
            <Radio.Group disabled={this.props.mode === 'edit'} onChange={this.handleInputChange('type')} value={this.state.type}>
              {this.props.deep < MAX_FOLDER_DEEP && <Radio value="group">章节</Radio>}
              <Radio value="lesson">课堂</Radio>
            </Radio.Group>
          </div>
        </div>}
        <div className="lesson_modal_item">
          <div className="item_title"><span>{this.state.type === 'group' ? '章节名称：' : '课堂名称：'}</span></div>
          <div className="item_content">
            <Input
              style={{ width: '280px' }}
              value={this.state.title}
              onChange={this.handleInputChange('title')}
              placeholder="请输入名称"
            />
          </div>
        </div>
        {/*{this.state.type === 'group' && <div className="lesson_modal_item">*/}
        {/*  <div className="desc_title">章节说明：</div>*/}
        {/*  <div className="item_content">*/}
        {/*    <TextArea*/}
        {/*      style={{ width: '280px' }}*/}
        {/*      value={this.state.desc}*/}
        {/*      onChange={this.handleInputChange('desc')}*/}
        {/*      placeholder="请输入章节说明"*/}
        {/*      autosize={{ minRows: 5, maxRows: 5 }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>}*/}
        <div className="lesson_modal_item">
          <div className="item_title">开放时间：</div>
          <div className="item_content">
            <DatePicker
              showTime
              format="YYYY-MM-DD HH:mm:ss"
              value={this.state.visible_time ? moment(formatTime(this.state.visible_time, 'LONG_DATETIME'), 'YYYY-MM-DD HH:mm:ss') : null}
              placeholder="不设置则为永久开放"
              onChange={this.handleDateChange}
            />
          </div>
        </div>
        {(this.props.mode === 'edit' && this.state.parent !== undefined) && <div className="lesson_modal_item">
          <div className="item_title">父级节点：</div>
          <div className="item_content">
            <LessonSelectTree
              lessonList={this.props.lessonList}
              onParentChange={this.handleParentChange}
              defaultKey={this.state.parent}
            />
          </div>
        </div>}
      </Modal>
    );
  }
}

export default LessonModal;
