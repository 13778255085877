import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, truncate } from 'lodash';
import { List, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AsgnActions, AsgnsSelectors } from 'scripts/common/logic/education/asgn';
import { AsgnRoutes } from 'scripts/apps/routes';

import { buildPath, openLink } from 'scripts/common/utils/location_helper';
import { LessonPropTypes } from 'scripts/common/prop-types/course';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import {
  formatTime,
  timeUsedFormatter,
  getStautsByStartEndTime
} from 'scripts/common/utils/time_formatter';
import { AsgnStatusTag } from 'scripts/apps/education/asgn/widgets/asgn_status_tag';

const mapDispatchToProps = dispatch => bindActionCreators({
  fetchAsgn: AsgnActions.fetchAsgn,
}, dispatch);

const mapStateToProps = (state, props) => {
  const asgnIds = get(props.lesson, 'asgn', []);
  let needFetchAsgn = false;
  const asgns = asgnIds.map((id) => {
    const asgn = AsgnsSelectors.getAsgnEntity(state)(id);
    if (!asgn) needFetchAsgn = true;
    return asgn;
  });
  return {
    asgns,
    needFetchAsgn,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class LessonAsgnList extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    lesson: LessonPropTypes.isRequired,
    asgns: PropTypes.arrayOf(AsgnPropTypes),
    needFetchAsgn: PropTypes.bool,
    fetchAsgn: PropTypes.func,
  };

  static defaultProps = {
    asgns: [],
    fetchAsgn: noop,
    needFetchAsgn: true,
  };

  state = {
    isLoading: true,
  };

  componentDidMount() {
    const asgnIds = get(this.props.lesson, 'asgn', []);
    if (this.props.needFetchAsgn && asgnIds.length) {
      this.props.fetchAsgn({
        courseId: this.props.courseId,
        ids: asgnIds,
        onComplete: () => {
          this.setState({
            isLoading: false,
          });
        },
      });
    } else {
      // eslint-disable-next-line
      this.setState({
        isLoading: false,
      });
    }
  }

  handleAsgnClick = asgn => () => {
    openLink(buildPath(
      AsgnRoutes.ASGN,
      { courseId: this.props.courseId, asgnId: get(asgn, 'id') }
    ), true);
  };

  render() {
    if (this.state.isLoading) {
      return <div className="course_asgn">
        <Spin className="drawer_loading" tip="载入中" />
      </div>;
    }
    return (<div className="course_asgn">
      <List
        bordered
        itemLayout="horizontal"
        dataSource={this.props.asgns.filter(item => !!item)}
        renderItem={(item) => {
          if (!item) return null;
          const status = get(item, 'status', {});
          const isManage = status.role === 'manage';
          const isStudent = status.role === 'student';
          const hasVisitPermission = get(status, 'permission', false);
          const timeStatus = getStautsByStartEndTime(get(status, 'start_time', 0), get(status, 'end_time', 0));
          return <List.Item
            actions={[
              <AsgnStatusTag
                mode="mini"
                status={get(item, 'status', {})}
                score={isStudent ? get(status, 'score', null) : null}
              />
            ]}
          >
            <List.Item.Meta
              title={isManage || (hasVisitPermission && timeStatus.status > -1)
                ? <a onClick={this.handleAsgnClick(item)}>{truncate(get(item, 'title'), { length: 24 })}</a> : get(item, 'title')}
              description={(isStudent && timeStatus.status > -1)
                ? `${timeStatus.status === 0 ? `离结束还有${timeUsedFormatter(timeStatus.diff)}` : '已结束'}`
                : `创建时间：${formatTime(get(item, 'create_time', 0), 'LONG_DATETIME')}`}
            />
          </List.Item>;
        }}
      />
    </div>);
  }
}

export default LessonAsgnList;
