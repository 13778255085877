//import 'styles/collection/answer/sharelist.scss';
import React, { Component, Fragment } from 'react';
import { isEmpty, get, noop  } from 'lodash';
import {
  DeleteOutlined,
  EditOutlined,
  LikeOutlined,
  LikeTwoTone,
  MessageOutlined
} from '@ant-design/icons';
import {
  List, Modal, Spin, Tag, Empty, Avatar 
} from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { bindActionCreators } from 'redux';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import {
  CollectionProblemSolutionsActions,
  CollectionProblemActions,
  ProblemSolutionSelectors,
  SolutionsTagsActions,
  SolutionCommentsActions
} from 'scripts/common/logic/collection';
import { buildAvatarPath } from 'scripts/common/utils/location_helper';
import Collapse from './collapse_solutions';
import ReEditor from './solutions_editor';

const LOADING_STATUS_KEY = 'solutions_view';

@connect((state, props) => {
  const { entities } = state;
  const  tags = get(entities, 'tags', {});
  const { match, auth } = props;
  const { account }  = auth;
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const problemId = get(match.params, 'problemId', '');
  const collectionId = get(match.params, 'collectionId', '');
  const Solutions = ProblemSolutionSelectors.getProblemsSolutionsListData(state);
  const pagesTotal = (ProblemSolutionSelectors.problemSolutionsListViewPagerSelector(state)).total;
  return {
    pagesTotal,
    isLoading,
    Solutions,
    problemId,
    collectionId,
    account,
    tags,
  };
}, dispatch => bindActionCreators({
  problemSolutions: CollectionProblemSolutionsActions.getCollectionProblemSolutionList,
  savecollection: CollectionProblemSolutionsActions.saveCollectionProblemSolutionsListView,
  getCollectionProblem: CollectionProblemActions.getCollectionProblem,
  markLikes: CollectionProblemSolutionsActions.markSolutionLikes,
  delSolutions: CollectionProblemSolutionsActions.delProblemSolution,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  onSubmitSolution: CollectionProblemSolutionsActions.saveAddSolution,
  fetchTagsList: SolutionsTagsActions.getSolutionsTagsList,
  adjustSolution: CollectionProblemSolutionsActions.adjustProblemSolution,
  getCommentsList: SolutionCommentsActions.getSolutionCommentsList,
  
}, dispatch))
class ListItem extends Component {
  static propTypes = {
    tags: PropTypes.shape({}),
    account: PropTypes.shape([]),
    fetchTagsList: PropTypes.func,
    Solutions: PropTypes.arrayOf(PropTypes.object),
    problemId: PropTypes.string.isRequired,
    collectionId: PropTypes.string.isRequired,
    problemSolutions: PropTypes.func.isRequired,
    onSubmitSolution: PropTypes.func.isRequired,
    markLikes: PropTypes.func.isRequired,
    delSolutions: PropTypes.func.isRequired,
    adjustSolution: PropTypes.func.isRequired,
    pagesTotal: PropTypes.number,
    showDetails: PropTypes.func,
    getCommentsList: PropTypes.func,
  };

  static defaultProps = {
    pagesTotal: 0,
    getCommentsList: noop,
    fetchTagsList: noop,
    showDetails: noop,
    tags: {},
    account: {},
    Solutions: [],
  };

  state = {
    pageSize: 10,
    current: 1, 
    loading: true,
    visible: false,
    Solution: [],
  };

  componentWillMount() {
    this.props.fetchTagsList();
    this.props.problemSolutions({
      collectionId: this.props.collectionId,
      id: this.props.problemId,
      query: {
        page: 1,
        limit: 10,
      },
    });
  }

  componentWillReceiveProps() {
    this.setState({
      loading: false,
    });
  }

  shouldComponentUpdate(nextProps) {
    const arr = nextProps.Solutions.map((array) => { return array.author; });
    if (arr.indexOf(undefined) !== -1) {
      return false; 
    }
    return true;
  }
 
  confirm(id) {
    Modal.confirm({
      mask: false,
      title: 'WEJUDGE',
      content: '确认要删除该题解吗',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.delItem(id),
    });
  }

  changePageSize(pageSize) {
    this.setState({
      pageSize,
    });
    this.props.problemSolutions({
      collectionId: this.props.collectionId,
      id: this.props.problemId,
      query: {
        page: this.state.current,
        limit: pageSize,
      },
    });
  }

  changePage(current) {
    this.setState({
      current,
    });
    this.props.problemSolutions({
      collectionId: this.props.collectionId,
      id: this.props.problemId,
      query: {
        page: current,
        limit: this.state.pageSize,
      },
    });
  }

  markLike(id, isAgree) {
    this.props.markLikes({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: id,
      if: isAgree,
    });
    this.props.problemSolutions({
      collectionId: this.props.collectionId,
      id: this.props.problemId,
    });
  }

  delItem(id) {
    this.props.delSolutions({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: id,
    });
  }

  toShowDetails(item, e) {
    this.props.getCommentsList({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      solutionId: item.id,
      query: {
        limit: 10,
        page: 1,
      },
    });
    e.stopPropagation();
    this.props.showDetails(item);
  }

  showModal = (item) => {
    this.setState({
      visible: true,
      Solution: item,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  
  render() {
    const { account } = this.props;
    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: false,
      showTotal: () => `共${this.props.pagesTotal}条`,
      pageSize: this.state.pageSize,
      current: this.state.current,
      total: this.props.pagesTotal,
      onShowSizeChange: (current, pageSize) => this.changePageSize(pageSize, current),
      onChange: current => this.changePage(current),
    };
    return (
      <Spin size="large" tip="加载中" spinning={this.state.loading}><>
        <Collapse
          collectionId={this.props.collectionId}
          problemId={this.props.problemId}
          getFilterList={this.props.problemSolutions} 
          tags={Object.values(this.props.tags)}
        />
        {this.props.Solutions.length === 0 
          ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> 
          
          : <div className="solutions-list-container" style={{ height: '100vh' }}> 
            <List
              itemLayout="vertical"
              size="large"
              pagination={paginationProps}
              dataSource={this.props.Solutions}
              footer={
                <div>
                  <b>Wejudge</b>
                </div>
                       }
              renderItem={item => (
                <List.Item
                  key={item.id}
                  actions={[
                    <span onClick={() => this.markLike(item.id, item.is_agree)}>
                      {item.is_agree ? <span>
                        <LikeTwoTone style={{ marginRight: 8 }} />{item.agree}</span> : <span>
                          <LikeOutlined style={{ marginRight: 8 }} /> {item.agree}
                        </span>}
                    </span>,
                    <span onClick={e => this.toShowDetails(item, e)}>
                      <MessageOutlined style={{ marginRight: 1 }} />
                      <small>{`查看${item.evaluate}评论`}</small></span>,
                    <span>
                      {(item.author.id === account.id || account.role === 99) ? <span>
                        <EditOutlined
                          style={{ marginLeft: 8 }}
                          onClick={() => this.showModal(item)}
                        />
                        <small onClick={() => this.showModal(item)}>修改</small>
                        <div>
                          <Modal 
                            mask={false}
                            width="60vw"
                            visible={this.state.visible}
                            title="修改"
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={null}
                          ><ReEditor
                            onSubmitSolution={this.props.onSubmitSolution}
                            collectionId={this.props.collectionId}
                            problemId={this.props.problemId}
                            solution={this.state.Solution}
                            adjustProblemSolution={this.props.adjustSolution}
                            handleCancel={this.handleCancel}
                            fetchTagsList={this.props.fetchTagsList}
                            TagsList={this.props.tags}
                          />
               
                          </Modal>
                        </div></span>
                        : null}
                    </span>,
                    <span onClick={() => this.confirm(item.id)}>
                      {(item.author.id === account.id || account.role === 99) ? <span>
                        <DeleteOutlined style={{ marginLeft: 8 }} /><small>删除</small></span>
                        : null}
                    </span>]}
                >
                  <List.Item.Meta
                    avatar={<Avatar src={item.author.avator ? buildAvatarPath(get(item.author, 'avator')) : null} />}
                    title={<div>
                      {item.title}{item.tags ? Object.values(item.tags).filter((array) => {
                        return array.language === true;
                      }).map((arr) => {
                        return <Tag color="green" key={arr.name}>{arr.name}</Tag>;
                      }) : null}
                      {item.tags ? Object.values(item.tags).filter((array) => {
                        return array.language !== true;
                      }).map((arr) => {
                        return <Tag color="blue" key={arr.name}>{arr.name}</Tag>;
                      }) : null}
                    </div>}
                  />
                  <span className="share-container" onClick={e => this.toShowDetails(item, e)} style={{ minWidth: '30vw' }}>
                    <RichtextContext content={item.content} textMode  />
                  </span>
                </List.Item>
              )}
            > {this.state.loading && this.state.hasMore && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
            </List>
          </div>}
      </>
      </Spin>
    );
  }
}
export default ListItem;
