import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { ContestRoutes } from 'scripts/apps/routes';
import FaqView from './faq/faq_view';
import FaqList from './faq/faq_list';
import ContestSimpleCrossCheckView from './simple_cross_check';
import PrintView from './print_view';

const { Content } = Layout;

const SupportApp = () => (
  <Content className="contest-layout-content">
    <Switch>
      <Route path={ContestRoutes.SUPPORT.FAQ_VIEW} component={FaqView} />
      <Route path={ContestRoutes.SUPPORT.FAQ} exact component={FaqList} />
      <Route path={ContestRoutes.SUPPORT.CROSS_CHECK} component={ContestSimpleCrossCheckView} />
      <Route path={ContestRoutes.SUPPORT.PRINT} component={PrintView} />
    </Switch>
  </Content>
);

export default SupportApp;
