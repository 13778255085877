import React from 'react';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import { Form, Input, Radio } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { RegisterTypeEnum } from 'scripts/common/enums/contest';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';

const EnrollPreview = (props) => {
  const {
    accountFields,
  } = props;

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  // 是否是团队比赛
  const isGroupContest = get(props.contest, 'team_type', 0) === RegisterTypeEnum.GROUP.value;

  return (
    <Form {...formItemLayout} style={{ marginTop: 48 }}>
      {!isGroupContest && <Form.Item label="昵称/队名" required>
        <Input
          className="input"
          placeholder="请输入昵称/队名"
          size="large"
        />
      </Form.Item>}
      <Form.Item label="队伍名称" required>
        <Input
          className="input"
          placeholder="请输入真实姓名"
          size="large"
        />
      </Form.Item>
      <Form.Item label="性别" required>
        <Radio.Group
          className="account-sex-radio"
          buttonStyle="solid"
          size="large"
        >
          <Radio.Button value={1} defaultChecked><ManOutlined /> 男</Radio.Button>
          <Radio.Button value={0}><WomanOutlined /> 女</Radio.Button>
        </Radio.Group>
      </Form.Item>
      {accountFields.map((item) => {
        if (!item.available) return null;
        const placename = get(item, 'placename', '');
        const placeholder = get(item, 'placeholder', '');

        return <Form.Item
          key={item.index}
          label={placename || item.name}
          required={item.require}
        >
          <Input
            className="input"
            placeholder={item.default || placeholder || `请输入${item.name}`}
            size="large"
          />
        </Form.Item>;
      })}
    </Form>
  );
};

EnrollPreview.propTypes = {
  contest: ContestPropTypes.isRequired,
  accountFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default EnrollPreview;
