import React from 'react';
import PropTypes from 'prop-types';
import {
  get, isEmpty, isEqual, noop 
} from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Form, Modal, Button, Switch, InputNumber, Tooltip, Alert 
} from 'antd';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import CodeEditor from 'scripts/common/widgets/code_mirror';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';

@connect(null, dispatch => bindActionCreators({
  saveSpecialJudgeConfig: CollectionProblemActions.saveSpecialJudgeConfig,
}, dispatch))
class SpecialJudgeEditor extends React.PureComponent {
  static propTypes  = {
    problem: ProblemPropTypes,
    collectionId: PropTypes.string.isRequired,
    saveSpecialJudgeConfig: PropTypes.func,
  };

  static defaultProps = {
    problem: {},
    saveSpecialJudgeConfig: noop,
  };

  state = {
    problem: {},
    submitting: false,
    spjConfig: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.problem)) {
      const spjConfig = get(nextProps.problem, 'options.judge_options.special_judge_config', {});
      if (!isEqual(nextProps.problem, prevState.problem)) {
        return {
          problem: nextProps.problem,
          spjConfig: JSON.parse(JSON.stringify(spjConfig)),
        };
      }
    }
    return null;
  }

  codeEdtior = React.createRef();

  handleSave = () => {
    const codes = this.codeEdtior.current.getCodeValue();
    const language = get(this.codeEdtior.current, 'state.language', 0);
    const spjConfig = {
      ...this.state.spjConfig,
      language,
      code_content: get(codes, language, ''),
    };
    this.setState({
      submitting: true,
    }, () => {
      this.props.saveSpecialJudgeConfig({
        collectionId: this.props.collectionId,
        problemId: this.props.problem.id,
        spjConfig,
        onError: (rel) => {
          Modal.error({
            width: 720,
            title: '编译失败',
            content: <pre>{get(rel, 'ce_info', '')}</pre>,
          });
        },
        onComplete: () => {
          this.setState({
            submitting: false,
          });
        },
      });
    });
  };

  handleInputChange = key => (value) => {
    this.setState({
      spjConfig: {
        ...this.state.spjConfig,
        [key]: value,
      },
    });
  };

  render() {
    const { problem } = this.props;
    const { spjConfig } = this.state;
    if (isEmpty(problem)) return null;
    return (
      <>
        <div className="special_judge_editor">
          <div className="code_editor">
            <CodeEditor
              defaultLanguage={get(spjConfig, 'language', 1)}
              defaultValue={{
                [get(spjConfig, 'language', 1)]: get(spjConfig, 'code_content'),
              }}
              ref={this.codeEdtior}
              languageMask={83}     // C、C++、Python3、Go
              footerControl={false}
              mode="normal"
              editorHeight="auto"
              contralBarLeftExtra={() => 'SpecialJudge只支持保存一种语言！切换到其他语言保存，其他语言下的代码将丢失！'}
              disablePerferenceSaving
            />
          </div>
          <div className="control_editor">
            {
              !get(spjConfig, 'program')
                ? <Alert message="特殊判题程序未部署" type="warning" showIcon />
                : <Alert message="特殊判题程序已部署" type="success" showIcon />
            }
            <Form.Item style={{ marginTop: 16 }}>
              <Switch
                checked={get(spjConfig, 'redirect_std', false)}
                onChange={this.handleInputChange('redirect_std')}
              /> 重定向到STD流&nbsp;
              <Tooltip
                title="开启后，将会把目标程序的运行输出结果重定向到裁判程序的Stdin中。"
                arrowPointAtCenter
                placement="topRight"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <p><strong>时间限制 (MS)</strong></p>
              <Tooltip
                placement="right"
                tigger="focus"
                title={`${(get(spjConfig, 'time_limit', 0) / 1000).toFixed(1)} S`}
              >
                <InputNumber
                  min={1000}
                  max={10000}
                  step={500}
                  value={get(spjConfig, 'time_limit', 1000)}
                  onChange={this.handleInputChange('time_limit')}
                />
              </Tooltip>
            </Form.Item>
            <Form.Item>
              <p><strong>内存限制 (KB)</strong></p>
              <Tooltip
                placement="right"
                tigger="focus"
                title={`${(get(spjConfig, 'memory_limit', 0) / 1024).toFixed(1)} MB`}
              >
                <InputNumber
                  min={32768}
                  max={524288}
                  step={1024}
                  value={get(spjConfig, 'memory_limit', 32768)}
                  onChange={this.handleInputChange('memory_limit')}
                />
              </Tooltip>
            </Form.Item>
          </div>
        </div>
        <div className="footer-content">
          <Button
            onClick={this.handleSave}
            loading={this.state.submitting}
            type="primary"
          >
            保存设置
          </Button>
        </div>
      </>
    );
  }
}

export default SpecialJudgeEditor;
