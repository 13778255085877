import React from 'react';
import 'styles/announcement/announcement.scss';
import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { Switch, Route } from 'react-router-dom';
import { AnnounceRoutes } from 'scripts/apps/routes';
import ListView from 'scripts/apps/announcement/list_view';
import BodyView from 'scripts/apps/announcement/body_view';
import EditorView from 'scripts/apps/announcement/editor_view';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';

const AnnounceApp = () => {
  return (
    <BaseLayout>
      <Switch>
        <Route path={AnnounceRoutes.LIST} component={ListView} exact />
        <Route path={AnnounceRoutes.NEW} component={EditorView} exact />
        <Route path={AnnounceRoutes.BODY_VIEW} component={BodyView} exact />
        <Route path={AnnounceRoutes.BODY_EDIT} component={EditorView} />
        <Route component={WeJudge404NotFoundComponent} />
      </Switch>
    </BaseLayout>
  );
};

export default AnnounceApp;
