import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ClassifyEditor from './classify_editor';

class ClassifyInfoModal extends React.PureComponent {
  static propTypes = {
    collectionId: PropTypes.string.isRequired,
  };

  static defaultProps = { };

  state = {
    classifyKey: {},
  };

  render() {
    return (
      <Modal
        {...this.props}
        title="分类到"
      >
        <ClassifyEditor
          collectionId={this.props.collectionId}
          hideManage
          handleClassifyKey={(key) => { this.setState({ classifyKey: key }); }}
        />
      </Modal>
    );
  }
}

export default ClassifyInfoModal;
