import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { ContestRoutes } from 'scripts/apps/routes';
import OrganizeListView from './organize_list';

const { Content } = Layout;

const OrganizeApp = () => (
  <Content className="contest-layout-content">
    <Switch>
      <Route path={ContestRoutes.ORGANIZATION.LIST} component={OrganizeListView} />
    </Switch>
  </Content>
);

export default OrganizeApp;
