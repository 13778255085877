import 'styles/education/asgn/management.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, SolutionOutlined } from '@ant-design/icons';
import {
  Button, Alert, Checkbox, Menu 
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AsgnActions } from 'scripts/common/logic/education/asgn';
import { CourseRoutes } from 'scripts/apps/routes';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import EditAsgnView from './widgets/edit_asgn_info';
import AsgnAccessControlView from './widgets/asgn_access_control';

const mapDiapatchToProps = dispatch => bindActionCreators({
  deleteAsgn: AsgnActions.deleteAsgn,
}, dispatch);

@withRouter
@connect(null, mapDiapatchToProps)
class AsgnManagement extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    asgn: AsgnPropTypes.isRequired,
    asgnId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
  };

  state={
    processing: false,
    agree: false,
    tabKey: 'info',
  };

  handleDeleteClick = () => {
    this.setState({
      processing: true,
    }, () => {
      const { deleteAsgn, history } = this.props;
      deleteAsgn({
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        onSuccess: () => {
          history.push(buildPath(
            CourseRoutes.CONTENT_ASGN,
            { courseId: this.props.courseId }
          ));
        },
        onComplete: () => {
          this.setState({
            processing: false,
          });
        },
      });
    });
  };

  handleTabChange = ({ key }) => {
    this.setState({
      tabKey: key,
    });
  };

  renderDeleteAlertMessage = () => {
    return (<ul className="message_ul">
      <li>题目关联信息将会被永久删除，无法恢复。题目原始信息将保留到【回收站】中</li>
      <li>所有与该题目关联的做题信息、统计、评测数据等都将会被连带删除。</li>
      <li>测试数据、运行数据等存放于服务器文件系统的内容将不会被删除。</li>
      <li>当前操作仅能由管理员或题库创建者执行。</li>
    </ul>);
  };

  render() {
    return (
      <div className="asgn_management_view">
        <Menu
          mode="horizontal"
          selectedKeys={[this.state.tabKey]}
          onSelect={this.handleTabChange}
        >
          <Menu.Item key="info"><EditOutlined key="info_icon" /> 编辑信息</Menu.Item>
          <Menu.Item key="access"><SolutionOutlined key="access_icon" /> 访问权限</Menu.Item>
          <Menu.Item key="delete"><DeleteOutlined key="delete_icon" /> 删除作业</Menu.Item>
        </Menu>
        <div className="content-body">
          {this.state.tabKey === 'info' && <EditAsgnView
            asgnId={this.props.asgnId}
            courseId={this.props.courseId}
            asgn={this.props.asgn}
          />}
          {this.state.tabKey === 'access' && <AsgnAccessControlView
            asgnId={this.props.asgnId}
            courseId={this.props.courseId}
            asgn={this.props.asgn}
          />}
          {this.state.tabKey === 'delete' && <div className="del_asgn_view">
            <Alert
              message="你应该清楚这个操作的风险！"
              description={this.renderDeleteAlertMessage()}
              type="error"
              iconType="info-circle-o"
              showIcon
            />
            <div className="del-control-bar">
              <Button
                type="danger"
                loading={this.state.processing}
                disabled={!this.state.agree}
                onClick={this.handleDeleteClick}
              >
                确认删除
              </Button>
              <Checkbox
                style={{ marginLeft: 8 }}
                disabled={this.state.agree}
                checked={this.state.agree}
                onChange={({ target }) => { this.setState({ agree: target.checked }); }}
              >我已阅读须知</Checkbox>
            </div>
          </div>}
        </div>
      </div>
    );
  }
}

export default AsgnManagement;
