import 'styles/common/layout/general_view.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ArrangementPropTypes } from 'scripts/common/prop-types/course';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { WeJudgeActions } from 'scripts/common/logic/wejudge/action';
import { showModal } from 'scripts/common/widgets/modal';
import StudentPicker from 'scripts/apps/education/widgets/student_picker';
import { importStudentExcel } from 'scripts/common/utils/xlsx_importer';
import AddStudentModal from './dialog/add_student';

const mapDispatchToProps = dispatch => bindActionCreators({
  getArrangementsList: CourseActions.getArrangementsList,
  addArrangementStudents: CourseActions.addArrangementStudents,
  delArrangementStudents: CourseActions.delArrangementStudents,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  viewErrorMessage: WeJudgeActions.viewErrorMessage,
}, dispatch);

const mapStateToProps = (state, props) => {
  return {
    arrangement: props.arrangement,
    students: get(props.arrangement, 'students', []),
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class ArrangementStudentsList extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.getArrangementsList({
      courseId: nextProps.courseId,
    });
  };

  static propTypes = {
    courseId: PropTypes.number.isRequired,
    schoolId: PropTypes.number.isRequired,
    onEditArrangement: PropTypes.func,
    arrangement: ArrangementPropTypes,
    addArrangementStudents: PropTypes.func,
    delArrangementStudents: PropTypes.func,
    getArrangementsList: PropTypes.func,
    viewErrorMessage: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
  };

  static defaultProps = {
    arrangement: {},
    delArrangementStudents: noop,
    getArrangementsList: noop,
    viewSuccessMessage: noop,
    viewErrorMessage: noop,
    addArrangementStudents: noop,
    onEditArrangement: noop,
  };

  state = {
    arrangement: {},
    students: [],
    studentSelectedKeys: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.arrangement, prevState.arrangement)) {
      // ArrangementStudentsList.fetchList(nextProps);
      return {
        arrangement: nextProps.arrangement,
        students: get(nextProps.arrangement, 'students', []),
      };
    }
    return null;
  }

  fetchData = () => {
    this.props.getArrangementsList({
      courseId: this.props.courseId,
    });
  };

  //导入学生
  inputRef = null;

  handleDelStudents = (ids) => {
    this.props.delArrangementStudents({
      courseId: this.props.courseId,
      arrangementId: this.props.arrangement.id,
      ids,
      onSuccess: () => {
        this.fetchData();
        //TODO 抽象通知
        this.props.viewSuccessMessage({
          message: '移除成功',
        });
      },
    });
  };

  handleStudentSelect = (student, addStudentCode) => {
    return new Promise((resolve, reject) => {
      if (!student) {
        if (addStudentCode) {
          const modalBox = showModal(AddStudentModal, {
            mode: 'add',
            width: 350,
            addStudentCode,
            onOk: (modal, state) => {
              this.props.addArrangementStudents({
                courseId: get(this.props, 'courseId', 0),
                arrangementId: this.props.arrangement.id,
                students: [{ code: state.student.code, realname: state.student.realname }],
                onSuccess: () => {
                  this.fetchData();
                  this.props.viewSuccessMessage({
                    message: '添加成功',
                  });
                  modalBox.close();
                  resolve();
                },
                onError: () => {
                  reject();
                },
              });
            },
            onCancel: () => {
              resolve();
            },
          });
        }
      } else {
        this.props.addArrangementStudents({
          courseId: get(this.props, 'courseId', 0),
          arrangementId: this.props.arrangement.id,
          students: [{ code: get(student, 'code', 0), realname: get(student, 'realname', '') }],
          onSuccess: () => {
            this.fetchData();
            this.props.viewSuccessMessage({
              message: '添加成功',
            });
            resolve();
          },
          onError: () => {
            reject();
          },
        });
      }
    });
  };

  handleEditArrangement = () => {
    this.props.onEditArrangement(this.props.arrangement);
  };

  handleStudentListSelectChange = (selectedKeys) => {
    this.setState({
      studentSelectedKeys: selectedKeys,
    });
  };

  handleXlsxChange = (e) => {
    const getResult = (data) => {
      this.props.addArrangementStudents({
        courseId: get(this.props, 'courseId', 0),
        arrangementId: this.props.arrangement.id,
        students: data,
        onSuccess: (result) => {
          this.fetchData();
          if (result) {
            this.props.viewSuccessMessage({
              message: '添加成功',
            });
          }
        },
      });
    };
    if (e.target.files.length > 0) {
      importStudentExcel(e.target.files)
        .then(data => getResult(data))
        .catch((ex) => {
          console.error(ex);
          this.props.viewErrorMessage({
            message: '解析Excel文件失败',
          });
        });
    }
  }

  handleXlsxUpload = () => {
    this.inputRef.click();
  };

  renderStudentListData = () => {
    const studentsList = [...this.state.students, { type: 'new' }];

    return studentsList.map((item) => {
      if (!item) return null;
      if (item.type === 'new') {
        return {
          key: 'new',
          code: <StudentPicker
            queryPayload={{ arrangement_id: this.props.arrangement.id }}
            schoolId={this.props.schoolId}
            onSelectChange={this.handleStudentSelect}
          />,
        };
      }
      return {
        key: item.id,
        code: get(item, 'code', ''),
        realname: get(item, 'realname', ''),
        action: <a onClick={() => this.handleDelStudents([item.id])}>移除</a>,
      };
    }).filter(item => !!item);
  };

  render() {
    const StudentsColumns = [{
      title: '学号',
      dataIndex: 'code',
      width: 300,
    }, {
      title: '真实姓名',
      dataIndex: 'realname',
      width: 300,
    }, {
      title: <Button
        onClick={() => this.handleDelStudents(this.state.studentSelectedKeys)}
        disabled={this.state.studentSelectedKeys.length === 0}
        size="small"
      >
        <CloseOutlined /> 移出排课
      </Button>,
      dataIndex: 'action',
      width: 80,
    }];
    if (!this.props.arrangement) return null;
    const studentDatas = this.renderStudentListData();
    const selectionSettings = {
      getCheckboxProps: (record) => { return record.key === 'new' ? { disabled: true } : {}; },
      selectedRowKeys: this.state.studentSelectedKeys,
      onChange: this.handleStudentListSelectChange,
    };
    return (
      <>
        <div className="student-action-bar" style={{ marginBottom: '16px' }}>
          <div className="left-sider">
            <input type="file" name="excel-file" onChange={this.handleXlsxChange} style={{ display: 'none' }} ref={(node) => this.inputRef = node} />
            <Button
              type="primary"
              onClick={this.handleXlsxUpload}
            >
              <FileExcelOutlined /> 导入学生
            </Button>
            <a href="/static/excel-template/import_students.xlsx" style={{ marginLeft: '16px' }}>学生模板</a>

          </div>
        </div>
        <Table
          size="middle"
          dataSource={studentDatas}
          columns={StudentsColumns}
          rowSelection={selectionSettings}
          pagination={false}
        />
      </>
    );
  }
}

export default ArrangementStudentsList;
