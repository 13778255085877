import 'styles/collection/collection_catetgory_tree.scss';

import React from 'react';
import { FileTextOutlined, FolderOpenOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Alert, Tree, Empty, Button, Modal 
} from 'antd';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { showModal } from 'scripts/common/widgets/modal';
import { CategoryPropTypes } from 'scripts/common/prop-types/category';
import {
  ContextMenu, MenuItem, ContextMenuTrigger, connectMenu 
} from 'react-contextmenu';
import  CategoryCollModal  from 'scripts/apps/widgets/category/modal';

const MENU_TYPE = 'DYNAMIC';

const { confirm } = Modal;

const CategoryPopverMenuBase = (props) => {
  const { id, trigger } = props;
  return <ContextMenu id={id}>
    {trigger ? <>
      <MenuItem onClick={() => trigger.onItemClick('add')} data={{ action: 'add' }} disabled={!trigger.allowCreate}>创建</MenuItem>
      <MenuItem onClick={() => trigger.onItemClick('edit')} data={{ action: 'edit' }}>修改</MenuItem>
      <MenuItem divider />
      <MenuItem onClick={() => trigger.onItemClick('delete')} data={{ action: 'delete' }}>删除</MenuItem>
    </> : <></>}
  </ContextMenu>;
};

CategoryPopverMenuBase.propTypes = {
  id: PropTypes.string.isRequired,
  trigger: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onItemClick: PropTypes.func.isRequired,
    allowCreate: PropTypes.bool,
  }),
};

CategoryPopverMenuBase.defaultProps = {
  trigger: null,
};

const CategoryPopverMenu = connectMenu(MENU_TYPE)(CategoryPopverMenuBase);

class CategoryCollEditor extends React.PureComponent {
  static propTypes= {
    categoryTree: PropTypes.arrayOf(CategoryPropTypes),
    categoryType: PropTypes.number,
    categoryId: PropTypes.string,
    modifyCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    createCategory: PropTypes.func,
    onTreeNodeClick: PropTypes.func,
    onFetchList: PropTypes.func,
    canManageCategory: PropTypes.bool,
  };

  static defaultProps = {
    categoryId: '',
    categoryTree: [],
    categoryType: 1,
    createCategory: noop,
    modifyCategory: noop,
    deleteCategory: noop,
    onTreeNodeClick: noop,
    onFetchList: noop,
    canManageCategory: false,
  };

  state = {
    selectedKeys: [this.props.categoryId],
  };

  onSelect = (selectedKeys) => {
    if (selectedKeys.length <= 0) return;
    if (selectedKeys[0] === 'add') {
      this.handleActionClick('new', { parent: null }, 0, true);
      return;
    }
    this.props.onTreeNodeClick(selectedKeys[0]);
    this.setState({ selectedKeys });
  };

  handleActionClick = (keyName, item = {}, deep, root = false) => {
    if (keyName !== 'del') {
      const modalBox = showModal(CategoryCollModal, {
        width: 450,
        mode: keyName,
        category: item,
        categoryTree: this.props.categoryTree,
        deep,
        root,
        onOk: (modal, state) => {
          const data = {
            title: state.title,
            parent: state.parent,
            type: this.props.categoryType,
          };
          if (keyName === 'new') {
            this.props.createCategory({
              ...data,
              categoryId: item.id,
              onSuccess: () => {
                this.props.onFetchList();
              },
            });
          } else {
            this.props.modifyCategory({
              ...data,
              categoryId: item.id,
              onSuccess: () => {
                this.props.onFetchList();
              },
            });
          }
          modalBox.close();
          return false;
        },
      });
    } else {
      confirm({
        title: '删除',
        content: `您确定要删除『${item.title}』吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.props.deleteCategory({
            categoryId: item.id,
            type: this.props.categoryType,
            onSuccess: () => {
              this.props.onFetchList();
            },
          });
        },
      });
    }
  };

  handlePopverMenuClick = (item, parent, deep) => (action) => {
    switch (action) {
      case 'add':
        this.handleActionClick('new', { ...item, parent }, deep);
        break;
      case 'edit':
        this.handleActionClick('edit', { ...item, parent });
        break;
      case 'delete':
        this.handleActionClick('del', { ...item });
        break;
      default:
        break;
    }
  };

  renderTreeNodeTitle = (item, parent, deep) => {
    return <ContextMenuTrigger
      id={MENU_TYPE}
      name={item.title}
      onItemClick={this.handlePopverMenuClick(item, parent, deep)}
      allowCreate={item.children}
      collect={props => props}
    >
      {item.title}
    </ContextMenuTrigger>;
  };

  renderCategoryTreeNodes = (categoryTree, parent = null, deep = 0) => {
    return [
      ...categoryTree.map((item) => {
        const ch = get(item, 'children', []);
        const hasChildren = ch.length !== 0;
        return {
          key: item.id,
          title: this.renderTreeNodeTitle(item, parent, deep),
          dataRef: item,
          isLeaf: !hasChildren,
          switcherIcon: deep === 0 ? <FolderOpenOutlined /> : <FileTextOutlined />,
          children: hasChildren ? this.renderCategoryTreeNodes(ch, item.id, deep + 1) : [],
        };
      }),
      ...((!parent && this.props.canManageCategory) ? [{
        key: 'add',
        title: '新建分类',
        isLeaf: true,
        switcherIcon: <PlusOutlined />,
        children: [],
      }] : [])
    ];
  };

  render() {
    return (
      <div className="category_tree">
        {this.props.categoryTree.length !== 0 ? <Tree.DirectoryTree
          onRightClick={props => (props)}
          showIcon={false}
          multiple={false}
          onSelect={this.onSelect}
          defaultExpandAll
          selectedKeys={this.state.selectedKeys}
          treeData={this.renderCategoryTreeNodes(this.props.categoryTree)}
        /> : <Empty description={<span>尚未创建分类</span>}>
          {this.props.canManageCategory
            ? <Button
              type="primary"
              onClick={() => this.handleActionClick('new', { parent: null }, 0, true)}
            >
              新建分类
            </Button>
            : null }
        </Empty>}
        <CategoryPopverMenu />
      </div>
    );
  }
}

export default CategoryCollEditor;
