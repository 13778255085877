import 'styles/problem/widgets.scss';

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { get, isEmpty, noop } from 'lodash';
import {
  Tooltip, Row
} from 'antd';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { filterHTML } from 'scripts/common/utils/filter';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import ProblemTagsView from './problem_tags';

const ExtraInfoItem = ({
  title, tooltip, children, horizontal,
}) => {
  return (<div className={classnames('info_item', { horizontal })}>
    <div className="item_left">
      {title}
    </div>
    <div className="item_right">
      <Tooltip placement="left" title={tooltip || children}>{children}</Tooltip>
    </div>
  </div>);
};

ExtraInfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  horizontal: PropTypes.bool,
};
ExtraInfoItem.defaultProps = {
  tooltip: '',
  horizontal: false,
};

const DEFAULT_VALUE_OF_EMPTY = '无';

class CodeProblemBody extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes.isRequired,
    showExtraInfo: PropTypes.bool,
    canManageProblem: PropTypes.bool,
    onProblemEntityChange: PropTypes.func,
  };

  static defaultProps = {
    showExtraInfo: true,
    canManageProblem: false,
    onProblemEntityChange: noop,
  };

  state = {};

  hasContent = (content) => {
    const cnt = filterHTML(content, true)
      .replace('&nbsp;', ' ').trim();
    return cnt.length > 0;
  };

  render() {
    const { problem } = this.props;
    if (!problem) return null;
    const options = get(problem, 'options', {});
    const { sample } = options;
    const inputRequire = get(options, 'input', DEFAULT_VALUE_OF_EMPTY) || DEFAULT_VALUE_OF_EMPTY;
    const outputRequire = get(options, 'output', DEFAULT_VALUE_OF_EMPTY) || DEFAULT_VALUE_OF_EMPTY;
    return  (
      <div className="widget_code_problem_body">
        <div className="pb_content">
          <div className="pbc_group">
            <RichtextContext className="content">{problem.description}</RichtextContext>
          </div>
          {this.hasContent(inputRequire) && <div className="pbc_group">
            <div className="title">输入要求：</div>
            <RichtextContext className="content">{inputRequire}</RichtextContext>
          </div>}
          {this.hasContent(outputRequire) && <div className="pbc_group">
            <div className="title">输出要求：</div>
            <RichtextContext className="content">{outputRequire}</RichtextContext>
          </div>}
          {!isEmpty(sample) && sample.map((item, key) => {
            return <div className="pbc_group">
              <h4>数据示例{key + 1}：</h4>
              <div className="pbc_sample_content">
                <div className="pbc_sample_line">
                  <div className="title">输入：</div>
                  <div className="content">
                    <pre dangerouslySetInnerHTML={{ __html: get(item, 'input', DEFAULT_VALUE_OF_EMPTY) || DEFAULT_VALUE_OF_EMPTY }} />
                  </div>
                </div>
                <div className="pbc_sample_line">
                  <div className="title">输出：</div>
                  <div className="content">
                    <pre dangerouslySetInnerHTML={{ __html: get(item, 'output', DEFAULT_VALUE_OF_EMPTY) || DEFAULT_VALUE_OF_EMPTY }} />
                  </div>
                </div>
                {get(item, 'explain') && <div className="pbc_sample_line">
                  <div className="title">解析：</div>
                  <div className="content">
                    <pre>{get(item, 'explain')}</pre>
                  </div>
                </div>}
              </div>
            </div>;
          })}
          {this.hasContent(problem.hint) && <div className="pbc_group">
            <div className="title">思路提示：</div>
            <RichtextContext className="content">{problem.hint}</RichtextContext>
          </div>}
        </div>
        {this.props.showExtraInfo && <div className="pb_extra_info">
          {problem.source && <ExtraInfoItem
            title="题目来源"
            horizontal
          >{problem.source}</ExtraInfoItem>}
          <ProblemTagsView
            problem={this.props.problem}
            canManageProblem={this.props.canManageProblem}
            onProblemEntityChange={this.props.onProblemEntityChange}
          />
        </div>}
      </div>
    );
  }
}

export default CodeProblemBody;
