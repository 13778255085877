import React from 'react';
import { noop } from 'lodash';
import { Input, Modal } from 'antd';
import PropTypes from 'prop-types';

export class DirectoryNameDialog extends React.PureComponent {
  static propTypes = {
    dirname: PropTypes.string,
  };

  static defaultProps = {
    dirname: '',
  };

  state = {
    dirname: this.props.dirname,
  };

  inputRef = React.createRef();

  handleClick = () => {
    try {
      const { input } = this.inputRef.current;
      input.setSelectionRange(0, input.value.length);
      input.focus();
    } catch (e) {
      noop(e);
    }
  };

  handleChange = (e) => {
    this.setState({
      dirname: e.target.value,
    });
  };

  render() {
    return <Modal
      {...this.props}
      title={`${this.props.dirname ? '重命名文件夹' : '请输入新的文件夹名称'}，最长为255字节`}
    >
      <Input
        type="text"
        value={this.state.dirname}
        onChange={this.handleChange}
        onClick={this.handleClick}
        ref={this.inputRef}
      />
    </Modal>;
  }
}

export class FileNameDialog extends React.PureComponent {
  static propTypes = {
    filename: PropTypes.string,
  };

  static defaultProps = {
    filename: '',
  };

  constructor(props) {
    super(props);
    const fns = this.splitFilename(props.filename);
    this.state = {
      filename: fns[0],
      fileext: fns[1],
    };
  }

  inputRef = React.createRef();

  splitFilename = (filename) => {
    const dotPos = filename.lastIndexOf('.');
    if (dotPos > -1) {
      return [filename.substr(0, dotPos), filename.substr(dotPos, filename.length)];
    }
    return [filename, ''];
  };

  handleClick = () => {
    try {
      const { input } = this.inputRef.current;
      input.setSelectionRange(0, input.value.length);
      input.focus();
    } catch (e) {
      noop(e);
    }
  };

  handleChange = (e) => {
    this.setState({
      filename: e.target.value,
    });
  };

  render() {
    return <Modal
      {...this.props}
      title={`${this.props.filename ? '重命名文件' : '请输入新的文件名称'}，最长为255字节`}
    >
      <Input
        type="text"
        value={this.state.filename}
        addonAfter={this.state.fileext}
        onChange={this.handleChange}
        onClick={this.handleClick}
        ref={this.inputRef}
      />
    </Modal>;
  }
}
