import 'styles/problem/widgets.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import {
  get, isString, isEmpty, noop 
} from 'lodash';
import CodeEditor from 'scripts/common/widgets/code_mirror';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { AccountSelectors } from 'scripts/common/logic/account';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { ProblemDraftsActions } from 'scripts/common/logic/problem/actions';
import JudgeStatusPollingView from './judge_status_polling';

@connect((state) => {
  return {
    currentAccount: AccountSelectors.getLoginAccount(state),
  };
}, dispatch => bindActionCreators({
  viewWarnMessage: WeJudgeActions.viewWarnMessage,
  getDrafts: ProblemDraftsActions.getDrafts,
  saveDraftToCloud: ProblemDraftsActions.saveDraftToCloud,
}, dispatch))
class SubmitCodeProblemWidget extends React.PureComponent {
  static propTypes = {
    currentAccount: AccountPropTypes,
    problem: ProblemPropTypes.isRequired,
    submitCode: PropTypes.func.isRequired,
    getJudgeStatusEntity: PropTypes.func.isRequired,
    getJudgeStatusEntitySelector: PropTypes.func.isRequired,
    onShowJudgeStatusModel: PropTypes.func.isRequired,
    getDrafts: PropTypes.func,
    saveDraftToCloud: PropTypes.func,
    onReceivedNewJudgeStatus: PropTypes.func,
    languageMask: PropTypes.number,
    disableDrafts: PropTypes.bool,
  };

  static defaultProps = {
    currentAccount: null,
    languageMask: 0,
    getDrafts: noop,
    saveDraftToCloud: noop,
    onReceivedNewJudgeStatus: noop,
    disableDrafts: false,
  };

  state = {
    submitting: false,
    pollingView: false,
    statusId: 0,
  };

  handleBackToEditor = () => {
    this.setState({
      pollingView: false,
    });
  };

  handleCodeSubmit = (code, language) => {
    this.setState({
      submitting: true,
    }, () => {
      const codeSend = get(code, language, '');
      this.props.submitCode({
        problemId: this.props.problem.id,
        code: isString(codeSend) ? codeSend : JSON.stringify(codeSend),
        language,
        onSuccess: (statusId) => {
          this.setState({
            statusId,
            pollingView: true,
          });
        },
        onComplete: () => {
          this.setState({
            submitting: false,
          });
        },
      });
    });
  };

  renderLimitTip = (state) => {
    const { problem } = this.props;
    const options = get(problem, 'options', {});
    const lang = get(state, 'language', 0);
    const timeLimit = get(options, `judge_options.time_limit.${lang}`, 0);
    const memLimit = get(options, `judge_options.mem_limit.${lang}`, 0);
    return (<span>
      <strong>时间限制：</strong><Tooltip title={`${timeLimit} MS`}>{(parseInt(timeLimit, 10) / 1000).toFixed(0)} S</Tooltip>，
      <strong>内存限制：</strong><Tooltip title={`${memLimit} KB`}>{(parseInt(memLimit, 10) / 1024).toFixed(0)} MB</Tooltip>
    </span>);
  };

  render() {
    const { problem, currentAccount, disableDrafts } = this.props;
    // 由于CodeEditor组件的特性，部分关键props只能在第一次设置的时候生效，因此需要判定problem是否为『真空』来决定是是否渲染组件。
    if (isEmpty(problem)) return null;
    // if (isEmpty(currentAccount)) return null;
    const options = get(problem, 'options', {});
    const type = ProblemTypeEnum.enumValueOf(get(problem, 'problem_type', 1));
    const ceOptions = {
      mode: type === ProblemTypeEnum.CODE_FILL ? 'fill' : 'normal',
      dynamicCodeKey: isEmpty(currentAccount) ? null : `${get(currentAccount, 'id', '')}_${get(problem, 'id', '')}`,
      dynamicCodeSecret: isEmpty(currentAccount) ? null : get(currentAccount, 'code_editor_crypto_key', ''),
      contralBarLeftExtra: this.renderLimitTip,
      onSubmit: this.handleCodeSubmit,
      submitting: this.state.submitting,
      languageMask: this.props.languageMask,
      draftsOption: disableDrafts ? false : {
        problemId: problem.id,
        getDrafts: this.props.getDrafts,
        saveDrafts: this.props.saveDraftToCloud,
      },
    };
    if (type === ProblemTypeEnum.CODE_FILL) {
      ceOptions.demoCases = get(options, 'judge_options.demo_cases', {});
      ceOptions.codeTemplates = get(options, 'judge_options.answer_cases', {});
    }
    return (<>
      {this.state.pollingView && <JudgeStatusPollingView
        key={`jspv_${this.state.statusId}`}
        getJudgeStatusEntity={this.props.getJudgeStatusEntity}
        getJudgeStatusEntitySelector={this.props.getJudgeStatusEntitySelector}
        onShowJudgeStatusModel={this.props.onShowJudgeStatusModel}
        backToEditor={this.handleBackToEditor}
        statusId={this.state.statusId}
        onReceivedNewJudgeStatus={this.props.onReceivedNewJudgeStatus}
      />}
      <div className="widget_submit_code_view">
        <CodeEditor {...ceOptions} />
      </div>
    </>);
  }
}

export default SubmitCodeProblemWidget;
