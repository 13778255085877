import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { showModal } from 'scripts/common/widgets/modal';
import { bindActionCreators } from 'redux';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions/problem';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import { CollectionProblemsSelectors } from 'scripts/common/logic/collection/selectors/problem';
import { CollectionProblemPropTypes } from 'scripts/common/prop-types/collection';
import ProblemInfoModal from './dialog/problem_info';

const mapDispatchToProps = dispatch => bindActionCreators({
  modifyProblems: CollectionProblemActions.saveModifyProblems,
  delProblems: CollectionProblemActions.saveDelProblems,
  getCollectionProblem: CollectionProblemActions.getCollectionProblem,
}, dispatch);

const mapStateToProps = (state, props) => {
  const collectionProblem =    CollectionProblemsSelectors.getCollectionProblemEntity(state)(props.problemId);
  return {
    collectionProblem,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class ModifyProblemIcon extends React.PureComponent {
  static propTypes = {
    problemId: PropTypes.string.isRequired,
    collectionProblem: CollectionProblemPropTypes,
    collectionId: PropTypes.string.isRequired,
    modifyProblems: PropTypes.func,
    delProblems: PropTypes.func,
  };

  static defaultProps = {
    collectionProblem: {},
    modifyProblems: noop,
    delProblems: noop,
  };

  state = {
    problem: {},
  };

  showModifyProblemModal = () => {
    const dialog = showModal(ProblemInfoModal, {
      problem: this.props.collectionProblem.problem,
      collectionId: this.props.collectionId,
      problemType: ProblemTypeEnum.enumValueOf(this.props.collectionProblem.problem.problem_type),
      mode: 'edit',
      onOk: (modal, state) => {
        this.props.modifyProblems({
          problem: {
            ...state.problem,
            id: this.props.collectionProblem.problem.id,
            problem_type:
              ProblemTypeEnum.enumValueOf(this.props.collectionProblem.problem.problem_type).value,
          },
          collectionId: this.props.collectionId,
          onSuccess: () => {
            dialog.close();
          },
        });
        return false;
      },
    });
  };

  render() {
    return (
      <a
        className="modify-problem-btn"
        onClick={this.showModifyProblemModal}
      >
        <EditOutlined />
      </a>
    );
  }
}

export default ModifyProblemIcon;
