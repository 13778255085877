import React from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader, Space, Divider
} from 'antd';
import { get, noop } from 'lodash';
import {
  ContestAccountPropTypes,
  ContestPropTypes
} from 'scripts/common/prop-types/contest';
import { bindActionCreators } from 'redux';
import { ContestAccountActions } from 'scripts/common/logic/contest/account';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { formatTime, nowTime } from 'scripts/common/utils/time_formatter';
import {
  ContestTypeEnum, RegisterTypeEnum, ScheduleTypeEnum
} from 'scripts/common/enums/contest';
import Countdown from 'scripts/common/widgets/countdown';

import { withContestContext } from '../contest_provider';

const mapDispatchToProps = dispatch => bindActionCreators({
  contestLogout: ContestAccountActions.contestLogout,
  editCurrentAccountInfoMeta: ContestAccountActions.editCurrentAccountInfoMeta,
}, dispatch);

@withRouter
@withContestContext
@connect(null, mapDispatchToProps)
class ContestPageHeader extends React.PureComponent {
  static propTypes =  {
    ...RoutePropTypes,
    account: ContestAccountPropTypes,
    contest: ContestPropTypes,
    onRefresh: PropTypes.func,
    miniMode: PropTypes.bool,
  };

  static defaultProps = {
    account: {},
    contest: {},
    onRefresh: noop,
    miniMode: false,
  };

  state = {
    secondTitle: '',
    countDown: -1,
    countDownType: 0,
  };

  componentDidMount() {
    this.setState(this.calcSecondTitle());
  }

  calcSecondTitle = () => {
    const timeNow = nowTime().unix();
    const enrollStartTime =  get(this.props.contest, 'configs.enroll_start_time', 0);
    const enrollEndTime =  get(this.props.contest, 'configs.enroll_end_time', 0);
    const contestStartTime =  get(this.props.contest, 'start_time', Infinity);
    const contestEndTime =  get(this.props.contest, 'end_time', Infinity);
    const isContestStarted = timeNow >= contestStartTime;
    const isContestEnded = timeNow >= contestEndTime;
    if (!isContestStarted) {
      if (timeNow >= enrollStartTime && timeNow <= enrollEndTime) {
        return { secondTitle: '离报名(组队)结束还有', countDown: enrollEndTime, countDownType: 1 };
      } else if (timeNow < enrollStartTime) {
        return { secondTitle: '离报名(组队)开始还有', countDown: enrollStartTime, countDownType: 2 };
      }
      return { secondTitle: '离比赛开始还有', countDown: contestStartTime, countDownType: 3 };
    } else if (isContestStarted && !isContestEnded) {
      return { secondTitle: '离比赛结束还有', countDown: contestEndTime, countDownType: 4 };
    } else if (isContestEnded) {
      return { secondTitle: '比赛已经结束', countDown: -1, countDownType: 5 };
    }
    return {};
  };

  handleCountDownFinished = () => {
    const prevState = { ...this.state };
    this.setState(this.calcSecondTitle(), () => {
      if (prevState.countDownType !== 0) {
        if (prevState.countDownType !== this.state.countDownType) {
          if (this.state.countDownType === 4) {
            // 如果是比赛开始，刷新比赛信息和题库列表
            this.props.onRefresh(true);
          } else {
            this.props.onRefresh(false);
          }
        }
      }
    });
  };

  renderContentExtra = () => {
    const cStyle = {
      fontSize: '16px',
      lineHeight: '32px',
    };
    if (this.state.countDownType === 5) {
      return <h3>{this.state.secondTitle}</h3>;
    }
    return <Countdown
      title={this.props.miniMode ? null : this.state.secondTitle}
      prefix={this.props.miniMode ? this.state.secondTitle : null}
      valueStyle={this.props.miniMode ? cStyle : {}}
      value={this.state.countDown * 1000}
      format="D 天 H 时 m 分 s 秒"
      onFinish={this.handleCountDownFinished}
    />;
  };

  renderMiniMode = () => {
    const { contest } = this.props;
    return (
      <div className="page-header-container">
        <PageHeader
          title={get(contest, 'title', '未命名比赛')}
          subTitle={get(contest, 'sponsor') || 'WeJudge官方比赛'}
          extra={this.renderContentExtra()}
        />
      </div>
    );
  };

  renderNormalMode = () => {
    const { contest } = this.props;
    const contestType = get(contest, 'contest_type', 0);
    const teamType = get(contest, 'team_type', 0);
    const scheduleType = get(contest, 'schedule_type', 0);
    const contestStartTime =  get(contest, 'start_time', Infinity);
    const contestEndTime =  get(contest, 'end_time', Infinity);
    return (
      <div className="page-header-container">
        <PageHeader
          title={get(contest, 'title', '未命名比赛')}
          subTitle={get(contest, 'sponsor') || 'WeJudge官方比赛'}
        >
          <div className="content">
            <div className="main">
              <div className="line"><strong>比赛时间：</strong>{formatTime(contestStartTime, 'LONG_DATETIME')} ~ {formatTime(contestEndTime, 'LONG_DATETIME')}</div>
              <div className="line">
                <Space split={<Divider type="vertical" />}>
                  <span><strong>参赛形式：</strong>{get(RegisterTypeEnum.enumValueOf(teamType), 'desc', '未知')}</span>
                  <span><strong>比赛赛制：</strong>{get(ContestTypeEnum.enumValueOf(contestType), 'desc', '未知')}</span>
                  <span><strong>比赛时长：</strong>{get(ScheduleTypeEnum.enumValueOf(scheduleType), 'desc', '未知')}</span>
                </Space>
              </div>
            </div>
            <div className="extra">
              {this.renderContentExtra()}
            </div>
          </div>
        </PageHeader>
      </div>
    );
  };

  render() {
    return this.props.miniMode ? this.renderMiniMode() : this.renderNormalMode();
  }
}

export default ContestPageHeader;
