import 'styles/education/course/course_asgn_list.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Spin, Table, Modal, Button 
} from 'antd';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import { buildPath, openLink } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';
import { AsgnsSelectors } from 'scripts/common/logic/education/asgn/selectors/asgn';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { CourseSelectors } from 'scripts/common/logic/education/course';
import { AsgnRoutes } from 'scripts/apps/routes';
import { AddAsgnButton } from './add_asgn';

const LOADING_STATUS_KEY = 'course_lesson_asgn_table_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnEntity: AsgnActions.getAsgnEntity,
  getAsgnEntities: AsgnActions.getAsgnEntities,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  createExportCodes: CourseActions.createExportCodes,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  exportGrades: CourseActions.exportGrades,
}, dispatch);

const mapStateToProps = (state, props) => {
  const lessonAsgnIds = get(props.lesson, 'asgn', []);
  const asgnDatas = AsgnsSelectors.getLessonAsgnListData(lessonAsgnIds)(state);
  const asgnDatasMapping = AsgnsSelectors.getLessonAsgnListMapping(lessonAsgnIds)(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const course = CourseSelectors.getCourseEntity(state)(props.courseId);
  return {
    isLoading,
    asgnDatas,
    asgnDatasMapping,
    course,
    lessonAsgnIds,
  };
};

@withRouter
@LoginRequire
@connect(mapStateToProps, mapDispatchToProps)
class LessonAsgnManage extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const noResultIds = [];
    nextProps.lessonAsgnIds.forEach((id) => {
      if (!nextProps.asgnDatasMapping[id]) {
        noResultIds.push(id);
      }
    });
    nextProps.getAsgnEntities({
      courseId: nextProps.courseId,
      ids: noResultIds,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes =  {
    ...RoutePropTypes,
    getAsgnEntity: PropTypes.func,
    getAsgnEntities: PropTypes.func,
    asgnDatas: PropTypes.arrayOf(AsgnPropTypes),
    asgnDatasMapping: PropTypes.shape({}),
    lessonAsgnIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    courseId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    setLoadingStatus: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    onEditLessonAsgn: PropTypes.func.isRequired,
  };

  static defaultProps = {
    getAsgnEntity: noop,
    getAsgnEntities: noop,
    asgnDatasMapping: {},
    asgnDatas: [],
    lessonAsgnIds: [],
    isLoading: true,
    setLoadingStatus: noop,
    viewSuccessMessage: noop,
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
  //     const { queryParams } = nextProps;
  //     LessonAsgnManage.fetchList(nextProps);
  //     return {
  //       queryParams,
  //     };
  //   }
  //   return null;
  // }
  componentDidMount() {
    LessonAsgnManage.fetchList(this.props);
  }

  ASGN_COLUMNS = [{
    title: 'ID',
    width: 80,
    dataIndex: 'id',
  }, {
    title: '名称',
    dataIndex: 'name',
  }, {
    title: '操作',
    width: 140,
    dataIndex: 'action',
  }];

  handleAsgnClick = asgn => () => {
    openLink(buildPath(
      AsgnRoutes.ASGN,
      { courseId: this.props.courseId, asgnId: asgn.id }
    ), true);
  };

  handleRemoveAsgn = (item) => () => {
    Modal.confirm({
      title: '操作提示',
      iconType: 'warning',
      content: <span>确认要移除这个作业的关联吗？<br />如果需要，你可以重新关联它。</span>,
      onOk: () => {
        const asgn = this.props.lesson.asgn.filter(it => it !== item.id);
        this.props.onEditLessonAsgn(asgn);
      },
    });
  };

  handleAddLessonAsgn = (id) => {
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    const fetchAsgnList = () => {
      this.props.getAsgnEntity({
        id,
        courseId: this.props.courseId,
        onComplete: () => {
          this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
    };
    const { asgn = [] } = this.props.lesson;
    const a = [...asgn];
    a.push(id);
    const { history } = this.props;
    this.props.onEditLessonAsgn(a, fetchAsgnList);
    history.push(buildPath(
      AsgnRoutes.ASGN,
      { courseId: this.props.courseId, asgnId: id }
    ), false);
  };

  renderListData = () => {
    return this.props.asgnDatas.map((item) => {
      if (!item) return {};
      return {
        key: get(item, 'id', ''),
        id: <div>#{get(item, 'id', '')}</div>,
        name: <a onClick={this.handleAsgnClick(item)}>{get(item, 'title')}</a>,
        action: <a onClick={this.handleRemoveAsgn(item)}>移除</a>,
      };
    });
  };

  renderListArea = () => {
    const datas = this.renderListData();
    return (
      <Table
        bordered
        className="asgn_list_view"
        size="middle"
        dataSource={datas}
        pagination={false}
        columns={this.ASGN_COLUMNS}
      />
    );
  };

  render() {
    return (
      <div className="group">
        <div className="header">
          <div className="title">在线作业</div>
          <div className="extra_btn">
            {/*<Button type="default" >*/}
            {/*  <Icon type="link" /> 关联作业*/}
            {/*</Button>*/}
            <AddAsgnButton
              courseId={this.props.courseId}
              onAddAsgnSuccess={this.handleAddLessonAsgn}
            />
          </div>
        </div>
        <div className="course_asgn_list_view">
          <Spin
            size="large"
            tip="加载中...."
            spinning={this.props.isLoading}
          >
            <div className="asgn_table">
              <div className="asgn_table_content">
                {this.renderListArea()}
              </div>
            </div>
          </Spin>
        </div>
      </div>);
  }
}
export default LessonAsgnManage;
