import 'styles/common/layout/contest_base.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Layout, Menu } from 'antd';
import WeJudgeLogoHorizontal from 'svgs/logo_horizontal.svg';
import { get, isEmpty, noop } from 'lodash';
import { withRouter } from 'react-router-dom';
import { GlobalAlertConfigPropTypes } from 'scripts/common/prop-types/wejudge';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { buildPath } from 'scripts/common/utils/location_helper';
import { WeJudgeRoutes } from 'scripts/apps/routes';
import {RoutePropTypes} from 'scripts/common/prop-types/route';

const {
  Content,
  Header,
} = Layout;

const mapDispatchToProps = dispatch => bindActionCreators({
  cleanGlobalAlert: WeJudgeActions.cleanGlobalAlert,
}, dispatch);

const mapStateToProps = (state) => {
  const alertConfig = WeJudgeSelectors.globalAlertSelector(state, 'wejudge');
  return {
    alertConfig,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ContestBaseLayout extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    header: PropTypes.node,
    topbar: PropTypes.node,
    account: PropTypes.node,
    cleanGlobalAlert: PropTypes.func,
    alertConfig: GlobalAlertConfigPropTypes,
  };

  static defaultProps = {
    header: null,
    topbar: null,
    account: null,
    cleanGlobalAlert: noop,
    alertConfig: null,
  };

  state = {};

  handleAlertAfterClose = () => {
    this.props.cleanGlobalAlert('wejudge');
  };

  handleBackWeJudge = () => {
    const { history } = this.props;
    history.push(buildPath(WeJudgeRoutes.WEJUDGE_ROOT));
  };

  render() {
    return <Layout className="contest-base-layout">
      <Header className="contest-topbar">
        <Menu
          selectedKeys={this.state.defaultSelectedKeys}
          theme="dark"
          mode="horizontal"
          className="logo-menu"
        >
          <Menu.SubMenu
            title={<span className="topbar-logo"><svg width={160} height={32}><use xlinkHref={`#${WeJudgeLogoHorizontal.id}`} /></svg></span>}
          >
            <Menu.Item onClick={this.handleBackWeJudge}>返回主站</Menu.Item>
          </Menu.SubMenu>
        </Menu>
        {this.props.topbar}
        {this.props.account}
      </Header>
      {!isEmpty(this.props.alertConfig) && <Alert
        banner
        type={get(this.props.alertConfig, 'type', 'info')}
        message={get(this.props.alertConfig, 'message', '通知')}
        closable={get(this.props.alertConfig, 'closable', true)}
        closeText={get(this.props.alertConfig, 'closeText', undefined)}
        description={get(this.props.alertConfig, 'description', undefined)}
        showIcon={get(this.props.alertConfig, 'showIcon', true)}
        icon={get(this.props.alertConfig, 'icon', undefined)}
        afterClose={this.handleAlertAfterClose}
      />}
      <Content className="contest-main-layout">
        {this.props.header}
        {this.props.children}
      </Content>
    </Layout>;
  }
}

export default ContestBaseLayout;
