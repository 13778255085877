import 'styles/helper/index.scss';

import React from 'react';
import { Menu } from 'antd';
import { get } from 'lodash';
import {
  withRouter, matchPath, Switch, Route, Redirect 
} from 'react-router-dom';
import { HelperRoutes } from 'scripts/apps/routes';
import BaseLayout from 'scripts/apps/wejudge/layout/base';

import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath } from 'scripts/common/utils/location_helper';
import DescView from 'scripts/apps/helper/desc';
import { WeJudge404NotFoundComponent } from '../wejudge/not_found_page';
import FAQView from './faq';
import HelperToolsView from './tools';
import HelperContributeView from './contribute';

@withRouter
class HelperAPP extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
  };

  handleClick = ({ key }) => {
    this.props.history.push(buildPath(HelperRoutes.SUBITEMS, {
      subItemName: key,
    }));
  };

  render() {
    const matchKey = get(matchPath(this.props.location.pathname, {
      path: HelperRoutes.SUBITEMS,
    }), 'params.subItemName', 'content');

    const beianForWeJdugeDotNet = window.location.host.indexOf('wejudge.net') > -1;

    return <BaseLayout>
      <div className="helper-app-view">
        <div className="helper-content">
          <Menu mode="horizontal" selectedKeys={[matchKey]} onSelect={this.handleClick}>
            <Menu.Item key="desc">初来乍到</Menu.Item>
            {beianForWeJdugeDotNet ? null : <Menu.Item key="contribution">一路走来</Menu.Item>}
            <Menu.Item key="faq">有问必答</Menu.Item>
            <Menu.Item key="tools">常用工具</Menu.Item>
            <Menu.Item key="documention" disabled>用户手册</Menu.Item>
          </Menu>
          <div className="helper-penal" id="helper-penal">
            <Switch>
              <Route path={HelperRoutes.DESC} component={DescView} exact />
              <Route path={HelperRoutes.FAQ} component={FAQView} exact />
              <Route path={HelperRoutes.TOOLS} component={HelperToolsView} exact />
              <Route path={HelperRoutes.CONTRIBUTION} component={HelperContributeView} exact />
              <Route path="" render={() => <Redirect to={HelperRoutes.DESC} />} exact />
              <Route component={WeJudge404NotFoundComponent} />
            </Switch>
          </div>
        </div>
      </div>
    </BaseLayout>;
  }
}

export default HelperAPP;
