import 'styles/contest/problem/problem_view.scss';

import React from 'react';
import { get } from 'lodash';
import { Menu, Tag } from 'antd';
import { connect } from 'react-redux';
import {
  Link, matchPath, Route, Switch, withRouter 
} from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import CodeProblemBody from 'scripts/apps/problem/widgets/code_problem_body';
import { ContestProblemSelectors } from 'scripts/common/logic/contest/problem';
import { ContestProblemPropTypes } from 'scripts/common/prop-types/contest';
import { indexToChar } from 'scripts/common/utils/unit';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ContestRoutes } from 'scripts/apps/routes';
import { CodeOutlined, FileTextOutlined } from '@ant-design/icons';
import SubmitCodeView from './submit_code_view';
import { withContestContext } from '../contest_provider';

const mapStateToProps = (state, props) => {
  const problemId = get(props, 'match.params.problemId', '');
  const contestProblem = ContestProblemSelectors.getContestProblemEntity(state)(problemId);
  return {
    contestProblem,
  };
};

@withRouter
@withContestContext
@connect(mapStateToProps, null)
class ProblemView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    contestProblem: ContestProblemPropTypes,
  };

  static defaultProps = {
    contestProblem: null,
  };

  menuItems = [
    {
      key: 'content',
      title: '题目内容',
      path: ContestRoutes.PROBLEM.CONTENT,
      icon: <FileTextOutlined />,
    },
    {
      key: 'submit',
      title: '提交评测',
      path: ContestRoutes.PROBLEM.SUBMIT,
      icon: <CodeOutlined />,
    }
  ];

  matchSubItemKey = () => {
    return get(matchPath(this.props.location.pathname, {
      path: ContestRoutes.PROBLEM.SUBITEMS,
    }), 'params.subItemName', 'content');
  };

  renderMenuItem = ({
    key,
    icon,
    title,
    path,
  }) => (
    <Menu.Item key={key}>
      <Link
        to={buildPath(path, null, this.props.match.params)}
      >
        {icon} {title}
      </Link>
    </Menu.Item>
  );

  renderRouterView = () => {
    const { contestProblem } = this.props;
    const problem = get(contestProblem, 'problem', {});
    return <Switch>
      <Route
        path={ContestRoutes.PROBLEM.CONTENT}
        render={() => {
          return <div className="problem-content">
            <CodeProblemBody
              problem={problem}
              showExtraInfo={false}
            />
          </div>;
        }}
        exact
      />
      <Route
        path={ContestRoutes.PROBLEM.SUBMIT}
        render={() => {
          return <SubmitCodeView
            contestProblem={contestProblem}
            problem={problem}
          />;
        }}
        exact
      />
    </Switch>;
  };

  render() {
    const { contestProblem } = this.props;
    const subItemKey = this.matchSubItemKey();
    return (
      <>
        <div className="contest_problem_view" key={get(contestProblem, 'id', '')}>
          <div className="problem_view_title">
            <Tag color={get(contestProblem, 'color', 'grey')}>{indexToChar(get(contestProblem, 'order', 0))}</Tag>
            {get(contestProblem, 'problem.title', '')}
          </div>
          <div className="problem-content-container">
            <Menu selectedKeys={[subItemKey]} mode="vertical-left">
              {this.menuItems.map(item => this.renderMenuItem(item))}
            </Menu>
            {this.renderRouterView()}
          </div>
        </div>

      </>
    );
  }
}

export default ProblemView;
