import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { OfficeUrlHelper } from 'scripts/common/utils/office_url_helper';
import { Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { LessonActions } from 'scripts/common/logic/education/lesson';

const OfficePlayer = (props) => {
  const {
    courseId,
    lessonId,
    officeId,
    officeType,
    getLessonResources,
  } = props;
  const [officeUrl, setOfficeUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [officeData, setOfficeData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getLessonResources({
      courseId,
      lessonId,
      id: officeId,
      onSuccess: (result) => {
        setOfficeData(result);
        setIsLoading(false);
      },
    });
  }, [officeId]);

  useEffect(() => {
    if (officeData) {
      const pickcode = get(officeData, 'token.pick_code', '');
      const fileHash = get(officeData, 'token.file_hash', '');
      const downloadUrl = `${process.env.RESOURCE_SERVICE}/wopi/files/${fileHash}?pickcode=${pickcode}`;
      setOfficeUrl(OfficeUrlHelper({ url: downloadUrl, type: officeType }));
    }
  }, [officeData]);

  const iframeStyle = {
    verticalAlign: 'bottom',
  };
  if (isLoading) {
    const loadingStyle = {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    };
    const spinStyle = {
      margin: '24px auto',
    };
    return <div style={loadingStyle}>
      <Spin style={spinStyle} spinning={isLoading} tip="解析中..." />
    </div>;
  }
  return (
    <iframe
      title="navigation"
      src={officeUrl}
      width="100%"
      height="100%"
      style={iframeStyle}
      // onLoad={() => setIsLoading(false)}
    >
      该浏览器不支持
    </iframe>
  );
};

OfficePlayer.propTypes = {
  courseId: PropTypes.number.isRequired,
  lessonId: PropTypes.string.isRequired,
  officeType: PropTypes.string,
  officeId: PropTypes.string,
  getLessonResources: PropTypes.func,
};

OfficePlayer.defaultProps = {
  officeType: null,
  officeId: null,
  getLessonResources: noop,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getLessonResources: LessonActions.getLessonResources,
}, dispatch);

export default connect(null, mapDispatchToProps)(OfficePlayer);
