import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import {
  Form, Modal, Input, InputNumber 
} from 'antd';

class GroupsEditDialog extends React.PureComponent {
  static propTypes = {
    group: PropTypes.shape({}),
  };

  static defaultProps = {
    group: {
      name: '',
      capacity: 0,
    },
  };

  state = {
    group: this.props.group,
  };

  COLUMNS = [
    {
      dataIndex: 'id',
      title: '#',
      width: 60,
    },
    {
      dataIndex: 'name',
      title: '分组名称',
    },
    {
      dataIndex: 'capacity',
      title: '容量',
      width: 80,
    },
    {
      dataIndex: 'action',
      title: '操作',
      width: 140,
    }
  ];

  handleNumberInputChange = (val) => {
    this.setState({
      group: {
        ...this.state.group,
        capacity: val,
      },
    });
  };

  handleInputChange = ({ target }) => {
    this.setState({
      group: {
        ...this.state.group,
        name: target.value,
      },
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        sm: { span: 4 },
      },
      wrapperCol: {
        sm: { span: 20 },
      },
    };
    return (
      <Modal
        {...this.props}
        width={520}
      >
        <Form {...formItemLayout}>
          <Form.Item label="分组名称">
            <Input
              onChange={this.handleInputChange}
              value={get(this.state.group, 'name', '')}
            />
          </Form.Item>
          <Form.Item label="分组容量">
            <InputNumber
              min={0}
              onChange={this.handleNumberInputChange}
              value={get(this.state.group, 'capacity', 0)}
            /> (0表示不限制)
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default GroupsEditDialog;
