import 'styles/widgets/cross_check.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {
  get, noop, truncate, isEqual, pick 
} from 'lodash';
import {
  Button, Table, Select
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AsgnCrossCheckActions } from 'scripts/common/logic/education/asgn/actions';
import {
  AsgnCrossCheckSelectors,
  AsgnProblemsSelectors
} from 'scripts/common/logic/education/asgn/selectors';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { buildPath, getQuery } from 'scripts/common/utils/location_helper';

import { AsgnRoutes } from 'scripts/apps/routes';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { indexToChar } from 'scripts/common/utils/unit';
import { AsgnProblemPropTypes } from 'scripts/common/prop-types/asgn';
import { showModal } from 'scripts/common/widgets/modal';
import SimpleCrossCheckDetailDialog from 'scripts/apps/widgets/cross_check/simple_detail';
import ReactResizeDetector from 'react-resize-detector';
import { AntTableMiddleFooterHeight, AntTableMiddleHeaderHeight } from 'scripts/common/constants/table';
import { ReloadOutlined } from '@ant-design/icons';

const LOADING_STATUS_KEY = 'asgn_simple_cross_check_logs_view';
const DEFAULT_RATIO = ['10', '20', '30', '40', '50', '60', '70', '80', '90'].reverse();

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnSimpleCrossCheckLogDetail: AsgnCrossCheckActions.getAsgnSimpleCrossCheckLogDetail,
  getAsgnSimpleCrossCheckLogs: AsgnCrossCheckActions.getAsgnSimpleCrossCheckLogs,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { location } = props;
  const query = getQuery(location);
  const logs = AsgnCrossCheckSelectors.asgnSimpleCrossCheckLogsSelector(state);
  const listdata = get(logs, 'logs', []);
  const pagination = get(logs, 'pagination', null);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const codeProblems = AsgnProblemsSelectors.getAsgnCodeProblemDatas(state);
  const queryParams = {
    ...PaginationParams40,
    ...pick(query, ['limit', 'page', 'order', 'problem_id', 'ratio']),
  };
  return {
    listdata,
    isLoading,
    queryParams,
    codeProblems,
    paginationParams: pagination || props.paginationParams,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AsgnSimpleCrossCheckView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    listdata: PropTypes.arrayOf(PropTypes.shape({})),
    queryParams: PropTypes.shape({}),
    paginationParams: PaginationPropTypes,
    codeProblems: PropTypes.arrayOf(AsgnProblemPropTypes),
    getAsgnSimpleCrossCheckLogs: PropTypes.func,
    getAsgnSimpleCrossCheckLogDetail: PropTypes.func,
  };

  static defaultProps = {
    isLoading: true,
    listdata: [],
    paginationParams: {},
    queryParams: {},
    codeProblems: [],
    getAsgnSimpleCrossCheckLogs: noop,
    getAsgnSimpleCrossCheckLogDetail: noop,
  };

  state = {
    tableHeight: 0,
    queryParams: {},
    hasFetchEntity: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchEntity || !isEqual(get(nextProps, 'queryParams'), prevState.queryParams)) {
      const { queryParams } = nextProps;
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getAsgnSimpleCrossCheckLogs({
        courseId: nextProps.courseId,
        asgnId: nextProps.asgnId,
        query: pick(queryParams, ['limit', 'page', 'order', 'problem_id', 'ratio']),
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        queryParams,
        hasFetchEntity: true,
      };
    }
    return null;
  }

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnRoutes.CROSS_CHECK_SIMPLE,
      {},
      match.params,
      {
        ...pick(this.props.queryParams, ['limit', 'page', 'ratio', 'order', 'problem_id']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleFilterOrderChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnRoutes.CROSS_CHECK_SIMPLE,
      {},
      match.params,
      value === '0'
        ? {
          ...pick(this.props.queryParams, ['problem_id', 'ratio']),
        } : {
          ...pick(this.props.queryParams, ['problem_id', 'ratio']),
          order: value,
        }
    ));
  };

  handleFilterProblemIdChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnRoutes.CROSS_CHECK_SIMPLE,
      {},
      match.params,
      value === '0' ? {
        ...pick(this.props.queryParams, ['order', 'ratio']),
      } : {
        ...pick(this.props.queryParams, ['order', 'ratio']),
        problem_id: value,
      }
    ));
  };

  handleFilterRatioChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnRoutes.CROSS_CHECK_SIMPLE,
      {},
      match.params,
      value === '0' ? {
        ...pick(this.props.queryParams, ['order', 'problem_id']),
      } : {
        ...pick(this.props.queryParams, ['order', 'problem_id']),
        ratio: value,
      }
    ));
  };

  handleViewDetailClick = (logId, labels) => () => {
    showModal(SimpleCrossCheckDetailDialog, {
      sourceLabel: labels.source_label,
      targetLabel: labels.target_label,
      getDetail: (success) => {
        return this.props.getAsgnSimpleCrossCheckLogDetail({
          courseId: this.props.courseId,
          asgnId: this.props.asgnId,
          logId,
          onSuccess: success,
        });
      },
      detailSelector: AsgnCrossCheckSelectors.asgnSimpleCrossCheckLogDetailSelector,
    });
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  renderSimpleColumns = () => {
    return [
      {
        title: '任务序号',
        dataIndex: 'id',
        key: 'id',
        width: 100,
      },
      {
        title: '题目编号',
        dataIndex: 'problem_id',
        key: 'problem_id',
        width: 100,
        align: 'center',
      },
      {
        title: '检测对象',
        key: 'status',
        dataIndex: 'status',
        children: [
          {
            title: '提交1',
            dataIndex: 'source_label',
            key: 'source_label',
            align: 'center',
          },
          {
            title: '提交2',
            dataIndex: 'target_label',
            key: 'target_label',
            align: 'center',
          }
        ],
      },
      {
        title: '查重率',
        dataIndex: 'sim_ratio',
        key: 'sim_ratio',
        align: 'center',
        width: 100,
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        align: 'center',
        width: 120,
      }
    ];
  };

  renderStatusLabel = (status) => {
    return <>
      <strong>#{get(status, 'id')}</strong>
      {get(status, 'student', null) && <>
        ({get(status, 'student.realname')}, {get(status, 'student.code')})
      </>}
    </>;
  };

  renderSimpleRow = () => {
    if (!this.props.listdata) return null;
    return this.props.listdata.map((item) => {
      const labels = {
        source_label: this.renderStatusLabel(get(item, 'source', {})),
        target_label: this.renderStatusLabel(get(item, 'target', {})),
      };
      return {
        key: get(item, 'id', 0),
        id: `#${get(item, 'id', 0)}`,
        problem_id: indexToChar(get(item, 'asgn_problem.order', 0)),
        ...labels,
        sim_ratio: `${get(item, 'sim_ratio', 0)} %`,
        operation: <>
          <Button onClick={this.handleViewDetailClick(get(item, 'id'), labels)} type="primary" size="small">查看详情</Button>
        </>,
      };
    });
  };

  renderTaskTable = () => {
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 1),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.handlePageChange,
      total: get(this.props.paginationParams, 'total', 0),
      size: 'small',
    };
    return <>
      <div className="simple_logs_toolbar">
        <div className="info-area">
          <div className="filter-group">
            <strong>排序方式</strong>
            <Select
              dropdownMatchSelectWidth={false}
              value={get(this.props.queryParams, 'order', '0')}
              onChange={this.handleFilterOrderChange}
            >
              <Select.Option value="0">按查重率倒序</Select.Option>
              <Select.Option value="1">按查重率正序</Select.Option>
              <Select.Option value="2">按ID正序</Select.Option>
              <Select.Option value="3">按ID倒序</Select.Option>
            </Select>
          </div>
          <div className="filter-group">
            <strong>题目筛选</strong>
            <Select
              dropdownMatchSelectWidth={false}
              value={get(this.props.queryParams, 'problem_id', '0')}
              onChange={this.handleFilterProblemIdChange}
            >
              <Select.Option value="0">不限</Select.Option>
              {this.props.codeProblems.map((ap) => {
                return <Select.Option key={get(ap, 'id', 0)} value={get(ap, 'id', 0).toString()}>
                  题目{indexToChar(get(ap, 'order', 0))}.&nbsp;
                  {truncate(get(ap, 'problem.title', ''), {
                    length: 20,
                  })}
                </Select.Option>;
              })}
            </Select>
          </div>
          <div className="filter-group">
            <strong>查重率</strong>
            <Select
              dropdownMatchSelectWidth={false}
              value={get(this.props.queryParams, 'ratio', '0')}
              onChange={this.handleFilterRatioChange}
            >
              <Select.Option value="0">不限</Select.Option>
              {DEFAULT_RATIO.map((ratio) => {
                return <Select.Option key={ratio.toString()} value={ratio.toString()}>
                  ≥ {ratio}%
                </Select.Option>;
              })}
            </Select>
          </div>
        </div>
        <div className="action-area">
          <Button
            onClick={() => this.setState({ hasFetchEntity: false })}
            type="primary"
          >
            <ReloadOutlined /> 刷新列表
          </Button>
        </div>
      </div>
      <div className="cross_check_table">
        <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
        <Table
          pagination={optPagination}
          bordered
          loading={{ spinning: this.props.isLoading, tip: '正在载入查重任务记录...' }}
          scroll={{
            y: this.state.tableHeight - (AntTableMiddleHeaderHeight * 2 + AntTableMiddleFooterHeight),
            scrollToFirstRowOnChange: true,
          }}
          columns={this.renderSimpleColumns()}
          dataSource={this.renderSimpleRow()}
        />
      </div>
    </>;
  };

  render() {
    return (<div className="simple_cross_check_view">
      {this.renderTaskTable()}
    </div>);
  }
}

export default AsgnSimpleCrossCheckView;
