import 'styles/education/course/list.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {
  get, pick, isEqual, isEmpty
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Input, Menu, Pagination, Tag, Select, Drawer, Button, Radio, List, Empty, Modal, message
} from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { PaginationParams } from 'scripts/common/constants/global';
import { withRouter } from 'react-router-dom';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { CourseTypeEnum, CourseListOrderEnum } from 'scripts/common/enums/course';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { CategoryActions } from 'scripts/common/logic/category/action';
import { SchoolActions } from 'scripts/common/logic/education/school/action';
import { CourseSelectors } from 'scripts/common/logic/education/course/selector';
import { CategorySelectors } from 'scripts/common/logic/category/selector';
import { SchoolSelectors } from 'scripts/common/logic/education/school/selector';
import { CourseRoutes } from 'scripts/apps/routes';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { SchoolPropTypes } from 'scripts/common/prop-types/school';
import { AccountPropTypes, AuthPropTypes } from 'scripts/common/prop-types/account';
import { CategoryPropTypes } from 'scripts/common/prop-types/category';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { CommonCategoryTypeEnum } from 'scripts/common/enums/common';
import { showModal } from 'scripts/common/widgets/modal';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import  CategoryEditor from 'scripts/apps/widgets/category/editor';
import DataKeys from 'scripts/common/constants/data_keys';
import { enumMap } from 'scripts/common/utils/enum_generator';
import IconText from 'scripts/common/widgets/icon-text';
import CenterSpin from 'scripts/common/widgets/center-spin';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import localStorage from 'localStorage';
import { deepFindCategory } from 'scripts/apps/widgets/category/utils';
import CourseListItem  from 'scripts/apps/education/course/widgets/course_list_item';
import AddCourseEditor from 'scripts/apps/education/course/widgets/dialog/add_course_editor';
import { CourseCard } from './widgets/course_card';
import CourseChoosingDialog from './widgets/course_choosing';

const LOADING_STATUS_KEY = 'course_list_view';

const { Search } = Input;

const { CheckableTag } = Tag;

const { Option } = Select;

const AddCourseModal = (props) => {
  return (<Modal
    {...props}
    title="新建课程"
  >
    <AddCourseEditor
      handleChange={props.onChange}
    />
  </Modal>);
};

AddCourseModal.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const CommonPickup = ['limit', 'keyword', 'school', 'type', 'order'];

const mapDispatchToProps = dispatch => bindActionCreators({
  getCoursesList: CourseActions.getCoursesList,
  saveCourseChoosing: CourseActions.saveCourseChoosing,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  addCourse: CourseActions.addCourse,
  getSchoolList: SchoolActions.getSchoolList,
  getCategoryTree: CategoryActions.getCategoryTree,
  createCategory: CategoryActions.createCategory,
  deleteCategory: CategoryActions.deleteCategory,
  modifyCategory: CategoryActions.modifyCategory,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const { match } = props;
  const categoryId = get(match.params, 'categoryId', '');
  const pager = CourseSelectors.coursesListViewPagerSelector(state);
  const account = AccountSelectors.getLoginAccount(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const hasCreateCoursePermission = props.auth.logined
    && (get(props.auth.account, 'role', 0) === AccountRoleEnum.TEACHER.value
      || get(props.auth.account, 'role', 0) === AccountRoleEnum.DEAN.value
      || get(props.auth.account, 'role', 0) === AccountRoleEnum.ADMINISTRATOR.value); //这里加上了系统管理员
  const isAdmin = get(props.auth.account, 'role', 0) === AccountRoleEnum.ADMINISTRATOR.value;
  let coursesList = CourseSelectors.getCourseList(state);
  const schoolList = SchoolSelectors.schoolListData(state);
  const categoryTree = CategorySelectors.categoryTreeViewSelector(
    CommonCategoryTypeEnum.COURSE.value
  )(state) || [];
  const queryParams = { 
    ...PaginationParams,
    ...pick(queryset, [...CommonPickup, 'page', 'category']),
    category: (!categoryId || categoryId === 'all') ? null : categoryId,
  };
  queryParams.type = get(queryParams, 'type', isAdmin ? CourseTypeEnum.ALL.value : CourseTypeEnum.MINE.value);
  // 对于教师来说，只卡 我的课程 显示本人课程
  if (get(account, 'role') === 2 && account && coursesList && +queryParams.type === +CourseTypeEnum.MINE.value) {
    coursesList = coursesList.map((item) => {
      if (get(item, 'author.id', '') === get(account, 'id')) {
        return item;
      }
      return null;
    }).filter(item => !!item);
  }
  return {
    account,
    queryParams,
    categoryId,
    isLoading,
    paginationParams: pager || PaginationParams,
    coursesList,
    schoolList,
    categoryTree,
    hasCreateCoursePermission,
    isAdmin,
  };
};

@LoginRequire
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CourseListView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const query = pick(
      nextProps.queryParams,
      [...CommonPickup, 'page', 'category']
    );
    nextProps.getCoursesList({
      query,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    auth: AuthPropTypes.isRequired,
    account: AccountPropTypes,
    coursesList: PropTypes.arrayOf(CoursePropTypes).isRequired, 
    getCoursesList: PropTypes.func.isRequired,
    getSchoolList: PropTypes.func.isRequired,
    categoryTree: PropTypes.arrayOf(CategoryPropTypes).isRequired,
    schoolList: PropTypes.arrayOf(SchoolPropTypes),
    getCategoryTree: PropTypes.func.isRequired,
    createCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    modifyCategory: PropTypes.func.isRequired,
    saveCourseChoosing: PropTypes.func.isRequired,
    ...RoutePropTypes,
    queryParams: PropTypes.shape({}).isRequired,
    paginationParams: PaginationPropTypes,
    categoryId: PropTypes.string,
    hasCreateCoursePermission: PropTypes.bool,
    isAdmin: PropTypes.bool,
    addCourse: PropTypes.func.isRequired,
  };

  static defaultProps = {
    categoryId: '',
    account: null,
    schoolList: [],
    paginationParams: { ...PaginationParams },
    hasCreateCoursePermission: false,
    isAdmin: false,
  };

  state = {
    newCourse: null,
    categoryEditVisible: false,
    queryParams: null,
    mode: 'listview',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // 如果因为路由的Query发生改变，则发起列表请求
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      CourseListView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  componentDidMount() {
    if (isEmpty(this.props.categoryTree)) {
      this.handleFetchCategoryList();
    }
    if (isEmpty(this.props.schoolList)) {
      this.props.getSchoolList({});
    }
    this.loadViewPreferences();
  }

  getViewPreferencesData = () => {
    let pref = {};
    try {
      pref = JSON.parse(localStorage.getItem(DataKeys.LOCAL_STORAGE.COURSE_PREF_STORAGE_KEY));
      if (!pref) pref = {};
    } catch (e) {
      pref = {};
    }
    return pref;
  };

  // 载入偏好设置
  loadViewPreferences = () => {
    const pref = this.getViewPreferencesData();
    if (!isEmpty(pref)) {
      this.setState(pref);
    }
  };

  // 保存偏好
  saveViewPreferences = (pkey) => {
    const pref = this.getViewPreferencesData();
    pref[pkey] = get(this.state, pkey, '');
    localStorage.setItem(
      DataKeys.LOCAL_STORAGE.COURSE_PREF_STORAGE_KEY,
      JSON.stringify(pref)
    );
  };

  openCourseChoosingDialog = (course) => {
    const modal = showModal(CourseChoosingDialog, {
      course,
      account: this.props.account,
      onSubmit: (state) => {
        this.props.saveCourseChoosing({
          courseId: course.id,
          ...state,
          onSuccess: () => {
            modal.close();
            const { history } = this.props;
            history.push(buildPath(
              CourseRoutes.CONTENT_OVERVIEW,
              { courseId: course.id }
            ));
          },
        });
        return false;
      },
    });
  }

  handleFetchCategoryList = () => {
    this.props.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COMMON_CATEGORY_LIST, true);
    this.props.getCategoryTree({
      categoryId: this.props.categoryId,
      type: CommonCategoryTypeEnum.COURSE.value,
      onSuccess: () => {
        this.props.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COMMON_CATEGORY_LIST, false);
      },
    });
  };

  handleDrawerClose = () => {
    this.setState({
      categoryEditVisible: false,
    });
  };

  handleCategoryIdChange = (categoryId) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.LIST,
      { categoryId: (categoryId === 'all') ? undefined : categoryId },
      match.params,
      pick(this.state.queryParams, CommonPickup)
    ));
  };

  handleSchoolIdChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        school: value,
      }
    ));
  };

  handleCourseOrderTabClick = (item) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        order: item.value,
      }
    ));
  };

  handleTypeOptionClick = ({ value }) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        type: value,
      }
    ));
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        page,
        limit: pageSize,
      }
    ));
  };

  handleSearch = (value) => {
    if (isEqual(value, get(this.state.queryParams, 'keyword'))) return;
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        keyword: value,
      }
    ));
  };

  handleListModeChange = (e) => {
    this.setState({
      mode: e.target.value,
    }, () => {
      this.saveViewPreferences('mode');
    });
  };

  handleCourseCardClick = item => () => {
    const hasPerm = get(item, 'permissions_calculated.1', false);

    if (hasPerm) {
      const { history } = this.props;
      history.push(buildPath(
        CourseRoutes.CONTENT_OVERVIEW,
        { courseId: item.id }
      ));
    } else {
      const hasChoice = get(item, 'has_choice', false);
      const st = get(item, 'start_time', 0);
      const et = get(item, 'end_time', 0);
      const nowt = new Date().getTime() / 1000;

      if (st > nowt) {
        // 未开始，如果没有访问权限，允许选课
        if (hasPerm && !hasChoice) {
          this.openCourseChoosingDialog(item);
        } else {
          message.info('作业还未开始');
        }
      } else if (st < nowt && nowt < et) {
        this.openCourseChoosingDialog(item);
      }
      if (nowt >= et) {
        // 已结束
        message.error('作业已经结束');
      }
    }
  };

  handleAddCourseClick = () => {
    const { history } = this.props;
    this.setState({
      newCourse: {},
    }, () => {
      const modalBoxCourse = showModal(AddCourseModal, {
        width: 720,
        onOk: () => {
          this.props.addCourse({
            ...this.state.newCourse,
            category: get(this.state.newCourse, 'category.1', get(this.state.newCourse, 'category.0', 0)),
            onSuccess: (result) => {
              modalBoxCourse.close();
              history.push(buildPath(
                CourseRoutes.CONTENT,
                {
                  courseId: `${result}`,
                },
                null,
                null
              ));
            },
          });
          return false;
        },
        onChange: (course) => {
          this.setState({
            newCourse: course,
          });
        },
      });
    });
  };

  handleAddCategoryClick= () => {
    this.setState({
      categoryEditVisible: true,
    });
  };
  
  render() {
    const { schoolList } = this.props;
    const currentCategory = deepFindCategory(this.props.categoryId, this.props.categoryTree);
    const levelOneCategoryId = get(currentCategory, 'parent.id',  get(currentCategory, 'entity.id', 'all')).toString();
    const showPagination = get(this.props.paginationParams, 'total', 0) > get(this.props.paginationParams, 'limit', 1);
    const subCategorys = get(currentCategory, 'parent.children', get(currentCategory, 'entity.children', []));
    const { coursesList } = this.props;
    const courseTypeEnumList = [
      CourseTypeEnum.MINE,
      CourseTypeEnum.ALL,
      CourseTypeEnum.NORMAL,
      CourseTypeEnum.PUBLIC
    ];
    return (
      <div className="course-list-view">
        <div className="list-header">
          <Search
            className="search_box"
            addonBefore={<Select
              showSearch
              dropdownMatchSelectWidth={false}
              size="large"
              placeholder="学校或组织"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.children.indexOf(input) >= 0;
              }}
              value={get(this.state, 'queryParams.school', '').toString()}
              onChange={this.handleSchoolIdChange}
            >
              <Option value="">全部学校或组织</Option>
              {schoolList.map((school) => {
                return <Option key={get(school, 'id')} value={get(school, 'id').toString()}>{get(school, 'name')}</Option>;
              })}
            </Select>}
            placeholder="支持搜索课程名称、创建者"
            enterButton
            onSearch={this.handleSearch}
            size="large"
            defaultValue={this.state.queryParams.keyword}
          />
          <div className="list-header-menu">
            <Menu
              onClick={(item) => this.handleCategoryIdChange(item.key)}
              selectedKeys={[levelOneCategoryId]}
              mode="horizontal"
              className="category"
            >
              <Menu.Item key="all">全部</Menu.Item>
              {this.props.categoryTree.map(item => (
                <Menu.Item key={item.id.toString()}>
                  {item.title}
                </Menu.Item>
              ))}
            </Menu>
            {this.props.hasCreateCoursePermission
              ? <div className="coursecategory-editor">
                <Button
                  onClick={this.handleAddCourseClick}
                  className="course-editor"
                  type="primary"
                >
                  创建课程
                </Button>
                <Button
                  onClick={this.handleAddCategoryClick}
                  className="category-editor"
                >
                  分类编辑
                </Button>
              </div>
              : null }
          </div>
          <div className="header-filter">
            {!isEmpty(subCategorys) && <div className="list-subject">
              <CheckableTag
                key={levelOneCategoryId}
                checked={levelOneCategoryId === this.props.categoryId}
                onChange={() => this.handleCategoryIdChange(levelOneCategoryId)}
                className="list-subject-item"
              >
                不限
              </CheckableTag>
              {subCategorys.map(item => (
                <CheckableTag
                  key={item.id.toString()}
                  checked={this.props.categoryId === item.id.toString()}
                  onChange={() => this.handleCategoryIdChange(item.id.toString())}
                  className="list-subject-item"
                >
                  {item.title}
                </CheckableTag>
              ))}
            </div>}
            <div className="list-arrange">
              <div className="option-selectors">
                <div className="selector-item">
                  <div className="s-title">
                    课程类型：
                  </div>
                  <div className="s-body">
                    {courseTypeEnumList.map((item) => {
                      if (this.props.isAdmin && item.value === CourseTypeEnum.MINE.value) {
                        return null;
                      }
                      return <CheckableTag
                        key={item.key}
                        checked={get(this.state, 'queryParams.type', this.props.isAdmin ? CourseTypeEnum.ALL.value : CourseTypeEnum.MINE.value).toString() === item.value.toString()}
                        className="list-rank"
                        onChange={() => this.handleTypeOptionClick(item)}
                      >
                        {item.desc}
                      </CheckableTag>;
                    })}
                  </div>
                </div>
                <div className="selector-item">
                  <div className="s-title">
                    排序方式：
                  </div>
                  <div className="s-body">
                    {enumMap(CourseListOrderEnum, (item) => {
                      return <CheckableTag
                        key={item.value}
                        checked={get(this.state, 'queryParams.order', CourseListOrderEnum.DEFAULT.value).toString() === item.value.toString()}
                        className="list-rank"
                        onChange={() => this.handleCourseOrderTabClick(item)}
                      >
                        {item.desc}
                      </CheckableTag>;
                    })}
                  </div>
                </div>
              </div>
              <div className="extra-selector">
                <Radio.Group
                  value={this.state.mode}
                  buttonStyle="solid"
                  onChange={this.handleListModeChange}
                >
                  <Radio.Button value="listview">
                    <IconText icon={<BarsOutlined />} text="列表" />
                  </Radio.Button>
                  <Radio.Button value="card">
                    <IconText icon={<AppstoreOutlined />} text="卡片" />
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames('list-wrap', {
            'listview-mode': this.state.mode === 'listview',
            'card-mode': this.state.mode === 'card',
          })}
        >
          <CenterSpin
            size="large"
            tip="加载中..."
            spinning={this.props.isLoading}
          />
          {!this.props.isLoading && <div className="list-view">
            {!this.props.isLoading && <>
              {this.state.mode === 'card' && <div className="card-wrap">
                {!isEmpty(coursesList) ? coursesList.map(item => <CourseCard
                  id={item.id}
                  key={item.id}
                  account={this.props.account}
                  onClick={this.handleCourseCardClick(item)}
                />) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无课程" />}
              </div>}
              {this.state.mode === 'listview' && <List
                itemLayout="vertical"
                dataSource={coursesList}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无课程" /> }}
                renderItem={(item) => {
                  return <CourseListItem
                    id={item.id}
                    key={item.id}
                    onClick={this.handleCourseCardClick(item)}
                  />;
                }}
              />}
            </>}
          </div>}
        </div>
        {!this.props.isLoading && showPagination && <div className="list-footer">
          <Pagination
            current={get(this.props.paginationParams, 'page', 1)}
            pageSize={get(this.props.paginationParams, 'limit', 1)}
            showQuickJumper
            showSizeChanger
            pageSizeOptions={['12', '20', '40']}
            onChange={this.handlePageChange}
            total={get(this.props.paginationParams, 'total', 0)}
          />
        </div>}
        <Drawer
          width={400}
          title="分类编辑"
          placement="right"
          onClose={this.handleDrawerClose}
          visible={this.state.categoryEditVisible}
          getContainer={false}
          handledrawerclose="true"
        >
          <CategoryEditor
            categoryTree={this.props.categoryTree}
            createCategory={this.props.createCategory}
            modifyCategory={this.props.modifyCategory}
            deleteCategory={this.props.deleteCategory}
            categoryId={this.props.categoryId}
            canManageCategory={this.props.isAdmin}
            onClose={this.handleDrawerClose}
            onFetchList={this.handleFetchCategoryList}
            categoryType={CommonCategoryTypeEnum.COURSE.value}
          />
        </Drawer>
      </div>);
  }
}

export default CourseListView;
