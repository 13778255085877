import 'styles/contest/account/auth_view.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty, isEqual } from 'lodash';
import {
  ManOutlined, WomanOutlined, CheckOutlined, PayCircleOutlined
} from '@ant-design/icons';
import {
  Form, Input, Radio, Button, Alert, Divider, Tabs, List, PageHeader, Menu, Result
} from 'antd';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ContestAccountPropTypes, ContestPropTypes } from 'scripts/common/prop-types/contest';
import { ContestAccountActions } from 'scripts/common/logic/contest/account/action';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ContestAccountSelectors } from 'scripts/common/logic/contest/account';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { showModal } from 'scripts/common/widgets/modal';
import WeixinPaymentQrcodeDialog from 'scripts/apps/widgets/common/weixin_payment_qrcode_dialog';
import { RegisterTypeEnum } from 'scripts/common/enums/contest';
import ContestLoginRequire from '../contest_auth';

const mapStateToProps = (state) => {
  return {
    account: ContestAccountSelectors.getLoginContestAccount(state),
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  enrollContestAccount: ContestAccountActions.enrollContestAccount,
  getEnrollPayment: ContestAccountActions.getEnrollPayment,
  createEnrollPayment: ContestAccountActions.createEnrollPayment,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
}, dispatch);

@ContestLoginRequire
@withRouter
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class EnrollView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    account: ContestAccountPropTypes,
    contest: ContestPropTypes,
    enrollContestAccount: PropTypes.func.isRequired,
    getEnrollPayment: PropTypes.func.isRequired,
    createEnrollPayment: PropTypes.func.isRequired,
    viewSuccessMessage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    account: {},
    contest: {},
  };

  constructor(props) {
    super(props);
    const isPaid = get(props.account, 'is_paid', false);
    const enrollNeedPay = get(props.contest, 'enroll_need_pay', false);
    const isEnrolled = get(props.account, 'is_enrolled', false);
    this.state = {
      username: '',
      password: '',
      nickname: '',
      sex: 1,
      realname: '',
      coachname: '',
      schoolname: '',
      accountFields: {},
      submitting: false,
      account: null,
      postPaymentLoading: false,
      paymentInfo: null,
      activeKey: (enrollNeedPay && isEnrolled && !isPaid) ? 'payment' : 'info',
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.account, prevState.account)) {
      const configFields = get(nextProps.contest, 'configs.account_fields', {});
      const accountFields = JSON.parse(JSON.stringify(get(nextProps.account, 'extra_fields', {})));
      Object.values(configFields).forEach((item) => {
        if (item.name === '教练名' || item.name === '学校名') return;
        accountFields[item.name] = accountFields[item.name] || item.default;
      });
      return {
        account: nextProps.account,
        nickname: get(nextProps.account, 'nickname', ''),
        sex: get(nextProps.account, 'sex', 1),
        realname: get(nextProps.account, 'realname', ''),
        coachname: get(nextProps.account, 'coachname', '') || get(configFields, '教练名.default', ''),
        schoolname: get(nextProps.account, 'schoolname', '') || get(configFields, '学校名.default', ''),
        accountFields,
      };
    }
    return null;
  }

  isSubmitDisabled = () => {
    const portalOn = get(this.props.contest, 'configs.register_portal', false);
    let ret = false;
    if (portalOn) {
      return !this.state.username || !this.state.password;
    }
    // 是否是团队比赛
    const isGroupContest = get(this.props.contest, 'team_type', 0) === RegisterTypeEnum.GROUP.value;
    if (isGroupContest) {
      ret = !this.state.realname;
    } else {
      ret = !this.state.realname || !this.state.nickname;
    }
    const fieldList = [];
    Object.values(get(this.props.contest, 'configs.account_fields', {})).forEach((item) => {
      if (get(item, 'available', false) && get(item, 'require', false)) {
        fieldList.push(item);
      }
    });
    fieldList.forEach((f) => {
      const key = get(f, 'name');
      if (key === '教练名') {
        ret = ret || !this.state.coachname;
      } else if (key === '学校名') {
        ret = ret || !this.state.schoolname;
      } else {
        ret = ret || !get(this.state, `accountFields.${f.name}`, '');
      }
    });
    return ret;
  };

  handleChange = key => ({ target }) => {
    if (key === '教练名') {
      this.setState({
        coachname: target.value,
      });
    } else if (key === '学校名') {
      this.setState({
        schoolname: target.value,
      });
    } else if (key === 'username' || key === 'password' || key === 'realname' || key === 'sex' || key === 'nickname') {
      this.setState({
        [key]: target.value,
      });
    } else {
      this.setState({
        accountFields: {
          ...this.state.accountFields,
          [key]: target.value,
        },
      });
    }
  };

  handleRegister = () => {
    if (!this.isSubmitDisabled() && !this.state.submitting) {
      this.setState({
        submitting: true,
      }, () => {
        const portalOn = get(this.props.contest, 'configs.register_portal', false);
        let payloads;
        if (portalOn) {
          payloads = {
            username: this.state.username,
            password: this.state.password,
          };
        } else {
          payloads = {
            sex: this.state.sex,
            nickname: this.state.nickname,
            realname: this.state.realname,
            coachname: this.state.coachname,
            schoolname: this.state.schoolname,
            account_fields: this.state.accountFields,
          };
        }
        this.props.enrollContestAccount({
          ...payloads,
          contestId: this.props.contest.id,
          onSuccess: () => {
            this.setState({
              activeKey: 'payment',
            });
          },
          onComplete: () => {
            this.setState({
              submitting: false,
            });
          },
        });
      });
    }
  };

  handleGetPaymentQrcode = product => () => {
    const productId = get(product, 'id');
    this.setState({
      postPaymentLoading: true,
    }, () => {
      this.props.createEnrollPayment({
        contestId: this.props.contest.id,
        paymentParams: {
          provider: 1,
          wx_trade_type: 'NATIVE',
          product_id: productId,
        },
        onSuccess: (ret) => {
          showModal(WeixinPaymentQrcodeDialog, {
            url: get(ret, 'code_url', ''),
            name: get(product, 'name'),
            price: (get(product, 'price', 0) / 100).toFixed(2),
            orderId: get(ret, 'order_id', 0),
            contestId: this.props.contest.id,
            getEnrollPayment: this.props.getEnrollPayment,
          });
        },
        onComplete: () => {
          this.setState({
            postPaymentLoading: false,
          });
        },
      });
    });
  };

  renderPortalView = () => {
    const isEnrolled = get(this.props.account, 'is_enrolled', false);
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
    };
    return isEnrolled ? '你已成功报名！' : <>
      <Alert
        showIcon
        type="info"
        message="主办方启用了认证报名方式，请按照主办方的要求输入认证信息。信息通过主办方的服务器验证后，报名即可成功"
      />
      <Form {...formItemLayout}>
        <Form.Item label="账号(如学号)">
          <Input
            required
            className="input"
            size="large"
            placeholder="认证账号(必填)"
            value={this.state.username}
            onChange={this.handleChange('username')}
          />
        </Form.Item>
        <Form.Item label="密码">
          <Input.Password
            required
            className="input"
            placeholder="认证密码(必填)"
            size="large"
            value={this.state.password}
            onChange={this.handleChange('password')}
          />
        </Form.Item>
      </Form>
    </>;
  };

  renderEnrollView = () => {
    let list = [];
    Object.values(get(this.props.contest, 'configs.account_fields', {})).forEach((item) => {
      list.push(item);
    });
    // 执行排序
    list = list.sort((a, b) => {
      return a.index < b.index ? -1 : 1;
    });
    // 是否是团队比赛
    const isGroupContest = get(this.props.contest, 'team_type', 0) === RegisterTypeEnum.GROUP.value;

    const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    };
    return (
      <Form {...formItemLayout}>
        {!isGroupContest && <Form.Item label="昵称/队名" required>
          <Input
            className="input"
            placeholder="请输入昵称/队名"
            size="large"
            value={this.state.nickname}
            maxLength={64}
            onChange={this.handleChange('nickname')}
          />
        </Form.Item>}
        <Form.Item label="真实姓名" required>
          <Input
            className="input"
            placeholder="请输入真实姓名"
            size="large"
            value={this.state.realname}
            maxLength={32}
            onChange={this.handleChange('realname')}
          />
        </Form.Item>
        <Form.Item label="性别" required>
          <Radio.Group
            className="account-sex-radio"
            value={this.state.sex}
            buttonStyle="solid"
            size="large"
            onChange={this.handleChange('sex')}
          >
            <Radio.Button value={1}><ManOutlined /> 男</Radio.Button>
            <Radio.Button value={0}><WomanOutlined /> 女</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {list.map((item) => {
          const placename = get(item, 'placename', '');
          const placeholder = get(item, 'placeholder', '');
          if (!item) return null;
          if (item.name === '教练名' && item.available) {
            return (
              <Form.Item
                key={item.index}
                label={placename || item.name}
                required={item.require}
              >
                <Input
                  className="input"
                  placeholder={placeholder || '请输入教练名'}
                  size="large"
                  value={this.state.coachname || ''}
                  maxLength={item.max_length ? item.max_length : undefined}
                  onChange={this.handleChange(item.name)}
                />
              </Form.Item>
            );
          } if (item.name === '学校名' && item.available) {
            return (
              <Form.Item
                key={item.index}
                label={placename || item.name}
                required={item.require}
              >
                <Input
                  className="input"
                  placeholder={placeholder || '请输入学校名'}
                  size="large"
                  value={this.state.schoolname || ''}
                  maxLength={item.max_length ? item.max_length : undefined}
                  onChange={this.handleChange(item.name)}
                />
              </Form.Item>
            );
          }
          if (!item.available) return null;
          return (
            <Form.Item
              key={item.index}
              label={placename || item.name}
              required={item.require}
            >
              <Input
                className="input"
                placeholder={placeholder || `请输入${item.name}`}
                size="large"
                value={get(this.state, `accountFields.${item.name}`, '')}
                maxLength={item.max_length ? item.max_length : undefined}
                onChange={this.handleChange(item.name)}
              />
            </Form.Item>
          );
        })}
      </Form>
    );
  };

  renderHeader = () => {
    const portalOn = get(this.props.contest, 'configs.register_portal', false);
    const isEnrolled = get(this.props.account, 'is_enrolled', false);
    const isPaid = get(this.props.account, 'is_paid', false);
    const enrollNeedPay = get(this.props.contest, 'enroll_need_pay', false);
    if (isEnrolled) {
      if (enrollNeedPay && !isPaid) {
        return <PageHeader
          avatar={{ icon: <PayCircleOutlined />, style: { background: '#1890ff' } }}
          title="待支付"
          subTitle="你已报名本次比赛，根据官方要求，需要支付报名费用"
        />;
      }
      if (portalOn) {
        return <PageHeader
          avatar={{ icon: <CheckOutlined />, style: { background: '#52c41a' } }}
          title="你已成功报名本次比赛！"
        />;
      }
      return <PageHeader
        avatar={{ icon: <CheckOutlined />, style: { background: '#52c41a' } }}
        title="报名成功"
        subTitle="你已成功报名本次比赛，如果有需要，你还可以修改报名信息"
      />;
    }
    return <PageHeader title="报名参赛" />;
  };

  renderNeedPaymentEnroll = () => {
    const isPaid = get(this.props.account, 'is_paid', false);
    const portalOn = get(this.props.contest, 'configs.register_portal', false);
    const isEnrolled = get(this.props.account, 'is_enrolled', false);
    const canShowEdit = !isEnrolled || (!portalOn && isEnrolled);
    const productList = get(this.props.contest, 'enroll_pay_products', []);
    const enrollNeedPay = get(this.props.contest, 'enroll_need_pay', false);
    return (
      <div className="contest_auth_view">
        <div className="auth_header">
          {this.renderHeader()}
          <Menu
            mode="horizontal"
            onSelect={({ key }) => {
              this.setState({
                activeKey: key,
              });
            }}
            selectedKeys={[this.state.activeKey]}
          >
            <Menu.Item key="info">
              信息
            </Menu.Item>
            {isEnrolled && <Menu.Item key="payment">
              支付
            </Menu.Item>}
          </Menu>
        </div>
        <div className="auth_body account-enroll account-enroll-payment">
          <div className="info-wrap">
            {this.state.activeKey === 'info' && <>
              {canShowEdit ? <>
                {portalOn ? this.renderPortalView() : this.renderEnrollView()}
              </> : <>
                认证报名模式不能修改信息哦！
              </>}
            </>}
            {this.state.activeKey === 'payment' && <>
              {enrollNeedPay && isEnrolled && <>
                {!isPaid ? <>
                  <Alert
                    showIcon
                    type="info"
                    message="请按照主办方的要求，选择对应的付费项去支付相应费用，否则将被视作报名失败。一经支付成功，将视作报名成功，除不可抗因素外不能退款。"
                  />
                  <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={productList}
                    renderItem={item => (
                      <List.Item
                        key={get(item, 'id')}
                        actions={[<Button
                          type="primary"
                          disabled={this.state.postPaymentLoading}
                          onClick={this.handleGetPaymentQrcode(item)}
                        >去支付</Button>]}
                      >
                        <List.Item.Meta
                          title={`${get(item, 'name')} (￥${(get(item, 'price', 0) / 100).toFixed(2)})`}
                          description={get(item, 'detail')}
                        />
                      </List.Item>
                    )}
                  />
                </> : <Result title="你已成功支付！" status="success" />}
              </>}
            </>}
          </div>
        </div>
        {this.state.activeKey === 'info' && <div className="auth_footer">
          <Button
            size="large"
            type="primary"
            disabled={this.isSubmitDisabled()}
            onClick={this.handleRegister}
            className="action-btn"
            loading={this.state.submitting}
          >
            {isEnrolled ? '修改报名信息' : '确认报名'}
          </Button>
        </div>}
      </div>
    );
  };

  renderNormalEnroll = () => {
    const portalOn = get(this.props.contest, 'configs.register_portal', false);
    const isEnrolled = get(this.props.account, 'is_enrolled', false);
    const canShowEdit = !isEnrolled || (!portalOn && isEnrolled);
    return (
      <div className="contest_auth_view">
        <div className="auth_header">
          {this.renderHeader()}
        </div>
        <div className="auth_body account-enroll">
          {canShowEdit && <div className="info-wrap">
            {portalOn ? this.renderPortalView() : this.renderEnrollView()}
          </div>}
        </div>
        <div className="auth_footer">
          <Button
            size="large"
            type="primary"
            disabled={this.isSubmitDisabled()}
            onClick={this.handleRegister}
            className="action-btn"
            loading={this.state.submitting}
          >
            {isEnrolled ? '修改报名信息' : '确认报名'}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    if (isEmpty(this.props.account)) return null;
    const enrollNeedPay = get(this.props.contest, 'enroll_need_pay', false);
    return enrollNeedPay ? this.renderNeedPaymentEnroll() : this.renderNormalEnroll();
  }
}

export default EnrollView;
