import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Tag } from 'antd';

const { CheckableTag } = Tag;
class Header extends PureComponent {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllList: PropTypes.func,
    collectionId: PropTypes.string.isRequired,
    problemId: PropTypes.string.isRequired,
    setLanguage: PropTypes.func,
    isMethod: PropTypes.bool,
    clearMethod: PropTypes.func,
  }

  static defaultProps = {
    setLanguage: noop,
    getAllList: noop,
    clearMethod: noop,
    isMethod: false,
    tags: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      languageSelector: '',
      checked: true,
    };
    this.selectitem = this.selectitem.bind(this);
  }
  
  selectitem = (item, checked) => {
    this.setState({
      currentIndex: item.id,
      languageSelector: item.name,
      checked: !checked,
    }, () => {
      this.props.setLanguage(this.state.languageSelector);
    });
  }

  stopPop(e, item, checked) {
    e.stopPropagation();
    this.setState({
      currentIndex: item.id,
      languageSelector: item.name,
      checked: !checked,
    });
  }

  stopPopAll(e) {
    e.stopPropagation();
  }

  showAll() {
    this.setState({
      currentIndex: 0,
    });
    this.props.getAllList({
      collectionId: this.props.collectionId,
      id: this.props.problemId,
      query: {
        keyword: '',
        tags: '',
        limit: 999,
        page: 1,
      },
    });
    this.props.clearMethod();
  }

  render() {
    return (
      <> 
        <div className="tags-langage-container"> 
          <span onClick={d => this.stopPopAll(d)}>
            <CheckableTag
              checked={this.state.currentIndex === 0 && !this.props.isMethod}
              onChange={() => this.showAll()}
            >
              全部
            </CheckableTag>
          </span>{
    this.props.tags.filter((arr) => {
      return arr.language === true;
    }).map(item => <div className="block-item" key={item.id}>
      <span className="solutions-tags-language" onClick={d => this.stopPop(d, item, this.state.checked)}>
        <CheckableTag
          key={item.id}
          checked={item.id === this.state.currentIndex}
          onChange={d => this.selectitem(d, item, this.state.checked)}
        >
          {item.name}
        </CheckableTag>
      </span>
    </div>)
        }
        </div>
      </>
    );
  }
}
export default Header;
