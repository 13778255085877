import React from 'react';
import {
  noop, get, set, isEqual 
} from 'lodash';
import PropTypes from 'prop-types';
import {
  Modal, Input, Checkbox, Row, Col, Divider 
} from 'antd';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { CoursePermissionsEnum, AsgnPermissionsEnum } from 'scripts/common/enums/course';
import { MixinPermissionHelper } from 'scripts/common/utils/mixin_calculater';

class PermissionInfoModal extends React.PureComponent {
  static propTypes = {
    permissions: PropTypes.shape({}).isRequired,
    assistantId: PropTypes.string.isRequired,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.permissions, prevState.oldPermissions)) {
      return {
        permissions: JSON.parse(JSON.stringify({
          course: {},
          asgn: {},
          repository: {},
          ...nextProps.permissions,
        })),
        oldPermissions: nextProps.permissions,
      };
    }
    return null;
  }

  state = {
    oldPermissions: '',
    permissions: JSON.parse(JSON.stringify({
      course: {},
      asgn: {},
      repository: {},
      ...this.props.permissions,
    })),
  };

  handleCoursePermissionChange = ({ target }) => {
    console.log(this.props.assistantId, get(this.state.permissions, `course.${this.props.assistantId}`))
    const helper = new MixinPermissionHelper(get(this.state.permissions, `course.${this.props.assistantId}`, {}));
    if (target.checked) {
      helper.add(target.value);
    } else {
      helper.remove(target.value);
    }
    const permission = {
      ...this.state.permissions,
    };
    permission.course[this.props.assistantId] = helper.mixin;
    this.setState({
      permissions: permission,
    });
  };

  handleAsgnPermissionChange = ({ target }) => {
    const helper = new MixinPermissionHelper(get(this.state.permissions, `asgn.${this.props.assistantId}`, {}));
    if (target.checked) {
      helper.add(target.value);
    } else {
      helper.remove(target.value);
    }
    const permission = {
      ...this.state.permissions,
    };
    permission.asgn[this.props.assistantId] = helper.mixin;
    this.setState({
      permissions: permission,
    });
  };

  render() {
    const coursePermission = get(this.state.permissions, `course.${this.props.assistantId}`, {});
    const courseHelper = new MixinPermissionHelper(coursePermission);
    const asgnPermission = get(this.state.permissions, `asgn.${this.props.assistantId}`, {});
    const asgnHelper = new MixinPermissionHelper(asgnPermission);
    return (
      <Modal
        {...this.props}
        title="设置助教权限"
      >
        <Divider orientation="left">课程权限</Divider>
        <Row>
          {enumMap(CoursePermissionsEnum, (item, key) => {
            return <Col key={key} xs={12} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <Checkbox
                value={item.value}
                checked={courseHelper.has(item)}
                onChange={this.handleCoursePermissionChange}
              >{item.desc}</Checkbox>
            </Col>;
          }, item => (item.value <= CoursePermissionsEnum.MANAGE.value
            && item.value !== CoursePermissionsEnum.DELETE.value))}
        </Row>
        <Divider orientation="left">作业权限</Divider>
        <Row>
          {enumMap(AsgnPermissionsEnum, (item, key) => {
            return <Col key={key} xs={12} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <Checkbox
                value={item.value}
                checked={asgnHelper.has(item)}
                onChange={this.handleAsgnPermissionChange}
              >{item.desc}</Checkbox>
            </Col>;
          }, item => (item.value < AsgnPermissionsEnum.STU_VISIT.value
            && item.value !== AsgnPermissionsEnum.DELETE.value))}
        </Row>
      </Modal>
    );
  }
}

export default PermissionInfoModal;
