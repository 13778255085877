import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isEqual } from 'lodash';
import {
  CopyOutlined, DeleteOutlined, ScissorOutlined, UploadOutlined 
} from '@ant-design/icons';
import {
  Breadcrumb, Badge, Button, Progress, Modal, message 
} from 'antd';
import { connect } from 'react-redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { bindActionCreators } from 'redux';
import {
  RepositoryFsAction,
  RepositorySelectors,
  RepositoryFileSystemSelectors,
  RepositoryCommonUploaderSelectors
} from 'scripts/common/logic/repository';
import { RepositoryPropType, RepositoryFileSystemPropType } from 'scripts/common/prop-types/repository';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RepositoryRoutes } from 'scripts/apps/routes';
import { showModal } from 'scripts/common/widgets/modal';
import FileView from 'scripts/apps/repository/file_view';
import { InitRepoCommonUploadWorker } from 'scripts/apps/repository/uploader';
import FolderSelectTree from 'scripts/apps/repository/widgets/folder_select_tree';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { RepositoryAction } from 'scripts/common/logic/repository/action';
import TreeView from './tree_view';
import UploadFilesDialog from './widgets/upload';

const LOADING_STATUS_KEY = 'repository_file_system_table';

InitRepoCommonUploadWorker();   // 直接初始化worker，单例的

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const baseUrl = get(match, 'url', '');
  const vpath = get(location, 'pathname', '').replace(baseUrl, '');
  const vdirs = vpath.split('/');
  const dirs = [];
  const taskQueue = RepositoryCommonUploaderSelectors.getUploadQueueTasks(state);
  vdirs.forEach((item) => {
    if (!item) return;
    dirs.push(item);
  });
  const repoPath = dirs.join('/');
  const repository = RepositorySelectors.getRepositoryEntity(state)(props.repoId);

  const navigators = [{
    id: 'root',
    name: '根目录',
  }, ...dirs.map((fsid) => {
    return RepositoryFileSystemSelectors.getRepositoryFileSystemEntity(state)(fsid);
  })];
  const directoryTree =    RepositoryFileSystemSelectors.getRepositoryFileSystemDirectoryTree(state, props.repoId);
  return {
    taskQueue,
    repoPath,
    repoPathArray: vdirs.map(item => item || 'root'),
    repository,
    navigators,
    directoryTree,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getRepositoryFsDirectory: RepositoryFsAction.getRepositoryFsDirectory,
  moveRepositoryFile: RepositoryFsAction.moveRepositoryFile,
  copyRepositoryFile: RepositoryFsAction.copyRepositoryFile,
  delRepositoryFile: RepositoryFsAction.deleteRepositoryFile,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getRepositoryFileList: RepositoryFsAction.getRepositoryFileList,
  getRepositoryInfo: RepositoryAction.getRepositoryInfo,

}, dispatch);

// 这个页面用来管理一些没有课程的
@connect(mapStateToProps, mapDispatchToProps)
class MainView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    repoPath: PropTypes.string,
    repoPathArray: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ])),
    taskQueue: PropTypes.arrayOf(PropTypes.shape({})),
    repository: RepositoryPropType,
    navigators: PropTypes.arrayOf(RepositoryFileSystemPropType),
    repoId: PropTypes.string.isRequired,
    getRepositoryFsDirectory: PropTypes.func,
  };

  static defaultProps = {
    repoPath: '/',
    repoPathArray: ['root'],
    repository: null,
    navigators: [],
    taskQueue: [],
    getRepositoryFsDirectory: noop,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.repoPath, prevState.repoPath)) {
      return {
        selectedFiles: [],
        repoPath: nextProps.repoPath,
      };
    }
    return null;
  }

  state = {
    repoPath: '/',
    selectedFiles: [],
  };

  componentDidMount() {
    const dirs = this.props.repoPath.split('/');
    const initRepoPath = dirs[dirs.length - 1] || 'root';
    const { history } = this.props;
    this.props.getRepositoryFsDirectory({
      init: true,
      repoId: this.props.repoId,
      fsId: initRepoPath,
      onSuccess: () => {

      },
      onError: () => {
        history.push(buildPath(RepositoryRoutes.TREE_VIEW));
      },
    });
  }

  treeView = React.createRef();

  getRepoCurSizePercent = () => {
    const curSize = get(this.props.repository, 'cur_size', 0);
    const maxSize = get(this.props.repository, 'max_size', 0);
    if (maxSize <= 0) return 0;
    return Number(((curSize / maxSize) * 100).toFixed(2));
  };

  handleGuideNewFolder = () => {
    if (this.treeView.current) {
      this.treeView.current.handleNewDirectory('root')();
    }
  };

  handleFileSelectChange = (selKeys) => {
    this.setState({
      selectedFiles: selKeys,
    });
  };

  handlePathChange = (vpath) => {
    const { history, repoId } = this.props;
    const baseURL = buildPath(
      RepositoryRoutes.TREE_VIEW,
      {
        repoId,
      }
    );
    history.push(`${baseURL}/${vpath.replace(/-/g, '/')}`);
  };

  handleOpenUploadDialog = () => {
    showModal(UploadFilesDialog, {
      repoId: this.props.repoId,
      repoPathArray: this.props.repoPathArray,
      currentFsId: this.props.currentFsId,
    });
  };

  handleFileChange = keyName => () => {
    let selectedKey = null;
    const changeSelectedKey = (selected) => {
      selectedKey = selected;
    };
    let currentFsId = 'root';
    if (this.props.repoPathArray.length > 0) {
      currentFsId = this.props.repoPathArray[this.props.repoPathArray.length - 1];
    }
    switch (keyName) {
      case 'move':
        Modal.confirm({
          width: 500,
          title: '请选择目标文件夹',
          content: <div style={{ margin: '24px' }}>
            <FolderSelectTree
              directoryTree={this.props.directoryTree}
              handleSelect={changeSelectedKey}
            />
          </div>,
          onOk: () => {
            this.props.moveRepositoryFile({
              repoId: this.props.repoId,
              fsId: selectedKey,
              ids: this.state.selectedFiles,
              onSuccess: () => {
                message.success('移动成功');
                this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
                this.props.getRepositoryFileList({
                  repoId: this.props.repoId,
                  fsId: currentFsId,
                  onComplete: () => {
                    this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
                  },
                });
              },
            });
          },
        });
        break;
      case 'copy':
        Modal.confirm({
          width: 500,
          title: '请选择目标文件夹',
          content: <div style={{ margin: '24px' }}>
            <FolderSelectTree
              directoryTree={this.props.directoryTree}
              handleSelect={changeSelectedKey}
            />
          </div>,
          onOk: () => {
            this.props.copyRepositoryFile({
              repoId: this.props.repoId,
              fsId: selectedKey,
              ids: this.state.selectedFiles,
              onSuccess: () => {
                message.success('复制成功');
                this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
                this.props.getRepositoryFileList({
                  repoId: this.props.repoId,
                  fsId: currentFsId,
                  onComplete: () => {
                    this.props.getRepositoryInfo({
                      repoId: this.props.repoId,
                      onSuccess: () => {
                        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
                      },
                    });
                  },
                });
              },
            });
          },
        });
        break;
      case 'delete':
        Modal.confirm({
          width: 500,
          title: '删除',
          content: '您确定要删除这些文件吗',
          onOk: () => {
            this.props.delRepositoryFile({
              repoId: this.props.repoId,
              ids: this.state.selectedFiles,
              onSuccess: () => {
                message.success('删除成功');
                this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
                this.props.getRepositoryFileList({
                  repoId: this.props.repoId,
                  fsId: currentFsId,

                  onComplete: () => {
                    this.props.getRepositoryInfo({
                      repoId: this.props.repoId,
                      onSuccess: () => {
                        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
                      },
                    });
                  },
                });
              },
            });
          },
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { navigators } = this.props;
    const hasFileSelected = this.state.selectedFiles.length > 0;
    let currentFsId = 'root';
    if (this.props.repoPathArray.length > 0) {
      currentFsId = this.props.repoPathArray[this.props.repoPathArray.length - 1] || 'root';
    }
    return (
      <div className="repository-view-body">
        <div className="rv-action-bar">
          <div className="rv-navbar">
            <Breadcrumb>
              {navigators.map((item) => {
                if (!item) return null;
                return <Breadcrumb.Item
                  key={get(item, 'id')}
                >
                  {get(item, 'name')}
                </Breadcrumb.Item>;
              })}
            </Breadcrumb>
          </div>
          <div className="rv-infobar">
            {/*<Progress percent={this.getRepoCurSizePercent()} />*/}
          </div>
        </div>
        <div className="rv-content">
          <div className="rv-treeview">
            <TreeView
              repoPath={this.props.repoPath.replace(/\//g, '-')}
              repoId={this.props.repoId}
              onPathChange={this.handlePathChange}
              ref={this.treeView}
            />
          </div>
          <div className="rv-fileview">
            {currentFsId !== 'root' && <div className="rv-fileview-actions">
              <Badge count={this.props.taskQueue.length}>
                <Button type="primary" onClick={this.handleOpenUploadDialog}>
                  <UploadOutlined /> 上传文件
                </Button>
              </Badge>
              <Button.Group style={{ marginLeft: 16 }}>
                <Button
                  disabled={!hasFileSelected}
                  onClick={this.handleFileChange('move')}
                >
                  <ScissorOutlined /> 移动
                </Button>
                <Button
                  disabled={!hasFileSelected}
                  onClick={this.handleFileChange('copy')}
                >
                  <CopyOutlined /> 复制
                </Button>
                <Button
                  type="danger"
                  disabled={!hasFileSelected}
                  onClick={this.handleFileChange('delete')}
                >
                  <DeleteOutlined /> 删除
                </Button>
              </Button.Group>
            </div>}
            <div className="rv-fileview-files">
              <FileView
                repoPathArray={this.props.repoPathArray}
                repoId={this.props.repoId}
                rowSelectedKeys={this.state.selectedFiles}
                handleSelectChange={this.handleFileSelectChange}
                handleGuideNewFolder={this.handleGuideNewFolder}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainView;
