//import 'styles/collection/answer/search.scss';
import React, { PureComponent } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

class demo extends PureComponent {
  static  propTypes ={
    searchName: PropTypes.func,
   
  }

  static defaultProps ={
    searchName: noop,

  }

  constructor(props) {
    super(props);
    this.state = {
      name: '', 
    };
    this.SearchName = this.SearchName.bind(this);
  }

  SearchName(e) {
    this.setState({
      name: e.target.value,
    }, () => this.props.searchName(this.state.name));
  }
 
  render() {
    return (
      <div className="search-out">
        <Input
          className="search-input"
          onChange={this.SearchName}
          placeholder="请输入发布人姓名或关键字"
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          maxLength={100}
        />
      </div>
    );
  }
}
export default demo;
