import 'styles/contest/problem/list.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { get, noop } from 'lodash';
import { connect } from 'react-redux';
import localStorage from 'localStorage';
import { withRouter } from 'react-router-dom';
import { ContestAccountSelectors } from 'scripts/common/logic/contest/account';
import { ContestProblemSelectors, ContestProblemActions } from 'scripts/common/logic/contest/problem';
import { ContestProblemPropTypes, ContestPropTypes, ContestAccountPropTypes } from 'scripts/common/prop-types/contest';
import { bindActionCreators } from 'redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath } from 'scripts/common/utils/location_helper';
import { CollectionRoutes, ContestRoutes } from 'scripts/apps/routes';
import DataKeys from 'scripts/common/constants/data_keys';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import ProblemsListTable from './widgets/probem_list_table';
import { withContestContext } from '../contest_provider';
import ContestLoginRequire from '../contest_auth';

const LOADING_STATUS_KEY = 'Contest_problem_list_view';

const mapStateToProps = (state) => {
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  return {
    problemDatas: ContestProblemSelectors.problemListData(state),
    account: ContestAccountSelectors.getLoginContestAccount(state),
    isLoading,
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  sortContestProblem: ContestProblemActions.manageContestProblem,
  getContestProblemList: ContestProblemActions.getContestProblemList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  doProblemRecalculation: ContestProblemActions.doProblemRecalculation,
  getCompleteList: ContestProblemActions.getCompleteList,
}, dispatch);

@ContestLoginRequire
@withContestContext
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ProblemListContent extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    account: ContestAccountPropTypes.isRequired,
    contest: ContestPropTypes,
    problemDatas: PropTypes.arrayOf(ContestProblemPropTypes),
    doProblemRecalculation: PropTypes.func,
    getContestProblemList: PropTypes.func,
    getCompleteList: PropTypes.func,
  };

  static defaultProps = {
    problemDatas: [],
    contest: {},
    doProblemRecalculation: noop,
    getContestProblemList: noop,
    getCompleteList: noop,
  };

  state = {
    newIdOrder: [],
    isSaving: false,
    completeList: {},
  };

  componentDidMount() {
    this.props.getCompleteList({
      contestId: this.props.contest.id,
      onSuccess: (res) => {
        this.setState({
          completeList: res,
        });
      },
    });
  }

  plistRef = React.createRef();

  handleProblemClick = (problem) => {
    const { history, match } = this.props;
    history.push(buildPath(
      ContestRoutes.PROBLEM.CONTENT,
      {
        contestId: match.params.contestId,
        problemId: get(problem, 'id', 0),
      }
    ));
  };

  handleSortProblem = (idList) => {
    this.setState({
      newIdOrder: idList,
    });
  };

  handleSortProblemClick = () => {
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.setState({
      isSaving: true,
    }, () => {
      this.props.sortContestProblem({
        contestId: this.props.contest.id,
        ids: this.state.newIdOrder,
        onComplete: () => {
          this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
          this.setState({
            newIdOrder: [],
            isSaving: false,
          });
        },
      });
    });
  };

  handleProblemChooseOpen = () => {
    const dpids = [];
    this.props.problemDatas.forEach((item) => {
      const pid = get(item, 'problem.id');
      pid && dpids.push(pid);
    });
    // 选题模式开启逻辑
    localStorage.setItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING, JSON.stringify({
      module: 'contest',
      payloads: {
        contestId: this.props.contest.id,
      },
      selectedProblemIds: [],
      disabledProblemsIds: dpids,
    }));
    this.props.history.push(buildPath(CollectionRoutes.LIST));
  };

  handleProblemOrderReset = () => {
    this.setState({
      newIdOrder: [],
    });
    this.plistRef.current.handleResetProblemOrder();
  };

  handleRefreshList = () => {
    // 刷新列表
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.props.getContestProblemList({
      contestId: this.props.contest.id,
      onComplete: () => {
        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  render() {
    const isAdmin = get(this.props.account, 'id') === get(this.props.contest, 'author_id');
    return (
      <div className="contest_problem_list">
        {isAdmin && <div className="list_control">
          <div className="left-sider">
            <Button onClick={this.handleProblemChooseOpen} type="primary">去题库选题</Button>
          </div>
          <div className="right-sider">
            {this.state.newIdOrder.length > 0 && <Button.Group>
              <Button
                type="primary"
                onClick={this.handleSortProblemClick}
                loading={this.state.isSaving}
              >
                保存排序
              </Button>
              <Button
                type="default"
                onClick={this.handleProblemOrderReset}
              >
                放弃变更
              </Button>
            </Button.Group>}
          </div>
          </div>}
        <div className="pl_list_scroll_view">
          <Spin
            size="large"
            tip="加载中..."
            spinning={this.props.isLoading}
          >
            {!this.props.isLoading ? <ProblemsListTable
              ref={this.plistRef}
              onRefreshList={this.handleRefreshList}
              contestId={get(this.props.contest, 'id')}
              problemDatas={this.props.problemDatas}
              isManage={this.props.account.role === ContestAccountRoleEnum.REFEREE.value}
              onProblemClick={this.handleProblemClick}
              onSortProblem={this.handleSortProblem}
              completeList={this.state.completeList}
            /> : <div style={{ width: '100%', height: 100 }} />}
          </Spin>
        </div>
      </div>
    );
  }
}

export default ProblemListContent;
