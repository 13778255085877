import 'styles/management/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { SmileTwoTone } from '@ant-design/icons';
import { Menu, Result } from 'antd';
import { get } from 'lodash';
import { connect } from 'react-redux';
import {
  withRouter, matchPath, Switch, Route, Redirect 
} from 'react-router-dom';
import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ManagementRoutes } from 'scripts/apps/routes';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import { AccountRoleEnum } from 'scripts/common/enums/account';

import { WeJudge404NotFoundComponent } from '../wejudge/not_found_page';
import AccountManagementView from './account';
import StudentManagementView from './school/student_view';
import SchoolsListView from './school_list';
import MonitorView from './monitor';

const mapStateToProps = (state) => {
  const currentAccount = AccountSelectors.getLoginAccount(state);
  const role = get(currentAccount, 'role');
  const canShow = (role === AccountRoleEnum.ADMINISTRATOR.value)
    || (role === AccountRoleEnum.DEAN.value);
  const isSystemAdmin = (role === AccountRoleEnum.ADMINISTRATOR.value);
  return {
    canShow,
    isSystemAdmin,
  };
};

@withRouter
@connect(mapStateToProps, null)
class ManagementAPP extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    canShow: PropTypes.bool,
    isSystemAdmin: PropTypes.bool,
  };

  static defaultProps = {
    canShow: false,
    isSystemAdmin: false,
  };

  handleClick = ({ key }) => {
    this.props.history.push(buildPath(ManagementRoutes.SUBITEMS, {
      subItemName: key,
    }));
  };

  render() {
    const { canShow, isSystemAdmin } = this.props;
    const matchKey = get(matchPath(this.props.location.pathname, {
      path: ManagementRoutes.SUBITEMS,
    }), 'params.subItemName', 'index');
    return (
      <BaseLayout>
        {canShow && <div className="management-app-view">
          <div className="management-content">
            <Menu mode="horizontal" selectedKeys={[matchKey]} onSelect={this.handleClick}>
              <Menu.Item key="index">首页</Menu.Item>
              <Menu.Item key="account">账户管理</Menu.Item>
              <Menu.Item key="school">学校管理</Menu.Item>
              <Menu.Item key="monitor">系统监控</Menu.Item>
            </Menu>
            <div className="management-penal">
              <Switch>
                <Route
                  path={ManagementRoutes.INDEX}
                  render={() => {
                    return (
                      <Result
                        icon={<SmileTwoTone />}
                        title="欢迎使用WeJudge管理面板！"
                      />
                    );
                  }}
                  exact
                />
                <Route
                  path={ManagementRoutes.ACCOUNT_MANAGEMENT}
                  component={AccountManagementView}
                  exact
                />
                <Route
                  path={ManagementRoutes.SCHOOL_LIST}
                  component={SchoolsListView}
                  exact
                />
                {isSystemAdmin && <Route
                  path={ManagementRoutes.STUDENT_MANAGEMENT}
                  component={StudentManagementView}
                  exact
                />}
                {isSystemAdmin && <Route
                  path={ManagementRoutes.MONITOR.SUBITEMS}
                  component={MonitorView}
                />}
                <Route render={() => <Redirect to={ManagementRoutes.INDEX} />} exact />
                <Route component={WeJudge404NotFoundComponent} />
              </Switch>
            </div>
          </div>
        </div>}

      </BaseLayout>
    );
  }
}

export default ManagementAPP;
