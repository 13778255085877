import React from 'react';
import { FileTextOutlined, FolderOpenOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Tree, Button, Modal, Empty
} from 'antd';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { showModal } from 'scripts/common/widgets/modal';
import LessonModal from 'scripts/apps/education/lesson/widgets/lesson_modal';
import { withRouter } from 'react-router-dom';
import { LessonPropTypes } from 'scripts/common/prop-types/course';
import {
  ContextMenu, MenuItem, ContextMenuTrigger, connectMenu 
} from 'react-contextmenu';

const MENU_TYPE = 'DYNAMIC';
const { confirm } = Modal;

const LessonPopverMenuBase = (props) => {
  const { id, trigger } = props;
  return <ContextMenu id={id}>
    {trigger ? <>
      <MenuItem onClick={() => trigger.onItemClick('add')} data={{ action: 'add' }} disabled={!trigger.allowCreate}>创建</MenuItem>
      <MenuItem onClick={() => trigger.onItemClick('edit')} data={{ action: 'edit' }}>修改</MenuItem>
      <MenuItem divider />
      <MenuItem onClick={() => trigger.onItemClick('delete')} data={{ action: 'delete' }}>删除</MenuItem>
    </> : <></>}
  </ContextMenu>;
};

LessonPopverMenuBase.propTypes = {
  id: PropTypes.string.isRequired,
  trigger: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onItemClick: PropTypes.func.isRequired,
    allowCreate: PropTypes.bool,
  }),
};

LessonPopverMenuBase.defaultProps = {
  trigger: null,
};

const LessonPopverMenu = connectMenu(MENU_TYPE)(LessonPopverMenuBase);

@withRouter
class LessonTree extends React.PureComponent {
  static propTypes = {
    lessonList: PropTypes.arrayOf(LessonPropTypes),
    addLesson: PropTypes.func,
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.string,
    editLesson: PropTypes.func,
    delLesson: PropTypes.func,
    onTreeNodeClick: PropTypes.func,
    onFetchList: PropTypes.func,
  };

  static defaultProps = {
    lessonList: [],
    lessonId: '',
    addLesson: noop,
    editLesson: noop,
    delLesson: noop,
    onTreeNodeClick: noop,
    onFetchList: noop,
  };

  state = {
    selectedKeys: [this.props.lessonId],
  };

  onSelect = (selectedKeys, e) => {
    if (selectedKeys.length <= 0) return;
    if (selectedKeys[0] === 'add') {
      this.handleActionClick('new', { parent: null }, 0, true);
      return;
    }
    const isGroup = get(e, 'node.props.dataRef.is_group');
    if (isGroup) return;
    this.props.onTreeNodeClick(selectedKeys[0]);
    this.setState({ selectedKeys });
  };

  handleActionClick = (keyName, item = {}, deep, root = false) => {
    if (keyName !== 'del') {
      const modalBox = showModal(LessonModal, {
        width: 450,
        mode: keyName,
        lesson: item,
        lessonList: this.props.lessonList,
        deep,
        root,
        onOk: (modal, state) => {
          if (keyName === 'new') {
            const data = {
              description: state.desc,
              title: state.title,
              is_group: state.type === 'group',
              parent: state.parent,
              visible_time: state.visible_time ? state.visible_time : 0,
            };
            this.props.addLesson({
              ...data,
              courseId: this.props.courseId,
              onSuccess: () => {
                this.props.onFetchList();
              },
            });
          } else {
            const data = {
              title: state.title,
              description: state.desc,
              parent: state.parent,
              visible_time: state.visible_time ? state.visible_time : 0,
            };
            this.props.editLesson({
              ...data,
              courseId: this.props.courseId,
              lessonId: item.id,
              onSuccess: () => {
                this.props.onFetchList();
              },
            });
          }
          modalBox.close();
          return false;
        },
      });
    } else {
      confirm({
        title: '删除',
        content: `您确定要删除『${item.title}』吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          this.props.delLesson({
            courseId: this.props.courseId,
            lessonId: item.id,
            onSuccess: () => {
              this.props.onFetchList();
            },
          });
        },
      });
    }
  };

  handlePopverMenuClick = (item, parent, deep) => (action) => {
    switch (action) {
      case 'add':
        this.handleActionClick('new', { ...item, parent }, deep);
        break;
      case 'edit':
        this.handleActionClick('edit', { ...item, parent });
        break;
      case 'delete':
        this.handleActionClick('del', { ...item });
        break;
      default:
        break;
    }
  };

  renderTreeNodeTitle = (item, parent, deep) => {
    return <ContextMenuTrigger
      id={MENU_TYPE}
      name={item.title}
      onItemClick={this.handlePopverMenuClick(item, parent, deep)}
      allowCreate={!!get(item, 'is_group')}
      collect={props => props}
    >
      {item.title}
    </ContextMenuTrigger>;
  };

  renderLessonTreeNodes = (lessonList, parent = null, deep = 0) => {
    return [
      ...lessonList.map((item) => {
        const ch = get(item, 'children', []);
        const hasChildren = ch.length !== 0;
        const isGroup = get(item, 'is_group', false);
        const iconHasChildren = (hasChildren ? null : <FolderOpenOutlined />);
        return {
          key: item.id,
          switcherIcon: isGroup ? iconHasChildren : <FileTextOutlined />,
          title: this.renderTreeNodeTitle(item, parent, deep),
          isLeaf: !hasChildren,
          dataRef: item,
          children: hasChildren ? this.renderLessonTreeNodes(ch, item.id, deep + 1) : [],
        };
      }),
      ...(!parent ? [{
        key: 'add',
        switcherIcon: <PlusOutlined />,
        isLeaf: true,
        title: '新建章节',
      }] : [])
    ];
  };

  render() {
    return (
      <div className="course_lesson_tree">
        {this.props.lessonList.length !== 0 ? <Tree.DirectoryTree
          showIcon={false}
          multiple={false}
          onSelect={this.onSelect}
          defaultExpandAll
          selectedKeys={this.state.selectedKeys}
          treeData={this.renderLessonTreeNodes(this.props.lessonList)}
        /> : <Empty description={<span>尚未创建章节</span>}>
          <Button
            type="primary"
            onClick={() => this.handleActionClick('new', { parent: null }, 0, true)}
          >
            新建章节
          </Button>
        </Empty>}
        <LessonPopverMenu />
      </div>
    );
  }
}

export default LessonTree;
