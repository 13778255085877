import 'styles/problem/widgets.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Tag } from 'antd';
import { get, noop } from 'lodash';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { ChoiceItem } from './choice_problem';

class TofProblem extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes.isRequired,
    // 显示OK按钮
    isShowOk: PropTypes.bool,
    // 工作模式： choose 选择模式, readonly 只读模式， view_answers 答案查看模式，
    mode: PropTypes.string,
    // 正确选项
    correctAnswers: PropTypes.bool,
    // 默认选项(比如记录中，用户曾经选中的)
    problemNumber: PropTypes.string,
    defaultChoices: PropTypes.bool,
    onSelectChanged: PropTypes.func,
    onOk: PropTypes.func,
  };

  static defaultProps = {
    mode: 'solving',
    isShowOk: false,
    correctAnswers: null,
    defaultChoices: null,
    onSelectChanged: noop,
    problemNumber: '',
    onOk: noop,
  };

  state = {
    currentChoices: this.props.defaultChoices,
  };

  handleItemSelect = (value) => {
    this.setState({
      currentChoices: !!value,
    }, () => {
      this.props.onSelectChanged(this.state.currentChoices);
    });
  };

  render() {
    const hasAnswer = this.props.correctAnswers === true || this.props.correctAnswers === false;
    const pt = ProblemTypeEnum.TRUE_OR_FALSE;
    return (
      <div className="widget_choosing_problem">
        <div className="problem_title">
          #{this.props.problemNumber || get(this.props, 'problem.number', '')}. {this.props.problem.title}
          <Tag style={{ float: 'right' }} color={pt.color}>{pt.desc}</Tag>
        </div>
        <RichtextContext className="problem_description" content={this.props.problem.description} />
        <div className="choices_group">
          <ChoiceItem
            value={1}
            key="correct"
            mode={this.props.mode === 'view_answers' ? (hasAnswer ? 'view_answers' : 'readonly') : this.props.mode}
            onSelect={this.handleItemSelect}
            isCorrectAnswer={hasAnswer && this.props.correctAnswers === this.state.currentChoices}
            isSelected={this.state.currentChoices === true}
          >
            <span className="choice_item_content"><CheckOutlined /> 正确</span>
          </ChoiceItem>
          <ChoiceItem
            value={0}
            key="wrong"
            mode={this.props.mode === 'view_answers' ? (hasAnswer ? 'view_answers' : 'readonly') : this.props.mode}
            onSelect={this.handleItemSelect}
            isCorrectAnswer={hasAnswer && this.props.correctAnswers === this.state.currentChoices}
            isSelected={this.state.currentChoices === false}
          >
            <span className="choice_item_content"><CloseOutlined /> 错误</span>
          </ChoiceItem>
        </div>
        {this.props.mode === 'view_answers' ? this.props.problem.explain && <div className="answer_view">
          <div className="explain">
            <div className="answer_view_title">解析：</div>
            <div
              className="answer_view_content"
              dangerouslySetInnerHTML={{ __html: this.props.problem.explain }}
            />
          </div>
        </div> : this.props.isShowOk && this.props.mode === 'solving'
          && <Button
            className="submit-btn"
            type="primary"
            onClick={() => this.props.onOk(this.state.currentChoices)}
          >
            确定
          </Button>}
      </div>
    );
  }
}

export default TofProblem;
