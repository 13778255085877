import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { WeJudgeRoutes, ContestRootRoutes } from '../routes';
import ContestView from './contest_view';
import ContestListView from './contest_list/contest_list_view';
import FancyRankView from './rank/fancy_rank';

const ContestApp = () => (
  <Switch>
    <Route exact path={ContestRootRoutes.FANCY_RANK} component={FancyRankView} />
    <Route exact path={WeJudgeRoutes.CONTEST_LIST} component={ContestListView} />
    <Route path={WeJudgeRoutes.CONTEST_ROOT} component={ContestView} />
  </Switch>
);

export default ContestApp;
