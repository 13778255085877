import React from 'react';
import { Spin, Button } from 'antd';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildPath, getQuery, getQueryFromUrlByKey } from 'scripts/common/utils/location_helper';
import { getWeChatQrconnectUrl } from 'scripts/common/utils/wechat/qrconnect';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeChatActions } from 'scripts/common/logic/wechat';
import { AccountRoutes } from 'scripts/apps/routes';
import Logger from 'scripts/common/utils/exceptions/logger';

const logger = Logger('WeChatQRConnect');

@connect(null, dispatch => bindActionCreators({
  doWechatQrconnect: WeChatActions.doWechatQrconnect,
  getWechatQrconnectStatus: WeChatActions.getWechatQrconnectStatus,
}, dispatch))
class LoginWechatQrconnect extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    changePage: PropTypes.func,
    doWechatQrconnect: PropTypes.func,
    getWechatQrconnectStatus: PropTypes.func,
  };

  static defaultProps = {
    changePage: noop,
    doWechatQrconnect: noop,
    getWechatQrconnectStatus: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
    };
    this.getWXInfoStatus();
  }

  getWXInfoStatus = () => {
    this.props.getWechatQrconnectStatus({
      onSuccess: (QRInfos) => {
        this.setState({
          fetching: false,
          openId: get(QRInfos, 'openid', ''),
          userInfo: QRInfos,
        });
      },
    });
  };

  gotoRegister = () => {
    this.props.history.push(buildPath(
      AccountRoutes.AUTH.REGISTER,
      null,
      null,
      getQuery(this.props.location)
    ));
  };

  handleLoad = () => {
    let pageUrl = '';
    try {
      pageUrl = document.getElementById('wechat_qrconnect_iframe').contentWindow.location.href;
    } catch (e) {
      logger.info('Now is on WeChat Website, wait for redirect');
    }
    if (pageUrl) {
      this.setState({
        fetching: true,
      }, () => {
        this.props.doWechatQrconnect({
          code: getQueryFromUrlByKey(pageUrl, 'code'),
          onSuccess: (action) => {
            if (action === 'bind_req') {
              this.getWXInfoStatus();
            } else {
              const query = getQuery(this.props.location);
              window.location.href = get(query, 'from', '/');
            }
          },
          onError: () => {
            this.setState({
              fetching: false,
            });
          },
        });
      });
    }
  };

  render() {
    if (this.state.openId) {
      return <div className="wechat_user_info_ares">
        <img className="headimg" width={132} height={132} src={get(this.state.userInfo, 'headimgurl')} alt="" />
        <div className="nickname">{get(this.state.userInfo, 'nickname')}</div>
        <Button type="primary" onClick={this.gotoRegister}>注册新账户</Button>
        <Button onClick={() => { this.props.changePage('login'); }}>已有账户，去登录</Button>
      </div>;
    }
    return this.state.fetching
      ? <Spin tip="正在与微信服务器通讯，请稍后..." size="large" />
      : <iframe
        title="WeChat"
        id="wechat_qrconnect_iframe"
        frameBorder={0}
        allowTransparency="true"     // eslint-disable-line
        scrolling="no"
        height={450}
        onLoad={this.handleLoad}
        src={getWeChatQrconnectUrl()}
      />;
  }
}

export default LoginWechatQrconnect;
