import 'styles/problem/widgets/problem_edit.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Modal, Alert } from 'antd';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import CodeProblemEditView  from 'scripts/apps/problem/manage/code_problem_edit';
import ChoiceProblemEditView from 'scripts/apps/problem/manage/choice_problem_edit';
import TofProblemEditView from 'scripts/apps/problem/manage/tof_problem_edit';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';

class ProblemInfoModal extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes,
    collectionId: PropTypes.string.isRequired,
    problemType: PropTypes.objectOf(ProblemTypeEnum).isRequired,
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: 'new',
    problem: {},
  };

  state = {
    problem: JSON.parse(JSON.stringify(this.props.problem)),
  };

  render() {
    const { problemType } = this.props;
    const { problem } = this.state;
    const problemParams = isEmpty(problem) ? {} : { problem };
    const title = this.props.mode === 'new' ? '新建题目' : '修改题目';
    return (<Modal
      wrapClassName="add-problem-dialog"
      {...this.props}
      title={title}
    >
      {(problemType === ProblemTypeEnum.CODE || problemType === ProblemTypeEnum.CODE_FILL)
      && <>
        <CodeProblemEditView
          mode="new"
          onChange={(p) => { this.setState({ problem: p }); }}
        />
        <Alert style={{ marginTop: '8px' }} message="小提示：代码题的需要先创建，再进行详细的设置。" type="info" />
      </>}
      {problemType === ProblemTypeEnum.SINGLE
        && <ChoiceProblemEditView
          mode={this.props.mode}
          onChange={(p) => { this.setState({ problem: p }); }}
          type={ProblemTypeEnum.SINGLE}
          {...problemParams}
        />}
      {problemType === ProblemTypeEnum.MULTIPLE
        && <ChoiceProblemEditView
          mode={this.props.mode}
          onChange={(p) => { this.setState({ problem: p }); }}
          type={ProblemTypeEnum.MULTIPLE}
          {...problemParams}
        />}
      {problemType === ProblemTypeEnum.TRUE_OR_FALSE
        && <TofProblemEditView
          mode={this.props.mode}
          onChange={(p) => { this.setState({ problem: p }); }}
          {...problemParams}
        />}
    </Modal>);
  }
}

export default ProblemInfoModal;
