import 'styles/collection/categlry_coll_modal.scss';

import React from 'react';
import { Modal, Input } from 'antd';
import PropTypes from 'prop-types';
import CategoryCollSelectTree from 'scripts/apps/widgets/category/select_tree.jsx';
import { CategoryPropTypes } from 'scripts/common/prop-types/category';

const MAX_FOLDER_DEEP = 1;

class CategoryCollModal extends React.PureComponent {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    categoryTree: PropTypes.arrayOf(CategoryPropTypes).isRequired,
    category: CategoryPropTypes,
    deep: PropTypes.number,
    root: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    category: {},
    deep: 0,
  };

  state = {
    type: this.props.mode === 'edit' ? (this.props.category.children ? 'category' : 'subject') : ((this.props.deep >= MAX_FOLDER_DEEP) ? 'category' : 'subject'),
    title: this.props.mode === 'edit' ? this.props.category.title : '',
    parent: this.props.mode === 'edit' ? this.props.category.parent : this.props.category.id,
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      [keyName]: target.value,
    });
  };

  handleParentChange = ({ parent }) => {
    this.setState({
      parent,
    });
  };

  render() {
    return (
      <Modal
        width={520}
        {...this.props}
        title="分类编辑"
        className="category_modal"
      >
        <div className="category_modal_item">
          {this.props.mode === 'new' 
            ? <div className="item_title"><span> 名   称 ：</span></div>
            : <div className="item_title"><span>{this.state.type === 'category' ? '分类名称：' : '学科名称：'}</span></div>}
          <div className="item_content">
            <Input
              style={{ width: '280px' }}
              value={this.state.title}
              onChange={this.handleInputChange('title')}
              placeholder="请输入名称"
            />
          </div>
        </div>
        {(this.props.mode === 'edit' && this.state.parent !== null) && <div className="category_modal_item">
          <div className="item_title">父级节点：</div>
          <div className="item_content">
            <CategoryCollSelectTree
              categoryTree={this.props.categoryTree}
              onParentChange={this.handleParentChange}
              defaultKey={this.state.parent}
            />
          </div>
        </div>}
      </Modal>
    );
  }
}

export default CategoryCollModal;
