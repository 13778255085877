import 'styles/wejudge/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Carousel } from 'antd';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { AccountSelectors } from 'scripts/common/logic/account';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AuthPropTypes } from 'scripts/common/prop-types/account';
import { buildPath, getQuery } from 'scripts/common/utils/location_helper';
import {
  AccountRoutes,
  CollectionRoutes, ContestRootRoutes,
  CourseRoutes,
  HelperRoutes,
  WeJudgeRoutes
} from 'scripts/apps/routes';
import WeJudgeLogoHorizontal from 'svgs/logo_horizontal.svg';
import { nowTime } from 'scripts/common/utils/time_formatter';

// import TopBar from './layout/topbar';

const mapStateToProps = (state, props) => {
  const authInfo = AccountSelectors.getLoginStatus(state);
  const account = AccountSelectors.getLoginAccount(state);
  const query = getQuery(get(props, 'location'));
  return {
    auth: {
      ...authInfo,
      account,
    },
    force: !!get(query, 'force', false),
  };
};

@connect(mapStateToProps)
class WeJudgeIndex extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    auth: AuthPropTypes.isRequired,
    force: PropTypes.bool,
  };

  static defaultProps = {
    force: false,
  };

  handleNavItemClick = path => (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { history } = this.props;
    history.push(path);
    return false;
  };

  renderYACPC = () => {
    return <div className="wejudge-index-body-wrap" style={{ marginTop: 64 }}>
      <img
        alt=""
        src="https://w3.static.wejudge.net/2020_cpc/yacpc-2020.jpg"
        style={{ width: '100%', cursor: 'pointer' }}
        onClick={this.handleNavItemClick(buildPath(ContestRootRoutes.HOME, { contestId: 22 }))}
      />
    </div>;
  };

  renderIndex = (gotoUse) => {
    return <div className="wejudge-index-body-wrap">
      <Carousel className="wejudge-carousel" autoplay>
        <div className="wejudge-carousel-container">
          <img src="https://w3.static.wejudge.net/carousel/1.jpg" alt="bg1" width="100%" />
          <div className="inner">
            <h1 className="header-title">WeJudge - 基于实时评测的实践教学辅助平台</h1>
            <div className="guide-box">
              <div className="guide-box-group">
                <div
                  className="guide-button guide-button-primary"
                  onClick={this.handleNavItemClick(gotoUse)}
                >
                  开始使用
                </div>
                <div
                  className="guide-button guide-button-default"
                  onClick={this.handleNavItemClick(buildPath(HelperRoutes.DESC))}
                >
                  了解网站
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="wejudge-carousel-container">
            <img src="https://w3.static.wejudge.net/carousel/2.jpg" alt="bg2" width="100%" />
            <div className="inner">
              <h1 className="header-title">实时在线评测与MOOC教学的融合创新</h1>
              <h3 className="header-title">创新教学模式，旨在构建一个MOOC教学、实践练习、学科竞赛的多维度辅助教学平台</h3>
            </div>
          </div>
        </div>
      </Carousel>
      {/*<article className="wejudge-index-features"></article>*/}
    </div>;
  };

  render() {
    const { auth } = this.props;
    let gotoUse = buildPath(AccountRoutes.AUTH.LOGIN);
    if (get(auth, 'logined') && !this.props.force) {
      gotoUse = buildPath(AccountRoutes.SPACE.INDEX, { accountId: 'my' });
      if (get(auth, 'system_pattern', 1) !== 2) {
        return <Redirect to={gotoUse} />;
      }
    }

    const beianForWeJdugeDotNet = window.location.host.indexOf('wejudge.net') > -1;

    const isYACPC = nowTime().toDate() < new Date(2020, 3, 26, 0, 0, 0);

    return (
      <div
        className="wejudge-index"
        style={{ backgroundColor: isYACPC ? '#14264a' : '' }}
      >
        <div className="wejudge-index-topbar">
          <div className="logo">
            <svg width={160} height={32}><use xlinkHref={`#${WeJudgeLogoHorizontal.id}`} /></svg>
          </div>
          <div className="navbox">
            <a
              href={buildPath(CollectionRoutes.LIST)}
              onClick={this.handleNavItemClick(buildPath(CollectionRoutes.LIST))}
            >
              题库
            </a>
            <a
              href={buildPath(CourseRoutes.LIST)}
              onClick={this.handleNavItemClick(buildPath(CourseRoutes.LIST))}
            >
              教学
            </a>
            <a
              href={buildPath(WeJudgeRoutes.CONTEST_LIST)}
              onClick={this.handleNavItemClick(buildPath(WeJudgeRoutes.CONTEST_LIST))}
            >
              比赛
            </a>
            <a
              href={buildPath(HelperRoutes.DESC)}
              onClick={this.handleNavItemClick(buildPath(HelperRoutes.DESC))}
            >
              关于
            </a>
            {!get(auth, 'logined') && <a
              href={buildPath(AccountRoutes.AUTH.LOGIN)}
              onClick={this.handleNavItemClick(buildPath(AccountRoutes.AUTH.LOGIN))}
              className="bordered"
            >
              登录
            </a>}
            {!get(auth, 'logined') && <a
              href={buildPath(AccountRoutes.AUTH.REGISTER)}
              onClick={this.handleNavItemClick(buildPath(AccountRoutes.AUTH.REGISTER))}
              className="hovered"
            >
              注册
            </a>}
          </div>
        </div>
        {isYACPC ? this.renderYACPC() : this.renderIndex(gotoUse)}
        <footer className="wejudge-index-footer">
          <div className="inner">
            ©&nbsp;2015-2020&nbsp;
            &nbsp;<a href="https://github.com/WeJudge" target="_blank" rel="noopener noreferrer">Github</a>&nbsp;
            {beianForWeJdugeDotNet ? <>|&nbsp;<a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">粤ICP备15015714号-2</a>&nbsp;</>
              : <>|&nbsp;<a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">粤ICP备05026012号-3</a>&nbsp;</>}
            |&nbsp;<a href="https://github.com/LanceLRQ/deer-executor" target="_blank" rel="noopener noreferrer">判题机实现</a>&nbsp;
            {/*|&nbsp;<a href="https://github.com/LanceLRQ/wejudge" target="_blank" rel="noopener noreferrer">WeJudge 1.0 开源</a>&nbsp;*/}
          </div>
        </footer>
      </div>
    );
  }
}

export default WeJudgeIndex;
