import 'styles/contest/contest_view.scss';
import React from 'react';
import { get, isEmpty, noop } from 'lodash';
import { Spin, Layout } from 'antd';
import PropTypes from 'prop-types';
import {
  Switch, Route, withRouter, matchPath, Redirect
} from 'react-router-dom';
import ContestBaseLayout from 'scripts/apps/wejudge/layout/contest_base';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import {
  ContestPropTypes,
  ContestAccountPropTypes,
  ContestAuthPropTypes
} from 'scripts/common/prop-types/contest';
import { showModal } from 'scripts/common/widgets/modal';
import localStorage from 'localStorage';
import { ContestAccountActions, ContestAccountSelectors } from 'scripts/common/logic/contest/account';
import { ContestSelectors, ContestActions } from 'scripts/common/logic/contest/contest';
import { ContestProblemActions } from 'scripts/common/logic/contest/problem';
import { ContestRootRoutes, WeJudgeRoutes } from 'scripts/apps/routes';
import ManageApp from 'scripts/apps/contest/manage';
import { ContestSupportActions, ContestSupportSelectors } from 'scripts/common/logic/contest/support';
import ContestNoticeModal from 'scripts/apps/contest/support/widgets/notice_modal';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import { nowTime } from 'scripts/common/utils/time_formatter';
import { store as APPStore } from 'scripts/common/logic/store';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { WeJudgeWebSocketClient } from 'scripts/common/utils/browser/websocket';
import API from 'scripts/common/constants/apis';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ContestWebSocketMessageRouter } from 'scripts/apps/contest/websocket';
import CenterSpin from 'scripts/common/widgets/center-spin';

import OrganizeApp from './organization/index';
import RankApp from './rank/index';
import SupportApp from './support';
import ProblemApp from './problem';
import JudgeStatusApp from './judge_status';
import AccountAPP from './account';
import { ContestContext, withContestContext } from './contest_provider';
import { WeJudge404NotFoundComponent } from '../wejudge/not_found_page';
import ContestNavigator from './widgets/navigation';
import ContestAccountHeader from './widgets/account_header';
import ContestPageHeader from './widgets/page_header';
import ContestHomeView from './contest_home';

const { Content } = Layout;
let WebSocketRetryCounter = 0;
let WebSocketRetryWorker = null;

const mapStateToProps = (state, props) => {
  const { location } = props;
  const cid = get(props, 'match.params.contestId', 0);
  const matchHomePage = matchPath(location.pathname, { path: ContestRootRoutes.HOME });
  const isHomePage = !!matchHomePage;
  const noticesData = ContestSupportSelectors.getContestNoticesListData(state);
  return {
    contestId: cid,
    account: ContestAccountSelectors.getLoginContestAccount(state),
    contest: ContestSelectors.getContestEntity(state)(cid),
    loginStatus: ContestAccountSelectors.getContestLoginStatus(state),
    noticesData,
    isHomePage,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  checkAccountLogin: ContestAccountActions.checkContestLogin,
  getContestEntity: ContestActions.getContestEntity,
  getContestProblemList: ContestProblemActions.getContestProblemList,
  getNoticeList: ContestSupportActions.getNoticeList,
  showGlobalAlert: WeJudgeActions.showGlobalAlert,
}, dispatch);

@withContestContext
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ContestView extends React.PureComponent {
  static propTypes = {
    contestId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    account: ContestAccountPropTypes,
    contest: ContestPropTypes,
    loginStatus: ContestAuthPropTypes,
    checkAccountLogin: PropTypes.func.isRequired,
    getContestEntity: PropTypes.func.isRequired,
    getContestProblemList: PropTypes.func.isRequired,
    getNoticeList: PropTypes.func.isRequired,
    noticesData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    ...RoutePropTypes,
    showGlobalAlert: PropTypes.func,
    isHomePage: PropTypes.bool,
  };

  static defaultProps = {
    account: null,
    contest: null,
    loginStatus: null,
    showGlobalAlert: noop,
    isHomePage: false,
  };

  state = {
    isLoadedContest: false,
    isInited: false,
  };

  componentDidMount() {
    this.initContest();
    // 注册通知程序
    window.wejudge.notification.initial().then(() => {
      console.info('浏览器通知功能已启用');
    }).catch((e) => {
      const payload = get(e, 'payload', null);
      if (!payload) return;
      this.props.showGlobalAlert('wejudge', {
        ...payload,
      });
    });
  }

  componentDidUpdate() {
    if (!this.websocket && get(this.props.loginStatus, 'logined', false)) {
      this.initWebSocket();
    }
  }

  componentWillUnmount() {
    if (this.websocket) {
      this.websocket.close();
    }
  }

  websocket = null;

  initContest = () => {
    const cid = get(this.props, 'match.params.contestId', 0);
    this.props.getContestEntity({
      id: cid,
      onSuccess: () => {
        // 检查登录
        this.props.checkAccountLogin({
          contestId: cid,
          callback: (flag) => {
            // 获取题目列表cd
            if (flag) {
              const store = APPStore.getState();
              const contest =  ContestSelectors.getContestEntity(store)(cid);
              const timeNow = nowTime().unix();
              const contestStartTime =  get(contest, 'start_time', Infinity);
              const isContestStarted = timeNow >= contestStartTime;
              if (isContestStarted || this.props.account.role === 2) {
                this.props.getContestProblemList({
                  contestId: cid,
                  onComplete: () => {
                    this.setState({ isInited: true });
                  },
                });
              } else {
                this.setState({ isInited: true });
              }
              this.getNotice((lastUpdateTime) => {
                let noticeList = JSON.parse(localStorage.getItem('contest_notice_list'));
                //公告弹出判断
                const show = (noticeList === null) || (Math.floor(lastUpdateTime)
                  > Math.floor(get(noticeList, this.props.contest.id, -1)));
                if ((get(this.props.account, 'role', 0) !== ContestAccountRoleEnum.REFEREE.value)
                  && (show || noticeList.account_id !== get(this.props.account, 'id', 0))) {
                  noticeList = {
                    ...noticeList,
                    account_id: get(this.props.account, 'id', 0),
                    [this.props.contest.id]: lastUpdateTime,
                  };
                  window.localStorage.setItem('contest_notice_list', JSON.stringify(noticeList));
                  this.showNoticeModel(false);
                }
              });
            } else {
              this.setState({ isInited: true });
            }
          },
        });
      },
      onComplete: () => {
        this.setState({ isLoadedContest: true });
      },
    });
  };

  initContestOnlyRefresh = (refreshProblems = false) => {
    const cid = get(this.props, 'match.params.contestId', 0);
    this.props.getContestEntity({
      id: cid,
      onSuccess: () => {
        // 检查登录
        this.props.checkAccountLogin({
          contestId: cid,
        });
        // 刷新题库
        if (refreshProblems) {
          this.props.getContestProblemList({
            contestId: cid,
          });
        }
      },
    });
  };

  getNotice = (callback) => {
    const cid = get(this.props, 'match.params.contestId', 0);
    this.props.getNoticeList({
      contestId: cid,
      onSuccess: callback,
    });
  };

  initWebSocket = () => {
    if (!get(this.props.loginStatus, 'logined', false)) return;
    if (this.websocket) return;
    try {
      this.websocket = new WeJudgeWebSocketClient('比赛服消息通知');
      this.websocket.onMessage = (msg) => {
        ContestWebSocketMessageRouter(this, msg);
      };
      this.websocket.onClose = () => {
        if (WebSocketRetryCounter < 3) {
          if (WebSocketRetryWorker) clearTimeout(WebSocketRetryWorker);
          console.log(`WebSocket连接失败或断开，${WebSocketRetryCounter + 1}s后重试...`);
          this.websocket = null;
          WebSocketRetryWorker = setTimeout(() => {
            this.initWebSocket();
          }, (WebSocketRetryCounter + 1) * 1000);
          WebSocketRetryCounter++;
        }
      };
      this.websocket.connect(buildPath(API.WEBSOCKET.CONTEST_SERVICES, {
        cid: this.props.contestId,
      }));
    } catch (e) { console.error(e); }
  };

  showNoticeModel = (needFetch = true) => {
    const func = () => {
      showModal(ContestNoticeModal, {
        noticesData: this.props.noticesData,
      });
    };
    if (needFetch) this.getNotice(func);
    else func();
  };

  fetchProblemList = () => {
    const cid = get(this.props, 'match.params.contestId', 0);
    const store = APPStore.getState();
    const contest =  ContestSelectors.getContestEntity(store)(cid);
    const timeNow = nowTime().unix();
    const contestStartTime =  get(contest, 'start_time', Infinity);
    const isContestStarted = timeNow >= contestStartTime;
    if (isContestStarted || get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value) {
      this.props.getContestProblemList({
        contestId: cid,
        onComplete: () => {
          this.setState({ isInited: true });
        },
      });
    } else {
      this.setState({ isInited: true });
    }
  };

  render() {
    const { contest, account } = this.props;
    if (this.state.isLoadedContest && isEmpty(contest)) {
      return <div style={{ margin: '32px 0' }}>
        <WeJudge404NotFoundComponent noBorder />
      </div>;
    }

    return this.state.isInited && contest ? (
      <ContestContext.Provider value={{ contest, account, loginStatus: this.props.loginStatus }}>
        <ContestBaseLayout
          account={<ContestAccountHeader
            showNoticeModel={this.showNoticeModel}
          />}
          topbar={<ContestNavigator
            account={this.props.account}
            fetchProblemList={this.fetchProblemList}
          />}
          header={<ContestPageHeader
            miniMode={!this.props.isHomePage}
            onRefresh={this.initContestOnlyRefresh}
          />}
        >
          <Switch>
            <Route
              path={WeJudgeRoutes.CONTEST_ROOT}
              exact
              strict
              render={(props) => <Redirect
                to={buildPath(ContestRootRoutes.HOME, null, props.match.params)}
              />}
            />,
            <Route path={ContestRootRoutes.ACCOUNT_ROOT} component={AccountAPP} />
            <Route path={ContestRootRoutes.PROBLEM_ROOT} component={ProblemApp} />
            <Route path={ContestRootRoutes.SUPPORT_ROOT} component={SupportApp} />
            <Route path={ContestRootRoutes.JUDGE_STATUS_ROOT} component={JudgeStatusApp} />
            <Route path={ContestRootRoutes.RANK_ROOT} component={RankApp} />
            <Route path={ContestRootRoutes.MANAGE_ROOT} component={ManageApp} />
            <Route path={ContestRootRoutes.ORGANIZATION_ROOT} component={OrganizeApp} />
            <Route path={ContestRootRoutes.HOME} strict component={ContestHomeView} />
            <Route
              render={() => <Content className="contest-layout-content">
                <WeJudge404NotFoundComponent noBorder />
              </Content>}
            />
          </Switch>
        </ContestBaseLayout>
      </ContestContext.Provider>
    ) : <CenterSpin tip="正在载入比赛数据，请稍后..." />;
  }
}

export default ContestView;
