import React from 'react';
import PropTypes from 'prop-types';
import 'styles/contest/contest_list.scss';

import { Button, PageHeader, Input } from 'antd';
import { pick, isEqual, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { bindActionCreators } from 'redux';
import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { connect } from 'react-redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { PaginationParams } from 'scripts/common/constants/global';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { ContestActions } from 'scripts/common/logic/contest/contest/action';
import { ContestSelectors } from 'scripts/common/logic/contest/contest/selector';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { ContestRootRoutes, WeJudgeRoutes } from 'scripts/apps/routes';
import { ContestPermissionEnum } from 'scripts/common/enums/contest';
import { AccountSelectors } from 'scripts/common/logic/account';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import CreateContestView from './contest_editor';
import ContestListTable from './contest_list_table';

const LOADING_STATUS_KEY = 'contest_list_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  getContestList: ContestActions.getContestList,
  manageContestList: ContestActions.manageContestList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  delContest: ContestActions.delContest,
}, dispatch);
const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const pager = ContestSelectors.contestsListViewPagerSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page', 'key']),
  };
  const canCreateContest = get(
    AccountSelectors.getAccountBasePermission(state),
    `contest.${ContestPermissionEnum.CREATE.value}`,
    false
  );
  const account = AccountSelectors.getLoginAccount(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  return {
    isLoading,
    queryParams,
    canCreateContest,
    account,
    paginationParams: pager || props.paginationParams,
    contestsList: ContestSelectors.contestsListViewSelector(state),
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ContestListView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const query = pick(
      nextProps.queryParams,
      ['limit', 'page', 'key']
    );
    nextProps.getContestList({
      query,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    canCreateContest: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getContestList: PropTypes.func.isRequired,
    manageContestList: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({}).isRequired,
    paginationParams: PaginationPropTypes,
    contestsList: PropTypes.arrayOf(ContestPropTypes),
  };

  static defaultProps = {
    paginationParams: { ...PaginationParams },
    contestsList: [],
  };

  state = {
    keyword: '',
    createVisible: false,
    queryParams: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), prevState.queryParams)) {
      const { queryParams } = nextProps;
      ContestListView.fetchList(nextProps);
      return {
        keyword: get(nextProps, 'queryParams.key', ''),
        queryParams,
      };
    }
    return null;
  }

  showDrawer = () => {
    this.setState({
      createVisible: true,
    });
  };

  handleContestClick = id => () => {
    const { history } = this.props;
    history.push(buildPath(
      ContestRootRoutes.HOME,
      { contestId: id }
    ), true);
  };

  handleCloseDrawer = () => {
    this.setState({
      createVisible: false,
    });
  };

  handlePinnedChange = (cid, pinned) => {
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.props.manageContestList({
      cids: [cid],
      pinned,
      onSuccess: () => {
        ContestListView.fetchList(this.props);
      },
      onComplete: () => {
        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  handleVisibleChange = (cid, isPrivate) => {
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.props.manageContestList({
      cids: [cid],
      is_private: isPrivate,
      onSuccess: () => {
        ContestListView.fetchList(this.props);
      },
      onComplete: () => {
        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      WeJudgeRoutes.CONTEST_LIST,
      {},
      match.params,
      {
        // ...pick(this.props.queryParams, ['']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleContestSearch = (keyword) => {
    const { history, match } = this.props;
    history.push(buildPath(
      WeJudgeRoutes.CONTEST_LIST,
      {},
      match.params,
      {
        ...pick(this.state.queryParams, ['limit']),
        key: keyword,
      }
    ));
  };

  renderHeaderExtraList = () => {
    const rel = [
      <Input.Search
        key="search"
        placeholder="搜索比赛"
        allowClear
        value={this.state.keyword}
        onSearch={this.handleContestSearch}
        onChange={(e) => this.setState({ keyword: e.target.value })}
        style={{ width: 168 }}
      />
    ];
    if (this.props.canCreateContest) {
      rel.push(<Button key="create" type="primary" onClick={this.showDrawer}>创建比赛</Button>);
    }
    return rel;
  };

  render() {
    return (
      <BaseLayout className="wejudge-contest-list">
        <PageHeader
          className="wejudge-contest-list-header"
          title="公开比赛"
          onBack={false}
          extra={this.renderHeaderExtraList()}
        />
        <div className="wejudge-contest-list-wrap">
          <ContestListTable
            isAdmin={parseInt(get(this.props, 'account.role'), 10) === AccountRoleEnum.ADMINISTRATOR.value}
            isLoading={this.props.isLoading}
            onContestClick={this.handleContestClick}
            contestList={this.props.contestsList}
            paginationParams={this.props.paginationParams}
            onPinnedChange={this.handlePinnedChange}
            onVisibleChange={this.handleVisibleChange}
            queryParams={this.state.queryParams}
            onPageChange={this.handlePageChange}
          />
          <CreateContestView visible={this.state.createVisible} onClose={this.handleCloseDrawer} />
        </div>
      </BaseLayout>
    );
  }
}

export default ContestListView;
