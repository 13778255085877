import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { ArrangementPropTypes } from 'scripts/common/prop-types/course';

class DelArrangementModal extends React.PureComponent {
  static propTypes = {
    arrangement: ArrangementPropTypes,
  }

  static defaultProps = {
    arrangement: PropTypes.object.isRequired,
  };

  state = {
    arrangement: this.props.arrangement ? this.props.arrangement : {},
  };

  render() {
    return (
      <Modal
        {...this.props}
        title="删除排课"
      >
        即将删除 {this.state.arrangement.name} 您确认要删除该排课吗？
      </Modal>
    );
  }
}

export default DelArrangementModal;
