import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Empty, Button, Tooltip, message, Popconfirm 
} from 'antd';
import {
  get, noop, isEqual, union 
} from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RepositoryFsAction, RepositoryFileSystemSelectors } from 'scripts/common/logic/repository';
import { RepositoryFileSystemPropType } from 'scripts/common/prop-types/repository';
import { formatTime, formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import { humanbytes } from 'scripts/common/utils/unit';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { showModal } from 'scripts/common/widgets/modal';
import { openLink } from 'scripts/common/utils/location_helper';
import { FileNameDialog } from './widgets/common';

const LOADING_STATUS_KEY = 'repository_file_system_table';

const mapStateToProps = (state, props) => {
  let filesList = [];
  let currentFsId = 'root';
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  if (props.repoPathArray.length > 0) {
    currentFsId = props.repoPathArray[props.repoPathArray.length - 1] || 'root';
    if (currentFsId !== 'root') {
      filesList = RepositoryFileSystemSelectors.getRepositoryFilesList(state, currentFsId);
    }
  }
  return {
    currentFsId,
    filesList,
    isLoading,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getRepositoryFileList: RepositoryFsAction.getRepositoryFileList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  renameRepositoryFsEntity: RepositoryFsAction.renameRepositoryFsEntity,
  downloadRepositoryFile: RepositoryFsAction.downloadRepositoryFile,
  deleteRepositoryFileOrDir: RepositoryFsAction.deleteRepositoryFileOrDir,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
class FileView extends React.PureComponent {
  static propTypes = {
    repoId: PropTypes.string.isRequired,
    repoPathArray: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ])).isRequired,
    currentFsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rowSelectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    getRepositoryFileList: PropTypes.func,
    handleGuideNewFolder: PropTypes.func,
    handleSelectChange: PropTypes.func,
    setLoadingStatus: PropTypes.func,
    renameRepositoryFsEntity: PropTypes.func,
    downloadRepositoryFile: PropTypes.func,
    deleteRepositoryFileOrDir: PropTypes.func,
    isLoading: PropTypes.bool,
    noFileAction: PropTypes.bool,
    noNewFolderGuide: PropTypes.bool,
    filesList: PropTypes.arrayOf(RepositoryFileSystemPropType),
    // 只选择视频
    onlyVideo: PropTypes.bool,
  };

  static defaultProps = {
    filesList: [],
    currentFsId: 'root',
    rowSelectedKeys: [],
    isLoading: true,
    noFileAction: false,
    noNewFolderGuide: false,
    setLoadingStatus: noop,
    handleSelectChange: noop,
    handleGuideNewFolder: noop,
    getRepositoryFileList: noop,
    downloadRepositoryFile: noop,
    renameRepositoryFsEntity: noop,
    deleteRepositoryFileOrDir: noop,
    onlyVideo: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.repoPathArray.length < 1) return null;
    const fsId = nextProps.repoPathArray[nextProps.repoPathArray.length - 1];
    if (!isEqual(fsId, prevState.lastFsId)) {
      if (!fsId || fsId === 'root') return null;
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getRepositoryFileList({
        repoId: nextProps.repoId,
        fsId,
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        lastFsId: fsId,
      };
    }
    return null;
  }

  state = {
    lastFsId: '',
  };

  DEFAULT_COLUMNS = [
    {
      title: '文件名',
      dataIndex: 'file_name',
    },
    {
      title: '文件大小',
      width: 100,
      dataIndex: 'file_size',
      align: 'center',
    },
    {
      title: '上传时间',
      width: 120,
      dataIndex: 'upload_time',
      align: 'center',
    },
    ...(this.props.noFileAction ? [] : [
      {
        title: '下载次数',
        width: 120,
        dataIndex: 'download_count',
        align: 'center',
      },
      {
        title: '操作',
        width: 200,
        dataIndex: 'operation',
      }
    ])
  ];

  handleRowSelectionSelect = (record, isChecked) => {
    let ret;
    // 视频模式
    if (this.props.onlyVideo) {
      ret = [record.key];
    } else if (isChecked) {
      ret = [...this.props.rowSelectedKeys, record.key];
    } else {
      ret = this.props.rowSelectedKeys.filter(key => !isEqual(key, record.key));
    }
    this.props.handleSelectChange(ret);
  };

  handleRowSelectionBatch = (isChecked, selectRows) => {
    let ret;
    const selectKeys = selectRows.map(item => item.key);
    if (isChecked) {
      ret = union(this.props.rowSelectedKeys, selectKeys);
    } else {
      const removeList = [];
      this.props.filesList.forEach((item) => {
        const key =  get(item, 'id', '');
        // 如果有文件不在
        if (selectKeys.indexOf(key) < 0) {
          removeList.push(key);
        }
      });
      ret = this.props.rowSelectedKeys.filter(key => removeList.indexOf(key) === -1);
    }
    this.props.handleSelectChange(ret);
  };

  handleDownloadFile = fs => () => {
    this.props.downloadRepositoryFile({
      repoId: this.props.repoId,
      fsId: get(fs, 'id', ''),
      onSuccess: (tokens) => {
        const pickcode = get(tokens, 'pick_code', '');
        const fileHash = get(tokens, 'file_hash', '');
        if (!pickcode || !fileHash) {
          message.error('获取下载信息失败');
        } else {
          console.log(`${process.env.RESOURCE_SERVICE}/download/${fileHash}?pickcode=${pickcode}`);
          openLink(`${process.env.RESOURCE_SERVICE}/download/${fileHash}?pickcode=${pickcode}`, true);
        }
      },
    });
  };

  handleDeleteFile = fs => () => {
    this.props.deleteRepositoryFileOrDir({
      repoId: this.props.repoId,
      fsId: get(fs, 'id', ''),
      isDir: false,
      parentId: this.props.currentFsId,
    });
  };

  handleRenameFile = fs => () => {
    const filename = get(fs, 'name', '');
    showModal(FileNameDialog, {
      filename,
      onOk: (ref, state) => {
        this.props.renameRepositoryFsEntity({
          repoId: this.props.repoId,
          fsId: get(fs, 'id', ''),
          fileName: state.filename + state.fileext,
        });
      },
    });
  };

  renderDataSource = () => {
    const { onlyVideo } = this.props;
    return this.props.filesList.map((item) => {
      const cTime = get(item, 'create_time', 0);
      // 只显示视频文件
      if (onlyVideo) {
        const { mime } = item;
        const isVideo = (mime.split('/')[0] === 'video');
        if (isVideo) {
          return {
            key: get(item, 'id', ''),
            file_name: get(item, 'name', ''),
            file_size: humanbytes(get(item, 'size', 0)),
            upload_time: <Tooltip title={formatTime(cTime, 'LONG_DATETIME')}>{formatTimeFromNow(cTime)}</Tooltip>,
            download_count: get(item, 'download_count', 0),
            operation: !this.props.noFileAction ? <div className="file-actbtn-group">
              <Button size="small" type="primary" onClick={this.handleDownloadFile(item)}>下载</Button>
              <Button size="small" onClick={this.handleRenameFile(item)}>重命名</Button>
              <Popconfirm
                placement="rightTop"
                title="确定要删除这个文件吗"
                onConfirm={this.handleDeleteFile(item)}
                okText="是"
                cancelText="否"
              >
                <Button size="small" type="danger">删除</Button>
              </Popconfirm>
            </div> : null,
          };
        }
        return null;
      } 
      return {
        key: get(item, 'id', ''),
        file_name: get(item, 'name', ''),
        file_size: humanbytes(get(item, 'size', 0)),
        upload_time: <Tooltip title={formatTime(cTime, 'LONG_DATETIME')}>{formatTimeFromNow(cTime)}</Tooltip>,
        download_count: get(item, 'download_count', 0),
        operation: !this.props.noFileAction ? <div className="file-actbtn-group">
          <Button size="small" type="primary" onClick={this.handleDownloadFile(item)}>下载</Button>
          <Button size="small" onClick={this.handleRenameFile(item)}>重命名</Button>
          <Popconfirm
            placement="rightTop"
            title="确定要删除这个文件吗"
            onConfirm={this.handleDeleteFile(item)}
            okText="是"
            cancelText="否"
          >
            <Button size="small" type="danger">删除</Button>
          </Popconfirm>
        </div> : null,
      };
    }).filter(item => !!item);
  };

  render() {
    if (this.props.currentFsId === 'root') {
      return <Empty>
        请先访问一个文件夹
        {!this.props.noNewFolderGuide && <>
          ，或者&nbsp;<a onClick={this.props.handleGuideNewFolder}>创建新文件夹</a>
        </>}
      </Empty>;
    }
    return <Table
      loading={this.props.isLoading}
      rowSelection={{
        onSelect: this.handleRowSelectionSelect,
        onSelectAll: this.handleRowSelectionBatch,
        selectedRowKeys: this.props.rowSelectedKeys,
        type: this.props.onlyVideo ? 'radio' : 'checkbox',
        // 视频模式时禁用全选和反选
        hideDefaultSelections: this.props.onlyVideo,
      }}
      size="middle"
      dataSource={this.renderDataSource()}
      columns={this.DEFAULT_COLUMNS}
      pagination={false}
    />;
  }
}

export default FileView;
