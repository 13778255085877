import React, { useEffect } from 'react';
import { Typography, Divider } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import moment from 'moment';
import {
  get, pick, isEqual, noop 
} from 'lodash';
import { LevelTag } from 'scripts/apps/announcement/widgets/list_table';
import { AccountSelectors, AccountActions } from '../../common/logic/account';

import 'styles/account/notice.scss';

const { Title, Paragraph, Text } = Typography;

const NoticeView = (props) => {
  const { notice, getNotice } = props;

  useEffect(() => {
    const { match } = props;
    if (match.params.aid) {
      getNotice({
        id: match.params.nid,
      });
    }
  }, []);
  const renderContent = () => {
    const n = get(notice, 'content', '').split(':');
    n.shift();
    return n.length ? n.join(':') : '';
  };
  return (
    <div className="notice-view">
      <div>
        <Title>{get(notice, 'title', '')}</Title>
        {/* <div>
          <LevelTag level={get(notice, 'level', 1)} />
        </div> */}
        <Text type="secondary">{get(notice, 'author.realname', '')} 发布于 {moment(get(notice, 'create_time', 0) * 1000).format('YYYY-MM-DD HH:mm')}</Text>
        <Divider />
        <div>
          <div dangerouslySetInnerHTML={{ __html: renderContent() }} />
        </div>
      </div>
    </div>
  );
};

NoticeView.propTypes = {
  notice: PropTypes.shape({}),
  getNotice: PropTypes.func,
  ...RoutePropTypes,
};
NoticeView.defaultProps = {
  notice: {},
  getNotice: noop,
};

const mapStateToProps = (state, props) => {
  const { match } = props;
  const noticeId = get(match.params, 'nid', 0);
  const notice = AccountSelectors.getNoticeEntity(state)(parseInt(noticeId, 10));
  return {
    notice,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getAnnouncement: AccountActions.getGlobalNoticeEntity,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NoticeView));
