/***
 * REVIEW: 2019年03月26日
 * 修正了一些偷工减料的部分，另外这边分页功能似乎没有正常工作，现阶段暂时无视吧，以后补上。
 */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { withRouter } from 'react-router-dom';
import { pick, noop, get } from 'lodash';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { showModal } from 'scripts/common/widgets/modal';
import { AsgnJudgeStatusActions } from 'scripts/common/logic/education/asgn/actions/judge_status';
import JudgeStatusListTable from 'scripts/apps/widgets/judge_status/list_table';

import { AsgnJudgeStatusSelectors } from 'scripts/common/logic/education/asgn/selectors/judge_status';
import { createJudgeResultModal } from 'scripts/apps/widgets/judge_status/dialog';
import { JudgeStatusPropTypes } from 'scripts/common/prop-types/judge_status';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { indexToChar } from '../../../../common/utils/unit';

const JudgeResultModal = createJudgeResultModal(AsgnJudgeStatusActions, AsgnJudgeStatusSelectors, 'Asgn');

const LOADING_STATUS_KEY = 'asgn_judge_status_list';

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnJudgeStatusList: AsgnJudgeStatusActions.getAsgnJudgeStatusList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

const mapStateToProps = (state, props) => {
  const judgeStatusDatas = AsgnJudgeStatusSelectors.getJudgeStatusListData(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  return {
    isLoading,
    judgeStatusDatas,
  };
};
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class JudgeDrawerView extends React.PureComponent {
  static propTypes = {
    authorId: PropTypes.string.isRequired,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    problemId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    judgeStatusDatas: PropTypes.arrayOf(JudgeStatusPropTypes),
    paginationParams: PaginationPropTypes,
  };

  static defaultProps = {
    judgeStatusDatas: [],
    paginationParams: null,
  };

  state = {
    authorId: null,
    problemId: null,
    queryParams: {
      limit: 40,
      page: 1,
      ...PaginationParams40,
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.authorId !== nextProps.authorId || prevState.problemId !== nextProps.problemId) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getAsgnJudgeStatusList({
        courseId: nextProps.courseId,
        asgnId: nextProps.asgnId,
        query: {
          ...pick(
            prevState.queryParams,
            ['limit', 'page']
          ),
          author_id: nextProps.authorId,
          problem_id: nextProps.problemId,
        },
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        authorId: nextProps.authorId,
        problemId: nextProps.problemId,
      };
    }
    return null;
  }

  handleJudgeStatusClick = judgeStatus => () => {
    showModal(JudgeResultModal, {
      judgeStatusId: get(judgeStatus, 'id'),
      apiPayloads: {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
      },
    });
  };

  renderProblemLabel = (item) => {
    return `题目${indexToChar(get(item, 'asgn_problem_item.order', 0))}`;
  };

  renderAuthorLabel = (item) => {
    return get(item, 'author.nickname', '');
  };

  render() {
    return (
      <>
        <Spin spinning={this.props.isLoading} title="评测历史列表加载中">
          <JudgeStatusListTable
            bordered
            onPageChange={this.handlePageChange}
            judgeStatusDatas={this.props.judgeStatusDatas}
            onProblemClick={noop}
            columnStyle="asgn-minimal"
            onJudgeStatusClick={this.handleJudgeStatusClick}
            paginationParams={this.props.paginationParams}
            renderProblemLabel={this.renderProblemLabel}
            renderAuthorLabel={this.renderAuthorLabel}
          />
        </Spin>

      </>
    );
  }
}

export default JudgeDrawerView;
