import React from 'react';
import PropTypes from 'prop-types';
import {
  get, noop, isArray, isEmpty 
} from 'lodash';
import {
  EditOutlined, FolderOutlined, MinusCircleOutlined, PlusCircleOutlined 
} from '@ant-design/icons';
import {
  Tree, Popover, Button, Modal 
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RepositoryFsAction, RepositoryFileSystemSelectors } from 'scripts/common/logic/repository';

import { showModal } from 'scripts/common/widgets/modal';
import { DFSForTree } from 'scripts/common/utils/functional';
import { DirectoryNameDialog } from './widgets/common';

const mapDispatchToProps = dispatch => bindActionCreators({
  getRepositoryFsDirectory: RepositoryFsAction.getRepositoryFsDirectory,
  createRepositoryFsFolder: RepositoryFsAction.createRepositoryFsFolder,
  deleteRepositoryFileOrDir: RepositoryFsAction.deleteRepositoryFileOrDir,
  renameRepositoryFsEntity: RepositoryFsAction.renameRepositoryFsEntity,
}, dispatch);

const mapStateToProps = (state, prop) => {
  const directoryTree =    RepositoryFileSystemSelectors.getRepositoryFileSystemDirectoryTree(state, prop.repoId);
  return {
    directoryTree,
  };
};

// 文件夹树列表
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class TreeView extends React.PureComponent {
  static propTypes = {
    repoPath: PropTypes.string,
    directoryTree: PropTypes.shape({}),
    repoId: PropTypes.string.isRequired,
    getRepositoryFsDirectory: PropTypes.func,
    createRepositoryFsFolder: PropTypes.func,
    renameRepositoryFsEntity: PropTypes.func,
    deleteRepositoryFileOrDir: PropTypes.func,
    disabledEditActionBar: PropTypes.bool,
    onPathChange: PropTypes.func,
  };

  static defaultProps = {
    repoPath: 'root',
    directoryTree: null,
    disabledEditActionBar: false,
    getRepositoryFsDirectory: noop,
    createRepositoryFsFolder: noop,
    deleteRepositoryFileOrDir: noop,
    renameRepositoryFsEntity: noop,
    onPathChange: noop,
  };

  state = {
    selectedKeys: [this.props.repoPath || 'root'],
  };

  // dfs = (node, key) => {
  //   if (node.id !== key) {
  //     const { children } = node;
  //     let rel = null;
  //     for (let i = 0; i < children.length; i++) {
  //       rel = this.dfs(children[i], key);
  //       if (rel) {
  //         break;
  //       }
  //     }
  //     return rel || null;
  //   }
  //   return node;
  // };

  loadData = (treeNode) => {
    const { getRepositoryFsDirectory } = this.props;
    return new Promise((resolve) => {
      if (!isEmpty(treeNode.props.children)) {
        resolve();
        return;
      }
      const fsId = get(treeNode, 'props.dataRef.id', '');
      getRepositoryFsDirectory({
        init: false,
        repoId: this.props.repoId,
        fsId,
        onSuccess: () => {

        },
        onComplete: () => {
          resolve();
        },
      });
    });
  };

  handleSelect = (selectKeys, e) => {
    if (!get(e, 'selected', true)) return;
    let path = get(selectKeys, '0', '');
    path = path === 'root' ? '' : path;
    this.setState({
      selectedKeys: [path || 'root'],
    });
    this.props.onPathChange(path);
  };

  handleNewDirectory = fsid => (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    showModal(DirectoryNameDialog, {
      dirname: '',
      onOk: (ref, state) => {
        this.props.createRepositoryFsFolder({
          repoId: this.props.repoId,
          fsId: fsid,
          folderName: state.dirname,
        });
      },
    });
  };

  handleRenameDirectory = (fsid, dirname) => (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    showModal(DirectoryNameDialog, {
      dirname,
      onOk: (ref, state) => {
        this.props.renameRepositoryFsEntity({
          repoId: this.props.repoId,
          fsId: fsid,
          fileName: state.dirname,
        });
      },
    });
  };

  handleDeleteDirectory = fs => (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    Modal.confirm({
      title: '操作确认',
      content: '确认删除此文件夹？',
      onOk: () => {
        const findRet = DFSForTree(this.props.directoryTree, fs.id, true);
        const { parentPath = [], path = [] } = findRet;
        const pPath = parentPath.join('-');
        const nPath = path.join('-');
        this.props.deleteRepositoryFileOrDir({
          repoId: this.props.repoId,
          fsId: get(fs, 'id', ''),
          isDir: true,
          onSuccess: () => {
            if (this.props.repoPath.startsWith(nPath)) {
              this.setState({
                selectedKeys: [pPath || 'root'],
              }, () => {
                this.props.onPathChange(pPath);
              });
            }
          },
        });
      },
    });
  };

  renderDirectoryPopMenu = (repofs) => {
    const isRoot = get(repofs, 'id', '') === 'root';
    return !this.props.disabledEditActionBar ? <Popover
      title={get(repofs, 'title', '')}
      content={<Button.Group>
        <Button type="primary" onClick={this.handleNewDirectory(repofs.id)}>
          <PlusCircleOutlined /> 新建
        </Button>
        {!isRoot && <Button onClick={this.handleRenameDirectory(repofs.id, repofs.title)}>
          <EditOutlined /> 重命名
        </Button>}
        {!isRoot && <Button onClick={this.handleDeleteDirectory(repofs)} type="danger">
          <MinusCircleOutlined /> 删除
        </Button>}
      </Button.Group>}
      placement="topLeft"
    >
      {get(repofs, 'title', '')}
    </Popover> : get(repofs, 'title', '');
  };

  renderTreeNodes =  (repositoryfs, parentId = '') => {
    const {
      id,
      children,
    } = repositoryfs;
    const hasChildren = get(repositoryfs, 'has_children', false);
    const currentId = id.toString() === 'root' ? '' : id.toString();
    const key = parentId !== '' ? `${parentId}-${currentId}` : currentId;
    return {
      key: key || 'root',
      icon: <FolderOutlined />,
      title: this.renderDirectoryPopMenu(repositoryfs),
      dataRef: repositoryfs,
      isLeaf: !hasChildren,
      children: (isArray(children) && children.length > 0) ? children.map((item) => {
        return this.renderTreeNodes(item, key);
      }) : [],
    };
  };

  renderTree = () => {
    return <Tree.DirectoryTree
      size="large"
      blockNode
      // showLine
      // switcherIcon={<Icon type="folder" />}
      onSelect={this.handleSelect}
      selectedKeys={this.state.selectedKeys}
      defaultExpandedKeys={[this.props.repoPath || 'root']}
      className="treeview-inline"
      loadData={this.loadData}
      expandAction="doubleClick"
      treeData={[this.renderTreeNodes(this.props.directoryTree)]}
    />;
  };

  render() {
    if (!this.props.directoryTree) return null;
    return this.renderTree();
  }
}

export default TreeView;
