import 'styles/contest/support/faqs.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Input } from 'antd';
import { get, isEqual, noop } from 'lodash';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';

class FaqEditor extends React.PureComponent {
  static propTypes = {
    onSave: PropTypes.func,
  };

  static defaultProps = {
    onSave: noop,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      FaqEditor.fetchList(nextProps);
      return {
        answer: get(queryParams, 'answer', ''),
        queryParams,
      };
    }
    return null;
  }

  state = {
    faq: {
      title: '',
      content: '',
    },
    processing: false,
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  unmounted = false;

  handleEditorChange = (text) => {
    this.setState({
      faq: {
        ...this.state.faq,
        content: text,
      },
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      ...this.state,
      faq: {
        ...this.state.faq,
        [keyName]: target.value,
      },
    });
  };

  handleClick = () => {
    if (this.state.processing) return;
    this.setState({
      processing: true,
    }, () => {
      this.props.onSave(this.state.faq).then(() => {
        this.setState({ processing: false });
      }).catch(() => {
        this.setState({ processing: false });
      });
    });
  };

  render() {
    return <div className="faqs-editor">
      <div className="top">
        <Form.Item label="提问标题" required>
          <Input value={get(this.state, 'faq.title', '')} placeholder="请简要概述你要提的问题" width={200} onChange={this.handleInputChange('title')} maxLength={30} />
        </Form.Item>
      </div>
      <div className="main">
        <MarkdownEditor
          height="100%"
          config={{
            view: {
              menu: true,
              md: true,
              html: true,
            },
          }}
          disablePlugins={['upload-image']}
          onChange={this.handleEditorChange}
          defaultValue={get(this.state, 'faq.content', '')}
        />
      </div>
      <div className="bottom">
        <Button type="primary" loading={this.state.processing} onClick={this.handleClick}>
          提问
        </Button>
      </div>
    </div>;
  }
}

export default FaqEditor;
