import 'styles/management/monitor.scss';

import React from 'react';
import { Menu } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ManagementRoutes } from 'scripts/apps/routes';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';
import JudgeQueueView from './judge_queue';

const MonitorView = () => {
  return <div className="management-monitor-view">
    <div className="monitor-left-side">
      <Menu selectedKeys={['judge_queue']}>
        <Menu.Item key="judge_queue">全局评测状态</Menu.Item>
      </Menu>
    </div>
    <div className="monitor-main-side">
      <Switch>
        <Route
          path={ManagementRoutes.MONITOR.JUDGE_QUEUE_WITH_TYPE}
          component={JudgeQueueView}
          exact
        />
        <Route
          path={ManagementRoutes.MONITOR.JUDGE_QUEUE}
          render={() => <Redirect to={buildPath(ManagementRoutes.MONITOR.JUDGE_QUEUE_WITH_TYPE, { type: 'collection' })} />}
          exact
        />
        <Route
          path={ManagementRoutes.MONITOR.INDEX}
          render={() => <Redirect to={buildPath(ManagementRoutes.MONITOR.JUDGE_QUEUE_WITH_TYPE, { type: 'collection' })} />}
          exact
        />
        <Route component={WeJudge404NotFoundComponent} />
      </Switch>
    </div>
  </div>;
};

MonitorView.propTypes = {};

MonitorView.defaultProps = {};

export default MonitorView;
