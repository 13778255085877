import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Tag } from 'antd';
import { getStautsByStartEndTime } from 'scripts/common/utils/time_formatter';

export const AsgnStatusTag = (props) => {
  const status = get(props, 'status', {});
  const mode = get(props, 'mode', 'normal');
  const score = get(props, 'score', '') || '未评';
  if (status.role === 'manage') {
    const color = get(status, 'checked', 0) >= get(status, 'reports', 0) ? '#52c41a' : '#1890ff';
    return <Tag color={color}>{get(status, 'checked', 0)} / {get(status, 'reports', 0)} 批改</Tag>;
  }
  if (!get(status, 'permission', false)) {
    return <Tag color="grey">无访问权限</Tag>;
  }
  const timeStatus = getStautsByStartEndTime(get(status, 'start_time', 0), get(status, 'end_time', 0));
  const color = {
    '-1': '#1890ff',
    0: '#52c41a',
    1: '#f5222d',
  };
  if (mode === 'mini') {
    return <Tag color={get(color, timeStatus.status, 'grey')}>进行中{mode === 'mini' ? `(${score}分)` : ''}</Tag>;
  }
  return <Tag color={get(color, timeStatus.status, 'grey')}>{timeStatus.desc}</Tag>;
};

AsgnStatusTag.propTypes = {
  mode: PropTypes.string,
  score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.shape({}).isRequired,
};

AsgnStatusTag.defaultProps = {
  mode: 'normal',
  score: null,
};
