import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { InputNumber, Slider, Tooltip } from 'antd';

const TIME_LIMIT_STEP_NUM = 100;
const MEM_LIMIT_STEP_NUM = 1024;
const TIME_LIMIT_DEFAULT = 1000;
const ONE_SECOND = 1000;
const ONE_MB = 1024;
const MEMORY_LIMIT_DEFAULT = 32768;
const TIME_LIMIT_MIN = 500;
const TIME_LIMIT_MAX = 20000;
const MEMORY_LIMIT_MIN = 32768;
const MEMORY_LIMIT_MAX = 2097152;

class LanguageLimitSlider extends React.PureComponent {
  static propTypes = {
    timeLimit: PropTypes.number,
    memLimit: PropTypes.number,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    timeLimit: TIME_LIMIT_DEFAULT,
    memLimit: MEMORY_LIMIT_DEFAULT,
    onChange: noop,
  };

  state = {
    timeLimit: this.props.timeLimit,
    memLimit: this.props.memLimit,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      timeLimit: nextProps.timeLimit,
      memLimit: nextProps.memLimit,
    };
  }

  handleLimitSliderChange = key => (value) => {
    let val = parseInt(value, 10);
    if (key === 'timeLimit') {
      val = val >= TIME_LIMIT_MIN &&  val <= TIME_LIMIT_MAX
        ? val : TIME_LIMIT_DEFAULT;
    } else if (key === 'memLimit') {
      val = val >= MEMORY_LIMIT_MIN && val <= MEMORY_LIMIT_MAX
        ? val : MEMORY_LIMIT_DEFAULT;
    }
    this.props.onChange(key, val);
  };

  render() {
    return <>
      <Slider
        key="time_limit_silder"
        step={TIME_LIMIT_STEP_NUM}
        min={TIME_LIMIT_MIN}
        max={TIME_LIMIT_MAX}
        tipFormatter={val => `${val / ONE_SECOND} 秒`}
        onChange={this.handleLimitSliderChange('timeLimit')}
        value={this.state.timeLimit}
      />
      <strong>时间限制:</strong>
      <Tooltip
        placement="right"
        tigger="focus"
        title={`${(this.state.timeLimit / ONE_SECOND).toFixed(1)} S`}
      >
        <InputNumber
          key="time_limit_in"
          min={TIME_LIMIT_MIN}
          max={TIME_LIMIT_MAX}
          step={TIME_LIMIT_STEP_NUM}
          style={{ marginLeft: 8 }}
          value={this.state.timeLimit}
          onChange={this.handleLimitSliderChange('timeLimit')}
        /> MS
      </Tooltip>
      <Slider
        key="mem_limit_silder"
        step={MEM_LIMIT_STEP_NUM}
        min={MEMORY_LIMIT_MIN}
        max={MEMORY_LIMIT_MAX}
        tipFormatter={val => `${val / ONE_MB} MB`}
        onChange={this.handleLimitSliderChange('memLimit')}
        value={this.state.memLimit}
      />
      <strong>内存限制:</strong>
      <Tooltip
        placement="right"
        tigger="focus"
        title={`${(this.state.memLimit / ONE_MB).toFixed(1)} MB`}
      >
        <InputNumber
          style={{ marginLeft: 8 }}
          key="mem_limit_in"
          step={MEM_LIMIT_STEP_NUM}
          min={MEMORY_LIMIT_MIN}
          max={MEMORY_LIMIT_MAX}
          value={this.state.memLimit}
          onChange={this.handleLimitSliderChange('memLimit')}
        /> KB
      </Tooltip>
    </>;
  }
}

export default LanguageLimitSlider;
