import React from 'react';
import { get, noop, pick } from 'lodash';
import { Form, Alert, Table, Col, Modal, Row, Select, Switch, Spin } from 'antd';
import PropTypes from 'prop-types';
import AccountEditor from 'scripts/apps/account/widgets/account_editor';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { SchoolPropTypes } from 'scripts/common/prop-types/school';
import { enumMap, enumEach } from 'scripts/common/utils/enum_generator';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AccountActions, AccountSelectors } from 'scripts/common/logic/account';
import { SchoolActions, SchoolSelectors } from 'scripts/common/logic/education/school';
import { ProblemPermissionEnum } from 'scripts/common/enums/problem';
import { CollectionPermissionEnum } from 'scripts/common/enums/collection';
import { ContestPermissionEnum } from 'scripts/common/enums/contest';
import { JudgeStatusPermissionEnum } from 'scripts/common/enums/judge';
import { MixinPermissionHelper } from 'scripts/common/utils/mixin_calculater';

const mapDispatchToProps = dispatch => bindActionCreators({
  editAccount: AccountActions.editAccount,
  addAccount: AccountActions.addAccount,
  getSchoolList: SchoolActions.getSchoolList,
}, dispatch);

const mapStateToProps = (state) => {
  const currentAccount = AccountSelectors.getLoginAccount(state);
  const schoolList = SchoolSelectors.schoolListData(state);
  return {
    currentAccount,
    schoolList,
  };
};

const TITLE_MAPPING = {
  add: '创建账号',
  edit: '编辑账号',
  permission: '权限设置',
};

const DEFAULT_VALUES = {
  reset_prc: false,
  role: 2,
  school: { id: -1, pk: -1 },
};

@connect(mapStateToProps, mapDispatchToProps)
export class AccountModal extends React.PureComponent {
  static propTypes = {
    account: AccountPropTypes,
    mode: PropTypes.string,
    onOk: PropTypes.func,
    getSchoolList: PropTypes.func,
    addAccount: PropTypes.func,
    editAccount: PropTypes.func,
    onAccountInfoChange: PropTypes.func,
    schoolList: PropTypes.arrayOf(SchoolPropTypes),
    currentAccount: AccountPropTypes,
  };

  static defaultProps = {
    mode: 'add',
    onOk: noop,
    addAccount: noop,
    editAccount: noop,
    getSchoolList: noop,
    onAccountInfoChange: noop,
    schoolList: [],
    currentAccount: {},
    account: null,
  };

  state = {
    isLoading: false,
    schoolFetched: this.props.schoolList.length > 0,
    account: {
      ...DEFAULT_VALUES,
      school_id: get(this.props.account, 'school.id', -1),
      ...(this.props.account ? JSON.parse(JSON.stringify(this.props.account)) : {}),
    },
  };

  componentDidMount() {
    if (this.props.schoolList.length <= 0) {
      this.props.getSchoolList({
        onSuccess: () => {
          this.setState({
            schoolFetched: true,
          });
        },
      });
    }
  }

  handleSwitchChange = keyName => (checked) => {
    this.setState(update(this.state, {
      account: {
        [keyName]: { $set: checked },
      },
    }));
  };

  handleSchoolChange = (value) => {
    this.setState(update(this.state, {
      account: {
        school_id: { $set: value },
      },
    }));
  };

  handleRoleChange = (value) => {
    this.setState(update(this.state, {
      account: {
        role: { $set: value },
      },
    }));
  };

  handleSaveEdit = () => {
    switch (this.props.mode) {
      case 'edit':
      case 'permission':
        {
          const account = pick(this.state.account, this.props.mode === 'permission' ? ['permissions'] : [
            'email', 'sex', 'nickname', 'realname', 'motto', 'role',
            'locked', 'reset_prc', 'email_validated', 'school_id'
          ]);
          this.setState({
            isLoading: true,
          }, () => {
            this.props.editAccount({
              accountId: this.state.account.id,
              account,
              onSuccess: () => {
                this.props.onAccountInfoChange();
                this.props.onOk();
              },
              onComplete: () => {
                this.setState({
                  isLoading: false,
                });
              },
            });
          });
        }
        break;
      case 'add':
        {
          const account = pick(this.state.account, [
            'email', 'sex', 'nickname', 'realname', 'motto', 'role',
            'locked', 'email_validated', 'school_id'
          ]);
          this.setState({
            isLoading: true,
          }, () => {
            this.props.addAccount({
              account,
              onSuccess: () => {
                this.props.onAccountInfoChange();
                this.props.onOk();
              },
              onComplete: () => {
                this.setState({
                  isLoading: false,
                });
              },
            });
          });
        }
        break;
      default:
        break;
    }
    return false;
  };

  // 内部信息编辑器：编辑学校归属、角色、
  renderInnerInfoEditor = () => {
    const { account, schoolFetched } = this.state;
    const currentRole = get(this.props.currentAccount, 'role', 0);
    const isAdmin = currentRole === AccountRoleEnum.ADMINISTRATOR.value;
    const changeSchool = () => (schoolFetched ? <Select
      value={get(account, 'school_id', -1)}
      dropdownMatchSelectWidth
      style={{ width: '100%' }}
      onChange={this.handleSchoolChange}
    >
      {this.props.schoolList.map((item) => {
        return (
          <Select.Option
            key={get(item, 'id')}
            value={get(item, 'id')}
          >
            {get(item, 'name', '')}
          </Select.Option>
        );
      })}
      <Select.Option
        key={-1}
        value={-1}
      >
        无归属学校
      </Select.Option>
    </Select> : <Spin />);
    return <Form.Item>
      <Row gutter={8}>
        <Col span={12}>
          <div className="editor-title">
            用户角色
          </div>
          <Select
            value={get(account, 'role')}
            style={{ width: '100%' }}
            dropdownMatchSelectWidth
            onChange={this.handleRoleChange}
          >
            {enumMap(AccountRoleEnum, (item, key) => {
              return <Select.Option key={key} value={item.value}>{item.desc}</Select.Option>;
            }, (item) => {
              const { currentAccount } = this.props;
              if (currentAccount.role === AccountRoleEnum.ADMINISTRATOR.value) {
                return item.value !== AccountRoleEnum.ASSISTANT.value;
              }
              // 如果教务自己，则不能选择其他身份
              if (currentAccount.id === account) {
                return item.value === AccountRoleEnum.DEAN.value;
              }
              // 否则可以选择老师或者教务
              return item.value === AccountRoleEnum.DEAN.value ||
                item.value === AccountRoleEnum.TEACHER.value;
            })}
          </Select>
        </Col>
        <Col span={12}>
          <div className="editor-title">
            归属学校
          </div>
          {!isAdmin ? get(account, 'school.name', '无') : changeSchool()}
        </Col>
      </Row>
    </Form.Item>;
  };

  renderPermissionTable = (pEnum, key) => {
    const { account } = this.state;
    const uState = get(account, `permissions.${key}`, 0);
    const data = { key: 'p' };
    enumEach(pEnum, (item) => {
      const ph = new MixinPermissionHelper(uState);
      data[item.value.toString()] = <Switch
        onChange={(checked) => {
          const pht = new MixinPermissionHelper(uState);
          this.setState(update(this.state, {
            account: {
              permissions: {
                [key]: { $set: checked ? pht.add(item) : pht.remove(item) },
              },
            },
          }));
        }}
        checked={ph.has(item)}
      />;
    });
    return <Table
      bordered
      size="small"
      columns={enumMap(pEnum, (item) => {
        return {
          key: item.value.toString(),
          dataIndex: item.value.toString(),
          title: item.desc,
        };
      })}
      pagination={false}
      dataSource={[data]}
    />;
  };

  // 用户权限编辑器
  renderPermissionEditor = () => {
    return <>
      <Alert
        type="warning"
        message={`这里设置的是账户(${get(this.state.account, 'email')})的全局权限，请谨慎操作！`}
      />
      <Form.Item>
        <div className="editor-title">题目权限</div>
        {this.renderPermissionTable(ProblemPermissionEnum, 'problem')}
        <div className="editor-title">题库权限</div>
        {this.renderPermissionTable(CollectionPermissionEnum, 'collection')}
        <div className="editor-title">评测权限(题库)</div>
        {this.renderPermissionTable(JudgeStatusPermissionEnum, 'judge_status')}
        <div className="editor-title">比赛权限</div>
        {this.renderPermissionTable(ContestPermissionEnum, 'contest')}
      </Form.Item>
    </>;
  };

  renderExtraOptionEditor = () => {
    const { account } = this.state;
    const currentRole = get(this.props.currentAccount, 'role', 0);
    const isAdmin = currentRole === AccountRoleEnum.ADMINISTRATOR.value;
    return <Row gutter={8}>
      <Col span={8}>
        <div className="editor-title">
          锁定账户
        </div>
        <Switch checked={get(account, 'locked', false)} onChange={this.handleSwitchChange('locked')} />
      </Col>
      <Col span={8}>
        <div className="editor-title">
          邮箱已验证
        </div>
        {isAdmin ?
          <Switch
            checked={get(account, 'email_validated')}
            onChange={this.handleSwitchChange('email_validated')}
          /> : '不可用'}
      </Col>
      <Col span={8}>
        <div className="editor-title">
          密码重试次数({get(account, 'password_retry_counter', '?')})
        </div>
        {this.props.mode === 'add' ? '不可用' : <>
          <Switch
            checked={get(account, 'reset_prc', false)}
            onChange={this.handleSwitchChange('reset_prc')}
          /> 重置
        </>}
      </Col>
    </Row>;
  };

  render() {
    const { account, isLoading } = this.state;
    return (<Modal
      {...this.props}
      width="80%"
      title={TITLE_MAPPING[this.props.mode]}
      confirmLoading={isLoading}
      okButtonProps={{
        onClick: this.handleSaveEdit,
      }}
    >
      {this.props.mode === 'permission' ? this.renderPermissionEditor() : <>
        <AccountEditor
          account={account}
          disabledEmail={false}
          handleChange={(a) => {
            this.setState(update(this.state, {
              account: {
                $merge: pick(a, [
                  'email', 'sex', 'nickname', 'realname', 'motto'
                ]),
              },
            }));
          }}
        />
        {this.renderInnerInfoEditor()}
        {this.renderExtraOptionEditor()}
      </>}
    </Modal>);
  }
}
