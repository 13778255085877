import React from 'react';
import PropTypes from 'prop-types';
import { FolderOutlined } from '@ant-design/icons';
import { Tree } from 'antd';
import { get, noop } from 'lodash';

const { DirectoryTree } = Tree;

const FolderSelectTree = (props) => {
  const {
    directoryTree,
    handleSelect,
  } = props;
  const renderFolderTreeNode = (directory) => {
    if (get(directory, 'id', '') === 'root') {
      return {
        icon: <FolderOutlined />,
        title: get(directory, 'title', ''),
        key: get(directory, 'id', ''),
        selectable: false,
        children: renderFolderTreeNode(get(directory, 'children', [])),
      };
    } 
    return directory.map((item) => {
      return {
        icon: <FolderOutlined />,
        title: get(item, 'title', ''),
        key: get(item, 'id', ''),
        selectable: true,
        children: get(item, 'has_children', false) && renderFolderTreeNode(get(item, 'children', [])),
      };
    });
  };

  const onSelect = (keys) => {
    handleSelect(keys[0]);
  };
  return (
    <DirectoryTree
      defaultExpandAll
      onSelect={onSelect}
      treeData={renderFolderTreeNode(directoryTree)}
    />
  );
};

FolderSelectTree.propTypes = {
  directoryTree: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
  handleSelect: PropTypes.func,
};

FolderSelectTree.defaultProps = {
  directoryTree: [],
  handleSelect: noop,
};

export default FolderSelectTree;
