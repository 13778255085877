import 'styles/education/asgn/asgn.scss';
import 'styles/common/layout/general_view.scss';

import React from 'react';
import localStorage from 'localStorage';

import {
  AppstoreOutlined,
  BookFilled,
  CheckOutlined,
  ClockCircleOutlined,
  EditOutlined,
  FileSearchOutlined,
  HomeOutlined,
  LineChartOutlined,
  LoadingOutlined,
  PieChartOutlined,
  RollbackOutlined,
  SettingOutlined
} from '@ant-design/icons';

import {
  Layout, Menu, Spin, Progress, Tooltip 
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  get, indexOf, has, isEmpty 
} from 'lodash';
import {
  withRouter, Route, Switch, matchPath, Redirect 
} from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { getStautsByStartEndTime } from 'scripts/common/utils/time_formatter';
import { buildPath, getCurrentURL } from 'scripts/common/utils/location_helper';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import { CourseRoutes, AsgnRoutes, AsgnProblemRoutes } from 'scripts/apps/routes';
import  { AsgnProblemActions, AsgnSolutionActions, AsgnReportActions } from 'scripts/common/logic/education/asgn/actions';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';
import {
  AsgnsSelectors,
  AsgnReportSelectors,
  AsgnSolutionSelectors,
  AsgnProblemsSelectors
} from 'scripts/common/logic/education/asgn/selectors';
import { AsgnPropTypes, AsgnProblemPropTypes } from 'scripts/common/prop-types/asgn';
import DataKeys from 'scripts/common/constants/data_keys';
import { AuthPropTypes } from 'scripts/common/prop-types/account';
import JudgeStatusView from 'scripts/apps/education/asgn/judge_status/judge_status_view';
import  AsgnProblemsListContent from './problems/problems_list';
import AsgnManagement from './management';
import AsgnOverviewComponent from './overview';
import MyReportView from './my_report';
import StudentReportView from './student_report';
import ReportListView from './report/report_list';
import RankListView from './rank_list/rank_list_view';
import AsgnStatistics from './statistics/index';
import AsgnCrossCheckView from './cross_check/index';
import CenterSpin from 'scripts/common/widgets/center-spin';

const LOADING_STATUS_KEY = 'asgn_view';

const { Content } = Layout;

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnEntity: AsgnActions.getAsgnEntity,
  getCodeProblemSolutions: AsgnSolutionActions.getCodeProblemSolutions,
  getNormalProblemSolutions: AsgnSolutionActions.getNormalProblemSolutions,
  getAsgnProblemsList: AsgnProblemActions.getAsgnProblemsList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getStudentReportsEntity: AsgnReportActions.getStudentReportsEntity,
  createStudentReport: AsgnReportActions.createStudentReport,
  createSolution: AsgnSolutionActions.createSolution,
}, dispatch);

const mapStateToProps = (state, props) => {
  const extraInfo = {};
  const { match, location } = props;
  const matchItem = matchPath(
    location.pathname,
    { path: AsgnRoutes.SUBITEMS }
  );
  const ItemKey = get(matchItem, 'params.subItemName', '');
  const subItemKey = (ItemKey === '' ? 'asgn' : ItemKey);
  const courseId = parseInt(get(match.params, 'courseId', 0), 10);
  const asgnId = parseInt(get(match.params, 'asgnId', 0), 10);
  const asgn = AsgnsSelectors.getAsgnEntity(state)(asgnId);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const codeProblemsDatas = AsgnProblemsSelectors.getAsgnCodeProblemDatas(state);
  const objectiveProblemsDatas = AsgnProblemsSelectors.getAsgnObjectiveProblemDatas(state);
  const status = get(asgn, 'status', {});

  if (get(status, 'role') === 'student') {
    const normalSolutions =      AsgnSolutionSelectors.getAsgnProblemSolutions(DataKeys.NORMAL_PROBLEMS)(state)();
    const stuReport = AsgnReportSelectors.getAsgnReportEntity(state)(get(status, 'report_id'));
    const fiCnt = Object.keys(normalSolutions).length + get(stuReport, 'code_rank_solved', 0);
    const allCnt = codeProblemsDatas.length + objectiveProblemsDatas.length;
    extraInfo.process = allCnt === 0 ? 0 : (fiCnt / allCnt) * 100;
    extraInfo.report = stuReport;
    extraInfo.problemsFinished = fiCnt;
    extraInfo.problemsTotal = allCnt;
  } else {
    const fiCnt = get(status, 'checked', 0);
    const subCnt = get(status, 'submitted', 0);
    const allCnt = get(status, 'reports', 0);
    extraInfo.process = allCnt === 0 ? 0 : (subCnt / allCnt) * 100;
    extraInfo.successProcess = allCnt === 0 ? 0 : (fiCnt / allCnt) * 100;
    if (extraInfo.process === 100 && extraInfo.successProcess !== 100) extraInfo.process -= 0.1;
    extraInfo.checked = fiCnt;
    extraInfo.submitted = subCnt;
    extraInfo.reports = allCnt;
  }

  return {
    subItemKey,
    courseId,
    asgnId,
    asgn,
    isLoading,
    extraInfo,
    asgnStatus: status,
    codeProblemsDatas,
    objectiveProblemsDatas,
  };
};

@LoginRequire
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AsgnView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    auth: AuthPropTypes.isRequired,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    subItemKey: PropTypes.string.isRequired,
    getAsgnEntity: PropTypes.func.isRequired,
    getAsgnProblemsList: PropTypes.func.isRequired,
    getStudentReportsEntity: PropTypes.func.isRequired,
    getNormalProblemSolutions: PropTypes.func.isRequired,
    createStudentReport: PropTypes.func.isRequired,
    codeProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    objectiveProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    asgn: AsgnPropTypes,
    extraInfo: PropTypes.shape({}),
    asgnStatus: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    createSolution: PropTypes.func.isRequired,
  };

  static defaultProps = {
    asgn: null,
    isLoading: true,
    extraInfo: {},
    asgnStatus: {},
  };

  state = {
    hasFetchEntity: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const onError = () => {
      nextProps.history.push(buildPath(
        CourseRoutes.CONTENT_ASGN,
        { courseId: nextProps.courseId }
      ));
    };
    localStorage.setItem(
      DataKeys.LOCAL_STORAGE.ASGN_VIEW_REFERER,
      getCurrentURL(window.location.href)
    );
    let asgnRaw = null;
    if (!prevState.hasFetchEntity) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getAsgnEntity({
        id: nextProps.asgnId,
        courseId: nextProps.courseId,
        onError,
        onSuccess: (asgns) => {
          asgnRaw = get(asgns, '0', {});
        },
        onComplete: () => {
          nextProps.getAsgnProblemsList({
            courseId: nextProps.courseId,
            asgnId: nextProps.asgnId,
            onError,
            onComplete: () => {
              const asgn = nextProps.asgn || asgnRaw;
              const status = get(asgn, 'status', {});
              const report = get(status, 'report', false);
              const reportId = get(status, 'report_id', '');
              if (get(status, 'role') === 'student') {
                const hasVisitPermission = get(status, 'permission', false);
                const timeStatus = getStautsByStartEndTime(get(status, 'start_time', 0), get(status, 'end_time', 0)).status;
                if (!(hasVisitPermission && timeStatus > -1)) onError();
                nextProps.getCodeProblemSolutions({
                  asgnId: nextProps.asgnId,
                  courseId: nextProps.courseId,
                  onComplete: () => {
                    nextProps.getNormalProblemSolutions({
                      asgnId: nextProps.asgnId,
                      courseId: nextProps.courseId,
                      onComplete: () => {
                        if (report) {
                          nextProps.getStudentReportsEntity({
                            asgnId: nextProps.asgnId,
                            courseId: nextProps.courseId,
                            id: reportId,
                            onComplete: () => {
                              nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
                            },
                          });
                        } else {
                          nextProps.createStudentReport({
                            asgnId: nextProps.asgnId,
                            courseId: nextProps.courseId,
                            onSuccess: () => {
                            },
                            onError,
                            onComplete: () => {
                              nextProps.getAsgnEntity({
                                id: nextProps.asgnId,
                                courseId: nextProps.courseId,
                              });
                              nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
                            },
                          });
                        }
                      },
                    });
                  },
                });
              } else {
                nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
              }
            },
          });
        },
      });
      return {
        hasFetchEntity: true,
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.hasFetchEntity && !this.props.isLoading && isEmpty(this.props.asgn)) {
      this.props.history.push(buildPath(
        CourseRoutes.CONTENT_ASGN,
        { courseId: this.props.courseId }
      ));
    }
  }

  getAsgnProblemSubMenu = () => {
    const { codeProblemsDatas, objectiveProblemsDatas } = this.props;
    const result = [
      {
        key: 'all_problems',
        desc: `所有题目 (${codeProblemsDatas.length + objectiveProblemsDatas.length})`,
        manageOnly: true,
      }
    ];
    codeProblemsDatas.length > 0 && result.push({
      key: 'code_problems',
      desc: `代码题 (${codeProblemsDatas.length})`,
      stuOnly: true,
    });
    objectiveProblemsDatas.length > 0 && result.push({
      key: 'objective_problems',
      desc: `客观题 (${objectiveProblemsDatas.length})`,
      stuOnly: true,
    });

    return result;
  };

  ASGN_MENU = [
    {
      key: 'overview',
      desc: '概览',
      icon: <HomeOutlined />,
    },
    {
      key: 'asgn_problems',
      desc: '作业内容',
      icon: <AppstoreOutlined />,
      subMenu: this.getAsgnProblemSubMenu,
    },
    {
      key: 'report',
      desc: '实验报告',
      icon: <BookFilled />,
      stuOnly: true,
    },
    {
      key: 'judge_history',
      desc: '评测历史',
      icon: <ClockCircleOutlined />,
    },
    {
      key: 'rank',
      desc: '作业排行',
      icon: <LineChartOutlined />,
    },
    {
      desc: 'divider',
      key: 'divider_manage',
      manageOnly: true,
    },
    {
      key: 'checkup',
      desc: '作业批改',
      icon: <EditOutlined />,
      manageOnly: true,
    },
    {
      key: 'management',
      desc: '作业管理',
      icon: <SettingOutlined />,
      manageOnly: true,
    },
    {
      key: 'statistics',
      desc: '作业统计',
      icon: <PieChartOutlined />,
      manageOnly: true,
    },
    {
      key: 'cross_check',
      desc: '代码查重',
      icon: <FileSearchOutlined />,
      manageOnly: true,
    }
  ];

  handleProblemClick = (item) => {
    const { history, asgnId, courseId } = this.props;
    history.push(buildPath(AsgnProblemRoutes.CONTENT, {
      asgnId,
      courseId,
      problemId: item.id,
    }));
  };

  handleReturnCourseClick = () => {
    const { history } = this.props;
    history.push(buildPath(
      CourseRoutes.CONTENT_ASGN,
      { courseId: this.props.courseId }
    ));
  };

  handleSubChange = ({ key }) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnRoutes.SUBITEMS,
      {
        subItemName: key,
      },
      match.params
    ));
  };

  fetchAsgnProblemList = (e) => {
    this.props.getAsgnProblemsList({
      courseId: this.props.courseId,
      asgnId: this.props.asgnId,
    });
  };

  renderProblemsListView = (props) => {
    const { match } = props;
    const { asgn, extraInfo } = this.props;
    const typeKey = get(match.params, 'subItemName');
    const isStudent = get(asgn, 'status.role') === 'student';
    const PROBLEMS_LIST_MATCHED_KEY = isStudent ? ['objective_problems', 'code_problems'] : ['all_problems'];
    let pdatas;
    if (isStudent) {
      pdatas = typeKey === 'objective_problems' ? this.props.objectiveProblemsDatas : this.props.codeProblemsDatas;
    } else {
      pdatas = [
        ...this.props.objectiveProblemsDatas,
        ...this.props.codeProblemsDatas
      ];
      pdatas.sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
        return -1;
      });
    }
    if (indexOf(PROBLEMS_LIST_MATCHED_KEY, typeKey) > -1) {
      return <AsgnProblemsListContent
        {...props}
        asgn={this.props.asgn}
        isManage={get(asgn, 'status.role') === 'manage'}
        reportSubmitted={get(extraInfo, 'report.submitted', false) || get(extraInfo, 'report.teacher_check', false)}
        groupName={typeKey}
        courseId={this.props.courseId}
        asgnId={this.props.asgnId}
        problemDatas={pdatas}
        onProblemClick={this.handleProblemClick}
        hasFetchList={(e) => { this.fetchAsgnProblemList(e); }}
      />;
    }
    return <Redirect to={buildPath(AsgnRoutes.OVERVIEW, null, this.props.match.params)} />;
  };

  render() {
    const { isLoading, asgn, extraInfo } = this.props;
    const isStudent = get(asgn, 'status.role') === 'student';
    const isManage = get(asgn, 'status.role') === 'manage';
    return !isLoading && asgn ? (
      <div className="asgn_view">
        <div className="asgn_header">
          <div className="left_side">
            <a onClick={this.handleReturnCourseClick}><RollbackOutlined /> 返回课程</a>
          </div>
          <div className="right_side">
            <div className="title">
              {this.props.asgn.title}
            </div>
            <div className="progress">
              <div className="progress-bar">
                {asgn && get(asgn, 'status.role') === 'student' ? (() => {
                  if (get(extraInfo, 'report.teacher_check', false)) {
                    return (
                      <Progress
                        percent={parseFloat((get(asgn, 'full_score', 0) <= 0 ? 0 : (get(extraInfo, 'report.finally_score', 0) / get(asgn, 'full_score', 0)) * 100).toFixed(2))}
                        width={20}
                        type="dashboard"
                        strokeLinecap="round"
                        format={() => <CheckOutlined />}
                        status={get(extraInfo, 'report.finally_score', 0) >= get(asgn, 'full_score', 0) ? 'success' : 'active'}
                      />
                    );
                  } if (get(extraInfo, 'report.submitted', false)) {
                    return <span>待批改 <LoadingOutlined /></span>;
                  }
                  return <Progress
                    percent={parseFloat(get(extraInfo, 'process', 0).toFixed(2))}
                    size="small"
                    status={get(extraInfo, 'process', 0) >= 100.0 ? 'success' : 'active'}
                  />;
                })() : <Progress
                  percent={parseFloat(get(extraInfo, 'process', 0).toFixed(2))}
                  success={{ percent: get(extraInfo, 'successProcess', 0) }}
                  size="small"
                  showInfo={false}
                />}
              </div>
              {asgn && get(asgn, 'status.role') === 'student' ? (() => {
                if (get(extraInfo, 'report.teacher_check', false)) {
                  return <div className="progress-label">
                    <Tooltip title="最终得分">
                      {get(extraInfo, 'report.finally_score')}
                    </Tooltip>
                  </div>;
                } if (get(extraInfo, 'report.submitted', false)) {
                  return null;
                }
                return null;
              })() : <div className="progress-label">
                {get(extraInfo, 'checked', 0)} / {get(extraInfo, 'reports', 0)}
              </div>}
            </div>
          </div>
        </div>
        <div className="asgn_content">
          <div className="sider">
            <Menu
              onSelect={this.handleSubChange}
              mode="inline"
              selectedKeys={[this.props.subItemKey]}
              defaultOpenKeys={['asgn_group']}
              defaultSelectedKeys={['asgn']}
            >
              {this.ASGN_MENU.map((item) => {
                if (get(item, 'stuOnly', false) && !isStudent) return null;
                if (get(item, 'manageOnly', false) && !isManage) return null;
                if (get(item, 'desc') === 'divider') {
                  return <Menu.Divider key={item.key} />;
                }
                if (has(item, 'subMenu')) {
                  return <Menu.SubMenu key="asgn_group" title={<span>{item.icon}{item.desc}</span>}>
                    {item.subMenu().map((sitem) => {
                      if (get(sitem, 'stuOnly', false) && !isStudent) return null;
                      if (get(sitem, 'manageOnly', false) && !isManage) return null;
                      return <Menu.Item key={sitem.key}>{sitem.desc}</Menu.Item>;
                    })}
                  </Menu.SubMenu>;
                }
                return <Menu.Item key={item.key}>{item.icon}{item.desc}</Menu.Item>;
              })}
            </Menu>
          </div>
          <div className="content">
            <Switch>
              <Route
                path={AsgnRoutes.OVERVIEW}
                render={props => <AsgnOverviewComponent
                  {...props}
                  courseId={this.props.courseId}
                  asgnId={this.props.asgnId}
                  asgn={this.props.asgn}
                  extraInfo={this.props.extraInfo}
                />}
                exact
              />
              {isStudent && <Route
                path={AsgnRoutes.REPORT}
                render={props => <MyReportView
                  {...props}
                  courseId={this.props.courseId}
                  asgnId={this.props.asgnId}
                  asgn={this.props.asgn}
                />}
                exact
              />}
              {isManage && <Route
                path={AsgnRoutes.CHECKUP_TEACHER}
                render={props => <StudentReportView
                  {...props}
                  courseId={this.props.courseId}
                  asgnId={this.props.asgnId}
                  asgn={this.props.asgn}
                />}
                exact
              />}
              <Route
                path={AsgnRoutes.JUDGE_HISTORY}
                render={props => <JudgeStatusView
                  {...props}
                  courseId={this.props.courseId}
                  asgnId={this.props.asgnId}
                  showOwner
                />}
                exact
              />
              <Route
                path={AsgnRoutes.RANK}
                render={() => <RankListView
                  courseId={this.props.courseId}
                  asgnId={this.props.asgnId}
                  isManage={isManage}
                />}
                exact
              />
              {isManage && <Route
                path={AsgnRoutes.CHECKUP}
                render={() => <ReportListView
                  asgnId={this.props.asgnId}
                  courseId={this.props.courseId}
                  match={this.props.match}
                  history={this.props.history}
                />}
                exact
              />}
              {isManage && <Route
                path={AsgnRoutes.MANAGEMENT}
                render={() => <AsgnManagement
                  asgn={this.props.asgn}
                  asgnId={this.props.asgnId}
                  courseId={this.props.courseId}
                />}
                exact
              />}
              {isManage && <Route
                path={AsgnRoutes.STATISTICS}
                render={() => <AsgnStatistics
                  asgnId={this.props.asgnId}
                  courseId={this.props.courseId}
                />}
                exact
              />}
              {isManage && <Route
                path={AsgnRoutes.CROSS_CHECK}
                render={() => <AsgnCrossCheckView
                  asgnId={this.props.asgnId}
                  courseId={this.props.courseId}
                />}
              />}
              <Route
                path={AsgnRoutes.SUBITEMS}
                render={this.renderProblemsListView}
                exact
              />
              <Route
                render={() => <Redirect
                  to={buildPath(AsgnRoutes.OVERVIEW, null, this.props.match.params)}
                />}
              />
            </Switch>
          </div>
        </div>
      </div>)
      : (
        <Layout className="wj_general_view asgn_problems_list">
          <Content className="wj_layout_content_with_padding">
            <CenterSpin size="large" tip="作业数据加载中..." spinning={this.props.isLoading} />
          </Content>
        </Layout>
      );
  }
}
export default AsgnView;
