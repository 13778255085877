import React from 'react';
import PropTypes from 'prop-types';
import {
  get, noop, set, isEqual 
} from 'lodash';
import {
  Form, Checkbox, Switch, Divider, Row, Col, Button, Radio 
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import { ProblemPermissionEnum, ProblemTypeEnum } from 'scripts/common/enums/problem';
import { enumMap } from 'scripts/common/utils/enum_generator';
import CodeEditor from 'scripts/common/widgets/code_mirror';
import JudgeLanguageCheckBox from 'scripts/common/widgets/language_checkbox';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { CollectionProblemPropTypes } from 'scripts/common/prop-types/collection';
import { MixinPermissionHelper } from 'scripts/common/utils/mixin_calculater';
import { SpecialJudgeEnum } from 'scripts/common/enums/judge';
import LanguageLimitSlider from './language_limit';

@connect(null, dispatch => bindActionCreators({
  saveModifyProblems: CollectionProblemActions.saveModifyProblems,
}, dispatch))
class JudgeConfig extends React.PureComponent {
  static propTypes = {
    collectionProblem: CollectionProblemPropTypes.isRequired,
    problem: ProblemPropTypes.isRequired,
    collectionId: PropTypes.string.isRequired,
    saveModifyProblems: PropTypes.func,
  };

  static defaultProps = {
    saveModifyProblems: noop,
  };

  state = {
    raw: {
      problem: this.props.problem,
      collectionProblem: this.props.collectionProblem,
    },
    problem: {
      id: get(this.props.problem, 'id', ''),
      available: get(this.props.problem, 'available', false),
      permission: get(this.props.problem, 'permission', 7),
      options: JSON.parse(JSON.stringify(this.props.problem.options)),
    },
    collectionProblem: {
      code_language: get(this.props.collectionProblem, 'code_language', 0),
    },
    submitting: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(prevState.raw.problem, nextProps.problem)
      || !isEqual(prevState.raw.collectionProblem, nextProps.collectionProblem)) {
      return {
        problem: {
          id: get(nextProps.problem, 'id', ''),
          available: get(nextProps.problem, 'available', false),
          permission: get(nextProps.problem, 'permission', 7),
          options: JSON.parse(JSON.stringify(nextProps.problem.options)),
        },
        collectionProblem: {
          code_language: get(nextProps.collectionProblem, 'code_language', 0),
        },
        raw: {
          problem: nextProps.problem,
          collectionProblem: nextProps.collectionProblem,
        },
      };
    }
    return null;
  }

  codeEdtior = React.createRef();

  handlePermissionChange = ({ target }) => {
    const helper = new MixinPermissionHelper(this.state.problem.permission);
    if (target.checked) {
      helper.add(target.value);
    } else {
      helper.remove(target.value);
    }
    this.setState({
      problem: set({ ...this.state.problem }, 'permission', helper.mixin),
    });
  };

  handleLanguageChange = ({ target }) => {
    this.setState({
      collectionProblem: {
        code_language: target.value,
      },
    });
  };

  handleJudgeState = ({ target }) => {
    this.setState({
      problem: set({ ...this.state.problem }, 'available', target.value),
    });
  };

  handleSpecialJudgeState = ({ target }) => {
    this.setState({
      problem: set({ ...this.state.problem }, 'options.judge_options.special_judge', target.value),
    });
  };

  handleLanguageLimitChange = language => (key, value) => {
    const relKey = key === 'timeLimit' ? 'time_limit' : 'mem_limit';
    this.setState({
      problem: set({ ...this.state.problem }, `options.judge_options.${relKey}.${language}`, value),
    });
  };

  handleSubmit = () => {
    let problem = {
      ...this.state.problem,
      ...this.state.collectionProblem,
    };
    if (this.props.problem.problem_type === ProblemTypeEnum.CODE.value) {
      problem = set(problem, 'options.judge_options.answer_cases', this.codeEdtior.current.getCodeValue());
    }
    const { id } = problem;
    if (id) {
      this.setState({
        submitting: true,
      }, () => {
        this.props.saveModifyProblems({
          problem,
          collectionId: this.props.collectionId,
          cpId: this.props.collectionProblem.id,
          onComplete: () => {
            this.setState({
              submitting: false,
            });
          },
        });
      });
    }
  };

  renderLanguageLimitSlider = (state) => {
    const { language } = state;
    const { options } = this.state.problem;
    return <LanguageLimitSlider
      timeLimit={get(options, `judge_options.time_limit.${language}`)}
      memLimit={get(options, `judge_options.mem_limit.${language}`)}
      onChange={this.handleLanguageLimitChange(language)}
    />;
  };

  render() {
    const { collectionProblem, problem } = this.state;
    const helper = new MixinPermissionHelper(problem.permission);
    return (<>
      <div className="judge_config_editor">
        <Form.Item>
          <Radio.Group
            value={problem.available}
            onChange={this.handleJudgeState}
            buttonStyle="solid"
          >
            <Radio.Button value>评测开启</Radio.Button>
            <Radio.Button value={false}>评测关闭</Radio.Button>
          </Radio.Group>
          <Radio.Group
            style={{ marginLeft: 32 }}
            onChange={this.handleSpecialJudgeState}
            value={get(problem, 'options.judge_options.special_judge', 0)}
          >
            {enumMap(SpecialJudgeEnum, (e) => {
              return <Radio.Button key={e.value} value={e.value}>{e.desc}</Radio.Button>;
            })}
          </Radio.Group>
        </Form.Item>
        <Divider orientation="left">
          <span className="item_title">可用评测语言（对于当前题库）</span>
        </Divider>
        <JudgeLanguageCheckBox
          allowInherit
          language={collectionProblem.code_language}
          onChange={this.handleLanguageChange}
        />
        <Divider orientation="left">
          <span className="item_title">访问权限</span>
        </Divider>
        <Row>
          {enumMap(ProblemPermissionEnum, (item, key) => {
            return <Col key={key} xs={12} sm={12} md={8} lg={6} xl={4} xxl={4}>
              <Form.Item>
                <Checkbox
                  value={item.value}
                  checked={helper.has(item)}
                  onChange={this.handlePermissionChange}
                >{item.desc}</Checkbox>
              </Form.Item>
            </Col>;
          }, item => item.value < ProblemPermissionEnum.CREATE.value)}
        </Row>
        {this.props.problem.problem_type === ProblemTypeEnum.CODE.value && <>
          <Divider orientation="left">
            <span className="item_title">资源限制与参考代码</span>
          </Divider>
          <CodeEditor
            ref={this.codeEdtior}
            footerControl={false}
            mode="normal"
            editorHeight={400}
            optionsArea={this.renderLanguageLimitSlider}
            defaultValue={get(problem, 'options.judge_options.answer_cases')}
            disablePerferenceSaving
          />
        </>}
      </div>
      <div className="footer-content">
        <Button
          type="primary"
          loading={this.state.submitting}
          onClick={this.handleSubmit}
        >
          保存设置
        </Button>
      </div>
    </>);
  }
}

export default JudgeConfig;
