import React from 'react';
import { Table } from 'antd';
import JudgeFlowImage from 'images/helper/judge_flow.png';
import {
  CODE_SUPPORT,
  CODE_SUPPORT_COLUMNS,
  JUDGE_STATUS,
  JUDGE_STATUS_COLUMNS,
  CODE_EXAMPLE
} from './constant';

class FAQView extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  /* eslint-disable */
  render() {
    return <div className="helper-faq-view">
      <div className="content">
        <h2>1. WeJudge 的代码评测支持哪些语言？有什么需要注意的地方？</h2>
        <p>从3.0版本开始，WeJudge判题机将支持以下主流语言：C、C++、Java、Python、Go、JavaScript(NodeJS)、PHP、Rust等。</p>
        <p>WeJudge判题机运行在Linux平台下，C、C++均使用的是GCC编译器，文本编码格式为UTF-8，换行符为『\n』，路径分割符为『/』。</p>
        <p><strong>评测机设置的编译/运行参数为：</strong></p>
        <Table
          bordered
          size="middle"
          dataSource={CODE_SUPPORT}
          columns={CODE_SUPPORT_COLUMNS}
          pagination={false}
          footer={() => '注：Java语言将自动识别类名，但是单个文件（提交）依然仅允许存在一个公开类。'}
        />
      </div>
      <div className="content">
        <h2>2. 评判结果各个状态分别是什么意思？</h2>
        <Table
          bordered
          size="middle"
          dataSource={JUDGE_STATUS}
          columns={JUDGE_STATUS_COLUMNS}
          pagination={false}
          footer={() => '注：严格模式一般在题库中开启，其他模块中判题机默不启用严格模式，但可能因题目设置而开启。'}
        />
      </div>
      <div className="content">
        <h2>3. 为什么我的程序在VC++/VC下能正常编译，但是在WeJudge上使用G++/GCC就会出现Compilation Error？</h2>
        <p>C语言、C++语言不具有跨平台特性，由于在不同操作系统中，系统API、动态链接库或编译环境不相同，通常会出现函数缺失的现象。常见的问题如：</p>
        <ol type="a">
          <li>在G++/GCC下 main 函数必须定义成int型，定义成 void main 会得到Compilation Error。</li>
          <li>在G++/GCC中itoa不是一个标准的ANSI函数。</li>
          <li>
            GNU C/C++ Compiler环境下，请使用long long替代__int64（或者#define __int64 long long)，使用%lld替代%I64d
          </li>
          <li>atoi()不是C语言的标准支持函数，在GNU GCC环境下不支持。</li>
          <li>scanf_s 为Visual Studio 2005以上版本提供的安全输入函数，GNU GCC环境不支持。</li>
        </ol>
      </div>
      <div className="content">
        <h2>4. 在线判题的流程是什么样的？</h2>
        <img src={JudgeFlowImage} alt="judge_flow" />
        <p>系统编译用户提交的源代码，运行程序并注入测试数据，然后等待程序应答输出结果并结束。 程序运行结束后，再和标准结果进行比较，比较包括字符、数字、标点符号以及空格、换行符等， 若输出结果与参考答案数据严格相同，则判定为通过（Accepted）。如果程序输出的内容和预设的数据不能正确匹配， 则会判为答案错误等。如果程序代码不能被正确编译，则报编译错误。评测结束后，结果数据将被保存在系统的数据库中以便向用户反馈。</p>
      </div>
      <div className="content">
        <h2>5. 程序怎样取得输入、进行输出? 什么是多组测试数据？什么是EOF？</h2>
        <p>WeJudge判题机在判题过程中会将题目的每一组测试单元的【Input文件流】链接到stdin，将stdout重定向到用户的【Output文件流】 因此，你的程序应该从标准输入流stdin获取输出，并将结果输出到标准输出流stdout中。 对于交互评测的题目，在每次输出内容到stdout后，请刷新stdout的缓冲区，以便让判题程序能够正确的读取到你的回答。</p>
        <p>WeJudge系统规定，每个题目可以存在多个测试数据单元（文件），每个测试单元可以存在多组测试数据。 当存在多组测试数据的时候，你的程序应当不停的从stdin中获取测试数据，直到达到文件末端EOF（End of File）。</p>
        <p>幸运的是，如果你使用了语言封装的标准流操作函数， 如：C语言的scanf、printf等；C++语言的cin、cout等；Java语言的System.out，Scanner等；python语言的input()、print()等， 你将很方便的能够得知当前的读写是否到达了EOF。你可以借助循环语句来连续读取数据和计算结果，并在抵达EOF后终止循环，退出程序。</p>
        <p>下面是『A+B』问题的标准代码，希望能够给你一些灵感</p>
        {CODE_EXAMPLE.map((item) => {
          return <code key={item.name}>
            <strong>{item.name}</strong>
            <pre>{item.code}</pre>
          </code>
        })}
      </div>
    </div>;
  }
  /* eslint-enable */
}

export default FAQView;
