import 'styles/education/asgn/management.scss';

import React from 'react';
import { noop, set } from 'lodash';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';

import AsgnInfoEditor from './asgn_editor';

const mapDiapatchToProps = dispatch => bindActionCreators({
  editAsgn: AsgnActions.editAsgn,
  getAsgnsList: AsgnActions.getAsgnsList,
}, dispatch);

@withRouter
@connect(null, mapDiapatchToProps)
class EditAsgnView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    handleChange: PropTypes.func,
    asgnId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    asgn: AsgnPropTypes.isRequired,
    editAsgn: PropTypes.func,
  };

  static defaultProps = {
    handleChange: noop,
    editAsgn: noop,
  };

  state = {
    asgn: this.props.asgn ? this.props.asgn : {},
  };

  handleSubmit = () => {
    const data = {
      courseId: this.props.courseId,
      asgnId: this.props.asgnId,
      ...this.state,
    };
    this.props.editAsgn(data);
  };

  render() {
    return (
      <div className="asgn_edit_view">
        <AsgnInfoEditor
          asgn={this.state.asgn}
          handleChange={(asgn) => { this.setState({ asgn }); }}
        />
        <div className="submit_btn">
          <Button
            type="primary"
            onClick={this.handleSubmit}
          >
            保存修改
          </Button>
        </div>
      </div>
    );
  }
}

export default EditAsgnView;
