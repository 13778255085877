import 'styles/contest/organization/manage.scss';

import React from 'react';
import {  withRouter } from 'react-router-dom';
import { Button, Table, Alert } from 'antd';
import { get, isEqual, pick } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import {
  ContestOrganizationActions,
  ContestOrganizationSelectors
} from 'scripts/common/logic/contest/organization';
import { ContestRoutes } from 'scripts/apps/routes';
import { PaginationParams } from 'scripts/common/constants/global';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';

import ContestLoginRequire from '../contest_auth';
import { withContestContext } from '../contest_provider';
import TeamDrawer from './widgets/team_drawer';
import { AntTableMiddleOutsideHeight } from 'scripts/common/constants/table';
import ReactResizeDetector from 'react-resize-detector';

const LOADING_STATUS_KEY = 'Organization_list_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrganizationList: ContestOrganizationActions.getOrganizationList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getOrganizationStatus: ContestOrganizationActions.getOrganizationStatus,
  getOrganizationGroupList: ContestOrganizationActions.getOrganizationGroupList,
}, dispatch);
const mapStateToProps = (state, props) => {
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const queryset = getQuery(props.location);
  const pager = ContestOrganizationSelectors.contestOrganizationListViewPagerSelector(state);
  const status = ContestOrganizationSelectors.contestOrganizationStatusSelector(state);
  const teamGroups = ContestOrganizationSelectors.contestOrganizationGroupListSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page']),
  };
  const currentRoutePath = ContestRoutes.ORGANIZATION.LIST;
  const organizationList = ContestOrganizationSelectors.organizationListData(state);
  return {
    status,
    isLoading,
    teamGroups,
    queryParams,
    paginationParams: pager || props.paginationParams,
    organizationList,
    currentRoutePath,
  };
};

@ContestLoginRequire
@withContestContext
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class OrganizationListView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const query = pick(
      nextProps.queryParams,
      ['limit', 'page']
    );
    nextProps.getOrganizationList({
      query,
      contestId: nextProps.contest.id,
      onComplete: () => {
        nextProps.getOrganizationStatus({
          contestId: nextProps.contest.id,
          onComplete: () => {
            nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
          },
        });
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    status: PropTypes.shape({}),
    isLoading: PropTypes.bool,
    getOrganizationList: PropTypes.func.isRequired,
    setLoadingStatus: PropTypes.func.isRequired,
    getOrganizationStatus: PropTypes.func.isRequired,
    getOrganizationGroupList: PropTypes.func.isRequired,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})),
    paginationParams: PaginationPropTypes,
  };

  static defaultProps = {
    status: {},
    isLoading: false,
    teamGroups: [],
    paginationParams: { ...PaginationParams },
  };

  state = {
    drawerVisible: false,
    queryParams: null,
    tableHeight: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      OrganizationListView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.getOrganizationGroupList({
      contestId: this.props.contest.id,
    });
  }

  LIST_COLUMNS = [{
    title: '#',
    dataIndex: 'teamid',
    key: 'teamid',
    width: 100,
  }, {
    title: '队名',
    dataIndex: 'nickname',
    key: 'nickname',
  }, {
    title: '分组',
    dataIndex: 'group',
    key: 'group',
    align: 'center',
    width: 220,
  }, {
    title: '队长',
    dataIndex: 'leader',
    key: 'leader',
    align: 'center',
    width: 100,
  }, {
    title: '人数',
    dataIndex: 'numbers',
    key: 'numbers',
    align: 'center',
    width: 80,
  }, {
    title: '开放性',
    dataIndex: 'teampublic',
    key: 'teampublic',
    align: 'center',
    width: 80,
  }, {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 100,
  }];

  createTeam = () => {
    this.setState({
      drawerVisible: true,
      drawerOptions: {
        teamId: null,
        detail: false,
      },
    });
  };

  getGroupName = (gid) => {
    const rel = this.props.teamGroups.find(item => get(item, 'id') === gid);
    return get(rel, 'name', '未分组');
  };

  showTeamDetail = (item) => {
    this.setState({
      drawerVisible: true,
      drawerOptions: {
        teamId: item.id,
        detail: true,
      },
    });
  };

  handleDrawerClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  renderListData = () => {
    if (!this.props.organizationList) return null;
    return this.props.organizationList.map((item) => {
      return ({
        key: get(item, 'id'),
        teamid: get(item, 'id'),
        nickname: get(item, 'nickname'),
        group: this.getGroupName(get(item, 'group_id')),
        leader: get(item, 'leader.realname'),
        numbers: `${get(item, 'members', []).length} / 3`,
        teampublic: get(item, 'public') ? '公开队伍' : '需要密码',
        action: <Button
          size="small"
          type={get(this.props.status, 'team_id') === get(item, 'id') ? 'primary' : 'default'}
          onClick={() => this.showTeamDetail(item)}
        >
          查看队伍
        </Button>,
      });
    });
  };

  render() {
    const data = this.renderListData();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 40),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.handlePageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };

    return (
      <div className="contest-organization-list-view">
        {!this.props.isLoading && (!get(this.props.status, 'joined', false) ? <div className="action-bar">
          <Button type="primary" onClick={this.createTeam}>创建队伍</Button>
        </div> : <Alert
          showIcon
          message={<span><strong>已加入队伍：</strong>{get(this.props.status, 'team_name')}</span>}
          type="success"
          style={{ marginBottom: 16 }}
        />)}
        <div className="content_layer">
          <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
          <Table
            bordered
            loading={this.props.isLoading}
            columns={this.LIST_COLUMNS}
            dataSource={data}
            pagination={optPagination}
            size="middle"
            scroll={{
              y: this.state.tableHeight - AntTableMiddleOutsideHeight,
              scrollToFirstRowOnChange: true,
            }}
          />
        </div>
        <TeamDrawer
          status={this.props.status}
          visible={this.state.drawerVisible}
          onClose={this.handleDrawerClose}
          teamGroups={this.props.teamGroups}
          emitRefeshList={() => OrganizationListView.fetchList(this.props)}
          {...this.state.drawerOptions}
        />
      </div>

    );
  }
}
export default OrganizationListView;
