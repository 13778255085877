import React from 'react';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import { Avatar, List } from 'antd';
import {
  UserOutlined, DashboardOutlined, TeamOutlined, UnlockOutlined, LockOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { CourseSelectors } from 'scripts/common/logic/education/course';
import CourseThumbImage from 'images/course_thumb.jpg';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import {
  buildAvatarPath,
  buildPath,
  buildResourcePath
} from 'scripts/common/utils/location_helper';
import IconText from 'scripts/common/widgets/icon-text';
import { CourseTypeEnum } from 'scripts/common/enums/course';
import { renderCourseStatus } from 'scripts/apps/education/course/widgets/course_card';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { AccountRoutes } from 'scripts/apps/routes';
import { withRouter } from 'react-router';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountPropTypes } from 'scripts/common/prop-types/account';

/*
* CourseCard
* */
const CourseListItemBase = (props) => {
  const authorId = get(props.course, 'author.id');
  const authorRole = get(props.course, 'author.role');
  const hasViewPermission = get(props.course, 'permissions_calculated.1', false);
  const thumbImage = get(props.course, 'thumb_img', '');
  const isTeacher = [
    AccountRoleEnum.TEACHER.value,
    AccountRoleEnum.DEAN.value
  ].indexOf(authorRole) > 0;
  const authorName = isTeacher ? `${get(props.course, 'author.realname')}老师` : get(props.course, 'author.nickname');
  const authorAvatar = get(props.course, 'author.avator');
  const studentCounter = `${get(props.course, 'student', 0)}人`;
  const courseType = CourseTypeEnum.enumValueOf(get(props.course, 'type'));
  const typeIcon = courseType === CourseTypeEnum.PUBLIC ? <UnlockOutlined /> : <LockOutlined />;

  return <List.Item
    actions={[
      <IconText
        key="list-item-author"
        icon={<Avatar
          className="user-avator"
          icon={<UserOutlined />}
          size={16}
          src={buildAvatarPath(authorAvatar)}
        />}
        onClick={authorId ? () => {
          const { history } = props;
          history.push(buildPath(AccountRoutes.SPACE.INDEX, { accountId: authorId }));
        } : null}
        text={authorName}
      />,
      <IconText
        key="list-item-counter"
        icon={typeIcon}
        text={get(courseType, 'desc', '未知')}
      />,
      <IconText
        key="list-item-counter"
        icon={<TeamOutlined />}
        text={studentCounter}
      />,
      <IconText
        key="list-item-status"
        icon={<DashboardOutlined />}
        text={renderCourseStatus(props.course, props.account, hasViewPermission)}
      />
    ]}
    extra={
      <img
        height={102}
        alt="logo"
        src={thumbImage ? buildResourcePath(thumbImage) : CourseThumbImage}
      />
    }
  >
    <List.Item.Meta
      title={<a onClick={props.onClick}>{get(props.course, 'name')}</a>}
      description={<RichtextContext
        textMode
        truncateLength={30}
        viewEmpty="暂无简介"
        content={get(props.course, 'description')}
      />}
    />
  </List.Item>;
};

CourseListItemBase.propTypes = {
  ...RoutePropTypes,
  account: AccountPropTypes,
  course: CoursePropTypes,
  onClick: PropTypes.func,
};

CourseListItemBase.defaultProps = {
  onClick: noop,
  account: null,
  course: null,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const course = id ? CourseSelectors.getCourseEntity(state)(id) : null;
  return {
    course,
  };
};

export const CourseListItem = connect(mapStateToProps, null)(
  withRouter(CourseListItemBase)
);
export default CourseListItem;
