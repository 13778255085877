import CryptoJS from 'crypto-js';
import CRC32, { decimalToHexString } from './crc32';

const noop = function () {};

class FileHashProcessor {
  file = null;

  static swapEndian32 = (val) => {
    return (((val & 0xFF) << 24)
      | ((val & 0xFF00) << 8)
      | ((val >> 8) & 0xFF00)
      | ((val >> 24) & 0xFF)) >>> 0;
  };

  static arrayBufferToWordArray = (arrayBuffer) => {
    const fullWords = Math.floor(arrayBuffer.byteLength / 4);
    const bytesLeft = arrayBuffer.byteLength % 4;

    const u32 = new Uint32Array(arrayBuffer, 0, fullWords);
    const u8 = new Uint8Array(arrayBuffer);

    const cp = [];
    for (let i = 0; i < fullWords; ++i) {
      cp.push(FileHashProcessor.swapEndian32(u32[i]));
    }

    if (bytesLeft) {
      let pad = 0;
      for (let i = bytesLeft; i > 0; --i) {
        pad <<= 8;
        pad += u8[u8.byteLength - i];
      }

      for (let i = 0; i < 4 - bytesLeft; ++i) {
        pad <<= 8;
      }

      cp.push(pad);
    }

    return CryptoJS.lib.WordArray.create(cp, arrayBuffer.byteLength);
  };

  constructor(file) {
    if (!file) throw new Error('文件不能为空');
    this.file = file;
  }

  progressiveRead = (start = 0, end = 0, progress = noop) => new Promise((resolve, reject) => {
    if (start < 0) reject(new Error('参数错误'));
    if (!FileReader) {
      reject(new Error('浏览器版本过低，不支持此操作'));
    }
    const chunkSize = 204800; // 20KiB at a time
    const totalSize = end > 0 ? Math.min(end, this.file.size) : this.file.size;
    let pos = Math.max(0, start);
    const reader = new FileReader();

    const progressiveReadNext = () => {
      try {
        const end = Math.min(pos + chunkSize, totalSize);

        reader.onload = (e) => {
          pos = end;
          progress(e.target.result, pos - start, totalSize - start);
          if (pos < totalSize) {
            progressiveReadNext();
          } else {
            resolve();
          }
        };

        if (this.file.slice) {
          reader.readAsArrayBuffer(this.file.slice(pos, end));
        } else if (this.file.webkitSlice) {
          reader.readAsArrayBuffer(this.file.webkitSlice(pos, end));
        } else {
          reject('浏览器版本过低，不支持此操作');
        }
      } catch (e) {
        reject(e);
      }
    };

    progressiveReadNext();
  });

  SHA1 = (process = noop) => {
    return new Promise((resolve, reject) => {
      try {
        const sha1 = CryptoJS.algo.SHA1.create();
        this.progressiveRead(0, -1, (data, pos, total) => {
          try {
            sha1.update(FileHashProcessor.arrayBufferToWordArray(data));
            process && process(pos / total);
          } catch (e) {
            reject(e);
          }
        }).then(() => {
          const hash = sha1.finalize().toString(CryptoJS.enc.Hex);
          resolve(hash);
        });
      } catch (e) {
        reject(e);
      }
    });
  };

  CRC32 = (start = 0, end = 0, process = noop) => {
    let crc32result = 0;
    return new Promise((resolve, reject) => {
      this.progressiveRead(start, end, (data, pos, total) => {
        try {
          crc32result = CRC32(new Uint8Array(data), crc32result);
          process && process(pos / total);
        } catch (e) {
          reject(e);
        }
      }).then(() => {
        resolve(decimalToHexString(crc32result));
      });
    });
  }
}

export default FileHashProcessor;
