import { isArray, isEmpty } from 'lodash';

export const deepFindCategory = (targetId, tree) => {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id.toString() === targetId) {
      return {
        id: tree[i].id.toString(),
        entity: tree[i],
        parent: null,
      };
    }
    if (isArray(tree[i].children) && !isEmpty(tree[i].children)) {
      const ret = deepFindCategory(targetId, tree[i].children);
      if (ret) {
        return {
          ...ret,
          parent: tree[i],
        };
      }
    }
  }
  return null;
};
