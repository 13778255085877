import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { get, noop, set } from 'lodash';
import {
  Form, Button, Col, Drawer, Row, Select, Input, DatePicker 
} from 'antd';
import { formatTime } from 'scripts/common/utils/time_formatter';
import JudgeLanguageCheckBox from 'scripts/common/widgets/language_checkbox';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { ContestTypeEnum, RegisterTypeEnum, ScheduleTypeEnum } from 'scripts/common/enums/contest';
import { bindActionCreators } from 'redux';
import { ContestActions } from 'scripts/common/logic/contest/contest';
import { connect } from 'react-redux';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ContestRoutes } from 'scripts/apps/routes';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { withRouter } from 'react-router-dom';

const { Option } = Select;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const mapDispatchToProps = dispatch => bindActionCreators({
  createContest: ContestActions.createContest,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
}, dispatch);

@withRouter
@connect(null, mapDispatchToProps)
class CreateContestView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    createContest: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    onClose: noop,
    createContest: noop,
    viewSuccessMessage: noop,
  };

  state = {
    contest: {
      title: '',
      sponsor: '',
      start_time: moment().format('X'),
      end_time: moment().add(5, 'hours').format('X'),
      code_language: 0,
      contest_type: 1,
      team_type: 1,
      schedule_type: 1,
      register_mode: 1,
    },
    submitting: false,
  };

  onSubmitClick = () => {
    this.setState({
      submitting: true,
    }, () => {
      this.props.createContest({
        ...this.state.contest,
        onSuccess: (cid) => {
          this.props.onClose();
          this.setState({
            contest: {
              title: '',
              sponsor: '',
              start_time: moment().format('X'),
              end_time: moment().add(5, 'hours').format('X'),
              code_language: 0,
              contest_type: 1,
              team_type: 1,
              schedule_type: 1,
              register_mode: 1,
            },
          });
          this.props.viewSuccessMessage({
            message: '比赛创建成功！页面将自动跳转到比赛设置，请稍等...',
            onClose: () => {
              const { history } = this.props;
              history.push(buildPath(
                ContestRoutes.MANAGE.CONTEST,
                { contestId: cid }
              ));
            },
          });
        },
        onError: () => {
          this.setState({
            submitting: false,
          });
        },
      });
    });
  };

  handleSingleDateChange = (date) => {
    const start = date.format('X');
    const end = date.clone().add(5, 'hours').format('X');
    const cfg = set({ ...JSON.parse(JSON.stringify(this.state.contest)) }, 'start_time', parseFloat(start));
    this.setState({
      changed: true,
      contest: set({ ...cfg }, 'end_time', parseFloat(end)),
    });
  };

  handleDateChange = (dates, dateString) => {
    const start = dates[0].format('X');
    const end = dates[1].format('X');
    this.setState({
      contest: {
        ...this.state.contest,
        start_time: parseFloat(start),
        end_time: parseFloat(end),
      },
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      contest: {
        ...this.state.contest,
        [keyName]: target.value,
      },
    });
  };

  handleSelectChange = keyName => (value) => {
    this.setState({
      contest: {
        ...this.state.contest,
        [keyName]: value,
      },
    });
  };

  render() {
    const fiveHoursCombat = get(this.state, 'contest.schedule_type', 1) === ScheduleTypeEnum.FIVE_HOURS.value;
    return <Drawer
      title="创建比赛"
      onClose={this.props.onClose}
      visible={this.props.visible}
      width={520}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="比赛名称">
            <Input
              required
              value={this.state.contest.title}
              onChange={this.handleInputChange('title')}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="主办方">
            <Input
              value={this.state.contest.sponsor}
              onChange={this.handleInputChange('sponsor')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="参赛形式">
            <Select
              value={this.state.contest.team_type}
              placeholder="请选择参赛形式"
              onChange={this.handleSelectChange('team_type')}
              style={{ width: '100%' }}
            >
              {enumMap(RegisterTypeEnum, (e) => {
                return <Option key={e.value} value={e.value}>{e.desc}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="赛制">
            <Select
              value={this.state.contest.schedule_type}
              placeholder="请选择赛制"
              onChange={this.handleSelectChange('schedule_type')}
              style={{ width: '100%' }}
            >
              {enumMap(ScheduleTypeEnum, (e) => {
                return <Option key={e.value} value={e.value}>{e.desc}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="比赛类型">
            <Select
              value={this.state.contest.contest_type}
              placeholder="请选择比赛类型"
              onChange={this.handleSelectChange('contest_type')}
              style={{ width: '100%' }}
            >
              {enumMap(ContestTypeEnum, (e) => {
                return <Option key={e.value} value={e.value}>{e.desc}</Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={20}>
          <Form.Item label={fiveHoursCombat ? '开始时间' : '比赛时间'}>
            {fiveHoursCombat ? <DatePicker
              style={{ width: '100%' }}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: moment('00:00:00', 'HH:mm:ss'),
              }}
              defaultValue={moment(formatTime(get(this.state, 'contest.start_time', 0), 'LONG_DATETIME'), dateFormat)}
              format="YYYY-MM-DD HH:mm:ss"
              onChange={this.handleSingleDateChange}
            /> : <DatePicker.RangePicker
              style={{ width: '100%' }}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')],
              }}
              onChange={this.handleDateChange}
            />}
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="可用评测语言">
        <JudgeLanguageCheckBox
          language={this.state.contest.code_language}
          onChange={this.handleInputChange('code_language')}
        />
      </Form.Item>
      <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        <Button
          loading={this.state.submitting}
          disabled={!this.state.contest.title || this.state.submitting}
          onClick={this.onSubmitClick}
          type="primary"
        >
          确定
        </Button>
      </div>
    </Drawer>;
  }
}

export default CreateContestView;
