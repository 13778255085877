import React from 'react';
import PropTypes from 'prop-types';
import {
  get, isEqual, noop, pick 
} from 'lodash';
import {
  Form, Input, InputNumber, Divider, Button 
} from 'antd';
import { SchoolPropTypes } from 'scripts/common/prop-types/school';
import { bindActionCreators } from 'redux';
import { SchoolActions, SchoolSelectors } from 'scripts/common/logic/education/school';
import { AccountSelectors, AccountActions } from 'scripts/common/logic/account';
import { store } from 'scripts/common/logic/store';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import AccountPicker from 'scripts/apps/account/widgets/account_picker';

const { TextArea } = Input;

const mapDispatchToProps = dispatch => bindActionCreators({
  addSchool: SchoolActions.addSchool,
  editSchool: SchoolActions.editSchool,
  getAccountEntity: AccountActions.getAccountEntity,
}, dispatch);

const mapStateToProps = (state, props) => {
  const school = SchoolSelectors.getSchoolEntity(state)(props.schoolId) || {};
  return {
    school,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class SchoolEditor extends React.PureComponent {
  static propTypes = {
    schoolId: PropTypes.number.isRequired,
    school: SchoolPropTypes,
    onClose: PropTypes.func,
    addSchool: PropTypes.func,
    editSchool: PropTypes.func,
    getAccountEntity: PropTypes.func,
  };

  static defaultProps = {
    school: {},
    onClose: noop,
    addSchool: noop,
    editSchool: noop,
    getAccountEntity: noop,
  };

  state = {
    focusMasterEditor: false,
    master_administrator: '',
    schoolRaw: {},
    school: {},
    processing: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.school, prevState.schoolRaw)) {
      return {
        master_administrator: get(nextProps.school, 'master_administrator.id'),
        school: JSON.parse(JSON.stringify(nextProps.school)),
        schoolRaw: JSON.parse(JSON.stringify(nextProps.school)),
      };
    }
    return null;
  }

  handleEditOrAddSchool = () => {
    this.setState({
      processing: true,
    }, () => {
      const func = this.props.schoolId ? this.props.editSchool : this.props.addSchool;
      func({
        school: {
          ...pick(this.state.school, [
            'name', 'short_name', 'description', 'repository_size'
          ]),
          master_administrator: this.state.master_administrator || '-1',
        },
        schoolId: this.props.school.id,
        onSuccess: () => {
          this.props.onClose(!this.props.schoolId);
        },
        onComplete: () => {
          this.setState({
            processing: false,
          });
        },
      });
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState(update(this.state, {
      school: {
        [keyName]: {
          $set: target.value,
        },
      },
    }));
  };

  handleInputChangeNotarget = keyName => (value) => {
    this.setState(update(this.state, {
      school: {
        [keyName]: {
          $set: value,
        },
      },
    }));
  };

  handleSelectMaster = master => new Promise((resolve, reject) => {
    this.props.getAccountEntity({
      accountId: master.id,
      onSuccess: () => {
        this.setState({
          master_administrator: master.id,
        }, () => {
          resolve();
        });
      },
      onError: () => { reject(); },
    });
  });

  renderSchoolEditArea = () => {
    const { school } = this.state;
    const master =      AccountSelectors.getAccountEntity(store.getState())(this.state.master_administrator) || null;
    return (
      <div className="school-editor">
        <Form.Item>
          <div className="editor-title">
            学校名称：
          </div>
          <Input
            size="large"
            key="name"
            value={get(school, 'name', '')}
            onChange={this.handleInputChange('name')}
            placeholder="学校名称"
          />
        </Form.Item>
        <Form.Item>
          <div className="editor-title">
            学校缩写：
          </div>
          <Input
            size="large"
            key="short_name"
            value={get(school, 'short_name', '')}
            onChange={this.handleInputChange('short_name')}
            placeholder="学校缩写"
          />
        </Form.Item>
        <Form.Item>
          <div className="editor-title">
            学校描述
          </div>
          <TextArea
            key="description"
            rows={6}
            value={get(school, 'description', '')}
            onChange={this.handleInputChange('description')}
            placeholder="学校描述"
          />
        </Form.Item>
        <Form.Item>
          <div className="editor-title">
            资源仓库最大容量
          </div>
          <InputNumber
            style={{ width: '80%' }}
            key="repository_size"
            value={get(school, 'repository_size', 10737418240)}
            onChange={this.handleInputChangeNotarget('repository_size')}
          /> Byte
        </Form.Item>
        <Form.Item>
          <div className="editor-title">
            学校管理员：{master ? `${get(master, 'nickname', '')} (${get(master, 'email', '')})` : '无'}
            &nbsp;{master && <a onClick={() => this.setState({ master_administrator: '' })}>移除</a>}
          </div>
          <AccountPicker
            placeholder="通过Email查询并修改"
            handleSelected={this.handleSelectMaster}
          />
        </Form.Item>
        <Divider />
        <div>
          <Button
            loading={this.state.processing}
            type="primary"
            onClick={this.handleEditOrAddSchool}
          >
            {this.props.schoolId ? '保存' : '新增'}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    return <div className="school-editor">
      {this.renderSchoolEditArea()}
    </div>;
  }
}

export default SchoolEditor;
