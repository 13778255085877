import 'styles/collection/problems_list.scss';
import 'styles/common/layout/general_view.scss';

import React from 'react';
import { pick, get, indexOf } from 'lodash';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  withRouter, Route, Switch, matchPath, Redirect 
} from 'react-router-dom';
import { Layout, Spin, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { CollectionActions, CollectionSelectors } from 'scripts/common/logic/collection';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { CollectionRoutes, CollectionViewsRoutes } from 'scripts/apps/routes';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { CollectionPropTypes } from 'scripts/common/prop-types/collection';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { isWeJudgeUUID, createPermissionChecker } from 'scripts/common/utils/validator';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import { CollectionPermissionEnum } from 'scripts/common/enums/collection';
import DataKeys from 'scripts/common/constants/data_keys';

import CollectionManagement from './management';
import ProblemsListSider, { NOT_CLASSIFY_ID } from './widgets/sider_menu';
import ProblemsListContent from './problems/problems_list';
import JudgeStatusView from './judge_status/judge_status_list';

const { Sider, Content } = Layout;

const mapDispatchToProps = dispatch => bindActionCreators({
  getCollectionEntity: CollectionActions.getCollectionEntity,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const query = getQuery(location);
  const matchItem = matchPath(
    location.pathname,
    { path: CollectionViewsRoutes.SUBITEMS, exact: true }
  );
  let itemKey = get(matchItem, 'params.subItemName', 'root');
  if (itemKey === 'problems') itemKey = 'root';
  const subItemKey = (indexOf(NOT_CLASSIFY_ID, itemKey) === -1) ? '' : itemKey;
  const classifyId = (indexOf(NOT_CLASSIFY_ID, itemKey) === -1) ? get(query, 'classify_id', 'root') : 'root';
  const collectionId = get(match, 'params.collectionId', '');
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(
    state, DataKeys.LOADING_STATUS_KEY.COLLECTION_VIEW
  );
  const collection = CollectionSelectors.getCollectionEntity(state)(collectionId);
  const collectionPermission = createPermissionChecker(get(collection, 'permissions_calculated', {}));
  const canManageCollection = collectionPermission(CollectionPermissionEnum.MANAGE);
  return {
    subItemKey,
    classifyId,
    isLoading,
    collection,
    collectionId,
    canManageCollection,
  };
};

@LoginRequire
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CollectionView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    isLoading: PropTypes.bool,
    subItemKey: PropTypes.string.isRequired,
    classifyId: PropTypes.string.isRequired,
    collection: CollectionPropTypes,
    collectionId: PropTypes.string.isRequired,
    getCollectionEntity: PropTypes.func.isRequired,
    setLoadingStatus: PropTypes.func.isRequired,
    canManageCollection: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isLoading: true,
    collection: null,
  };

  state = {
    hasFetchEntity: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isWeJudgeUUID(nextProps.collectionId)) {
      nextProps.history.push(buildPath(CollectionRoutes.LIST));
      return null;
    }
    if (!prevState.hasFetchEntity) {
      nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COLLECTION_VIEW, true);
      nextProps.getCollectionEntity({
        id: nextProps.collectionId,
        onError: () => {
          nextProps.history.push(buildPath(CollectionRoutes.LIST));
        },
        onComplete: () => {
          nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COLLECTION_VIEW, false);
        },
      });
      return {
        hasFetchEntity: true,
      };
    }
    return null;
  }

  componentDidUpdate() {
    // if (this.state.hasFetchEntity && !this.props.isLoading && !this.props.collection) {
    //   this.props.history.push(buildPath(CollectionRoutes.LIST));
    // }
  }

  handleClassifyChange = (key) => {
    const { history, match, location } = this.props;
    const query = (matchPath(location.pathname, {
      path: CollectionViewsRoutes.PROBLEMS_LIST,
      exact: true,
    }))
      ? pick(getQuery(location), ['limit', 'keyword', 'type', 'tags']) : null;
    history.push(buildPath(
      CollectionViewsRoutes.PROBLEMS_LIST,
      null,
      match.params,
      {
        ...query,
        classify_id: key,
      }
    ));
  };

  handlePageChange = (key) => {
    const { history, match } = this.props;
    const PAGE_MAPPING = {
      judge_status: CollectionViewsRoutes.JUDGE_STATUS,
      statistics: CollectionViewsRoutes.STATISTICS,
      management: CollectionViewsRoutes.MANAGEMENT,
    };
    history.push(buildPath(
      get(PAGE_MAPPING, key, CollectionViewsRoutes.PROBLEMS_LIST),
      null,
      match.params
    ));
  };

  render() {
    const { collection } = this.props;
    
    return collection ? (
      <Layout className="wj_general_view collection_problems_list">
        <Sider className="left_sider" width={200}>
          <div className="sider_title">
            <Tooltip title={get(collection, 'title')}>{collection.title}</Tooltip>
          </div>
          <ProblemsListSider
            subItemKey={this.props.subItemKey}
            classifyId={this.props.classifyId}
            collectionId={this.props.collectionId}
            onClassifyChange={this.handleClassifyChange}
            onPageChange={this.handlePageChange}
            canManageCollection={this.props.canManageCollection}
          />
        </Sider>
        <Content>
          <Switch>
            <Route
              path={CollectionViewsRoutes.PROBLEMS_LIST}
              render={() => <ProblemsListContent
                canManageCollection={this.props.canManageCollection}
                collectionId={this.props.collectionId}
              />}
            />
            <Route
              path={CollectionViewsRoutes.JUDGE_STATUS}
              render={props => <JudgeStatusView
                {...props}
                showOwner
                collectionId={this.props.collectionId}
              />}
              exact
            />
            {this.props.canManageCollection && <Route
              path={CollectionViewsRoutes.MANAGEMENT}
              render={() => <CollectionManagement collection={this.props.collection} />}
              exact
            />}
            <Route
              render={() => <Redirect
                to={buildPath(CollectionViewsRoutes.PROBLEMS_LIST, null, this.props.match.params)}
              />}
            />
          </Switch>
        </Content>
      </Layout>)
      : (
        <Layout className="wj_general_view collection_problems_list">
          <Content className="wj_layout_content_with_padding">
            <Spin
              size="large"
              tip="加载中..."
              spinning={this.props.isLoading}
            />
          </Content>
        </Layout>
      );
  }
}
export default CollectionView;
