import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  List, Avatar, Button, Select, Tag, Space
} from 'antd';
import { AutoSizer, List as VList } from 'react-virtualized';
import { ContestJudgeStatusActions } from 'scripts/common/logic/contest/judge_status';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactResizeDetector from 'react-resize-detector';
import { ContestProblemSelectors, ContestSelectors } from 'scripts/common/logic/contest';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { ReloadOutlined } from '@ant-design/icons';

const JudgeSolutionsView = (props) => {
  const [solutions, setSolutions] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [problemFilter, setProblemFilter] = useState(0);
  const [second, setSecond] = useState(30);
  let refreshTimer = null;

  const loadJudgeSolutions = () => {
    props.getContestJudgeSolutions({
      contestId: props.contestId,
      onSuccess: (rel) => {
        setSolutions(get(rel, 'solutions', []));
      },
    });
  };

  useEffect(() => {
    loadJudgeSolutions();
    refreshTimer = setInterval(() => {
      setSecond((ss) => {
        let s = ss - 1;
        if (s < 0) {
          loadJudgeSolutions();
          s = 30;
        }
        return s;
      });
    }, 1000);
    return () => {
      if (refreshTimer) {
        clearInterval(refreshTimer);
        refreshTimer = null;
      }
    };
  }, []);

  const handleContainerHeightChange = (width, height) => {
    setTableHeight(height);
  };

  const handleRefresh = () => {
    loadJudgeSolutions();
    setSecond(30);
  };

  const solutionsList = (problemFilter > 0) ? solutions.filter((item) => {
    return get(item, 'cproblem_id', 0) === problemFilter;
  }) : solutions;

  const renderItem = ({ index, key, style }) => {
    const item = solutionsList[index];
    const isAc = get(item, 'accepted', 0) > 0;
    return (
      <List.Item key={key} style={style}>
        <List.Item.Meta
          avatar={<Avatar style={{ backgroundColor: get(item, 'color', '#acacac') }}>{get(item, 'cproblem_index')}</Avatar>}
          title={<span>{get(item, 'nickname')}({get(item, 'username')})</span>}
        />
        <div>
          <Tag color={isAc ? '#52c41a' : '#f5222d'}>{isAc ? '已通过' : '未通过'}</Tag>
        </div>
      </List.Item>
    );
  };

  return <div className="judge_solution_view">
    <div className="action-bar">
      <Space>
        <Select
          placeholder="选择题目添加到白名单"
          value={problemFilter}
          onChange={(value) => {
            setProblemFilter(value);
          }}
        >
          <Select.Option key={0} value={0}>
            全部题目
          </Select.Option>
          {props.problemLists.map((item) => {
            return (
              <Select.Option key={item.id} value={item.id}>
                {item.problem.title}
              </Select.Option>
            );
          })}
        </Select>
        <a href="https://resource.wejudge.net/tools" target="_blank" rel="noreferrer">比赛服工具</a>
      </Space>
      <Button type="primary" onClick={handleRefresh}><ReloadOutlined /> 刷新({second})</Button>
    </div>
    <div className="list-view">
      <ReactResizeDetector handleHeight onResize={handleContainerHeightChange} />
      <List>
        <AutoSizer disableHeight>
          {({ width }) => (<VList
            height={tableHeight}
            rowCount={solutionsList.length}
            rowHeight={73}
            rowRenderer={renderItem}
            width={width}
          />)}
        </AutoSizer>
      </List>
    </div>
  </div>;
};

JudgeSolutionsView.propTypes = {
  ...RoutePropTypes,
  contestId: PropTypes.number.isRequired,
  contest: ContestPropTypes,
  problemLists: PropTypes.arrayOf(PropTypes.shape({})),
};

JudgeSolutionsView.defaultProps = {
  contest: null,
  problemLists: [],
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getContestJudgeSolutions: ContestJudgeStatusActions.getContestJudgeSolutions,
}, dispatch);

const mapStateToProps = (state, props) => {
  const contestId = parseInt(get(props, 'match.params.contestId', 0), 10);
  const contest =  ContestSelectors.getContestEntity(state)(contestId);
  return {
    contestId,
    contest,
    problemLists: ContestProblemSelectors.problemListData(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JudgeSolutionsView);
