import React from 'react';
import PropTypes from 'prop-types';
import {
  get, isEmpty, isEqual, noop, set 
} from 'lodash';
import { Button } from 'antd';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import CodeEditor from 'scripts/common/widgets/code_mirror';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import LanguageLimitSlider from 'scripts/apps/problem/manage/language_limit';

@connect(null, dispatch => bindActionCreators({
  saveModifyProblems: CollectionProblemActions.saveModifyProblems,
}, dispatch))
class DemoCasesEditorView extends React.PureComponent {
  static propTypes  = {
    problem: ProblemPropTypes,
    collectionId: PropTypes.string.isRequired,
    saveModifyProblems: PropTypes.func,
  };

  static defaultProps = {
    problem: {},
    saveModifyProblems: noop,
  };

  state = {
    problem: {},
    submitting: false,
    demoCases: {},
    answerCases: {},
    timeLimit: {},
    memLimit: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEmpty(nextProps.problem)) {
      const demoCases = get(nextProps.problem, 'options.judge_options.demo_cases', {});
      const answerCases = get(nextProps.problem, 'options.judge_options.answer_cases', {});
      const timeLimit = get(nextProps.problem, 'options.judge_options.time_limit', {});
      const memLimit = get(nextProps.problem, 'options.judge_options.mem_limit', {});
      if (!isEqual(nextProps.problem, prevState.problem)) {
        return {
          problem: nextProps.problem,
          demoCases: JSON.parse(JSON.stringify(demoCases)),
          answerCases: JSON.parse(JSON.stringify(answerCases)),
          timeLimit: JSON.parse(JSON.stringify(timeLimit)),
          memLimit: JSON.parse(JSON.stringify(memLimit)),
        };
      }
    }
    return null;
  }

  editor = React.createRef();

  handleSave = () => {
    const { demoCases } = this.editor.current.state;
    const answerCases = this.editor.current.getCodeValue();
    const judgeOptions = {
      demo_cases: demoCases,
      answer_cases: answerCases,
      time_limit: this.state.timeLimit,
      mem_limit: this.state.memLimit,
    };
    const { id } = this.props.problem;
    if (id) {
      this.setState({
        submitting: true,
      }, () => {
        this.props.saveModifyProblems({
          problem: {
            id: get(this.props.problem, 'id', ''),
            options: {
              judge_options: judgeOptions,
            },
          },
          collectionId: this.props.collectionId,
          onComplete: () => {
            this.setState({
              submitting: false,
            });
          },
        });
      });
    }
  };

  handleLanguageLimitChange = language => (key, value) => {
    this.setState(set({ [key]: { ...this.state[key] } }, `${key}.${language}`, value));
  };

  renderLanguageLimitSlider = (state) => {
    const { language } = state;
    const { timeLimit, memLimit } = this.state;
    return <LanguageLimitSlider
      timeLimit={get(timeLimit, language.toString())}
      memLimit={get(memLimit, language.toString())}
      onChange={this.handleLanguageLimitChange(language)}
    />;
  };

  render() {
    const { problem } = this.props;
    if (isEmpty(problem)) return null;
    const ceOptions = {
      mode: 'fill-edit',
      defaultValue: this.state.answerCases,
      demoCases: this.state.demoCases,
      footerControl: false,
      editorHeight: 'auto',
    };
    return (<>
      <div className="demo_cases_editor">
        <CodeEditor
          ref={this.editor}
          optionsArea={this.renderLanguageLimitSlider}
          disablePerferenceSaving
          {...ceOptions}
        />
      </div>
      <div className="footer-content">
        <Button
          onClick={this.handleSave}
          loading={this.state.submitting}
          type="primary"
        >
          保存设置
        </Button>
      </div>
    </>);
  }
}

export default DemoCasesEditorView;
