import 'styles/wechat/qrconnect.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
// import { connect } from 'react-redux';
import { getQueryByKey } from 'scripts/common/utils/location_helper';

// @connect((state) => {
//
// }, dispatch => {})
class WeChatQrConnect extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      code: getQueryByKey('code'),
    };
  }

  render() {
    return <div className="wechat-qrconnect-loading">
      <Spin tip="正在与微信服务器通讯，请稍后..." size="large" />
    </div>;
  }
}

export default WeChatQrConnect;
