import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Divider, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { WeJudgeRoutes } from 'scripts/apps/routes';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import BaseLayout from './layout/base';

@withRouter
export class WeJudge404NotFoundComponent extends React.PureComponent {
  static propTypes = {
    noBorder: PropTypes.bool,
    ...RoutePropTypes,
  };

  static defaultProps = {
    noBorder: false,
  };

  render() {
    return <div className={classnames('wejudge-404-content-box', { 'no-border': this.props.noBorder })}>
      <div className="glitch-404">404 Not Found!</div>
      <Divider />
      <div className="content">
        <div className="title">
          页面千万个，正确就几个；访问不规范，用户两行泪。大侠，请重新来过吧~
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              this.props.history.go(-1);
            }}
          >
            后退一页
          </Button>
        </div>
      </div>
      <div className="footer">
        <a
          onClick={() => {
            this.props.history.push(WeJudgeRoutes.WEJUDGE_ROOT);
          }}
        >
          WeJudge / 3.0
        </a>
      </div>
    </div>;
  }
}

export const WeJudge404NotFound = () => (
  <BaseLayout>
    <div className="wejudge-404">
      <WeJudge404NotFoundComponent />
    </div>
  </BaseLayout>
);

