import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Button, Spin, Modal } from 'antd';
import connect from 'react-redux/es/connect/connect';
import  { bindActionCreators } from 'redux';
import { WeChatActions } from 'scripts/common/logic/wechat';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { getWeChatQrconnectUrl } from 'scripts/common/utils/wechat/qrconnect';
import { getQueryFromUrlByKey } from 'scripts/common/utils/location_helper';
import Logger from 'scripts/common/utils/exceptions/logger';

const logger = Logger('WeChatQRConnect');

@connect(null, dispatch => bindActionCreators({
  getWechatQrconnectInfo: WeChatActions.getWechatQrconnectInfo,
  doWechatQrconnect: WeChatActions.doWechatQrconnect,
  deleteWechatQrconnect: WeChatActions.deleteWechatQrconnect,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
}, dispatch), null, { forwardRef: true })
class WeChatConnectDialog extends React.PureComponent {
  static propTypes = {
    ...Modal.propTypes,
    account: AccountPropTypes.isRequired,
    doWechatQrconnect: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    deleteWechatQrconnect: PropTypes.func,
    getWechatQrconnectInfo: PropTypes.func,
  };

  static defaultProps = {
    doWechatQrconnect: noop,
    viewSuccessMessage: noop,
    deleteWechatQrconnect: noop,
    getWechatQrconnectInfo: noop,
  };

  state = {
    mode: 'loading',
    wxUserInfo: {},
  };

  componentDidMount() {
    this.props.getWechatQrconnectInfo({
      onSuccess: (ui) => {
        this.setState({
          mode: 'info',
          wxUserInfo: ui,
        });
      },
      onError: (e) => {
        if (get(e, 'payload') === 'bind_req') {
          this.setState({
            mode: 'bind',
          });
        }
      },
    });
  }

  handleUnbind = () => {
    Modal.confirm({
      title: '解除微信绑定',
      okType: 'danger',
      message: '解除绑定后，将无法使用微信扫码登录。你可以绑定新的微信用户以便使用扫码登录功能。',
      onOk: () => {
        this.setState({
          mode: 'loading',
        }, () => {
          this.props.deleteWechatQrconnect({
            onSuccess: () => {
              this.setState({
                mode: 'bind',
                wxUserInfo: {},
              }, () => {
                this.props.onOk();
              });
            },
            onError: () => {
              this.setState({
                mode: 'info',
              });
            },
          });
        });
      },
      cancelText: '我再想想',
      okText: '解除绑定',
    });
  };

  handleLoad = () => {
    let pageUrl = '';
    try {
      pageUrl = document.getElementById('wechat_qrconnect_iframe').contentWindow.location.href;
    } catch (e) {
      logger.info('Now is on WeChat Website, wait for redirect');
    }
    if (pageUrl) {
      this.setState({
        mode: 'loading',
      }, () => {
        this.props.doWechatQrconnect({
          code: getQueryFromUrlByKey(pageUrl, 'code'),
          onSuccess: (action) => {
            if (action === 'binded') {
              this.props.viewSuccessMessage({
                message: '绑定成功!',
              });
              this.props.getWechatQrconnectInfo({
                onSuccess: (ui) => {
                  this.setState({
                    mode: 'info',
                    wxUserInfo: ui,
                  }, () => {
                    this.props.onOk();
                  });
                },
              });
            } else {
              this.setState({
                mode: 'bind',
              });
            }
          },
          onError: () => {
            this.setState({
              mode: 'bind',
            });
          },
        });
      });
    }
  };

  render() {
    return <Modal
      {...this.props}
      title="微信绑定"
      width={540}
      footer={false}
    >
      <div className="space-wechat-connect-dialog">
        {this.state.mode === 'loading' && <div align="center"><Spin tip="正在与微信服务器通讯，请稍后..." size="large" /></div>}
        {this.state.mode === 'info' && <div className="wechat_user_info_ares">
          <img className="headimg" width={132} height={132} src={get(this.state.wxUserInfo, 'headimgurl')} alt="" />
          <div className="nickname">{get(this.state.wxUserInfo, 'nickname')}</div>
          <Button type="danger" onClick={this.handleUnbind}>解除绑定</Button>
        </div>}
        {this.state.mode === 'bind' && <iframe
          title="WeChat"
          id="wechat_qrconnect_iframe"
          frameBorder={0}
          allowTransparency="true"     // eslint-disable-line
          scrolling="no"
          height={450}
          onLoad={this.handleLoad}
          src={getWeChatQrconnectUrl()}
        />}
      </div>
    </Modal>;
  }
}

export default WeChatConnectDialog;
