import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { humanbytes } from 'scripts/common/utils/unit';
import {
  Button, Empty, Modal, Progress 
} from 'antd';
import { bindActionCreators } from 'redux';
import { RepositoryAction } from 'scripts/common/logic/repository';
import { connect } from 'react-redux';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { RepositoryPropType } from 'scripts/common/prop-types/repository';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RepositoryRoutes } from 'scripts/apps/routes';
import { withRouter } from 'react-router';
import { APP_CONST } from 'scripts/common/constants/apps';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

const mapDispatchToProps = dispatch => bindActionCreators({
  createRepository: RepositoryAction.createRepository,
}, dispatch);

const MyRepositoryCard = (props) => {
  const { myRepository, account } = props;
  const handleCreateMyRepo = () => {
    Modal.confirm({
      title: '操作确认',
      content: '确认要激活教学资源仓库吗？',
      onOk: () => {
        props.createRepository();
      },
    });
  };
  const handleRepoCardClick = repoId => () => {
    props.history.push(buildPath(RepositoryRoutes.INDEX, { repoId }));
  };
  if (get(account, 'role') === AccountRoleEnum.STUDENT.value) return null;
  const maxSize = get(myRepository, 'max_size', 0);
  const curSize = get(myRepository, 'cur_size', 0);
  return <div className=" common-card-panel my-repo-card-wrap">
    <div className="panel-title">
      <div className="title">
        我的教学资源仓库
      </div>
    </div>
    {myRepository ? <div
      className="panel-body"
      onClick={handleRepoCardClick(get(myRepository, 'id', 0))}
    >
      <div className="left-tips">
        <strong>已用：</strong>{humanbytes(curSize)}<br />
        <strong>剩余：</strong>{humanbytes(maxSize - curSize)}
      </div>
      <div className="right-graph">
        <Progress
          type="circle"
          percent={Number(
            ((curSize / maxSize) * APP_CONST.COMMON.PERCENT_100)
              .toFixed(APP_CONST.COMMON.FIXED_DIGIT)
          )}
          width={80}
        />
      </div>
    </div> : <div className="panel-body">
      <Empty description="" image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <Button type="primary" onClick={handleCreateMyRepo}>激活我的仓库</Button>
      </Empty>
    </div>}
  </div>;
};

MyRepositoryCard.propTypes = {
  ...RoutePropTypes,
  account: AccountPropTypes,
  myRepository: RepositoryPropType,
  createRepository: PropTypes.func,
};

MyRepositoryCard.defaultProps = {
  account: null,
  myRepository: null,
  createRepository: noop,
};

export default withRouter(connect(null, mapDispatchToProps)(MyRepositoryCard));
