import Axios from 'axios';
import Qs from 'qs';
import { get } from 'lodash';
import Logger from 'scripts/common/utils/exceptions/logger';

const logger = Logger('REPO_UPD');

const noop = function () {};
const API_URLS = {
  AUTH: '/upload/:file_hash/auth',
  INIT: '/upload/:file_hash/init',
  CHUNKS: '/upload/:file_hash/chunks',
  FINISH: '/upload/:file_hash/finish',
};

class UploadServerError extends Error {
  info = null;

  constructor(resp) {
    super(get(resp, 'message', '网络异常')); // (1)
    this.name = 'UploadServerError'; // (2)
    this.info = resp;
  }
}

const axios = Axios.create({
  baseURL: get(process.env, 'RESOURCE_SERVICE'),
  withCredentials: true,
  responseType: 'json',
});

export class UploadProcessor {
  inProgress = false;

  file = null;

  uploadToken = null;

  author = null;

  fileHash = '';

  fileUploadInfo = null;

  constructor(file, author, uploadToken, fileHash) {
    // if (!file) throw new Error('文件不能为空');
    if (!author) throw new Error('author不能为空');
    if (!uploadToken) throw new Error('上传Token不能为空');
    this.file = file;
    this.author = author;
    this.uploadToken = uploadToken;
    this.fileHash = fileHash;
  }

  buildApiURL = url => url.replace(':file_hash', this.fileHash);

  sliceFile = (pos, end) => {
    if (this.file.slice) {
      return this.file.slice(pos, end);
    } if (this.file.webkitSlice) {
      return this.file.webkitSlice(pos, end);
    } 
    throw new Error('浏览器版本过低，不支持此操作');
  };

  /**
   * 初始化上传工作
   * @returns {Promise<void>}
   */
  initUpload = async () => {
    try {
      if (this.inProgress) return;
      this.inProgress = true;

      const postBody = {
        file_name: this.file.name,
        file_size: this.file.size,
        author: this.author,
      };
      const postConfig = {
        params: {
          access_token: this.uploadToken.access_token,
        },
      };
      const resp = await axios.post(
        this.buildApiURL(API_URLS.INIT),
        Qs.stringify(postBody),
        postConfig
      );
      if (!get(resp, 'data.status')) {
        this.inProgress = false;
        throw new UploadServerError(resp.data);
      }
      this.fileUploadInfo = resp.data.data;
      this.inProgress = false;
    } catch (e) {
      throw e;
    } finally {
      this.inProgress = false;
    }
  };

  sendChunks = async (process = noop) => {
    if (this.inProgress || !this.fileUploadInfo) return;
    if (this.fileUploadInfo.persistence || this.fileUploadInfo.is_upload_finished) return;
    this.inProgress = true;
    const { chunks = [] } = this.fileUploadInfo;
    const fileSize = this.fileUploadInfo.file_size * 1;
    // 遍历分片信息
    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      if (chunk.status === 0) {    // 未上传分片
        const start = chunk.chunk_start;
        const end = chunk.chunk_start + chunk.chunk_size;
        const chunkSize = chunk.chunk_size;
        const fileSend = this.sliceFile(start, end);
        const form = new FormData();
        form.append('chunk_size', chunk.chunk_size);
        form.append('chunk_index', chunk.chunk_index);
        form.append('chunk', fileSend);
        form.append('author', this.author);
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            access_token: this.uploadToken.access_token,
          },
          transformRequest: [data => data],
          onUploadProgress: (progressEvent) => {
            const proc = progressEvent.loaded / progressEvent.total;
            const complete = ((start + Math.floor(chunkSize * proc)) / fileSize);
            process(complete, proc, chunk.chunk_index + 1, chunks.length);
          },
        };
        let resp = {};
        try {
          // eslint-disable-next-line no-await-in-loop
          resp = await axios.post(this.buildApiURL(API_URLS.CHUNKS), form, config);
        } catch (e) {
          logger.error(e);
          resp = {};
        }
        if (get(resp, 'data.status')) {
          this.fileUploadInfo = Object.assign(this.fileUploadInfo, resp.data.data);
        } else {
          this.inProgress = false;
          throw new UploadServerError(resp.data);
        }
      }
    }
    this.inProgress = true;
  };

  finishUpload = async () => {
    //no-await-in-loop
    if (this.fileUploadInfo.persistence || this.fileUploadInfo.is_upload_finished) return;
    this.inProgress = true;
    const config = {
      params: {
        access_token: this.uploadToken.access_token,
      },
    };
    const resp = await axios.post(this.buildApiURL(API_URLS.FINISH), Qs.stringify({
      author: this.author,
    }), config);
    if (get(resp, 'data.status')) {
      this.inProgress = false;
      this.fileUploadInfo = Object.assign(this.fileUploadInfo, resp.data.data);
    } else {
      this.inProgress = false;
      throw new UploadServerError(resp.data);
    }
  }
}

export default UploadProcessor;
