import React from 'react';
import { get, noop, truncate } from 'lodash';
import PropTypes from 'prop-types';
import {
  Card, Tooltip, Skeleton, Avatar
} from 'antd';
import { connect } from 'react-redux';
import { CollectionSelectors } from 'scripts/common/logic/collection';
import CollectionThumbImage from 'images/collection_thumb.jpg';
import { CollectionPropTypes } from 'scripts/common/prop-types/collection';
import {
  buildAvatarPath,
  buildPath,
  buildResourcePath
} from 'scripts/common/utils/location_helper';
import { getCollectionType } from 'scripts/apps/collection/widgets/collection_list_item';
import { UserOutlined } from '@ant-design/icons';
import { AccountRoutes } from 'scripts/apps/routes';
import IconText from 'scripts/common/widgets/icon-text';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { withRouter } from 'react-router';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

const { Meta } = Card;
/*
* CollectionCard
* */
const CollectionCardView = (props) => {

  const authorId = get(props.collection, 'author.id');
  const authorAvatar = get(props.collection, 'author.avator');
  const authorRole = get(props.collection, 'author.role');
  const isTeacher = [
    AccountRoleEnum.TEACHER.value,
    AccountRoleEnum.DEAN.value
  ].indexOf(authorRole) > 0;
  const thumbImage = get(props.collection, 'thumb_img', '');
  const authorName = isTeacher ? `${get(props.collection, 'author.realname')}老师` : get(props.collection, 'author.nickname');
  const renderCollectionTypeItem = () => {
    const ctype = getCollectionType(props);
    return <Tooltip title={ctype.text}>{ctype.icon}</Tooltip>;
  };

  return (
    <div className="card_container">
      <Card
        className="card"
        hoverable
        cover={<img
          className="thumb"
          onClick={props.onClick}
          alt="collection"
          src={thumbImage ? buildResourcePath(thumbImage) : CollectionThumbImage}
        />}
        actions={[
          <IconText
            key="list-item-author"
            icon={<Avatar
              className="user-avator"
              icon={<UserOutlined />}
              size={16}
              src={buildAvatarPath(authorAvatar)}
            />}
            truncate={8}
            onClick={authorId ? (e) => {
              e.stopPropagation();
              e.preventDefault();
              const { history } = props;
              history.push(buildPath(AccountRoutes.SPACE.INDEX, { accountId: authorId }));
            } : null}
            text={authorName}
          />,
          <span key="problems_counter">{get(props, 'problemsCount', 0)} 题</span>
        ]}
      >
        <Skeleton loading={!props.collection} active>
          <Meta
            onClick={props.onClick}
            title={<>
              <Tooltip title={get(props.collection, 'title')}>{get(props.collection, 'title')}</Tooltip>
              &nbsp;
              {renderCollectionTypeItem()}
            </>}
            description={<Tooltip title={get(props.collection, 'description')}>
              {truncate(get(props.collection, 'description'), { length: 14 }) || '暂无简介'}
            </Tooltip>}
          />
        </Skeleton>
      </Card>
    </div>
  );
};

CollectionCardView.propTypes = {
  ...RoutePropTypes,
  id: PropTypes.string.isRequired,
  collection: CollectionPropTypes,
  onClick: PropTypes.func,
  isAdmin: PropTypes.bool,
};

CollectionCardView.defaultProps = {
  onClick: noop,
  collection: null,
  isAdmin: false,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const collection = id ? CollectionSelectors.getCollectionEntity(state)(id) : null;
  return {
    collection,
  };
};

export const CollectionCard = connect(mapStateToProps, null)(withRouter(CollectionCardView));
