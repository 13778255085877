import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  get, noop, set, isEqual, pick, debounce
} from 'lodash';
import { BulbFilled, CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  Form, Input, Row, Col, Button, Select, Rate, Space, Tooltip
} from 'antd';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import CodeProblemBody from 'scripts/apps/problem/widgets/code_problem_body';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { ProblemDifficultyEnum } from 'scripts/common/enums/problem';

const { TextArea } = Input;

@connect(null, dispatch => bindActionCreators({
  saveModifyProblems: CollectionProblemActions.saveModifyProblems,
}, dispatch))
class CodeProblemEditView extends React.PureComponent {
  static propTypes  = {
    problem: ProblemPropTypes,
    onChange: PropTypes.func,
    saveModifyProblems: PropTypes.func,
    collectionId: PropTypes.string,
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: 'edit',
    collectionId: '',
    problem: {
      id: '',
      title: '',
      description: '',
      options: {
        input: '',
        output: '',
        sample: [],
      },
      hint: '',
      explain: '',
      source: '',
    },
    onChange: noop,
    saveModifyProblems: noop,
  };

  state = {
    sampleIndex: 0,
    submitting: false,
    problem: JSON.parse(JSON.stringify(this.props.problem)),
  };

  componentDidUpdate(prevProp, prevState) {
    if (!isEqual(prevState.problem, this.state.problem)) {
      this.props.onChange(this.state.problem);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handleAddSample = () => {
    const sample = [...get(this.state, 'problem.options.sample', []), { input: '', output: '' }];
    const { problem } = this.state;
    problem.options.sample = sample;
    this.setState(set({
      problem: { ...this.state.problem },
      sampleIndex: sample.length - 1,
    }, 'problem.options.sample', sample));
  };

  handleRemoveSample = () => {
    const sample = get(this.state, 'problem.options.sample', []);
    const t = this.state.sampleIndex - 1;
    if (t < 0) return;
    this.setState(set({
      problem: { ...this.state.problem },
      sampleIndex: t,
    }, 'problem.options.sample', sample.filter((item, key) => key !== this.state.sampleIndex)));
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState(set({ problem: { ...this.state.problem } }, `problem.${keyName}`,  target.value));
  };

  handleMdEditorChange = keyName => debounce((text) => {
    this.setState(set({
      problem: { ...this.state.problem },
    }, `problem.${keyName}`, text));
  }, 100);

  handleSaveProblemSettings = () => {
    const { problem } = this.state;
    const { id } = problem;
    if (id) {
      this.setState({
        submitting: true,
      }, () => {
        this.props.saveModifyProblems({
          problem: {
            ...pick(problem, [
              'id',
              'title',
              'difficulty',
              'description',
              'options.input',
              'options.output',
              'options.sample',
              'explain',
              'hint',
              'source'
            ]),
          },
          collectionId: this.props.collectionId,
          onComplete: () => {
            this.setState({
              submitting: false,
            });
          },
        });
      });
    }
  };

  render() {
    const { problem, editorViewWidth } = this.state;
    const options = get(problem, 'options', {});
    const sample = get(options, 'sample', []);
    return (
      <div className="code_problem_editor">
        <div className="main-wrap">
          {this.props.mode !== 'new' && <div className="preview-wrap">
            {editorViewWidth}
            <CodeProblemBody
              problem={problem}
              canManageProblem={false}
            />
          </div>}
          <div className="form-wrap">
            <Row>
              <Col span={18}>
                <Form.Item>
                  <Input
                    key="title"
                    value={problem.title}
                    onChange={this.handleInputChange('title')}
                    placeholder="题目名称"
                  />
                </Form.Item>
              </Col>
              <Col span={6} align="right">
                <Form.Item>
                  <Rate
                    character={({ index }) => <Tooltip
                      title={get(ProblemDifficultyEnum.enumValueOf(index + 1), 'desc')}
                    >
                      <BulbFilled />
                    </Tooltip>}
                    value={problem.difficulty}
                    onChange={(value) => { this.handleInputChange('difficulty')({ target: { value } }); }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="form-item-group">
              <div className="label">题目描述</div>
              <div className="content">
                <MarkdownEditor
                  height="20em"
                  onChange={this.handleMdEditorChange('description')}
                  defaultValue={problem.description}
                />
              </div>
            </div>
            {this.props.mode !== 'new' && <>
              <div className="form-item-group">
                <div className="label">输入要求</div>
                <div className="content">
                  <MarkdownEditor
                    height="15em"
                    onChange={this.handleMdEditorChange('options.input')}
                    defaultValue={get(problem, 'options.input', '')}
                  />
                </div>
              </div>
              <div className="form-item-group">
                <div className="label">输出要求</div>
                <div className="content">
                  <MarkdownEditor
                    height="15em"
                    onChange={this.handleMdEditorChange('options.output')}
                    defaultValue={get(problem, 'options.output', '')}
                  />
                </div>
              </div>
              <div className="form-item-group form-item-group-bordered">
                <div className="label">测试数据示例</div>
                <div className="content">
                  <Space>
                    <Select
                      value={this.state.sampleIndex}
                      size="small"
                      disabled={sample.length === 0}
                      onChange={key => this.setState({ sampleIndex: key })}
                    >
                      {sample.map((item, key) => {
                        return <Select.Option
                          key={`td_${key + 1}`}
                          value={key}
                        >
                          测试数据示例 {key + 1}
                        </Select.Option>;
                      })}
                    </Select>
                    <a
                      onClick={this.handleAddSample}
                      className="sample_cases_btn"
                    >
                      <PlusCircleOutlined />
                    </a>
                    {sample.length > 0 && <a
                      onClick={this.handleRemoveSample}
                      className="sample_cases_btn"
                    >
                      <CloseCircleOutlined />
                    </a>}
                  </Space>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Form.Item label="输入数据样例" labelCol={{ span: 24 }}>
                        <TextArea
                          placeholder="请输入输入数据样例"
                          disabled={sample.length === 0}
                          value={get(options, `sample.${this.state.sampleIndex}.input`, '')}
                          rows={6}
                          onChange={this.handleInputChange(`options.sample.${this.state.sampleIndex}.input`)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="输出数据样例" labelCol={{ span: 24 }}>
                        <TextArea
                          placeholder="请输入输出数据样例"
                          disabled={sample.length === 0}
                          value={get(options, `sample.${this.state.sampleIndex}.output`, '')}
                          rows={6}
                          onChange={this.handleInputChange(`options.sample.${this.state.sampleIndex}.output`)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label="样例解析" labelCol={{ span: 24 }}>
                        <TextArea
                          placeholder="请输入样例解析"
                          disabled={sample.length === 0}
                          value={get(options, `sample.${this.state.sampleIndex}.explain`, '')}
                          rows={6}
                          onChange={this.handleInputChange(`options.sample.${this.state.sampleIndex}.explain`)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="form-item-group">
                <div className="label">解题提示</div>
                <div className="content">
                  <MarkdownEditor
                    height="15em"
                    onChange={this.handleMdEditorChange('hint')}
                    defaultValue={get(problem, 'hint', '')}
                  />
                </div>
              </div>
              <div className="form-item-group">
                <div className="label">答案解析</div>
                <div className="content">
                  <MarkdownEditor
                    height="15em"
                    onChange={this.handleMdEditorChange('explain')}
                    defaultValue={get(problem, 'explain', '')}
                  />
                </div>
              </div>
              <div className="form-item-group">
                <div className="label">题目来源</div>
                <div className="content">
                  <TextArea
                    placeholder="请输入题目来源"
                    value={get(problem, 'source', '')}
                    rows={2}
                    onChange={this.handleInputChange('source')}
                  />
                </div>
              </div>
            </>}
          </div>
        </div>
        {this.props.mode !== 'new' && <div className="footer-wrap">
          <Button
            type="primary"
            loading={this.state.submitting}
            onClick={this.handleSaveProblemSettings}
          >
            保存设置
          </Button>
        </div>}
      </div>
    );
  }
}

export default CodeProblemEditView;
