import React from 'react';
import PropTypes from 'prop-types';
import { List, Avatar } from 'antd';
import BalloonIcon from 'svgs/si-glyph-balloon.svg';

export const ProblemListTableForUser = (props) => {
  return <List
    className="problem_list_view"
    loading={props.loading}
    itemLayout="horizontal"
    dataSource={props.dataSource}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          avatar={
            <Avatar
              src={<svg
                fill={item.passed ? item.color : '#f0f0f0'}
                width={32}
                height={32}
              >
                <use xlinkHref={`#${BalloonIcon.id}`} />
              </svg>}
            />
          }
          title={<>{item.number} {item.title}</>}
          description={<span>
            正确率：{item.correct_ratio}
          </span>}
        />
        <div>{item.status}</div>
      </List.Item>
    )}
  />;
};

ProblemListTableForUser.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ProblemListTableForUser.defaultProps = {
  loading: false,
};
