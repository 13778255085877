import 'styles/collection/problems_list.scss';
import React from 'react';
import PropTypes from 'prop-types';

import {
  CloudDownloadOutlined,
  CopyOutlined,
  DeleteOutlined,
  ExportOutlined,
  FolderOpenOutlined,
  ImportOutlined,
  ScissorOutlined,
  SnippetsOutlined,
  TagsOutlined
} from '@ant-design/icons';

import {
  Button, Badge, Menu, Input, notification, Upload, Modal, Progress, Tooltip 
} from 'antd';
import { showModal } from 'scripts/common/widgets/modal';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getQuery, buildApiPath } from 'scripts/common/utils/location_helper';
import { get, noop, pick } from 'lodash';
import { store as APPStore } from 'scripts/common/logic/store';
import { CollectionProblemActions } from 'scripts/common/logic/collection/index';
import { CollectionSelectors } from 'scripts/common/logic/collection';
import { CollectionPropTypes } from 'scripts/common/prop-types/collection';
import { PaginationParams } from 'scripts/common/constants/global';
import APIs from 'scripts/common/constants/apis';
import { CollectionActions } from 'scripts/common/logic/collection/actions/colleciton';
import { CollectionProblemsSelectors } from 'scripts/common/logic/collection/selectors/problem';
import { AddProblemButton } from '../../problem/widgets/add_problem';
import ProblemControlModal from './problem_control_dialog';
import ClassifyInfoModal from '../classify/classify_modal';
import { openLink } from '../../../common/utils/location_helper';

const ButtonGroup = Button.Group;
const { Search } = Input;

const mapDispatchToProps = dispatch => bindActionCreators({
  moveProblems: CollectionProblemActions.saveMoveProblems,
  copyProblems: CollectionProblemActions.saveCopyProblems,
  cloneProblems: CollectionProblemActions.saveCloneProblems,
  removeProblems: CollectionProblemActions.saveRemoveProblems,
  getCollectionsList: CollectionActions.getCollectionsList,
  editProblemClassify: CollectionActions.editProblemClassify,
  downloadProblem: CollectionProblemActions.downloadProblem,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const collectionsList = CollectionSelectors.collectionsListData(state);
  const pager = CollectionSelectors.collectionsListViewPagerSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page']),
  };
  return {
    collectionsList,
    queryParams,
    paginationParams: pager || props.paginationParams,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ProblemsListControlBar extends React.PureComponent {
  static propTypes = {
    minorMode: PropTypes.bool,
    canCreateProblem: PropTypes.bool.isRequired,
    canManageCollection: PropTypes.bool.isRequired,
    collectionId: PropTypes.string.isRequired,
    editProblemClassify: PropTypes.func,
    searchKeyword: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    moveProblems: PropTypes.func,
    copyProblems: PropTypes.func,
    cloneProblems: PropTypes.func,
    removeProblems: PropTypes.func,
    onCreateProblemSuccess: PropTypes.func.isRequired,
    selectedProblemIds: PropTypes.arrayOf(PropTypes.string),
    collectionsList: PropTypes.arrayOf(CollectionPropTypes),
    fetchList: PropTypes.func.isRequired,
    problemChoosingOptions: PropTypes.shape({}),
    onChoiceProblem: PropTypes.func,
    queryParams: PropTypes.shape({}),
    downloadProblem: PropTypes.func,
    onClearSelection: PropTypes.func,
    onOpenTagsFilterDialog: PropTypes.func,
    tagKeys: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    minorMode: false,
    collectionsList: [],
    selectedProblemIds: [],
    searchKeyword: '',
    problemChoosingOptions: {},
    queryParams: {},
    onChoiceProblem: noop,
    moveProblems: noop,
    copyProblems: noop,
    cloneProblems: noop,
    removeProblems: noop,
    downloadProblem: noop,
    editProblemClassify: noop,
    onOpenTagsFilterDialog: noop,
    onClearSelection: noop,
    tagKeys: [],
  };

  state= {
    // queryParams: null,
    keyword: this.props.searchKeyword,
    problemProgressBar: -1,
    problemProgress: 0,
  };

  isBatchAvailable = () => this.props.selectedProblemIds.length > 0;

  openNotification = (result, key) => {
    const resultList = [];
    const store = APPStore.getState();
    result.result.forEach((item) => {
      const problem =        CollectionProblemsSelectors.getCollectionProblemEntity(store)(item.id);
      const status = get(item, 'status', 0);
      let statusMsg = '';
      if (status === 0) {
        statusMsg = '失败';
      } else if (status === 1) {
        statusMsg = '成功';
      } else if (status === 2) {
        if (key === 'clone' || key === 'move') {
          statusMsg = '失败(目标题库已存在该题目)';
        } else if (key === 'copy') {
          statusMsg = '失败(不能在原题库复制)';
        } else if (key === 'remove') {
          statusMsg = '失败(代码题非镜像不能执行移除操作)';
        }
      }
      resultList.push({
        name: get(problem, 'problem.title', ''),
        number: get(problem, 'problem.number', ''),
        statusMsg,
        status,
      });
    });
    let durationFlag = true;
    resultList.forEach((item) => {
      if (item.status === 0 || item.status === 2) {
        durationFlag = false;
      }
    });
    const args = {
      message: '题目操作结果',
      description: <React.Fragment key={Math.random().toString()}>
        {resultList.map((item) => {
          return (<React.Fragment key={item.number}>
            {item.status === 1
              ? <div style={{ color: 'green', margin: '2px 2px' }}>
                [{item.number}] {item.name}: {item.statusMsg}
              </div>
              :              <div>
                <div style={{ color: 'red', margin: '2px 2px' }}>
                  [{item.number}] {item.name}: {item.statusMsg}
                </div>
              </div>}
          </React.Fragment>
          );
        })}
      </React.Fragment>,
      duration: durationFlag === false ? null : 3,
    };
    notification.open(args);
  };

  uploadAction = buildApiPath(
    APIs.COLLECTION.COLLECTION_PROBLEMS.UPLOAD,
    {
      cid: this.props.collectionId,
    }
  );

  //上传操作
  handleBeforeUploadChange = key => () => {
    this.setState({
      [`${key}ProgressBar`]: 0,
    });
  };

  handleUploadChange = key => (info) => {
    if (info.file.status === 'uploading') {
      this.setState({
        [`${key}Progress`]: parseFloat(get(info, 'event.percent', 0).toFixed(1)),
      });
    }
    if (info.file.status === 'done') {
      this.setState({
        [`${key}Progress`]: 100,
        [`${key}ProgressBar`]: 1,
      });
      Modal.success({
        title: '导入成功',
        content: '导入题目成功',
      });
    } else if (info.file.status === 'error') {
      this.setState({
        [`${key}ProgressBar`]: 2,
      });
      Modal.error({
        title: '导入失败',
        content: get(info, 'file.response.errors.0.message', '未知错误'),
      });
    }
  };

  // 题目基本操作
  handleProblemOperationClick = key => () => {
    const { selectedProblemIds } = this.props;
    let modalBox = null;
    switch (key) {
      case 'move':
        modalBox = showModal(ProblemControlModal, {
          width: 600,
          type: key,
          onOk: (modal, state) => {
            this.props.moveProblems({
              collectionId: this.props.collectionId, //当前题库ID
              ids: selectedProblemIds, //ids 集合
              targetCollectionId: state.target_collection_id, //目标题库id
              onSuccess: () => {
                modalBox.close();
                this.props.fetchList();
                this.props.onClearSelection();
              },
              returnResult: (result) => {
                this.openNotification(result, key);
              },
            });
            return false;
          },
        });
        break;
      case 'copy':
        modalBox = showModal(ProblemControlModal, {
          width: 600,
          type: key,
          onOk: (modal, state) => {
            this.props.copyProblems({
              collectionId: this.props.collectionId, //当前题库ID
              ids: selectedProblemIds, //ids 集合
              targetCollectionId: state.target_collection_id, //目标题库id
              onSuccess: () => {
                modalBox.close();
                this.props.fetchList();
                this.props.onClearSelection();
              },
              returnResult: (result) => {
                this.openNotification(result, key);
              },
            });
            return false;
          },
        });
        break;
      case 'clone':
        modalBox = showModal(ProblemControlModal, {
          width: 600,
          type: key,
          onOk: (modal, state) => {
            this.props.cloneProblems({
              collectionId: this.props.collectionId, //当前题库ID
              ids: selectedProblemIds, //ids 集合
              targetCollectionId: state.target_collection_id, //目标题库id
              onSuccess: () => {
                modalBox.close();
                this.props.fetchList();
                this.props.onClearSelection();
              },
              returnResult: (result) => {
                this.openNotification(result, key);
              },
            });
            return false;
          },
        });
        break;
      case 'remove':
        modalBox = showModal(ProblemControlModal, {
          width: 600,
          type: key,
          onOk: () => {
            this.props.removeProblems({
              collectionId: this.props.collectionId, //当前题库ID
              ids: selectedProblemIds, //ids 集合
              onSuccess: () => {
                modalBox.close();
                this.props.fetchList();
                this.props.onClearSelection();
              },
              returnResult: (result) => {
                this.openNotification(result, key);
              },
            });
            return false;
          },
        });
        break;
      case 'export':
        this.props.downloadProblem({
          collection_model: false,
          ids: selectedProblemIds,
          collectionId: this.props.collectionId,
          onSuccess: (r) => {
            openLink(`${r}?download=1`);
            this.props.onClearSelection();
          },
        });
        break;
      default:
        break;
    }
  };

  handleDownloadClick = () => {
    this.props.downloadProblem({
      collection_model: true,
      collectionId: this.props.collectionId,
      onSuccess: (r) => {
        openLink(`${r}?download=1`);
      },
    });
  };

  handleClassifyClick = () => {
    const modalBox = showModal(ClassifyInfoModal, {
      collectionId: this.props.collectionId,
      onOk: (modal, state) => {
        this.props.editProblemClassify({
          collectionId: this.props.collectionId,
          classifyId: get(state, 'classifyKey.selectKeys.0', ''),
          ids: get(this.props, 'selectedProblemIds', []),
          onSuccess: () => {
          },
          onComplete: () => {
            modalBox.close();
            this.props.fetchList();
            this.props.onClearSelection();
          },
        });
        modalBox.close();
        return false;
      },
    });
  };

  renderOperationMenu = () => (
    <Menu onClick={this.handleMenuClick(this.props.selectedProblemIds)}>
      <Menu.Item key="move">移动到</Menu.Item>
      <Menu.Item key="clone">镜像到</Menu.Item>
      <Menu.Item key="copy">复制到</Menu.Item>
      <Menu.Item key="remove">移除题目</Menu.Item>
    </Menu>
  );

  renderArchiveMenu = () => (
    <Menu onClick={this.handleMenuClick(this.props.selectedProblemIds)}>

      <Menu.Item key="export">导出并下载</Menu.Item>
      <Menu.Item key="import">
        <Upload
          withCredentials
          action={this.uploadAction}
          name="problems_zip"
          onChange={this.handleUploadChange('problem')}
          beforeUpload={this.handleBeforeUploadChange('problem')}
          showUploadList={false}
        >
          从文件导入...
        </Upload>
      </Menu.Item>
    </Menu>
  );

  render() {
    const STATUS_MAPPING = {
      0: 'active',
      1: 'success',
      2: 'exception',
    };
    const { problemChoosingOptions } = this.props;
    const module = get(problemChoosingOptions, 'module', '');
    const isChoosingMode = module !== '';
    return (
      <div className="pl_control_bar">
        <div className="control_btns">
          {isChoosingMode && <>
            {/*<Button*/}
            {/*  type="primary"*/}
            {/*  id="do-problem-choosing"*/}
            {/*  disabled={!this.isBatchAvailable()}*/}
            {/*  onClick={this.props.onChoiceProblem}*/}
            {/*>*/}
            {/*  <Icon type="plus" theme="outlined" /> 选题*/}
            {/*</Button>*/}
            <span className="do-problem-choose-tip">
              小提示：点击题目旁的小框框就可以选题哦~！
            </span>
          </>}
          {!isChoosingMode && this.props.canManageCollection && <>
            {this.props.canCreateProblem && <AddProblemButton
              collectionId={this.props.collectionId}
              onSuccess={this.props.onCreateProblemSuccess}
            />}
            <ButtonGroup className="btns_group">
              <Tooltip title="移动">
                <Button
                  onClick={this.handleProblemOperationClick('move')}
                  disabled={!this.isBatchAvailable()}
                >
                  <ScissorOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="镜像">
                <Button
                  onClick={this.handleProblemOperationClick('clone')}
                  disabled={!this.isBatchAvailable()}
                >
                  <CopyOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="另存为">
                <Button
                  onClick={this.handleProblemOperationClick('copy')}
                  disabled={!this.isBatchAvailable()}
                >
                  <SnippetsOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="移除">
                <Button
                  onClick={this.handleProblemOperationClick('remove')}
                  disabled={!this.isBatchAvailable()}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </ButtonGroup>
            <ButtonGroup className="btns_group">
              <Tooltip title="移动分类">
                <Button
                  disabled={!this.isBatchAvailable()}
                  onClick={this.handleClassifyClick}
                >
                  <FolderOpenOutlined />
                </Button>
              </Tooltip>
            </ButtonGroup>
            {!this.props.minorMode && <ButtonGroup className="btns_group">
              <Tooltip title="导入题目(开发中)">
                <Button
                  disabled
                >
                  <ImportOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="导出选中题目(开发中)">
                <Button
                  disabled
                >
                  <ExportOutlined />
                </Button>
              </Tooltip>
              <Tooltip title="导出所有题目(开发中)">
                <Button
                  disabled
                >
                  <CloudDownloadOutlined />
                </Button>
              </Tooltip>
            </ButtonGroup>}
            <div className="progress">
              {this.state.problemProgressBar > -1 && <Progress
                percent={this.state.problemProgress}
                status={get(STATUS_MAPPING, this.state.problemProgressBar, 'active')}
              />}
            </div>
          </>}
        </div>
        <div className="search_box">
          <Tooltip title="按标签筛选">
            <Button
              onClick={this.props.onOpenTagsFilterDialog}
              className="tag_filter"
            >
              <Badge
                count={this.props.tagKeys.length}
                offset={[16, -4]}
              >
                <TagsOutlined />
              </Badge>
            </Button>
          </Tooltip>
          <Search
            value={this.state.keyword}
            placeholder="输入题号或题目标题"
            onChange={({ target }) => { this.setState({ keyword: target.value }); }}
            onSearch={this.props.onSearch}
          />
        </div>
      </div>
    );
  }
}

export default ProblemsListControlBar;
