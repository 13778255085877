import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Table, Modal } from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ContestOrganizationActions, ContestOrganizationSelectors } from 'scripts/common/logic/contest/organization';
import { get, noop } from 'lodash';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import { showModal } from 'scripts/common/widgets/modal';
import { AntTableMiddleHeaderHeight } from 'scripts/common/constants/table';
import ReactResizeDetector from 'react-resize-detector';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import GroupsEditDialog  from './groups_dialog';

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrganizationGroupList: ContestOrganizationActions.getOrganizationGroupList,
  createOrganizationGroup: ContestOrganizationActions.createOrganizationGroup,
  modifyOrganizationGroup: ContestOrganizationActions.modifyOrganizationGroup,
  deleteOrganizationGroup: ContestOrganizationActions.deleteOrganizationGroup,
}, dispatch);

const mapStateToProps = (state) => {
  const teamGroups = ContestOrganizationSelectors.contestOrganizationGroupListSelector(state);
  return {
    teamGroups,
  };
};

@ContestLoginRequire
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class OrganizationManageGroupsListView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    contest: ContestPropTypes.isRequired,
    getOrganizationGroupList: PropTypes.func,
    createOrganizationGroup: PropTypes.func,
    modifyOrganizationGroup: PropTypes.func,
    deleteOrganizationGroup: PropTypes.func,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    getOrganizationGroupList: noop,
    createOrganizationGroup: noop,
    modifyOrganizationGroup: noop,
    deleteOrganizationGroup: noop,
    teamGroups: [],
  };

  state = {
    tableHeight: 0,
  };

  COLUMNS = [
    {
      dataIndex: 'id',
      title: '#',
      width: 60,
    },
    {
      dataIndex: 'name',
      title: '分组名称',
    },
    {
      dataIndex: 'capacity',
      title: '容量',
      width: 100,
    },
    {
      dataIndex: 'action',
      title: '操作',
      width: 240,
      render: (index, record) => {
        return <>
          <Button type="primary" onClick={this.handleEditGroup(record)}>编辑</Button>
          <Button style={{ marginLeft: '8px' }} type="danger" onClick={this.handleDeleteGroup(record.id)}>删除</Button>
        </>;
      },
    }
  ];

  refreshGroupsList = () => {
    this.props.getOrganizationGroupList({
      contestId: this.props.contest.id,
    });
  };

  handleAddGroup = () => {
    showModal(GroupsEditDialog, {
      title: '新增分组',
      onOk: (dg, state) => {
        this.props.createOrganizationGroup({
          contestId: this.props.contest.id,
          group: state.group,
          onSuccess: () => {
            this.refreshGroupsList();
          },
        });
      },
    });
  };

  handleEditGroup = group => () => {
    showModal(GroupsEditDialog, {
      title: '修改分组',
      group: {
        name: group.name,
        capacity: group.capacity_val,
      },
      onOk: (dg, state) => {
        this.props.modifyOrganizationGroup({
          contestId: this.props.contest.id,
          group: {
            id: group.id,
            ...state.group,
          },
          onSuccess: () => {
            this.refreshGroupsList();
          },
        });
      },
    });
  };

  handleDeleteGroup = gid => () => {
    Modal.confirm({
      title: '提示',
      content: '确定要删除这个分组吗？关联的队伍不会丢失，但将失去会分组信息',
      onOk: () => {
        this.props.deleteOrganizationGroup({
          contestId: this.props.contest.id,
          group_id: gid,
          onSuccess: () => {
            this.refreshGroupsList();
          },
        });
      },
    });
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  renderTable = () => {
    return this.props.teamGroups.map((group) => {
      return {
        key: get(group, 'id'),
        id: get(group, 'id'),
        name: get(group, 'name'),
        capacity: get(group, 'capacity', 0) > 0 ? `${get(group, 'capacity')}队` : '不限制',
        capacity_val: get(group, 'capacity', 0),
      };
    });
  };

  render() {
    return (
      <div className="contest-organization-groups-view">
        <div className="action-bar">
          <Button
            type="primary"
            onClick={this.handleAddGroup}
          >
            新增
          </Button>
        </div>
        <div className="content_layer">
          <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
          <Table
            columns={this.COLUMNS}
            dataSource={this.renderTable()}
            pagination={false}
            scroll={{
              y: this.state.tableHeight - AntTableMiddleHeaderHeight,
              scrollToFirstRowOnChange: true,
            }}
          />
        </div>
      </div>
    );
  }
}

export default OrganizationManageGroupsListView;
