import React from 'react';
import { Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { AccountActions } from 'scripts/common/logic/account';
import { connect } from 'react-redux';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { isEmail } from 'scripts/common/utils/validator';

const mapDispatchToProps = dispatch => bindActionCreators({
  changePasswordMe: AccountActions.changePasswordMe,
}, dispatch);

@connect(null, mapDispatchToProps, null, { forwardRef: true })
class PasswordDialog extends React.PureComponent {
  static propTypes = {
    ...Modal.propTypes,
    mode: PropTypes.string,
    account: AccountPropTypes,
    onOk: PropTypes.func,
    changePasswordMe: PropTypes.func,
  };

  static defaultProps = {
    mode: 'password',
    account: null,
    onOk: noop,
    changePasswordMe: noop,
  };

  state = {
    loading: false,
    old_pwd: '',
    new_pwd: '',
    renew_pwd: '',
    new_email: '',
    new_pwd_same: true,
  };

  isOKDisabled = () => {
    if (this.props.mode === 'password') {
      return (!this.state.old_pwd || !this.state.new_pwd
        || !this.state.renew_pwd || !this.state.new_pwd_same);
    } if (this.props.mode === 'email') {
      return (!this.state.old_pwd || !this.state.new_email || !isEmail(this.state.new_email));
    }
    return true;
  };

  handleChangePassword = () => {
    this.setState({
      loading: true,
    }, () => {
      this.props.changePasswordMe({
        oldpassword: this.state.old_pwd,
        newpassword: this.state.new_pwd,
        onSuccess: () => {
          this.props.onOk();
        },
        onError: () => {
          this.setState({
            loading: false,
          });
        },
      });
    });
  };

  handleInputChange = key => ({ target }) => {
    this.setState({
      [key]: target.value,
    }, () => {
      if (this.props.mode === 'password') {
        this.setState({
          new_pwd_same: this.state.newPwd === this.state.renewPwd,
        });
      }
    });
  };

  handleSave = () => {
    if (this.state.loading || this.isOKDisabled()) return;
    if (this.props.mode === 'password') {
      this.handleChangePassword();
    }
  };

  render() {
    return <Modal
      {...this.props}
      title={this.props.mode === 'password' ? '修改密码' : '修改邮箱'}
      okButtonProps={{
        disabled: this.state.loading || this.isOKDisabled(),
      }}
      onOk={this.handleSave}
    >
      <Form
        labelCol={{ sm: { span: 6 } }}
        wrapperCol={{ sm: { span: 18 } }}
      >
        <Form.Item label="当前密码" required fieldContext={null}>
          <Input.Password
            value={this.state.old_pwd}
            onChange={this.handleInputChange('old_pwd')}
            onPressEnter={this.handleSave}
          />
        </Form.Item>
        {this.props.mode === 'password' && <>
          <Form.Item label="新密码" required fieldContext={null}>
            <Input.Password
              maxLength={16}
              value={this.state.new_pwd}
              onChange={this.handleInputChange('new_pwd')}
              onPressEnter={this.handleSave}
            />
          </Form.Item>
          <Form.Item
            fieldContext={null}
            label="重复新密码"
            required
            validateStatus={this.state.new_pwd_same ? '' : 'error'}
            help={this.state.new_pwd_same ? '' : '新密码和重复密码不一致'}
          >
            <Input.Password
              maxLength={16}
              value={this.state.renew_pwd}
              onChange={this.handleInputChange('renew_pwd')}
              onPressEnter={this.handleSave}
            />
          </Form.Item>
        </>}
        {this.props.mode === 'email' && <Form.Item fieldContext={null} label="新邮箱" required>
          <Input
            value={this.state.new_email}
            onChange={this.handleInputChange('new_email')}
            onPressEnter={this.handleSave}
          />
        </Form.Item>}
      </Form>
    </Modal>;
  }
}

export default PasswordDialog;
