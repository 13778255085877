export const CODE_SUPPORT_COLUMNS = [
  {
    dataIndex: 'language',
    title: '语言',
  },
  {
    dataIndex: 'version',
    title: '版本',
    align: 'center',
    width: 140,
  },
  {
    dataIndex: 'compile',
    title: '编译',
  },
  {
    dataIndex: 'run',
    title: '执行',
  }
];

export const JUDGE_STATUS_COLUMNS = [
  {
    dataIndex: 'name',
    title: '状态',
    width: 140,
  },
  {
    dataIndex: 'en',
    title: '英文对照',
    width: 240,
  },
  {
    dataIndex: 'desc',
    title: '说明',
  }
];

export const CODE_SUPPORT = [
  {
    key: 'c',
    language: 'C',
    version: 'gcc 5.4',
    compile: 'gcc -ansi -fno-asm -Wall -std=c99 -lm',
    run: './program',
  },
  {
    key: 'cpp',
    language: 'C++',
    version: 'gcc 5.4',
    compile: 'gcc -ansi -fno-asm -Wall -lm --static',
    run: './program',
  },
  {
    key: 'java',
    language: 'Java',
    version: 'OpenJDK 1.8',
    compile: 'javac -encoding utf-8',
    run: 'java -client -Dfile.encoding=utf-8',
  },
  {
    key: 'python2',
    language: 'Python',
    version: '2.7',
    compile: '---',
    run: 'python2 -u program.py',
  },
  {
    key: 'python3',
    language: 'Python',
    version: '3.5',
    compile: '---',
    run: 'python3 -u program.py',
  },
  {
    key: 'go',
    language: 'Go',
    version: '1.10',
    compile: 'go build -o program program.go',
    run: './program',
  },
  {
    key: 'nodejs',
    language: 'NodeJS',
    version: '8.11',
    compile: '---',
    run: 'node program.js',
  },
  {
    key: 'php',
    language: 'PHP',
    version: '7.x',
    compile: 'php -l -f program.php (语法检查)',
    run: 'php -f program.php',
  },
  // {
  //   key: 'ruby',
  //   language: 'Ruby',
  //   version: '2.3',
  //   compile: 'ruby -c program.rb (语法检查）',
  //   run: 'ruby program.rb',
  // },
  {
    key: 'rust',
    language: 'Rust',
    version: '1.5',
    compile: 'rustc program.rb -o program',
    run: './program',
  }
];

export const JUDGE_STATUS = [
  {
    flag: -2,
    key: 'queuing',
    name: '队列中',
    en: 'Queuing',
    desc: '可能短时间内提交过多，判题机无法第一时间反馈评测结果，因此后续提交的程序将暂时处于排队状态。这个过程一般不会很长，如果长时间处于这个状态，可能是网络故障或系统发生故障，则请耐心等待系统管理员处理，也可以使用邮件催促管理员解决。',
  },
  {
    flag: -1,
    key: 'running',
    name: ' 运行中',
    en: 'Running',
    desc: '你的程序正在被判题机执行。如果这个状态长时间没有改变，情况同上。',
  },
  {
    flag: 0,
    key: 'ac',
    name: '评测通过',
    en: 'Accepted (AC)',
    desc: '恭喜你，你的代码运行测试数据后反馈的结果与题目预设的参考结果完全吻合。',
  },
  {
    flag: 1,
    key: 'pe',
    name: '格式错误',
    en: 'Presentation Error (PE)',
    desc: '当判题模式为严格模式时，存在这个状态。表示评测机发现您的答案在格式上有问题。虽然你的程序貌似输出了正确的结果，但是这个结果的格式有点问题，并不能和参考结果完全吻合。请检查程序的输出是否多了或者少了空格（" "）、制表符（"\\t"）或者换行符（"\\n"）。PE不会中断判题进程，因此只有所有测试数据均为PE时会判题结果为PE。',
  },
  {
    flag: 4,
    key: 'wa',
    name: '答案错误',
    en: 'Wrong Answer (WA)',
    desc: '评测机发现你的答案与参考数据不匹配。请检查你的程序代码，看看是算法写错了？没看清楚题目？还是因为数据精确度不够导致误差？请记住，判题机会逐个字检查程序的输出答案，哪怕错了一个字符，都会认为答案是错误的。在严格模式下触发WA，判题机将终止本次判题进程',
  },
  {
    flag: 2,
    key: 'tle',
    name: '超过时间限制',
    en: 'Time Limit Exceeded (TLE)',
    desc: '你的程序运行的时间已经超出了这个题目的时间限制，请检查你的程序代码。通常是因为循环没有及时终止，或者算法不是最优的，时间复杂度高等所导致的。注意，TLE是以一组测试数据为单位限制的，触发TLE后，判题机将终止本次判题进程。',
  },
  {
    flag: 3,
    key: 'mle',
    name: '超过内存限制',
    en: 'Memory Limit Exceeded (MLE)',
    desc: '你的程序运行的内存已经超出了这个题目的内存限制，请检查你的程序代码。通常是因为申请了太大的数组，或者递归函数深度太高等原因造成的。注意，MLE是以一组测试数据为单位限制的，触发MLE后，判题机将终止本次判题进程。',
  },
  {
    flag: 5,
    key: 'ole',
    name: '输出内容超限',
    en: 'Output Limit Exceeded (OLE)',
    desc: '你的程序输出内容太多，超过了这个题目的输出限制，检查你的程序代码，并删除多余的提示性输出（如：请输入xxx，Please enter xxx等）。通常情况下，如果程序输出的内容超过题目参考答案内容的2倍大小将引发此错误。',
  },
  {
    flag: 6,
    key: 're',
    name: '运行时错误',
    en: 'Runtime Error (RE)',
    desc: '你的程序在运行期间执行了非法的操作，请检查你的程序代码。通常是因为数组越界、无限制递归、除数为0、非法内存操作、浮点数超限、栈溢出等问题引发的。触发RE后，判题机将终止本次判题进程。',
  },
  {
    flag: 7,
    key: 'ce',
    name: '编译错误',
    en: 'Compilation Error (CE)',
    desc: '表示你的程序语法有问题，无法通过编译，请检查你的程序代码。你可以查看评测机报告，根据编译器反馈的错误信息，修正你的程序。',
  },
  {
    flag: 8,
    key: 'se',
    name: '系统错误',
    en: 'System Error (SE)',
    desc: '可能是由于网络故障或者系统故障引发的问题，这个结果将不会对你造成任何损失（如记错，扣分等）。偶然情况请忽略并重试，如果频繁出现，请耐心等待系统开发者处理，也可以使用邮件催促解决。',
  },
  {
    flag: 9,
    key: 'rejudge',
    name: '重判',
    en: 'Rejudge',
    desc: '此状态同【队列中】状态，仅标识用。',
  }
];

export const CODE_EXAMPLE_C = `
#include <stdio.h>
int main(){
  int a,b;
  while(scanf("%d %d", &a, &b) != EOF){
    printf("%d\\n",a+b);
  }
  return 0;
}`;

export const CODE_EXAMPLE_CPP = `
#include <iostream>
using namespace std;
int main(){
    int a,b;
    while(cin >> a >> b){
        cout << a+b << endl;
    }
    return 0;
}
`;

export const CODE_EXAMPLE_JAVA = `
import java.util.Scanner;
  public class Main {
  public static void main(String[] args){
      Scanner scan = new Scanner(System.in);
      while(scan.hasNext()){
          int a = scan.nextInt();
          int b = scan.nextInt();
          System.out.println(String.format("%d", a + b));
      }
  }
}
`;
export const CODE_EXAMPLE_PY2 = `
# -*- coding: utf-8 -*-

while True:
    try:
        a, b = map(int, raw_input().split())
    except:
        break
    
    print(a + b)
`;
export const CODE_EXAMPLE_PY3 = `
# -*- coding: utf-8 -*-
# coding:utf-8

import sys
import codecs
sys.stdout = codecs.getwriter("utf-8")(sys.stdout.detach())

while True:
    try:
        a, b = map(int, input().split())
    except:
        break
      
    print(a + b)
`;
export const CODE_EXAMPLE_NODEJS = `
const readline = require('readline').createInterface({
    input:process.stdin,
    output: process.stdout,
});
readline.on('line', function(line) {
    const rel = line.split(' ');
    if (rel.length === 2) {
        console.log(parseInt(rel[0], 10) + parseInt(rel[1], 10))
    }
});
`;
export const CODE_EXAMPLE_GO = `
package main

import "fmt"

func main() {
    var a,b int
    for {
        _, e := fmt.Scanln(&a, &b)
        if (e != nil) {
            break
        }
        fmt.Println(a+b)
    }
}
`;
export const CODE_EXAMPLE_PHP = `
<?php
while ($dat = fgets(STDIN)){
    $dat = explode(" ", $dat);
    if (count($dat) == 2) {
        echo sprintf("%d\\n", intval($dat[0]) + intval($dat[1]));
    }
}
`;
export const CODE_EXAMPLE_RUBY = `
while rel = gets
   rel = rel.split(' ')
   puts rel[0].to_i + rel[1].to_i
end
`;
export const CODE_EXAMPLE_RUST = `
use std::io;
fn main() -> io::Result<()> {
    loop {
        let mut buffer = String::new();
        let bytes = io::stdin().read_line(&mut buffer)?;
        if bytes == 0 {
            break;
        }
        let nums: Vec<&str> = buffer.trim().split(' ').collect();
        let num: isize = nums[0].to_string().parse::<isize>().unwrap()
            + nums[1].to_string().parse::<isize>().unwrap();
        println!("{}", num);
    }
    Ok(())
}`;

export const CODE_EXAMPLE = [
  {
    name: 'C语言',
    code: CODE_EXAMPLE_C,
  },
  {
    name: 'C++',
    code: CODE_EXAMPLE_CPP,
  },
  {
    name: 'Java',
    code: CODE_EXAMPLE_JAVA,
  },
  {
    name: 'Python 2',
    code: CODE_EXAMPLE_PY2,
  },
  {
    name: 'Python 3',
    code: CODE_EXAMPLE_PY3,
  },
  {
    name: 'Go',
    code: CODE_EXAMPLE_GO,
  },
  {
    name: 'NodeJs',
    code: CODE_EXAMPLE_NODEJS,
  },
  {
    name: 'PHP',
    code: CODE_EXAMPLE_PHP,
  },
  // {
  //   name: 'Ruby',
  //   code: CODE_EXAMPLE_RUBY,
  // },
  {
    name: 'Rust',
    code: CODE_EXAMPLE_RUST,
  }
];

export const CONTRIBUTOR = {
  small_red_fish: {
    name: '肖红玉',
    classname: '信息技术学院',
    email: '',
    github: '',
    color: 'gold',
    isTeacher: true,
  },
  lancelrq: {
    name: '小七',
    classname: '14软件2班',
    email: 'lancelrq@wejudge.net',
    github: 'lancelrq',
    color: 'blue',
  },
  lzl: {
    name: '121',
    classname: '17软件2班',
    email: '121@wejudge.net',
    github: 'shoogoome',
    color: 'blue',
  },
  ztr: {
    name: 'ztr',
    classname: '17软件2班',
    email: 'thelns@wejudge.net',
    github: 'TtTRz',
    color: 'blue',
  },
  xj: {
    name: 'Jing',
    classname: '17软件2班',
    email: 'xjfn26@outlook.com',
    github: 'Genense',
    color: 'pink',
  },
  dickqi: {
    name: '董杉菜',
    classname: '17软件2班',
    email: 'dickqi@wejudge.net',
    github: 'dickqi',
    color: 'blue',
  },
  kingswwong: {
    name: 'kingswwong',
    classname: '15软件2班',
    email: '',
    github: 'kingswwong',
    color: 'pink',
  },
  cycsics: {
    name: 'Cycsics',
    classname: '16软件2班',
    email: 'cycsics@wejudge.net',
    github: 'Cycsics',
    color: 'blue',
    mascot: true,
  },
  csharb: {
    name: 'C-sharb',
    classname: '17软件2班',
    email: '',
    github: 'C-sharb',
    color: 'blue',
  },
  wyq: {
    name: 'wyq',
    classname: '17计科1班',
    email: 'jade7748@wejudge.net',
    github: 'YingqhiW',
    color: 'pink',
  },
  yyyr: {
    name: 'yyyr',
    classname: '17计科1班',
    email: 'yyrr13@wejudge.net',
    github: 'littleyo',
    color: 'pink',
  },
  whizzkid: {
    name: 'whizzkid',
    classname: '17计科1班',
    email: 'whizzkid@wejudge.net',
    github: 'whizzkid817',
    color: 'pink',
  },
  jackyinn: {
    name: '殷宇杰',
    classname: '17计科1班',
    email: 'jackyinn@wejudge.net',
    github: 'JackYinn',
    color: 'blue',
  },
  plus_c_tian: {
    name: '栖星鬼',
    classname: '17数媒2班',
    email: '7starX@wejudge.net',
    github: '',
    color: 'pink',
  },
  hyy: {
    name: '黄颖妍',
    classname: '15软件',
    email: '',
    github: '',
    color: 'pink',
    mascot: true,
  },
  crt: {
    name: '陈若桐',
    classname: '15软件',
    email: '',
    github: '',
    color: 'pink',
    mascot: true,
  },
  wpy: {
    name: '吴佩仪',
    classname: '15软件',
    email: '',
    github: '',
    color: 'pink',
    mascot: true,
  },
  hjy: {
    name: '黄靖滢',
    classname: '15软件',
    email: '',
    github: '',
    color: 'pink',
    mascot: true,
  },
};
