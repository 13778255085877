import React from 'react';
import { get } from 'lodash';
import { Divider, Result, Button, Layout } from 'antd';
import { SmileFilled } from '@ant-design/icons';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import {
  ContestAccountPropTypes,
  ContestAuthPropTypes,
  ContestPropTypes
} from 'scripts/common/prop-types/contest';
import {
  ContestAccountRoleEnum,
  RegisterTypeEnum
} from 'scripts/common/enums/contest';
import { nowTime } from 'scripts/common/utils/time_formatter';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AccountRoutes, ContestRoutes } from 'scripts/apps/routes';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import RichtextContext from 'scripts/common/widgets/richtext_context';

@withContestContext
class ContestHomeView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    contest: ContestPropTypes,
    account: ContestAccountPropTypes,
    loginStatus: ContestAuthPropTypes,
  };

  static defaultProps = {
    contest: {},
    account: null,
    loginStatus: null,
  };

  state = {};

  handleEnrollClick = () => {
    const { history } = this.props;
    history.push(buildPath(
      ContestRoutes.ACCOUNT.ENROLL,
      { contestId: this.props.contest.id }
    ));
  };

  handleLoginClick = (refresh = false) => {
    const { history } = this.props;
    const url = buildPath(
      ContestRoutes.ACCOUNT.LOGIN,
      { contestId: this.props.contest.id }
    );
    if (refresh === true) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  handleRegisterClick() {
    window.open(buildPath(AccountRoutes.AUTH.REGISTER));
  }

  renderAccountMeta = (title, content, key) => {
    return <div className="card-content-item" key={key}>
      <div className="item-title">{title}：</div>
      <div className="item-body">{content}</div>
    </div>;
  };

  renderAccountCard = () => {
    const isLogined = get(this.props.loginStatus, 'logined', false);
    if (isLogined) {
      const { account, history, contest } = this.props;
      const role = get(account, 'role', 0);
      const enrollStartTime =  get(this.props.contest, 'configs.enroll_start_time', 0);
      const enrollEndTime =  get(this.props.contest, 'configs.enroll_end_time', 0);
      const timeNow = nowTime().unix();
      const enableEnrolled = get(this.props.contest, 'configs.enroll', false);
      const isEnrolled = !(timeNow > enrollStartTime && timeNow < enrollEndTime) || ((role === ContestAccountRoleEnum.REFEREE.value) || (isLogined  && get(this.props.account, 'is_enrolled', false)));
      const canEditInfo = (timeNow < enrollEndTime)
        && (role === ContestAccountRoleEnum.COMPETITOR.value);
      const isGroupContest = get(contest, 'team_type', 0) === RegisterTypeEnum.GROUP.value;
      const accountMetas = [{
        key: 'nickname',
        title: '队名',
        content: get(account, 'nickname', ''),
      }, {
        key: 'realname',
        title: (isGroupContest && (role === ContestAccountRoleEnum.TEAM.value)) ? '小组成员' : '真实姓名',
        content: get(account, 'realname', ''),
      }];
      // 如果是团队赛并且现在是个人账户，去掉队名信息
      if (isGroupContest && (role === ContestAccountRoleEnum.COMPETITOR.value)) {
        accountMetas.shift();
      }
      // 教练信息
      if (get(account, 'coachname')) {
        accountMetas.push({
          key: 'coachname',
          title: '教练',
          content: get(account, 'coachname'),
        });
      }
      if (get(account, 'schoolname')) {
        accountMetas.push({
          key: 'schoolname',
          title: '学校',
          content: get(account, 'schoolname'),
        });
      }
      return enableEnrolled && !isEnrolled ? <Result
        title="请先报名比赛"
        extra={
          <Button type="primary" key="console" onClick={this.handleEnrollClick}>
            去报名
          </Button>
        }
      /> : <>
        <div className="card-title">
          参赛信息
        </div>
        <div className="card-content">
          {accountMetas.map(item => this.renderAccountMeta(item.title, item.content, item.key))}
        </div>
        {canEditInfo && <>
          <Divider dashed />
          <Button
            type="primary"
            onClick={() => history.push(buildPath(ContestRoutes.ACCOUNT.ENROLL, {
              contestId: this.props.contest.id,
            }))}
            block
          >
            修改信息
          </Button>
        </>}
      </>;
    }
    return <>
      <Result
        icon={<SmileFilled />}
        title="欢迎参加本次比赛"
        extra={<>
          <Button type="primary" onClick={this.handleLoginClick}>登录</Button>
          <Button onClick={this.handleRegisterClick}>注册 & 报名</Button>
        </>}
      />
    </>;
  };

  renderPersonalCard = () => {
    const role = get(this.props.account, 'role', 0);
    const isReferee = ContestAccountRoleEnum.REFEREE.value === role;
    return <div className="wejudge-content-info-card">
      {!isReferee ? this.renderAccountCard() : <Result
        title="你正在以裁判身份登入本次比赛"
        icon={<SmileFilled />}
      />}
    </div>;
  };

  render() {
    return <Layout.Content className="contest-layout-content wejudge-contest-home-page">
      <div className="home-page-wrap">
        <div className="left-layout">
          <RichtextContext className="index-content">{get(this.props.contest, 'description', '') || '欢迎参加本次比赛！'}</RichtextContext>
        </div>
        <div className="right-layout">
          {this.renderPersonalCard()}
        </div>
      </div>
    </Layout.Content>;
  }
}

export default ContestHomeView;
