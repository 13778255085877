import 'styles/wejudge/error.scss';

import React from 'react';
import { get, isEqual } from 'lodash';
import Raven from 'raven-js';
import { CloseCircleOutlined, CloudUploadOutlined, HomeOutlined } from '@ant-design/icons';
import { Button, Result, Typography } from 'antd';

const { Paragraph, Text } = Typography;

class ErrorBoundary extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  state = {
    lastError: null,
    error: false,
  };

  componentDidCatch(error, info) {
    if (!isEqual(this.state.lastError, error)) {
      Raven.captureException(error, {
        extra: info,
        release: `${get(process.env, 'BUILD_ENV.version', 'Local')} [${get(process.env, 'BUILD_ENV.commit', 'Local')}]`,
      });
    }
    this.setState({
      lastError: error,
      error: true,
    });
    if (process.env.NODE_ENV === 'development') {
      console.error('[dev] 侦测到未能捕获的异常!');
      console.dir(error);
      console.dir(info);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <>
          <Result
            status="error"
            title="抱歉，页面无法显示"
            subTitle="请刷新页面重试，或者是前往别的页面"
            extra={[
              <Button
                type="primary"
                onClick={() => Raven.lastEventId() && Raven.showReportDialog()}
              >
                <CloudUploadOutlined /> 报告错误
              </Button>,
              <Button
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                <HomeOutlined /> 返回首页
              </Button>
            ]}
          >
            <div className="desc">
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  导致这个问题可能的原因是:
                </Text>
              </Paragraph>
              <Paragraph>
                <CloseCircleOutlined style={{ color: 'red' }} /> 服务器返回了意外的数据
              </Paragraph>
              <Paragraph>
                <CloseCircleOutlined style={{ color: 'red' }} /> 我们的网页代码执行时遇到了意外的输入
              </Paragraph>
              <Paragraph>
                <CloseCircleOutlined style={{ color: 'red' }} /> 网络有点小问题，导致相关资源没有正确加载
              </Paragraph>
              <Paragraph>
                如果问题持续出现，请尽快与管理员联系 webmaster@wejudge.net
              </Paragraph>
            </div>
          </Result>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

/**
<div className="web-app-error">
  哎呀，出错了！请刷新页面重试，或者是前往别的页面
  <div className="action-buttons">
    <Button
      type="primary"
      onClick={() => Raven.lastEventId() && Raven.showReportDialog()}
    >
      <Icon type="cloud-upload" theme="outlined" /> 报告错误
    </Button>
    <Button
      onClick={() => {
        window.location.href = '/';
      }}
    >
      <Icon type="home" theme="outlined" /> 返回首页
    </Button>
  </div>
</div>
 */
