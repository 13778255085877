// import 'antd/dist/antd.less';
import 'styles/root.scss';
// import 'braft-editor/dist/index.css';
import 'styles/common/widgets/barrager.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';
import { get, noop, endsWith } from 'lodash';
import zhCN from 'antd/es/locale/zh_CN';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  BrowserRouter, Switch, Route, Redirect 
} from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import { WeJudgeRoutes } from 'scripts/apps/routes';
import AccountAPP from 'scripts/apps/account';
import CollectionAPP from 'scripts/apps/collection/index';
import WeJudgeIndex from 'scripts/apps/wejudge';
import { WeJudge404NotFound } from 'scripts/apps/wejudge/not_found_page';
import { AccountActions } from 'scripts/common/logic/account/action';
import EducationAPP from 'scripts/apps/education/index';
import ExampleAPP from 'scripts/apps/example/index';
import HelperAPP from 'scripts/apps/helper/index';
import ManagementApp from 'scripts/apps/management/index';
import WeChatApp from 'scripts/apps/wechat/index';
import RepositoryApp from 'scripts/apps/repository/index';
import ErrorBoundary from 'scripts/apps/wejudge/error_boundary';
import ContestApp from 'scripts/apps/contest/index';
import AnnounceApp from 'scripts/apps/announcement/index';
import NoticeApp from 'scripts/apps/notice/index';
import { getHostName, getCurrentURL } from 'scripts/common/utils/location_helper';
import ContestShortNameConverter from 'scripts/apps/contest/short_name_convert';
import { cleanInitPageAnimationContainer } from 'scripts/common/utils';

const mapDispatchToProps = dispatch => bindActionCreators({
  checkLogin: AccountActions.checkLogin,
}, dispatch);

@connect(null, mapDispatchToProps)
class DesktopRoot extends React.PureComponent {
  static propTypes = {
    checkLogin: PropTypes.func,
  };

  static defaultProps = {
    checkLogin: noop,
  };

  componentDidMount() {
    this.props.checkLogin({
      onSuccess: () => {
        cleanInitPageAnimationContainer();
      },
    });
  }

  IndexRoute = () => {
    return <Redirect to={WeJudgeRoutes.WEJUDGE_ROOT} />;
  };

  renderAllModules = () => {
    return [
      <Route key="notice" path={WeJudgeRoutes.NOTICE_ROOT} component={NoticeApp} />,
      <Route key="contest" path={WeJudgeRoutes.CONTEST_ROOT} component={ContestApp} />,
      <Route
        key="contest"
        path={WeJudgeRoutes.CONTEST_ROOT_JUMP}
        render={() => <Redirect to={WeJudgeRoutes.CONTEST_LIST} />}
      />,
      <Route key="announcement" path={WeJudgeRoutes.ANNOUNCE_ROOT} component={AnnounceApp} />,
      <Route key="account" path={WeJudgeRoutes.ACCOUNT_ROOT} component={AccountAPP} />,
      <Route key="wejudge" path={WeJudgeRoutes.WEJUDGE_ROOT} component={WeJudgeIndex} exact />,
      <Route key="colltion" path={WeJudgeRoutes.COLLECTION_ROOT} component={CollectionAPP} />,
      <Route key="eduction" path={WeJudgeRoutes.EDUCATION_ROOT} component={EducationAPP} />,
      <Route key="repository" path={WeJudgeRoutes.REPOSITORY_ROOT} component={RepositoryApp} />,
      <Route key="helper" path={WeJudgeRoutes.HELPER_ROOT} component={HelperAPP} />,
      <Route key="example" path={WeJudgeRoutes.EXAMPLE_ROOT} component={ExampleAPP} />,
      <Route key="management" path={WeJudgeRoutes.MANAGEMENT_ROOT} component={ManagementApp} />,
      <Route key="wechat" path={WeJudgeRoutes.WECHAT_ROOT} component={WeChatApp} />,
      <Route key="home" path="/" exact render={this.IndexRoute} />,
      <Route key="default" component={WeJudge404NotFound} />
    ];
  };

  renderContestModuleOnly = () => {
    return [
      <Route key="contest" path={WeJudgeRoutes.CONTEST_ROOT} component={ContestApp} />,
      <Route key="short_name" path={WeJudgeRoutes.SHORT_NAME} exact component={ContestShortNameConverter} />,
      <Route key="short_name_at_domain" path="/" exact component={ContestShortNameConverter} />,
      <Route
        key="default"
        render={() => {
          const path = getCurrentURL();
          window.location.href = get(process.env, 'MASTER_DOMAIN') + path;
          return null;
        }}
      />
    ];
  };

  render() {
    const hostName = getHostName();
    const forContest = endsWith(hostName, 'contest.wejudge.net') || endsWith(hostName, 'cpc.bnuz.edu.cn');
    return (
      <AppContainer>
        <div className="desktop-root">
          <ErrorBoundary>
            <ConfigProvider locale={zhCN}>
              <BrowserRouter>
                <Switch>
                  {forContest ? this.renderContestModuleOnly() : this.renderAllModules()}
                </Switch>
              </BrowserRouter>
            </ConfigProvider>
          </ErrorBoundary>
        </div>
      </AppContainer>
    );
  }
}
export default DesktopRoot;
