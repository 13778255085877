import React from 'react';
import { get, noop, truncate } from 'lodash';
import PropTypes from 'prop-types';
import { Avatar, List, Tooltip } from 'antd';
import {
  UserOutlined, FileTextOutlined, UnlockOutlined, LockOutlined, ShareAltOutlined, SmileOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { CollectionSelectors } from 'scripts/common/logic/collection';
import CollectionThumbImage from 'images/collection_thumb.jpg';
import { CollectionPropTypes } from 'scripts/common/prop-types/collection';
import {
  buildAvatarPath,
  buildPath,
  buildResourcePath
} from 'scripts/common/utils/location_helper';
import IconText from 'scripts/common/widgets/icon-text';
import { CollectionTypeEnum } from 'scripts/common/enums/collection';
import { AccountRoutes } from 'scripts/apps/routes';
import { withRouter } from 'react-router';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountRoleEnum } from 'scripts/common/enums/account';

export const getCollectionType = (props) => {
  const type = CollectionTypeEnum.enumValueOf(get(props.collection, 'type'));

  let icon = <UnlockOutlined />;
  let text = '公开题库';

  if (get(props.collection, 'is_mine')) {
    icon = <SmileOutlined />;
    text = `我发布的${type.typeName}`;
  } else if (type === CollectionTypeEnum.PRIVATE) {
    icon = props.isAdmin ? <LockOutlined /> : <ShareAltOutlined />;
    text = props.isAdmin ? '私有题库' : '共享的私有题库';
  } else if (type === CollectionTypeEnum.SHARED) {
    icon = <ShareAltOutlined />;
    text = '共享题库';
  }
  return { icon, text };
};

/*
* CollectionCard
* */
const CollectionListItemBase = (props) => {
  const thumbImage = get(props.collection, 'thumb_img', '');
  const authorRole = get(props.collection, 'author.role');
  const isTeacher = [
    AccountRoleEnum.TEACHER.value,
    AccountRoleEnum.DEAN.value
  ].indexOf(authorRole) > 0;
  const authorName = isTeacher ? `${get(props.collection, 'author.realname')}老师` : get(props.collection, 'author.nickname');
  const authorAvatar = get(props.collection, 'author.avator');

  const authorId = get(props.collection, 'author.id');

  const renderCollectionTypeItem = () => {
    const ctype = getCollectionType(props);
    return <IconText
      key="list-item-type"
      icon={ctype.icon}
      text={ctype.text}
    />;
  };

  return <List.Item
    actions={[
      <IconText
        key="list-item-author"
        icon={<Avatar
          className="user-avator"
          icon={<UserOutlined />}
          size={16}
          src={buildAvatarPath(authorAvatar)}
        />}
        onClick={authorId ? () => {
          const { history } = props;
          history.push(buildPath(AccountRoutes.SPACE.INDEX, { accountId: authorId }));
        } : null}
        text={authorName}
      />,
      renderCollectionTypeItem(),
      <IconText
        key="list-item-counter"
        icon={<FileTextOutlined />}
        text={`${get(props, 'problemsCount', 0)} 题`}
      />
    ]}
    extra={
      <img
        height={102}
        alt="logo"
        src={thumbImage ? buildResourcePath(thumbImage) : CollectionThumbImage}
      />
    }
  >
    <List.Item.Meta
      title={<a onClick={props.onClick}>{get(props.collection, 'title')}</a>}
      description={<Tooltip title={get(props.collection, 'description')}>
        {truncate(get(props.collection, 'description'), { length: 50 }) || '暂无简介'}
      </Tooltip>}
    />
  </List.Item>;
};

CollectionListItemBase.propTypes = {
  ...RoutePropTypes,
  collection: CollectionPropTypes,
  onClick: PropTypes.func,
  isAdmin: PropTypes.bool,
};

CollectionListItemBase.defaultProps = {
  onClick: noop,
  collection: null,
  isAdmin: false,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const collection = id ? CollectionSelectors.getCollectionEntity(state)(id) : null;
  return {
    collection,
  };
};

export const CollectionListItem = connect(mapStateToProps, null)(
  withRouter(CollectionListItemBase)
);
export default CollectionListItem;
