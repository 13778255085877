import React from 'react';
import { noop, set } from 'lodash';
import {
  Form, Modal, Switch, Col, Row 
} from 'antd';

class ResubmitInfoModal extends React.PureComponent {
  state = {
    code: false,
    nomal: false,
  };

  onChange = keyName => (e) => {
    this.setState({
      ...this.state,
      [keyName]: e,
    });
  };

  render() {
    return (
      <Modal
        {...this.props}
        title="重置作业"
      >
        <Form.Item>
          <Row>
            你可以选择重置以下内容
          </Row>
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={12}>
              代码题：
              <Switch
                checked={this.state.code}
                onChange={this.onChange('code')}
              />
            </Col>
            <Col span={12}>
              非代码题：
              <Switch
                checked={this.state.nomal}
                onChange={this.onChange('nomal')}
              />
            </Col>
          </Row>
        </Form.Item>
      </Modal>
    );
  }
}

export default ResubmitInfoModal;
