import React from 'react';
import { Menu } from 'antd';
import { get } from 'lodash';

const { SubMenu } = Menu;
const MAX_DEEP = 3;

const CreateClassifySubMenu = (classify, selectedId, onSubMenuOpen, onTitleClick) => {
  const {
    id,
    title,
    deep,
    children,
  } = classify;
  const hasChildren = get(classify, 'has_children');
  return deep < MAX_DEEP && id !== 'root' && hasChildren
    ? <SubMenu
      key={id}
      className={selectedId === id ? 'ant-submenu-item-selected' : ''}
      title={
        <span
          style={{ display: 'inline-block' }}
          onClick={(e) => {
            e.stopPropagation();
            onTitleClick({ key: id });
          }}
        >
          {title}
        </span>
      }
      onTitleClick={onSubMenuOpen}
    >
      {children.map((item) => {
        return CreateClassifySubMenu(item, selectedId, onSubMenuOpen, onTitleClick);
      })}
    </SubMenu>
    : <Menu.Item key={id}>
      {title}
    </Menu.Item>;
};

export default CreateClassifySubMenu;
