//import 'styles/collection/answer/Collapse.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Collapse, Tag } from 'antd';
import { noop } from 'lodash';
import Header from './collapseheader_solutions';
import Search from './solutions_search';

const { Panel } = Collapse;
const { CheckableTag } = Tag;

class Collapsepanel extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getFilterList: PropTypes.func,
    collectionId: PropTypes.string.isRequired,
    problemId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    tags: [],
    getFilterList: noop,
  }

  constructor() {
    super();
    this.state = {
      currentIndex: -1,
      autherName: '',
      languageName: '',
      method: '',
      isMethod: false,
    };
    this.clearMethod = this.clearMethod.bind(this);
  }

  setSearch = (name) => {
    this.setState({
      autherName: name, 
    }, () => {
      this.props.getFilterList({
        collectionId: this.props.collectionId,
        id: this.props.problemId,
        query: {
          keyword: `${this.state.autherName}`,
          tags: '',
          limit: 999,
          page: 1,
        },
      }); 
    });
  }

  setLanguage = (name) => {
    this.setState({
      languageName: name, 
    }, () => {
      this.props.getFilterList({
        collectionId: this.props.collectionId,
        id: this.props.problemId,
        query: {
          keyword: `${this.state.autherName}`,
          tags: `${this.state.languageName},${this.state.method}`,
          limit: 999,
          page: 1,
        },
      }); 
    });
  }

  selectitem(item) {
    if (this.state.method === item.name) {
      this.setState({
        currentIndex: -1,
        method: '',
        isMethod: false,
      }, () => {
        this.props.getFilterList({
          collectionId: this.props.collectionId,
          id: this.props.problemId,
          query: {
            keyword: `${this.state.autherName}`,
            tags: `${this.state.languageName}`,
            limit: 999,
            page: 1,
          },
        }); 
      });
    } else {
      this.setState({
        currentIndex: item.id,
        method: item.name,
        isMethod: true,
      }, () => {
        this.props.getFilterList({
          collectionId: this.props.collectionId,
          id: this.props.problemId,
          query: {
            keyword: `${this.state.autherName}`,
            tags: `${this.state.languageName},${this.state.method}`,
            limit: 999,
            page: 1,
          },
        }); 
      }); 
    }
  }

  clearMethod() {
    this.setState({
      currentIndex: -1,
      method: '',
      languageName: '',
      isMethod: false,
    });
  }

  stopPop(e, item) {
    e.stopPropagation();
    if (this.state.method === item.name) {
      this.setState({
        currentIndex: -1,
        method: '',
        isMethod: false,
      });
    } else {
      this.setState({
        currentIndex: item.id,
        method: item.name,
        isMethod: true,
      }); 
    }
  }

  render() {
    return (
     
      <Collapse defaultActiveKey={['1']}>
        <Search searchName={this.setSearch} />
        <Panel
          header={<Header
            tags={this.props.tags} 
            getAllList={this.props.getFilterList}
            collectionId={this.props.collectionId}
            problemId={this.props.problemId}
            setLanguage={this.setLanguage}
            isMethod={this.state.isMethod}
            clearMethod={this.clearMethod}
          />}
          key="1"
          showArrow
        >
          <div className="solutions-tags-foot">  <div className="block-item" key={0} />{
                this.props.tags.filter((arr) => {
                  return arr.language !== true;
                }).map(item => <div className="block-item" key={item.id}>
                  <span className="solutions-tags-method-item" onClick={d => this.stopPop(d, item)}>
                    <CheckableTag
                      key={item.id}
                      checked={item.id === this.state.currentIndex}
                      onChange={() => this.selectitem(item)}
                    >
                      {item.name}
                    </CheckableTag>
                  </span>
                </div>)
        }
          </div>
        </Panel>
      </Collapse>
           
    );
  }
}
export default Collapsepanel;
