import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Tree, Row, Col, Alert, Input, Button, Modal 
} from 'antd';
import {
  get, isArray, noop, isEmpty 
} from 'lodash';
import { CollectionActions, CollectionSelectors } from 'scripts/common/logic/collection';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { ClassifyPropType } from 'scripts/common/prop-types/collection';

const mapStateToProps = (state, props) => {
  return {
    classifies: CollectionSelectors.collectionClassifiesDataSelector(state, props.collectionId),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getClassifiesData: CollectionActions.getClassifiesData,
  addClassify: CollectionActions.addClassify,
  modifyClassify: CollectionActions.modifyClassify,
  deleteClassify: CollectionActions.deleteClassify,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
class ClassifyEditor extends React.PureComponent {
  static propTypes = {
    collectionId: PropTypes.string.isRequired,
    classifies: ClassifyPropType,
    getClassifiesData: PropTypes.func,
    addClassify: PropTypes.func,
    modifyClassify: PropTypes.func,
    deleteClassify: PropTypes.func,
    handleClassifyKey: PropTypes.func,
    hideManage: PropTypes.bool,
  };

  static defaultProps = {
    classifies: {},
    getClassifiesData: noop,
    addClassify: noop,
    modifyClassify: noop,
    deleteClassify: noop,
    handleClassifyKey: noop,
    hideManage: false,
  };

  state = {
    node: {
      id: 'root',
      title: '根节点',
      deep: 0,
    },
    newNode: '',
    loading: false,
    selectedKeys: ['root'],
  };

  dfs = (node, key) => {
    if (node.id !== key) {
      const { children } = node;
      let rel = null;
      for (let i = 0; i < children.length; i++) {
        rel = this.dfs(children[i], key);
        if (rel) {
          break;
        }
      }
      return rel || null;
    }
    return node;
  };

  loadData = (treeNode) => {
    const { getClassifiesData } = this.props;
    return new Promise((resolve) => {
      if (!isEmpty(treeNode.props.children)) {
        resolve();
        return;
      }
      const cid = get(treeNode, 'props.dataRef.id', '');
      getClassifiesData({
        collectionId: this.props.collectionId,
        classifyId: cid,
        onComplete: () => {
          resolve();
        },
      });
    });
  };

  handleSelect = (selectKeys, e) => {
    this.setState({
      node: { ...get(e, 'node.props.dataRef', {}) },
      newNode: '',
      selectedKeys: [get(e, 'node.props.dataRef.id', 'root')],
    });
    this.props.handleClassifyKey({ selectKeys, e });
  };

  handleCreateClassify = () => {
    const { node, newNode } = this.state;
    this.setState({
      loading: true,
    });
    this.props.addClassify({
      collectionId: this.props.collectionId,
      classifyId: node.id,
      title: newNode,
      onComplete: () => {
        this.setState({
          loading: false,
        });
      },
    });
  };

  handleModifyClassify = () => {
    const { node } = this.state;
    this.setState({
      loading: true,
    });
    this.props.modifyClassify({
      collectionId: this.props.collectionId,
      classifyId: node.id,
      title: node.title,
      onComplete: () => {
        this.setState({
          loading: false,
        });
      },
    });
  };

  handleDeleteClassify = () => {
    const { node } = this.state;
    Modal.confirm({
      okType: 'danger',
      title: '删除分类',
      content: '确认要删除当前分类吗？如果有子分类将被一并删除，另外，分类内的题目将变为未分类状态。',
      onOk: () => {
        const parentId = get(node, 'parent.id', 'root');
        this.setState({
          loading: true,
        });
        this.props.deleteClassify({
          collectionId: this.props.collectionId,
          classifyId: node.id,
          parentId,
          onSuccess: () => {
            this.setState({
              node: this.dfs(this.props.classifies, parentId),
              selectedKeys: [parentId],
            });
          },
          onComplete: () => {
            this.setState({
              loading: false,
            });
          },
        });
      },
    });
  };

  renderTreeNodes =  (classify, selectedId, onSubMenuOpen, onTitleClick) => {
    const {
      id,
      title,
      children,
    } = classify;
    const hasChildren = get(classify, 'has_children');
    return {
      key: id,
      title,
      dataRef: classify,
      isLeaf: !hasChildren,
      children: isArray(children) ? children.map((item) => {
        return this.renderTreeNodes(item, selectedId, onSubMenuOpen, onTitleClick);
      }) : [],
    };
  };

  renderTree = () => {
    return <Tree
      showLine
      size="large"
      onSelect={this.handleSelect}
      selectedKeys={this.state.selectedKeys}
      defaultExpandedKeys={['root']}
      loadData={this.loadData}
      treeData={[this.renderTreeNodes(this.props.classifies)]}
    />;
  };

  render() {
    if (!this.props.classifies) return null;
    const { node, loading } = this.state;
    return this.props.hideManage ? this.renderTree() : <Row gutter={8}>
      <Col span={12}>
        {this.renderTree()}
      </Col>
      <Col span={12} className="classify-editor-area">
        <Alert
          type="info"
          message={`节点名称：${node.title}`}
          description={`节点深度：${node.deep}`}
        />
        <Alert
          type="warning"
          message="修改节点"
          description={<>
            <Form.Item>
              <Input
                disabled={node.id === 'root'}
                value={node.title}
                onChange={({ target }) => {
                  this.setState({
                    node: {
                      ...node,
                      title: target.value,
                    },
                  });
                }}
              />
            </Form.Item>
            <Button
              loading={loading}
              type="primary"
              disabled={node.id === 'root'}
              onClick={this.handleModifyClassify}
            >
              保存
            </Button>
          </>}
        />
        <Alert
          type="success"
          message="新增节点"
          description={<>
            <Form.Item>
              <Input
                disabled={node.deep >= 3}
                value={this.state.newNode}
                onChange={({ target }) => {
                  this.setState({
                    newNode: target.value,
                  });
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              disabled={node.deep >= 3}
              loading={loading}
              onClick={this.handleCreateClassify}
            >
              创建
            </Button>
          </>}
        />

        <Alert
          type="error"
          message="删除节点"
          description={<>
            <Button
              disabled={node.id === 'root'}
              loading={loading}
              type="danger"
              onClick={this.handleDeleteClassify}
            >删除</Button>
          </>}
        />
      </Col>
    </Row>;
  }
}

export default ClassifyEditor;
