import 'styles/collection/list.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {
  get, pick, isEqual, isEmpty
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Input, Menu, Pagination, Button,
  Tag, Radio, Drawer, List, Empty, Modal
} from 'antd';
import localStorage from 'localStorage';
import DataKeys from 'scripts/common/constants/data_keys';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { PaginationParams } from 'scripts/common/constants/global';
import { withRouter } from 'react-router-dom';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { CollectionActions, CollectionSelectors } from 'scripts/common/logic/collection';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { CollectionRoutes, CollectionViewsRoutes } from 'scripts/apps/routes';
import { CollectionTypeEnum, CollectionPermissionEnum, CollectionOrderEnum } from 'scripts/common/enums/collection';
import { AccountSelectors } from 'scripts/common/logic/account';
import { CategoryPropTypes } from 'scripts/common/prop-types/category'; 
import { CategoryActions } from 'scripts/common/logic/category/action';
import { CategorySelectors } from 'scripts/common/logic/category/selector';
import  CategoryCollEditor from 'scripts/apps/widgets/category/editor';
import { CommonCategoryTypeEnum } from 'scripts/common/enums/common';
import CenterSpin from 'scripts/common/widgets/center-spin';
import IconText from 'scripts/common/widgets/icon-text';
import { showModal } from 'scripts/common/widgets/modal';
import CollectionInfoEditor from 'scripts/apps/collection/widgets/collection_editor';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { deepFindCategory } from 'scripts/apps/widgets/category/utils';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { CollectionCard } from './widgets/collection_card';
import { CollectionListItem } from './widgets/collection_list_item';


const { Search } = Input;
const { CheckableTag } = Tag;

const AddCollectionModal = (props) => {
  return (<Modal
    {...props}
    title="新建题库"
  >
    <CollectionInfoEditor
      mode="new"
      handleChange={props.onChange}
    />
  </Modal>);
};

AddCollectionModal.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const CommonPickup = ['limit', 'keyword', 'order', 'type'];

const mapDispatchToProps = dispatch => bindActionCreators({
  getCollectionsList: CollectionActions.getCollectionsList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  addCollections: CollectionActions.addCollection,
  getCategoryTree: CategoryActions.getCategoryTree,
  createCategory: CategoryActions.createCategory,
  deleteCategory: CategoryActions.deleteCategory,
  modifyCategory: CategoryActions.modifyCategory,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const { match } = props;
  const  canCreateCollection = get(
    AccountSelectors.getAccountBasePermission(state),
    `collection.${CollectionPermissionEnum.CREATE.value}`,
    false
  );
  const loginAccount = AccountSelectors.getLoginAccount(state);
  const isAdmin = loginAccount
  && (get(loginAccount, 'role', 0) === AccountRoleEnum.ADMINISTRATOR.value);
  const isStudent = !loginAccount
  || (get(loginAccount, 'role', 0) === AccountRoleEnum.STUDENT.value);
  const categoryId = get(match.params, 'categoryId', '');
  const pager = CollectionSelectors.collectionsListViewPagerSelector(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(
    state, DataKeys.LOADING_STATUS_KEY.COLLECTION_LIST_VIEW
  );
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, [...CommonPickup, 'page']),
    category: (!categoryId || categoryId === 'all') ? null : categoryId,
  };
  const categoryTree = CategorySelectors.categoryTreeViewSelector(
    CommonCategoryTypeEnum.COLLECTION.value
  )(state) || [];
  return {
    canCreateCollection,
    queryParams,
    categoryId,
    isLoading,
    paginationParams: pager || props.paginationParams,
    collectionsList: CollectionSelectors.collectionsListViewSelector(state),
    categoryTree,
    isAdmin,
    isStudent,
    loginAccount,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ListView extends React.PureComponent {
  static fetchList = (nextProps) => {
    // Next tick.
    nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COLLECTION_LIST_VIEW, true);
    // 获取题集列表
    const query = pick(
      nextProps.queryParams,
      [...CommonPickup, 'page', 'category']
    );
    nextProps.getCollectionsList({
      query,
      onComplete: () => {
        nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COLLECTION_LIST_VIEW, false);
      },
    });
  };

  static propTypes = {
    canCreateCollection: PropTypes.bool,
    addCollections: PropTypes.func.isRequired,
    collectionsList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })).isRequired,
    ...RoutePropTypes,
    queryParams: PropTypes.shape({}).isRequired,
    paginationParams: PaginationPropTypes,
    getCategoryTree: PropTypes.func.isRequired,
    categoryTree: PropTypes.arrayOf(CategoryPropTypes).isRequired,
    createCategory: PropTypes.func.isRequired,
    deleteCategory: PropTypes.func.isRequired,
    modifyCategory: PropTypes.func.isRequired,
    categoryId: PropTypes.string,
    isAdmin: PropTypes.bool,
    isStudent: PropTypes.bool,
    isLoading: PropTypes.bool,
    loginAccount: AccountPropTypes,
  };

  static defaultProps = {
    categoryId: '',
    isLoading: false,
    canCreateCollection: false,
    paginationParams: { ...PaginationParams },
    isAdmin: false,
    isStudent: true,
    loginAccount: null,
  };

  state = {
    keyword: '',
    newCollection: {},
    queryParams: null,
    categoryEditVisible: false,
    mode: 'listview',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // 如果因为路由的Query发生改变，则发起列表请求
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      ListView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  componentDidMount() {
    if (isEmpty(this.props.categoryTree)) {
      this.handleFetchCategoryList();
    }
    this.loadViewPreferences();
  }

  getViewPreferencesData = () => {
    let pref = {};
    try {
      pref = JSON.parse(localStorage.getItem(DataKeys.LOCAL_STORAGE.COLLECTION_PREF_STORAGE_KEY));
      if (!pref) pref = {};
    } catch (e) {
      pref = {};
    }
    return pref;
  };

  // 载入偏好设置
  loadViewPreferences = () => {
    const pref = this.getViewPreferencesData();
    if (!isEmpty(pref)) {
      this.setState(pref);
    }
  };

  // 保存偏好
  saveViewPreferences = (pkey) => {
    const pref = this.getViewPreferencesData();
    pref[pkey] = get(this.state, pkey, '');
    localStorage.setItem(
      DataKeys.LOCAL_STORAGE.COLLECTION_PREF_STORAGE_KEY,
      JSON.stringify(pref)
    );
  };

  handleManageCategoryClick= () => {
    this.setState({
      categoryEditVisible: true,
    });
  };

  handleFetchCategoryList = () => {
    this.props.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COMMON_CATEGORY_LIST, true);
    this.props.getCategoryTree({
      type: CommonCategoryTypeEnum.COLLECTION.value,
      onSuccess: () => {
        this.props.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.COMMON_CATEGORY_LIST, false);
      },
    });
  };

  handleListModeChange = (e) => {
    this.setState({
      mode: e.target.value,
    }, () => {
      this.saveViewPreferences('mode');
    });
  };

  handleTypeOptionClick = ({ value }) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CollectionRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        type: value,
      }
    ));
  };

  handleCategoryIdChange = (categoryId) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CollectionRoutes.LIST,
      { categoryId: (categoryId === 'all') ? undefined : categoryId },
      match.params,
      pick(this.state.queryParams, CommonPickup)
    ));
  };

  handleDrawerClose = () => {
    this.setState({
      categoryEditVisible: false,
    });
  };

  handleCollectionOrderTabClick = (item) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CollectionRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        order: item.value,
      }
    ));
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CollectionRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        page,
        limit: pageSize,
      }
    ));
  };


  handleSearch = (value) => {
    if (value === 'egdujew') {
      document.body.style.transform = 'rotateY(180deg)';
    } else if (value === 'wejudge') {
      document.body.style.transform = 'rotateY(0deg)';
    }
    if (isEqual(value, get(this.state.queryParams, 'keyword'))) return;
    const { history, match } = this.props;
    history.push(buildPath(
      CollectionRoutes.LIST,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, CommonPickup),
        keyword: value,
      }
    ));
  };

  handleCollectionCardClick = cid => () => {
    const { history } = this.props;
    history.push(buildPath(
      CollectionViewsRoutes.PROBLEMS_LIST,
      { collectionId: cid }
    ));
  };

  handleSearchChange = ({ target }) => {
    this.setState({
      keyword: target.value,
    });
  };

  // 新增题库
  handleAddClick = () => {
    const { history } = this.props;
    const modalBox = showModal(AddCollectionModal, {
      width: 720,
      onOk: () => {
        this.props.addCollections({
          ...this.state.newCollection,
          category: get(this.state.newCollection, 'category.1', get(this.state.newCollection, 'category.0', 0)),
          onSuccess: (result) => {
            history.push(buildPath(
              CollectionRoutes.COLLETION_VIEW,
              {
                collectionId: `${result}`,
              },
              null,
              null
            ));
            modalBox.close();
          },
        });
        return false;
      },
      onChange: (collection) => {
        this.setState({
          newCollection: collection,
        });
      },
    });
  };

  renderCollectionTypeList = () => {
    const collectionTypeList = [
      CollectionTypeEnum.ALL
    ];
    if (this.props.loginAccount) {
      collectionTypeList.push(CollectionTypeEnum.PUBLIC);
      collectionTypeList.push(CollectionTypeEnum.SHARED);
    }
    if (this.props.isAdmin) {
      collectionTypeList.push(CollectionTypeEnum.PRIVATE);
    }
    if (!this.props.isStudent) {
      collectionTypeList.push(CollectionTypeEnum.MINE);
    }
    return collectionTypeList;
  };

  render() {
    const showPagination = get(this.props.paginationParams, 'total', 0) > get(this.props.paginationParams, 'limit', 1);
    const currentCategory = deepFindCategory(this.props.categoryId, this.props.categoryTree);
    const levelOneCategoryId = get(currentCategory, 'parent.id',  get(currentCategory, 'entity.id', 'all')).toString();
    const subCategorys = get(currentCategory, 'parent.children', get(currentCategory, 'entity.children', []));
    const { collectionsList } = this.props;
    return (
      <div className="collection-list-view">
        <div className="list-header">
          <Search
            className="search"
            placeholder="支持搜索题目集名称、创建者"
            enterButton
            onSearch={this.handleSearch}
            onChange={this.handleSearchChange}
            size="large"
            value={this.state.keyword}
          />
          <div className="list-header-menu">
            <Menu
              onClick={(item) => this.handleCategoryIdChange(item.key)}
              selectedKeys={[levelOneCategoryId]}
              mode="horizontal"
              className="category"
            >
              <Menu.Item key="all">全部</Menu.Item>
              {this.props.categoryTree.map(item => (
                <Menu.Item key={item.id.toString()}>
                  {item.title}
                </Menu.Item>
              ))}
            </Menu>
            <div className="category-actions">
              {this.props.isAdmin && <Button
                onClick={this.handleManageCategoryClick}
              >
                分类编辑
              </Button>}
              {this.props.canCreateCollection && <Button
                type="primary"
                onClick={this.handleAddClick}
              >
                新增题库
              </Button>}
            </div>
          </div>
          <div className="header-filter">
            {!isEmpty(subCategorys) && <div className="list-subject">
              <CheckableTag
                key={levelOneCategoryId}
                checked={levelOneCategoryId === this.props.categoryId}
                onChange={() => this.handleCategoryIdChange(levelOneCategoryId)}
                className="list-subject-item"
              >
                不限
              </CheckableTag>
              {subCategorys.map(item => (
                <CheckableTag
                  key={item.id.toString()}
                  checked={this.props.categoryId === item.id.toString()}
                  onChange={() => this.handleCategoryIdChange(item.id.toString())}
                  className="list-subject-item"
                >
                  {item.title}
                </CheckableTag>
              ))}
            </div>}
            <div className="list-arrange">
              <div className="option-selectors">
                <div className="selector-item">
                  <div className="s-title">
                    题库类型：
                  </div>
                  <div className="s-body">
                    {this.renderCollectionTypeList().map((item) => {
                      return <CheckableTag
                        key={item.key}
                        checked={get(this.state, 'queryParams.type', CollectionTypeEnum.ALL.value).toString() === item.value.toString()}
                        className="list-rank"
                        onChange={() => this.handleTypeOptionClick(item)}
                      >
                        {item.desc}
                      </CheckableTag>;
                    })}
                  </div>
                </div>
                <div className="selector-item">
                  <div className="s-title">
                    排序方式：
                  </div>
                  <div className="s-body">
                    {enumMap(CollectionOrderEnum, (item) => {
                      return <CheckableTag
                        key={item.value}
                        checked={get(this.state, 'queryParams.order', CollectionOrderEnum.DEFAULT.value).toString() === item.value.toString()}
                        className="list-rank"
                        onChange={() => this.handleCollectionOrderTabClick(item)}
                      >
                        {item.desc}
                      </CheckableTag>;
                    })}
                  </div>
                </div>
              </div>
              <div className="extra-selector">
                <Radio.Group
                  value={this.state.mode}
                  buttonStyle="solid"
                  onChange={this.handleListModeChange}
                >
                  <Radio.Button value="listview">
                    <IconText icon={<BarsOutlined />} text="列表" />
                  </Radio.Button>
                  <Radio.Button value="card">
                    <IconText icon={<AppstoreOutlined />} text="卡片" />
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames('list-wrap', {
            'listview-mode': this.state.mode === 'listview',
            'card-mode': this.state.mode === 'card',
          })}
        >
          <CenterSpin
            size="large"
            tip="加载中..."
            spinning={this.props.isLoading}
          />
          {!this.props.isLoading && <div className="list-view">
            {!this.props.isLoading && <>
              {this.state.mode === 'card' && <div className="card-wrap">
                {!isEmpty(collectionsList) ? collectionsList.map((item) => <CollectionCard
                  id={item.id}
                  key={item.id}
                  isAdmin={this.props.isAdmin}
                  problemsCount={get(item, 'items_count', 0)}
                  onClick={this.handleCollectionCardClick(item.id)}
                />) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无题库" />}
              </div>}
              {this.state.mode === 'listview' && <List
                itemLayout="vertical"
                dataSource={collectionsList}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无题库" /> }}
                renderItem={(item) => {
                  return <CollectionListItem
                    id={item.id}
                    key={item.id}
                    isAdmin={this.props.isAdmin}
                    problemsCount={get(item, 'items_count', 0)}
                    onClick={this.handleCollectionCardClick(item.id)}
                  />;
                }}
              />}
            </>}
          </div>}
        </div>
        {!this.props.isLoading && showPagination && <div className="list-footer">
          <Pagination
            current={get(this.props.paginationParams, 'page', 1)}
            pageSize={get(this.props.paginationParams, 'limit', 1)}
            showQuickJumper
            showSizeChanger
            pageSizeOptions={['12', '20', '40']}
            onChange={this.handlePageChange}
            total={get(this.props.paginationParams, 'total', 0)}
          />
        </div>}
        <Drawer
          width={400}
          title="分类编辑"
          placement="right"
          onClose={this.handleDrawerClose}
          visible={this.state.categoryEditVisible}
          getContainer={false}
          handledrawerclose="true"
        >
          <CategoryCollEditor
            categoryTree={this.props.categoryTree}
            createCategory={this.props.createCategory}
            modifyCategory={this.props.modifyCategory}
            deleteCategory={this.props.deleteCategory}
            categoryId={this.props.categoryId}
            onClose={this.handleDrawerClose}
            onFetchList={this.handleFetchCategoryList}
            canManageCategory={this.props.isAdmin}
          />
        </Drawer>
      </div>
    );
  }
}

export default ListView;
