import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { buildPath, getCurrentURL } from 'scripts/common/utils/location_helper';
import { ContestAccountSelectors } from 'scripts/common/logic/contest/account';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ContestAccountPropTypes, ContestPropTypes } from 'scripts/common/prop-types/contest';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ContestRootRoutes, ContestRoutes } from 'scripts/apps/routes';

import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';

const ContestManagePermissionCheck = (options = {}) => (Component) => {
  const mapStateToProps = (state) => {
    const contestAccount = ContestAccountSelectors.getLoginContestAccount(state);
    return {
      contestAccount,
    };
  };
  @withContestContext
  @connect(mapStateToProps)
  class ContestManagePermissionCheckComponent extends React.Component {
    static propTypes = {
      ...RoutePropTypes,
      contestAccount: ContestAccountPropTypes.isRequired,
      contest: ContestPropTypes.isRequired,
    };

    state = {
      pass: false,
    };

    static getDerivedStateFromProps(nextProps) {
      const redirectToHome = get(options, 'redirectToHome', true);
      const requireAdmin = get(options, 'requireAdmin', true);
      const isAdmin = nextProps.contestAccount.id === nextProps.contest.author_id;
      const isReferee = get(nextProps.contestAccount, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
      const hasPermission = (isAdmin || (!requireAdmin && isReferee));
      if (!hasPermission) {
        if (redirectToHome) {
          setTimeout(() => {
            nextProps.history.push(buildPath(
              ContestRootRoutes.HOME,
              { contestId: nextProps.contest.id },
              null
            ));
          }, 100);
        }
        return {
          pass: false,
        };
      }
      return {
        pass: true,
      };
    }

    render() {
      if (!this.state.pass) return null;
      return <Component key={this.props.contestAccount.id || 'not_manage_permission'} {...this.props} />;
    }
  }
  return ContestManagePermissionCheckComponent;
};

export default ContestManagePermissionCheck;
