import 'styles/problem/widgets.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tag } from 'antd';
import {
  get, indexOf, noop, join, isArray, isEmpty 
} from 'lodash';
import classnames from 'classnames';
import { indexToChar } from 'scripts/common/utils/unit';
import { ProblemItemPropTypes, ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import RichtextContext from 'scripts/common/widgets/richtext_context';

export const ChoiceItem = (props) => {
  let itemStyle;
  if (props.mode === 'view_answers') {
    itemStyle = {
      wrong: props.isSelected && !props.isCorrectAnswer,
      correct: props.isSelected && props.isCorrectAnswer,
    };
  } else if (props.mode === 'readonly') {
    itemStyle = {
      selected: props.isSelected,
    };
  } else {
    itemStyle = {
      normal: true,
      selected: props.isSelected,
    };
  }
  const handleClick = () => {
    if (props.mode !== 'solving') return;
    if (props.isSelected) props.onDeSelect(props.value);
    else props.onSelect(props.value);
  };
  return (
    <div
      onClick={handleClick}
      className={classnames('choice_item', itemStyle)}
    >
      {props.children}
    </div>
  );
};
ChoiceItem.propTypes = {
  value: PropTypes.number.isRequired,
  isSelected: PropTypes.bool,
  isCorrectAnswer: PropTypes.bool,
  mode: PropTypes.string,
  onSelect: PropTypes.func,
  onDeSelect: PropTypes.func,
};
ChoiceItem.defaultProps = {
  isSelected: false,
  isCorrectAnswer: false,
  mode: 'solving',
  onSelect: noop,
  onDeSelect: noop,
};

class ChoosingProblem extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes.isRequired,
    problemItem: ProblemItemPropTypes.isRequired,
    problemNumber: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      order: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
    // 显示OK按钮
    isShowOk: PropTypes.bool,
    // 工作模式： solving 选择模式, readonly 只读模式， view_answers 答案查看模式，
    mode: PropTypes.string,
    // 多选开关
    multiple: PropTypes.bool,
    // 正确选项
    correctAnswers: PropTypes.arrayOf(PropTypes.number),
    // 默认选项
    defaultChoices: PropTypes.arrayOf(PropTypes.number),
    onSelectChanged: PropTypes.func,
    onOk: PropTypes.func,
  };

  static defaultProps = {
    mode: 'solving',
    problemNumber: '',
    isShowOk: false,
    multiple: false,
    correctAnswers: [],
    defaultChoices: [],
    onSelectChanged: noop,
    onOk: noop,
  };

  state = {
    currentChoices: this.props.defaultChoices || [],
  };

  handleItemSelect = (key) => {
    const currentChoices = this.props.multiple ? [...this.state.currentChoices, key] : [key];
    this.setState({
      currentChoices,
    }, () => {
      if (this.props.multiple) {
        this.props.onSelectChanged(this.state.currentChoices);
      } else {
        this.props.onSelectChanged(this.state.currentChoices[0] || false);
      }
    });
  };

  handleItemDeSelect = (key) => {
    const currentChoices = this.props.multiple
      ? this.state.currentChoices.filter(val => val !== key) : [];
    this.setState({
      currentChoices,
    }, () => {
      if (this.props.multiple) {
        this.props.onSelectChanged(this.state.currentChoices);
      } else {
        this.props.onSelectChanged(this.state.currentChoices[0] || null);
      }
    });
  };

  handleOnOk = () => {
    if (this.props.multiple) {
      this.props.onOk(this.state.currentChoices);
    } else {
      this.props.onOk(this.state.currentChoices[0] || null);
    }
  };

  render() {
    const choiceItems = [...this.props.options];
    const problemType = this.props.multiple ? ProblemTypeEnum.MULTIPLE : ProblemTypeEnum.SINGLE;
    const { correctAnswers } = this.props;
    choiceItems.sort((a, b) => parseInt(a.order, 10) > parseInt(b.order, 10));
    return (<div className="widget_choosing_problem">
      <div className="problem_title">
        #{this.props.problemNumber || get(this.props, 'problem.number', '')}. {this.props.problem.title}
        <Tag style={{ float: 'right' }} color={problemType.color}>{problemType.desc}</Tag>
      </div>
      <RichtextContext className="problem_description" content={this.props.problem.description} />
      <div className="choices_group">
        {choiceItems.map((choice, index) => {
          const keyId = index + 1;
          return (<ChoiceItem
            key={keyId}
            value={keyId}
            mode={this.props.mode === 'view_answers' ? (isArray(correctAnswers) && !isEmpty(correctAnswers) ? 'view_answers' : 'readonly') : this.props.mode}
            onSelect={this.handleItemSelect}
            onDeSelect={this.handleItemDeSelect}
            isCorrectAnswer={isArray(correctAnswers) && indexOf(correctAnswers, keyId) > -1}
            isSelected={indexOf(this.state.currentChoices, keyId) > -1}
          >
            <div className="choice_item_number">{indexToChar(keyId)}. </div>
            <pre className="choice_item_content">{choice.name}</pre>
          </ChoiceItem>);
        })}
      </div>
      {isArray(correctAnswers) && !isEmpty(correctAnswers) && this.props.mode === 'view_answers' ? <div className="answer_view">
        <div className="explain">
          <div className="answer_view_title">正确答案：</div>
          <div className="answer_view_content">
            {join(correctAnswers.map(k => indexToChar(k)), '、')}
          </div>
        </div>
        {this.props.problem.explain && <div className="explain">
          <div className="answer_view_title">解析：</div>
          <div
            className="answer_view_content"
            dangerouslySetInnerHTML={{ __html: this.props.problem.explain }}
          />
        </div>}
      </div> : this.props.isShowOk && this.props.mode === 'solving'
        && <Button
          className="submit-btn"
          type="primary"
          onClick={this.handleOnOk}
        >
          确定
        </Button>}
    </div>);
  }
}

export default ChoosingProblem;
