import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { buildPath, getCurrentURL } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ContestAccountSelectors } from 'scripts/common/logic/contest/account';
import { ContestAuthPropTypes } from 'scripts/common/prop-types/contest';
import { ContestRoutes } from 'scripts/apps/routes';

const ContestLoginRequire = (Component, options = {}) => {
  const mapStateToProps = (state) => {
    const contestAuthInfo = ContestAccountSelectors.getContestLoginStatus(state);
    const contestAccount = ContestAccountSelectors.getLoginContestAccount(state);
    return {
      contestAuth: {
        ...contestAuthInfo,
        contestAccount,
      },
    };
  };
  @connect(mapStateToProps)
  class ContestLoginRequireComponent extends React.Component {
    static propTypes = {
      ...RoutePropTypes,
      contestAuth: ContestAuthPropTypes.isRequired,
    }

    state = {};

    static getDerivedStateFromProps(nextProps) {
      const redirectToLogin = get(options, 'redirectToLogin', true);
      const currentUrl = getCurrentURL();
      if (!nextProps.contestAuth.fetched) return null;
      if (!nextProps.contestAuth.logined) {
        if (redirectToLogin) {
          setTimeout(() => {
            nextProps.history.push(buildPath(
              ContestRoutes.ACCOUNT.LOGIN,
              { contestId: nextProps.match.params.contestId },
              null,
              { from: currentUrl }
            ));
          }, 100);
        }
        return null;
      }
      return null;
    }

    render() {
      if (!this.props.contestAuth.fetched || !this.props.contestAuth.logined) return null;
      return <Component key={this.props.contestAuth.id || 'not_contest_auth'} {...this.props} />;
    }
  }
  return ContestLoginRequireComponent;
};

export default ContestLoginRequire;
