import 'styles/contest/support/print.scss';

import React from 'react';
import { Table, Button, Tag } from 'antd';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import ReactResizeDetector from 'react-resize-detector';
import { AntTableOutsideHeight } from 'scripts/common/constants/table';

@withContestContext
class PrinterListTable extends React.PureComponent {
  static propTypes = {
    datas: PropTypes.arrayOf(PropTypes.shape({})),
    contest: ContestPropTypes,
    isManager: PropTypes.bool,
    paginationParams: PaginationPropTypes,
    onPageChange: PropTypes.func,
    onDelPrinter: PropTypes.func.isRequired,
    onDoProcess: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onPageChange: noop,
    datas: [],
    paginationParams: {},
    isManager: false,
    contest: {},
  };

  state = {
    tableHeight: 0,
  };

  COLUMNS = [{
    title: '#ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 80,
  }, {
    title: '提交者',
    dataIndex: 'author',
    key: 'author',
  }, {
    title: '提交时间',
    dataIndex: 'time',
    key: 'time',
    width: 240,
  }, {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 140,
  }, {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: 200,
    align: 'center',
  }];

  handleProcess = item => () => {
    this.props.onDoProcess(item);
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  renderStatusTag = (status) => {
    if (status === 0) {
      return <Tag color="#2db7f5">待打印</Tag>;
    } if (status === 1) {
      return <Tag color="#87d068">已打印</Tag>;
    }
    return <Tag color="#f50">拒绝打印</Tag>;
  };

  renderPrinterData = () => {
    if (this.props.datas.length === 0) return [];
    return this.props.datas.map((item) => {
      return {
        id: get(item, 'id', '-'),
        author: <div>{get(item, 'author.realname', '-')} ({get(item, 'author.nickname', '-')})</div>,
        time: formatTime(get(item, 'create_time', ''), 'LONG_DATETIME'),
        status: this.renderStatusTag(item.status),
        action: <>
          <Button
            type="primary"
            onClick={this.handleProcess(item)}
          >
            {this.props.isManager ? '查看/处理' : '查看'}
          </Button>
          <Button
            type="danger"
            onClick={this.props.onDelPrinter(item)}
            disabled={!this.props.isManager && item.status !== 0}
            style={{ marginLeft: '8px' }}
          >
            删除
          </Button>
        </>,
      };
    });
  };

  render() {
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 40),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.props.onPageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    const data = this.renderPrinterData();
    return (
      <div className="printer_table_wrap">
        <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
        <Table
          scroll={{
            y: this.state.tableHeight - AntTableOutsideHeight,
            scrollToFirstRowOnChange: true,
          }}
          pagination={optPagination}
          className="table"
          columns={this.COLUMNS}
          dataSource={data}
        />
      </div>
    );
  }
}

export default PrinterListTable;
