import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { Typography, Table } from 'antd';
import RichtextContext from 'scripts/common/widgets/richtext_context';

class PrinterBaseTemplates extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    contest: ContestPropTypes.isRequired,
    item: PropTypes.shape({}),
    accounts: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    item: {},
    type: 'printer',
    accounts: [],
  };

  state = {

  };

  COLUMNS = [{
    title: '账户名',
    dataIndex: 'username',
    key: 'username',
    align: 'center',
    width: 140,
  }, {
    title: '队伍名称',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center',
  }, {
    title: '参赛队员',
    dataIndex: 'realname',
    key: 'realname',
    align: 'center',
  }, {
    title: '密码',
    dataIndex: 'clear_password',
    key: 'clear_password',
    align: 'center',
    width: 120,
  }];

  renderAccountData = () => {
    if (this.props.accounts.length === 0) return null;
    return this.props.accounts.map((item) => {
      return {
        username: item.username,
        nickname: item.nickname,
        realname: item.realname,
        clear_password: item.clear_password,
      };
    });
  };

  renderCompetitorPage = () => {
    return <>
      <div style={{ margin: '0 4px' }}>
        比赛：{get(this.props.contest, 'title', '未知')}
      </div>
      <div style={{ margin: '0 4px' }}>
        选手：{get(this.props.item, 'author.nickname', '未知')}
      </div>
      <div style={{ margin: '0 4px' }}>
        时间：{formatTime(get(this.props.item, 'create_time', ''), 'LONG_DATETIME')}
      </div>
      <hr />
      <RichtextContext>{get(this.props.item, 'content', '')}</RichtextContext>
    </>;
  };

  renderTeamAccountListPage = () => {
    return <>
      <div style={{ fontSize: '16px', textAlign: 'center' }}>
        {get(this.props.contest, 'title', '未知')}
      </div>
      <Table
        bordered
        size="small"
        columns={this.COLUMNS}
        dataSource={this.renderAccountData()}
        pagination={false}
      />
    </>;
  };

  render() {
    if (this.props.type === 'printer') {
      return this.renderCompetitorPage();
    } if (this.props.type === 'team_account') {
      return this.renderTeamAccountListPage();
    }
    return null;
  }
}

export default PrinterBaseTemplates;
