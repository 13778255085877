import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { createPermissionChecker, isWeJudgeUUID } from 'scripts/common/utils/validator';
import { Spin } from 'antd';
import { CollectionProblemPropTypes } from 'scripts/common/prop-types/collection';
import connect from 'react-redux/es/connect/connect';
import { CollectionProblemActions, CollectionProblemsSelectors } from 'scripts/common/logic/collection';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { bindActionCreators } from 'redux';
import ObjectiveProblemView from 'scripts/apps/problem/widgets/objective_problem';
import ProblemTagsView from 'scripts/apps/problem/widgets/problem_tags';
import { ProblemPermissionEnum } from 'scripts/common/enums/problem';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import { AccountPropTypes } from 'scripts/common/prop-types/account';

const LOADING_STATUS_KEY = 'collection_normal_problem_view';

@connect((state, props) => {
  const { problemId } = props;
  const collectionProblem =    CollectionProblemsSelectors.getCollectionProblemEntity(state)(problemId);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const problemPermission = createPermissionChecker(get(collectionProblem, 'problem.permissions_calculated', {}));
  const canManageProblem = problemPermission(ProblemPermissionEnum.MANAGE);
  return {
    problemId,
    isLoading,
    currentAccount: AccountSelectors.getLoginAccount(state),
    collectionProblem,
    canManageProblem,
  };
}, dispatch => bindActionCreators({
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getCollectionProblem: CollectionProblemActions.getCollectionProblem,
  getCollectionProblemEntity: CollectionProblemActions.getCollectionProblemEntity,
}, dispatch))
class NormalProblemView extends React.PureComponent {
  static updateProblemEntity = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getCollectionProblem({
      collectionId: nextProps.collectionId,
      id: nextProps.problemId,
      onError: () => { },
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    isLoading: PropTypes.bool,
    problemId: PropTypes.string.isRequired,
    collectionId: PropTypes.string.isRequired,
    className: PropTypes.string,
    currentAccount: AccountPropTypes,
    solutions: PropTypes.shape({}),
    collectionProblem: CollectionProblemPropTypes,
    canManageProblem: PropTypes.bool,
    getCollectionProblemEntity: PropTypes.func,
  };

  static defaultProps = {
    isLoading: false,
    solutions: {},
    className: '',
    currentAccount: {},
    collectionProblem: {},
    canManageProblem: false,
    getCollectionProblemEntity: noop,
  };

  state = {
    problemId: '',
    hasFetchEntity: false,
    problemMode: 'solving',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isWeJudgeUUID(nextProps.problemId)) return null;
    if (!prevState.hasFetchEntity && !nextProps.collectionProblem) {
      NormalProblemView.updateProblemEntity(nextProps);
      return {
        hasFetchEntity: true,
      };
    }
    if (prevState.problemId !== nextProps.problemId) {
      return {
        problemId: nextProps.problemId,
      };
    }
    return null;
  }

  handleProblemModeChange = (name) => {
    this.setState({
      ...this.state,
      problemMode: name,
    });
  };

  handleProblemEntityChange = (problemId) => {
    this.props.getCollectionProblemEntity({
      collectionId: this.props.collectionId,
      id: problemId,
    });
  };

  render() {
    // const role = get(this.props.currentAccount, 'role', 0);
    // if (role !== 0) {
    //   this.setState({
    //     ...this.state,
    //     problemMode: 'view_answers',
    //   });
    // }
    const problemEntity = get(this.props.collectionProblem, 'problem', null);
    if (!this.props.collectionProblem) return null;
    return (<div className={`normal_problem_view ${this.props.className}`}>
      <ObjectiveProblemView
        problemMode={this.state.problemMode}
        problemItem={this.props.collectionProblem}
        problemModeChange={this.handleProblemModeChange}
      />
      {problemEntity && <div className="problem_tag_view" style={{ marginTop: 16 }}>
        <ProblemTagsView
          problem={problemEntity}
          canManageProblem={this.props.canManageProblem}
          onProblemEntityChange={this.handleProblemEntityChange}
        />
      </div>}
      <div className="loading_area">
        <Spin
          size="large"
          tip="加载中..."
          spinning={this.props.isLoading}
        />
      </div>
    </div>);
  }
}

export default NormalProblemView;
