import React, { useState, useEffect } from 'react';
import 'styles/education/course/lesson_repo_manage.scss';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Empty, List, message, Tooltip 
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, noop, endsWith } from 'lodash';
import { RepositoryFsAction } from 'scripts/common/logic/repository/action';
import { RepositoryFileSystemSelectors } from 'scripts/common/logic/repository/selector';
import FileSelectorView from 'scripts/apps/repository/file_selector';
import { showModal } from 'scripts/common/widgets/modal';
import PropTypes from 'prop-types';
import { humanbytes } from 'scripts/common/utils/unit';
import { RepositoryFileSystemPropType } from 'scripts/common/prop-types/repository';
import { LessonActions } from 'scripts/common/logic/education/lesson';
import { LessonPropTypes } from 'scripts/common/prop-types/course';

const mapStateToProps = (state, props) => {
  const fsIds = get(props.lesson, 'resources', []);
  const ret =  RepositoryFileSystemSelectors.getLessonRepositoryFilesList(fsIds)(state);
  const { notExistIds, files } = ret;

  return {
    fsIds,
    fileList: files,
    notExistIds,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getRepositoryFsEntities: RepositoryFsAction.getRepositoryFsEntities,
  editLesson: LessonActions.editLesson,
}, dispatch);

const LessonRepoManage = (props) => {
  const {
    lesson,
    repoId,
    fileList,
    lessonId,
    courseId,
    notExistIds,
    getRepositoryFsEntities,
  } = props;

  const [isRepoLoading, setIsRepoLoading] = useState(true);
  useEffect(() => {
    getRepositoryFsEntities({
      repoId,
      lesson: true,
      ids: notExistIds,
      onSuccess: () => {
        setIsRepoLoading(false);
      },
    });
  }, [lessonId]);

  const handleRepoFileAdd = () => {
    const selectedIds = fileList.map(item => item.id);
    showModal(FileSelectorView, {
      initFileSystemIds: selectedIds,
      onOk: (modal, state) => {
        props.editLesson({
          courseId,
          lessonId,
          resources: state.selectedFiles,
          onSuccess: () => {
            message.success('设置成功');
          },
        });
      },
    });
  };

  const handleDelRepoFile = (item, isDefaultExhib) => () => {
    const { resources } = props.lesson;
    const fsid = item.id;
    const result = resources.filter(_item => _item !== fsid);
    const payload = {
      courseId,
      lessonId,
      resources: result,
      onSuccess: () => {
        message.success('文件移除成功');
      },
    };
    if (isDefaultExhib) {
      payload.default_exhibition = {
        fsid: 0,
        type: '',
      };
    }
    props.editLesson(payload);
  };

  const handleChooseDefaultExhibition = (item, stype) => () => {
    props.editLesson({
      courseId,
      lessonId,
      default_exhibition: {
        fsid: get(item, 'id'),
        type: stype,
      },
      onSuccess: () => {
        message.success('设置成功');
      },
    });
  };

  const getSpecialType = (item) => {
    const fileNname = get(item, 'name', '');
    if (endsWith(fileNname, '.doc') || endsWith(fileNname, '.docx')) {
      return 'doc';
    } if (endsWith(fileNname, '.xls') || endsWith(fileNname, '.xlsx')) {
      return 'xls';
    } if (endsWith(fileNname, '.ppt') || endsWith(fileNname, '.pptx')) {
      return 'ppt';
    } if (endsWith(fileNname, '.pdf')) {
      return 'pdf';
    }
    return '';
  };

  const renderExtraButtons = (item, isDefaultExhib) => {
    const ret = [
      <a key="repo-del" onClick={handleDelRepoFile(item, isDefaultExhib)}>移除</a>
    ];
    const stype = getSpecialType(item);
    if (stype && !isDefaultExhib) {
      ret.unshift(<a key="repo-sel" onClick={handleChooseDefaultExhibition(item, stype)}>设为默认</a>);
    }
    return ret;
  };

  return (
    <div className="group">
      <div className="header">
        <div className="title">课件资源
          <Tooltip title="若当前课堂没有设置视频时，会优先使用默认的课件文件进行预览。(仅支持Word/Excel/PPT/PDF格式)"><a className="subtitle">什么是设为默认资源？</a></Tooltip>
        </div>
        <div className="extra_btn">
          <Button type="primary" onClick={handleRepoFileAdd}>
            <PlusOutlined /> 从资源仓库选择
          </Button>
        </div>
      </div>
      {fileList.length === 0 ? <Empty description="暂无教学资源" /> : <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={fileList}
        loading={isRepoLoading}
        bordered
        renderItem={(item) => {
          const exhib = get(lesson, 'default_exhibition', '').split(':');
          const isDefaultExhib = (exhib.length === 2) && (exhib[1] === get(item, 'id', '').toString());
          return <List.Item
            actions={renderExtraButtons(item, isDefaultExhib)}
          >
            <List.Item.Meta
              title={<>
                {get(item, 'name', '')}
                {isDefaultExhib ? '(默认)' : null}
              </>}
              description={<>
                <span style={{ marginRight: '16px' }}>{humanbytes(get(item, 'size', 0))}</span>
                <span>下载次数: {get(item, 'download_count', 0)} </span>
              </>}
            />
          </List.Item>;
        }}
      />}
    </div>
  );
};

LessonRepoManage.propTypes = {
  courseId: PropTypes.number.isRequired,
  repoId: PropTypes.number.isRequired,
  fsIds: PropTypes.arrayOf(PropTypes.number),
  notExistIds: PropTypes.arrayOf(PropTypes.number),
  getRepositoryFsEntities: PropTypes.func,
  fileList: PropTypes.arrayOf(RepositoryFileSystemPropType),
  lessonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lesson: LessonPropTypes,
  editLesson: PropTypes.func,
};

LessonRepoManage.defaultProps = {
  fsIds: [],
  notExistIds: [],
  getRepositoryFsEntities: noop,
  fileList: [],
  lesson: null,
  editLesson: noop,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonRepoManage);
