import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { formatTime, getStautsByStartEndTime } from 'scripts/common/utils/time_formatter';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { AsgnStatusTag } from 'scripts/apps/education/asgn/widgets/asgn_status_tag';

class AsgnListTable extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    asgnDatas: PropTypes.arrayOf(AsgnPropTypes),
    onAsgnClick: PropTypes.func,
    onPageChange: PropTypes.func,
    paginationParams: PaginationPropTypes,
    enableCheckbox: PropTypes.bool,
    onSelectedRowChange: PropTypes.func,
  };

  static defaultProps = {
    asgnDatas: [],
    onAsgnClick: noop,
    onPageChange: noop,
    onSelectedRowChange: noop,
    paginationParams: {},
    enableCheckbox: false,
  };

  state = {
    selectedRowKeys: [],
  };

  COLUMNS= [{
    title: 'ID',
    width: 50,
    dataIndex: 'id',
  }, {
    title: '名称',
    width: 140,
    dataIndex: 'name',
  }, {
    title: '状态',
    width: 60,
    dataIndex: 'status',
  }, {
    title: '创建时间',
    width: 100,
    dataIndex: 'update_time',
  }, {
    title: '得分',
    width: 40,
    dataIndex: 'score',
  }];

  handleSelectChange = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys,
    }, () => {
      this.props.onSelectedRowChange(selectedRowKeys);
    });
  };

  renderListData = () => {
    return this.props.asgnDatas.map((item) => {
      if (!item) return {};
      const status = get(item, 'status', {});
      const timeStatus = getStautsByStartEndTime(get(status, 'start_time', 0), get(status, 'end_time', 0)).status;
      const isManage = status.role === 'manage';
      const isStudent = status.role === 'student';
      const hasVisitPermission = get(status, 'permission', false);
      return {
        key: get(item, 'id', ''),
        id: <div>#{get(item, 'id', '')}</div>,
        name: isManage || (hasVisitPermission && timeStatus > -1)
          ? <a onClick={this.props.onAsgnClick(item)}>{get(item, 'title')}</a> : get(item, 'title'),
        status: <AsgnStatusTag status={get(item, 'status', {})} />,
        update_time: formatTime(get(item, 'update_time', 0), 'LONG_DATETIME'),
        score: isStudent ? get(status, 'score', '---') : '---',
      };
    });
  };

  render() {
    const datas = this.renderListData();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 40),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.props.onPageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.handleSelectChange,
    };
    return <Table
      bordered
      rowSelection={this.props.enableCheckbox ? rowSelection : undefined}
      className="asgn_list_view"
      size="middle"
      dataSource={datas}
      pagination={optPagination}
      columns={this.COLUMNS}
    />;
  }
}

export default AsgnListTable;
