import React from 'react';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import {
  Form, Modal, Input, Button, Avatar 
} from 'antd';
import { ContestAccountPropTypes } from 'scripts/common/prop-types/contest';
import WeJudgeAvatarEditor from 'scripts/common/widgets/avatar';
import { buildAvatarPath } from 'scripts/common/utils/location_helper';

class ContestAccountInfoEditorDialog extends React.PureComponent {
  static propTypes = {
    account: ContestAccountPropTypes.isRequired,
  };

  static defaultProps = {

  };

  state = {
    nickname: get(this.props.account, 'nickname'),
    newAvator: null,
    isCreateNewAvator: false,
  };

  handleAvatorConfirm = (imageData, showEditor = false) => {
    this.setState({
      newAvator: imageData,
      isCreateNewAvator: showEditor,
    });
  };

  render() {
    const formItemLayout = {
      labelCol: {
        sm: { span: 4 },
      },
      wrapperCol: {
        sm: { span: 20 },
      },
    };
    const { newAvator, isCreateNewAvator } = this.state;
    return (
      <Modal
        {...this.props}
        title="编辑个人信息"
        width={520}
        okButtonProps={{
          disabled: this.state.isCreateNewAvator,
        }}
      >
        <Form {...formItemLayout}>
          <Form.Item label="昵称" required>
            <Input
              value={this.state.nickname}
              onChange={(e) => {
                this.setState({
                  nickname: e.target.value,
                });
              }}
            />
          </Form.Item>
          { !isCreateNewAvator ? <Form.Item label="头像">
            { !newAvator ? <div>
              <Avatar
                shape="square"
                icon={<UserOutlined />}
                size={110}
                src={buildAvatarPath(get(this.props.account, 'avator'))}
              />
              <div>
                <Button
                  type="primary"
                  onClick={() => { this.handleAvatorConfirm('', true); }}
                >
                  <EditOutlined /> 修改头像
                </Button>
              </div>
            </div> : <div>
              <Avatar
                shape="square"
                icon={<UserOutlined />}
                size={110}
                src={newAvator}
              />
              <div>
                <Button
                  onClick={() => { this.handleAvatorConfirm('', true); }}
                >
                  <DeleteOutlined /> 重新选择
                </Button>
              </div>
            </div>}
          </Form.Item> : <div className="avatar_view" style={{ display: 'flex', justifyContent: 'center' }}>
            <WeJudgeAvatarEditor
              borderWidth={50}
              borderHeight={30}
              width={180}
              height={180}
              onUpload={e => this.handleAvatorConfirm(e, false)}
            />
          </div>}
        </Form>
      </Modal>
    );
  }
}

export default ContestAccountInfoEditorDialog;
