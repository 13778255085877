import 'styles/repository/uploader.scss';
import React from 'react';
import { get, noop } from 'lodash';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Progress, List, Tabs, Modal, Badge, Upload, Alert 
} from 'antd';
import PropTypes from 'prop-types';
import {
  RepositoryFsAction,
  RepositoryCommonUploadAction,
  RepositoryCommonUploaderSelectors
} from 'scripts/common/logic/repository';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const TASK_STATUS = {
  0: '等待上传',
  1: '分析文件中',
  2: '上传中',
  3: '上传成功',
  4: '上传失败',
  5: '用户取消',
  6: '秒传成功',
};

const mapStateToProps = (state, props) => {
  let currentFsId = 'root';
  if (props.repoPathArray.length > 0) {
    currentFsId = props.repoPathArray[props.repoPathArray.length - 1] || 'root';
  }
  const taskQueue = RepositoryCommonUploaderSelectors.getUploadQueueTasks(state);
  const successList = RepositoryCommonUploaderSelectors.getUploadSuccess(state);
  const failedList = RepositoryCommonUploaderSelectors.getUploadFailed(state);
  return {
    taskQueue,
    currentFsId,
    successList,
    failedList,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getRepositoryFileList: RepositoryFsAction.getRepositoryFileList,
  startRepositoryUploadFileTask: RepositoryCommonUploadAction.startRepositoryUploadFileTask,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class UploadFilesDialog extends React.PureComponent {
  static propTypes = {
    repoId: PropTypes.string.isRequired,
    repoPathArray: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ])).isRequired,
    currentFsId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    taskQueue: PropTypes.arrayOf(PropTypes.shape({})),
    successList: PropTypes.arrayOf(PropTypes.shape({})),
    failedList: PropTypes.arrayOf(PropTypes.shape({})),
    startRepositoryUploadFileTask: PropTypes.func,
  };

  static defaultProps = {
    currentFsId: 'root',
    taskQueue: [],
    successList: [],
    failedList: [],
    startRepositoryUploadFileTask: noop,
  };

  handleUploadFile = (file) => {
    this.props.startRepositoryUploadFileTask({
      repoId: this.props.repoId,
      fsId: this.props.currentFsId,
      fileName: file.name,
      fileSize: file.size,
      file,
    });
    // console.log(file);
    return false;
  };

  renderTaskList = (type) => {
    const listData = {
      1: this.props.taskQueue,
      2: this.props.successList,
      3: this.props.failedList,
    };
    return <List
      locale={{ emptyText: '暂无任务' }}
      itemLayout="horizontal"
      dataSource={listData[type]}
      renderItem={(item) => {
        const taskStatus = get(item, 'status', 0);
        const fileProcess = Number((get(item, 'sendProcess', 0) * 100).toFixed(2));
        const hashProcess = Number(get(item, 'hashProcess', 0));
        const chunkProcess = Number((get(item, 'chunkProcess', 0) * 100).toFixed(2));
        const renderDesc = () => {
          if (taskStatus === 1 || taskStatus === 2) {
            return <div className="task-status">
              <div className="progress">
                <Progress
                  percent={taskStatus === 1 ? hashProcess : fileProcess}
                  success={{ percent: chunkProcess }}
                />
              </div>
              <div className="status">
                {get(TASK_STATUS, taskStatus, '')}
                {taskStatus === 2 && `(${get(item, 'sendingChunk', 0)}/${get(item, 'chunkLength', 0)})`}
              </div>
            </div>;
          }
          return <>
            {get(TASK_STATUS, taskStatus, '')}
            {taskStatus === 4 && `(${get(item, 'errmsg')})`}
          </>;
        };
        return <List.Item>
          <List.Item.Meta
            title={<a href="https://ant.design">{item.name}</a>}
            description={renderDesc()}
          />
          {/*{taskStatus < 3 && <a onClick={noop}><Icon type="close" /> 取消</a>}*/}
        </List.Item>;
      }}
    />;
  };

  render() {
    const uploadProps = {
      showUploadList: false,
      multiple: true,
      beforeUpload: this.handleUploadFile,
    };
    return (
      <Modal
        {...this.props}
        className="wejudge-repo-common-uploader"
        width={720}
        footer={null}
        title="批量上传文件"
      >
        <Alert message="温馨提示：上传任务是在后台完成的哦！关闭本对话框不会终止上传任务。" type="info" showIcon closable style={{ marginBottom: 8 }} />
        <div className="upload-btns-group">
          <div className="upload-single">
            <Upload {...uploadProps}>
              <div className="ant-upload ant-upload-drag">
                <span className="ant-upload ant-upload-btn">
                  <div className="ant-upload-drag-container">
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      上传文件
                    </p>
                  </div>
                </span>
              </div>
            </Upload>
          </div>
          <div className="upload-drag">
            <Upload.Dragger
              {...uploadProps}
              directory
              openFileDialogOnClick={false}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">拖动文件/文件夹到此上传</p>
            </Upload.Dragger>
          </div>
        </div>
        <Tabs>
          <Tabs.TabPane
            tab={<Badge
              count={this.props.taskQueue.length}
              offset={[16, 0]}
              style={{ backgroundColor: '#1890ff' }}
            >
              上传任务
            </Badge>}
            key="1"
          >
            <div className="task-list">
              {this.renderTaskList(1)}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Badge
              count={this.props.successList.length}
              offset={[16, 0]}
              style={{ backgroundColor: '#52c41a' }}
            >
              已完成
            </Badge>}
            key="2"
          >
            <div className="task-list">
              {this.renderTaskList(2)}
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Badge count={this.props.failedList.length} offset={[16, 0]}>已失败</Badge>}
            key="3"
          >
            <div className="task-list">
              {this.renderTaskList(3)}
            </div>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    );
  }
}

export default UploadFilesDialog;
