import React from 'react';
import PropTypes from 'prop-types';
import {
  BellOutlined, EditOutlined, EyeFilled, LogoutOutlined, UserOutlined
} from '@ant-design/icons';
import {
  Button, Modal, Tooltip, Avatar, Tag, Menu, Badge, Divider, message
} from 'antd';
import moment from 'moment'
import { get, noop } from 'lodash';
import {
  ContestAccountPropTypes,
  ContestAuthPropTypes,
  ContestPropTypes
} from 'scripts/common/prop-types/contest';
import { bindActionCreators } from 'redux';
import { ContestAccountActions } from 'scripts/common/logic/contest/account';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildAvatarPath, buildPath } from 'scripts/common/utils/location_helper';
import { ContestRoutes, AccountRoutes } from 'scripts/apps/routes';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import { nowTime } from 'scripts/common/utils/time_formatter';

import { showModal } from 'scripts/common/widgets/modal';
import ContestAccountInfoEditorDialog from 'scripts/apps/contest/account/user_info_dialog';
import { withContestContext } from '../contest_provider';

const mapDispatchToProps = dispatch => bindActionCreators({
  contestLogout: ContestAccountActions.contestLogout,
  editCurrentAccountInfoMeta: ContestAccountActions.editCurrentAccountInfoMeta,
}, dispatch);

@withRouter
@withContestContext
@connect(null, mapDispatchToProps)
class ContestAccountHeader extends React.PureComponent {
  static propTypes =  {
    ...RoutePropTypes,
    account: ContestAccountPropTypes,
    contest: ContestPropTypes,
    loginStatus: ContestAuthPropTypes,
    contestLogout: PropTypes.func,
    showNoticeModel: PropTypes.func,
    editCurrentAccountInfoMeta: PropTypes.func,
  };

  static defaultProps = {
    account: {},
    contest: {},
    loginStatus: {},
    contestLogout: noop,
    showNoticeModel: noop,
    editCurrentAccountInfoMeta: noop,
  };

  state = {};

  doLogout = () => {
    Modal.confirm({
      title: '退出登录',
      content: '是否退出登录？',
      type: 'warning',
      onOk: () => {
        this.props.contestLogout({
          contestId: this.props.contest.id,
          onSuccess: () => {
            this.handleLoginClick(true);
          },
        });
      },
    });
  };

  showEditInfo = () => {
    showModal(ContestAccountInfoEditorDialog, {
      account: this.props.account,
      onOk: (dialog, state) => {
        const now_time = nowTime().format('X')
        if (+this.props.contest.start_time >= +now_time || +now_time >= +this.props.contest.end_time) {
          const payloads = {
            contestId: this.props.contest.id,
            nickname: get(state, 'nickname'),
            onSuccess: () => {
              // setTimeout(() => {
              //   window.location.reload();
              // }, 2000);
            },
          };
          if (get(state, 'newAvator')) {
            payloads.avator = get(state, 'newAvator');
          }
          this.props.editCurrentAccountInfoMeta(payloads);
        } else {
          message.error("比赛期间无法更改")
        }
        
      },
    });
  };

  handleLoginClick = (refresh = false) => {
    const { history } = this.props;
    const url = buildPath(
      ContestRoutes.ACCOUNT.LOGIN,
      { contestId: this.props.contest.id }
    );
    if (refresh === true) {
      window.location.href = url;
    } else {
      history.push(url);
    }
  };

  handleRegisterClick() {
    window.open(buildPath(AccountRoutes.AUTH.REGISTER));
  }

  handleEnrollClick = () => {
    const { history } = this.props;
    history.push(buildPath(
      ContestRoutes.ACCOUNT.ENROLL,
      { contestId: this.props.contest.id }
    ));
  };

  renderNotLoginedItems = () => {
    return <>
      <Menu.Item key="register" onClick={this.handleRegisterClick}>注册</Menu.Item>
      <Menu.Item key="login" onClick={this.handleLoginClick}>登录</Menu.Item>
    </>;
  };

  renderLoginedItems = () => {
    const { contest, account, loginStatus } = this.props;
    const timeNow = nowTime().unix();
    const isLogined = get(loginStatus, 'logined', false);
    const role = get(account, 'role', 0);
    const enrollStartTime =  get(contest, 'configs.enroll_start_time', 0);
    const enrollEndTime =  get(contest, 'configs.enroll_end_time', 0);
    const enableEnrolled = get(contest, 'configs.enroll', false);
    const isEnrolled = !(timeNow > enrollStartTime && timeNow < enrollEndTime) || ((role === ContestAccountRoleEnum.REFEREE.value) || (isLogined  && get(account, 'is_enrolled', false)));
    const isAdmin = get(account, 'id') === get(contest, 'author_id');
    return <>
      {enableEnrolled && !isEnrolled && <Menu.Item
        key="notice"
        onClick={this.handleEnrollClick}
      >
        报名
      </Menu.Item>}
      <Menu.SubMenu
        className="topbar-nav-item-account"
        title={<div className="topbar-user-area">
          <Avatar
            shape="circle"
            icon={<UserOutlined />}
            size={32}
            src={buildAvatarPath(get(account, 'avator'))}
            style={{ marginRight: 8 }}
          />
          {get(account, 'nickname', '') || get(account, 'realname', '') || <span style={{ color: '#ccc' }}>无昵称</span>}
        </div>}
      >
        <Menu.Item key="role">{get(ContestAccountRoleEnum.enumValueOf(role), 'desc', '未知角色')}{isAdmin && '，发起人'}</Menu.Item>
        <Menu.Divider />
        <Menu.Item key="edit_info" onClick={this.showEditInfo}><EditOutlined /> 更改资料</Menu.Item>
        <Menu.Item key="logout" onClick={this.doLogout}><LogoutOutlined /> 退出登录</Menu.Item>
      </Menu.SubMenu>
    </>;
  };

  render() {
    const isLogined = get(this.props.loginStatus, 'logined', false);
    return <Menu
      theme="dark"
      mode="horizontal"
      className="account-menu"
    >
      <Menu.Item key="notice" onClick={this.props.showNoticeModel}><BellOutlined /></Menu.Item>
      {isLogined ? this.renderLoginedItems() : this.renderNotLoginedItems() }
    </Menu>;
  }
}

export default ContestAccountHeader;
