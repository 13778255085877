import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal } from 'antd';
import {
  get, isEqual, noop, set 
} from 'lodash';
import { CouresNotePropTypes } from 'scripts/common/prop-types/course';
import { bindActionCreators } from 'redux';
import { NoteActions } from 'scripts/common/logic/education/note';
import { connect } from 'react-redux';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';

const mapDispatchToProps = dispatch => bindActionCreators({
  addNote: NoteActions.addNote,
  editNote: NoteActions.editNote,
}, dispatch);

@connect(null, mapDispatchToProps, null, { forwardRef: true })
class CourseNoteDialog extends React.PureComponent {
  static propTypes = {
    ...Modal.propTypes,
    edit: PropTypes.bool,
    note: CouresNotePropTypes,
    addNote: PropTypes.func,
    editNote: PropTypes.func,
    courseId: PropTypes.number.isRequired,
    onNoteEdited: PropTypes.func,
  };

  static defaultProps = {
    edit: false,
    note: null,
    addNote: noop,
    editNote: noop,
    onNoteEdited: noop,
  };

  state = {
    currentId: null,
    notebook: {
      title: '',
      content: '',
    },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps.note, 'id'), prevState.currentId)) {
      return {
        notebook: {
          title: get(nextProps.note, 'title'),
          content: get(nextProps.note, 'content'),
        },
        currentId: get(nextProps.note, 'id'),
      };
    }
    return null;
  }

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      ...this.state,
      notebook: {
        ...this.state.notebook,
        [keyName]: target.value,
      },
    });
  };

  handleSaveNote = () => {
    const noteId = get(this.props, 'note.id');
    if (!noteId) {
      this.props.addNote({
        content: this.state.notebook.content,
        title: this.state.notebook.title,
        params: {
          courseId: this.props.courseId,
        },
        onSuccess: () => {
          this.props.onNoteEdited();
          this.props.onOk();
        },
      });
    } else {
      this.props.editNote({
        noteId,
        content: this.state.notebook.content,
        title: this.state.notebook.title,
        onSuccess: () => {
          this.props.onNoteEdited();
          this.props.onOk();
        },
      });
    }
  };

  handleEditorChange = (text) => {
    this.setState(set({
      notebook: { ...this.state.notebook },
    }, 'notebook.content', text));
  };

  renderTitle = () => {
    const { note, edit } = this.props;
    if (note) {
      return edit ? '编辑笔记' : get(note, 'title', '笔记');
    }
    return '新建笔记';
  };

  render() {
    const { note, edit } = this.props;
    const updateTime = get(note, 'update_time', 0);
    const createTime = get(note, 'create_time', 0);
    return <Modal
      {...this.props}
      className="coures-note-dialog"
      title={this.renderTitle()}
      onOk={this.handleSaveNote}
      okText="保存"
      footer={edit ? undefined : null}
      width={720}
    >
      {!this.props.edit ? <div className="coures-note-view">
        <RichtextContext>{get(note, 'content', '')}</RichtextContext>
        <div className="meta-content">
          编辑于：{formatTimeFromNow(updateTime > 0 ? updateTime : createTime)}
        </div>
      </div> : <div className="note-editor">
        <Form.Item>
          <Input
            value={this.state.notebook.title}
            placeholder="笔记标题"
            onChange={this.handleInputChange('title')}
          />
        </Form.Item>
        <Form.Item>
          <MarkdownEditor
            onChange={this.handleEditorChange}
            defaultValue={get(this.state, 'notebook.content', '')}
          />
        </Form.Item>
      </div>}
    </Modal>;
  }
}

export default CourseNoteDialog;
