import React, { useEffect } from 'react';
import { Layout, PageHeader } from 'antd';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AnnounceRoutes } from 'scripts/apps/routes';
import { get, noop, isEmpty } from 'lodash';
import { AnnouncementActions, AnnouncementSelectors } from 'scripts/common/logic/announcement';
import { buildPath } from 'scripts/common/utils/location_helper';
import CenterSpin from 'scripts/common/widgets/center-spin';
import AnnouncementEditor from './widgets/info_editor';
// import { AccountActions } from 'scripts/common/logic/account';

const AnnouncementEditorView = (props) => {
  const handleBack = () => {
    const { history } = props;
    if (props.mode === 'new') {
      history.push(buildPath(AnnounceRoutes.LIST));
    } else {
      history.push(buildPath(AnnounceRoutes.BODY_VIEW, { aid: props.announcementId }));
    }
  };

  useEffect(() => {
    if (props.mode === 'edit' && isEmpty(props.announcement)) {
      props.getAnnouncement({
        id: props.announcementId,
        onError: () => {
          handleBack();
        },
      });
    }
  }, []);

  const handleSave = (announcement) => new Promise((resolve, reject) => {
    // if (announcement.notice) {
    //   addNotice({
    //     title: announcement.title,
    //     content: announcement.content,
    //     category: 1,
    //     ids: [],
    //   });
    // }
    const saveFunc = props.mode === 'new' ? props.addAnnouncement : props.editAnnouncement;
    saveFunc({
      ...announcement,
      announcementId: props.announcementId,
      onSuccess: () => {
        resolve();
        handleBack();
      },
      onError: () => {
        reject();
      },
    });
  });

  return (
    <Layout className="wj_general_view announcement-editor">
      <PageHeader
        onBack={handleBack}
        title={props.mode === 'new' ? '发布新公告' : '编辑公告'}
      />
      <Layout.Content>
        {props.mode === 'new' || !isEmpty(props.announcement) ? <AnnouncementEditor
          onSave={handleSave}
          announcement={props.announcement}
        /> : <CenterSpin />}
      </Layout.Content>
    </Layout>
  );
};

AnnouncementEditorView.propTypes = {
  ...RoutePropTypes,
  mode: PropTypes.string.isRequired,
  announcementId: PropTypes.string,
  announcement: PropTypes.shape({}),
  addAnnouncement: PropTypes.func,
};

AnnouncementEditorView.defaultProps = {
  announcement: {},
  announcementId: '',
  addAnnouncement: noop,
};

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const isNewMode = matchPath(
    location.pathname,
    { path: AnnounceRoutes.NEW }
  );
  let announcementId = 0;
  let announcement = {};
  if (!isNewMode) {
    announcementId = get(match, 'params.aid', null);
    announcement = AnnouncementSelectors.getAnnouncementEntity(state)(
      parseInt(announcementId, 10)
    ) || {};
  }
  return {
    mode: isNewMode ? 'new' : 'edit',
    announcementId,
    announcement,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getAnnouncement: AnnouncementActions.getAnnouncement,
  addAnnouncement: AnnouncementActions.addAnnouncement,
  editAnnouncement: AnnouncementActions.editAnnouncement,
  // addNotice: AccountActions.addGlobalNotice,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnnouncementEditorView));
