import React, { useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { AccountSelectors } from 'scripts/common/logic/account/index';
import { AuthPropTypes } from 'scripts/common/prop-types/account';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath, getCurrentURL } from 'scripts/common/utils/location_helper';
import { AccountRoutes } from 'scripts/apps/routes';

export const LoginRequire = (Component, options = {}) => {
  const mapStateToProps = (state) => {
    const authInfo = AccountSelectors.getLoginStatus(state);
    const account = AccountSelectors.getLoginAccount(state);
    return {
      auth: {
        ...authInfo,
        account,
      },
    };
  };

  const LoginRequireComponent = (props) => {
    useEffect(() => {
      const redirectToLogin = get(options, 'redirectToLogin', true);
      const currentUrl = getCurrentURL();
      if (!props.auth.fetched) return;
      if (!props.auth.logined) {
        if (redirectToLogin) {
          setTimeout(() => {
            props.history.push(buildPath(
              AccountRoutes.AUTH.LOGIN,
              null,
              null,
              { from: currentUrl }
            ));
          }, 100);
        }
      }
    });
    if (!props.auth.fetched || !props.auth.logined) return null;
    return <Component key={props.auth.id || 'not_auth'} {...props} />;
  };

  LoginRequireComponent.propTypes = {
    ...RoutePropTypes,
    auth: AuthPropTypes.isRequired,
  };

  return connect(mapStateToProps)(LoginRequireComponent);
};

export default LoginRequire;
