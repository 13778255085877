import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { ContestRoutes } from 'scripts/apps/routes';
import ProblemView from './problem_view';
import ProblemListContent from './problem_list';

const { Content } = Layout;

const ProblemApp = () => (
  <Content className="contest-layout-content">
    <Switch>
      <Route path={ContestRoutes.PROBLEM.LIST} component={ProblemListContent} />
      <Route path={ContestRoutes.PROBLEM.SUBITEMS} component={ProblemView} />
    </Switch>
  </Content>
);

export default ProblemApp;
