import React from 'react';
import { set, get } from 'lodash';
import {
  Form, Modal, Switch, InputNumber
} from 'antd';
import PropTypes from 'prop-types';
import { debounce } from 'scripts/common/utils/functional';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';

export class CheckupReportModal extends React.PureComponent {
  static propTypes= {
    selectedReportDatas: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  state = {
    selectedReportDatas: this.props.selectedReportDatas,
    finally_score: null,
    score: 100,
    score_input_disable: true,
  };

  changeScoreSwitch = () => {
    const score = get(this.state, 'score', 0);
    this.setState({
      finally_score: this.state.score_input_disable ? score : null,
      score_input_disable: !this.state.score_input_disable,
    });
  };

  onScoreChange = (value) => {
    this.setState({
      finally_score: value,
      score: value,
    });
  };

  handleEditorChange = debounce((value) => {
    const datas = this.props.selectedReportDatas.map((item) => {
      set(item, 'teacher_remark', value);
      return item;
    });
    this.setState({
      selectedReportDatas: datas,
    });
  }, INPUT_CHANGE_DEBOUNDCE_TIME);

  render() {
    return (
      <Modal
        {...this.props}
        title="快速批改"
      >
        <Form>
          <Form.Item>
            <span style={{ marginRight: '8px' }}>
              最终得分：
              <InputNumber
                min={0}
                max={200}
                disabled={this.state.score_input_disable}
                defaultValue={100}
                onChange={this.onScoreChange}
              />
            </span>
            <span style={{ marginRight: '8px' }}>
              <Switch
                defaultChecked
                checkedChildren="开"
                unCheckedChildren="关"
                onChange={this.changeScoreSwitch}
              />
            </span>
            使用判题机分数
          </Form.Item>
          <Form.Item>
            <MarkdownEditor
              height="15em"
              onChange={this.handleEditorChange}
              placeholder="请输入评语，可以为空"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}
