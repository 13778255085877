import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  noop, pick, get, isEqual 
} from 'lodash';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { JudgeStatusPropTypes } from 'scripts/common/prop-types/judge_status';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { ContestJudgeStatusActions, ContestJudgeStatusSelectors } from 'scripts/common/logic/contest/judge_status';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { ContestRoutes } from 'scripts/apps/routes';
import JudgeStatusFilter from 'scripts/apps/widgets/judge_status/filter';
import JudgeStatusListTable from 'scripts/apps/widgets/judge_status/list_table';
import { indexToChar } from 'scripts/common/utils/unit';
import { showModal } from 'scripts/common/widgets/modal';
import { createJudgeResultModal } from 'scripts/apps/widgets/judge_status/dialog';
import { ContestPropTypes, ContestProblemPropTypes } from 'scripts/common/prop-types/contest';
import { ContestProblemSelectors } from 'scripts/common/logic/contest/problem';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import ContestLoginRequire from '../contest_auth';
import { withContestContext } from '../contest_provider';
import {Tooltip} from 'antd';

const JudgeResultModal = createJudgeResultModal(ContestJudgeStatusActions, ContestJudgeStatusSelectors, 'Contest');

const LOADING_STATUS_KEY = 'judge_status_list';

const FILTER_KEY_PICKUP = ['limit', 'page', 'problem_order', 'order', 'author', 'flag'];

const mapDispatchToProps = dispatch => bindActionCreators({
  getJudgeStatusList: ContestJudgeStatusActions.getContestJudgeStatusList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const contestId = props.contest.id;
  const judgeStatusDatas = ContestJudgeStatusSelectors.getContestJudgeStatusListData(state);
  const pager = ContestJudgeStatusSelectors.contestJudgeStatusListViewPagerSelector(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const queryParams = {
    ...PaginationParams40,
    ...pick(queryset, ['limit', 'page', 'problem_order', 'order', 'author', 'flag']),
    contestId,
  };
  const problemList = ContestProblemSelectors.problemListData(state);
  const currentRoutePath = ContestRoutes.JUDGE_STATUS.LIST;
  return {
    isLoading,
    currentRoutePath,
    pager,
    queryParams,
    judgeStatusDatas,
    paginationParams: pager || PaginationParams40,
    problemList,
  };
};

@ContestLoginRequire
@withRouter
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class JudgeStatusView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getJudgeStatusList({
      contestId: nextProps.contest.id,
      query: {
        ...pick(
          nextProps.queryParams,
          FILTER_KEY_PICKUP
        ),
      },
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    contest: ContestPropTypes,
    getJudgeStatusList: PropTypes.func,
    problemList: PropTypes.arrayOf(ContestProblemPropTypes),
    isLoading: PropTypes.bool,
    problemId: PropTypes.string,
    setLoadingStatus: PropTypes.func,
    judgeStatusDatas: PropTypes.arrayOf(JudgeStatusPropTypes),
    currentRoutePath: PropTypes.string,
    paginationParams: PaginationPropTypes,
  };

  static defaultProps = {
    contest: {},
    isLoading: true,
    getJudgeStatusList: noop,
    setLoadingStatus: noop,
    judgeStatusDatas: [],
    problemId: '',
    currentRoutePath: '',
    paginationParams: null,
    problemList: [],
  };

  state = {
    queryParams: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      JudgeStatusView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  handleReload = () => {
    JudgeStatusView.fetchList(this.props);
  };

  handleProblemClick = (probelm) => {
    const { history, match } = this.props;
    history.push(buildPath(
      ContestRoutes.PROBLEM.CONTENT,
      {
        contestId: this.props.contest.id,
        problemId: get(probelm, 'contest_problem_item.id', ''),
      },
      match.params,
      {}
    ));
  };

  handleJudgeStatusClick = judgeStatus => () => {
    const isReferee = this.props.account.role === ContestAccountRoleEnum.REFEREE.value;
    showModal(JudgeResultModal, {
      canReJudge: isReferee,
      isManualJudge: isReferee && get(judgeStatus, 'contest_problem_item.manual_judge', false),
      judgeStatusId: get(judgeStatus, 'id'),
      apiPayloads: {
        contestId: this.props.contest.id,
      },
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      {
        ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
        limit: pageSize,
        page,
      }
    ));
  };

  handleProblemOrderClick = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      value !== 'all' ? {
        ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
        problem_order: parseInt(value, 10),
      } : {
        ...pick(this.state.queryParams, 'limit', 'page', 'flag', 'order', 'author'),
      }
    ));
  };

  handleAuthorSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      value === ''
        ? {
          ...pick(this.state.queryParams, 'limit', 'page', 'order', 'problem_order', 'flag'),
        } : {
          ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
          author: value,
        }
    ));
  };

  handleOrderClick = (value) => {
    const num = value ? 1 : 0;
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      {
        ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
        order: num,
      }
    ));
  };

  handleFlagClick = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      value !== 'all' ? {
        ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
        flag: parseInt(value, 10),
      } : {
        ...pick(this.state.queryParams, 'limit', 'page', 'problem_order', 'order', 'author'),
      }
    ));
  };

  renderProblemLabel = (item) => {
    const index = indexToChar(get(item, 'contest_problem_item.order', 0));
    const title = get(item, 'contest_problem_item.problem.title', '');
    return <Tooltip title={title}>题目{index}</Tooltip>;
  };

  renderAuthorLabel = (item) => {
    const nickname = get(item, 'author.nickname', '');
    const username = get(item, 'author.username', '');
    return <Tooltip title={username || ''}>
      <span style={get(item, 'author.sex', 1) === 0 ? { color: 'red' } : {}}>
        {nickname}
      </span>
    </Tooltip>;
  };

  render() {
    return <div className="judge_status_view">
      <JudgeStatusFilter
        type="select_problem_order"
        onProblemOrderClick={this.handleProblemOrderClick}
        onAuthorSearch={this.handleAuthorSearch}
        onOrderClick={this.handleOrderClick}
        onFlagClick={this.handleFlagClick}
        onReload={this.handleReload}
        problemList={this.props.problemList}
        problemOrder={get(this.state.queryParams, 'problem_order', 'all')}
        authorKw={get(this.state.queryParams, 'author', '')}
        problemKw={get(this.state.queryParams, 'problem', '')}
        flag={get(this.state.queryParams, 'flag', 'all')}
        orderAsc={get(this.state.queryParams, 'order', false) === '1'}
      />
      <JudgeStatusListTable
        bordered
        loading={this.props.isLoading}
        onPageChange={this.handlePageChange}
        judgeStatusDatas={this.props.judgeStatusDatas}
        onProblemClick={this.handleProblemClick}
        onJudgeStatusClick={this.handleJudgeStatusClick}
        paginationParams={this.props.paginationParams}
        renderProblemLabel={this.renderProblemLabel}
        renderAuthorLabel={this.renderAuthorLabel}
      />
    </div>;
  }
}

export default JudgeStatusView;
