import 'styles/contest/rank/fancy_rank.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { get, has, debounce } from 'lodash';
import { ForwardOutlined, PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ContestRankListActions } from 'scripts/common/logic/contest/ranklist';
import { ContestSelectors, ContestActions } from 'scripts/common/logic/contest/contest';
import 'jquery.scrollto';
import FancyRankItem from './fancy_rank_item';

const ITEM_COMMON_HEIGHT = 100;
const COMMON_WAIT_TIME = 3 * 1000;
const ROLLING_NEXT_DURATION = 3 * 1000;

@connect((state, props) => {
  const cid = get(props, 'match.params.contestId', 0);
  const contest =  ContestSelectors.getContestEntity(state)(cid);
  const customAwards = get(contest, 'configs.custom_awards', false);
  return {
    contestId: cid,
    awardsOptions: {
      gold: !customAwards ? 0.1 :  get(contest, 'configs.awards_gold'),
      silver: !customAwards ? 0.2 :  get(contest, 'configs.awards_silver'),
      bronze: !customAwards ? 0.3 :  get(contest, 'configs.awards_bronze'),
    },
  };
}, dispatch => bindActionCreators({
  getContestFancyRankDatas: ContestRankListActions.getContestFancyRankDatas,
  getContestEntity: ContestActions.getContestEntity,
}, dispatch))
class FancyRank extends React.Component {
  static propTypes = {
    awardsOptions: PropTypes.shape({}),
    contestId: PropTypes.string.isRequired,
    getContestEntity: PropTypes.func.isRequired,
    getContestFancyRankDatas: PropTypes.func.isRequired,
  };

  static defaultProps = {
    awardsOptions: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      currentIndex: -1,
      currentProblemIndex: -1,
      playing: false,             // 播放状态
    };
    props.getContestEntity({ id: props.contestId });
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyBoardEvent);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.fetchRankDatas();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyBoardEvent);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  initState = (fancyRankData) => {
    const rankListRaw = JSON.parse(JSON.stringify(get(fancyRankData, 'ranklist', [])));
    const problemListRaw = JSON.parse(JSON.stringify(get(fancyRankData, 'problems', [])));
    const diffSolutionsRaw = JSON.parse(JSON.stringify(get(fancyRankData, 'diff_solutions', {})));
    const rankList = [];
    const accountInfos = {};
    const problems = [];
    const problemInfos = {};
    const rollingStatus = {};   // { accountId: { problemId: true } }
    // 执行normalizr操作
    rankListRaw.forEach((item) => {
      const accountId = get(item, 'account.id');
      const diffSolutions = get(diffSolutionsRaw, accountId, {});
      rankList.push(accountId);
      accountInfos[accountId] = item;
      rollingStatus[accountId] = {};
      Object.keys(diffSolutions).forEach((pid) => {
        rollingStatus[accountId][pid] = 0;
      });
    });
    problemListRaw.sort((a, b) => a.order - b.order);
    problemListRaw.forEach((item) => {
      const problemId = get(item, 'problem_id');
      problems.push(problemId);
      problemInfos[problemId] = item;
    });
    return {
      rankList,
      accountInfos,
      problems,
      problemInfos,
      rollingStatus,
      diffSolutionsRaw,
      currentIndex: rankList.length,
      currentProblemIndex: -1,
    };
  };

  rollingWorker = null;

  hadMove = false;

  // 如果有移动过项，会选中这个，防止pindex == 1时自动currentIndex - 1
  playStack = [];       // 播放栈 (如果要实现向前播放的可以去做这个，我现在懒得去搞了）

  fetchRankDatas = () => {
    this.props.getContestFancyRankDatas({
      contestId: this.props.contestId,
      onSuccess: (rel) => {
        this.setState({
          isLoaded: true,
          ...this.initState(rel),
        });
      },
    });
  };

  setPosition = (fromIndex, toIndex) => {
    const { rankList } = this.state;
    if (fromIndex >= rankList.length || toIndex >= rankList.length || fromIndex <= toIndex) {
      return;
    }
    const target = rankList[fromIndex];
    for (let i = fromIndex; i > toIndex; i--) {
      rankList[i] = rankList[i - 1];
    }
    rankList[toIndex] = target;
    this.setState({
      rankList,
    });
  };

  cleanWorker = () => {
    if (this.rollingWorker) {
      clearTimeout(this.rollingWorker);
      this.rollingWorker = null;
    }
  };

  toggleRunning = () => {
    if (this.state.playing) {
      this.setState({
        playing: false,
      });
      this.cleanWorker();
      console.log('Pause');
    } else {
      this.cleanWorker();
      this.setState({
        playing: true,
      }, () => {
        this.scrollToCurrent();
        this.rollingWorker = setTimeout(this.rollingWorkerFunc, ROLLING_NEXT_DURATION);
      });
      console.log('Start');
    }
  };

  findNextSolution = () => {
    const {
      problems,
      rankList,
      diffSolutionsRaw,
      currentIndex,
      rollingStatus,
      currentProblemIndex,
    } = this.state;
    let pindex = currentProblemIndex;
    let cindex = currentIndex;
    if (!this.hadMove && pindex === -1) {
      cindex--;
    }
    this.hadMove = false;
    while (cindex >= 0) {
      const accountId = rankList[cindex];
      const solutions = get(diffSolutionsRaw, accountId, null) || {};
      while (++pindex < problems.length) {
        const pid = problems[pindex];
        // 找有diff的并且是没有移动过的
        if (has(solutions, pid) && get(rollingStatus, `${accountId}.${pid}`, 0) === 0) {
          return {
            emptyLine: false,
            solution: solutions[pid],
            pindex,
            cindex,
          };
        }
      }
      pindex = -1;
      return {
        emptyLine: true,
        pindex,
        cindex,
      };
    }
    return null;
  };

  findSortTarget = (accountId) => {
    const { rankList, accountInfos } = this.state;
    const tinfo = accountInfos[accountId];
    for (let i = 0; i < rankList.length; i++) {
      const aid = rankList[i];
      const sinfo = accountInfos[aid];
      // 如果解题数少了，直接返回当前i
      if (sinfo.solved < tinfo.solved) {
        return i;
      } if (sinfo.solved === tinfo.solved) {
        // 解题相等的时候，比较时间
        if (sinfo.time_used > tinfo.time_used) {
          return i;
        }
      }
    }
    return -1;
  };

  scrollToCurrent = (cindex) => {
    const top = ITEM_COMMON_HEIGHT * ((cindex || this.state.currentIndex) - 5);
    this.scrollToTarget(top > 0 ? top : 0);
  };

  rollingWorkerFunc = (noAnimate = false) => {
    // 1: 找到对应的rollingStatus
    // 2: 标记rollingStatus
    // 3：执行动画
    if (!this.state.playing) return;
    this.cleanWorker();
    const positionResult = this.findNextSolution();
    if (positionResult) {
      if (positionResult.emptyLine) {
        this.setState({
          currentIndex: positionResult.cindex,
          currentProblemIndex: positionResult.pindex,
        });
        this.scrollToCurrent(positionResult.cindex);
        this.rollingWorker = setTimeout(this.rollingWorkerFunc, ROLLING_NEXT_DURATION);
        return;
      }
      // 入栈
      this.playStack.push(positionResult);
      const {
        rollingStatus, rankList, problems, accountInfos,
      } = this.state;
      const accountId = rankList[positionResult.cindex];
      const problemId = problems[positionResult.pindex];
      rollingStatus[accountId][problemId] = 1;
      this.setState({
        currentIndex: positionResult.cindex,
        currentProblemIndex: positionResult.pindex,
        rollingStatus,
      }, () => {
        this.scrollToCurrent(positionResult.cindex);
        const showResult = () => {
          rollingStatus[accountId][problemId] = 2;
          this.setState({
            rollingStatus,
          }, () => {
            const hasAccepted = get(accountInfos, `${accountId}.solutions.${problemId}.accepted`, 0) > 0;
            if (!hasAccepted && positionResult.solution.accepted > 0) {
              // 向栈顶写入动作状态
              this.playStack[this.playStack.length - 1].isAc = true;
              // 绿了！
              const info = accountInfos[accountId];
              info.solved += 1;
              info.time_used += get(positionResult, 'solution.time_used', 0);
              accountInfos[accountId] = info;
              this.setState({
                accountInfos,
              }, () => {
                const tindex = this.findSortTarget(accountId);
                // 向栈顶写入目标地址
                this.playStack[this.playStack.length - 1].tindex = tindex;
                if (tindex > -1) {
                  // 如果需要移动
                  this.setState({
                    currentProblemIndex: -1,      // 回退标记
                  }, () => {
                    // 飞上去
                    this.setPosition(positionResult.cindex, tindex);
                    this.hadMove = true;
                  });
                } else {
                  // 询问下一个
                  this.rollingWorker = setTimeout(this.rollingWorkerFunc, ROLLING_NEXT_DURATION);
                }
              });
            } else {
              // 向栈顶写入动作状态
              this.playStack[this.playStack.length - 1].isAc = false;
              this.rollingWorker = setTimeout(this.rollingWorkerFunc, ROLLING_NEXT_DURATION);
            }
          });
        };
        if (noAnimate) {
          showResult();
        } else {
          // pending闪烁2秒
          setTimeout(showResult, COMMON_WAIT_TIME);
        }
      });
    }
  };

  scrollToTarget = debounce((top) => {
    window.jQuery('#fancy-rank-scroll-wrap').scrollTo(top, 500);
  }, 500);

  handleBeforeUnload = (e) => {
    if (this.state.playing) {
      e.stopPropagation();
      e.returnValue = false;
      return false;
    }
    return true;
  };

  handleKeyBoardEvent = (e) => {
    e.stopPropagation();
    e.preventDefault();
    switch (e.code) {
      case 'Space':
        this.toggleRunning();
        break;
      case 'ArrowRight':
      case 'ArrowDown':
        if (this.state.playing) {
          this.rollingWorkerFunc(true);
        }
        break;
      default:
        break;
    }
  };

  handleTransitionEnd = () => {
    this.rollingWorker = setTimeout(this.rollingWorkerFunc, ROLLING_NEXT_DURATION);
  };

  render() {
    if (!this.state.isLoaded) {
      return <Spin tip="正在载入排行榜数据" style={{ width: '100%', marginTop: '48px' }} />;
    }
    let rankCounter = 0;
    const { awardsOptions } = this.props;
    const gold = get(awardsOptions, 'gold', 0);
    const silver = get(awardsOptions, 'silver', 0);
    const bronze = get(awardsOptions, 'bronze', 0);
    const goldNumber = gold >= 1 ? gold : Math.floor(this.state.rankList.length * gold);
    const silverNumber = goldNumber + (
      silver >= 1 ? silver : Math.floor(this.state.rankList.length * silver)
    );
    const bronzeNumber = silverNumber
      + (bronze >= 1 ? bronze : Math.floor(this.state.rankList.length * bronze));
    return (
      <div className="fancy-rank">
        <div className="fancy-rank-header">
          <div className="fancy-rank-header-item rank">排名</div>
          <div className="fancy-rank-header-item fill_logo" />
          <div className="fancy-rank-header-item fill" />
          <div className="fancy-rank-header-item solved">解题</div>
          <div className="fancy-rank-header-item time">时间</div>
        </div>
        <div className="fancy-rank-wrap" id="fancy-rank-scroll-wrap">
          {this.state.rankList.map((accountId, index) => {
            const ignoreRank = get(this.state.accountInfos, `${accountId}.account.ignore_rank`, false);
            if (!ignoreRank) rankCounter++;
            const number = index + 1;
            const theAwardsType = (rankCounter <= goldNumber) ? 1 :            // eslint-disable-line
              (rankCounter <= silverNumber ? 2 : (rankCounter <= bronzeNumber) ? 3 : 0);       // eslint-disable-line
            const isEnd = (index > this.state.currentIndex || this.state.currentIndex === 0);
            const awardType = (isEnd && !ignoreRank) ? theAwardsType : 0;
            return <FancyRankItem
              key={accountId}
              top={index * ITEM_COMMON_HEIGHT}
              number={number}
              isCurrent={index === this.state.currentIndex}
              awardType={awardType}
              rankCount={rankCounter}
              problems={this.state.problems}
              problemInfos={this.state.problemInfos}
              accountInfo={this.state.accountInfos[accountId]}
              diffSolutions={this.state.diffSolutionsRaw[accountId]}
              rollingStatus={this.state.rollingStatus[accountId]}
              transitionEnd={this.handleTransitionEnd}
            />;
          })}
          <div className="fancy-rank-placebottom" style={{ top: `${ITEM_COMMON_HEIGHT * this.state.rankList.length}px` }} />
        </div>
        <div className="fancy-rank-control">
          <div className="button" onClick={this.toggleRunning}>
            {this.state.playing ? <PauseCircleFilled /> : <PlayCircleFilled />}
          </div>
          <div
            className="button"
            onClick={() => {
              if (this.state.playing) {
                this.rollingWorkerFunc(true);
              }
            }}
          >
            <ForwardOutlined />
          </div>
        </div>
      </div>
    );
  }
}

export default FancyRank;
