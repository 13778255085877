import React from 'react';
import {
  get, noop, pick, isEqual 
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Table } from 'antd';
import { withRouter } from 'react-router-dom';

import { AccountPropTypes, AccountSolutionsPropTypes  } from 'scripts/common/prop-types/account';
import { AccountActions } from 'scripts/common/logic/account/action';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountRoutes } from 'scripts/apps/routes';

const mapDispatchToProps = dispatch => bindActionCreators({
  getAccountSolutionsList: AccountActions.getAccountSolutionsList,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { location } = props;
  const query = getQuery(location);
  const pager = AccountSelectors.accountSolutionsListViewPagerSelector(state);
  const solutionsDatas  = AccountSelectors.accountSolutionsListData(state);
  const queryParams = {
    ...PaginationParams40,
    ...pick(query, ['limit', 'page', 'problem', 'keyword']),
  };
  return {
    queryParams,
    paginationParams: pager || props.paginationParams,
    solutionsDatas,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class SpaceCodeProblemsSolutionsView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    account: AccountPropTypes.isRequired,
    getAccountSolutionsList: PropTypes.func,
    solutionsDatas: PropTypes.arrayOf(AccountSolutionsPropTypes),
    paginationParams: PaginationPropTypes,
  };

  static defaultProps = {
    getAccountSolutionsList: noop,
    solutionsDatas: null,
    paginationParams: {},
  };

  state = {
    hasFetchEntity: false,
    queryParams: {},
    searchValue: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchEntity || !isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      nextProps.getAccountSolutionsList({
        accountId: nextProps.account.id,
        query: {
          ...pick(queryParams, ['limit', 'page', 'problem', 'keyword']),
        },
        onComplete: () => {

        },
      });
      return {
        queryParams,
        hasFetchEntity: true,
      };
    }
    return null;
  }

  NORMAL_COLUMNS = [{
    title: '题目ID',
    width: 100,
    dataIndex: 'id',
  }, {
    title: '题目',
    dataIndex: 'title',
  }, {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    align: 'center',
  }, {
    title: '通过',
    width: 100,
    dataIndex: 'accepted',
    align: 'center',
  }, {
    title: '提交',
    width: 100,
    dataIndex: 'submission',
    align: 'center',
  }, {
    title: '正确率',
    width: 100,
    dataIndex: 'ratio',
    align: 'center',
  }];

  handleSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AccountRoutes.SPACE.CODE_SOL,
      {},
      match.params,
      value === '' ? {
        ...pick(this.state.queryParams, ['limit', 'page']),
      } : {
        ...pick(this.state.queryParams, ['limit', 'page']),
        keyword: value,
      }
    ));
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AccountRoutes.SPACE.CODE_SOL,
      {},
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'problem', 'keyword']),
        limit: pageSize,
        page,
      }
    ));
  };

  renderSolutionsDatas = () => {
    const getRatio = (a, b) => {
      return `${(b === 0) ? 0 : (((a * 1.0) / b) * 100.0).toFixed(2)} %`; // eslint-disable-line
    };
    return this.props.solutionsDatas.map((item) => {
      if (!item) return null;
      return {
        key: get(item, 'id', ''),
        id: get(item, 'problem.number', ''),
        title: get(item, 'problem.title', ''),
        status: parseInt(get(item, 'accepted', ''), 10) > 0 ? '已通过' : '未通过',
        accepted: get(item, 'accepted', '0'),
        submission: get(item, 'submission', '0'),
        ratio: getRatio(parseInt(get(item, 'accepted', '0'), 10), parseInt(get(item, 'submission', '0'), 10)),
      };
    }).filter(item => !!item);
  };

  render() {
    const datas = this.renderSolutionsDatas();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 1),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.handlePageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    return <div className="account-space-code-solutions">
      <div className="header-wrap">
        <Input.Search
          placeholder="按题目名称/ID搜索"
          style={{ width: 200 }}
          value={this.state.searchValue}
          onChange={({ target }) => { this.setState({ searchValue: target.value }); }}
          onSearch={this.handleSearch}
        />
      </div>
      <div className="body-wrap">
        {datas && <Table
          dataSource={datas}
          pagination={optPagination}
          columns={this.NORMAL_COLUMNS}
        />}
      </div>
    </div>;
  }
}

export default SpaceCodeProblemsSolutionsView;
