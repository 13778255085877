import React from 'react';
import {
  set, get, isEqual, pick
} from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Switch, Select, Button, InputNumber, Tooltip, Row, Col, Form, Input
} from 'antd';
import JudgeLanguageCheckBox from 'scripts/common/widgets/language_checkbox';
import { ContestProblemPropTypes } from 'scripts/common/prop-types/contest';
import PropTypes from 'prop-types';
import { BlockPicker } from 'react-color';
import { BalloonColors } from 'scripts/common/constants/color';
import { isLightRGBText } from 'scripts/common/utils/color';

const { Option } = Select;

class ContestProblemSettings extends React.PureComponent {
  static propTypes = {
    contestProblem: ContestProblemPropTypes.isRequired,
    onSaveSetting: PropTypes.func.isRequired,
  };

  state = {
    saving: false,
    raw: null,
    contestProblem: null,
    displayColorPicker: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.contestProblem, prevState.raw)) {
      return {
        raw: nextProps.contestProblem,
        contestProblem: JSON.parse(JSON.stringify(nextProps.contestProblem)),
      };
    }
    return null;
  }

  handleInputChange = key => (value) => {
    this.setState(set({
      contestProblem: { ...this.state.contestProblem },
    }, `contestProblem.${key}`, value));
  };

  handleColorChange = key => (color) => {
    this.setState(set({
      contestProblem: { ...this.state.contestProblem },
    }, `contestProblem.${key}`, color.hex));
  };

  handleInputEventChange = key => (e) => {
    this.setState(set({
      contestProblem: { ...this.state.contestProblem },
    }, `contestProblem.${key}`, e.target.value));
  };

  handleSaveSetting = () => {
    this.setState({
      saving: true,
    }, () => {
      this.props.onSaveSetting(pick(
        this.state.contestProblem,
        [
          'manual_judge', 'code_language', 'simple_cross_check',
          'cross_check_threshold', 'ignore_pe', 'color'
        ]
      )).then(() => {
        this.setState({
          saving: false,
        });
      });
    })
  };

  handleColorPickerClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleColorPickerClose = () => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const colorPickerPopover = {
      position: 'absolute',
      zIndex: '2',
      top: '48px',
    };
    const colorPickerCover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };
    const { contestProblem } = this.state;
    const color = get(contestProblem, 'color', '#bfbfbf') || '#bfbfbf';
    return (
        <div className="settings-view">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="手动评测">
                <Switch
                  checked={get(contestProblem, 'manual_judge', false)}
                  onChange={this.handleInputChange('manual_judge')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<>PE判定 <Tooltip placement="top" title={<><div>继承：继承自比赛设置的忽略格式错误设置</div><div>严格：答案正确但格式错误，视为评测不通过</div><div>忽略：格式错误将视为评测通过</div></>}><InfoCircleOutlined /></Tooltip></>}
              >
                <Select value={get(contestProblem, 'ignore_pe', -1)} style={{ width: 120 }} onChange={this.handleInputChange('ignore_pe')}>
                  <Option value={-1}>继承</Option>
                  <Option value={0}>严格</Option>
                  <Option value={1}>忽略</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="启用查重">
                <Switch
                  checked={get(contestProblem, 'simple_cross_check', false)}
                  onChange={this.handleInputChange('simple_cross_check')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="查重阈值">
                <InputNumber
                  max={100}
                  min={10}
                  disabled={!get(contestProblem, 'simple_cross_check', false)}
                  onChange={this.handleInputChange('cross_check_threshold')}
                  value={get(contestProblem, 'cross_check_threshold', 70)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="气球颜色">
                <Input
                  style={{
                    color: isLightRGBText(color) ? 'black' : 'white',
                    background: color,
                  }}
                  readOnly
                  onClick={this.handleColorPickerClick}
                  value={color}
                  onChange={this.handleInputEventChange('color')}
                />
                {this.state.displayColorPicker && <div style={colorPickerPopover}>
                  <div style={colorPickerCover} onClick={this.handleColorPickerClose} />
                  <BlockPicker
                    colors={BalloonColors}
                    onChange={this.handleColorChange('color')}
                    color={color}
                  />
                </div>}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="可用评测语言">
            <JudgeLanguageCheckBox
              allowInherit
              language={get(contestProblem, 'code_language', 0)}
              onChange={({ target }) => { this.handleInputChange('code_language')(target.value); }}
            />
          </Form.Item>
          <Button type="primary" loading={this.state.saving} onClick={this.handleSaveSetting}>保存设置</Button>
        </div>
    );
  }
}

export default ContestProblemSettings;
