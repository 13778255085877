import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { CourseRoutes } from 'scripts/apps/routes';
import CourseContestStatistics from 'scripts/apps/education/course/statistic/contest_list';
import CourseContestStatisticsView from 'scripts/apps/education/course/statistic/contest_view';
import { Redirect } from 'react-router-dom';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

class CourseStatistics extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    courseId: PropTypes.number.isRequired,
  };

  static defaultProps = {};

  state = {};

  render() {
    return <div className="course_view_body">
      <Switch>
        <Route
          path={CourseRoutes.STATISTICS_CONTEST_LIST}
          render={props => <CourseContestStatistics
            {...props}
            courseId={this.props.courseId}
          />}
          exact
        />
        <Route
          path={CourseRoutes.STATISTICS_CONTEST_VIEW}
          render={props => <CourseContestStatisticsView
            {...props}
            courseId={this.props.courseId}
          />}
          exact
        />
        <Route
          render={() => <Redirect
            to={buildPath(
              CourseRoutes.STATISTICS_CONTEST_LIST,
              null,
              this.props.match.params
            )}
          />}
        />
      </Switch>
    </div>;
  }
}

export default CourseStatistics;
