import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import {
  BulbOutlined,
  FireOutlined,
  GithubFilled,
  MailFilled,
  SmileFilled,
  TrophyOutlined
} from '@ant-design/icons';

import {
  Tooltip, Popover, Typography, Timeline 
} from 'antd';
import { CONTRIBUTOR } from './constant';

const { Title, Paragraph, Text } = Typography;

const openGayhubLink = name => () => {
  const a = document.createElement('a');
  a.href = `https://github.com/${name}`;
  a.target = '_blank';
  a.click();
};

const ContributorLabel = (props) => {
  return (
    <div className="contributor-label">
      <div className="label-content">
        <div className="label-classname">{props.classname}</div>
        <div className={`label-name label-color-${props.color}`}>{props.name}</div>
        {props.email && <Tooltip title={props.email}><div className="label-email"><MailFilled /></div></Tooltip>}
        {props.github && <div className="label-github" onClick={openGayhubLink(props.github)}><GithubFilled /></div>}
        {(props.isTeacher || props.mascot) && <div className="label-teacher"><SmileFilled /></div>}
      </div>
      {props.extra && <div className="label-extra">{props.extra}</div>}
    </div>
  );
};
ContributorLabel.propTypes = {
  name: PropTypes.string,
  classname: PropTypes.string,
  github: PropTypes.string,
  email: PropTypes.string,
  color: PropTypes.string,
  extra: PropTypes.node,
  isTeacher: PropTypes.bool,
  mascot: PropTypes.bool,
};
ContributorLabel.defaultProps = {
  color: 'blue',
  name: '',
  github: '',
  email: '',
  extra: null,
  classname: '',
  isTeacher: false,
  mascot: false,
};

const SPECIAL_CONTRIBUTOR = {
  lzl: <Popover
    placement="top"
    title="征婚启事"
    content={<span><strong>男女不限！！人数不限！！</strong><br /><img src="http://s1.static.wejudge.net/lzl.jpeg" width={200} alt="" /></span>}
    trigger="click"
  >
    <a className="text-lightning">征婚</a>
  </Popover>,
  ztr: <Popover
    placement="top"
    title="121征婚"
    content={<span><strong>121说的：男女不限！！人数不限！！</strong><br /><img src="http://s1.static.wejudge.net/lzl.jpeg" width={200} alt="" /></span>}
    trigger="click"
  >
    <a className="text-lightning">121NB</a>
  </Popover>,
  cycsics: <Popover
    placement="top"
    title="征婚启事"
    content={<span><strong>男女不限！！！</strong><br /><img src="http://s1.static.wejudge.net/lsm.jpg" width={200} alt="" /></span>}
    trigger="click"
  >
    <a className="text-lightning">征婚</a>
  </Popover>,
  plus_c_tian: <Popover
    placement="top"
    title="征婚启事"
    content={<span>仅限妹子！！</span>}
    trigger="click"
  >
    <a className="text-lightning">征婚</a>
  </Popover>,
};

class HelperContributeView extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  // extra={get(SPECIAL_CONTRIBUTOR, id)}
  renderGroupContent = (groupName, people) => {
    return <>
      <Title level={4}>{groupName}</Title>
      <Paragraph>
        {people.map((id) => {
          const person = get(CONTRIBUTOR, id, {});
          return <ContributorLabel key={id} {...person}  />;
        })}
      </Paragraph>
    </>;
  };

  render() {
    return (
      <div className="helper-contributions">
        <div className="contributions-timeline">
          <Timeline mode="alternate">
            <Timeline.Item>
              WeJudge 3.0 正式发布 <Text type="secondary">2019-09</Text>
              <br />
              <Text type="secondary">
                这是一个全新的开始！
                <br />
                我们依然没有停下探索的脚步，如果你愿意，欢迎和我们并肩前行！
                <br />
                <Text strong>不忘初心，方得始终</Text>
              </Text>
            </Timeline.Item>
            <Timeline.Item dot={<TrophyOutlined />}>
              获2019年(第12届)全国大学生计算机设计大赛 一等奖 <Text type="secondary">2019-08</Text>
              <br />
              <a href="https://raw.static.wejudge.net/static/help/19cdc.jpg" target="_blank" rel="noopener noreferrer">
                <img src="https://raw.static.wejudge.net/static/help/19cdc_thumb.jpg" alt="国赛" width={240} />
              </a>
            </Timeline.Item>
            <Timeline.Item>
              WeJudge 3.0 比赛模块发布 <Text type="secondary">2019-05</Text>
              <br />
              <Text type="secondary">不仅仅是教学管理，对ACM比赛的支持我们也要say YES!</Text>
            </Timeline.Item>
            <Timeline.Item>
              WeJudge 3.0 内测发布 <Text type="secondary">2018-09</Text>
              <br />
              <Text type="secondary">
                带来了一套全新的教学管理辅助平台，更加适应实际教学使用。
                <br />
                更加强大的题库系统，配合先进的教学管理系统，旨在为传统的教学模式注入科技的活力。
              </Text>
            </Timeline.Item>
            <Timeline.Item>
              拙文几篇，还望斧正
              <br />
              <em><a
                href="https://raw.static.wejudge.net/static/help/paper1.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                『程序设计在线评测辅助教学系统的设计与实现』(本科毕业论文)
              </a></em>
              <br />
              <em><a
                href="https://raw.static.wejudge.net/static/help/paper2.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                『在线评测教学辅助系统的设计与应用』(电子设计工程2017.2)
              </a></em>
            </Timeline.Item>
            <Timeline.Item dot={<BulbOutlined />}>
              筹划一个全新的系统 <Text type="secondary">2018-01</Text>
              <br />
              <Text type="secondary">
                <br />
                新的力量加入，让新系统的开发成为了可能。
                <br />
                三年来在实际教学过程中遇到的各种问题，为产品不断的改进、发展、创新提供了可能。
                <br />
                在这个技术飞速发展、日新月异的时代，跟紧步伐，大胆试错，力争做得更好。
              </Text>
            </Timeline.Item>
            <Timeline.Item dot={<FireOutlined />}>
              WeJudge 2.0 发布 <Text type="secondary">2017-08</Text>
              <br />
              <Text type="secondary">
                1.0系统的ACM风格太浓，在实际使用中，被各种吐槽太严苛。
                <br />
                与此同时，重写系统架构和功能，更专注教学管理模块的设计。
              </Text>
            </Timeline.Item>
            <Timeline.Item dot={<TrophyOutlined />}>
              获第十四届『挑战杯』广东省大学生课外学术科技作品竞赛 三等奖 <Text type="secondary">2017-06</Text>
              <br />
              <a href="https://raw.static.wejudge.net/static/help/tzb.jpg" target="_blank" rel="noopener noreferrer">
                <img src="https://raw.static.wejudge.net/static/help/tzb_thumb.jpg" alt="挑战杯" width={240} />
              </a>
            </Timeline.Item>
            <Timeline.Item dot={<FireOutlined />}>
              1.0版本正式上线，正式命名为WeJudge <Text type="secondary">2016-08</Text>
              <br />
              <Text type="secondary">开始规模化使用，投入学院16级C语言教学中。</Text>
            </Timeline.Item>
            <Timeline.Item dot={<TrophyOutlined />}>
              获学院「IT节」计算机作品设计大赛 院长特别奖 <Text type="secondary">2016-05</Text>
              <br />
              <a href="https://raw.static.wejudge.net/static/help/itc.jpg" target="_blank" rel="noopener noreferrer">
                <img src="https://raw.static.wejudge.net/static/help/itc_thumb.jpg" alt="IT节" width={240} />
              </a>
            </Timeline.Item>
            <Timeline.Item dot={<TrophyOutlined />}>
              获2016年『攀登计划』项目立项 <Text type="secondary">2016-05</Text>
              <br />
              <a href="https://raw.static.wejudge.net/static/help/pandeng1.jpg" target="_blank" rel="noopener noreferrer">
                <img src="https://raw.static.wejudge.net/static/help/pandeng1_thumb.jpg" alt="攀登计划" width={240} />
              </a>
              <a href="https://raw.static.wejudge.net/static/help/pandeng2.jpg" target="_blank" rel="noopener noreferrer">
                <img src="https://raw.static.wejudge.net/static/help/pandeng2_thumb.jpg" alt="攀登计划" width={240} />
              </a>
            </Timeline.Item>
            <Timeline.Item dot={<BulbOutlined />}>
              通过实际教学论证，改进和完善系统，1.0版本内测上线。 <Text type="secondary">2016-02</Text>
            </Timeline.Item>
            <Timeline.Item dot={<FireOutlined />}>
              BNUZOJ 0.3 发布，投入实际教学测试 <Text type="secondary">2015-09</Text>
            </Timeline.Item>
            <Timeline.Item>完成理论调研，着手开发 <Text type="secondary">2015-08</Text></Timeline.Item>
            <Timeline.Item>
              北京师范大学珠海分校创新创业训练计划立项 <Text type="secondary">2015-05</Text>
              <br />
              <a href="https://raw.static.wejudge.net/static/help/cxcy.jpg" target="_blank" rel="noopener noreferrer">
                <img src="https://raw.static.wejudge.net/static/help/cxcy_thumb.jpg" alt="北京师范大学珠海分校创新创业训练计划立项" width={240} />
              </a>
            </Timeline.Item>
            <Timeline.Item dot={<BulbOutlined />}>
              开始计划研发一个适用于教学的OJ系统 <Text type="secondary">2015-05</Text>
              <br />
              <em style={{ color: '#959595' }}>It all started with the big BANG!</em>
            </Timeline.Item>
          </Timeline>
        </div>
        <div className="contributions-authors">
          <Typography>
            <Title level={3}>
              参与过开发的同学（名字不分先后）
            </Title>
            {this.renderGroupContent('指导老师', ['small_red_fish'])}
            {this.renderGroupContent('系统总设计', ['lancelrq'])}
            {this.renderGroupContent('前端负责人', ['ztr'])}
            {this.renderGroupContent('后端负责人', ['lzl'])}
            {this.renderGroupContent('小程序负责人', ['xj'])}
            {this.renderGroupContent('往届前端小组', [
              'wyq', 'yyyr', 'whizzkid', 'jackyinn'
            ])}
            {this.renderGroupContent('往届后端小组', [
              'dickqi', 'kingswwong', 'cycsics', 'csharb'
            ])}
            {this.renderGroupContent('美术大佬(Logo设计等)', ['plus_c_tian'])}
            {this.renderGroupContent('吉祥物', ['cycsics', 'hyy', 'hjy', 'wpy', 'crt'])}
          </Typography>
        </div>
      </div>
    );
  }
}

export default HelperContributeView;
