import React from 'react';
import { Alert, Input, Modal } from 'antd';

class PasswordModal extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  state = {
    password: '',
    isPassword: false,
  };

  handleInputChange = ({ target }) => {
    this.setState({
      password: target.value,
      isPassword: target.value && target.value.length >= 8 && target.value.length <= 16,
    }, () => {
      this.setState({
        ...this.state,
      });
    });
  };

  render() {
    return (<Modal
      {...this.props}
      title="修改密码"
    >
      <Input.Password
        size="large"
        value={this.state.password}
        onChange={this.handleInputChange}
        placeholder="请输入新密码"
        maxLength={16}
        autocomplete="off"
      />
      {((this.state.password) && !this.state.isPassword)
        ?  <Alert message="密码长度请设为8-16位" type="error" style={{ marginTop: 8 }} /> : null }
    </Modal>);
  }
}

export default PasswordModal;
