import React from 'react';
import {
  set, get, truncate, cloneDeep, noop 
} from 'lodash';
import { FileExcelOutlined } from '@ant-design/icons';
import {
  Button,
  Table,
  Select,
  InputNumber,
  Alert,
  Row,
  Col,
  Upload,
  List,
  Typography,
  Modal
} from 'antd';
import DownloadHelper from 'downloadjs';
import localStorage from 'localStorage';
import PropTypes from 'prop-types';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { importStudentCodeForGradeExportExcel } from 'scripts/common/utils/xlsx_importer';

class CourseExportGrades extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    asgnDatas: PropTypes.arrayOf(AsgnPropTypes).isRequired,
    exportGrades: PropTypes.func,
  };

  static defaultProps = {
    exportGrades: noop,
  };

  state = {
    excelFile: null,
    asgnsGroup: [],
    selectAsgns: [],
    selectAsgnsOptions: [],
    studentCodes: [],
  };

  componentDidMount() {
    this.storageName = `course_export_grades_config_${this.props.courseId}`;
    try {
      const asgnsGroup = JSON.parse(localStorage.getItem(this.storageName));
      this.setState({     // eslint-disable-line
        asgnsGroup: asgnsGroup || [],
      });
    } catch (e) { console.log(e); }
  }

  storageName = 'course_export_grades_config';

  COLUMNS= [{
    title: '组别',
    align: 'center',
    dataIndex: 'id',
    width: 60,
  }, {
    title: '作业',
    align: 'center',
    dataIndex: 'asgns',
  }, {
    title: '权重',
    align: 'center',
    dataIndex: 'ratio',
    width: 140,
  }, {
    title: '最高分',
    align: 'center',
    dataIndex: 'max_score',
    width: 140,
  }, {
    title: '操作',
    align: 'center',
    dataIndex: 'operation',
    width: 60,
  }];

  getAsgnDataWithFilter = () => {
    return this.props.asgnDatas.filter((asgn) => {
      let matched = false;
      this.state.asgnsGroup.forEach((item) => {
        if (matched) return;
        if (get(item, 'asgns', []).indexOf(asgn.id) > -1) {
          matched = true;
        }
      });
      return !matched;
    });
  };

  getRatioTotalCount = () => {
    const { asgnsGroup = [] } = this.state;
    if (asgnsGroup.length <= 0) return null;
    let v = 0;
    asgnsGroup.forEach((asgn) => {
      v += get(asgn, 'ratio', 0);
    });
    return v;
  };

  doExport = (cb) => {
    if (this.state.asgnsGroup.length <= 0) {
      Modal.error({
        title: '错误',
        content: '请先设置需要导出的作业组',
      });
      cb(false);
      return;
    }
    const v = this.getRatioTotalCount();
    if (v < 100) {
      Modal.warning({
        title: '权重设置错误',
        content: '当前所有作业的分值权重总和不足100%，无法进行导出操作',
      });
      cb(false);
      return;
    } if (v > 100) {
      Modal.error({
        title: '权重设置错误',
        content: '权重总和不能大于100%，无法进行导出操作',
      });
      cb(false);
      return;
    }
    const asgnSettings = [];
    const asgnRatios = {};
    const asgnMaxScore = {};

    this.state.asgnsGroup.forEach((g, index) => {
      const asgns = get(g, 'asgns', []);
      asgnSettings.push(asgns.length > 1 ? asgns : asgns[0]);
      asgnRatios[index] = g.ratio / 100.0;
      asgnMaxScore[index] = g.max_score;
    });

    const configs = {
      asgn_stcodes: this.state.studentCodes,
      asgn_ratios: asgnRatios,
      asgn_settings: asgnSettings,
      asgn_max_score: asgnMaxScore,
    };

    this.props.exportGrades({
      courseId: this.props.courseId,
      configs,
      onSuccess: (result) => {
        DownloadHelper(result, 'export.xls', 'application/octet-stream');
      },
      onComplete: () => {
        cb(true);
      },
    });
  };

  handleAddAsgnToList = () => {
    const { asgnsGroup } = this.state;
    asgnsGroup.push({
      id: asgnsGroup.length + 1,
      asgns: this.state.selectAsgns,
      asgnLabels: this.state.selectAsgnsOptions.map((item) => {
        return get(item, 'props.label', '');
      }),
      ratio: 0,
      max_score: Math.max(...this.state.selectAsgnsOptions.map((item) => {
        return get(item, 'props.dat_ams', 100);
      })),
    });
    this.setState({
      asgnsGroup,
      selectAsgns: [],
      selectAsgnsOptions: [],
    }, () => {
      localStorage.setItem(this.storageName, JSON.stringify(asgnsGroup));
    });
  };

  handleGroupRatioChange = index => (value) => {
    const asgnsGroup  = cloneDeep(this.state.asgnsGroup);
    this.setState({
      asgnsGroup: set(asgnsGroup, `${index}.ratio`, value),
    }, () => {
      localStorage.setItem(this.storageName, JSON.stringify(asgnsGroup));
    });
  };

  handleGroupMaxScoreChange = index => (value) => {
    const asgnsGroup  = cloneDeep(this.state.asgnsGroup);
    this.setState({
      asgnsGroup: set(asgnsGroup, `${index}.max_score`, value),
    }, () => {
      localStorage.setItem(this.storageName, JSON.stringify(asgnsGroup));
    });
  };

  handleDeleteGroup = index => () => {
    const asgnsGroup  = cloneDeep(this.state.asgnsGroup);
    asgnsGroup.splice(index, 1);
    this.setState({
      asgnsGroup,
    }, () => {
      localStorage.setItem(this.storageName, JSON.stringify(asgnsGroup));
    });
  };

  handleExcelFileAdd = (file) => {
    this.setState({
      excelFile: file,
    }, () => {
      importStudentCodeForGradeExportExcel(this.state.excelFile, (stuCodes = []) => {
        this.setState({
          studentCodes: stuCodes,
        });
      });
    });
    return false;
  };

  handleExcelFileRemove = () => {
    this.setState({
      studentCodes: [],
      excelFile: null,
    });
  };

  renderTips = () => {
    const v = this.getRatioTotalCount();
    return v > 100 ? <Alert
      showIcon
      type="error"
      style={{ marginBottom: 8 }}
      message="权重总和不能大于100，否则将无法计算成绩"
    /> :  <Alert
      showIcon
      type="info"
      style={{ marginBottom: 8 }}
      message={`当前所有作业的分值权重总和：${v || 0} %`}
    />;
  };

  renderListData = () => {
    const { asgnsGroup } = this.state;
    return asgnsGroup.map((item, index) => {
      return {
        key: index,
        id: index + 1,
        asgns: <>
          {item.asgnLabels.map((label) => {
            return <p style={{ margin: 0 }} key={label}>{label}</p>;
          })}
        </>,
        ratio: <>
          <InputNumber
            min={0}
            max={100}
            style={{ width: 80 }}
            value={item.ratio}
            onChange={this.handleGroupRatioChange(index)}
          /> %
        </>,
        max_score: <InputNumber
          min={1}
          max={200}
          style={{ width: 80 }}
          value={item.max_score}
          onChange={this.handleGroupMaxScoreChange(index)}
        />,
        operation: <a onClick={this.handleDeleteGroup(index)}>移除</a>,
      };
    });
  };

  render() {
    const asgnData = this.getAsgnDataWithFilter();
    return (
      <div className="course_asgn_export_grades">
        <Row gutter={8}>
          <Col span={16}>
            <h4 className="ant-typography">设置需要导出的作业</h4>
            {this.renderTips()}
            <Table
              bordered
              size="middle"
              dataSource={this.renderListData()}
              pagination={false}
              columns={this.COLUMNS}
            />
            <div className="add_asgns_container">
              <div className="left-area">
                <Select
                  placeholder="请选择作业并添加为作业组，同组作业取最高成绩"
                  onChange={(value, options) => {
                    this.setState({
                      selectAsgns: value,
                      selectAsgnsOptions: options,
                    });
                  }}
                  style={{ width: '100%' }}
                  mode="multiple"
                  value={this.state.selectAsgns}
                  disabled={asgnData.length <= 0}
                >
                  {asgnData.map((item) => {
                    return <Select.Option
                      key={get(item, 'id', '0')}
                      label={`#${get(item, 'id')}. ${truncate(get(item, 'title'), { length: 30 })}`}
                      value={get(item, 'id')}
                      dat_ams={get(item, 'full_score', 100)}
                    >
                      {`#${get(item, 'id')}. ${truncate(get(item, 'title'), { length: 30 })}`}
                    </Select.Option>;
                  })}
                </Select>
              </div>
              <div className="right-area">
                <Button
                  onClick={this.handleAddAsgnToList}
                  type="primary"
                  style={{ height: '100%' }}
                  disabled={asgnData.length <= 0 || this.state.selectAsgns.length <= 0}
                >
                  添加
                </Button>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <Typography>
              <Typography.Title level={4}>按指定学号顺序导出</Typography.Title>
              <Typography.Paragraph>
                需要按照指定的学号顺序进行导出，请在下方选择对应的学号模板文件，解析成功后即可使用。<a href="/static/excel-template/export_grade_stucode.xlsx">【学号模板下载】</a>
              </Typography.Paragraph>
            </Typography>
            <Upload
              className="parse_excel"
              fileList={this.state.excelFile ? [this.state.excelFile] : []}
              accept=".xlsx"
              multiple={false}
              onRemove={this.handleExcelFileRemove}
              beforeUpload={this.handleExcelFileAdd}
            >
              <Button>
                <FileExcelOutlined /> 选择Excel文件(2007+)
              </Button>
            </Upload>
            {this.state.studentCodes.length > 0 && <List
              size="small"
              header={<div><strong>已解析列表</strong></div>}
              bordered
              dataSource={this.state.studentCodes}
              renderItem={item => <List.Item>{item}</List.Item>}
            />}
          </Col>
        </Row>
      </div>
    );
  }
}

export default CourseExportGrades;
