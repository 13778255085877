import 'styles/education/course/course_asgn_list.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
import {
  Button, Spin, Dropdown, Menu, Modal 
} from 'antd';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  get, isEqual, pick, noop 
} from 'lodash';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import { getQuery, buildPath, openLink } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { PaginationParams } from 'scripts/common/constants/global';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';
import { AsgnsSelectors } from 'scripts/common/logic/education/asgn/selectors/asgn';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { AsgnRoutes, CourseRoutes } from 'scripts/apps/routes';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { AddAsgnButton } from 'scripts/apps/education/course/widgets/add_asgn';
import { CourseSelectors } from 'scripts/common/logic/education/course';
import PathToRegexp from 'path-to-regexp';
import API from 'scripts/common/constants/apis';
import { createPermissionChecker } from 'scripts/common/utils/validator';
import { CoursePermissionsEnum } from 'scripts/common/enums/course';
import DownloadHelper from 'downloadjs';
import AsgnListTable from './widgets/course_asgn_list_table';
import CourseExportCodesTask from './widgets/course_export_codes_task';
import CourseExportGrades from './widgets/course_export_grades';

const LOADING_STATUS_KEY = 'asgn_list';

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnsList: AsgnActions.getAsgnsList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  createExportCodes: CourseActions.createExportCodes,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  exportGrades: CourseActions.exportGrades,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const asgnDatas = AsgnsSelectors.getAsgnListData(state);
  const pager = AsgnsSelectors.asgnListViewPagerSelector(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const course = CourseSelectors.getCourseEntity(state)(props.courseId);
  const coursePermission = createPermissionChecker(get(course, 'permissions_calculated', {}));
  const hasManageCoursePermission = coursePermission(CoursePermissionsEnum.MANAGE);
  const hasCreateAsgnPermission = get(course, 'create_asgn_permissions', false);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page']),
  };
  return {
    queryParams,
    isLoading,
    asgnDatas,
    hasCreateAsgnPermission,
    hasManageCoursePermission,
    paginationParams: pager || props.paginationParams,
  };
};

@withRouter
@LoginRequire
@connect(mapStateToProps, mapDispatchToProps)
class AsgnListContent extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getAsgnsList({
      courseId: nextProps.courseId,
      query: pick(
        nextProps.queryParams,
        ['limit', 'page']
      ),
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes =  {
    ...RoutePropTypes,
    getAsgnsList: PropTypes.func,
    asgnDatas: PropTypes.arrayOf(AsgnPropTypes),
    courseId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    hasManageCoursePermission: PropTypes.bool,
    hasCreateAsgnPermission: PropTypes.bool,
    setLoadingStatus: PropTypes.func,
    paginationParams: PaginationPropTypes,
    createExportCodes: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    exportGrades: PropTypes.func,
  };

  static defaultProps = {
    getAsgnsList: noop,
    asgnDatas: [],
    isLoading: true,
    hasManageCoursePermission: false,
    hasCreateAsgnPermission: false,
    setLoadingStatus: noop,
    paginationParams: { ...PaginationParams },
    createExportCodes: noop,
    exportGrades: noop,
    viewSuccessMessage: noop,
  };

  state = {
    queryParams: {},
    sending: false,
    mode: 'list',
    asgnSelected: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      AsgnListContent.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  gradeExportViewRef = React.createRef();

  handleGetAsgnList = () => {
    AsgnListContent.fetchList(this.props);
  };

  handleAsgnClick = asgn => () => {
    const { history } = this.props;
    history.push(buildPath(
      AsgnRoutes.ASGN,
      { courseId: this.props.courseId, asgnId: asgn.id }
    ));
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseRoutes.CONTENT_ASGN,
      { courseId: this.props.courseId },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleAsgnSelectChange = (selectedRowKeys) => {
    this.setState({
      asgnSelected: selectedRowKeys,
    });
  };

  handleExportCodeClick = () => {
    const call = () => {
      this.setState({
        sending: true,
      }, () => {
        this.props.createExportCodes({
          asgn_ids: this.state.asgnSelected,
          courseId: this.props.courseId,
          onSuccess: () => {
            this.setState({
              sending: false,
              mode: 'export_codes_history',
            }, () => {
              this.props.viewSuccessMessage({
                message: '成功创建打包任务，请在任务历史列表中等待导出结果',
              });
            });
          },
        });
      });
    };
    if (this.state.asgnSelected.length === 0) {
      Modal.confirm({
        title: '提示',
        content: '不选择作业的话会导出全部哦，是否继续？',
        okText: '继续',
        onOk: call,
      });
    } else {
      call();
    }
  };

  handleExportGradesClick = () => {
    this.setState({
      sending: true,
    }, () => {
      if (!this.gradeExportViewRef || !this.gradeExportViewRef.current) return;
      this.gradeExportViewRef.current.doExport(() => {
        this.setState({
          sending: false,
        });
      });
    });
  };

  handleFastExportGradesClick = () => {
    this.setState({
      sending: true,
    }, () => {
      this.props.exportGrades({
        fast: true,
        courseId: this.props.courseId,
        onSuccess: (result) => {
          DownloadHelper(result, 'export.xls', 'application/octet-stream');
        },
        onComplete: () => {
          this.setState({
            sending: false,
          });
        },
      });
    });
  };

  handleAsgnExportMenuChange = (param) => {
    const { key } = param;
    switch (key) {
      case 'codes':
      case 'grades':
      case 'codes_history':
        this.setState({
          mode: `export_${key}`,
        });
        break;
      default:
        break;
    }
  };

  renderAsgnExportMenu = () => {
    return <Menu onClick={this.handleAsgnExportMenuChange}>
      <Menu.Item key="grades">导出成绩</Menu.Item>
      <Menu.Item key="codes">导出代码</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="codes_history">导出代码历史</Menu.Item>
    </Menu>;
  };

  renderToolbarRightArea = () => {
    switch (this.state.mode) {
      case 'export_codes':
        return <Button
          type="primary"
          loading={this.state.sending}
          onClick={this.handleExportCodeClick}
        >
          确认导出
        </Button>;
      case 'export_grades':
        return <>
          <Button
            type="default"
            loading={this.state.sending}
            onClick={this.handleFastExportGradesClick}
          >
            导出全部
          </Button>
          <Button
            style={{ marginLeft: 16 }}
            type="primary"
            loading={this.state.sending}
            onClick={this.handleExportGradesClick}
          >
            按设置导出
          </Button>
        </>;
      case 'export_codes_history':
        return null;
      default:
        return null;
    }
  };

  renderToolbarLeftArea = () => {
    switch (this.state.mode) {
      case 'export_codes':
      case 'export_grades':
      case 'export_codes_history':
        return <Button
          type="default"
          disabled={this.state.sending}
          onClick={() => {
            this.setState({
              mode: 'list',
            });
          }}
        >
          返回
        </Button>;
      default:
        return (
          <>
            {this.props.hasCreateAsgnPermission
              ? <AddAsgnButton 
                courseId={this.props.courseId}
                onAddAsgnSuccess={this.handleGetAsgnList}
              /> : null}
            <Dropdown
              overlay={this.renderAsgnExportMenu()}
            >
              <Button type="default" style={{ marginLeft: 8 }}>
                导出 <DownOutlined />
              </Button>
            </Dropdown>
          </>
        );
    }
  };

  renderListArea = () => {
    const canSelected = this.state.mode === 'export_codes';
    switch (this.state.mode) {
      case 'export_codes_history':
        return <CourseExportCodesTask
          courseId={this.props.courseId}
        />;
      case 'export_grades':
        return <CourseExportGrades
          courseId={this.props.courseId}
          asgnDatas={this.props.asgnDatas}
          ref={this.gradeExportViewRef}
          exportGrades={this.props.exportGrades}
        />;
      default:
        return <>
          <AsgnListTable
            onPageChange={this.handlePageChange}
            asgnDatas={this.props.asgnDatas}
            onAsgnClick={this.handleAsgnClick}
            paginationParams={this.props.paginationParams}
            courseId={this.props.courseId}
            enableCheckbox={canSelected}
            onSelectedRowChange={this.handleAsgnSelectChange}
          />
        </>;
    }
  };

  render() {
    return (
      <div className="course_view_body">
        <div className="course_asgn_list_view">
          <Spin
            size="large"
            tip="加载中..."
            spinning={this.props.isLoading}
          >
            <div className="asgn_table">
              {this.props.hasManageCoursePermission && <div className="control_bar">
                <div className="left-area">
                  {this.renderToolbarLeftArea()}
                </div>
                <div className="right-area">
                  {this.renderToolbarRightArea()}
                </div>
              </div>}
              <div className="asgn_table_content">
                {this.renderListArea()}
              </div>
            </div>
          </Spin>
        </div>
      </div>
    );
  }
}

export default AsgnListContent;
