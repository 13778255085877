import 'styles/education/course/management.scss';
import React from 'react';
import { noop, set, get } from 'lodash';
import {
  Menu, Button, Checkbox, Alert, Spin 
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  matchPath, withRouter, Switch, Route, Redirect 
} from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { buildPath } from 'scripts/common/utils/location_helper';
import { WeJudgeActions } from 'scripts/common/logic/wejudge/index';
import { CategoryActions } from 'scripts/common/logic/category/action';
import { CategorySelectors } from 'scripts/common/logic/category/selector';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { CategoryPropTypes } from 'scripts/common/prop-types/category'; 
import { CourseRoutes, CourseManagementRoutes } from 'scripts/apps/routes';
import { CourseSelectors } from 'scripts/common/logic/education/course/selector';
import CourseArrangementView from 'scripts/apps/education/course/course_arrangement';
import LessonManagementView from 'scripts/apps/education/course/lesson_manage_view';
import { CommonCategoryTypeEnum } from 'scripts/common/enums/common';
import CourseInfoEditor from './widgets/course_info_editor';
import CenterSpin from 'scripts/common/widgets/center-spin';

const mapDispatchToProps = dispatch => bindActionCreators({
  modifyCourse: CourseActions.modifyCourse,
  deleteCourse: CourseActions.deleteCourse,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  getCourseExtraInfo: CourseActions.getCourseExtraInfo,
  resetChoosingCode: CourseActions.resetChoosingCode,
  getCourseEntity: CourseActions.getCourseEntity,
  getCategoryTree: CategoryActions.getCategoryTree,
}, dispatch);

const mapStateToProps = (state, props) => {
  const course = CourseSelectors.getCourseEntity(state)(props.courseId);
  const { location } = props;
  const matchItem = matchPath(
    location.pathname,
    { path: CourseManagementRoutes.TABITEMS }
  );
  const categoryTree = CategorySelectors.categoryTreeViewSelector(
    CommonCategoryTypeEnum.COURSE.value
  )(state) || [];
  const ItemKey = get(matchItem, 'params.tabItemName', '');
  const tabItemKey = (ItemKey === '' ? 'info' : ItemKey);
  return {
    course,
    tabItemKey,
    categoryTree,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CourseManagementView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    handleChange: PropTypes.func,
    getCourseExtraInfo: PropTypes.func,
    courseId: PropTypes.number.isRequired,
    course: CoursePropTypes,
    hasDeleteCoursePermission: PropTypes.bool,
    resetChoosingCode: PropTypes.func,
    getCourseEntity: PropTypes.func,
    tabItemKey: PropTypes.string.isRequired,
    categoryTree: PropTypes.arrayOf(CategoryPropTypes).isRequired,
  };

  static defaultProps = {
    course: null,
    handleChange: noop,
    getCourseExtraInfo: noop,
    hasDeleteCoursePermission: false,
    resetChoosingCode: noop,
    getCourseEntity: noop,
  };

  state = {
    processing: false,
    agree: false,
    isLoading: false,
  };

  componentDidMount() {
    this.props.getCategoryTree({
      type: CommonCategoryTypeEnum.COURSE.value,
    });
    // this.setState({         // eslint-disable-line
    //   isLoading: true,
    // }, () => {
    //   this.props.getCourseExtraInfo({
    //     courseId: this.props.courseId,
    //     onComplete: () => {
    //       this.setState({
    //         isLoading: false,
    //       });
    //     },
    //   });
    // });
  }

  handleSubmit = () => {
    this.setState({ isLoading: true }, () => {
      this.props.modifyCourse({
        courseId: this.props.courseId,
        ...this.state.course,
        category: get(this.state.course, 'category.1', get(this.state.course, 'category.0', 0)),
        onSuccess: () => {
          this.props.viewSuccessMessage({
            message: '保存课程信息成功',
          });
          this.props.getCourseEntity({
            courseId: this.props.courseId,
            onSuccess: () => {
              this.props.getCourseExtraInfo({
                courseId: this.props.courseId,
                onComplete: () => {

                },
              });
            },
          });
        },
        onComplete: () => {
          this.setState({
            isLoading: false,
          });
        },
      });
    });
  };

  handleDeleteClick = () => {
    this.setState({
      processing: true,
    }, () => {
      const { deleteCourse, history } = this.props;
      deleteCourse({
        courseId: this.props.courseId,
        onSuccess: () => {
          history.push(buildPath(CourseRoutes.LIST));
        },
        onComplete: () => {
          this.setState({
            processing: false,
          });
        },
      });
    });
  };

  handleTabSelect = ({ key }) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseManagementRoutes.TABITEMS,
      {
        tabItemName: key,
      },
      match.params
    ));
  };

  handleResetChoosingCodeClick = () => {
    this.props.resetChoosingCode({
      courseId: this.props.courseId,
      onSuccess: () => {
      },
    });
  };

  renderDeleteAlertMessage = () => {
    return (<ul className="message_ul">
      <li>当前课程内所有信息将被永久删除</li>
      <li>课程资源等存储于文件系统上的内容将不会被删除。</li>
      <li>当前操作仅能由课程创建者、教务老师或者系统管理员执行。</li>
    </ul>);
  };

  renderRouter = () => {
    if (this.state.isLoading) return <CenterSpin tip="处理中" />;
    return <Switch>
      <Route
        path={CourseManagementRoutes.INFO}
        render={props => <CourseInfoEditor
          {...props}
          course={this.props.course}
          handleChange={(c) => { this.setState({ course: c }); }}
          onSubmit={this.handleSubmit}
          categoryTree={this.props.categoryTree}
        />}
        exact
      />
      <Route
        path={CourseManagementRoutes.LESSON_VIEW}
        render={props => <LessonManagementView
          {...props}
          schoolId={get(this.props.course, 'school.id')}
          courseId={this.props.courseId}
        />}
      />
      <Route
        path={CourseManagementRoutes.ARRANGEMENT}
        render={props => <CourseArrangementView
          {...props}
          schoolId={get(this.props.course, 'school.id')}
          courseId={this.props.courseId}
        />}
      />
      <Route
        path={CourseManagementRoutes.CHOSSING}
        render={() => <div className="course-choosing-code-view">
          <div className="course-choosing-code-body">
            <div className="choosing-code-title">
              {get(this.props.course, 'choosing_code', '')}
            </div>
            <div className="choosing-code-btn">
              <Button type="primary" onClick={this.handleResetChoosingCodeClick}>
                重置选课码
              </Button>
            </div>
          </div>
        </div>}
        exact
      />
      {this.props.hasDeleteCoursePermission && <Route
        path={CourseManagementRoutes.DELETE}
        render={() => <div style={{ margin: '16px' }}>
          <Alert
            message="你应该清楚这个操作的风险！"
            description={this.renderDeleteAlertMessage()}
            type="error"
            iconType="info-circle-o"
            showIcon
          />
          <div className="del-control-bar">
            <Button
              type="danger"
              loading={this.state.processing}
              disabled={!this.state.agree}
              onClick={this.handleDeleteClick}
            >
              确认删除
            </Button>
            <Checkbox
              style={{ marginLeft: 8 }}
              disabled={this.state.agree}
              checked={this.state.agree}
              onChange={({ target }) => { this.setState({ agree: target.checked }); }}
            >我已阅读须知</Checkbox>
          </div>
        </div>}
        exact
      />}
      <Route
        render={() => <Redirect
          to={buildPath(
            CourseManagementRoutes.INFO,
            null,
            this.props.match.params
          )}
        />}
      />
    </Switch>;
  };

  render() {
    return (<div className="course_view_body">
      <div className="course_management_view">
        <div className="left_side_nav">
          <Menu
            mode="inline"
            onSelect={this.handleTabSelect}
            selectedKeys={[this.props.tabItemKey]}
          >
            <Menu.Item key="info">基本信息</Menu.Item>
            <Menu.Item key="lesson">课堂管理</Menu.Item>
            <Menu.Item key="arrangement">排课管理</Menu.Item>
            <Menu.Item key="choosing">选课</Menu.Item>
            {this.props.hasDeleteCoursePermission && <Menu.Item key="delete">删除课程</Menu.Item>}
          </Menu>
        </div>
        <div className="right_side_body">
          {this.renderRouter()}
          {/*{this.state.tabKey === 'assistant' && <>*/}
          {/*  <AssistantListView courseId={this.props.courseId} course={this.props.course} />*/}
          {/*</>}*/}
        </div>
      </div>
    </div>);
  }
}

export default CourseManagementView;
