import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import {
  Empty, Typography, Input, Button, Modal, Spin 
} from 'antd';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import ContestManagePermissionCheck from 'scripts/apps/contest/manage_permission';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { bindActionCreators } from 'redux';
import { ContestSupportActions, ContestSupportSelectors } from 'scripts/common/logic/contest/support';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

import { noop, get } from 'lodash';
import 'styles/contest/manager/notice.scss';

import { formatTime } from 'scripts/common/utils/time_formatter';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import CenterSpin from 'scripts/common/widgets/center-spin';

const { Paragraph } = Typography;

const LOADING_STATUS_KEY = 'contest_notice_list_view';
const mapStateToProps = (state) => {
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);

  return {
    noticesData: ContestSupportSelectors.getContestNoticesListData(state),
    isLoading,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  addNotice: ContestSupportActions.addNotice,
  editNotice: ContestSupportActions.editNotice,
  delNotice: ContestSupportActions.delNotice,
  getNoticeList: ContestSupportActions.getNoticeList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

@ContestLoginRequire
@ContestManagePermissionCheck({
  requireAdmin: false,
})
@withRouter
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class NoticeManageView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    noticesData: PropTypes.arrayOf(PropTypes.shape({})),
    addNotice: PropTypes.func,
    delNotice: PropTypes.func,
    editNotice: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    noticesData: [],
    addNotice: noop,
    delNotice: noop,
    editNotice: noop,
  };

  state = {
    currentNoticeId: -1,
    showEditor: true, // 暂时不改了吧，直接开出编辑器
    currentNotice: {
      title: '',
      content: '',
    },
  };

  componentWillUnmount() {
    this.unmounted = true;
  }

  unmounted = false;

  fetchList = () => {
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.props.getNoticeList({
      contestId: this.props.contest.id,
      onComplete: () => {
        this.handleAddClick();
        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  onNoticeClick = item => () => {
    this.setState({
      currentNoticeId: item.id,
      addNotice: false,
      currentNotice: JSON.parse(JSON.stringify(item)),
    });
  };

  handleAddNoticeClick = () => {
    this.props.addNotice({
      contestId: this.props.contest.id,
      title: this.state.currentNotice.title,
      content: this.state.currentNotice.content,
      onSuccess: () => {
        this.fetchList(this.props);
      },
    });
  };

  handleEditNoticeClick = () => {
    this.props.editNotice({
      contestId: this.props.contest.id,
      noticeId: this.state.currentNotice.id,
      title: this.state.currentNotice.title,
      content: this.state.currentNotice.content,
    });
  };

  handleDelNoticeClick = () => {
    Modal.confirm({
      title: '删除公告',
      content: '您确认要删除此公告吗',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.props.delNotice({
          contestId: this.props.contest.id,
          noticeId: this.state.currentNotice.id,
          onSuccess: () => {
            this.fetchList(this.props);
          },
        });
      },
    });
  };

  handleAddClick = () => {
    this.setState({
      currentNoticeId: -1,
      showEditor: true,
      currentNotice: {
        title: '',
        content: '',
      },
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      ...this.state,
      currentNotice: {
        ...this.state.currentNotice,
        [keyName]: target.value,
      },
    });
  };

  handleEditorChange = (text) => {
    this.setState({
      currentNotice: {
        ...this.state.currentNotice,
        content: text,
      },
    });
  };

  renderList = () => {
    return this.props.noticesData.map((item) => {
      return <div
        key={item.id}
        onClick={this.onNoticeClick(item)}
        className={classnames('notice-card', { 'notice-card-hover': item.id === this.state.currentNoticeId })}
      >
        <div className="title">
          {item.title}
        </div>
        <div className="timer">
          {formatTime(get(item, 'create_time', 0), 'SHORT_DATETIME')}
        </div>
      </div>;
    });
  };

  renderContent = () => {
    if (this.props.noticesData.length <= 0) {
      return <div style={{ marginTop: 16, width: '100%' }}><Empty description="暂无公告" /></div>;
    }
    const target = this.props.noticesData.filter(item => item.id === this.state.currentNoticeId);
    if (target.length > 0) {
      const item = target[0];
      return <div className="notice-content">
        <div className="header">
          {item.title}
        </div>
        <div className="content">
          <Paragraph>
            <span dangerouslySetInnerHTML={{ __html: item.content }} />
          </Paragraph>
        </div>
        <div className="meta">
          发布者: {get(item, 'author.nickname', '')}；
          创建时间： {formatTime(get(item, 'create_time', 0), 'SHORT_DATETIME')}
          {(get(item, 'create_time', 0) !== get(item, 'update_time', 0)) && `；修改时间： ${formatTime(get(item, 'update_time', 0), 'SHORT_DATETIME')}`}
        </div>
      </div>;
    }
    return <div />;
  };

  render() {
    const isAdd = this.state.currentNoticeId === -1;
    return (
      <div className="contest-notice-manage">
        {!this.props.isLoading ? <>
          <div className="notice-outer-list">
            <div className="notice-inner-list">
              <div
                className={classnames('notice-card', {
                  'notice-card-add': true,
                  'notice-card-hover': this.state.currentNoticeId === -1,
                })}
                onClick={this.handleAddClick}
              >
                <PlusOutlined /> 新增公告
              </div>
              {this.renderList()}
            </div>
          </div>
          <div className="contest-notice-content">
            {this.state.showEditor ? <div className="notice-editor">
              <h3>{isAdd ? '创建公告' : '修改公告'}</h3>
              <div className="editor_title">
                <Input value={this.state.currentNotice.title} placeholder="公告标题" width={200} onChange={this.handleInputChange('title')} />
              </div>
              <div className="editor_area">
                <MarkdownEditor
                  key={this.state.currentNotice.id || 'new'}
                  height="100%"
                  onChange={this.handleEditorChange}
                  defaultValue={this.state.currentNotice.content}
                />
              </div>
              {isAdd ? <div className="button_groups">
                <Button type="primary" onClick={this.handleAddNoticeClick}>创建公告</Button>
              </div> : <div className="button_groups">
                <Button type="primary" onClick={this.handleEditNoticeClick}>保存修改</Button>
                <Button type="danger" onClick={this.handleDelNoticeClick}>删除公告</Button>
              </div>}
            </div> : this.renderContent()}
          </div>
        </> : <CenterSpin size="large" tip="加载中..."  />}
      </div>);
  }
}

export default NoticeManageView;
