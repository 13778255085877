import React from 'react';
import PropTypes from 'prop-types';
import {
  get, set, isEqual, noop 
} from 'lodash';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Form, Modal, Row, Col, Input, Switch, Alert, Upload, Button, Progress, Spin 
} from 'antd';
import { TestCasesPropTypes } from 'scripts/common/prop-types/problem';
import APIs from 'scripts/common/constants/apis';
import { buildApiPath } from 'scripts/common/utils/location_helper';
import { buildResourcePath, openLink } from '../../../../common/utils/location_helper';

const { TextArea } = Input;

class TestCasesEditor extends React.PureComponent {
  static propTypes = {
    mode: PropTypes.string,
    collectionId: PropTypes.string.isRequired,
    problemId: PropTypes.string.isRequired,
    testCase: TestCasesPropTypes,
    testCaseDataTemp: PropTypes.shape({
      stdin: PropTypes.string,
      stdout: PropTypes.string,
      overload: PropTypes.bool,
    }),
    getTestCaseDatas: PropTypes.func,
    downloadTestCases: PropTypes.func,
  };

  static defaultProps = {
    mode: 'new',
    testCase: {
      name: '',
      visible: false,
      available: true,
    },
    testCaseDataTemp: {
      stdin: '',
      stdout: '',
      overload: false,
    },
    getTestCaseDatas: noop,
    downloadTestCases: noop,
  };

  state = {
    isLoading: false,
    raw: null,
    testCase: {},
    testCaseDataTemp: {},
    stdinProgressBar: -1,
    stdoutProgressBar: -1,
    stdinProgress: 0,
    stdoutProgress: 0,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.testCase, get(prevState.raw, 'testCase'))
      || !isEqual(nextProps.testCaseDataTemp, get(prevState.raw, 'testCaseDataTemp'))) {
      return {
        raw: {
          testCase: nextProps.testCase,
          testCaseDataTemp: nextProps.testCaseDataTemp,
        },
        testCase: JSON.parse(JSON.stringify(nextProps.testCase)),
        testCaseDataTemp: JSON.parse(JSON.stringify(nextProps.testCaseDataTemp)),
      };
    }
    return null;
  }

  onOk = (model, state) => {
    if (this.state.isLoading) return;
    this.props.onOk(model, state);
  };

  handleInput = key => ({ target }) => {
    this.setState(set({ testCase: { ...this.state.testCase } }, key, target.value));
  };

  handleInputTC = key => ({ target }) => {
    this.setState(set({ testCaseDataTemp: { ...this.state.testCaseDataTemp } }, key, target.value));
  };

  handleSwitch = key => (checked) => {
    this.setState(set({ testCase: { ...this.state.testCase } }, key, checked));
  };

  handleBeforeUploadChange = (key = 'sdtin') => () => {
    this.setState({
      [`${key}ProgressBar`]: 0,
    });
  };

  handleUploadChange = (key = 'sdtin') => (info) => {
    if (info.file.status === 'uploading') {
      this.setState({
        [`${key}Progress`]: parseFloat(get(info, 'event.percent', 0).toFixed(1)),
      });
    }
    if (info.file.status === 'done') {
      this.setState({
        [`${key}Progress`]: 100,
        [`${key}ProgressBar`]: 1,
      });
      this.setState({
        isLoading: true,
      });
      this.props.getTestCaseDatas({
        collectionId: this.props.collectionId,
        problemId: this.props.problemId,
        testCaseId: this.props.testCase.handle,
        onComplete: () => {
          this.setState({
            isLoading: false,
          });
        },
        onSuccess: (result) => {
          this.setState({
            testCaseDataTemp: result,
          });
        },
      });
    } else if (info.file.status === 'error') {
      this.setState({
        [`${key}ProgressBar`]: 2,
      });
      Modal.error({
        title: '上传失败',
        content: get(info, 'file.response.errors.0.message', '未知错误'),
      });
    }
  };

  handleTestCasesDownload = (key) => {
    this.props.downloadTestCases({
      collectionId: this.props.collectionId,
      problemId: this.props.problemId,
      testCaseId: this.props.testCase.handle,
      key,
      onSuccess: (result) => {
        const { vpath } = result;
        const url = buildResourcePath(vpath);
        openLink(url, true, true);
      },
    });
  }

  render() {
    const uploadAction = buildApiPath(
      APIs.COLLECTION.COLLECTION_PROBLEMS.JUDGE.TESTCASE.UPLOAD,
      {
        cid: this.props.collectionId,
        pid: this.props.problemId,
        tcid: get(this.props.testCase, 'handle', 'null'),
      }
    );
    const STATUS_MAPPING = {
      0: 'active',
      1: 'success',
      2: 'exception',
    };
    const overLoaded = get(this.state.testCaseDataTemp, 'overload', false);
    return (
      <Modal
        wrapClassName="test-cases-editor-dialog"
        {...this.props}
        onOk={this.onOk}
        title={this.props.mode === 'new' ? '新增测试单元' : '修改测试单元'}
      >
        <Spin spinning={this.state.isLoading}>
          <Form.Item>
            <div className="test-case-base-info">
              <div className="info-title">
                <Input
                  placeholder="测试数据名称（标识）"
                  value={get(this.state.testCase, 'name', '')}
                  onChange={this.handleInput('testCase.name')}
                />
              </div>
              <div className="info-switch">
                <Switch
                  onChange={this.handleSwitch('testCase.available')}
                  checked={get(this.state.testCase, 'available', false)}
                /> 数据有效
              </div>
              <div className="info-switch">
                <Switch
                  onChange={this.handleSwitch('testCase.visible')}
                  checked={get(this.state.testCase, 'visible', false)}
                /> 公开数据
              </div>
            </div>
          </Form.Item>
          {overLoaded
          && <Alert
            type="warning"
            message="测试数据内容过大，无法直接通过网页编辑"
            description="你依然可以编辑数据的设置，如果需要更改输入输出数据，请使用【上传】功能。"
          />}
          <Row gutter={8}>
            <Col span={12}>
              <h4>测试数据输入</h4>
              <TextArea
                disabled={overLoaded}
                placeholder="测试数据输入（StdIn）"
                rows={overLoaded ? 4 : 20}
                value={get(this.state.testCaseDataTemp, 'stdin', '')}
                onChange={this.handleInputTC('testCaseDataTemp.stdin')}
              />
              {this.props.mode !== 'new' && <div className="upload-area">
                <div className="control">
                  <Upload
                    withCredentials
                    action={uploadAction}
                    name="stdin"
                    onChange={this.handleUploadChange('stdin')}
                    beforeUpload={this.handleBeforeUploadChange('stdin')}
                    showUploadList={false}
                  >
                    <Button>
                      <UploadOutlined /> 上传文件数据
                    </Button>
                  </Upload>
                  <span style={{ marginLeft: '8px' }}>
                    <Button onClick={() => this.handleTestCasesDownload('in')}>
                      <DownloadOutlined /> 下载文件数据
                    </Button>
                  </span>
                </div>
                <div className="progress">
                  {this.state.stdinProgressBar > -1 && <Progress
                    percent={this.state.stdinProgress}
                    status={get(STATUS_MAPPING, this.state.stdinProgressBar, 'active')}
                  />}
                </div>
              </div>}
            </Col>
            <Col span={12}>
              <h4>测试数据输出</h4>
              <TextArea
                disabled={overLoaded}
                placeholder="测试数据输出（StdOut）"
                rows={overLoaded ? 4 : 20}
                value={get(this.state.testCaseDataTemp, 'stdout', '')}
                onChange={this.handleInputTC('testCaseDataTemp.stdout')}
              />
              {this.props.mode !== 'new' && <div className="upload-area">
                <div className="control">
                  <Upload
                    withCredentials
                    action={uploadAction}
                    name="stdout"
                    onChange={this.handleUploadChange('stdout')}
                    beforeUpload={this.handleBeforeUploadChange('stdout')}
                    showUploadList={false}
                  >
                    <Button>
                      <UploadOutlined /> 上传文件数据
                    </Button>
                  </Upload>
                  <span style={{ marginLeft: '8px' }}>
                    <Button onClick={() => this.handleTestCasesDownload('out')}>
                      <DownloadOutlined /> 下载文件数据
                    </Button>
                  </span>
                </div>
                <div className="progress">
                  {this.state.stdoutProgressBar > -1 && <Progress
                    percent={this.state.stdoutProgress}
                    status={get(STATUS_MAPPING, this.state.stdoutProgressBar, 'active')}
                  />}
                </div>
              </div>}
            </Col>
          </Row>
        </Spin>
      </Modal>
    );
  }
}

export default TestCasesEditor;
