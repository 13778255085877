import 'styles/widgets/judge_status.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import html2canvas from 'html2canvas';
import { CameraOutlined, SwapOutlined, SwapRightOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Timeline,
  Tag,
  Spin,
  Row,
  Statistic,
  Tooltip,
  Alert,
  Menu,
  Popover,
  Result,
  Select,
  Popconfirm,
  PageHeader,
  Table
} from 'antd';
import {
  get, noop, isEmpty, indexOf, isString 
} from 'lodash';
import { JudgeFlagEnum } from 'scripts/common/enums/judge';
import { JudgeStatusPropTypes } from 'scripts/common/prop-types/judge_status';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { formatTime, formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import CodeEditor from 'scripts/common/widgets/code_mirror';
import TextDiffWidget from 'scripts/common/widgets/difftools/textdiff';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { JUDGE_STATUS } from 'scripts/apps/helper/constant.js';

const LOADING_STATUS_KEY = 'judge_status_details';
const ENABLE_JUDGE_STATUS_FLAGS = [
  JudgeFlagEnum.AC.value,
  JudgeFlagEnum.PE.value,
  JudgeFlagEnum.TLE.value,
  JudgeFlagEnum.MLE.value,
  JudgeFlagEnum.WA.value,
  JudgeFlagEnum.OLE.value,
  JudgeFlagEnum.RE.value,
  JudgeFlagEnum.CE.value,
  JudgeFlagEnum.MANUAL.value
];
const COLOR_MAPPING = {
  0: 'blue',
  1: 'green',
  2: 'orange',
  3: 'red',
};

const LINUX_SIGNAL_NUMBERS = {
  4: {
    name: 'SIGILL',
    desc: '非法指令',
  },
  6: {
    name: 'SIGABRT/SIGIOT',
    desc: '异常中止',
  },
  7: {
    name: 'SIGBUS',
    desc: '总线异常',
  },
  8: {
    name: 'SIGFPE',
    desc: '浮点运算溢出，也有可能是除数为零等情况',
  },
  9: {
    name: 'SIGKILL',
    desc: '强制进程终止(SIGKILL)，可能是因为程序时间或内存超限导致操作系统强制结束程序',
  },
  11: {
    name: 'SIGSEGV',
    desc: '非法内存地址引用，可能是由数组越界、非法指针、内存超限等引发的，通常是RE、MLE的触发条件',
  },
  14: {
    name: 'SIGALRM',
    desc: '时钟中断，通常是TLE的触发条件',
  },
  15: {
    name: 'SIGTERM',
    desc: '进程终止',
  },
  16: {
    name: 'SIGSTKFLT',
    desc: '协处理器栈错误',
  },
  24: {
    name: 'SIGXCPU',
    desc: 'CPU时间限制被打破，通常是TLE的触发条件',
  },
  25: {
    name: 'SIGXFSZ',
    desc: '文件大小限制被打破，通常是OLE的触发条件。',
  },
  31: {
    name: 'SIGSYS/SYSUNUSED',
    desc: '系统调用异常',
  },
};

export const createJudgeResultModal = (JudgeStatusActions, JudgeStatusSelectors, appName = '') => {
  @connect((state, props) => {
    const judgeStatus = JudgeStatusSelectors[`get${appName}JudgeStatusEntity`](state)(props.judgeStatusId);
    const judgeDetailsSelector =      JudgeStatusSelectors.judgeStatusDetailsSelector(props.judgeStatusId)(state);
    const judgeDetails = get(judgeDetailsSelector, 'overviews', {});
    const judgeDetailsTCDatas = get(judgeDetailsSelector, 'test_cases', {});
    const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
    const testCases = [...get(judgeStatus, 'problem.options.judge_options.test_cases', [])];
    testCases.sort((a, b) => parseInt(get(a, 'order', 0), 10) > parseInt(get(b, 'order', 0), 10));
    return {
      isLoading,
      testCases,
      judgeDetails,
      judgeStatus,
      judgeDetailsTCDatas,
    };
  }, dispatch => bindActionCreators({
    getJudgeStatusDetails: JudgeStatusActions[`get${appName}JudgeStatusDetails`],
    getJudgeStatusTcDataDetails: JudgeStatusActions[`get${appName}JudgeStatusTcDataDetails`],
    editJudgeStatus: JudgeStatusActions[`edit${appName}JudgeStatus`],
    doReJudge: get(JudgeStatusActions, `do${appName}ReJudgeSingle`, noop),
    setLoadingStatus: WeJudgeActions.setLoadingStatus,
  }, dispatch), null, { forwardRef: true })
  class JudgeResultModal extends React.PureComponent {
    static propTypes = {
      apiPayloads: PropTypes.shape({}).isRequired,
      judgeStatusId: PropTypes.number.isRequired,
      judgeStatus: JudgeStatusPropTypes,
      judgeDetails: PropTypes.shape({}),
      testCases: PropTypes.arrayOf(PropTypes.shape({})),
      judgeDetailsTCDatas: PropTypes.shape({}),
      getJudgeStatusDetails: PropTypes.func,
      getJudgeStatusTcDataDetails: PropTypes.func,
      changeJudgeStatusFlag: PropTypes.func,
      editJudgeStatus: PropTypes.func,
      doReJudge: PropTypes.func,
      isManualJudge: PropTypes.bool,
      canReJudge: PropTypes.bool,
      setLoadingStatus: PropTypes.func,
      onCancel: PropTypes.func.isRequired,
      isLoading: PropTypes.bool,
    };

    static defaultProps = {
      judgeStatus: null,
      isLoading: true,
      testCases: {},
      judgeDetails: null,
      judgeDetailsTCDatas: {},
      editJudgeStatus: noop,
      getJudgeStatusDetails: noop,
      getJudgeStatusTcDataDetails: noop,
      setLoadingStatus: noop,
      doReJudge: noop,
      changeJudgeStatusFlag: noop,
      canReJudge: false,
      isManualJudge: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
      if (!isEmpty(nextProps.judgeDetailsTCDatas) && !prevState.tcHandle) {
        try {
          const handles = Object.keys(nextProps.judgeDetailsTCDatas);
          return {
            tcHandle: handles[0],
          };
        } catch (e) {
          return {
            tcHandle: '',
          };
        }
      }
      return null;
    }

    state = {
      mode: 'main',
      captureImg: '',
      tcHandle: '',
      diffArea1: 'userout',
      diffArea2: 'out',
      compareMode: 'userout-out',
      fetchingTestCases: false,
      isError: false,
      errorMessage: '',
    };

    componentDidMount() {
      this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
      this.props.getJudgeStatusDetails({
        ...this.props.apiPayloads,
        judgeStatusId: this.props.judgeStatusId,
        onComplete: () => {
          this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
        onError: (e) => {
          this.setState({
            isError: true,
            errorMessage: get(e, 'message', '获取评测详情信息失败'),
          });
        },
      });
    }

    CASES_COLUMNS = [
      {
        title: '#',
        dataIndex: 'index',
      },
      {
        title: '测试点',
        dataIndex: 'name',
      },
      {
        title: '结果',
        dataIndex: 'flag',
        align: 'center',
      },
      {
        title: '耗时',
        dataIndex: 'timeused',
        align: 'center',
      },
      {
        title: '内存',
        dataIndex: 'memused',
        align: 'center',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        align: 'center',
      }
    ];

    getCurrentDetailCase = (handle) => {
      const detailsCases = get(this.props.judgeDetails, 'details', []);
      return detailsCases.find(item => item.handle === handle) || {};
    };

    canShowDataTab = () => {
      const flag = get(this.props.judgeStatus, 'flag', -1);
      const AFlags = [
        JudgeFlagEnum.PE,
        JudgeFlagEnum.WA,
        JudgeFlagEnum.TLE,
        JudgeFlagEnum.OLE,
        JudgeFlagEnum.MLE,
        JudgeFlagEnum.RE,
        JudgeFlagEnum.SPJFIN,
        JudgeFlagEnum.SPJERR1,
        JudgeFlagEnum.SPJERR2
      ];
      return indexOf(AFlags, JudgeFlagEnum.enumValueOf(flag)) > -1;
    };

    handleDatasViewTabChange = (key) => {
      this.setState({
        tcHandle: key,
      });
    };

    // 切换diff的选项卡
    handleDiffTabChange = ({ key }) => {
      const keys = key.split('-');
      this.setState({
        diffArea1: get(keys, '0'),
        diffArea2: get(keys, '1'),
        compareMode: key,
      });
    };

    handleCaputreVisibleChange = (visiable) => {
      if (visiable) {
        this.setState({
          captureImg: '',
        }, () => {
          const dlg = document.getElementsByClassName('judge_detail_modal')[0];
          dlg.style.color = 'black';
          html2canvas(dlg).then((canvas) => {
            this.setState({
              captureImg: canvas.toDataURL(),
            });
          });
        });
      }
    };

    handleReJudgeClick = () => {
      this.props.doReJudge({
        ...this.props.apiPayloads,
        judgeStatusId: this.props.judgeStatusId,
      });
    };

    handleJudgeStatusChange = (key) => {
      this.props.editJudgeStatus({
        ...this.props.apiPayloads,
        judgeStatusId: this.props.judgeStatusId,
        flag: key,
        onSuccess: () => {

        },
      });
    };

    handleViewModeChange = key => () => {
      if (key === 'datas') {
        if (isEmpty(this.props.judgeDetailsTCDatas)) {
          this.setState({
            fetchingTestCases: true,
          }, () => {
            this.props.getJudgeStatusTcDataDetails({
              ...this.props.apiPayloads,
              judgeStatusId: this.props.judgeStatusId,
              onSuccess: () => {
                this.setState({
                  fetchingTestCases: false,
                });
              },
            });
          });
        }
      }
      this.setState({
        mode: key,
      });
    };

    handleViewModeBack = () => {
      this.setState({
        mode: 'main',
      });
    };

    renderJudgeFlag = (flag) => {
      const flagEnum = JudgeFlagEnum.enumValueOf(flag);
      return <Tag color={get(flagEnum, 'color')}>{get(flagEnum, 'desc', '未知')}</Tag>;
    };

    renderJudgeFlagTitle = (flag) => {
      const flagEnum = JudgeFlagEnum.enumValueOf(flag);
      return <span style={{ color: get(flagEnum, 'color') }}>{get(flagEnum, 'desc', '未知')}</span>;
    };

    renderManualJudgeFlagTitle = (flag, dflag) => {
      if (flag.toString() !== '20') return this.renderJudgeFlagTitle(flag);
      const flagEnum = JudgeFlagEnum.enumValueOf(dflag);
      return <>
        等待裁判确认 (<span style={{ color: get(flagEnum, 'color') }}>机判:{get(flagEnum, 'desc', '未知')})</span>
      </>;
    };

    renderNumbers = (num, unit = '') => `${((num >= 0 && num < 1) ? '< 1' : num)} ${unit}`;

    renderJudgeLog = (log, key) => {
      return <Timeline.Item
        key={`${log.timestamp}_${key}`}
        color={get(COLOR_MAPPING, log.level, 'grey')}
      >
        [{formatTime(log.timestamp, 'LONG_TIME_WITH_MS')}] {log.msg}
      </Timeline.Item>;
    };

    renderDiffArea = () => {
      const { tcHandle, compareMode } = this.state;
      const { judgeDetailsTCDatas } = this.props;
      if (isEmpty(judgeDetailsTCDatas)) return null;
      const details = get(judgeDetailsTCDatas, tcHandle);

      if (compareMode === 'error' || compareMode === 'log') {
        return <div className="detail_logs_text_area">
          {get(details, compareMode) || '无数据'}
        </div>;
      }
      return !isEmpty(details) ? <TextDiffWidget
        key={`${tcHandle}_${compareMode}`}
        baseText={get(details, this.state.diffArea1) || '数据为空，或者是因过期被清除'}
        nextText={get(details, this.state.diffArea2) || '数据为空，或者是因过期被清除'}
        disableDiff={compareMode !== 'userout-out'}
      /> : <Alert type="alert" message="测试数据不存在或者被屏蔽" />;
    };

    renderCaptureView = () => {
      const { captureImg } = this.state;
      return captureImg ? <div className="judge_status_detail_capture_view">
        <img src={captureImg} alt="" />
        <div className="desc-tip">截图成功！右键复制图像并分享吧</div>
      </div> : <Spin spinning tip="截图中" />;
    };

    renderLogsView = () => {
      const { judgeDetails } = this.props;
      const judgeLogs = get(judgeDetails, 'judge_logs', []);
      return <div className="detail_view_layout">
        <div className="judge_logs">
          {isEmpty(judgeLogs) ? <div className="timeline">无</div> : <Timeline className="timeline">
            {judgeLogs.map(this.renderJudgeLog)}
          </Timeline>}
        </div>
      </div>;
    };

    renderExtraButtons = () => {
      const { judgeStatus } = this.props;
      const flag = get(judgeStatus, 'flag', JudgeFlagEnum.UNKNOWN.value);
      const hideCode = flag === JudgeFlagEnum.CE.value || flag === JudgeFlagEnum.SE.value;
      const { mode } = this.state;
      const ret = [
        {
          key: 'logs',
          name: '日志',
          type: '',
          action: this.handleViewModeChange('logs'),
          confirm: false,
        }
      ];
      if (!hideCode) {
        ret.push({
          key: 'code',
          name: '代码',
          type: '',
          action: this.handleViewModeChange('code'),
          confirm: false,
        });
      }
      if (this.canShowDataTab()) {
        ret.push({
          key: 'datas',
          name: '数据',
          type: '',
          action: this.handleViewModeChange('datas'),
          confirm: false,
        });
      }
      if (this.props.canReJudge) {
        ret.unshift({
          key: 'rejudge',
          name: '重判',
          type: '',
          confirm: '你确定要执行重判吗？',
          action: this.handleReJudgeClick,
        });
      }
      ret[ret.length - 1].type = 'primary';
      return mode === 'main' ? ret.map((item) => {
        return item.confirm ? <Popconfirm
          key={item.key}
          title={item.confirm}
          onConfirm={item.action}
        >
          <Button key={item.key} type={item.type || 'default'}>
            {item.name}
          </Button>
        </Popconfirm> : <Button
          key={item.key}
          onClick={item.action}
          type={item.type || 'default'}
        >
          {item.name}
        </Button>;
      }) : undefined;
    };

    renderPageHeader = () => {
      const { mode } = this.state;
      const { judgeDetails, judgeStatus, testCases = [] } = this.props;
      testCases.sort((a, b) => get(a, 'order', 0) - get(b, 'order', 0));
      const judgeFlag = get(judgeStatus, 'flag', -3);
      const judgeFlagInfo = JudgeFlagEnum.enumValueOf(judgeFlag);
      const judgeFlagDesc = get(JUDGE_STATUS, `${judgeFlag}.desc`, '');
      const detailsCases = get(judgeDetails, 'details', []);
      let subTitle = null;
      if (this.props.isManualJudge) {
        subTitle = <Select
          defaultValue={get(judgeStatus, 'flag', JudgeFlagEnum.MANUAL.value)}
          onChange={this.handleJudgeStatusChange}
          style={{ width: '250px' }}
          size="small"
        >
          {enumMap(JudgeFlagEnum, item => (
            <Select.Option key={get(item, 'value')} value={get(item, 'value')}>{get(item, 'desc', '')}({get(item, 'abbr', '')})</Select.Option>
          ), item => indexOf(ENABLE_JUDGE_STATUS_FLAGS, item.value) > -1)}
        </Select>;
      } else {
        subTitle = <Tooltip title={judgeFlagDesc} placement="topLeft" arrowPointAtCenter overlayStyle={{ maxWidth: 520 }}>
          {get(judgeFlagInfo, 'message', '')}
        </Tooltip>;
      }
      return <PageHeader
        title={this.props.isManualJudge
          ? this.renderManualJudgeFlagTitle(judgeFlag, get(judgeDetails, 'exitcode', -3))
          : this.renderJudgeFlagTitle(judgeFlag)}
        subTitle={subTitle}
        onBack={mode !== 'main' ? this.handleViewModeBack : undefined}
        extra={this.renderExtraButtons()}
      >
        {mode === 'main' && <Row className="judge-statistic">
          <Statistic
            title="数据通过"
            value={`${detailsCases.filter(item => get(item, 'judge_result', -1) === JudgeFlagEnum.AC.value).length} / ${testCases.filter(item => get(item, 'available', false)).length}`}
          />
          <Statistic
            title="最长运行时间"
            value={this.renderNumbers(get(judgeDetails, 'timeused', -1), 'MS')}
          />
          <Statistic
            title="最大运行内存"
            value={this.renderNumbers(get(judgeDetails, 'memused', -1), 'KB')}
          />
          <Tooltip
            title={formatTime(get(judgeStatus, 'create_time', 0), 'LONG_DATETIME')}
          >
            <Statistic
              title="提交时间"
              value={formatTimeFromNow(get(judgeStatus, 'create_time', 0))}
            />
          </Tooltip>
        </Row>}
      </PageHeader>;
    };

    renderTestCasesTable = () => {
      const { testCases } = this.props;
      return testCases.map((tc, index) => {
        const currentDetailCase = this.getCurrentDetailCase(get(tc, 'handle'));
        const currectDetailFlag = get(currentDetailCase, 'judge_result', -3);
        const timeUsed = get(currentDetailCase, 'time_used', -1);
        const memUsed = get(currentDetailCase, 'memory_used', -1);
        return {
          key: get(tc, 'handle'),
          index: index + 1,
          name: <Tooltip title={get(tc, 'handle', '')}>{get(tc, 'name', `测试数据${get(tc, 'order', 0) + 1}`)}</Tooltip>,
          flag: this.renderJudgeFlag(currectDetailFlag),
          timeused: (timeUsed < 0 || currectDetailFlag === JudgeFlagEnum.TLE.value) ? '---' : `${this.renderNumbers(timeUsed)} MS`,
          memused: (memUsed < 0 || currectDetailFlag === JudgeFlagEnum.MLE.value) ? '---' : `${this.renderNumbers(memUsed)} KB`,
          operation: <Tag
            color="blue"
            onClick={() => {
              this.setState({
                tcHandle: get(tc, 'handle'),
              }, () => {
                this.handleViewModeChange('datas')();
              });
            }}
          >
            转到详情
          </Tag>,
        };
      });
    };

    renderMainView = () => {
      const { judgeStatus } = this.props;
      const flag = get(judgeStatus, 'flag', JudgeFlagEnum.UNKNOWN.value);
      const showEditor = flag === JudgeFlagEnum.CE.value || flag === JudgeFlagEnum.SE.value;
      if (showEditor) {
        return this.renderCodeView();
      }
      return <div className="detail_view_layout detail_main_view">
        <Table
          columns={this.CASES_COLUMNS}
          dataSource={this.renderTestCasesTable()}
          size="middle"
          pagination={false}
        />
      </div>;
    };

    renderCodeView = () => {
      const { judgeDetails, judgeStatus } = this.props;
      const ceinfo = get(judgeDetails, 'ceinfo', '');
      const seinfo = get(judgeDetails, 'seinfo', '');
      const codes = {};
      codes[get(judgeStatus, 'code_lang', '1')] = {
        default: get(judgeDetails, 'finally_code', ''),
      };
      return <div className="detail_view_layout detail_code_view">
        <CodeEditor
          readOnly
          footerControl={false}
          disableLanguageSelector
          languageMask={get(judgeStatus, 'code_lang', 1)}
          defaultValue={codes}
          extraInfo={(ceinfo || seinfo) ? <>
            {ceinfo && <>
              <h3>编译错误信息</h3>
              <pre>{ceinfo.toString()}</pre>
            </>}
            {seinfo && <>
              <h3>系统错误信息</h3>
              <pre>{isString(seinfo) ? seinfo : JSON.stringify(seinfo)}</pre>
            </>}
          </> : ''}
        />
      </div>;
    };

    renderDataView = () => {
      const { testCases = [] } = this.props;

      const currentDetailCase = this.getCurrentDetailCase(this.state.tcHandle);
      const currectDetailFlag = get(currentDetailCase, 'judge_result', -3);
      const currectDetailSignal = get(
        LINUX_SIGNAL_NUMBERS,
        get(currentDetailCase, 're_signum'),
        { name: get(currentDetailCase, 'signal'), desc: '未知信号' }
      );
      const isSpinning = isEmpty(this.props.judgeDetailsTCDatas) && this.state.fetchingTestCases;
      const canViewData = currectDetailFlag.toString() === '1' || currectDetailFlag.toString() === '4';
      const isAccepted = currectDetailFlag.toString() === '0';
      return (
        <div className="detail_view_layout detail_datas_view">
          {isSpinning ? <div style={{ textAlign: 'center' }}>
            <Spin spinning />
          </div> : <div className="datas_view">
            <div className="test_cases_data">
              <div className="test_details_panel">
                <Select
                  onSelect={this.handleDatasViewTabChange}
                  value={this.state.tcHandle}
                  mode="inline"
                  dropdownMatchSelectWidth={false}
                >
                  {testCases.map((item) => {
                    // 如果数据被后台屏蔽了，则直接跳过不显示
                    if (!get(item, 'available', false)) return null;
                    const cdc = this.getCurrentDetailCase(item.handle);
                    const flag = get(cdc, 'judge_result', '-9');
                    return <Select.Option
                      key={item.handle}
                      value={item.handle}
                      disabled={flag === '-9'}
                    >
                      {this.renderJudgeFlag(flag)} {item.name}
                    </Select.Option>;
                  })}
                </Select>
                <strong>运行时间：</strong>{this.renderNumbers(get(currentDetailCase, 'time_used', -1))} MS，
                <strong>运行内存：</strong>{this.renderNumbers(get(currentDetailCase, 'memory_used', -1))} KB
                {/*{get(currentDetailCase, 'judge_result') === JudgeFlagEnum.WA.value && <>*/}
                {/*  ，<strong>正确行数：</strong>{this.renderNumbers(get(currentDetailCase, 'same_lines', -1))} / {this.renderNumbers(get(currentDetailCase, 'total_lines', -1))}*/}
                {/*</>}*/}
                {(currectDetailFlag === JudgeFlagEnum.TLE.value
                  || currectDetailFlag === JudgeFlagEnum.MLE.value
                  || currectDetailFlag === JudgeFlagEnum.RE.value
                  || currectDetailFlag === JudgeFlagEnum.OLE.value)
                  && <Tooltip title={`RE信息：${get(currentDetailCase, 're_msg') || '无'}`}>
                    ，<strong>内核信号：</strong>
                    {currectDetailSignal.name} ({currectDetailSignal.desc})
                  </Tooltip>}
              </div>
              {!isEmpty(this.props.judgeDetailsTCDatas) && (canViewData) ? <>
                <div className="test_data_tab_panel">
                  <Menu
                    mode="horizontal"
                    onSelect={this.handleDiffTabChange}
                    selectedKeys={[this.state.compareMode]}
                  >
                    <Menu.Item key="userout-out">
                      【用户<SwapOutlined />答案】
                    </Menu.Item>
                    <Menu.Item key="in-out">
                      【输入<SwapRightOutlined />答案】
                    </Menu.Item>
                    <Menu.Item key="in-userout">
                      【输入<SwapRightOutlined />用户】
                    </Menu.Item>
                    <Menu.Item key="error">
                      错误信息（StdErr）
                    </Menu.Item>
                    <Menu.Item key="log">
                      特殊判题日志
                    </Menu.Item>
                  </Menu>
                </div>
                {this.renderDiffArea()}
              </> : <Result
                status={isAccepted ? 'success' : 'warning'}
                title={isAccepted ? '当前数据已通过' : '此状态不会记录数据'}
              />}
            </div>
          </div>}
        </div>
      );
    };

    renderDialogBody = () => {
      const { mode } = this.state;
      if (this.state.isError) {
        return <Result status="error" title={this.state.errorMessage} />;
      }
      return this.props.isLoading ? <div style={{ textAlign: 'center' }}>
        <Spin
          size="large"
          tip="加载中..."
          spinning={this.props.isLoading}
        />
      </div> : <div className="judge_detail_view">
        {this.renderPageHeader()}
        {mode === 'main' && this.renderMainView()}
        {mode === 'logs' && this.renderLogsView()}
        {mode === 'code' && this.renderCodeView()}
        {mode === 'datas' && this.renderDataView()}
      </div>;
    };

    render() {
      const { judgeStatus } = this.props;
      return (
        <Modal
          wrapClassName="judge_detail_modal"
          footer={null}
          title={<div className="dialog_title">
            <div>评测详情#{get(judgeStatus, 'id', 'Unknown')} (来自: #{get(judgeStatus, 'problem.number', '')}. {get(judgeStatus, 'problem.title', '')})</div>
            <Tooltip title="截图" placement="bottom">
              <Popover
                trigger="click"
                placement="leftTop"
                onVisibleChange={this.handleCaputreVisibleChange}
                content={this.renderCaptureView()}
              >
                <div className="capture_btn">
                  <CameraOutlined />
                </div>
              </Popover>
            </Tooltip>
          </div>}
          {...this.props}
        >
          {this.renderDialogBody()}
        </Modal>
      );
    }
  }
  return JudgeResultModal;
};
