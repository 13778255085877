import React from 'react';
import { noop, get } from 'lodash';
import PropTypes from 'prop-types';
import { Avatar, Card, Tooltip } from 'antd';
import classnames from 'classnames';
import { connect } from 'react-redux';
import CourseIconImage from 'images/course_icon.jpg';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { CourseSelectors } from 'scripts/common/logic/education/course/selector';
import { formatTimeFromNowPure, formatTimeToNowPure, diffTimeSeconds } from 'scripts/common/utils/time_formatter';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { CourseTypeEnum } from 'scripts/common/enums/course';
import { LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { buildAvatarPath, buildPath } from 'scripts/common/utils/location_helper';
import { AccountRoutes } from 'scripts/apps/routes';
import IconText from 'scripts/common/widgets/icon-text';
import { withRouter } from 'react-router';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountPropTypes } from 'scripts/common/prop-types/account';

const { Meta } = Card;

const DAYS14 = 14 * 24 * 60 * 60;

export const renderCourseStatus = (course, account, hasPerm) => {
  const st = get(course, 'start_time', 0);
  const et = get(course, 'end_time', 0);
  const dst = diffTimeSeconds(st);
  const det = diffTimeSeconds(et);

  if (dst < 0) {
    return <span className="course-before-start">{formatTimeFromNowPure(st)}后开始</span>;
  }
  if (det < 0) {
    let statusTip = '';
    let isDeadLine = false;
    if (get(account, 'role', 0) === AccountRoleEnum.STUDENT.value) {
      if (!hasPerm) {
        statusTip = '，未选课';
      } else {
        statusTip = det > -DAYS14  ? `，${formatTimeFromNowPure(et)}后过期` : '';
        isDeadLine = det > -DAYS14;
      }
    }
    return <span
      className={classnames({
        'course-learning': !isDeadLine && hasPerm,
        'course-deadline': isDeadLine,
        'course-pending': !hasPerm,
      })}
    >
      进行中{statusTip}
    </span>;
  }
  if (det >= 0) {
    return <span className="course-deadline">已过期{formatTimeToNowPure(et)}</span>;
  }
  return null;
};

const CourseCardView = (props) => {
  const authorId = get(props.course, 'author.id');
  const hasViewPermission = get(props.course, 'permissions_calculated.1', false);
  const courseType = CourseTypeEnum.enumValueOf(get(props.course, 'type'));
  const typeIcon = courseType === CourseTypeEnum.PUBLIC ? <UnlockOutlined /> : <LockOutlined />;
  const authorRole = get(props.course, 'author.role');
  const isTeacher = [
    AccountRoleEnum.TEACHER.value,
    AccountRoleEnum.DEAN.value
  ].indexOf(authorRole) > 0;
  const authorName = isTeacher ? `${get(props.course, 'author.realname')}老师` : get(props.course, 'author.nickname');
  const authorAvatar = get(props.course, 'author.avator');
  return props.course && (
    <div className="card_container">
      <Card
        key={get(props.course, 'id')}
        className="card"
        hoverable
        onClick={props.onClick}
        actions={[
          <IconText
            key="list-item-author"
            icon={<Avatar
              className="user-avator"
              icon={<UserOutlined />}
              size={16}
              src={buildAvatarPath(authorAvatar)}
            />}
            truncate={8}
            onClick={authorId ? (e) => {
              e.stopPropagation();
              e.preventDefault();
              const { history } = props;
              history.push(buildPath(AccountRoutes.SPACE.INDEX, { accountId: authorId }));
            } : null}
            text={authorName}
          />,
          <span>{get(props.course, 'student', 0)}人</span>
        ]}
      >
        <Meta
          avatar={<img alt="course" src={CourseIconImage} className="course-icon" />}
          title={<>
            <Tooltip title={get(props.course, 'name')}>
              {get(props.course, 'name')}
            </Tooltip>
            &nbsp;
            <Tooltip title={get(courseType, 'desc', '未知')}>{typeIcon}</Tooltip>
          </>}
          description={<RichtextContext
            textMode
            truncateLength={30}
            viewEmpty="暂无简介"
            content={get(props.course, 'description')}
          />}
        />
        {renderCourseStatus(props.course, props.account, hasViewPermission)}
      </Card>
    </div>
  );
};

CourseCardView.propTypes = {
  ...RoutePropTypes,
  course: CoursePropTypes,
  account: AccountPropTypes,
  onClick: PropTypes.func,
};

CourseCardView.defaultProps = {
  onClick: noop,
  account: null,
  course: null,
};

const mapStateToProps = (state, props) => {
  const { id } = props;
  const course = id ? CourseSelectors.getCourseEntity(state)(id) : null;
  return {
    course,
  };
};

export const CourseCard = connect(mapStateToProps, null)(
  withRouter(CourseCardView)
);
