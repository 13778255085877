import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined } from '@ant-design/icons';
import {
  Spin, List, Button, Tooltip 
} from 'antd';
import { get, noop } from 'lodash';
import { connect } from 'react-redux';
import { LessonSelectors, LessonActions } from 'scripts/common/logic/education/lesson';
import { humanbytes } from 'scripts/common/utils/unit';
import { WOPI_URL_MAPPING } from 'scripts/common/utils/office_url_helper';
import { openLink } from 'scripts/common/utils/location_helper';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state, props) => {
  const fsIds = get(props.lesson, 'resources', []);
  return {
    fsIds,
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  getLessonResourcesList: LessonActions.getLessonResourcesList,
  getLessonResources: LessonActions.getLessonResources,
}, dispatch);

const LessonResourcesList = (props) => {
  const {
    // onChangeOfficeResource,
    fsIds,
    getLessonResourcesList,
    courseId,
    lessonId,
    getLessonResources,
  } = props;

  const [isLoading, setIsLoading] =  useState(true);
  const [lessonList, setLessonList] = useState(null);
  useEffect(() => {
    getLessonResourcesList({
      courseId,
      lessonId,
      ids: fsIds,
      onSuccess: (result) => {
        setLessonList(result);
        setIsLoading(false);
      },
    });
  }, [isLoading]);

  const downloadResources = (item) => {
    getLessonResources({
      lessonId,
      courseId,
      id: item.id,
      onSuccess: (tokens) => {
        console.log(tokens);

        const pickcode = get(tokens, 'token.pick_code', '');
        const fileHash = get(tokens, 'token.file_hash', '');
        openLink(`${process.env.RESOURCE_SERVICE}/download/${fileHash}?pickcode=${pickcode}`, true);
      },
    });
  };

  if (isLoading) {
    return <div className="course_resources">
      <Spin className="drawer_loading" tip="载入中" />
    </div>;
  }
  const renderLessonResourcesData = () => {
    if (!lessonList) return [];
    return Object.keys(lessonList).map((keys) => {
      return {
        id: keys,
        ...lessonList[keys],
      };
    });
  };
  return (
    <div className="course_resources">
      <List
        bordered
        itemLayout="horizontal"
        dataSource={renderLessonResourcesData()}
        renderItem={(item) => {
          if (!item) return null;
          const name = get(item, 'name', '');
          // let type = item.name.split('.');
          // type = type[type.length - 1];
          // const canPreview = Object.prototype.hasOwnProperty.call(WOPI_URL_MAPPING, type);
          const size = get(item, 'size', 0);
          return (
            <List.Item
              actions={[
                <Tooltip title="下载">
                  <Button size="small" type="primary" onClick={() => downloadResources(item)}>
                    <DownloadOutlined />
                  </Button>
                </Tooltip>
              ]}
            >
              <List.Item.Meta
                title={name}
                description={`大小:${humanbytes(size)}`}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

LessonResourcesList.propTypes = {
  lesson: PropTypes.shape({}),
  lessonId: PropTypes.number,
  courseId: PropTypes.number,
  fsIds: PropTypes.shape([]),
  getLessonResourcesList: PropTypes.func,
  // onChangeOfficeResource: PropTypes.func,
  lessonResources: PropTypes.shape([]),
};

LessonResourcesList.defaultProps = {
  lesson: null,
  courseId: null,
  // onChangeOfficeResource: noop,
  lessonResources: null,
  lessonId: null,
  fsIds: [],
  getLessonResourcesList: noop,

};

export default connect(mapStateToProps, mapDispatchToProps)(LessonResourcesList);
