import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { noop } from 'lodash';
import { DeleteOutlined } from '@ant-design/icons';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import { showModal } from 'scripts/common/widgets/modal';
import { matchPath, withRouter } from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

import { CollectionViewsRoutes } from 'scripts/apps/routes';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import DelProblemModal from './del_problem_modal';

const mapDispatchToProps = dispatch => bindActionCreators({
  delProblems: CollectionProblemActions.saveDelProblems,
}, dispatch);

@withRouter
@connect(null, mapDispatchToProps)
class DelProblemIcon extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    problemId: PropTypes.string.isRequired,
    collectionId: PropTypes.string.isRequired,
    delProblems: PropTypes.func,
  };

  static defaultProps = {
    delProblems: noop,
  };

  state = {

  };

  showDelProblemModal = () => {
    const dialog = showModal(DelProblemModal, {
      onOk: () => {
        this.props.delProblems({
          problemId: this.props.problemId,
          collectionId: this.props.collectionId,
          onSuccess: () => {
            dialog.close();
            const { history } = this.props;
            history.push(buildPath(CollectionViewsRoutes.PROBLEMS_LIST, {
              collectionId: this.props.collectionId,
            }));
          },
        });
        return false;
      },
    });
  };

  render() {
    return (
      <a
        className="modify-problem-btn"
        onClick={this.showDelProblemModal}
      >
        <DeleteOutlined />
      </a>
    );
  }
}

export default DelProblemIcon;
