import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Input, Menu, Pagination, Layout
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import {
  get, pick, noop, isEqual 
} from 'lodash';
import { PaginationParams } from 'scripts/common/constants/global';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { AnnounceRoutes } from 'scripts/apps/routes';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import AnnounceList from 'scripts/apps/announcement/widgets/list_table';
import { AnnouncementLevelEnum } from 'scripts/common/enums/announcement';
import { AnnouncementActions, AnnouncementSelectors } from 'scripts/common/logic/announcement';

const { Search } = Input;

const LevelTypeItems = [
  AnnouncementLevelEnum.ALL,
  AnnouncementLevelEnum.NORMAL,
  AnnouncementLevelEnum.SECONDARY,
  AnnouncementLevelEnum.FIRST
];

const CommonPickup = ['limit', 'level', 'title'];

const AnnounceListView = (props) => {
  const [queryParams, setQueryParams] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const {
    getAnnouncementList,
    announcementList,
    paginationParams,
    levelTab,
  } = props;

  useEffect(() => {
    if (!isEqual(props.queryParams, queryParams)) {
      setQueryParams(props.queryParams);
    }
  }, [props.queryParams]);

  useEffect(() => {
    setLoading(true);
    getAnnouncementList({
      queryParams: pick(
        props.queryParams,
        ['limit', 'page', 'level', 'title']
      ),
      onComplete: () => {
        setLoading(false);
      },
    });
  }, [queryParams]);

  const handleSearch = (value) => {
    if (isEqual(value, get(queryParams, 'title'))) return;
    const { history, match } = props;
    history.push(buildPath(
      AnnounceRoutes.LIST,
      null,
      match.params,
      {
        ...pick(queryParams, CommonPickup),
        title: value,
      }
    ));
  };

  const handleLevelTabClick = ({ key }) => {
    const { history, match } = props;
    if (key === 'create') {
      history.push(buildPath(AnnounceRoutes.NEW));
      return;
    }
    history.push(buildPath(
      AnnounceRoutes.LIST,
      null,
      match.params,
      {
        ...pick(queryParams, CommonPickup),
        level: key === '0' ? '' : key,
      }
    ));
  };

  const handlePageChange = (page, pageSize) => {
    const { history, match } = props;
    history.push(buildPath(
      AnnounceRoutes.LIST,
      null,
      match.params,
      {
        ...pick(queryParams, CommonPickup),
        limit: pageSize,
        page,
      }
    ));
  };

  return (
    <Layout className="wj_general_view announce-list-view">
      <div className="list-header">
        <Search
          className="search"
          placeholder="搜索"
          enterButton
          onSearch={handleSearch}
          size="large"
        />
        <Menu
          mode="horizontal"
          selectedKeys={[levelTab.key]}
          onClick={handleLevelTabClick}
        >
          {LevelTypeItems.map(item => (
            <Menu.Item key={item.key}>
              {item.desc}
            </Menu.Item>
          ))}
          <Menu.Item key="create">
            <PlusOutlined />
            <strong>发布公告</strong>
          </Menu.Item>
        </Menu>
      </div>
      <div className="list-content">
        <div className="list-comp">
          <AnnounceList
            canManage={props.isAdmin}
            data={announcementList}
            loading={isLoading}
          />
        </div>
      </div>
      {get(paginationParams, 'total', 0) > 0 && <div className="list-footer">
        <Pagination
          current={get(paginationParams, 'page', 1)}
          pageSize={get(paginationParams, 'limit', 1)}
          total={get(paginationParams, 'total', 0)}
          showQuickJumper
          showSizeChanger
          pageSizeOptions={['10', '20', '40']}
          onChange={handlePageChange}
        />
      </div>}
    </Layout>
  );
};

AnnounceListView.propTypes = {
  ...RoutePropTypes,
  getAnnouncementList: PropTypes.func,
  queryParams: PropTypes.shape({}),
  announcementList: PropTypes.shape([]),
  paginationParams: PropTypes.shape({}),
  levelTab: PropTypes.instanceOf(AnnouncementLevelEnum).isRequired,
  isAdmin: PropTypes.bool,
};

AnnounceListView.defaultProps = {
  getAnnouncementList: noop,
  queryParams: {},
  paginationParams: {},
  announcementList: [],
  isAdmin: false,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getAnnouncementList: AnnouncementActions.getAnnouncementList,
}, dispatch);

const mapStateToProps = (state, props) => {
  const account = AccountSelectors.getLoginAccount(state);
  const queryset = getQuery(props.location);
  const pager = AnnouncementSelectors.announcementListViewPagerSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page', 'title', 'level']),
  };
  const levelTab = AnnouncementLevelEnum.enumValueOf(parseInt(get(queryset, 'level', 0), 10));
  const announcementList = AnnouncementSelectors.announcementListData(state);
  return {
    queryParams,
    paginationParams: pager || props.paginationParams,
    announcementList,
    levelTab,
    isAdmin: get(account, 'role', 0) === AccountRoleEnum.ADMINISTRATOR.value,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnnounceListView));
