import 'styles/contest/manager/del_contest.scss';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import ContestManagePermissionCheck from 'scripts/apps/contest/manage_permission';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import { bindActionCreators } from 'redux';
import { Alert, Button, Checkbox } from 'antd';
import { ContestActions } from 'scripts/common/logic/contest/contest/action';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { buildPath } from 'scripts/common/utils/location_helper';
import { WeJudgeRoutes } from 'scripts/apps/routes';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

const mapDispatchToProps = dispatch => bindActionCreators({
  delContest: ContestActions.delContest,
}, dispatch);

@ContestLoginRequire
@ContestManagePermissionCheck()
@withRouter
@withContestContext
@connect(null, mapDispatchToProps)
class DelContestView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    delContest: PropTypes.func,
    contest: ContestPropTypes.isRequired,
  };

  static defaultProps = {
    delContest: noop,
  };

  state = {
    processing: false,
    agree: false,
  };

  handleDeleteClick = () => {
    const { history } = this.props;
    this.props.delContest({
      contestId: this.props.contest.id,
      onComplete: () => {
        history.push(buildPath(WeJudgeRoutes.CONTEST_LIST));
      },
    });
  };

  renderDeleteAlertMessage = () => {
    return (<ul className="message_ul">
      <li>比赛信息将会被删除，正常情况下不予恢复。</li>
      <li>所有与该比赛关联的做题信息、统计、评测数据等都不会被删除。</li>
      <li>测试数据、运行数据等存放于服务器文件系统的内容不会被删除。</li>
      <li>当前操作仅能由管理员或比赛创建者执行。</li>
    </ul>);
  };

  render() {
    return (
      <div className="del-contest-view">
        <Alert
          message="你应该清楚这个操作的风险！"
          description={this.renderDeleteAlertMessage()}
          type="error"
          showIcon
        />
        <div className="del-control-bar">
          <Button
            type="danger"
            loading={this.state.processing}
            disabled={!this.state.agree}
            onClick={this.handleDeleteClick}
          >
            确认删除
          </Button>
          <Checkbox
            style={{ marginLeft: 8 }}
            disabled={this.state.agree}
            checked={this.state.agree}
            onChange={({ target }) => { this.setState({ agree: target.checked }); }}
          >我已阅读须知</Checkbox>
        </div>
      </div>
    );
  }
}

export default DelContestView;
