import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Menu, Spin, Typography } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import CourseNoticeList from 'scripts/apps/education/course/widgets/course_notice_list';
import CourseNoteList from 'scripts/apps/education/course/widgets/course_note_list';
import RichtextContext from 'scripts/common/widgets/richtext_context';

const mapDispatchToProps = dispatch => bindActionCreators({
  getCourseExtraInfo: CourseActions.getCourseExtraInfo,
}, dispatch);

@withRouter
@connect(null, mapDispatchToProps)
class CourseOverviewView extends React.PureComponent {
  static propTypes = {
    course: CoursePropTypes.isRequired,
    courseId: PropTypes.number.isRequired,
    hasManageCoursePermission: PropTypes.bool.isRequired,
    ...RoutePropTypes,

  };

  static defaultProps = {
  };

  state = {
    currentKey: 'notice',
    isLoading: false,
  };

  componentDidMount() {
    this.setState({         // eslint-disable-line
      isLoading: true,
    }, () => {
      this.props.getCourseExtraInfo({
        courseId: this.props.courseId,
        onComplete: () => {
          this.setState({
            isLoading: false,
          });
        },
      });
    });
  }

  SecondTabItems = [{
    key: 'notice',
    desc: '课程公告',
  }, {
    key: 'notebook',
    desc: '我的笔记',
  }];

  handleNavTabClick = ({ key }) => {
    this.setState({
      currentKey: key,
    });
  };

  renderSider = () => {
    switch (this.state.currentKey) {
      case 'notice': return <CourseNoticeList canManage={this.props.hasManageCoursePermission} courseId={this.props.courseId} />;
      case 'notebook': return <CourseNoteList canManage={this.props.hasManageCoursePermission} courseId={this.props.courseId} />;
      default: return <div />;
    }
  };

  render() {
    const { course } = this.props;
    const desc = get(course, 'description', '');
    return (
      <div className="course_view_body">
        <div className="course_view_left">
          <div className="course_overview">
            <div className="course_introduction">
              <div className="desc">
                <RichtextContext className="desc_html" content={desc} viewEmpty />
              </div>
              <div className="desc">
                <Typography.Title level={4}>教学大纲</Typography.Title>
                <RichtextContext className="desc_html" content={get(course, 'teach_plan', '')} viewEmpty />
              </div>
            </div>
          </div>
        </div>
        <div className="course_view_right">
          {this.state.isLoading ? <Spin tip="加载中" /> : <>
            <Menu
              onSelect={this.handleNavTabClick}
              selectedKeys={[this.state.currentKey]}
              mode="horizontal"
            >
              {this.SecondTabItems.map(item => (
                <Menu.Item key={item.key}>
                  {item.desc}
                </Menu.Item>
              ))}
            </Menu>
            <div className="sider_container">
              {this.renderSider()}
            </div>
          </>}
        </div>
      </div>);
  }
}

export default CourseOverviewView;
