import React from 'react';
import {
  Form, Input, DatePicker, Select, Cascader, Row, Col
} from 'antd';
import { CourseTypeEnum } from 'scripts/common/enums/course';
import PropTypes from 'prop-types';
import { get, noop, debounce } from 'lodash';
import { bindActionCreators } from 'redux';
import { CategoryActions } from 'scripts/common/logic/category/action';
import { CategorySelectors } from 'scripts/common/logic/category/selector';
import { CategoryPropTypes } from 'scripts/common/prop-types/category';
import { connect } from 'react-redux';
import { CommonCategoryTypeEnum } from 'scripts/common/enums/common';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

const { RangePicker } = DatePicker;
const { Option } = Select;

const mapDispatchToProps = dispatch => bindActionCreators({
  getCategoryTree: CategoryActions.getCategoryTree,
}, dispatch);

const mapStateToProps = (state) => {
  const categoryTree = CategorySelectors.categoryTreeViewSelector(
    CommonCategoryTypeEnum.COURSE.value
  )(state) || [];
  return {
    categoryTree,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class AddCourseEditor extends React.PureComponent {
  static propTypes= {
    handleChange: PropTypes.func,
    categoryTree: PropTypes.arrayOf(CategoryPropTypes),
  };

  static defaultProps = {
    handleChange: noop,
    categoryTree: [],
  };

  state = {
    name: '',
    description: '',
    category: [],
    type: CourseTypeEnum.NORMAL.value,
    start_time: 0,
    end_time: 0,
  };

  handleChange = debounce(v => this.props.handleChange(v), INPUT_CHANGE_DEBOUNDCE_TIME);

  handleInputChange = keyName => (e) => {
    if (keyName === 'type' || keyName === 'category') {
      this.setState({
        [keyName]: e,
      },  () => {
        this.handleChange(this.state);
      });
    } else if (keyName === 'time') {
      const start = e ? e[0].format('X') : 0;
      const end = e ? e[1].format('X') : 0;
      this.setState({
        start_time: parseFloat(start),
        end_time: parseFloat(end),
      }, () => {
        this.handleChange(this.state);
      });
    } else {
      const { target } = e;
      this.setState({
        [keyName]: target.value,
      }, () => {
        this.handleChange(this.state);
      });
    }
  };

  handleEditorChange = keyName => (text) => {
    this.setState({
      [keyName]: text,
    }, () => {
      this.handleChange(this.state);
    });
  };

  render() {
    const { state } = this;
    const courseDescription = get(state, 'description');
    const TypeTabItems = [ 
      CourseTypeEnum.PUBLIC,
      CourseTypeEnum.NORMAL
    ];

    return (
      <div className="add-course-editor-view">
        <Row>
          <Col span={24}>
            <Form.Item label="课程名称" required>
              <Input
                key="name"
                value={state.name}
                onChange={this.handleInputChange('name')}
                placeholder="课程名称"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="课程类型" required>
              <Select
                value={state.type}
                onChange={this.handleInputChange('type')}
              >
                {TypeTabItems.map(item => (
                  <Option key={item.key} value={item.value}>{item.desc}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="课程归类" required>
              <Cascader
                fieldNames={{ label: 'title', value: 'id', children: 'children' }}
                options={this.props.categoryTree}
                expandTrigger="hover"
                onChange={this.handleInputChange('category')}
                placeholder="请选择"
                value={state.category}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="开课时间">
              <RangePicker
                style={{ width: '100%' }}
                block
                format="YYYY-MM-DD HH:mm:ss"
                onChange={this.handleInputChange('time')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="课程简介" required />
        <MarkdownEditor
          height="15em"
          onChange={this.handleEditorChange('description')}
          defaultValue={courseDescription}
        />
      </div>
    );
  }
}

export default AddCourseEditor;
