import 'styles/widgets/cross_check.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Spin, Modal } from 'antd';
import { store } from 'scripts/common/logic/store';
import CodeCompare from 'scripts/common/widgets/code_compare.jsx';

class SimpleCrossCheckDetailDialog extends React.PureComponent {
  static propTypes = {
    detailSelector: PropTypes.func.isRequired,
    sourceLabel: PropTypes.node,
    targetLabel: PropTypes.node,
    listdata: PropTypes.arrayOf(PropTypes.shape({})),
    getDetail: PropTypes.func,
  };

  static defaultProps = {
    listdata: [],
    sourceLabel: null,
    targetLabel: null,
    getDetail: noop,
  };

  state = {
    detailData: null,
    isLoading: true,
  };

  componentDidMount() {
    this.props.getDetail(() => {
      this.setState({
        isLoading: false,
      });
      this.setState({
        detailData: this.props.detailSelector(store.getState()),
      });
    });
  }

  render() {
    const { detailData = {} } = this.state;
    return <Modal
      className="simple-cross-check-modal"
      title="代码比较"
      {...this.props}
      footer={null}
    >
      {this.state.isLoading
        ? <div style={{ textAlign: 'center' }}><Spin tip="正在载入详情..." /></div>
        : <div className="compare_area">
          <CodeCompare
            sourceLabel={this.props.sourceLabel}
            targetLabel={this.props.targetLabel}
            source={get(detailData, 'source', '')}
            target={get(detailData, 'target', '')}
            diff_lines={get(detailData, 'sim_lines', [])}
          />
        </div>}
    </Modal>;
  }
}

export default SimpleCrossCheckDetailDialog;
