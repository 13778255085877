import React from 'react';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import {
  Button, Table, Popconfirm, Tooltip, Avatar, Popover
} from 'antd';
import { get, isEmpty, indexOf } from 'lodash';
import { ContestAccountPropTypes } from 'scripts/common/prop-types/contest';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { isEmail } from 'scripts/common/utils/validator';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { buildAvatarPath } from 'scripts/common/utils/location_helper';
import ReactResizeDetector from 'react-resize-detector';
import { AntTableMiddleOutsideHeight } from 'scripts/common/constants/table';

class AccountManageListTable extends React.PureComponent {
  static propTypes = {
    contest: PropTypes.shape({}).isRequired,
    account: PropTypes.shape({}).isRequired,
    data: PropTypes.arrayOf(ContestAccountPropTypes).isRequired,
    paginationParams: PaginationPropTypes.isRequired,
    onPageChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onAccountClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onSelectedKeysChange: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    tableHeight: 0,
    selectedKeys: [],
  };

  COLUMNS = [{
    key: 'username',
    dataIndex: 'username',
    title: '登录名称',
  }, {
    key: 'nickname',
    dataIndex: 'nickname',
    title: '队伍名称',
    align: 'center',
  }, {
    key: 'realname',
    dataIndex: 'realname',
    title: '真实姓名',
    align: 'center',
    width: 140,
  }, {
    key: 'role',
    dataIndex: 'role',
    title: '角色',
    align: 'center',
    width: 80,
  }, {
    key: 'status',
    dataIndex: 'status',
    title: '状态',
    align: 'center',
    width: 80,
  }, {
    key: 'action',
    dataIndex: 'action',
    title: '操作',
    align: 'center',
    width: 140,
  }];

  handleSelect = (record, selected) => {
    const selectedKeys = JSON.parse(JSON.stringify(this.state.selectedKeys));
    const idx = indexOf(selectedKeys, record.key);
    if (selected) {
      if (idx > -1) return;
      selectedKeys.push(record.key);
    } else {
      if (idx < 0) return;
      selectedKeys.splice(idx, 1);
    }
    this.setState({ selectedKeys }, () => {
      this.props.onSelectedKeysChange(selectedKeys);
    });
  };

  handleSelectAll = (selected, selectedRows, changeRows) => {
    const selectedRowKeys = JSON.parse(JSON.stringify(this.state.selectedKeys));
    for (let i = 0; i < changeRows.length; i++) {
      const record = changeRows[i];
      const idx = indexOf(selectedRowKeys, record.key);
      if (selected) {
        if (idx > -1) return;
        selectedRowKeys.push(record.key);
      } else {
        if (idx < 0) return;
        if (selectedRowKeys.length === 1) {
          selectedRowKeys.shift();
        } else {
          selectedRowKeys.splice(idx, 1);
        }
      }
    }
    this.setState({ selectedKeys: selectedRowKeys }, () => {
      this.props.onSelectedKeysChange(selectedRowKeys);
    });
  };

  // handleTableRowSelect = (selectedRowKeys) => {
  //   this.setState({
  //     selectedKeys: selectedRowKeys,
  //   }, () => {
  //     this.props.onSelectedKeysChange(selectedRowKeys);
  //   });
  // };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  renderListData = () => {
    const accountFields = get(this.props.contest, 'configs.account_fields', {});
    if (this.props.data.length === 0) return [];

    return this.props.data.map((item) => {
      const isEnrolled = get(item, 'is_enrolled', false);
      const isPaid = get(item, 'is_paid', false);
      const enrollNeedPay = get(item, 'enroll_need_pay', false);
      const enrollStatusText = (isEnrolled && (!enrollNeedPay || (enrollNeedPay && isPaid))) ? '已报名' : (
        (!isEnrolled && enrollNeedPay && isPaid) ? <span style={{ color: 'red' }}>未支付</span> : <span style={{ color: 'red' }}>未报名</span>
      );
      const enrollTime = get(item, 'enroll_time', 0);
      const payTime = get(item, 'pay_time', 0);
      const clearPwd = get(item, 'clear_password', '');
      return {
        key: item.id,
        entity: item,
        username: <Popover
          title={item.username}
          trigger="hover"
          content={<div>
            {Object.keys(accountFields).map((key) => {
              const aitem = accountFields[key];
              const keyMap = { '教练名': 'coachname', '学校名': 'schoolname' }; // eslint-disable-line
              const relkey = keyMap[key] || `account_fields.${key}`;
              return <p key={key}>
                <strong>{get(aitem, 'placename') || get(aitem, 'name')}：</strong>
                {get(item, relkey, '无')}
              </p>;
            })}
            {enrollTime ? <p><strong>报名时间：</strong>{formatTime(enrollTime, 'LONG_DATETIME')}</p> : null}
            {payTime ? <p><strong>支付时间：</strong>{formatTime(payTime, 'LONG_DATETIME')}</p> : null}
            {clearPwd ? <p><strong>登录密码：</strong>{get(item, 'clear_password') || '空'}</p> : null}
          </div>}
        >
          <Avatar
            shape="circle"
            icon={<UserOutlined />}
            size={16}
            src={buildAvatarPath(get(item, 'avator'))}
            style={{ marginRight: 8 }}
          />
          {!isEmpty(item.wejudge_account) ? <i style={{ color: isEmail(item.username) ? '#cfcfcf' : '' }}>
            {item.username}
            <Tooltip title="通过WeJudge账户登录">
              <img src="/static/images/logo.png" width={12} height={12} alt="wejudge" style={{ marginLeft: 8 }} />
            </Tooltip>
          </i> : item.username}
        </Popover>,
        nickname: <span style={get(item, 'sex', 1) === 0 ? { color: 'red' } : {}}>
          {get(item, 'ignore_rank') ? '*' : ''}
          {get(item, 'banned_rank', false) ? <del>
            {get(item, 'nickname', '') || '未知'}
          </del> : get(item, 'nickname', '') || '未知'}
        </span>,
        realname: get(item, 'realname', '未知'),
        role: <>
          {get(ContestAccountRoleEnum.enumValueOf(item.role), 'desc', '未知')}
        </>,
        school_name: get(item, 'schoolname', '---'),
        status: <>
          {item.role === ContestAccountRoleEnum.COMPETITOR.value ? enrollStatusText : '---'}
        </>,
        action: <div className="list-item-actions">
          <Button
            type="primary"
            size="small"
            onClick={() => this.props.onAccountClick(item.id)}
          >
            编辑
          </Button>
          {get(this.props.contest, 'author_id') !== item.id && <Popconfirm
            title="你确定要删除这个账户吗？操作不可逆！"
            okType="danger"
            onConfirm={() => this.props.onDeleteClick(item.id)}
          >
            <Button type="danger" size="small">删除</Button>
          </Popconfirm>}
        </div>,
      };
    });
  };

  render() {
    const data = this.renderListData();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 40),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.props.onPageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    return (
      <div className="list-view">
        <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
        <Table
          size="middle"
          pagination={optPagination}
          dataSource={data}
          columns={this.COLUMNS}
          loading={this.props.isLoading}
          rowSelection={this.props.onSelectedKeysChange && {
            selectedRowKeys: this.state.selectedKeys,
            onSelect: this.handleSelect,
            onSelectAll: this.handleSelectAll,
            getCheckboxProps: record => ({
              disabled: get(record, 'entity.role') === ContestAccountRoleEnum.REFEREE.value,
            }),
          }}
          scroll={{
            y: this.state.tableHeight - AntTableMiddleOutsideHeight,
            scrollToFirstRowOnChange: true,
          }}
        />
      </div>
    );
  }
}

export default AccountManageListTable;
