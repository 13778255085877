import React from 'react';
import { Form, Modal, Input } from 'antd';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { CourseTypeEnum } from 'scripts/common/enums/course';
import { AccountPropTypes } from 'scripts/common/prop-types/account';

class CourseChoosingDialog extends React.PureComponent {
  static propTypes = {
    account: AccountPropTypes,
    course: CoursePropTypes.isRequired,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    account: null,
    onSubmit: noop,
  };

  state = {
    code: '',
    realname: get(this.props.account, 'realname', ''),
    course_code: '',
  };

  onOk = () => this.props.onSubmit({
    code: this.state.code,
    realname: this.state.realname,
    course_code: this.state.course_code,
  });

  handleInputChange = key => ({ target }) => {
    this.setState({
      [key]: target.value,
    });
  };

  render() {
    const { course } = this.props;
    const ctype = get(course, 'type', 0);
    let isDisableButton;
    if (ctype === CourseTypeEnum.NORMAL.value) {
      isDisableButton = !this.state.course_code || !this.state.code;
    } else {
      isDisableButton = !this.state.realname;
    }
    return <Modal
      {...this.props}
      title={ctype === CourseTypeEnum.PUBLIC.value ? '报名参与' : '学生选课'}
      onOk={this.onOk}
      okButtonProps={{
        disabled: isDisableButton,
      }}
    >
      {ctype === CourseTypeEnum.NORMAL.value && <Form.Item>
        <Input
          value={this.state.code}
          onChange={this.handleInputChange('code')}
          placeholder="学号"
        />
      </Form.Item>}
      {ctype === CourseTypeEnum.PUBLIC.value && <Form.Item>
        <Input
          value={this.state.realname}
          onChange={this.handleInputChange('realname')}
          placeholder="姓名"
        />
      </Form.Item>}
      {ctype === CourseTypeEnum.NORMAL.value && <Form.Item>
        <Input
          value={this.state.course_code}
          onChange={this.handleInputChange('course_code')}
          placeholder="选课代码，没有请留空"
        />
      </Form.Item>}
    </Modal>;
  }
}

export default CourseChoosingDialog;
