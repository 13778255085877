import React, { useState } from 'react';
import {
  Form, InputNumber, Switch, Select, Row, Col, Alert
} from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

// interface AccountExportModalPropsType {
//   changeRankFlag: () => void
// }

const AccountExportModal = (props) => {
  const [rankValue, setRankValue] = useState(30);
  const [crossValue, setCrossValue] = useState(80);
  const [rankFlag, setRankFlag] = useState(false);
  const [crossFlag, setCrossFlag] = useState(false);
  const [excludeProblems, setExcludeProblems] = useState([]);
  const renderLabel = () => {
    if (!crossFlag && !rankFlag) {
      return '导出全部名单';
    }
    const txt = [];
    if (rankFlag) {
      txt.push(`排行榜前 ${rankValue}%`);
    }
    if (crossFlag) {
      txt.push(`查重率高于 ${crossValue}%`);
    }
    return `导出${txt.join('且')}的名单`;
  };

  const handleProblemChange = (value) => {
    setExcludeProblems(value);

    props.changeExcludeProblems(value);
  };

  return (
    <>
      <Row>
        <Col span="8">
          <Form.Item label="最终排名">
            <Switch
              value={rankFlag}
              onChange={(e) => { setRankFlag(e); props.changeRankFlag(e); }}
            />
          </Form.Item>
        </Col>
        <Col span="16">
          <Form.Item>
            <InputNumber
              prefix="前"
              disabled={!rankFlag}
              min={0}
              max={100}
              defaultValue={100}
              formatter={_value => `${_value}%`}
              parser={_value => _value.replace('%', '')}
              value={rankValue}
              onChange={(e) => { setRankValue(e); props.changeRankValue(e); }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span="8">
          <Form.Item label="重复率">
            <Switch
              value={crossFlag}
              onChange={(e) => { setCrossFlag(e); props.changeCrossFlag(e); }}
            />
          </Form.Item>
        </Col>
        <Col span="16">
          <InputNumber
            prefix="高于"
            disabled={!crossFlag}
            min={0}
            max={100}
            defaultValue={100}
            formatter={_value => `${_value}%`}
            parser={_value => _value.replace('%', '')}
            value={crossValue}
            onChange={(e) => { setCrossValue(e); props.changeCrossValue(e); }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="查重忽略题目">
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="选择题目添加到白名单"
              value={excludeProblems}
              onChange={handleProblemChange}
            >
              {props.problemLists.map((item) => {
                return (
                  <Option key={item.id} value={item.id} label={item.problem.title}>
                    {item.problem.title}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Alert message={renderLabel()} type="info" />
    </>
  );
};

AccountExportModal.propTypes = {
  problemLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  changeCrossValue: PropTypes.func.isRequired,
  changeCrossFlag: PropTypes.func.isRequired,
  changeRankValue: PropTypes.func.isRequired,
  changeRankFlag: PropTypes.func.isRequired,
  changeExcludeProblems: PropTypes.func.isRequired,
};

export default AccountExportModal;
