import 'styles/collection/problem_choosing.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  get, remove, noop, pick, truncate 
} from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  CheckOutlined,
  DeleteOutlined,
  FileTextOutlined,
  LogoutOutlined,
  FileDoneOutlined
} from '@ant-design/icons';

import {
  Button, Table, Modal, InputNumber, Switch, Popover, message 
} from 'antd';
import localStorage from 'localStorage';
import DataKeys from 'scripts/common/constants/data_keys';
import jQuery from 'jquery';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AsgnRoutes, ContestRoutes } from 'scripts/apps/routes';
import { AsgnProblemActions } from 'scripts/common/logic/education/asgn/actions';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ContestProblemActions } from 'scripts/common/logic/contest/problem';

@withRouter
@connect(null, dispatch => bindActionCreators({
  chooseAsgnProblem: AsgnProblemActions.chooseAsgnProblem,
  chooseContestProblem: ContestProblemActions.chooseContestProblem,
}, dispatch))
class ProblemChoosingWidget extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    chooseAsgnProblem: PropTypes.func,
    chooseContestProblem: PropTypes.func,
  };

  static defaultProps = {
    chooseAsgnProblem: noop,
    chooseContestProblem: noop,
  };

  state = {
    options: {},
    visible: false,
  };

  componentDidMount() {
    this.loadFromLocalStorage();
    jQuery(window).on('ChooseProblem', this.loadFromLocalStorage);
  }

  componentDidUpdate(prevProps, prevState) {
    const module = get(this.state.options, 'module', '');
    const currentProblems = get(this.state.options, 'currentProblems', []);
    if (module) {
      if (currentProblems.length > 0 && get(prevState, 'options.currentProblems.length', 0) !== currentProblems.length) {
        // eslint-disable-next-line
        this.setState({
          visible: true,
        });
      }
    }
  }

  componentWillUnmount() {
    jQuery(window).off('ChooseProblem', this.loadFromLocalStorage);
  }

  loadFromLocalStorage = () => {
    let options = localStorage.getItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING) || '{}';
    try {
      options =        JSON.parse(options);
    } catch (e) {
      options = {};
    }
    this.setState({ options });  //eslint-disable-line
  };

  PROBLEM_LIST_COLUMN = [
    {
      title: '#',
      dataIndex: 'index',
      width: 40,
      align: 'center',
    },
    {
      title: '标题',
      dataIndex: 'title',
      width: 140,
    }
  ];

  ASGN_MODULE_COLUMN = [
    {
      title: '分值',
      dataIndex: 'score',
      width: 80,
      align: 'center',
    },
    {
      title: '必做',
      dataIndex: 'require',
      width: 60,
      align: 'center',
    }
  ];

  ACTION_COLUMN = {
    title: '操作',
    dataIndex: 'options',
    width: 60,
    align: 'center',
  };

  apiMapping = () => {
    const { options } = this.state;
    const module = get(options, 'module', '');
    if (module === 'asgn') {
      return this.props.chooseAsgnProblem;
    } if (module === 'contest') {
      return this.props.chooseContestProblem;
    }
    return noop;
  };

  backToModule = (options) => {
    const payloads = get(options, 'payloads', {});
    const module = get(options, 'module', '');
    if (module === 'asgn') {
      this.props.history.push(buildPath(AsgnRoutes.SUBITEMS, {
        ...payloads,
        subItemName: 'all_problems',
      }));
    } else if (module === 'contest') {
      this.props.history.push(buildPath(ContestRoutes.PROBLEM.LIST, {
        ...payloads,
      }));
    }
  };

  handleProblemChoosing = () => {
    const { options } = this.state;
    const callChooseAPI = this.apiMapping();
    const payloads = get(options, 'payloads', {});
    const module = get(options, 'module', '');
    const extraKw = (module === 'asgn') ?  ['require', 'score', 'difficulty'] : [];
    callChooseAPI({
      payloads,
      problems: get(options, 'currentProblems', []).map((item) => {
        return pick(item, ['id', 'collection_id', 'collection_item', ...extraKw]);
      }),
      onSuccess: (result) => {
        let suc = 0;
        let total = 0;
        Object.keys(result).forEach((key) => {
          suc += result[key] === 0 ? 1 : 0;
          total += 1;
        });
        const onOk = () => {
          localStorage.removeItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING);
          jQuery(window).trigger('ChooseProblem');
          this.backToModule({ ...options });
        };
        if (suc >= total) {
          message.success({
            content: '选题操作执行成功!',
            onClose: onOk,
          });
        } else {
          Modal.warning({
            title: '选题完成',
            content: `所选${total}道题目中有${total - suc}道因已存在或者其他问题，未能选择成功。点击确定退出选题模式。`,
            onOk,
          });
        }
      },
    });
  };

  handleExitChoosing = () => {
    Modal.confirm({
      title: '操作提示',
      content: '确认要退出选题模式吗？退出操作将清空当前的清单。',
      okType: 'warn',
      onOk: () => {
        localStorage.removeItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING);
        jQuery(window).trigger('ChooseProblem');
        this.backToModule(this.state.options);
      },
    });
  };

  handleRemoveProblem = item => () => {
    Modal.confirm({
      title: '操作提示',
      content: '确认要移除这个题目吗',
      okType: 'warn',
      onOk: () => {
        const { options } = this.state;
        const currentProblems = get(options, 'currentProblems', []);
        const currentPidMapping = get(options, 'currentPidMapping', {});
        currentPidMapping[item.id] = false;
        options.currentPidMapping =  currentPidMapping;
        options.currentProblems = remove([...currentProblems], t => t.id !== item.id);
        localStorage.setItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING, JSON.stringify(options));
        jQuery(window).trigger('ChooseProblem');
      },
    });
  };

  handleValueChange = (index, key) => (value) => {
    const { options } = this.state;
    const currentProblems = get(options, 'currentProblems', []);
    currentProblems.forEach((item, idx) => {
      if (idx === index) {
        currentProblems[idx][key] = value;
      }
    });
    localStorage.setItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING, JSON.stringify(options));
    jQuery(window).trigger('ChooseProblem');
  };

  handleClearChoosing = () => {
    Modal.confirm({
      title: '操作提示',
      content: '确认要清空选择吗',
      okType: 'warn',
      onOk: () => {
        const { options } = this.state;
        options.currentProblems = [];
        options.currentPidMapping = {};
        localStorage.setItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING, JSON.stringify(options));
        jQuery(window).trigger('ChooseProblem');
      },
    });
  };

  renderTable = () => {
    const { options } = this.state;
    const currentProblems = get(options, 'currentProblems', []);
    const module = get(options, 'module', '');
    return currentProblems.map((item, key) => {
      const extraOpt = (module === 'asgn') ? {
        require: <Switch
          checked={get(item, 'require', false)}
          onChange={this.handleValueChange(key, 'require')}
        />,
        score: <InputNumber
          min={0}
          max={200}
          value={get(item, 'score', 0)}
          onChange={this.handleValueChange(key, 'score')}
        />,
      } : {};
      return {
        key: item.id,
        index: key + 1,
        title: truncate(`${item.number}. ${item.title}`, { length: 20 }),
        options: <a onClick={this.handleRemoveProblem(item)}>移除</a>,
        ...extraOpt,
      };
    });
  };

  renderColumn = () => {
    const { options } = this.state;
    const module = get(options, 'module', '');
    if (module === 'asgn') {
      return [...this.PROBLEM_LIST_COLUMN, ...this.ASGN_MODULE_COLUMN, this.ACTION_COLUMN];
    }
    return [...this.PROBLEM_LIST_COLUMN, this.ACTION_COLUMN];
  };

  renderPopoverContent = () => {
    return (
      <div className="problem-choosing-bill">
        <div className="bill-content">
          <Table
            size="middle"
            dataSource={this.renderTable()}
            columns={this.renderColumn()}
            pagination={false}
          />
        </div>
        <div className="bill-footer">
          <div className="group">
            <Button
              type="primary"
              onClick={this.handleProblemChoosing}
            >
              <CheckOutlined /> 确认选择
            </Button>
            <Button
              type="danger"
              onClick={this.handleClearChoosing}
            >
              <DeleteOutlined /> 清空选择
            </Button>
          </div>
          <div className="group">
            <Button
              onClick={this.handleExitChoosing}
              type="default"
            >
              <LogoutOutlined /> 退出选题
            </Button>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { options } = this.state;
    const module = get(options, 'module', '');
    const currentProblems = get(options, 'currentProblems', []);
    if (module !== '') {
      return (
        <>
          <div id="problem-chossing-fly-icon" style={{ display: 'none' }}>
            <FileTextOutlined />
          </div>
          {/*<Drawer*/}
          {/*  title="选题清单"*/}
          {/*  width={600}*/}
          {/*  style={{ height: 'calc(100% - 55px)', padding: 0 }}*/}
          {/*  visible={this.state.visible}*/}
          {/*  onClose={() => { this.setState({ visible: false }); }}*/}
          {/*>*/}
          {/*  */}
          {/*</Drawer>*/}
          <Popover
            className="ant-popover-choosing"
            placement="topRight"
            // arrowPointAtCenter={false}
            visible={this.state.visible}
            content={this.renderPopoverContent()}
            trigger="click"
          >
            <div className="problem-choosing-btn" id="problem-choosing-btn" onClick={() => { this.setState({ visible: !this.state.visible }); }}>
              <FileDoneOutlined />
              <div className="tips">已选({currentProblems.length})题</div>
            </div>
          </Popover>
        </>
      );
    }
    return null;
  }
}

export default ProblemChoosingWidget;
