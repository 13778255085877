import React from 'react';
import { Player, LoadingSpinner } from 'video-react';
import '../../../../node_modules/video-react/dist/video-react.css'; // import css

class VideoDemo extends React.PureComponent {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ height: '600px', width: '800px', margin: '10px auto' }}>
          <Player
            playInline
            poster="/assets/poster.png"
            src="blob:https://www.bilibili.com/6e6a45d2-5a16-4e26-bcfa-37903e259893"

          ><LoadingSpinner /></Player>
        </div>
      </div>
    );
  }
}

export default VideoDemo;
