import React from 'react';
import PropTypes from 'prop-types';
import { List, Avatar } from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { get } from 'lodash';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import CourseIconImage from 'images/course_icon.jpg';
import { buildPath } from 'scripts/common/utils/location_helper';
import { CourseRoutes } from 'scripts/apps/routes';

class AccountSpaceCourseView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    // account: AccountPropTypes,
    courseList: PropTypes.arrayOf(CoursePropTypes),
  };

  static defaultProps = {
    // account: null,
    courseList: [],
  };

  state = {};

  gotoCourseView = (courseId) => {
    this.props.history.push(buildPath(CourseRoutes.CONTENT, { courseId }));
  };

  render() {
    return <div className="account-space-courses">
      <List
        dataSource={this.props.courseList}
        renderItem={item => (
          <List.Item
            key={get(item, 'id')}
          >
            <List.Item.Meta
              avatar={<Avatar src={CourseIconImage} />}
              title={<a onClick={() => { this.gotoCourseView(item.id); }}>{get(item, 'name', '')}</a>}
              description={<RichtextContext
                textMode
                truncateLength={30}
                viewEmpty="暂无简介"
                content={get(item, 'description')}
              />}
            />
            <div>{get(item, 'author.realname', '')}老师</div>
          </List.Item>
        )}
      />
    </div>;
  }
}

export default AccountSpaceCourseView;
