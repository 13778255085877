import React from 'react';
import PropTypes from 'prop-types';

import { PlusOutlined } from '@ant-design/icons';

import {
  Table,
  Button,
  Drawer,
  Modal,
  PageHeader,
  Descriptions,
  Typography,
  Divider
} from 'antd';
import { bindActionCreators } from 'redux';
import { get, noop, truncate } from 'lodash';
import { connect } from 'react-redux';
import { SchoolActions } from 'scripts/common/logic/education/school/action';
import { SchoolSelectors } from 'scripts/common/logic/education/school/selector';
import { SchoolPropTypes } from 'scripts/common/prop-types/school';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import SchoolEditor from 'scripts/apps/management/widgets/school_editor';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { AccountSelectors } from 'scripts/common/logic/account';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ManagementRoutes } from 'scripts/apps/routes';
import { humanbytes } from 'scripts/common/utils/unit';
import StudentManagementView from 'scripts/apps/management/school/student_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  getSchoolList: SchoolActions.getSchoolList,
  getSchoolEntity: SchoolActions.getSchoolEntity,
  deleteSchool: SchoolActions.deleteSchool,
}, dispatch);

const mapStateToProps = (state) => {
  const schoolList = SchoolSelectors.schoolListData(state);
  const currentAccount = AccountSelectors.getLoginAccount(state);
  const currentRole = get(currentAccount, 'role', 0);
  const currentAccountSchool = get(currentAccount, 'school.id', 0);
  const isAdmin = currentRole === AccountRoleEnum.ADMINISTRATOR.value;
  let school = null;
  if (!isAdmin && currentAccountSchool) {
    school = SchoolSelectors.getSchoolEntity(state)(currentAccountSchool);
  }
  return {
    school,
    isAdmin,
    schoolList,
    currentAccount,
    currentAccountSchool,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class SchoolsListView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.getSchoolList({
      onComplete: () => {},
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    school: SchoolPropTypes,
    getSchoolList: PropTypes.func,
    deleteSchool: PropTypes.func,
    getSchoolEntity: PropTypes.func,
    currentAccount: AccountPropTypes,
    isAdmin: PropTypes.bool,
    currentAccountSchool: PropTypes.number,
    schoolList: PropTypes.arrayOf(SchoolPropTypes),
  };

  static defaultProps = {
    school: null,
    isAdmin: false,
    deleteSchool: noop,
    getSchoolList: noop,
    getSchoolEntity: noop,
    currentAccount: {},
    schoolList: [],
    currentAccountSchool: 0,
  };

  state = {
    schoolId: 0,
    schoolEditVisible: false,
    hasFetchSchoolList: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchSchoolList) {
      if (nextProps.isAdmin) {
        SchoolsListView.fetchList(nextProps);
      } else {
        nextProps.getSchoolEntity({
          id: nextProps.currentAccountSchool,
        });
      }
      return {
        hasFetchSchoolList: true,
      };
    }
    return null;
  }

  SchoolsColumns=[{
    title: 'ID',
    dataIndex: 'id',
  }, {
    title: '学校',
    dataIndex: 'school',
  }, {
    title: '空间限制',
    dataIndex: 'repo_size',
  }, {
    title: '管理员',
    dataIndex: 'manager',
  }, {
    title: '操作',
    dataIndex: 'option',
    width: 160,
  }];

  handleSchoolClick = (id) => {
    this.setState({
      schoolId: id,
      schoolEditVisible: true,
    });
  };

  handleAddSchoolClick = () => {
    this.setState({
      schoolId: 0,
      schoolEditVisible: true,
    });
  };

  handleDeleteSchool = id => () => {
    Modal.confirm({
      okType: 'danger',
      title: '确认',
      content: '确认要删除这个学校信息吗？',
      onOk: () => {
        this.props.deleteSchool({
          schoolId: id,
          onSuccess: () => {
            SchoolsListView.fetchList(this.props);
          },
        });
      },
    });
  };

  handleDrawerClose = (refresh) => {
    this.setState({
      schoolEditVisible: false,
    });
    if (refresh) {
      SchoolsListView.fetchList(this.props);
    }
  };

  handleEditStudents = id => () => {
    const { history } = this.props;
    history.push(buildPath(
      ManagementRoutes.STUDENT_MANAGEMENT,
      {
        schoolId: id,
      }
    ));
  };

  renderSchoolList = () => {
    const schoolList = [...this.props.schoolList];

    return schoolList.map((item) => {
      if (!item) return null;
      return {
        key: item.id,
        id: get(item, 'id'),
        school: item.id === 1 ? get(item, 'name', '') : <a
          onClick={() => this.handleSchoolClick(item.id)}
        >
          {get(item, 'name', '')}
        </a>,
        repo_size: humanbytes(get(item, 'repository_size', 0)),
        manager: get(item, 'master_administrator') ? <>
          {get(item, 'master_administrator.nickname', '')} ({get(item, 'master_administrator.email', '')})
        </> : '无',
        option: <>
          <a onClick={this.handleEditStudents(item.id)}>
            编辑学生
          </a>
          {item.id === 1 ? null : <a
            style={{ marginLeft: 8 }}
            onClick={this.handleDeleteSchool(item.id)}
          >
            删除
          </a>}
        </>,
      };
    });
  };

  render() {
    const schoolDatas = this.renderSchoolList();
    const { school = null } = this.props;
    return (
      <div className="management-school-view">
        {this.props.isAdmin ? <>
          <div className="school-action-bar">
            <Button
              onClick={this.handleAddSchoolClick}
              type="primary"
            >
              <PlusOutlined /> 新增学校
            </Button>
          </div>
          <div className="school-list">
            <Table
              size="middle"
              dataSource={schoolDatas}
              columns={this.SchoolsColumns}
              // rowSelection={selectionSettings}
              pagination={false}
              loading={!this.state.hasFetchSchoolList}
            />
          </div>
        </> : <>
          <PageHeader
            title={get(school, 'name', '')}
          >
            <Descriptions column={2}>
              {get(school, 'master_administrator') ? <Descriptions.Item label="管理员">
                {get(school, 'master_administrator.nickname', '')}
                ({get(school, 'master_administrator.email', '')})
              </Descriptions.Item> : <Descriptions.Item label="管理员">无</Descriptions.Item> }
              <Descriptions.Item label="最大仓库容量">
                {humanbytes(get(school, 'repository_size'))}
              </Descriptions.Item>
            </Descriptions>
            {get(school, 'description', '') && <Typography.Paragraph>
              {truncate(get(school, 'description', ''), { length: 50 })}
            </Typography.Paragraph>}
          </PageHeader>
          <Divider style={{ margin: '0 4px' }} />
          <StudentManagementView hideBack />
        </>}
        <Drawer
          width={520}
          title={this.state.schoolId ? '编辑学校' : '新增学校'}
          placement="right"
          maskClosable={false}
          onClose={this.handleDrawerClose}
          visible={this.state.schoolEditVisible}
          getContainer={false}
        >
          <SchoolEditor
            onClose={this.handleDrawerClose}
            schoolId={this.state.schoolId}
          />
        </Drawer>
      </div>
    );
  }
}

export default SchoolsListView;
