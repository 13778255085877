import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { ContestRoutes } from 'scripts/apps/routes';
import RankListView from './rank_list';

const { Content } = Layout;

const RankApp = () => (
  <Content className="contest-layout-content contest-layout-content-wide">
    <Switch>
      <Route path={ContestRoutes.RANK.LIST} component={RankListView} />
    </Switch>
  </Content>
);

export default RankApp;
