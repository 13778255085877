import React from 'react';
import { TrophyOutlined } from '@ant-design/icons';
import {
  Tag, List, Avatar, Pagination 
} from 'antd';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { formatTime, nowTime } from 'scripts/common/utils/time_formatter';
import { ContestTypeEnum, RegisterTypeEnum, ScheduleTypeEnum } from 'scripts/common/enums/contest';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { PaginationParams } from 'scripts/common/constants/global';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

@withRouter
class ContestListTable extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    contestList: PropTypes.arrayOf(ContestPropTypes),
    onContestClick: PropTypes.func,
    onPinnedChange: PropTypes.func,
    onVisibleChange: PropTypes.func,
    onPageChange: PropTypes.func,
    queryParams: PropTypes.shape({}),
    paginationParams: PaginationPropTypes,
  };

  static defaultProps = {
    isAdmin: false,
    paginationParams: { ...PaginationParams },
    isLoading: false,
    contestList: [],
    onContestClick: noop,
    onPinnedChange: noop,
    onVisibleChange: noop,
    onPageChange: PropTypes.func,
    queryParams: {},
  };

  calcSecondTitle = (item) => {
    const timeNow = nowTime().unix();
    const enrollStartTime =  get(item, 'configs.enroll_start_time', 0);
    const enrollEndTime =  get(item, 'configs.enroll_end_time', 0);
    const contestStartTime =  get(item, 'start_time', Infinity);
    const contestEndTime =  get(item, 'end_time', Infinity);
    const isContestStarted = timeNow >= contestStartTime;
    const isContestEnded = timeNow >= contestEndTime;
    const teamType = get(item, 'team_type', 0);
    if (!isContestStarted) {
      if (timeNow >= enrollStartTime && timeNow <= enrollEndTime) {
        return <Tag color="#722ed1">{teamType === RegisterTypeEnum.SINGLE.value ? '报名' : '组队'}中</Tag>;
      } if (timeNow < enrollStartTime) {
        return <Tag color="#bfbfbf">{teamType === RegisterTypeEnum.SINGLE.value ? '报名' : '组队'}未开始</Tag>;
      }
      return <Tag color="#1890ff">即将开赛</Tag>;
    } if (isContestStarted && !isContestEnded) {
      return <Tag color="#52c41a">比赛中</Tag>;
    } if (isContestEnded) {
      return <Tag color="#f5222d">比赛已结束</Tag>;
    }
    return null;
  };

  handlePinnedSwitch = item => () => {
    this.props.onPinnedChange(item.id, !get(item, 'pinned', false));
  };

  handleVisibleSwitch = item => () => {
    this.props.onVisibleChange(item.id, !get(item, 'is_private', false));
  };



  renderListItems = (item) => {
    const teamType = get(item, 'team_type', 0);
    const contestType = get(item, 'contest_type', 0);
    const scheduleType = get(item, 'schedule_type', 0);
    const { isAdmin } = this.props;
    const description = [
      { title: '主办方', content: get(item, 'sponsor') || 'WeJudge官方' },
      { title: '参赛形式', content: get(RegisterTypeEnum.enumValueOf(teamType), 'desc', '未知') },
      { title: '比赛赛制', content: `${get(ContestTypeEnum.enumValueOf(contestType), 'desc', '未知')}(${get(ScheduleTypeEnum.enumValueOf(scheduleType), 'desc', '未知')})` }
    ];
    return (
      <List.Item
        key={item.id}
        actions={isAdmin ? [
          <a key="list-action-pin" onClick={this.handlePinnedSwitch(item)}>{get(item, 'pinned', true) ? '取消置顶' : '置顶'}</a>,
          <a
            key="list-action-private"
            onClick={this.handleVisibleSwitch(item)}
            style={{ color: get(item, 'is_private', true) ? '#52c41a' : '#f5222d' }}
          >{get(item, 'is_private', true) ? '设为公开' : '设为私有'}</a>
        ] : []}
      >
        <List.Item.Meta
          avatar={
            <Avatar
              shape="square"
              icon={<TrophyOutlined />}
              // src={buildAvatarPath(get(item, 'thumbs'))}
              style={{ backgroundColor: get(item, 'pinned', false) ? '#ffd666' : '#1890ff' }}
            />
          }
          title={<>
            <a onClick={this.props.onContestClick(item.id)}>{get(item, 'title', '')}</a>
            {get(item, 'is_private', false) && <Tag color="red">私密</Tag>}
            <span className="sub-title">({formatTime(get(item, 'start_time', 0), 'LONG_DATETIME')} ~ {formatTime(get(item, 'end_time', 0), 'LONG_DATETIME')})</span>
          </>}
          description={<div className="contenst-list-item-metas">
            {description.map((desc) => {
              return <div className="meta-item" key={desc.title}>
                <strong>{desc.title}：</strong>
                {desc.content}
              </div>;
            })}
          </div>}
        />
        <div className="contest-list-item-status">{this.calcSecondTitle(item)}</div>
      </List.Item>
    );
  };

  render() {
    const total = get(this.props.paginationParams, 'total', 0);
    return (
      <>
        <div className="contest-list-view">
          <List
            className="demo-loadmore-list"
            loading={this.props.isLoading}
            itemLayout="horizontal"
            dataSource={this.props.contestList}
            renderItem={this.renderListItems}
          />
        </div>
        {total ? <div className="contest-list-footer">
          <Pagination
            current={get(this.props.paginationParams, 'page', 1)}
            pageSize={get(this.props.paginationParams, 'limit', 1)}
            showQuickJumper
            showSizeChanger
            pageSizeOptions={['12', '20', '40']}
            onChange={this.props.onPageChange}
            total={total}
          />
        </div> : null}
      </>
    );
  }
}

export default ContestListTable;
