import React, { useEffect } from 'react';
import { Button, PageHeader, Modal, Tag } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import moment from 'moment';
import {
  get, noop
} from 'lodash';
import { LevelTag } from 'scripts/apps/announcement/widgets/list_table';
import { AnnouncementSelectors, AnnouncementActions } from 'scripts/common/logic/announcement';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AnnounceRoutes } from 'scripts/apps/routes';
import { AccountSelectors } from 'scripts/common/logic/account';
import { AccountRoleEnum } from 'scripts/common/enums/account';

const AnnouncementView = (props) => {
  const { announcement, getAnnouncement } = props;
  useEffect(() => {
    getAnnouncement({
      id: props.announcementId,
    });
  }, []);

  const handleBack = () => {
    const { history } = props;
    history.push(buildPath(AnnounceRoutes.LIST));
  };

  const handleEdit = () => {
    const { history } = props;
    history.push(buildPath(AnnounceRoutes.BODY_EDIT, { aid: props.announcementId }));
  };

  const handleDelete = () => {
    Modal.confirm({
      title: '删除公告确认',
      content: '确认要删除这篇公告吗？删除后不可恢复',
      okType: 'danger',
      onOk: () => {
        props.delAnnouncement({
          announcementId: props.announcementId,
          onSuccess: () => {
            handleBack();
          },
        });
      },
    });
  };

  const extraButtons = props.isAdmin ? [
    <Button type="primary" onClick={handleEdit}>编辑</Button>,
    <Button type="danger" onClick={handleDelete}>删除</Button>
  ] : [];

  return (
    <div className="announcement-view">
      <PageHeader
        onBack={handleBack}
        title={get(announcement, 'title', '')}
        subTitle={<>
          <LevelTag level={get(announcement, 'level', 1)} />
          {get(announcement, 'is_expired', false) && <Tag color="red">已过期</Tag>}
        </>}
        extra={extraButtons}
      >
        <span className="status-text">
          {get(announcement, 'author.nickname', '')} 发布于 {moment(get(announcement, 'update_time', 0) * 1000).format('YYYY-MM-DD HH:mm')}
        </span>
      </PageHeader>
      <RichtextContext className="ann-content" content={get(announcement, 'content', '')} viewEmpty />
    </div>
  );
};

AnnouncementView.propTypes = {
  isAdmin: PropTypes.bool,
  announcement: PropTypes.shape({}),
  announcementId: PropTypes.string,
  getAnnouncement: PropTypes.func,
  delAnnouncement: PropTypes.func,
  ...RoutePropTypes,
};

AnnouncementView.defaultProps = {
  isAdmin: false,
  announcementId: '',
  announcement: {},
  getAnnouncement: noop,
  delAnnouncement: noop,
};

const mapStateToProps = (state, props) => {
  const { match } = props;
  const account = AccountSelectors.getLoginAccount(state);
  const announcementId = get(match.params, 'aid', 0);
  const announcement = AnnouncementSelectors.getAnnouncementEntity(state)(
    parseInt(announcementId, 10)
  );
  return {
    announcement,
    announcementId,
    isAdmin: get(account, 'role', 0) === AccountRoleEnum.ADMINISTRATOR.value,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getAnnouncement: AnnouncementActions.getAnnouncement,
  delAnnouncement: AnnouncementActions.delAnnouncement,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnnouncementView));
