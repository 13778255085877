import 'styles/collection/answer/index.scss';
import 'styles/collection/answer/tags_container.scss';
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import List from './solutions_list';

class Answer extends PureComponent {
  static propTypes = {
    showDetails: PropTypes.func,
  };

  static defaultProps = {
    showDetails: noop,
  };

  render() {
    return (
      <>
        <div className="problem_answer_containter">
          <List {...this.props} showDetails={this.props.showDetails} />
        </div>
      </>
    );
  }
}
export default Answer;
