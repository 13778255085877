import 'styles/problem/widgets/problem_edit.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import {
  Button, Modal, Spin, Table, Tag 
} from 'antd';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { JudgeFlagEnum } from 'scripts/common/enums/judge';

@connect(null, dispatch => bindActionCreators({
  getTestCasesMakerHistory: CollectionProblemActions.getTestCasesMakerHistory,
}, dispatch))
class TcMakerHistoryDialog extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes.isRequired,
    collectionId: PropTypes.string.isRequired,
    getTestCasesMakerHistory: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    isLoading: true,
    histories: [],
  };

  componentDidMount() {
    this.fetchHistory();
  }

  fetchHistory = () => {
    this.setState({
      isLoading: true,
    }, () => {
      this.props.getTestCasesMakerHistory({
        collectionId: this.props.collectionId,
        problemId: this.props.problem.id,
        onSuccess: (result) => {
          this.setState({
            isLoading: false,
            histories: get(result, 'status', []),
          });
        },
      });
    });
  };

  COLUMNS= [{
    title: '任务ID',
    align: 'center',
    dataIndex: 'id',
  }, {
    title: '任务状态',
    align: 'center',
    dataIndex: 'status',
  }, {
    title: '写入状态',
    align: 'center',
    dataIndex: 'write_status',
  }, {
    title: '创建时间',
    align: 'center',
    dataIndex: 'create_time',
  }];

  renderJudgeFlag = (flag) => {
    const flagEnum = JudgeFlagEnum.enumValueOf(flag);
    return <Tag color={get(flagEnum, 'color')}>{flag === 0 ? '运行完成' : get(flagEnum, 'desc', '未知')}</Tag>;
  };

  renderWriteStatus = (flag, writed) => {
    if (flag === 0) {
      return <Tag color={writed ? '#52c41a' : '#f5222d'}>{writed ? '写入成功' : '写入失败'}</Tag>;
    }
    return <Tag color="#8c8c8c">等待执行</Tag>;
  };

  renderListData = () => {
    return this.state.histories.map((item) => {
      return {
        key: get(item, 'id', ''),
        id: <div>#{get(item, 'id', '')}</div>,
        status: this.renderJudgeFlag(get(item, 'flag', -3)),
        write_status: this.renderWriteStatus(get(item, 'flag', -3), get(item, 'writed', false)),
        create_time: formatTime(get(item, 'create_time', 0), 'LONG_DATETIME'),
        exe_time: get(item, 'exe_time', -1),
        exe_mem: get(item, 'exe_mem', ''),
        se_info: get(item, 'se_info', -1),
      };
    });
  };

  renderRowExpanded = (record) => {
    return <p style={{ margin: 0 }}>
      运行用时：{record.exe_time} MS；
      运行最高内存：{record.exe_mem} KB；
      {record.se_info ? <p>
        <strong>运行错误内容</strong>
        <pre>{record.se_info}</pre>
      </p> : ''}
    </p>;
  };

  render() {
    return (<Modal
      width={720}
      wrapClassName="test-cases-maker-history-dialog"
      {...this.props}
      footer={null}
      title="测试数据生成器历史"
    >
      {(this.state.isLoading) ? <div style={{ textAlign: 'center' }}>
        <Spin tip="加载中..." />
      </div> : <div>
        <Button
          type="primary"
          block
          size="small"
          onClick={() => this.fetchHistory()}
        >
          刷新
        </Button>
        <Table
          bordered
          size="middle"
          dataSource={this.renderListData()}
          pagination={false}
          columns={this.COLUMNS}
          expandedRowRender={this.renderRowExpanded}
        />
      </div>}
    </Modal>);
  }
}

export default TcMakerHistoryDialog;
