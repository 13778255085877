import React from 'react';
import PropTypes from 'prop-types';
import { KeyOutlined, MailOutlined, WechatOutlined } from '@ant-design/icons';
import { List, Modal } from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { get, noop, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { WeChatActions } from 'scripts/common/logic/wechat';
import { showModal } from 'scripts/common/widgets/modal';
import WeChatConnectDialog from 'scripts/apps/account/space/widgets/wechat_dialog';
import { AccountActions } from 'scripts/common/logic/account';
import PasswordDialog from './widgets/pwd_dialog';

@connect(null, dispatch => bindActionCreators({
  doEmailVerification: AccountActions.doEmailVerification,
  getWechatQrconnectInfo: WeChatActions.getWechatQrconnectInfo,
  deleteWechatQrconnect: WeChatActions.deleteWechatQrconnect,
}, dispatch))
class AccountSpaceSafetyView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    account: AccountPropTypes,
    // courseList: PropTypes.arrayOf(CoursePropTypes),
    getWechatQrconnectInfo: PropTypes.func,
    doEmailVerification: PropTypes.func,
    deleteWechatQrconnect: PropTypes.func,
  };

  static defaultProps = {
    account: null,
    // courseList: [],
    getWechatQrconnectInfo: noop,
    doEmailVerification: noop,
    deleteWechatQrconnect: noop,
  };

  state = {
    isWxBind: false,
    wxUserInfo: {},
  };

  componentDidMount() {
    this.props.getWechatQrconnectInfo({
      onSuccess: (ui) => {
        this.setState({
          isWxBind: true,
          wxUserInfo: ui,
        });
      },
    });
  }

  handleEmailVerification = () => {
    const {
      protocol,
      hostname,
      port,
    } = window.location;
    this.props.doEmailVerification({
      host: `${protocol}//${hostname}${port ? `:${port}` : ''}`,
      onSuccess: () => {
        Modal.info({
          okType: 'primary',
          okText: '确认',
          content: '已发送一封认证邮件至您的邮箱，请注意查收。',
          title: '邮箱认证',
        });
      },
    });
  };

  handleUnbindWeixin = () => {
    Modal.confirm({
      title: '解除微信绑定',
      okType: 'danger',
      message: '解除绑定后，将无法使用微信扫码登录。你可以绑定新的微信用户以便使用扫码登录功能。',
      onOk: () => {
        this.props.deleteWechatQrconnect({
          onSuccess: () => {
            this.setState({
              isWxBind: false,
              wxUserInfo: {},
            });
          },
        });
      },
      cancelText: '我再想想',
      okText: '解除绑定',
    });
  };

  handleShowEditPwdDialog = key => () => {
    showModal(PasswordDialog, {
      mode: key,
      account: this.props.account,
    });
  };

  handleSowWeChatConnectDialog = () => {
    showModal(WeChatConnectDialog, {
      account: this.props.account,
      onOk: (dg, state) => {
        const ui = get(state, 'wxUserInfo', {});
        this.setState({
          isWxBind: !isEmpty(ui),
          wxUserInfo: ui,
        });
        return false;
      },
    });
  };

  render() {
    const email = get(this.props.account, 'email');
    const { isWxBind, wxUserInfo } = this.state;
    const isEmailValidated = get(this.props.account, 'email_validated', false);
    return (
      <div className="account-space-safety">
        <List>
          <List.Item
            actions={[
              ...(isEmailValidated ? [] : [<a key="validate" onClick={this.handleEmailVerification}>去验证</a>]),
              <a key="edit" onClick={this.handleShowEditPwdDialog('email')}>修改</a>
            ]}
          >
            <List.Item.Meta
              avatar={<MailOutlined style={{ fontSize: '32px' }} />}
              title="登录邮箱"
              description={email ? `当前绑定邮箱：${email}` : '请先绑定邮箱'}
            />
          </List.Item>
          <List.Item
            actions={[<a key="edit" onClick={this.handleShowEditPwdDialog('password')}>修改密码</a>]}
          >
            <List.Item.Meta
              avatar={<KeyOutlined style={{ fontSize: '32px' }} />}
              title="密码保护"
              description="请妥善保管你的密码，并不定期修改，确保账号安全"
            />
          </List.Item>
          <List.Item
            actions={isWxBind ? [
              <a key="wechat_unbind" onClick={this.handleUnbindWeixin}>解除绑定</a>,
              <a key="wechat_info" onClick={this.handleSowWeChatConnectDialog}>查看绑定</a>
            ] : [<a key="wechat_bind" onClick={this.handleSowWeChatConnectDialog}>立即绑定</a>]}
          >
            <List.Item.Meta
              avatar={<WechatOutlined style={{ fontSize: '32px' }} />}
              title="微信登录"
              description={!isWxBind ? '绑定微信号即可直接扫码登录' : `当前绑定微信号：${get(wxUserInfo, 'nickname')}`}
            />
          </List.Item>
        </List>
      </div>
    );
  }
}

export default AccountSpaceSafetyView;
