import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Table, Button, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { CourseActions, CourseSelectors } from 'scripts/common/logic/education/course';
import { openLink, buildAvatarPath, buildResourcePath } from 'scripts/common/utils/location_helper';
import { bindActionCreators } from 'redux';

const mapStateToProps = (state) => {
  return {
    tasks: CourseSelectors.courseExportCodesTaskSelector(state),
  };
};

const StatusMapping = {
  0: <><LoadingOutlined /> 处理中</>,
  1: '完成',
  2: '失败',
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getExportCodesTasks: CourseActions.getExportCodesTasks,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
class CourseExportCodesTask extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.shape({})),
    getExportCodesTasks: PropTypes.func,
  };

  static defaultProps = {
    tasks: [],
    getExportCodesTasks: noop,
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.tasks) {
      const hasProcessing = nextProps.tasks.find(item => get(item, 'status') === 0);
      return {
        hasProcessing: !!hasProcessing,
      };
    }
    return null;
  }

  state = {
    loading: false,
    hasProcessing: false,
  };

  componentDidMount() {
    this.fetchList();
    this.autoRefresh.bind(this);
    this.refreshWorker = setTimeout(() => {
      this.autoRefresh();
    }, 30000);
  }

  componentWillUnmount() {
    if (this.refreshWorker) {
      clearTimeout(this.refreshWorker);
      this.refreshWorker = null;
    }
  }

  refreshWorker = null;

  autoRefresh = () => {
    // 如果有正在处理的，则启用自动刷新(30秒)
    if (this.state.hasProcessing) {
      this.fetchList();
      this.refreshWorker = setTimeout(() => {
        this.autoRefresh();
      }, 30000);
    } else {
      this.refreshWorker = null;
    }
  };

  fetchList = () => {
    this.setState({
      loading: true,
    }, () => {
      this.props.getExportCodesTasks({
        courseId: this.props.courseId,
        onSuccess: () => {
          this.setState({
            loading: false,
          });
        },
      });
    });
  };

  COLUMNS= [{
    title: '任务ID',
    align: 'center',
    dataIndex: 'id',
  }, {
    title: '任务状态',
    align: 'center',
    dataIndex: 'status',
  }, {
    title: '创建时间',
    align: 'center',
    dataIndex: 'create_time',
  }, {
    title: '完成时间',
    align: 'center',
    dataIndex: 'finished_time',
  }, {
    title: '操作',
    align: 'center',
    dataIndex: 'operation',
  }];

  handleDownloadFile = vpath => () => {
    const url = buildResourcePath(vpath);
    return openLink(url);
  };

  renderListData = () => {
    return this.props.tasks.map((item) => {
      const ftime = get(item, 'finished_time', 0);
      const status = get(item, 'status', 0);
      return {
        key: get(item, 'id', ''),
        id: <div>#{get(item, 'id', '')}</div>,
        create_time: formatTime(get(item, 'create_time', 0), 'LONG_DATETIME'),
        finished_time: ftime ? formatTime(ftime, 'LONG_DATETIME') : '---',
        status: get(StatusMapping, status, '未知'),
        operation: (() => {
          if (status === 1) {
            return <Button type="primary" onClick={this.handleDownloadFile(get(item, 'file', ''))}>下载</Button>;
          }
          return <Button type="default" disabled>下载</Button>;
        })(),
      };
    });
  };

  render() {
    if (this.state.loading) {
      return <div style={{ textAlign: 'center' }}>
        <Spin tip="加载中..." />
      </div>;
    }
    return <Table
      bordered
      size="middle"
      dataSource={this.renderListData()}
      pagination={false}
      columns={this.COLUMNS}
    />;
  }
}

export default CourseExportCodesTask;
