import React from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { showModal } from 'scripts/common/widgets/modal';
import connect from 'react-redux/es/connect/connect';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';
import { AddAsgnModal } from './dialog/asgn_info';

const AddAsgnButtonBase = (props) => {
  const showAddAsgnModal = () => {
    const modalBox = showModal(AddAsgnModal, {
      width: 500,
      onOk: (modal, state) => {
        props.addAsgn({
          ...state,
          courseId: props.courseId,
          onSuccess: (id) => {
            props.onAddAsgnSuccess(id);
            modalBox.close();
          },
        });
        return false;
      },
    });
  };
  return (
    <Button
      className="add_asgn_button"
      type="primary"
      onClick={showAddAsgnModal}
    >
      <PlusOutlined />发布作业
    </Button>
  );
};

AddAsgnButtonBase.propTypes = {
  courseId: PropTypes.number.isRequired,
  addAsgn: PropTypes.func,
  onAddAsgnSuccess: PropTypes.func,
};

AddAsgnButtonBase.defaultProps = {
  addAsgn: noop,
  onAddAsgnSuccess: noop,
};

const mapDispatchToProps = dispatch => bindActionCreators({
  addAsgn: AsgnActions.addAsgn,
}, dispatch);

export const AddAsgnButton = connect(null, mapDispatchToProps)(AddAsgnButtonBase);
