import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Table, Button, Tag, Space, Popover
} from 'antd';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {
  get, isEqual, noop, pick
} from 'lodash';
import { ContestProblemPropTypes } from 'scripts/common/prop-types/contest';
import { buildAntdTableDNDComponent } from 'scripts/apps/widgets/common/antd_table_dnd';
import { indexToChar } from 'scripts/common/utils/unit';
import ContestProblemSettings from 'scripts/apps/contest/problem/widgets/problem_setting';
import { ContestProblemActions } from 'scripts/common/logic/contest/problem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ProblemListTableForUser } from 'scripts/apps/contest/problem/widgets/ptable_for_user';

const mapDispatchToProps = dispatch => bindActionCreators({
  delContestProblem: ContestProblemActions.delContestProblem,
  doProblemRejudge: ContestProblemActions.doProblemRejudge,
  editContestProblem: ContestProblemActions.editContestProblem,
}, dispatch);

@connect(null, mapDispatchToProps, null, { forwardRef: true })
class ProblemsListTable extends React.PureComponent {
  static propTypes = {
    contestId: PropTypes.number.isRequired,
    isManage: PropTypes.bool,
    problemDatas: PropTypes.arrayOf(ContestProblemPropTypes),
    onSortProblem: PropTypes.func,
    isLoading: PropTypes.bool,
    doProblemRejudge: PropTypes.func,
    delContestProblem: PropTypes.func,
    onRefreshList: PropTypes.func,
    onProblemClick: PropTypes.func.isRequired,
    editContestProblem: PropTypes.func,
    completeList: PropTypes.shape({}),
  };

  static defaultProps = {
    isLoading: false,
    isManage: false,
    problemDatas: [],
    onSortProblem: noop,
    doProblemRejudge: noop,
    delContestProblem: noop,
    onRefreshList: noop,
    editContestProblem: noop,
    completeList: {},
  };

  state = {
    data: this.props.problemDatas,
    rawData: this.props.problemDatas,
    editingData: {},
    saving: {},
    isDraged: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.problemDatas, prevState.rawData)) {
      const editingData = {};
      nextProps.problemDatas.forEach((pd) => {
        editingData[pd.id] = pick(pd, ['manual_judge', 'code_language', 'simple_cross_check', 'cross_check_threshold', 'ignore_pe']);
      });
      return {
        data: nextProps.problemDatas,
        rawData: nextProps.problemDatas,
        editingData,
      };
    }
    return null;
  }

  components = buildAntdTableDNDComponent();

  NORMAL_COLUMNS = [{
    title: '题号',
    width: 80,
    dataIndex: 'number',
    key: 'number',
    align: 'center',
  }, {
    title: '题目',
    key: 'title',
    dataIndex: 'title',
  },
  {
    title: '正确率',
    width: 200,
    dataIndex: 'correct_ratio',
    ket: 'correct_ratio',
    align: 'center',
  },
  ...(this.props.isManage ? [
    {
      title: '管理',
      key: 'manage',
      dataIndex: 'manage',
      width: 200,
      align: 'center',
    }
  ] : [
    {
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      width: 80,
      align: 'center',
    }
  ])
  ];

  handleMoveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];
    this.setState(update(this.state, {
      data: {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
      },
    }));
    this.props.onSortProblem(this.state.data.map((item) => {
      return get(item, 'id', 0);
    }));
  };

  handleProblemDelClick = problem => () => {
    Modal.confirm({
      content: '确定要移除该道题目吗',
      cancelText: '取消',
      okText: '确认',
      title: '移除题目',
      okType: 'danger',
      onOk: () => {
        this.props.delContestProblem({
          contestId: this.props.contestId,
          problemId: problem.id,
          onSuccess: () => {
            this.props.onRefreshList();
          },
        });
        return false;
      },
    });
  };

  handleRejudgeClick = cproblem => () => {
    Modal.confirm({
      content: '确定要重判该道题目吗',
      cancelText: '取消',
      okText: '确认',
      title: '重判题目',
      onOk: () => {
        this.props.doProblemRejudge({
          contestId: this.props.contestId,
          problemId: cproblem.id,
          onSuccess: () => {},
        });
        return false;
      },
    });
  };

  handleSaveProblemSettings = cpid => (entity) => new Promise((resolve) => {
    this.setState({
      saving: {
        ...this.state.saving,
        [cpid]: true,
      },
    }, () => {
      this.props.editContestProblem({
        contestId: this.props.contestId,
        problemId: cpid,
        payloads: entity,
        onComplete: () => {
          resolve();
          this.setState({
            saving: {
              ...this.state.saving,
              [cpid]: false,
            },
          });
        },
      });
    });
  })

  handleResetProblemOrder = () => {
    const { data } = this.state;
    const sData = JSON.parse(JSON.stringify(data));
    this.setState({
      data: sData.sort((a, b) => {
        return a.order < b.order ? -1 : 1;
      }),
    });
  };

  rednerManagePopover = (cproblem) => {
    return <div className="contest_problem_manage_popover">
      <ContestProblemSettings
        contestId={this.props.contestId}
        contestProblem={cproblem}
        onSaveSetting={this.handleSaveProblemSettings(cproblem.id)}
      />
    </div>;
  };

  renderTableData = () => {
    const getRatio = (a, b) => {
      return (b === 0) ? 0 : (((a * 1.0) / b) * 100.0).toFixed(2); // eslint-disable-line
    };
    return this.state.data.map((cproblem) => {
      const problem = get(cproblem, 'problem', {});
      const cproblemId = get(cproblem, 'id', null);
      const problemStatus = get(this.props.completeList, cproblemId, {});
      const problemHasPass = get(problemStatus, 'ac', 0) > 0;
      const problemStatusText = `${get(problemStatus, 'ac', 0)}/${get(problemStatus, 'sub', 0)}`;
      const number = indexToChar(get(cproblem, 'order', 0));
      return {
        id: get(cproblem, 'id'),
        key: get(problem, 'id'),
        number: <Tag color={get(cproblem, 'color', 'grey')}>{number}</Tag>,
        color: get(cproblem, 'color', 'grey'),
        passed: problemHasPass,
        status: this.props.isManage ? '---' : <Tag color={problemHasPass ? '#52c41a' :  '#bfbfbf'}>{problemHasPass ? '已完成' : '待完成'}({problemStatusText})</Tag>,
        title: <span>
          <a onClick={() => this.props.onProblemClick(cproblem)}>{get(problem, 'title', '')}</a>
          {get(cproblem, 'manual_judge', false) && <Tag color="#69c0ff" style={{ marginLeft: 8 }}>人工评测</Tag>}
        </span>,
        correct_ratio: `${getRatio(get(cproblem, 'accepted', 0), get(cproblem, 'submission', 0))}% (${get(cproblem, 'accepted', 0)} / ${get(cproblem, 'submission', 0)})`,
        entity: cproblem,
        manage: <Space>
          <Popover content={this.rednerManagePopover(cproblem)} title={`管理题目${number} - ${get(problem, 'title', '')}`} placement="topRight" trigger="click">
            <Button loading={this.state.saving[cproblem.id]} type="primary">管理</Button>
          </Popover>
          <Button disabled={this.state.saving[cproblem.id]} type="default" onClick={this.handleRejudgeClick(cproblem)}>重判</Button>
          <Button disabled={this.state.saving[cproblem.id]} type="danger" onClick={this.handleProblemDelClick(cproblem)}>移除</Button>
        </Space>,
      };
    });
  };

  render() {
    return (this.props.isManage ? <DndProvider backend={HTML5Backend}>
      <Table
        loading={this.props.isLoading}
        className="problem_list_view"
        size="middle"
        dataSource={this.renderTableData()}
        columns={this.NORMAL_COLUMNS}
        pagination={false}
        onRow={(record, index) => ({
          index,
          moveRow: this.handleMoveRow,
        })}
        components={this.components}
        // expandedRowRender={this.renderExpandedRow}
      />
    </DndProvider> : <ProblemListTableForUser
      loading={this.props.isLoading}
      dataSource={this.renderTableData()}
      pagination={false}
    />);
  }
}
export default ProblemsListTable;
