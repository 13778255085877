import React from 'react';
import PropTypes from 'prop-types';

import { DownOutlined, UserAddOutlined } from '@ant-design/icons';

import {
  Select, Dropdown, Menu, Table, Button, Input, Tag 
} from 'antd';
import { bindActionCreators } from 'redux';
import {
  get, pick, isEqual, noop 
} from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { AccountActions } from 'scripts/common/logic/account/action';
import { PaginationParams } from 'scripts/common/constants/global';
import { AccountSelectors } from 'scripts/common/logic/account/selector';
import { showModal } from 'scripts/common/widgets/modal';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';

import { AccountModal } from './widgets/account_modal';
import PasswordModal from './widgets/password_modal';
import { ManagementRoutes } from '../routes';
import { importTeacherExcel } from '../../common/utils/xlsx_importer';

const mapDispatchToProps = dispatch => bindActionCreators({
  getAccountList: AccountActions.getAccountList,
  changePasswordAdmin: AccountActions.changePasswordAdmin,
  addAccount: AccountActions.addAccount,
  createAccount: AccountActions.createAccount,
  loginShadow: AccountActions.loginShadow,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const pager = AccountSelectors.accountListViewPagerSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page', 'keyword', 'role']),
  };
  const currentRoutePath = ManagementRoutes.ACCOUNT_MANAGEMENT;
  const accountList = AccountSelectors.accountListData(state);
  const currentAccount = AccountSelectors.getLoginAccount(state);
  return {
    queryParams,
    paginationParams: pager || props.paginationParams,
    accountList,
    currentRoutePath,
    currentAccount,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class AccountManagementView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.getAccountList({
      query: {
        ...pick(nextProps.queryParams, ['limit', 'page', 'keyword', 'role']),
      },
      onComplete: () => {},
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    getAccountList: PropTypes.func,
    accountList: PropTypes.arrayOf(AccountPropTypes),
    changePasswordAdmin: PropTypes.func,
    paginationParams: PaginationPropTypes,
    currentAccount: AccountPropTypes,
    createAccount: PropTypes.func,
    loginShadow: PropTypes.func,
  };

  static defaultProps = {
    getAccountList: noop,
    accountList: [],
    changePasswordAdmin: noop,
    paginationParams: { ...PaginationParams },
    currentAccount: {},
    createAccount: noop,
    loginShadow: noop,
  };

  state= {
    // accounts: [],
    // accountSelectedKeys: [],
    queryParams: {},
    searchKeyword: get(this.props.queryParams, 'keyword', ''),
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams }  = nextProps;
      AccountManagementView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  AccountsColumns=[{
    title: '登录账户',
    dataIndex: 'email',
  }, {
    title: '账户昵称',
    dataIndex: 'nickname',
  }, {
    title: '真实姓名',
    dataIndex: 'realname',
  }, {
    title: '性别',
    dataIndex: 'sex',
    align: 'center',
    width: 60,
  }, {
    title: '用户身份',
    dataIndex: 'role',
    align: 'center',
    width: 120,
  }, {
    title: '操作',
    dataIndex: 'action',
    width: 80,
  }];

  inputRef = null;

  handleAddAccount = () => {
    showModal(AccountModal, {
      mode: 'add',
      onAccountInfoChange: () => {
        AccountManagementView.fetchList(this.props);
      },
    });
  };

  handleXlsxChange = (e) => {
    const getResult = (list, password) => {
      this.props.createAccount({
        email_list: list,
        password,
        onSuccess: (result) => {
          AccountManagementView.fetchList(this.props);
          this.setState({
            queryParams: {},
          });
          if (result) {
            this.props.viewSuccessMessage({
              message: '添加成功',
            });
          }
          //TODO 抽象一个通知
        },
      });
    };
    importTeacherExcel(e.target.files, (data, password) => getResult(data, password));
  };

  handleXlsxUpload = () => {
    this.inputRef.click();
  };

  handleEditAccount = (item) => {
    showModal(AccountModal, {
      account: item,
      mode: 'edit',
      onAccountInfoChange: () => {
        AccountManagementView.fetchList(this.props);
      },
    });
  };

  handleEditPermission = (item) => {
    showModal(AccountModal, {
      account: item,
      mode: 'permission',
      onAccountInfoChange: () => {
        AccountManagementView.fetchList(this.props);
      },
    });
  };

  handleResetPassword = (item) => {
    showModal(PasswordModal, {
      onOk: (modal, state) => {
        this.props.changePasswordAdmin({
          accountId: item.id,
          newpassword: state.password,
          onSuccess: () => {
            AccountManagementView.fetchList(this.props);
          },
        });
      },
    });
  };

  handleShadowLogin = (item) => {
    const accountId = get(item, 'id');
    this.props.loginShadow({
      accountId,
      onSuccess: () => {
        // 直接执行跳转
        window.location.href = '/';
      },
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'role', 'keyword']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleAccountSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      null,
      match.params,
      value === '' ? {
        ...pick(this.state.queryParams, ['limit', 'role']),
      } : {
        ...pick(this.state.queryParams, ['limit', 'role']),
        keyword: value,
      }
    ));
  };

  handleRoleChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      null,
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'keyword']),
        role: value,
      }
    ));
  };

  renderRoleFlag = (role) => {
    const roleEnum = AccountRoleEnum.enumValueOf(role);
    return <Tag color={roleEnum.color}>{roleEnum.desc}</Tag>;
  };

  renderActionBtn = (item) => {
    const currentRole = get(this.props.currentAccount, 'role', 0);
    const userRole = get(item, 'role', 0);
    const isAdmin = currentRole === AccountRoleEnum.ADMINISTRATOR.value;
    const isAdminUser = userRole === AccountRoleEnum.ADMINISTRATOR.value;
    return (
      <Dropdown
        overlay={
          <Menu
            onClick={({ key }) => {
              switch (key) {
                case 'shadow':
                  this.handleShadowLogin(item);
                  break;
                case 'profile':
                  this.handleEditAccount(item);
                  break;
                case 'permission':
                  this.handleEditPermission(item);
                  break;
                case 'passwd':
                  this.handleResetPassword(item);
                  break;
                default:
                  break;
              }
            }}
          >
            <Menu.Item key="profile">资料设置</Menu.Item>
            {(isAdmin && !isAdminUser) && <Menu.Item key="permission">权限设置</Menu.Item>}
            <Menu.Item key="passwd">重设密码</Menu.Item>
            {isAdmin && <Menu.Divider />}
            {isAdmin && <Menu.Item key="shadow">登录影子</Menu.Item>}
          </Menu>
}
      >
        <Button>
          更多操作 <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  renderAccountList = () => {
    const accountList = [...this.props.accountList];
    return accountList.map((item) => {
      if (!item) return null;
      return {
        key: item.id,
        id: '',
        role: this.renderRoleFlag(get(item, 'role', 0)),
        email: get(item, 'email', ''),
        realname: get(item, 'realname', ''),
        nickname: get(item, 'nickname', ''),
        sex: get(item, 'sex', '') === 1 ? '男' : '女',
        action: this.renderActionBtn(item),
      };
    });
  };

  render() {
    const accountDatas = this.renderAccountList();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 1),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.handlePageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };

    return (
      <div className="management-account-view">
        <div className="account-action-bar">
          <div className="left-sider">
            {/*<input
              type="file"
              name="excel-file"
              onChange={this.handleXlsxChange}
              style={{ display: 'none' }}
              ref={node => this.inputRef = node}
            />*/}
            {/*<Button type="primary" onClick={this.handleXlsxUpload} disabled>*/}
            {/*  <Icon type="file-excel" /> 导入教师(开发中)*/}
            {/*</Button>*/}
          </div>
          <div className="right-sider">
            <Button type="primary" onClick={this.handleAddAccount}>
              <UserAddOutlined /> 创建新用户
            </Button>
          </div>
        </div>
        <div className="account-filter-bar">
          <Input.Search
            placeholder="按关键词搜索"
            style={{ width: 200 }}
            value={this.state.searchKeyword}
            onChange={e => this.setState({ searchKeyword: e.target.value })}
            onSearch={this.handleAccountSearch}
          />
          <Select
            style={{ marginLeft: 8 }}
            dropdownMatchSelectWidth={false}
            value={parseInt(get(this.props.queryParams, 'role', -1), 10)}
            onChange={this.handleRoleChange}
          >
            <Select.Option
              key={-1}
              value={-1}
            >
              全部
            </Select.Option>
            {enumMap(AccountRoleEnum, (item) => {
              return <Select.Option
                key={item.value}
                value={item.value}
              >
                {item.desc}
              </Select.Option>;
            }, item => item.value !== AccountRoleEnum.ASSISTANT.value)}
          </Select>
        </div>
        <div className="account-list">
          <Table
            bordered
            size="middle"
            dataSource={accountDatas}
            columns={this.AccountsColumns}
            pagination={optPagination}
          />
        </div>
      </div>
    );
  }
}

export default AccountManagementView;
