import 'styles/collection/management.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  Button, Alert, Checkbox, Tabs 
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { CollectionActions } from 'scripts/common/logic/collection';
import { CollectionRoutes } from 'scripts/apps/routes';
import { CollectionPropTypes } from 'scripts/common/prop-types/collection';
import CollectionInfoEditor from 'scripts/apps/collection/widgets/collection_editor';
import ClassifyEditor from './classify/classify_editor';

const { TabPane } = Tabs;

const mapDispatchToProps = dispatch => bindActionCreators({
  delCollections: CollectionActions.deleteCollection,
  editCollections: CollectionActions.editCollection,
}, dispatch);

@withRouter
@connect(null, mapDispatchToProps)
class CollectionManagement extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    collection: CollectionPropTypes.isRequired,
    delCollections: PropTypes.func,
    editCollections: PropTypes.func,
  };

  static defaultProps = {
    delCollections: noop,
    editCollections: noop,
  };

  state = {
    processing: false,
    agree: false,
    collection: this.props.collection,
  };

  handleDeleteClick = () => {
    this.setState({
      processing: true,
    }, () => {
      const { delCollections, collection, history } = this.props;
      delCollections({
        id: get(collection, 'id'),
        onSuccess: () => {
          history.push(buildPath(CollectionRoutes.LIST));
        },
        onComplete: () => {
          this.setState({
            processing: false,
          });
        },
      });
    });
  };

  renderDeleteAlertMessage = () => {
    return (<ul className="message_ul">
      <li>题目关联信息将会被永久删除，无法恢复。题目原始信息将保留到【回收站】中</li>
      <li>所有与该题目关联的做题信息、统计、评测数据等都将会被连带删除。</li>
      <li>测试数据、运行数据等存放于服务器文件系统的内容将不会被删除。</li>
      <li>当前操作仅能由管理员或题库创建者执行。</li>
    </ul>);
  };

  render() {
    return (
      <>
        <Tabs defaultActiveKey="info" className="collection_management_view">
          <TabPane
            className="collection-content-body"
            tab={[<EditOutlined key="info_icon" />, '编辑信息']}
            key="info"
          >
            <CollectionInfoEditor
              mode="edit"
              collection={this.state.collection}
              editCollections={this.props.editCollections}
            />
          </TabPane>
          <TabPane tab={[<ShareAltOutlined key="classify_icon" />, '题目分类']} key="classify">
            <ClassifyEditor
              collectionId={this.props.collection.id}
            />
          </TabPane>
          <TabPane
            className="collection-content-body"
            tab={[<DeleteOutlined key="delete_icon" />, '删除题库']}
            key="delete"
          >
            <>
              <Alert
                message="你应该清楚这个操作的风险！"
                description={this.renderDeleteAlertMessage()}
                type="error"
                iconType="info-circle-o"
                showIcon
              />
              <div className="del-control-bar">
                <Button
                  type="danger"
                  loading={this.state.processing}
                  disabled={!this.state.agree}
                  onClick={this.handleDeleteClick}
                >
                  确认删除
                </Button>
                <Checkbox
                  style={{ marginLeft: 8 }}
                  disabled={this.state.agree}
                  checked={this.state.agree}
                  onChange={({ target }) => { this.setState({ agree: target.checked }); }}
                >我已阅读须知</Checkbox>
              </div>
            </>
          </TabPane>
        </Tabs>

      </>
    );
  }
}

export default CollectionManagement;
