import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, noop } from 'lodash';
import ChoosingProblem from 'scripts/apps/problem/widgets/choice_problem';
import TofProblem from 'scripts/apps/problem/widgets/tof_problem';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';

import { ProblemItemPropTypes } from 'scripts/common/prop-types/problem';

class ObjectiveProblemView extends React.PureComponent {
  static propTypes = {
    isShowOk: PropTypes.bool,
    problemMode: PropTypes.string,
    problemNumber: PropTypes.string,
    problemItem: ProblemItemPropTypes.isRequired,
    problemModeChange: PropTypes.func,
    onSelectChanged: PropTypes.func,
    onOk: PropTypes.func,
    solution: PropTypes.shape({}),
  };

  static defaultProps = {
    isShowOk: true,
    problemMode: '',
    problemNumber: '',
    onOk: null,
    problemModeChange: noop,
    onSelectChanged: noop,
    solution: {},
  };

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.problemMode) {
      return {
        problemMode: nextProps.problemMode,
      };
    }
    return null;
  }

  state = {
    problemMode: this.props.problemMode || 'solving',
  };

  changeView = (name = 'solving') => {
    this.setState({ problemMode: name }, () => { this.props.problemModeChange(name); });
  };

  defaultOnOk = () => { this.changeView('view_answers'); };

  renderSingleChoosingProblem = () => {
    const { problemItem } = this.props;
    if (isEmpty(problemItem)) return null;
    const { problem } = problemItem;
    if (isEmpty(problem)) return null;

    const options = get(problem, 'options', {});
    const chooseOptions = get(options, 'options', []);
    const correctAnswers = get(options, 'correct_answer');
    const userAnswer = get(this.props.solution, 'answer', null);
    return (<ChoosingProblem
      key={get(problemItem, 'problem.id', '')}
      mode={this.state.problemMode}
      problem={problem}
      problemItem={problem}
      problemNumber={this.props.problemNumber}
      options={chooseOptions}
      isShowOk={this.props.isShowOk}
      onOk={this.props.onOk || this.defaultOnOk}
      correctAnswers={correctAnswers ? [correctAnswers] : []}
      defaultChoices={userAnswer && [userAnswer]}
      onSelectChanged={this.props.onSelectChanged}
    />);
  };

  renderMultiChoosingProblem = () => {
    const { problemItem } = this.props;
    if (isEmpty(problemItem)) return null;
    const { problem } = problemItem;
    if (isEmpty(problem)) return null;

    const options = get(problem, 'options', {});
    const chooseOptions = get(options, 'options', []);
    const correctAnswers = get(options, 'correct_answer', []);
    const userAnswer = get(this.props.solution, 'answer', null);
    return (<ChoosingProblem
      key={get(problemItem, 'problem.id', '')}
      mode={this.state.problemMode}
      problem={problem}
      problemItem={problemItem}
      problemNumber={this.props.problemNumber}
      options={chooseOptions}
      isShowOk={this.props.isShowOk}
      multiple
      onOk={this.props.onOk || this.defaultOnOk}
      correctAnswers={correctAnswers}
      defaultChoices={userAnswer}
      onSelectChanged={this.props.onSelectChanged}
    />);
  };

  renderTofProblem = () => {
    const { problemItem } = this.props;
    if (isEmpty(problemItem)) return null;
    const { problem } = problemItem;
    if (isEmpty(problem)) return null;

    const options = get(problem, 'options', {});
    const correctAnswers = options.correct_answer;
    const userAnswer = get(this.props.solution, 'answer', null);
    return (<TofProblem
      key={get(problemItem, 'problem.id', '')}
      mode={this.state.problemMode}
      problem={problem}
      problemItem={problemItem}
      problemNumber={this.props.problemNumber}
      isShowOk={this.props.isShowOk}
      correctAnswers={correctAnswers}
      onOk={this.props.onOk || this.defaultOnOk}
      defaultChoices={userAnswer}
      onSelectChanged={this.props.onSelectChanged}
    />);
  };

  render() {
    const { problemItem } = this.props;
    if (isEmpty(problemItem)) return null;
    const { problem } = problemItem;
    if (isEmpty(problem)) return null;
    switch (ProblemTypeEnum.enumValueOf(get(problem, 'problem_type', 0))) {
      case ProblemTypeEnum.SINGLE:
        return this.renderSingleChoosingProblem();
      case ProblemTypeEnum.MULTIPLE:
        return this.renderMultiChoosingProblem();
      case ProblemTypeEnum.TRUE_OR_FALSE:
        return this.renderTofProblem();
      default:
        return null;
    }
  }
}

export default ObjectiveProblemView;
