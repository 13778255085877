import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined, UpOutlined } from '@ant-design/icons';
import {
  Menu, Dropdown, Button, message, Tag, Input, Select, Popover 
} from 'antd';
import { noop } from 'lodash';
import { MenuItem } from 'react-contextmenu';

const { Option, OptGroup } = Select;
function handleMenuClick() {
  message.success('ok');
}
class Tabselector extends PureComponent {
  static  propTypes ={
    selectTabs: PropTypes.func,
    setTitle: PropTypes.func,
    TabsValue: PropTypes.arrayOf(PropTypes.object),
    Tab: PropTypes.arrayOf(PropTypes.object),
    TitleValue: PropTypes.string,
  }

  static defaultProps ={
    TabsValue: [],
    Tab: [],
    TitleValue: '',
    selectTabs: noop,
    setTitle: noop,
  }

  constructor(props) {
    super(props);
    this.state = {
      tablist: this.props.Tab.map((item) => { return item.id; }),
      tabListName: this.props.Tab.map((item) => { return item.name; }),
      tabs: this.props.TabsValue.map((item) => { return { ...item, visible: true }; }),
      tab: this.props.Tab.map((item) => { return item; }),
      title: this.props.TitleValue,
      visible: false,
    };
    this.setTitle = this.setTitle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tablist: nextProps.Tab.map((item) => { return item.id; }),
      tabListName: nextProps.Tab.map((item) => { return item.name; }),
      tabs: nextProps.TabsValue.map((item) => { return { ...item, visible: true }; }),
      tab: nextProps.Tab.map((item) => { return item; }),
      title: nextProps.TitleValue,
    });
  }

  selectItem(tid, la) {
    const listData = [...this.state.tabs];
    const ListData = [...this.state.tab];
    const itemFilter = listData.map((item) => { return item.id; });
    const item = ListData
      .filter((arr) => { return (arr.id === tid && itemFilter.indexOf(tid) === -1); });
    if (item.length !== 0) {
      const additem = {
        id: tid, name: item[0].name, language: la, visible: true, 
      };
      this.setState({
        tablist: [...this.state.tablist, tid],
        tabListName: [...this.state.tabListName, item[0].name],
        tabs: [...listData, additem],
      }, () => {
        this.props.selectTabs(this.state.tabs);
      });
    }
  }

  deleteItem(id) {
    const listData = [...this.state.tabs];
    const listTabs = [...this.state.tablist];
    const listTabName = [...this.state.tabListName];
    const ids = this.state.tabs.map(tabs => tabs.id);
    const sequences = ids.indexOf(id);
    this.setState({
      tablist: listTabs.filter((arr) => { return arr !== id; }),
      tabListName: listTabName.filter((arr) => { return arr !== this.state.tabs[sequences].name; }),
      tabs: listData.filter((arr) => { return arr.id !== id;  }),
    }, () => {
      this.props.selectTabs(this.state.tabs);
    });
  }

  setTitle(e) {
    this.setState({
      title: e.target.value,
    }, () => this.props.setTitle(this.state.title));
  }
 
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    return (
      <>
        <div id="solutions-markdowm-input-title-container">
          <Input className="problems-solutions-markdowm-input-title" placeholder="请输入标题" value={this.state.title} size="large" onChange={this.setTitle}  />
        </div>
        <div id="solutions-tags-list-line">{this.state.visible 
          ? <Button shape="round" icon={<UpOutlined />} onClick={() => this.handleVisibleChange()}>收起</Button>
          :  <Button shape="round" icon={<PlusOutlined />} onClick={() => this.handleVisibleChange()}>添加标签</Button>  }
          <span className="solutions-tags-list-line-items">{this.state.tabs.length === 0 ? <span><small>添加语言和方法标签</small></span>
            : this.state.tabs.filter((tab) => { 
              return (this.state.tablist.indexOf(tab.id) !== -1); 
            }).map((tab) => {
              return (
                <Tag
                  key={tab.id}
                  closable
                  visible={tab.visible}
                  onClose={() => this.deleteItem(tab.id)}
                >
                  {tab.name}
                </Tag>); 
            })}</span></div>
        <div id="search-select-solutions-tags">{this.state.visible 
          ? <div className="search-select-solutions-tags-selector"><Select 
            autoFocus
            open
            placeholder="搜素标签"
            onClick={handleMenuClick} 
            style={{ width: 200 }}
            showSearch
            showArrow={false}
            optionFilterProp="children"
          ><OptGroup label="语言类">{
         (this.state.tab).filter((item) => { return item.language === true; }).map((tab) => {
           return <Option
             key={tab.id} 
             onClick={() => this.selectItem(tab.id, tab.language)}
           >{tab.name}</Option>;
         })
         }
          </OptGroup>
            <OptGroup label="方法类">{
         (this.state.tab).filter((item) => { return item.language === false; }).map((tab) => {
           return <Option
             key={tab.id} 
             onClick={() => this.selectItem(tab.id, tab.language)}
           >{tab.name}</Option>;
         })
         }
            </OptGroup>
          </Select>
          </div> : null}</div>
      </>
    );
  }
}
export default Tabselector;
