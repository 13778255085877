import React from 'react';
import PropTypes from 'prop-types';
import { Card, Progress, Empty } from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import moment from 'moment';
import { get } from 'lodash';
import { diffTimeSeconds, formatTimeFromNowPure } from 'scripts/common/utils/time_formatter';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AsgnRoutes, CourseRoutes } from 'scripts/apps/routes';
import { AccountRoleEnum } from 'scripts/common/enums/account';
import { APP_CONST } from 'scripts/common/constants/apps';

const ASGN_STATUS_CALL = {
  0: '未访问',
  1: '正在做',
  2: '已提交',
};

const TEACHER_ASGN_STATUS_CALL = {
  1: '正在批改',
  2: '已批改',
};

class AccountSpaceHomeView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    // account: AccountPropTypes,
    backlogList: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    // account: null,
    backlogList: [],
  };

  state = {};

  gotoCourseAsgnList = (courseId) => {
    this.props.history.push(buildPath(CourseRoutes.CONTENT_ASGN, { courseId }));
  };

  gotoAsgnView = (courseId, asgnId) => {
    this.props.history.push(buildPath(AsgnRoutes.OVERVIEW, { courseId, asgnId }));
  };

  renderBacklogStatus = (st, et) => {
    const dst = diffTimeSeconds(st);
    const det = diffTimeSeconds(et);
    const DAYS7 = APP_CONST.COMMON.DAYS_7;

    if (dst < 0) {
      return <span className="task-status task-status-pending">{formatTimeFromNowPure(st)}后开始</span>;
    } if (det > -DAYS7 && det < 0) {
      return <span className="task-status task-status-deadline">{formatTimeFromNowPure(et)}后截止</span>;
    } if (det >= 0) {
      return <span className="task-status task-status-deadline">已截止</span>;
    }
    return <span className="task-status task-status-running">进行中</span>;
  };

  renderBacklogList = () => {
    const ASGN_CHECKED_OR_DONE = 2;
    return <div className="backlog-wrap">
      {this.props.backlogList.length > 0 ? this.props.backlogList.map((item) => {
        const ptotal = get(item, 'problems.total', 0);
        const psolved = get(item, 'problems.solved', 0);
        const rtotal = get(item, 'report.total', 0);
        const rcheck = get(item, 'report.check', 0);
        const asgnId = get(item, 'asgn_id');
        const status = get(item, 'status', 0);
        const courseId = get(item, 'course_id');
        const role = get(item, 'role', 0);
        const itemClick = () => {
          if (status === 0) this.gotoCourseAsgnList(courseId);
          else if (status === 1 || status === ASGN_CHECKED_OR_DONE) {
            this.gotoAsgnView(courseId, asgnId);
          }
        };
        return <div key={asgnId} className="task-item" onClick={itemClick}>
          <div className="task-details">
            <div className="task-title">{item.title}</div>
            {(item.role === AccountRoleEnum.STUDENT.value) && this.renderBacklogStatus(get(item, 'start_time', 0), get(item, 'end_time', 0))}
          </div>
          {diffTimeSeconds(get(item, 'start_time', 0)) >= 0 && <div className="task-details">
            <div className="task-progress">
              {role === AccountRoleEnum.STUDENT.value && <Progress
                percent={parseFloat(
                  ptotal === 0 ? 0 : ((psolved / ptotal) * APP_CONST.COMMON.PERCENT_100).toFixed(1)
                )}
              />}
              {role === AccountRoleEnum.TEACHER.value && <Progress
                percent={parseFloat(
                  rtotal === 0 ? 0 : ((rcheck / rtotal) * APP_CONST.COMMON.PERCENT_100).toFixed(1)
                )}
              />}
            </div>
            <div className="task-process">
              {role === AccountRoleEnum.TEACHER.value ? get(TEACHER_ASGN_STATUS_CALL, status, '未知') : get(ASGN_STATUS_CALL, status, '未知')}
            </div>
          </div>}
        </div>;
      }) : <Empty description="暂无待办" />}
    </div>;
  };

  render() {
    return <div className="account-space-home">
      <div className="header-wrap">
        <div className="left-wrap">
          <Card bordered={false} size="small" title={`今天是${moment().format('YYYY年MM月DD日 dddd')}`}>
            祝你AC每一天 ;-)
          </Card>
        </div>
        <div className="right-wrap">
          <Card bordered={false} title="待办事项" size="small">
            {this.renderBacklogList()}
          </Card>
        </div>
      </div>
      <div style={{ padding: 32, color: '#ccc', textAlign: 'center' }}>Coming soon...</div>
    </div>;
  }
}

export default AccountSpaceHomeView;
