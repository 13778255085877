import 'styles/widgets/cross_check.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {
  get, noop, truncate, isEqual, pick 
} from 'lodash';
import {
  Button, Spin, Table, Select, Result, Popconfirm, message, Space
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  ContestSupportSelectors,
  ContestSupportActions
} from 'scripts/common/logic/contest/support';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import {
  buildPath, getQuery, openLink, buildApiPath 
} from 'scripts/common/utils/location_helper';
import { ContestRoutes } from 'scripts/apps/routes';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { indexToChar } from 'scripts/common/utils/unit';
import { ContestProblemPropTypes, ContestPropTypes } from 'scripts/common/prop-types/contest';
import { showModal } from 'scripts/common/widgets/modal';
import SimpleCrossCheckDetailDialog from 'scripts/apps/widgets/cross_check/simple_detail';
import { ContestProblemSelectors } from 'scripts/common/logic/contest/problem';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import { nowTime } from 'scripts/common/utils/time_formatter';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import ReactResizeDetector from 'react-resize-detector';
import {
  AntTableMiddleFooterHeight,
  AntTableMiddleHeaderHeight
} from 'scripts/common/constants/table';
import { ReloadOutlined, ExportOutlined } from '@ant-design/icons';

const timer = null;

const LOADING_STATUS_KEY = 'contest_simple_cross_check_logs_view';
const DEFAULT_RATIO = ['10', '20', '30', '40', '50', '60', '70', '80', '90'].reverse();

const mapDispatchToProps = dispatch => bindActionCreators({
  markCrossCheck: ContestSupportActions.markCrossCheck,
  getCrossCheckList: ContestSupportActions.getCrossCheckList,
  getCrossCheckDetail: ContestSupportActions.getCrossCheckDetail,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { location } = props;
  const query = getQuery(location);
  const logs = ContestSupportSelectors.contestSimpleCrossCheckLogsSelector(state);
  const listdata = get(logs, 'logs', []);
  const pagination = get(logs, 'pagination', null);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const codeProblems = ContestProblemSelectors.problemListData(state);
  const queryParams = {
    ...PaginationParams40,
    ...pick(query, ['limit', 'page', 'order', 'problem_id', 'ratio']),
  };
  const contestId = props.contest.id;
  return {
    listdata,
    isLoading,
    contestId,
    queryParams,
    codeProblems,
    paginationParams: pagination || props.paginationParams,
  };
};

@withRouter
@ContestLoginRequire
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class ContestSimpleCrossCheckView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    contest: ContestPropTypes,
    contestId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    listdata: PropTypes.arrayOf(PropTypes.shape({})),
    queryParams: PropTypes.shape({}),
    paginationParams: PaginationPropTypes,
    codeProblems: PropTypes.arrayOf(ContestProblemPropTypes),
    markCrossCheck: PropTypes.func,
    getCrossCheckList: PropTypes.func,
    getCrossCheckDetail: PropTypes.func,
  };

  static defaultProps = {
    contest: {},
    isLoading: true,
    listdata: [],
    paginationParams: {},
    queryParams: {},
    codeProblems: [],
    markCrossCheck: noop,
    getCrossCheckList: noop,
    getCrossCheckDetail: noop,
  };

  state = {
    tableHeight: 0,
    queryParams: {},
    exportLoading: false,
    hasFetchEntity: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchEntity || !isEqual(get(nextProps, 'queryParams'), prevState.queryParams)) {
      const { queryParams } = nextProps;
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getCrossCheckList({
        contestId: nextProps.contestId,
        query: pick(queryParams, ['limit', 'page', 'order', 'problem_id', 'ratio']),
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        queryParams,
        hasFetchEntity: true,
      };
    }
    return null;
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      ContestRoutes.SUPPORT.CROSS_CHECK,
      {},
      match.params,
      {
        ...pick(this.props.queryParams, ['limit', 'page', 'order', 'problem_id', 'ratio']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleFilterOrderChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      ContestRoutes.SUPPORT.CROSS_CHECK,
      {},
      match.params,
      value === '0' ? {
        ...pick(this.props.queryParams, ['problem_id', 'ratio']),
      } : {
        ...pick(this.props.queryParams, ['problem_id', 'ratio']),
        order: value,
      }
    ));
  };

  handleFilterProblemIdChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      ContestRoutes.SUPPORT.CROSS_CHECK,
      {},
      match.params,
      value === '0' ? {
        ...pick(this.props.queryParams, ['order', 'ratio']),
      } : {
        ...pick(this.props.queryParams, ['order', 'ratio']),
        problem_id: value,
      }
    ));
  };

  handleFilterRatioChange = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      ContestRoutes.SUPPORT.CROSS_CHECK,
      {},
      match.params,
      value === '0' ? {
        ...pick(this.props.queryParams, ['order', 'problem_id']),
      } : {
        ...pick(this.props.queryParams, ['order', 'problem_id']),
        ratio: value,
      }

    ));
  };

  handleViewDetailClick = (logId, labels) => () => {
    showModal(SimpleCrossCheckDetailDialog, {
      sourceLabel: labels.source_label,
      targetLabel: labels.target_label,
      getDetail: (success) => {
        return this.props.getCrossCheckDetail({
          logId,
          contestId: this.props.contestId,
          onSuccess: success,
        });
      },
      detailSelector: ContestSupportSelectors.contestSimpleCrossCheckLogDetailSelector,
    });
  };

  handleMarkUser = logId => () => {
    this.props.markCrossCheck({
      logId,
      contestId: this.props.contestId,
      onSuccess: () => {
        this.setState({
          hasFetchEntity: false,
        });
      },
    });
  };

  handleExportClick = () => {
    message.info('正在导出中。如长时间未下载，请刷新重试。');
    this.setState({
      exportLoading: true,
    });
    openLink(buildApiPath(`/contests/${this.props.contestId}/cross/download`), '', true);
    setTimeout(() => {
      this.setState({
        exportLoading: false,
      });
    }, 5000);
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  renderSimpleColumns = () => {
    return [
      {
        title: '任务序号',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        align: 'center',
      },
      {
        title: '题目编号',
        dataIndex: 'problem_id',
        key: 'problem_id',
        width: 100,
        align: 'center',
      },
      {
        title: '检测对象',
        key: 'status',
        dataIndex: 'status',
        children: [
          {
            title: '提交1',
            dataIndex: 'source_label',
            key: 'source_label',
            align: 'center',
          },
          {
            title: '提交2',
            dataIndex: 'target_label',
            key: 'target_label',
            align: 'center',
          }
        ],
      },
      {
        title: '查重率',
        dataIndex: 'sim_ratio',
        key: 'sim_ratio',
        align: 'center',
        width: 100,
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        align: 'center',
        width: 200,
      }
    ];
  };

  renderStatusLabel = (status) => {
    return <>
      <strong>#{get(status, 'id')}</strong>
      {get(status, 'author', null) && <>
        ({get(status, 'author.nickname')}, {get(status, 'author.username')})
      </>}
    </>;
  };

  renderSimpleRow = () => {
    if (!this.props.listdata) return null;
    return this.props.listdata.map((item) => {
      const labels = {
        source_label: this.renderStatusLabel(get(item, 'source', {})),
        target_label: this.renderStatusLabel(get(item, 'target', {})),
      };
      return {
        key: get(item, 'id', 0),
        id: `#${get(item, 'id', 0)}`,
        problem_id: indexToChar(get(item, 'contest_problem.order', 0)),
        ...labels,
        sim_ratio: `${get(item, 'sim_ratio', 0)} %`,
        operation: <>
          <Button style={{ marginRight: 8 }} onClick={this.handleViewDetailClick(get(item, 'id'), labels)} type="default" size="small">查看详情</Button>
          <Popconfirm title="确定要标记吗？" onConfirm={this.handleMarkUser(get(item, 'id', 0))}>
            <Button type="primary" size="small">{get(item, 'is_process') ? '已标记' : '确认并标记'}</Button>
          </Popconfirm>
        </>,
      };
    });
  };

  renderTaskTable = () => {
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 1),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.handlePageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    return <>
      <div className="simple_logs_toolbar">
        <div className="info-area">
          <div className="filter-group">
            {/*TODO 这里以后可以支持一下keyword查询*/}
            <strong>排序方式</strong>
            <Select
              dropdownMatchSelectWidth={false}
              value={get(this.props.queryParams, 'order', '0')}
              onChange={this.handleFilterOrderChange}
            >
              <Select.Option value="0">按查重率倒序</Select.Option>
              <Select.Option value="1">按查重率正序</Select.Option>
              <Select.Option value="2">按ID正序</Select.Option>
              <Select.Option value="3">按ID倒序</Select.Option>
            </Select>
          </div>
          <div className="filter-group">
            <strong>题目筛选</strong>
            <Select
              dropdownMatchSelectWidth={false}
              value={get(this.props.queryParams, 'problem_id', '0')}
              onChange={this.handleFilterProblemIdChange}
            >
              <Select.Option value="0">不限</Select.Option>
              {this.props.codeProblems.map((ap) => {
                return <Select.Option key={get(ap, 'id', 0)} value={get(ap, 'id', 0).toString()}>
                  题目{indexToChar(get(ap, 'order', 0))}.&nbsp;
                  {truncate(get(ap, 'problem.title', ''), {
                    length: 20,
                  })}
                </Select.Option>;
              })}
            </Select>
          </div>
          <div className="filter-group">
            <strong>查重率</strong>
            <Select
              dropdownMatchSelectWidth={false}
              value={get(this.props.queryParams, 'ratio', '0')}
              onChange={this.handleFilterRatioChange}
            >
              <Select.Option value="0">不限</Select.Option>
              {DEFAULT_RATIO.map((ratio) => {
                return <Select.Option key={ratio.toString()} value={ratio.toString()}>
                  ≥ {ratio}%
                </Select.Option>;
              })}
            </Select>
          </div>
        </div>
        <div className="action-area">
          <Space>
            <Button
              onClick={this.handleExportClick}
              loading={this.state.exportLoading}
            >
              <ExportOutlined /> {this.state.exportLoading ? '导出中' : '导出信息'}
            </Button>
            <Button
              onClick={() => this.setState({ hasFetchEntity: false })}
              type="primary"
            >
              <ReloadOutlined /> 刷新列表
            </Button>
          </Space>
        </div>
      </div>
      <div className="cross_check_table">
        <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
        <Table
          size="middle"
          pagination={optPagination}
          loading={{ spinning: this.props.isLoading, tip: '正在载入查重任务记录...' }}
          bordered
          scroll={{
            y: this.state.tableHeight - (96 + AntTableMiddleFooterHeight),
            scrollToFirstRowOnChange: true,
          }}
          columns={this.renderSimpleColumns()}
          dataSource={this.renderSimpleRow()}
        />
      </div>
    </>;
  };

  render() {
    const isReferee = get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
    const timeNow = nowTime().unix();
    const contestEndTime =  get(this.props.contest, 'end_time', Infinity);
    const publicCross = get(this.props.contest, 'configs.public_cross_check', false);
    const isShowCrosscheck = isReferee || (publicCross && timeNow > contestEndTime);

    if (!isShowCrosscheck) {
      return <Result title="查重信息还没有开放" />;
    }
    
    return (<div className="simple_cross_check_view">
      {this.renderTaskTable()}
    </div>);
  }
}

export default ContestSimpleCrossCheckView;
