import React from 'react';
import PropTypes from 'prop-types';
import {
  get, isEmpty, noop
} from 'lodash';

import {
  AppstoreOutlined,
  AuditOutlined,
  ClockCircleOutlined,
  CrownOutlined,
  HomeOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TeamOutlined,
  TrophyOutlined
} from '@ant-design/icons';

import {Menu, Tag} from 'antd';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import { nowTime } from 'scripts/common/utils/time_formatter';
import { ContestRoutes, ContestRootRoutes } from 'scripts/apps/routes';
import {
  ContestAccountPropTypes,
  ContestPropTypes,
  ContestProblemPropTypes,
  ContestAuthPropTypes
} from 'scripts/common/prop-types/contest';
import { indexToChar } from 'scripts/common/utils/unit';
import { ContestProblemSelectors } from 'scripts/common/logic/contest/problem';
import { ContestAccountRoleEnum, RegisterTypeEnum } from 'scripts/common/enums/contest';
import { withContestContext } from '../contest_provider';
import BalloonIcon from 'svgs/si-glyph-balloon.svg';

const { SubMenu } = Menu;

const CONTEST_PATH = {
  home: ContestRootRoutes.HOME,
  problems_list: ContestRoutes.PROBLEM.LIST,
  problems: ContestRoutes.PROBLEM.LIST,
  rank: ContestRoutes.RANK.LIST,
  rank_list: ContestRoutes.RANK.LIST,
  organization_list: ContestRoutes.ORGANIZATION.LIST,
  enroll: ContestRoutes.ACCOUNT.ENROLL,
  faq: ContestRoutes.SUPPORT.FAQ,
  cross_check: ContestRoutes.SUPPORT.CROSS_CHECK,
  print: ContestRoutes.SUPPORT.PRINT,
  judge_status: ContestRoutes.JUDGE_STATUS.LIST,
  judge_solutions: ContestRoutes.JUDGE_STATUS.SOLUTIONS,
  manage: ContestRoutes.MANAGE.CONTEST,
  contest_manage: ContestRoutes.MANAGE.CONTEST,
  account_manage: ContestRoutes.MANAGE.ACCOUNT,
  login: ContestRoutes.ACCOUNT.LOGIN,
  organization_manage: ContestRoutes.MANAGE.ORGANIZATION.LIST,
  notice_manage: ContestRoutes.MANAGE.NOTICE,
  del_contest: ContestRoutes.MANAGE.DEL_CONTEST,
  wj_info: ContestRoutes.SUPPORT.WJ_INFO,
};

const mapStateToProps = (state) => {
  return {
    problemDatas: ContestProblemSelectors.problemListData(state),
  };
};

@withRouter
@withContestContext
@connect(mapStateToProps, null)
class ContestNavigator extends React.Component {
  static propTypes = {
    account: ContestAccountPropTypes,
    contest: ContestPropTypes,
    loginStatus: ContestAuthPropTypes,
    ...RoutePropTypes,
    problemDatas: PropTypes.arrayOf(ContestProblemPropTypes),
    fetchProblemList: PropTypes.func,
  };

  static defaultProps = {
    account: {},
    contest: {},
    loginStatus: {},
    problemDatas: [],
    fetchProblemList: noop,
  };

  static getDerivedStateFromProps() {
    let matchItem = matchPath(
      window.location.pathname,
      { path: ContestRootRoutes.SUBITEMS, exact: true }
    );
    if (isEmpty(matchItem)) {
      matchItem =  matchPath(
        window.location.pathname,
        { path: ContestRootRoutes.SUBITEMS2 }
      );
    }
    const subItemKey = get(matchItem, 'params.subItemName', 'home');
    const subItemKey2 = get(matchItem, 'params.subItemName2', '');
    const defaultSelectedKeys = isEmpty(subItemKey2) ? [subItemKey] : [
      subItemKey, `${subItemKey}_${subItemKey2}`
    ];
    return {
      defaultSelectedKeys,
    };
  }

  state = {
    defaultSelectedKeys: ['home'],
  };

  menuRef = React.createRef();

  handleMenuClick = keyName => () => {
    const { history } = this.props;
    //题目列表强制刷新
    if (keyName === 'problems_list' || keyName === 'problems') {
      this.props.fetchProblemList();
    }
    history.push(buildPath(CONTEST_PATH[keyName], { contestId: this.props.contest.id }));
  };

  handleSubMenuClick = ({ key }) => {
    this.handleMenuClick(key)();
  };

  handleProblemClick = (problemId) => {
    const { history } = this.props;
    history.push(buildPath(
      ContestRoutes.PROBLEM.CONTENT,
      { contestId: this.props.contest.id, problemId }
    ));
  };

  handleFancyRank = () => {
    window.open(buildPath(
      ContestRootRoutes.FANCY_RANK,
      { contestId: this.props.contest.id }
    ));
  };

  renderProblemLists = () => {
    return this.props.problemDatas.map((problem) => {
      const pid = get(problem, 'id', 0);
      return <Menu.Item
        key={`problems_${pid}`}
        onClick={() => this.handleProblemClick(pid)}
      >
        题目{indexToChar(get(problem, 'order', 0))}
      </Menu.Item>;
    });
  };

  renderJudgeNavSubMenu = () => {
    const isReferee = get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
    if (isReferee) {
      return <SubMenu onTitleClick={this.handleSubMenuClick} key="judge_status" title={<><ClockCircleOutlined /> 评测 </>}>
        <Menu.Item
          key="judge_status_list"
          onClick={this.handleMenuClick('judge_status')}
        >
          评测记录
        </Menu.Item>
        <Menu.Item
          key="judge_status_solutions"
          onClick={this.handleMenuClick('judge_solutions')}
        >
          过题记录
        </Menu.Item>
      </SubMenu>;
    }
    return <Menu.Item
      key="judge_status_list"
      onClick={this.handleMenuClick('judge_status')}
    >
      <ClockCircleOutlined /> 评测
    </Menu.Item>;
  };

  renderRankList = () => {
    const isReferee = get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
    if (isReferee) {
      return <SubMenu onTitleClick={this.handleSubMenuClick} key="rank" title={<><CrownOutlined /> 排行 </>}>
        <Menu.Item key="rank_list" onClick={this.handleMenuClick('rank')}>排行榜</Menu.Item>
        {isReferee && <Menu.Item key="fancy_rank" onClick={this.handleFancyRank}>滚榜(beta)</Menu.Item>}
      </SubMenu>;
    }
    return <Menu.Item key="rank_list" onClick={this.handleMenuClick('rank')}>
      <CrownOutlined /> 排行
    </Menu.Item>;
  };

  renderNavNormal = () => {
    const isReferee = get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
    const timeNow = nowTime().unix();
    const contestEndTime =  get(this.props.contest, 'end_time', Infinity);
    const publicCross = get(this.props.contest, 'configs.public_cross_check', false);
    const isShowPrint = get(this.props.contest, 'configs.enable_printer_queue', false);
    const isShowCrosscheck = isReferee || (publicCross && timeNow > contestEndTime);
    const isShowMore = isShowCrosscheck || isShowPrint;
    const ret = [
      <SubMenu
        onTitleClick={this.handleSubMenuClick}
        key="problems"
        title={<><svg
          fill="#ffffff"
          fillOpacity={0.65}
          width={16}
          height={16}
          style={{ marginTop: 2, verticalAlign: 'text-bottom' }}
        >
          <use xlinkHref={`#${BalloonIcon.id}`} />
        </svg> 题目</>}
      >
        <Menu.Item key="problems_list" onClick={this.handleMenuClick('problems_list')}> 所有题目</Menu.Item>
        {this.renderProblemLists()}
      </SubMenu>,
      this.renderJudgeNavSubMenu(),
      this.renderRankList(),
      <Menu.Item
        key="supports_faq"
        onClick={this.handleMenuClick('faq')}
      >
        <QuestionCircleOutlined /> 答疑
      </Menu.Item>
    ];
    if (isShowMore) {
      ret.push(<SubMenu key="supports" title={<><AppstoreOutlined /> 更多</>}>
        {isShowCrosscheck && <Menu.Item key="supports_cross_check" onClick={this.handleMenuClick('cross_check')}> 查重</Menu.Item>}
        {isShowPrint && <Menu.Item key="supports_print" onClick={this.handleMenuClick('print')}> 打印服务</Menu.Item>}
      </SubMenu>);
    }
    return ret;
  };

  renderNavOrg = () => {
    return [
      <Menu.Item
        key="organization_list"
        onClick={this.handleMenuClick('organization_list')}
      >
        <TeamOutlined /> 自由组队
      </Menu.Item>
    ];
  };

  renderNavEnroll = () => {
    const isLogined = get(this.props.loginStatus, 'logined', false);
    const isEnrolled = isLogined && get(this.props.account, 'is_enrolled', false);
    const isPaid = get(this.props.account, 'is_paid', false);
    const enrollNeedPay = get(this.props.contest, 'enroll_need_pay', false);
    return [
      <Menu.Item
        key="accounts_enroll"
        onClick={this.handleMenuClick('enroll')}
      >
        <AuditOutlined /> 报名参赛{isEnrolled && ((enrollNeedPay && !isPaid) ? <Tag color="#f50" style={{ marginLeft: '8px', transform: 'translateY(-1px)' }}>待支付</Tag> : ' (已报名)')}
      </Menu.Item>
    ];
  };

  renderNavLogin = () => {
    const timeNow = nowTime().unix();
    const contestStartTime =  get(this.props.contest, 'start_time', Infinity);
    const ret = [];
    if (timeNow >= contestStartTime) {
      ret.push(<Menu.Item
        key="rank_list"
        onClick={this.handleMenuClick('rank')}
      >
        <CrownOutlined /> 排行
      </Menu.Item>);
    }
    return ret;
  };

  renderNavAdmin = () => {
    const isAdmin = get(this.props.account, 'id') === get(this.props.contest, 'author_id');
    return [
      <SubMenu key="manage" onTitleClick={this.handleSubMenuClick} title={<><SettingOutlined /> 管理</>}>
        {isAdmin && <Menu.Item onClick={this.handleMenuClick('contest_manage')} key="manage_contest"> 比赛设置</Menu.Item>}
        {isAdmin && <Menu.Item onClick={this.handleMenuClick('account_manage')} key="manage_account"> 账号管理</Menu.Item>}
        {this.props.contest.contest_type === 1 && isAdmin && <Menu.Item onClick={this.handleMenuClick('organization_manage')} key="manage_organization">
          队伍管理
        </Menu.Item>}
        <Menu.Item onClick={this.handleMenuClick('notice_manage')} key="manage_notice">公告管理</Menu.Item>
        {isAdmin && <Menu.Item onClick={this.handleMenuClick('del_contest')} key="del_contest"> 删除比赛</Menu.Item>}
        {/*<Menu.Item key="data_zip"> 数据归档</Menu.Item>*/}
      </SubMenu>
    ];
  };

  renderNavLogined = () => {
    const timeNow = nowTime().unix();
    const enrollStartTime =  get(this.props.contest, 'configs.enroll_start_time', 0);
    const enrollEndTime =  get(this.props.contest, 'configs.enroll_end_time', 0);
    const needEnroll =  get(this.props.contest, 'configs.enroll', false);
    const contestStartTime =  get(this.props.contest, 'start_time', Infinity);
    const isContestStarted = timeNow >= contestStartTime;
    const contestTeamType = get(this.props.contest, 'team_type', 1);
    const isAdmin = get(this.props.account, 'id') === get(this.props.contest, 'author_id');
    const isReferee = get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
    const isTeam =   get(this.props.account, 'role', 0) === ContestAccountRoleEnum.TEAM.value;
    const showOrganize = !isContestStarted && contestTeamType === RegisterTypeEnum.GROUP.value
      && timeNow >= enrollStartTime && timeNow <= enrollEndTime;
    const isPaid = get(this.props.account, 'is_paid', false);
    const enrollNeedPay = get(this.props.contest, 'enroll_need_pay', false);
    const hadEnrolled = get(this.props.account, 'is_enrolled', false);
    const isEnrolled = isReferee || isTeam || !needEnroll
      || (hadEnrolled && (!enrollNeedPay || isPaid));
    return (isEnrolled && (isContestStarted || isReferee || isAdmin) ? [
      ...this.renderNavNormal(),
      ...(isReferee ? this.renderNavAdmin() : [])
    ] : [
      ...this.renderNavEnroll(),
      ...(isEnrolled && showOrganize ? this.renderNavOrg() : [])
    ]);
  };

  render() {
    const isLogined = get(this.props.loginStatus, 'logined', false);
    return (
      <Menu
        selectedKeys={this.state.defaultSelectedKeys}
        theme="dark"
        mode="horizontal"
        className="navigation-menu"
        ref={this.menuRef}
      >
        <Menu.Item key="home" onClick={this.handleMenuClick('home')}>
          <HomeOutlined />
          <span className="nav-text"> 首页</span>
        </Menu.Item>
        {isLogined ? this.renderNavLogined() : this.renderNavLogin()}
      </Menu>
    );
  }
}

export default ContestNavigator;
