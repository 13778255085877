import 'styles/education/lesson/index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {
  FileTextFilled, FolderOutlined, HomeOutlined, VideoCameraFilled 
} from '@ant-design/icons';
import { Menu, Typography, Tooltip } from 'antd';
import {
  get, noop, isEqual, isEmpty, uniq 
} from 'lodash';
import { LessonSelectors } from 'scripts/common/logic/education/lesson/selector';
import { bindActionCreators } from 'redux';
import { LessonActions } from 'scripts/common/logic/education/lesson';
import { connect } from 'react-redux';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { LessonPropTypes } from 'scripts/common/prop-types/course';
import { buildPath } from 'scripts/common/utils/location_helper';
import { CourseRoutes, LessonRoutes } from 'scripts/apps/routes';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { createPermissionChecker } from 'scripts/common/utils/validator';
import moment from 'moment';
import { CoursePermissionsEnum } from 'scripts/common/enums/course';
import { matchPath } from 'react-router';
import LessonBodyView from './lesson_body';

const mapStateToProps = (state, props) => {
  const { match, course } = props;
  const lessonId = get(match, 'params.lessonId', '');
  const lessonList = LessonSelectors.lessonListViewSelector(state);
  const lesson = LessonSelectors.lessonEntitiesSelector(state)(lessonId);
  const coursePermission = createPermissionChecker(get(course, 'permissions_calculated', {}));
  const hasModifyCoursePermission = coursePermission(CoursePermissionsEnum.MODIFY);
  return {
    lesson,
    lessonId,
    lessonList,
    hasModifyCoursePermission,
  };
};
const mapDispatchToProps = dispatch => bindActionCreators({
  getLessonsList: LessonActions.getLessonsList,
  getLessonEntity: LessonActions.getLessonEntity,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
class LessonView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.string,
    lesson: LessonPropTypes,
    lessonList: PropTypes.arrayOf(LessonPropTypes),
    getLessonsList: PropTypes.func,
    getLessonEntity: PropTypes.func,
  };

  static defaultProps = {
    lessonId: '',
    lesson: null,
    lessonList: [],
    getLessonsList: noop,
    getLessonEntity: noop,
  };

  state = {
    currentLessonId: '',
    openKeys: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.lessonId, prevState.currentLessonId)) {
      if (nextProps.lessonId) {
        nextProps.getLessonEntity({
          courseId: nextProps.courseId,
          id: nextProps.lessonId,
        });
      }
      return {
        currentLessonId: nextProps.lessonId,
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.getLessonsList({
      courseId: this.props.courseId,
    });
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.lessonList) && !isEmpty(this.props.lessonList)) {
      const { lessonList = [] } = this.props;
      const dfs = (node) => {
        if (isEqual(get(node, 'id', '').toString(), this.props.lessonId)) {
          return {
            flag: true,
            rel: [node.id],
          };
        }
        const { children = [] } = node;
        let rel = null;
        for (let i = 0; i < children.length; i++) {
          rel = dfs(children[i]);
          if (rel.flag) {
            return {
              flag: true,
              rel: [get(node, 'id', '').toString(), ...rel.rel],
            };
          }
        }
        return {
          flag: false,
          rel: [],
        };
      };
      const { rel } = dfs({ id: '', children: lessonList });
      const openKeys = [];
      lessonList.forEach((item) => {
        openKeys.push(get(item, 'id').toString());
      });
      this.setState({   // eslint-disable-line
        openKeys: uniq([
          ...openKeys,
          ...rel
        ]).filter(i => !!i),
      });
    }
  }

  handleSiderClick = (item) => {
    const { history } = this.props;
    if (item.key === 'root') {
      history.push(buildPath(
        CourseRoutes.LESSON,
        { courseId: this.props.courseId }
      ));
      return;
    }
    history.push(buildPath(
      LessonRoutes.LESSON,
      { courseId: this.props.courseId, lessonId: item.key }
    ));
  };

  renderMenuItem = (lesson) => {
    const {
      id,
      title,
      video,
      children = [],
    } = lesson;
    const visibleTime = get(lesson, 'visible_time', 0);
    const hasChildren = get(lesson, 'is_group');
    let canVisible = (visibleTime > 0 && moment(visibleTime * 1000) <= moment()) || !visibleTime;
    if (this.props.hasManageCoursePermission) {
      canVisible = true;
    }
    return id !== 'root' && hasChildren
      ? <Menu.SubMenu
        key={id}
        title={
          <>
            <FolderOutlined />
            {title}
          </>
        }
      >
        {children.map((item) => {
          return this.renderMenuItem(item);
        })}
      </Menu.SubMenu>
      : <Menu.Item key={id} disabled={!canVisible}>
        {visibleTime === 0 ? <span> {video ? <VideoCameraFilled /> : <FileTextFilled />}
          {title}</span> : <Tooltip title={moment(visibleTime * 1000).format('开放时间：YY年MM月DD日 HH:mm')}>
            {video ? <VideoCameraFilled /> : <FileTextFilled />}
            {title}
          </Tooltip>}
      </Menu.Item>;
  };

  renderLessonBody = () => {
    if (!this.props.lessonId) {
      const { course } = this.props;
      const courseDesc = get(course, 'description', '');
      return <div className="lesson_body_view">
        <div className="course_introduction">
          <div className="desc">
            <Typography.Title level={4}>课程介绍</Typography.Title>
            <RichtextContext className="desc_html" content={courseDesc} viewEmpty />
          </div>
        </div>
      </div>;
    }
    if (this.props.lesson) {
      return <LessonBodyView
        lesson={this.props.lesson}
        courseId={this.props.courseId}
        lessonId={this.props.lessonId}
      />;
    }
    return null;
  };

  render() {
    return (
      <div className="course_lession_view">
        <div className="lesson_tree_view">
          <Menu
            onClick={this.handleSiderClick}
            openKeys={this.state.openKeys}
            selectedKeys={[this.props.lessonId || 'root']}
            mode="inline"
            onOpenChange={(openKeys) => { this.setState({ openKeys }); }}
          >
            <Menu.Item key="root"><HomeOutlined /> 课程介绍</Menu.Item>
            {this.props.lessonList.map((item) => {
              return this.renderMenuItem(item);
            })}
          </Menu>
        </div>
        {this.renderLessonBody()}
      </div>
    );
  }
}

export default LessonView;
