import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { ContestRoutes } from 'scripts/apps/routes';
import ContestManageView from 'scripts/apps/contest/manage/contest_manage_view';
import AccountManageView from 'scripts/apps/contest/manage/account_manage_view';
import OrganizationManageView from 'scripts/apps/contest/manage/organization/index';
import NoticeManageView from 'scripts/apps/contest/manage/notice_manage_view';
import DelContestView from 'scripts/apps/contest/manage/del_contest_view';

const { Content } = Layout;

const ManageApp = () => (
  <Content className="contest-layout-content">
    <Switch>
      <Route path={ContestRoutes.MANAGE.CONTEST} component={ContestManageView} />
      <Route path={ContestRoutes.MANAGE.ACCOUNT} component={AccountManageView} />
      <Route path={ContestRoutes.MANAGE.ORGANIZATION.SUBITEMS} component={OrganizationManageView} />
      <Route path={ContestRoutes.MANAGE.NOTICE} component={NoticeManageView} />
      <Route path={ContestRoutes.MANAGE.DEL_CONTEST} component={DelContestView} />
    </Switch>
  </Content>
);

export default ManageApp;
