import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { DownOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import { showModal } from 'scripts/common/widgets/modal';
import { bindActionCreators } from 'redux';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions/problem';
import connect from 'react-redux/es/connect/connect';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';

import ProblemInfoModal from './dialog/problem_info';
import { WeJudgeActions } from '../../../common/logic/wejudge/action';

const mapDispatchToProps = dispatch => bindActionCreators({
  addProblems: CollectionProblemActions.saveAddProblems,
  viewWarnMessage: WeJudgeActions.viewWarnMessage,
}, dispatch);

export const AddProblemButton = connect(null, mapDispatchToProps)((props) => {
  const showAddProblemModal = val => () => {
    const dialog = showModal(ProblemInfoModal, {
      collectionId: props.collectionId,
      problemType: ProblemTypeEnum.enumValueOf(val),
      onOk: (modal, state) => {
        // 访问接口，提交data数据。
        let checkOptions = true;
        if (ProblemTypeEnum.enumValueOf(val).value === 3
          || ProblemTypeEnum.enumValueOf(val).value === 4
          || ProblemTypeEnum.enumValueOf(val).value === 5) {
          const { options } = state.problem.options;
          options.forEach((item) => {
            if (item.name === '') {
              checkOptions = false;
            }
          });
        }
        if (!checkOptions) {
          props.viewWarnMessage({
            message: '请完善选项信息',
          });
        } else {
          props.addProblems({
            problem: {
              ...state.problem,
              problem_type: ProblemTypeEnum.enumValueOf(val).value,
            },
            collectionId: props.collectionId,
            onSuccess: (cpid) => {
              props.onSuccess(cpid, ProblemTypeEnum.enumValueOf(val));
              dialog.close();
            },
          });
        }
        return false;
      },
    });
  };

  const TypeItems = [
    ProblemTypeEnum.CODE,
    ProblemTypeEnum.CODE_FILL,
    ProblemTypeEnum.SINGLE,
    ProblemTypeEnum.MULTIPLE,
    ProblemTypeEnum.TRUE_OR_FALSE
  ];
  const menu = (
    <Menu>
      {TypeItems.map(item => <Menu.Item
        key={item.key}
        onClick={showAddProblemModal(item.value)}
      >
        {item.desc}
      </Menu.Item>)}
    </Menu>
  );

  return (
    <div className="add_problem_button">
      <Dropdown overlay={menu}>
        <Button type="primary">
          发布题目 <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
});

AddProblemButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  addProblems: PropTypes.func,
  collectionId: PropTypes.string.isRequired,
  viewWarnMessage: PropTypes.func,
};

AddProblemButton.defaultProps = {
  addProblems: noop,
};
