import 'styles/education/widgets.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { get, indexOf, noop } from 'lodash';
import { debounce } from 'scripts/common/utils/functional';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { StudentsActions } from 'scripts/common/logic/education/students/action';
import connect from 'react-redux/es/connect/connect';

const REQ_DELAY = 500;

const mapDispatchToProps = dispatch => bindActionCreators({
  getStudentInfoInCode: StudentsActions.getStudentsInfoInCode,
}, dispatch);

@connect(null, mapDispatchToProps)
class StudentPicker extends React.PureComponent {
  static propTypes = {
    schoolId: PropTypes.number.isRequired,
    queryPayload: PropTypes.shape({}),
    getStudentInfoInCode: PropTypes.func,
    onSelectChange: PropTypes.func,
    allowNew: PropTypes.bool,
    excludeStudentIds: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    getStudentInfoInCode: noop,
    onSelectChange: noop,
    queryPayload: {},
    excludeStudentIds: [],
    allowNew: true,
  };

  state = {
    text: '',
    studentSearchDatas: [],
  };

  //节流操作
  debounceSearch = debounce((value) => {
    if (value) {
      this.props.getStudentInfoInCode({
        code: [value],
        schoolId: this.props.schoolId,
        // arrangement_id: this.props.arrangement.id,
        ...this.props.queryPayload,
        onSuccess: (e) => {
          if (this.state.text === '') {
            this.setState({
              studentSearchDatas: [],
            });
          } else if (e.students.length === 0) {  //无匹配学生 []
            this.setState({
              studentSearchDatas: [
                this.props.allowNew ? { code: '请点击添加该学生', id: 'new' } : { id: 'unknown', code: '查无此学生' }
              ],
            });
          } else {  //返回查询到的学生数组 [{},{}]
            let data = e.students.map((item) => {
              return { id: get(item, 'id'), code: get(item, 'code', ''), realname: get(item, 'realname', '') };
            });

            data = [
              ...data,
              ...(this.props.allowNew ? [{ code: '请点击添加该学生', id: 'new' }] : [])
            ];
            this.setState({
              studentSearchDatas: data,
            });
          }
        },
      });
    } else {
      this.setState({
        studentSearchDatas: [...(this.props.allowNew ? [{ code: '请点击添加该学生', id: 'new' }] : [])],
      });
    }
  }, REQ_DELAY);

  handleStudentSearch = (value) => {
    if (value === '') {
      this.setState({
        studentSearchDatas: [],
      });
    } else {
      this.debounceSearch(value);
    }
  };

  handleStudentSelect = (value) => {
    const svalue = value.replace('stu_', '') || this.state.text.replace('stu_', '');
    let student = null;
    this.setState({
      text: '',
      studentSearchDatas: [],
    });
    if (svalue === '' || svalue === 'unknown' || svalue === '查无此学生') {
      return;
    }
    if (svalue !== 'new') {
      this.state.studentSearchDatas.forEach((item) => {
        if (item.code === svalue || item.id === parseInt(svalue, 10)) {
          student = item;
        }
      });
    }
    // entity, newStudentCode
    this.props.onSelectChange(student, svalue === 'new'
      ? this.state.text.replace('stu_', '') : null);
  };

  renderOption = () => {
    return this.state.studentSearchDatas.map((item) => {
      if (indexOf(this.props.excludeStudentIds, get(item, 'id')) > -1) return null;
      return {
        value: `stu_${item.id}`,
        label: <div className="student_picker_item">
          <span className="title">{item.code}</span> <span style={{ color: 'grey', fontSize: '12px' }}>{item.realname}</span>
        </div>,
      };
    }).filter(item => !!item);
  };

  render() {
    return (
      <AutoComplete
        placeholder="输入学号查询并添加"
        className="student_picker_widget"
        options={this.renderOption()}
        style={{ width: 200 }}
        onSelect={this.handleStudentSelect}
        onSearch={this.handleStudentSearch}
        value={this.state.text}
        onChange={(value) => { this.setState({ text: value }); }}
      >
        <Input suffix={<SearchOutlined />} />
      </AutoComplete>
    );
  }
}

export default StudentPicker;
