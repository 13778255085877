import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  get, noop, set, isEqual, pick, isEmpty 
} from 'lodash';
import { Alert, Button, Checkbox } from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { CollectionViewsRoutes } from '../../routes';

const mapDispatchToProps = dispatch => bindActionCreators({
  delProblems: CollectionProblemActions.saveDelProblems,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const collectionId = get(match, 'params.collectionId', '');
  return {
    collectionId,
  };
};
@connect(mapStateToProps, mapDispatchToProps)
class DeleteProblemView extends React.PureComponent {
  static propTypes  = {
    ...RoutePropTypes,
    problem: ProblemPropTypes,
    delProblems: PropTypes.func.isRequired,
    collectionId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    problem: {},
  };

  state = {
    agree: false,
  };

  handleDelClick = () => {
    const { history } = this.props;
    this.props.delProblems({
      collectionId: this.props.collectionId,
      problemId: get(this.props.problem, 'id', ''),
      onSuccess: () => {
        history.push(buildPath(
          CollectionViewsRoutes.PROBLEMS_LIST,
          { collectionId: get(this.props, 'collectionId', '') }
        ));
      },
    });
  };

  renderMessage = () => {
    return (<ul className="message_ul">
      <li>题目信息将会被永久删除，无法恢复。</li>
      <li>所有与该题目关联的做题信息、统计、评测数据等都将会被连带删除。</li>
      <li>题目ID不再存在，新增题目也将不能再使用当前的题目ID。</li>
      <li>测试数据、运行数据等存放于服务器文件系统的内容将不会被删除。</li>
      <li>当前操作仅能由管理员或题目发布者执行。</li>
    </ul>);
  };

  render() {
    const { problem } = this.props;
    if (isEmpty(problem)) return null;
    return (<div className="delete_problem_view">
      <Alert
        message="你应该清楚这个操作的风险！"
        description={this.renderMessage()}
        type="error"
        iconType="info-circle-o"
        showIcon
      />
      <div className="control">
        <Button
          type="danger"
          disabled={!this.state.agree}
          onClick={this.handleDelClick}
        >
          确认删除
        </Button>
        <Checkbox
          style={{ marginLeft: 8 }}
          disabled={this.state.agree}
          checked={this.state.agree}
          onChange={({ target }) => { this.setState({ agree: target.checked }); }}
        >我已阅读须知</Checkbox>
      </div>

    </div>);
  }
}

export default DeleteProblemView;
