import 'styles/education/course/management.scss';
import React from 'react';
import  moment  from 'moment';
import { noop, get } from 'lodash';
import PropTypes from 'prop-types';
import {
  Form, Input, DatePicker, Select, Button, Row, Col, Cascader
} from 'antd';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { CourseTypeEnum } from 'scripts/common/enums/course';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { CategoryPropTypes } from 'scripts/common/prop-types/category';
import CourseInfoCollapse from 'scripts/apps/education/course/widgets/course_info_collapse';
import AssistantListView from 'scripts/apps/education/course/widgets/assistant_list_view';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

class CourseInfoEditor extends React.PureComponent {
  static propTypes= {
    course: CoursePropTypes,
    handleChange: PropTypes.func,
    onSubmit: PropTypes.func,
    categoryTree: PropTypes.arrayOf(CategoryPropTypes).isRequired,
  };

  static defaultProps = {
    course: null,
    handleChange: noop,
    onSubmit: noop,
  };

  state = {
    course: {
      name: '',
      author: '',
      description: '',
      exam_type: 0,
      type: CourseTypeEnum.NORMAL.value,
      start_time: moment().format('X'),
      end_time: moment().format('X'),
      ...JSON.parse(JSON.stringify(this.props.course)),
      category: [
        get(this.props.course, 'category.parent.id', get(this.props.course, 'category.id', '')),
        get(this.props.course, 'category.id', '')
      ],
    },
  };

  componentDidMount() {
    this.props.handleChange(this.state.course);
  }

  handleTimeSubmit = (date) => {
    let start = 0;
    let end = 0;
    if (date) {
      start = date[0].format('X');
      end = date[1].format('X');
    }
    this.setState(
      {
        course: {
          ...this.state.course,
          start_time: parseFloat(start),
          end_time: parseFloat(end),
        },
      },
      () => {
        console.log(this.state.course)
        this.props.handleChange(this.state.course);
      }
    );
  };

  handleInputChange = keyName => (e) => {
    if (keyName === 'type' || keyName === 'exam_type' || keyName === 'category') {
      this.setState({
        course: {
          ...this.state.course,
          [keyName]: e,
        },
      }, () => {
        this.props.handleChange(this.state.course);
      });
    } else {
      const { target } = e;
      this.setState({
        course: {
          ...this.state.course,
          [keyName]: target.value,
        },
      }, () => {
        this.props.handleChange(this.state.course);
      });
    }
  };

  handleCollapseEditorChange = (payload) => {
    this.setState({
      course: {
        ...this.state.course,
        ...payload,
      },
    }, () => {
      this.props.handleChange(this.state.course);
    });
  };

  render() {
    const TypeTabItems = [
      CourseTypeEnum.NORMAL,
      CourseTypeEnum.PUBLIC
    ];
    const { course } = this.state;

    return (
      <div className="course_info_editor">
        <div className="editor_header">
          <div className="course_title">
            <Input
              key="name"
              value={course.name}
              onChange={this.handleInputChange('name')}
              placeholder="课程名称"
            />
          </div>
          <div className="editor_action">
            <Button type="primary" onClick={this.props.onSubmit}>提交</Button>
          </div>
        </div>
        <div className="editor_body">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="开课时间">
                <RangePicker
                  style={{ width: '100%' }}
                  defaultValue={[moment(formatTime(course.start_time, 'LONG_DATETIME'), dateFormat), moment(formatTime(course.end_time, 'LONG_DATETIME'), dateFormat)]}
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={this.handleTimeSubmit}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="课程类型" required>
                <Select value={course.type} block onChange={this.handleInputChange('type')}>
                  {TypeTabItems.map(item => (
                    <Option key={item.key} value={item.value}>{item.desc}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="课程分类" required>
                <Cascader
                  fieldNames={{ label: 'title', value: 'id', children: 'children' }}
                  options={this.props.categoryTree}
                  expandTrigger="hover"
                  onChange={this.handleInputChange('category')}
                  placeholder="请选择分类"
                  value={course.category}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={16}>
              <CourseInfoCollapse
                course={this.state.course}
                onChange={this.handleCollapseEditorChange}
              />
            </Col>
            <Col span={8}>
              <AssistantListView course={this.props.course} courseId={this.props.course.id} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default CourseInfoEditor;
