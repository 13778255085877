import { get } from 'lodash';
import {
  Axis, Chart, Coord, Geom, Label, Legend, Tooltip 
} from 'bizcharts';
import React from 'react';
import PropTypes from 'prop-types';
import DataSet from '@antv/data-set';

const { DataView } = DataSet;

// 成绩x人数 饼图
export const GradesPieChart = ({ interval, values }) => {
  const data = [];
  interval.forEach((intl, idx) => {
    const left = get(intl, '0', 0);
    const right = get(intl, '1', 0);
    const sept = (idx + 1 === interval.length) ? '≤' : '<';
    data.push({
      title: `${left} ≤ 分数 ${sept} ${right}`,
      count: values[idx],
    });
  });
  const dv = new DataView();
  dv.source(data).transform({
    type: 'percent',
    field: 'count',
    dimension: 'title',
    as: 'percent',
  });
  const cols = {
    percent: {
      formatter: (val) => {
        return `${(val * 100).toFixed(2)}%`;
      },
    },
  };
  return <Chart
    height={320}
    width={480}
    data={dv}
    scale={cols}
    padding={[10, 10, 10, 10]}
  >
    <span className="main-title" style={{ fontWeight: 'bolder', fontSize: 16 }}>
      学生成绩统计
    </span>
    <Coord type="theta" radius={0.75} />
    <Axis name="percent" />
    <Legend
      position="right"
      offsetX={-110}
      offsetY={-20}
    />
    <Tooltip
      showTitle={false}
      itemTpl="<li><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}: {value}</li>"
    />
    <Geom
      type="intervalStack"
      position="percent"
      color="title"
      tooltip={[
        'title*count',
        (title, count) => {
          const p = `${count} 人`;
          return {
            name: title,
            value: p,
          };
        }
      ]}
      style={{
        lineWidth: 1,
        stroke: '#fff',
      }}
    >
      <Label
        content="percent"
        textStyle={{
          rotate: 0,
          textAlign: 'center',
          shadowBlur: 2,
          shadowColor: 'rgba(0, 0, 0, .45)',
        }}
      />
    </Geom>
  </Chart>;
};

GradesPieChart.propTypes = {
  interval: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
};

// 成绩x人数 面积图
export const GradesDistributionAreaChart = ({ distribution, width }) => {
  const data = [];
  Object.keys(distribution).forEach((key) => {
    data.push({
      grade: key,
      count: distribution[key],
    });
  });
  const dv = new DataView();
  const cols = {
    grade: {
      type: 'linear',
      alias: '成绩',
    },
    count: {
      alias: '人数',
    },
  };
  dv.source(data);
  return <Chart
    height={190}
    width={width}
    data={dv}
    scale={cols}
    padding={[25, 50, 20, 50]}
  >
    <Axis name="count" grid={null} />
    <Tooltip
      showTitle={false}
      itemTpl="<li>得到{grade}分的有{count}人</li>"
    />
    <Legend
      custom
      position="top"
      items={[
        {
          value: '人数',
          marker: {
            symbol: 'circle',
            fill: 'l(100) 0:#a50f15 1:#fee5d9',
            radius: 5,
          },
        }
      ]}
    />
    <Geom
      tooltip={[
        'grade*count',
        (grade, count) => {
          return {
            grade,
            count,
          };
        }
      ]}
      type="area"
      position="grade*count"
      color="l(100) 0:#a50f15 1:#f7f7f7"
      opacity={0.85}
    />
  </Chart>;
};

GradesDistributionAreaChart.propTypes = {
  distribution: PropTypes.shape({}).isRequired,
  width: PropTypes.number.isRequired,
};

// 成绩 x (正确率) 散点图
export const GradesDistributionPointChart = ({ reports, width }) => {
  return <Chart
    width={width}
    height={300}
    data={reports}
  >
    <Tooltip
      showTitle={false}
      crosshairs={{
        type: 'cross',
      }}
      itemTpl="<li data-index={index} style=&quot;margin-bottom:4px;&quot;><span style=&quot;background-color:{color};&quot; class=&quot;g2-tooltip-marker&quot;></span>{name}<br/><strong>过题:</strong> {solved}(代码)<br/><strong>提交：</strong>{percent}<br /><strong>得分：</strong>{score}</li>"
    />
    <Axis name="score" />
    <Axis name="ac_percent" />
    <Geom
      type="point"
      position="score*ac_percent"
      opacity={0.65}
      shape="circle"
      size={4}
      tooltip={[
        'accepted*submission*ac_percent*stucode*stuname*solved*score',
        (accepted, submission, acPercent, stucode, stuname, solved, score) => {
          return {
            name: `${stuname} (${stucode})`,
            percent: `${accepted} / ${submission} (${acPercent}%)`,
            solved,
            score,
          };
        }
      ]}
    />
  </Chart>;
};

GradesDistributionPointChart.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  width: PropTypes.number.isRequired,
};
