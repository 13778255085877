import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, isEmpty } from 'lodash';

import {
  CheckCircleOutlined,
  CoffeeOutlined,
  DashboardOutlined,
  LoadingOutlined,
  RollbackOutlined,
  SolutionOutlined
} from '@ant-design/icons';

import { Steps, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { JudgeFlagEnum } from 'scripts/common/enums/judge';
import { JudgeStatusPropTypes } from 'scripts/common/prop-types/judge_status';
import { connect } from 'react-redux';

import { WeJudgeActions } from 'scripts/common/logic/wejudge/index';

const { Step } = Steps;

@connect((state, props) => {
  const status = props.getJudgeStatusEntitySelector(state)(props.statusId);
  return {
    status: isEmpty(status) ? {
      flag: -2,
    } : status,
  };
}, dispatch => bindActionCreators({
  viewWarnMessage: WeJudgeActions.viewWarnMessage,
}, dispatch))
class JudgeStatusPollingWidget extends React.PureComponent {
  static isPending = flag => (flag === JudgeFlagEnum.UNKNOWN.value
    || flag === JudgeFlagEnum.PENDING.value || flag === JudgeFlagEnum.RJ.value);

  static isJudging = flag => (flag === JudgeFlagEnum.JUDGING.value || flag === JudgeFlagEnum.MANUAL.value);

  static isFinished = flag => (flag > JudgeFlagEnum.UNKNOWN.value && !JudgeStatusPollingWidget.isJudging(flag)
      && !JudgeStatusPollingWidget.isPending(flag));

  static propTypes = {
    statusId: PropTypes.number.isRequired,
    getJudgeStatusEntity: PropTypes.func.isRequired,
    getJudgeStatusEntitySelector: PropTypes.func.isRequired,
    backToEditor: PropTypes.func,
    viewWarnMessage: PropTypes.func,
    onShowJudgeStatusModel: PropTypes.func.isRequired,
    onReceivedNewJudgeStatus: PropTypes.func,
    status: JudgeStatusPropTypes,
  };

  static defaultProps = {
    status: {
      flag: -2,
    },
    backToEditor: noop,
    viewWarnMessage: noop,
    onReceivedNewJudgeStatus: noop,
  };

  state = {
    waitTimeout: false,
  };

  componentDidMount() {
    if (!this.worker) {
      // 开始轮询
      this.worker = setTimeout(this.refreshJudgeStatus(0), 200);
    }
  }

  componentWillUnmount() {
    if (this.worker) {
      clearTimeout(this.worker);
      this.worker = null;
    }
  }

  refreshJudgeStatus = (times = 0) => () => {
    if (times > 10) {
      // this.props.viewWarnMessage({
      //   message: '判题机长时间没有应答，可能比较繁忙，请先返回编辑器，后续通过评测历史查看',
      // });
      this.setState({
        waitTimeout: true,
      });
      this.worker = null;
      return;
    }
    this.props.getJudgeStatusEntity({
      id: this.props.statusId,
      onComplete: () => {
        const flag = get(this.props.status, 'flag', -3);
        if (!JudgeStatusPollingWidget.isFinished(flag)) {
          // 延迟轮询
          const delay = (Math.floor(times / 2) * 2000) + 2000;
          this.worker = setTimeout(
            this.refreshJudgeStatus(times + 1),
            delay
          );
          console.info(`[评测结果轮询] 将在${delay}ms后进行第${times}次尝试`);
        } else {
          console.info('[评测结果轮询] 完成');
          this.props.onReceivedNewJudgeStatus();
        }
      },
    });
  };

  handleShowJudgeStatus = () => {
    this.props.onShowJudgeStatusModel(this.props.status);
  };

  handleBackToEditor = () => {
    this.setState({
      waitTimeout: false,
    });
    if (this.worker) {
      clearTimeout(this.worker);
      this.worker = null;
    }
    this.props.backToEditor();
  };

  render() {
    const { status } = this.props;
    const flag = this.state.waitTimeout ? -8 : get(status, 'flag', -3);
    const flagEnum = JudgeFlagEnum.enumValueOf(flag);

    return (
      <div className="judge_status_polling_view">
        <Steps>
          {/*<Step status="finish" title="提交代码" icon={<Icon type="cloud-upload-o" />} />*/}
          <Step
            status={!JudgeStatusPollingWidget.isPending(flag) ? 'finish' : 'process'}
            title="队列中"
            icon={!JudgeStatusPollingWidget.isPending(flag) ? <CoffeeOutlined /> : <LoadingOutlined />}
          />
          <Step
            status={!JudgeStatusPollingWidget.isJudging(flag) ? (JudgeStatusPollingWidget.isPending(flag) ? 'wait' : 'finish') : 'process'}
            title="评测中"
            icon={!JudgeStatusPollingWidget.isJudging(flag) ? <DashboardOutlined /> : <LoadingOutlined />}
          />
          <Step
            status={JudgeStatusPollingWidget.isFinished(flag) ? 'finish' : 'wait'}
            title="评测完成"
            icon={<CheckCircleOutlined />}
          />
        </Steps>
        <div className="status_panel">
          <h1 className="status_title" style={{ color: flagEnum.color }}>
            {flagEnum.desc}
          </h1>
          <div className="status_desc">
            {flagEnum.message}
          </div>
        </div>
        <div className="option_panel">
          <Button
            onClick={this.handleBackToEditor}
          >
            <RollbackOutlined /> 返回编辑器
          </Button>
          <Button
            disabled={!JudgeStatusPollingWidget.isFinished(flag)}
            type="primary"
            onClick={this.handleShowJudgeStatus}
          >
            <SolutionOutlined /> 查看评测详情
          </Button>
        </div>
      </div>
    );
  }
}

export default JudgeStatusPollingWidget;
