import React from 'react';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';
import {
  Form, Modal, Spin, Input, Button, Divider, Row, Col, Select 
} from 'antd';
import { AnnouncementPropTypes } from 'scripts/common/prop-types/course';
import { bindActionCreators } from 'redux';
import {
  get, isEmpty, isEqual, noop, set 
} from 'lodash';
import { CourseActions, CourseSelectors } from 'scripts/common/logic/education/course';
import connect from 'react-redux/es/connect/connect';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { enumMap } from 'scripts/common/utils/enum_generator';
import { CourseAnnouncementLevelEnum } from 'scripts/common/enums/course';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';

const MODEL_TITLE = {
  edit: '编辑公告',
  new: '新增公告',
};

const LOADING_STATUS_KEY = 'course_announcement_detail';

const mapDispatchToProps = dispatch => bindActionCreators({
  getCourseAnnouncement: CourseActions.getCourseAnnouncement,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  addCourseAnnouncement: CourseActions.addCourseAnnouncement,
  modifyCourseAnnouncement: CourseActions.modifyCourseAnnouncement,
}, dispatch);

const mapStateToProps = (state, props) => {
  const loading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const announcement = CourseSelectors.getCourseAnnouncementEntity(state)(props.announcementId);
  return {
    loading,
    announcement,
  };
};

@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class AnnouncementView extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    announcementId: PropTypes.number,
    mode: PropTypes.string,
    announcement: AnnouncementPropTypes,
    getCourseAnnouncement: PropTypes.func,
    loading: PropTypes.bool,
    onOk: PropTypes.func,
    setLoadingStatus: PropTypes.func,
    addCourseAnnouncement: PropTypes.func,
    modifyCourseAnnouncement: PropTypes.func,
    hasManageCoursePermission: PropTypes.bool,
    listQuery: PropTypes.shape({}),
  };

  static defaultProps = {
    mode: 'view',
    loading: false,
    hasManageCoursePermission: false,
    announcement: null,
    onOk: noop,
    announcementId: 0,
    setLoadingStatus: noop,
    getCourseAnnouncement: noop,
    addCourseAnnouncement: noop,
    modifyCourseAnnouncement: noop,
    listQuery: {},
  };

  state = {
    submitting: false,
    mode: this.props.mode,
    fetchAnnouncement: false,
    announcementRaw: null,
    announcement: {
      title: '',
      level: 1,
      content: '',
    },
    content_editor: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.mode === 'view' && !prevState.fetchAnnouncement) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getCourseAnnouncement({
        courseId: nextProps.courseId,
        id: nextProps.announcementId,
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        fetchAnnouncement: true,
      };
    }
    if (nextProps.mode !== 'new' && !isEqual(nextProps.announcement, prevState.announcementRaw)) {
      return {
        announcementRaw: nextProps.announcement,
        announcement: JSON.parse(JSON.stringify(nextProps.announcement)),
      };
    }
    return null;
  }

  handleEditMode = () => {
    this.setState({
      mode: 'edit',
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState(set({ announcement: { ...this.state.announcement } }, `announcement.${keyName}`, target.value));
  };

  handleInputChangeByValue = keyName => (value) => {
    this.setState(set({ announcement: { ...this.state.announcement } }, `announcement.${keyName}`, value));
  };

  handleEditorChange = (text) => {
    this.setState(set({
      announcement: { ...this.state.announcement },
    }, 'announcement.content', text));
  };

  handleInputChangeBE = keyName => (es) => {
    this.setState(set({
      announcement: { ...this.state.announcement },
      [`${keyName}_editor`]: es,
    }, `announcement.${keyName}`, es.toHTML()));
  };

  handleOnOk = () => {
    this.setState({
      submitting: true,
    }, () => {
      if (this.state.mode === 'new') {
        this.props.addCourseAnnouncement({
          courseId: this.props.courseId,
          announcement: this.state.announcement,
          query: this.props.listQuery,
          onSuccess: () => {
            this.props.onOk();
          },
          onError: () => {
            this.setState({
              submitting: false,
            });
          },
        });
      } else if (this.state.mode === 'edit') {
        this.props.modifyCourseAnnouncement({
          courseId: this.props.courseId,
          announcementId: this.props.announcementId,
          announcement: this.state.announcement,
          onSuccess: () => {
            this.props.onOk();
          },
          onError: () => {
            this.setState({
              submitting: false,
            });
          },
        });
      } else {
        this.props.onOk();
      }
    });
    return false;
  };

  renderTitle = () => {
    const { announcement } = this.state;
    return (
      <span>
        {get(MODEL_TITLE, this.props.mode) || get(announcement, 'title') || '公告详情'}
        {this.props.hasManageCoursePermission && this.state.mode === 'view' && <Button
          size="small"
          type="primary"
          style={{ marginLeft: 16 }}
          onClick={this.handleEditMode}
          disabled={this.props.loading || this.state.mode !== 'view'}
        >
          <EditOutlined />编辑公告
        </Button>}
      </span>
    );
  };

  render() {
    const { announcement } = this.state;
    return <Modal
      {...this.props}
      maskClosable={false}
      className="course-announcement-dialog"
      confirmLoading={this.state.submitting}
      title={this.renderTitle()}
      onOk={this.handleOnOk}
      footer={this.state.mode !== 'view' ? undefined : false}
    >
      {this.state.mode === 'view' ? <Spin spinning={this.props.loading}>
        {!isEmpty(announcement) && <>
          <div className="meta-content">作者：{get(announcement, 'author.realname', '未知')}，发布时间：{formatTime(get(announcement, 'create_time', 0), 'LONG_DATETIME')}，修改时间：{formatTime(get(announcement, 'update_time', 0), 'LONG_DATETIME')}</div>
          <RichtextContext>{get(announcement, 'content', '')}</RichtextContext>
        </>}
      </Spin> : <div>
        <Form.Item>
          <Row gutter={16}>
            <Col span={20}>
              <Input
                value={get(this.state, 'announcement.title', '')}
                placeholder="公告标题"
                onChange={this.handleInputChange('title')}
              />
            </Col>
            <Col span={4}>
              <Select
                style={{ width: '100%' }}
                placeholder="请选择消息等级"
                value={get(this.state, 'announcement.level', 1)}
                onChange={this.handleInputChangeByValue('level')}
              >
                {enumMap(CourseAnnouncementLevelEnum, (e) => {
                  return <Select.Option key={e.key} value={e.value}>{e.desc}</Select.Option>;
                })}
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <MarkdownEditor
            onChange={this.handleEditorChange}
            defaultValue={get(this.state, 'announcement.content', '')}
          />
        </Form.Item>
      </div>}
    </Modal>;
  }
}

export default AnnouncementView;
