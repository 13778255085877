import React from 'react';
import PropTypes from 'prop-types';
import {
  get, noop, isEqual, isEmpty 
} from 'lodash';
import { Spin, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AsgnRoutes, CourseRoutes } from 'scripts/apps/routes';
import { bindActionCreators } from 'redux';
import { AsgnProblemPropTypes, AsgnPropTypes, AsgnReportPropTypes } from 'scripts/common/prop-types/asgn';
import {
  AsgnProblemsSelectors,
  AsgnReportSelectors,
  AsgnSolutionSelectors
} from 'scripts/common/logic/education/asgn/selectors';
import { showModal } from 'scripts/common/widgets/modal';
import { AsgnSolutionActions, AsgnReportActions } from 'scripts/common/logic/education/asgn/actions';
import DataKeys from 'scripts/common/constants/data_keys';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import StudentReport from './widgets/asgn_report_widgets';
import ResubmitInfoModal from './widgets/resubmit_info';

const LOADING_STATUS_KEY = 'asgn_report_view';
const LOADING_STATUS_KEY2 = 'asgn_report_list';

@withRouter
@connect((state, props) => {
  const { match } = props;
  const report = AsgnReportSelectors.getAsgnReportEntity(state)(get(match, 'params.reportId', 0));
  const reportId = get(match, 'params.reportId', 0);
  const reportList = AsgnReportSelectors.getAsgnReportsList(DataKeys.TEACHER)(state);
  let reportIdIndex = null;
  if (reportList.length !== 0) {
    if (reportList.length === 1) {
      reportIdIndex = 2;
    } else if (parseInt(reportId, 10)
      === parseInt(reportList[reportList.length - 1].id, 10)) {    // 1 代表室最后一个 0 代表第一个 2代表只有一个
      reportIdIndex = 1;
    } else if (parseInt(reportId, 10) === parseInt(reportList[0].id, 10)) {
      reportIdIndex = 0;
    }
  }
  const normalSolutions =    AsgnSolutionSelectors.getAsgnProblemSolutions(DataKeys.NORMAL_PROBLEMS)(state)();
  const codeSolutions =    AsgnSolutionSelectors.getAsgnProblemSolutions(DataKeys.CODE_PROBLEMS)(state)();
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const isListLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY2);
  const codeProblemsDatas = AsgnProblemsSelectors.getAsgnCodeProblemDatas(state);
  const objectiveProblemsDatas = AsgnProblemsSelectors.getAsgnObjectiveProblemDatas(state);
  return {
    report,
    reportId,
    isLoading,
    isListLoading,
    reportIdIndex,
    codeSolutions,
    normalSolutions,
    codeProblemsDatas,
    objectiveProblemsDatas,
    reportList,
  };
}, dispatch => bindActionCreators({
  getCodeProblemSolutions: AsgnSolutionActions.getCodeProblemSolutions,
  getNormalProblemSolutions: AsgnSolutionActions.getNormalProblemSolutions,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getStudentReportsList: AsgnReportActions.getStudentReportsList,
  modifyStudentReport: AsgnReportActions.modifyStudentReport,
  deleteStudentReport: AsgnReportActions.deleteStudentReport,
  resubmitStudentReport: AsgnReportActions.resubmitStudentReport,
  viewWarnMessage: WeJudgeActions.viewWarnMessage,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
}, dispatch))
class StudentReportView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    asgn: AsgnPropTypes.isRequired,
    report: AsgnReportPropTypes,
    normalSolutions: PropTypes.shape({}),
    codeSolutions: PropTypes.shape({}),
    codeProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    objectiveProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    isLoading: PropTypes.bool,
    isListLoading: PropTypes.bool,
    setLoadingStatus: PropTypes.func,
    getCodeProblemSolutions: PropTypes.func,
    getStudentReportsList: PropTypes.func,
    modifyStudentReport: PropTypes.func,
    reportList: PropTypes.arrayOf(AsgnReportPropTypes),
    viewWarnMessage: PropTypes.func,
    deleteStudentReport: PropTypes.func,
    resubmitStudentReport: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    reportIdIndex: PropTypes.number,
    getNormalProblemSolutions: PropTypes.func,
  };

  static defaultProps = {
    report: null,
    isLoading: false,
    isListLoading: false,
    codeSolutions: null,
    normalSolutions: null,
    setLoadingStatus: noop,
    getCodeProblemSolutions: noop,
    getStudentReportsList: noop,
    modifyStudentReport: noop,
    reportList: null,
    viewWarnMessage: noop,
    deleteStudentReport: noop,
    viewSuccessMessage: noop,
    resubmitStudentReport: noop,
    reportIdIndex: null,
    getNormalProblemSolutions: noop,
  };

  state = {
    hasFetchedReport: false,
    report: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchedReport && !nextProps.report) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY2, true);
      nextProps.getStudentReportsList({
        courseId: nextProps.courseId,
        asgnId: nextProps.asgnId,
        mode: 'all',
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY2, false);
        },
      });
      return {
        hasFetchedReport: true,
      };
    }
    if (!isEqual(prevState.report, nextProps.report) && nextProps.report) {
      if (!nextProps.report) {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        return null;
      }
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getCodeProblemSolutions({
        courseId: nextProps.courseId,
        asgnId: nextProps.asgnId,
        authorId: get(nextProps, 'report.author.id'),
        onComplete: () => {
          nextProps.getNormalProblemSolutions({
            courseId: nextProps.courseId,
            asgnId: nextProps.asgnId,
            author_id: get(nextProps, 'report.author.id'),
            onComplete: () => {
              nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
            },
          });
        },
      });
      return {
        ...prevState,
        report: nextProps.report,
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.state.hasFetchedReport && !this.props.isLoading
      && !this.props.isListLoading && isEmpty(this.props.report)) {
      this.props.history.push(buildPath(
        AsgnRoutes.CHECKUP,
        { courseId: this.props.courseId, asgnId: this.props.asgnId }
      ));
    }
  }

  onCheckupClick = (e) => {
    const { checkup, report } = e;
    this.props.modifyStudentReport({
      courseId: this.props.courseId,
      asgnId: this.props.asgnId,
      reportId: this.props.report.id,
      body: {
        ...checkup,
        impressions: report.impressions,
      },
      onComplete: () => {
        this.setState({
          hasFetchProblemSolutions: false,
        });
      },
    });
  };

  onPrevClick = (id) => {
    let reportId = null;
    this.props.reportList.forEach((item, key) => {
      if (item.id === id && key > 0) {
        reportId = this.props.reportList[key - 1].id;
      }
    });
    const { history } = this.props;
    history.push(buildPath(
      AsgnRoutes.CHECKUP_TEACHER,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        reportId,
      }
    ));
  };

  onNextClick = (id) => {
    let reportId = null;
    this.props.reportList.forEach((item, key) => {
      if (item.id === id && key < this.props.reportList.length - 1) {
        reportId = this.props.reportList[key + 1].id;
      }
    });
    const { history } = this.props;
    history.push(buildPath(
      AsgnRoutes.CHECKUP_TEACHER,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        reportId,
      }
    ));
  };

  onResubmitClick = (id) => {
    const modalBox = showModal(ResubmitInfoModal, {
      width: 400,
      onOk: (modal, state) => {
        this.props.resubmitStudentReport({
          courseId: this.props.courseId,
          asgnId: this.props.asgnId,
          reportId: id,
          data: state,
          onSuccess: (result) => {
            modalBox.close();
            if (result) {
              this.props.viewSuccessMessage({
                message: '重置作业状态成功',
              });
            }
            this.setState({
              hasFetchProblemSolutions: false,
            });
          },
        });
      },
    });
  };

  onDelReport = (id) => {
    const { history } = this.props;
    this.props.deleteStudentReport({
      courseId: this.props.courseId,
      asgnId: this.props.asgnId,
      reportId: id,
      onSuccess: () => {
        this.props.viewSuccessMessage({
          message: '删除实验报告成功',
        });
        history.push(buildPath(
          AsgnRoutes.CHECKUP,
          {
            courseId: this.props.courseId,
            asgnId: this.props.asgnId,
          }
        ));
      },
    });
  };

  onShowDelModal = (id) => {
    Modal.confirm({
      title: '删除实验报告',
      content: '您确定要删除该实验报告吗？',
      okTest: '确定',
      okType: 'danger',
      cancelText: '取消',
      onOk: () => {
        this.onDelReport(id);
      },
    });
  };

  render() {
    if (isEmpty(this.props.report)) return null;
    const {
      report,
      asgn,
      isLoading,
      codeSolutions,
      normalSolutions,
      codeProblemsDatas,
      objectiveProblemsDatas,
    } = this.props;
    return <div className="asgn_report_view">
      {report && <StudentReport
        asgn={asgn}
        report={report}
        codeSolutions={codeSolutions}
        normalSolutions={normalSolutions}
        codeProblemsDatas={codeProblemsDatas}
        objectiveProblemsDatas={objectiveProblemsDatas}
        teacherMode
        onCheckupClick={this.onCheckupClick}
        onNextClick={this.onNextClick}
        onDelClick={this.onShowDelModal}
        onResubmitClick={this.onResubmitClick}
        reportIdIndex={this.props.reportIdIndex}
        onPrevClick={this.onPrevClick}
        spinning={isLoading}
      />}

    </div>;
  }
}

export default StudentReportView;
