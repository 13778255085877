import 'styles/contest/support/faqs.scss';

import React from 'react';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import {
  Comment, Button, Switch, Modal, Tag, Empty, Input,
  Typography, Tooltip, PageHeader, Spin, Avatar, Space
} from 'antd';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { buildAvatarPath, buildPath } from 'scripts/common/utils/location_helper';
import {
  get, isEmpty, noop, debounce
} from 'lodash';
import { ContestSupportActions, ContestSupportSelectors } from 'scripts/common/logic/contest/support';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { formatTime, formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import { ContestRoutes } from 'scripts/apps/routes';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';
import { filterXss } from 'scripts/common/utils/filter';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import RichtextContext from 'scripts/common/widgets/richtext_context';

const { Paragraph } = Typography;

const mapStateToProps = (state, props) => {
  const canManage = props.account.role === 2;
  const faqSelector = ContestSupportSelectors.contestFaqsEntitiesSelector(state);
  const faqId = parseInt(get(props.match, 'params.faqId', '0'), 10);
  const faqEntity = faqSelector(faqId);
  return {
    faqId,
    canManage,
    faqEntity,
    faqsReplyData: ContestSupportSelectors.getContestFaqsReplyListData(faqId)(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  delFaq: ContestSupportActions.delFaq,
  getFaq: ContestSupportActions.getFaq,
  editFaq: ContestSupportActions.editFaq,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getFaqsReplyList: ContestSupportActions.getFaqsReplyList,
  addFaqsReply: ContestSupportActions.addFaqsReply,
  delFaqReply: ContestSupportActions.delFaqReply,
}, dispatch);

@withContestContext
@ContestLoginRequire
@connect(mapStateToProps, mapDispatchToProps)
class FaqView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    getFaq: PropTypes.func,
    getFaqsReplyList: PropTypes.func,
    delFaq: PropTypes.func,
    editFaq: PropTypes.func,
    setLoadingStatus: PropTypes.func,
    addFaqsReply: PropTypes.func,
    delFaqReply: PropTypes.func,
    canManage: PropTypes.bool.isRequired,
    account: AccountPropTypes.isRequired,
    contest: ContestPropTypes.isRequired,
    faqId: PropTypes.number.isRequired,
    faqEntity: PropTypes.shape({}),
    faqsReplyData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  static defaultProps = {
    faqEntity: null,
    getFaq: noop,
    editFaq: noop,
    delFaq: noop,
    setLoadingStatus: noop,
    getFaqsReplyList: noop,
    addFaqsReply: noop,
    delFaqReply: noop,
  };

  state = {
    addFaq: true,
    showReplyEditor: true,
    // faq: {},
    reply: {
      content: '',
    },
    editorFocus: false,
    isFetched: false,
    isLoadingReply: false,
    publicProcessing: false,
  };

  componentDidMount() {
    if (isEmpty(this.props.faqEntity)) {
      this.getReply();
    } else {
      this.setState({ // eslint-disable-line
        isFetched: true,
      }, () => {
        this.getReplyList();
      });
    }
    window.jQuery(window).on('hashchange', this.handleHashChange);
  }

  componentDidUpdate() {
    if (!this.state.isFetched && isEmpty(this.props.faqEntity)) {
      this.getReply();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    window.jQuery(window).off('hashchange');
  }

  editorRef = React.createRef();

  unmounted = false;

  getReply = () => {
    this.setState({
      isFetched: false,
    }, () => {
      this.props.getFaq({
        contestId: this.props.contest.id,
        faqId: this.props.faqId,
        onSuccess: () => {
          this.setState({
            isFetched: true,
          }, () => {
            this.getReplyList();
          });
        },
        onComplete: () => {
          this.setState({
            isFetched: true,
          });
        },
      });
    });
  };

  getReplyList = () => {
    if (this.state.isLoadingReply || !this.state.isFetched) return;
    this.setState({
      isLoadingReply: true,
    }, () => {
      this.props.getFaqsReplyList({
        contestId: this.props.contest.id,
        faqId: this.props.faqId,
        onComplete: () => {
          this.setState({
            isLoadingReply: false,
          });
          this.handleHashChange();
        },
      });
    });
  };

  hideReplyEditor = () => this.setState({ editorFocus: false });

  handleHashChange = () => {
    const hash = window.location.hash.replace('#', '');
    const item = window.jQuery(`.reply-item-${hash}`);
    if (item.length) {
      const layout = window.jQuery('.contest-layout-content')[0];
      if (layout) { layout.scrollTop = get(item.offset(), 'top', 0); }
    }
  };

  handleSwitchChange = (checked) => {
    this.setState({
      publicProcessing: true,
    }, () => {
      this.props.editFaq({
        contestId: this.props.contest.id,
        faqId: this.props.faqId,
        public: checked,
        onComplete: () => {
          this.setState({
            publicProcessing: false,
          });
        },
      });
    });
  };

  handleAddReplyClick = () => {
    this.props.addFaqsReply({
      contestId: this.props.contest.id,
      content: this.state.reply.content,
      faqId: this.props.faqId,
      onSuccess: () => {
        this.setState({
          reply: {
            content: '',
          },
          editorFocus: false,
        });
        this.getReplyList();
      },
    });
  };

  handleDelClick = () => {
    Modal.confirm({
      title: '删除提问',
      content: '您确认要删除此提问吗',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.props.delFaq({
          contestId: this.props.contest.id,
          faqId: this.props.faqId,
          onSuccess: () => {
            this.props.history.push(buildPath(ContestRoutes.SUPPORT.FAQ, {
              contestId: this.props.contest.id,
            }));
          },
        });
      },

    });
  };

  handleDelReplyClick = item => () => {
    Modal.confirm({
      title: '删除回复',
      content: '您确认要删除此回复吗',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.props.delFaqReply({
          contestId: this.props.contest.id,
          faqId: this.props.faqId,
          replyId: item.id,
          onSuccess: () => {
            this.getReplyList();
          },
        });
      },
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      ...this.state,
      reply: {
        ...this.state.reply,
        [keyName]: target.value,
      },
    });
  };

  handleEditorChange = debounce((text) => {
    this.setState({
      reply: {
        content: text,
      },
    });
  }, 20);

  handleBack = () => {
    this.props.history.push(buildPath(
      ContestRoutes.SUPPORT.FAQ,
      {
        contestId: this.props.contest.id,
      }
    ));
  };

  renderExtraButtons = () => {
    const ret = [];
    const isReferee = get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
    const isMine = get(this.props.account, 'id') === get(this.props.faqEntity, 'author.id');
    if (isReferee || isMine) {
      if (isReferee) {
        ret.push(<Switch
          key="public"
          checkedChildren="公开"
          unCheckedChildren="公开"
          loading={this.state.publicProcessing}
          checked={get(this.props.faqEntity, 'public', false)}
          onChange={this.handleSwitchChange}
        />);
      }
      ret.push(<Button
        key="delete"
        type="danger"
        onClick={this.handleDelClick}
      >
        删除
      </Button>);
    }
    return ret;
  };

  render() {
    const isReferee = get(this.props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
    const { faqEntity } = this.props;
    const isPublic = get(faqEntity, 'public', false);
    if (!this.state.isFetched) {
      return <div className="contest-faqs contest-faqs-view" style={{ textAlign: 'center', padding: '16px' }}>
        <Spin tip="加载中..." />
      </div>;
    }
    if (isEmpty(this.props.faqEntity)) {
      return <WeJudge404NotFoundComponent noBorder />;
    }
    return (
      <div className="contest-faqs contest-faqs-view">
        <PageHeader
          title={get(faqEntity, 'title', '无标题')}
          tags={<Tag color={isPublic ? 'green' : 'blue'}>{isPublic ? '公开问答' : '私密问答'}</Tag>}
          onBack={this.handleBack}
          extra={this.renderExtraButtons()}
        />
        <div className="body-layout">
          <div className="faqs-content">
            <Paragraph>
              <RichtextContext content={filterXss(get(faqEntity, 'content'))} />
            </Paragraph>
            <Paragraph>
              <Typography.Text type="secondary">
                <Space>
                  <Avatar size={16} icon={<UserOutlined />} src={get(faqEntity, 'author.avator') ? buildAvatarPath(get(faqEntity, 'author.avator')) : null} />
                  <span>{get(faqEntity, 'author.nickname', '')} ({get(faqEntity, 'author.username', '')})</span>
                  <span>发表于</span>
                  <Tooltip title={formatTime(get(faqEntity, 'create_time', 0), 'LONG_DATETIME')}>{formatTimeFromNow(get(faqEntity, 'create_time', 0))}</Tooltip>
                </Space>
              </Typography.Text>
            </Paragraph>
          </div>
          <div className="reply-layout">
            {this.props.faqsReplyData.length ? this.props.faqsReplyData.map((it) => {
              const replyId = get(it, 'id');
              return (
                <Comment
                  className={`reply-item-${replyId}`}
                  key={replyId}
                  actions={this.props.canManage ? [
                    <span onClick={this.handleDelReplyClick(it)}><DeleteOutlined />删除</span>
                  ] : []}
                  avatar={<Avatar icon={<UserOutlined />} src={get(it, 'author.avator') ? buildAvatarPath(get(it, 'author.avator')) : null} />}
                  author={<>{get(it, 'author.nickname', '')}{get(it, 'author.username', '') && `(${get(it, 'author.username', '')})`}</>}
                  datetime={<Tooltip title={formatTime(get(it, 'create_time', 0), 'LONG_DATETIME')}>
                    {formatTimeFromNow(get(it, 'create_time', 0))}
                  </Tooltip>}
                  content={<RichtextContext content={filterXss(it.content)} />}
                />
              );
            }) : <Empty description="暂无回复" image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: 16 }} />}
          </div>
        </div>
        {(isReferee || get(this.props.account, 'id') === get(faqEntity, 'author.id')) && <>
          {this.state.editorFocus ? <div className="editor-layout editor-layout-full-height">
            <MarkdownEditor
              ref={this.editorRef}
              height="100%"
              config={{
                view: {
                  menu: true,
                  md: true,
                  html: true,
                },
              }}
              disablePlugins={['upload-image']} // 比赛不支持选手上传图片的，考虑到一般来比赛里没办法登录WJ系统，上传会失败
              onChange={this.handleEditorChange}
              defaultValue={get(this.state, 'reply.content', '')}
            />
            <div className="action-bar">
              <Button type="primary" onClick={this.handleAddReplyClick}>
                回复
              </Button>
              <Button type="danger" onClick={this.hideReplyEditor}>
                取消
              </Button>
            </div>
          </div> : <div className="editor-layout">
            <Input.TextArea
              placeholder="点击此处输入回复"
              onFocus={() => {
                this.setState({ editorFocus: true }, () => {
                  this.editorRef.current.focus();
                });
              }}
            />
          </div>}
        </>}
      </div>
    );
  }
}

export default FaqView;
