import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, noop, isEqual } from 'lodash';
import { EditOutlined, FileOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Spin, Drawer, Typography } from 'antd';
import { LessonSelectors } from 'scripts/common/logic/education/lesson/selector';
import { bindActionCreators } from 'redux';
import { LessonActions } from 'scripts/common/logic/education/lesson';
import { LessonPropTypes } from 'scripts/common/prop-types/course';
import VideoPlayer from 'scripts/apps/education/lesson/widgets/video_player';
import LessonAsgnList from 'scripts/apps/education/lesson/widgets/lesson_asgn_list';
import CourseNoteBook from 'scripts/apps/education/lesson/widgets/notebook';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import OfficePlayer from 'scripts/apps/education/lesson/widgets/office_player';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import LessonResourcesList from 'scripts/apps/education/lesson/widgets/lesson_resources_list';

const LOADING_STATUS_KEY = 'course_lesson_body_loading';

const { Title, Paragraph } = Typography;

const mapStateToProps = (state, props) => {
  const lessonId = get(props, 'lessonId', '');
  const lesson = LessonSelectors.getLessonEntity(state)(lessonId);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  return {
    isLoading,
    lessonId,
    lesson,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getLessonVideo: LessonActions.getLessonVideo,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getLessonResources: LessonActions.getLessonResources,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
class LessonBodyView extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    lessonId: PropTypes.string,
    lesson: LessonPropTypes.isRequired,
    getLessonVideo: PropTypes.func,
    setLoadingStatus: PropTypes.func,
    getLessonResources: PropTypes.func,
  };

  static defaultProps = {
    lessonId: '',
    isLoading: false,
    getLessonVideo: noop,
    setLoadingStatus: noop,
    getLessonResources: noop,
  };

  state = {
    currentLesson: null,
    mode: 'text',
    drawerMode: 'hidden',
    resType: '',
    resId: null,
  };

  static getDerivedStateFromProps(nextProps, prevState)  {
    if (!isEqual(get(nextProps.lesson, 'id'), get(prevState.currentLesson, 'id'))) {
      const hasVideo = !!get(nextProps, 'lesson.video', null);
      const hasResFile = (get(nextProps, 'lesson.default_exhibition', '') !== '');
      let mode = 'text';
      let resType = '';
      let resId = null;
      if (hasVideo) {
        mode = 'video'; // 视频模式
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
        nextProps.getLessonVideo({
          courseId: nextProps.courseId,
          lessonId: nextProps.lessonId,
          download: '1',
          onSuccess: () => {
            nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
          },
        });
      } else if (hasResFile) {
        mode = 'resource';
        const resFile = get(nextProps, 'lesson.default_exhibition', '').split(':');
        resType = get(resFile, '0', '');
        resId = get(resFile, '1', '');
      }
      return {
        mode,
        resId,
        resType,
        currentLesson: nextProps.lesson,
      };
    }
    return null;
  }
  //
  // handleChangeOfficeResource = ({ officeType, officeId }) => {
  //   this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
  //   this.setState({
  //     officeType,
  //     officeId,
  //     drawerMode: 'hidden',
  //     mode: 'resource',
  //   }, () => {
  //     this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
  //   });
  // };

  handleShowAsgnDrawer = () => {
    this.setState({
      drawerMode: 'asgn',
    });
  };

  handleShowNoteDrawer = () => {
    this.setState({
      drawerMode: 'note',
    });
  };

  handleShowResourceDrawer = () => {
    this.setState({
      drawerMode: 'resource',
    });
  };

  handleDrawerClose = () => {
    this.setState({
      drawerMode: 'hidden',
    });
  };

  renderVideoPlayer = () => {
    return <VideoPlayer
      lessonId={this.props.lessonId}
      lesson={this.props.lesson}
      getLessonVideo={this.props.getLessonVideo}
      courseId={this.props.courseId}
    />;
  };

  renderOfficePlayer = () => {
    return <OfficePlayer
      lessonId={this.props.lessonId}
      courseId={this.props.courseId}
      officeType={this.state.resType}
      officeId={this.state.resId}
    />;
  };

  renderResourcePlayer = () => {
    const { resType } = this.state;
    if (resType === 'doc' || resType === 'xls' || resType === 'ppt') {
      return this.renderOfficePlayer();
    }
    return null;
  };

  renderDescPlayer = () => {
    return <div className="lesson_introduction">
      <Typography>
        <Title level={4}>课堂介绍</Title>
        <Paragraph>
          <RichtextContext className="desc_html" content={get(this.props.lesson, 'description')} viewEmpty />
        </Paragraph>
      </Typography>
    </div>;
  };

  render() {
    if (!this.props.lesson || this.props.isLoading) {
      return <div className="lesson_body_view">
        <Spin className="body-loading" tip="载入中" />
      </div>;
    }
    let openDrawer = true;
    let drawerTitle = '';
    let drawerWidth = 520;
    if (this.state.drawerMode === 'asgn') {
      drawerTitle = '课堂作业';
    } else if (this.state.drawerMode === 'note') {
      drawerTitle = '随堂笔记';
      drawerWidth = 720;
    } else if (this.state.drawerMode === 'resource') {
      drawerTitle = '课件资源';
    } else {
      openDrawer = false;
    }
    return (
      <div className="lesson_body_view">
        {this.state.mode === 'video' && this.renderVideoPlayer()}
        {this.state.mode === 'resource' && this.renderResourcePlayer()}
        {this.state.mode === 'text' && this.renderDescPlayer()}
        <div className="functional-buttons">
          <div className="button" onClick={this.handleShowAsgnDrawer}>
            <ScheduleOutlined />作业
          </div>
          <div className="button" onClick={this.handleShowNoteDrawer}>
            <EditOutlined />笔记
          </div>
          <div className="button" onClick={this.handleShowResourceDrawer}>
            <FileOutlined />资源
          </div>
        </div>
        <Drawer
          title={drawerTitle}
          width={drawerWidth}
          placement="right"
          destroyOnClose
          onClose={this.handleDrawerClose}
          visible={openDrawer}
          getContainer={false}
          className="lesson_drawer"
        >
          {this.state.drawerMode === 'asgn' && <LessonAsgnList
            courseId={this.props.courseId}
            lesson={this.props.lesson}
          />}
          {this.state.drawerMode === 'note' && <CourseNoteBook
            courseId={this.props.courseId}
            lessonId={this.props.lessonId}
          />}
          {this.state.drawerMode === 'resource' && <LessonResourcesList
            courseId={this.props.courseId}
            lesson={this.props.lesson}
            lessonId={this.props.lessonId}
            // onChangeOfficeResource={this.handleChangeOfficeResource}
          />}
        </Drawer>
      </div>
    );
  }
}

export default LessonBodyView;
