import 'styles/contest/organization/manage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { buildPath } from 'scripts/common/utils/location_helper';
import { Menu } from 'antd';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import {  get, noop } from 'lodash';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import ContestManagePermissionCheck from 'scripts/apps/contest/manage_permission';
import { ContestRoutes } from 'scripts/apps/routes';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { bindActionCreators } from 'redux';
import { ContestOrganizationActions } from 'scripts/common/logic/contest/organization';
import OrgList from './list';
import OrgGroupList from './groups';

const mapStateToProps = (state, props) => {
  const { match } = props;
  return {
    subItemKey: get(match, 'params.subItemKey', 'list'),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrganizationGroupList: ContestOrganizationActions.getOrganizationGroupList,
}, dispatch);

@ContestLoginRequire
@ContestManagePermissionCheck()
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class OrganizationManageView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    contest: ContestPropTypes.isRequired,
    subItemKey: PropTypes.string,
    getOrganizationGroupList: PropTypes.func,
  };

  static defaultProps = {
    subItemKey: 'list',
    getOrganizationGroupList: noop,
  };

  state = {

  };

  componentDidMount() {
    this.props.getOrganizationGroupList({
      contestId: this.props.contest.id,
    });
  }

  handleTabChange = ({ key }) => {
    const { history, match } = this.props;
    history.push(buildPath(
      ContestRoutes.MANAGE.ORGANIZATION.SUBITEMS,
      {
        contestId: this.props.contest.id.toString(),
        subItemKey: key,
      },
      match.params
    ));
  };

  render() {
    return (<div className="contest-organization-view">
      <Menu onSelect={this.handleTabChange} selectedKeys={[this.props.subItemKey]} mode="horizontal">
        <Menu.Item key="list">队伍管理</Menu.Item>
        <Menu.Item key="groups">分组管理</Menu.Item>
      </Menu>
      <Switch>
        <Route
          path={ContestRoutes.MANAGE.ORGANIZATION.LIST}
          component={OrgList}
        />
        <Route
          path={ContestRoutes.MANAGE.ORGANIZATION.GROUPS}
          component={OrgGroupList}
        />
        <Route
          render={() => <Redirect
            to={buildPath(
              ContestRoutes.MANAGE.ORGANIZATION.LIST,
              { contestId: this.props.contest.id }
            )}
          />}
        />
      </Switch>
    </div>);
  }
}

export default OrganizationManageView;
