import React from 'react';
import PropTypes from 'prop-types';
import {
  List, Tag, Skeleton, Empty
} from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { buildPath } from 'scripts/common/utils/location_helper';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { AnnounceRoutes } from 'scripts/apps/routes';
import { AnnouncementLevelEnum } from 'scripts/common/enums/announcement';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import RichtextContext from 'scripts/common/widgets/richtext_context';

const AnnounceList = (props) => {
  const { 
    data,
    loading,
    history,
  } = props;

  const handleTitleClick = (id) => {
    history.push(buildPath(AnnounceRoutes.BODY_VIEW, { aid: id }));
  };

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={data}
      size="large"
      locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无公告" /> }}
      renderItem={item => (
        <List.Item>
          <Skeleton title={false} loading={!item} active>
            <List.Item.Meta
              title={<a
                className={classnames({ 'expired-title': get(item, 'is_expired', false) })}
                onClick={() => handleTitleClick(item.id)}
              >
                {item.title}
              </a>}
              description={<RichtextContext
                textMode
                truncateLength={50}
                content={get(item, 'content')}
              />}
            />
            <div className="announcement-list-extra">
              <LevelTag level={item.level} />
              <div style={{ margin: '0 16px' }}>{moment(get(item, 'update_time', '') * 1000).format('YYYY-MM-DD HH:mm')}</div>
            </div>
          </Skeleton>
        </List.Item>
      )}
    />
  );
};

AnnounceList.propTypes = {
  ...RoutePropTypes,
  data: PropTypes.shape([]),
};

AnnounceList.defaultProps = {
  data: [],
};

export default withRouter(AnnounceList);

export const LevelTag = (props) => {
  const { level } = props;
  switch (level) {
    case AnnouncementLevelEnum.SECONDARY.value:
      return <Tag color="blue">重要</Tag>;
    case AnnouncementLevelEnum.FIRST.value:
      return <Tag color="red">紧急</Tag>;
    default:
      return null;
  }
};

LevelTag.propTypes = {
  level: PropTypes.number,
};

LevelTag.defaultProps = {
  level: 0,
};
