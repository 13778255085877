import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Modal } from 'antd';
import { noop } from 'lodash';
import { AccountActions } from 'scripts/common/logic/account/action';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AccountRoutes } from 'scripts/apps/routes';
import { WeJudgeSelectors } from 'scripts/common/logic/wejudge/selector';
import { WeJudgeActions } from 'scripts/common/logic/wejudge/action';
import DataKeys from 'scripts/common/constants/data_keys';
import { RoutePropTypes } from 'scripts/common/prop-types/route';

const mapDispatchToProps = dispatch => bindActionCreators({
  checkEmailVerification: AccountActions.checkEmailVerification,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,

}, dispatch);

@connect((state) => {
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(
    state, DataKeys.LOADING_STATUS_KEY.EMAIL_VERIFY_VIEW
  );
  return {
    isLoading,
  };
}, mapDispatchToProps)
class VerificationView extends React.PureComponent {
  static propTypes= {
    ...RoutePropTypes,
    setLoadingStatus: PropTypes.func,
    checkEmailVerification: PropTypes.func,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    setLoadingStatus: noop,
    checkEmailVerification: noop,
    isLoading: false,
  };

  state = {
    isWaiting: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.isWaiting) {
      nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.EMAIL_VERIFY_VIEW, true);
      const { location, history } = nextProps;
      const token = location.search.split('=')[1];
      if (token) {
        nextProps.checkEmailVerification({
          token,
          onSuccess: (result) => {
            nextProps.setLoadingStatus(DataKeys.LOADING_STATUS_KEY.EMAIL_VERIFY_VIEW, false);
            Modal.info({
              okType: 'primary',
              okText: '确认',
              content: result.id ? '验证成功' : '验证失败',
              title: '验证结果',
              onOk: () => {
                history.push(buildPath(AccountRoutes.DASHBOARD));
              },
            });
          },
          onComplete: () => {

          },
        });
      }
      return {
        isWaiting: true,
      };
    }
    return null;
  }

  render() {
    return (
      <>
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',  height: '100%', 
        }}
        >
          <Spin spinning={this.props.isLoading} size="large" tip="邮箱验证中" />
        </div>
      </>
    );
  }
}

export default VerificationView;
