import 'styles/problem/widgets/problem_edit.scss';

import React from 'react';
import { BulbFilled, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Form, Input, Button, Divider, Radio, Row, Col, Checkbox, Rate, Tooltip
} from 'antd';
import { ProblemDifficultyEnum, ProblemTypeEnum } from 'scripts/common/enums/problem';
import PropTypes from 'prop-types';
import {
  get, noop, set, isEqual, isEmpty, indexOf 
} from 'lodash';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { indexToChar } from 'scripts/common/utils/unit';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { debounce } from 'scripts/common/utils/functional';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

const RadioGroup = Radio.Group;

class ChoiceProblemEditView extends React.Component {
  static propTypes = {
    problem: ProblemPropTypes,
    onSave: PropTypes.func,
    onChange: PropTypes.func,
    mode: PropTypes.string,
    type: PropTypes.objectOf(ProblemTypeEnum).isRequired,
  };

  static defaultProps = {
    mode: 'edit',
    problem: {},
    onSave: noop,
    onChange: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      optionIndex: 3,
      problem: isEmpty(props.problem) ? {
        title: '',
        description: '',
        options: {
          options: [
            { order: 1, name: '' },
            { order: 2, name: '' },
            { order: 3, name: '' },
            { order: 4, name: '' }
          ],
          correct_answer: (props.type === ProblemTypeEnum.SINGLE) ? 1 : [],
        },
        hint: '',
      } : props.problem,
      firstEdit: true,
      multipleSelected: isEmpty(props.problem)
        ? [false, false, false, false]
        : this.reverseCurrentAnswer(
          get(props.problem, 'options.options', []),
          get(props.problem, 'options.correct_answer', [])
        ),
    };
  }

  componentDidUpdate(prevProp, prevState) {
    if (!isEqual(prevState.problem, this.state.problem)) {
      this.props.onChange(this.state.problem);
    }
  }

  reverseCurrentAnswer = (options, currentAnswer) => {
    return options.map((item) => {
      return indexOf(currentAnswer, item.order) > -1;
    });
  };

  handleInputChange = keyName => ({ target }) => {
    this.setState(set({ problem: { ...this.state.problem } }, `problem.${keyName}`, target.value));
  };

  handleOptionInputChange = key => ({ target }) => {
    const stateTemp = this.state;
    set(stateTemp, `problem.options.options.${key}.order`, key + 1);
    set(stateTemp, `problem.options.options.${key}.name`, target.value);
    this.setState({
      optionIndex: stateTemp.optionIndex,
      problem: {
        title: stateTemp.problem.title,
        description: stateTemp.problem.description,
        options: stateTemp.problem.options,
        hint: stateTemp.problem.hint,
      },
      firstEdit: false,
      multipleSelected: stateTemp.multipleSelected,
    });
  };

  handleInputChangeBE = keyName => (es) => {
    this.setState(set({
      problem: { ...this.state.problem },
      [`${keyName}_editor`]: es,
    }, `problem.${keyName}`, es.toHTML()));
  };

  handleAddOption = () => {
    const rawOpt = get(this.state, 'problem.options.options', []);
    if (rawOpt.length >= 10) return;
    const options = [...rawOpt, { order: 1, name: '' }].map((item, key) => {
      return {
        ...item,
        order: 1,
      };
    });

    this.setState(set({
      problem: { ...this.state.problem },
    }, 'problem.options.options', options));
  };

  handleAddMultipleOption = () => {
    const stateTemp = this.state;
    const multipleSelected = [...get(stateTemp, 'multipleSelected', []), false];
    const rawOpt = get(stateTemp, 'problem.options.options', []);
    if (rawOpt.length >= 10) return;
    const options = [...rawOpt, { order: 1, name: '' }].map((item, key) => {
      return {
        ...item,
        order: key + 1,
      };
    });
    set(stateTemp, 'problem.options.options', options);
    set(stateTemp, 'multipleSelected', multipleSelected);
    this.setState({
      optionIndex: stateTemp.optionIndex,
      problem: {
        title: stateTemp.problem.title,
        description: stateTemp.problem.description,
        options: stateTemp.problem.options,
        hint: stateTemp.problem.hint,
      },
      firstEdit: false,
      multipleSelected: stateTemp.multipleSelected,
    });
  };

  handleRemoveOption = k => () => {
    const options = get(this.state, 'problem.options.options', []);
    if (options.length <= 2) return;
    const t = this.state.optionIndex - 1;
    const  { problem }  = this.state;
    set(problem, 'options.options', options.filter((item, key) => key !== k));
    const stateTemp = { ...this.state };
    if (this.props.type === ProblemTypeEnum.SINGLE) {
      const ca = (k + 1) <= problem.options.correct_answer
        ? problem.options.correct_answer - 1 : problem.options.correct_answer;
      set(problem, 'options.correct_answer', ca < 0 ? 0 : ca);
      set(stateTemp, 'optionIndex', t < 0 ? 0 : t);
      set(stateTemp, 'problem', problem);
    } else if (this.props.type === ProblemTypeEnum.MULTIPLE) {
      const multipleSelectedTemp = stateTemp.multipleSelected.filter((i, key) => {
        return key !== k;
      });
      const correctAnswer = [];
      multipleSelectedTemp.forEach((item, key) => {
        if (item) correctAnswer.push(key + 1);
      });
      set(stateTemp, 'multipleSelected', multipleSelectedTemp);
      set(stateTemp, 'problem.options.correct_answer', correctAnswer);
    }
    this.setState({
      optionIndex: stateTemp.optionIndex,
      problem: {
        title: problem.title,
        description: problem.description,
        options: stateTemp.problem.options,
        hint: problem.hint,
      },
      firstEdit: false,
      multipleSelected: stateTemp.multipleSelected,
    });
  };

  handleRadioChange = ({ target }) => {
    this.setState(set({ problem: { ...this.state.problem } }, 'problem.options.correct_answer', target.value + 1));
  };

  //
  handleCheckboxChange = k => ({ target }) => {
    const stateTemp = this.state;
    const multipleSelectedTemp = this.state.multipleSelected;
    multipleSelectedTemp[k] = target.checked;
    const correctAnswer = [];
    multipleSelectedTemp.forEach((item, key) => {
      if (item) correctAnswer.push(key + 1);
    });
    set(stateTemp, 'multipleSelected', multipleSelectedTemp);
    set(stateTemp, 'problem.options.correct_answer', correctAnswer);
    this.setState({
      optionIndex: stateTemp.optionIndex,
      problem: {
        title: stateTemp.problem.title,
        description: stateTemp.problem.description,
        options: stateTemp.problem.options,
        hint: stateTemp.problem.hint,
      },
      firstEdit: false,
      multipleSelected: stateTemp.multipleSelected,
    });
  };

  handleEditorChange = keyName => debounce((value) => {
    this.setState(set({
      problem: { ...this.state.problem },
    }, `problem.${keyName}`, value));
  }, INPUT_CHANGE_DEBOUNDCE_TIME);

  render() {
    const { problem } = this.state;
    const options = get(problem, 'options.options', []);
    return (
      <>
        <Form className="choice_problem_editor">
          <div className="left_sider">
            <Form.Item>
              <Input
                key="title"
                value={problem.title}
                onChange={this.handleInputChange('title')}
                maxLength={140}
                placeholder="题目名称"
              />
            </Form.Item>
            <Form.Item>
              <Divider orientation="left">
                <span className="item_title">题目描述</span>
              </Divider>
              <MarkdownEditor
                height="15em"
                defaultValue={get(problem, 'description', '')}
                onChange={this.handleEditorChange('description')}
                placeholder="请输入题目描述，可以为空"
              />
            </Form.Item>
            <Form.Item>
              <Divider orientation="left">
                <span className="item_title">答案解析</span>
              </Divider>
              <MarkdownEditor
                height="10em"
                defaultValue={get(problem, 'explain', '')}
                onChange={this.handleEditorChange('explain')}
                placeholder="请输入题目答案解析，可以为空"
              />
            </Form.Item>
          </div>
          <div className="right_sider">
            <Form.Item style={{ textAlign: 'center' }}>
              <Rate
                character={({ index }) => <Tooltip
                  title={get(ProblemDifficultyEnum.enumValueOf(index + 1), 'desc')}
                >
                  <BulbFilled />
                </Tooltip>}
                value={problem.difficulty}
                onChange={(value) => { this.handleInputChange('difficulty')({ target: { value } }); }}
              />
            </Form.Item>
            <Form.Item>
              <Divider orientation="left">
                <span className="item_title">题目选项</span>
              </Divider>
              {this.props.type === ProblemTypeEnum.SINGLE
              && <RadioGroup
                value={get(problem, 'options.correct_answer', 0) - 1}
                onChange={this.handleRadioChange}
                style={{ width: '100%' }}
              >
                {options.map((item, key) => {
                  return (
                    <React.Fragment key={`g_${key + 1}`}>
                      <Row className="choice_item" gutter={8} key={`g_${key + 1}`}>
                        <Col span={6}>
                          <Radio
                            value={key}
                          >选项: {indexToChar(key + 1)}</Radio>
                        </Col>
                        <Col span={14}>
                          <Input
                            value={item.name}
                            onChange={this.handleOptionInputChange(key)}
                          />
                        </Col>
                        <Col span={4}>
                          <MinusCircleOutlined className="dynamic-delete-button" onClick={this.handleRemoveOption(key)} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
              </RadioGroup>}
              {this.props.type === ProblemTypeEnum.MULTIPLE
                && <>
                  {options.map((item, key) => {
                    return (
                      <Row className="choice_item" key={`g_${key + 1}`}>
                        <Col span={6}>
                          <Checkbox
                            onChange={this.handleCheckboxChange(key)}
                            checked={this.state.multipleSelected[key]}
                          >
                            选项: {indexToChar(key + 1)}
                          </Checkbox>
                        </Col>
                        <Col span={14}>
                          <Input
                            value={item.name}
                            onChange={this.handleOptionInputChange(key)}
                          />
                        </Col>
                        <Col span={4}>
                          <MinusCircleOutlined className="dynamic-delete-button" onClick={this.handleRemoveOption(key)} />
                        </Col>
                      </Row>
                    );
                  })}
                </>}
              <Button
                className="add_choice_item"
                type="dashed"
                onClick={this.props.type === ProblemTypeEnum.SINGLE
                  ? this.handleAddOption : this.handleAddMultipleOption}
              >
                <PlusOutlined /> 增加选项
              </Button>
            </Form.Item>
          </div>
        </Form>
      </>
    );
  }
}

export default ChoiceProblemEditView;
