import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { JudgeStatusSelectors } from 'scripts/common/logic/collection/selectors';
import { JudgeStatusActions } from 'scripts/common/logic/collection/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SubmitCodeProblemWidget from 'scripts/apps/problem/judge_status/submit_code_problem';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { showModal } from 'scripts/common/widgets/modal';
import { createJudgeResultModal } from 'scripts/apps/widgets/judge_status/dialog';
import { CollectionProblemPropTypes, CollectionPropTypes } from 'scripts/common/prop-types/collection';

const JudgeResultModal = createJudgeResultModal(JudgeStatusActions, JudgeStatusSelectors);

@connect(null, dispatch => bindActionCreators({
  submitCode: JudgeStatusActions.submitCode,
  getJudgeStatusEntity: JudgeStatusActions.getJudgeStatusEntity,
}, dispatch))
class SubmitCodeView extends React.PureComponent {
  static propTypes = {
    problem: ProblemPropTypes.isRequired,
    collectionId: PropTypes.string.isRequired,
    submitCode: PropTypes.func,
    getJudgeStatusEntity: PropTypes.func,
    languageMask: PropTypes.number,
    collection: CollectionPropTypes,
    collectionProblem: CollectionProblemPropTypes.isRequired,
    onReceivedNewJudgeStatus: PropTypes.func,      // 当有新的提交并且有评测结果时触发
  };

  static defaultProps = {
    collection: null,
    submitCode: noop,
    getJudgeStatusEntity: noop,
    onReceivedNewJudgeStatus: noop,
    languageMask: 0,
  };

  state = {};

  showJudgeStatusModel = (status) => {
    showModal(JudgeResultModal, {
      judgeStatusId: get(status, 'id'),
      apiPayloads: {
        collectionId: this.props.collectionId,
      },
    });
  };

  getLanguageMask = () => {
    if (this.props.collection && this.props.collectionProblem) {
      const plang = get(this.props.collectionProblem, 'code_language', -1);
      if (plang < 0) {
        return get(this.props.collection, 'code_language', 0);
      }
      return plang;
    }
    return 0;
  };

  render() {
    const { collectionId } = this.props;
    return <SubmitCodeProblemWidget
      problem={this.props.problem}
      languageMask={this.getLanguageMask()}
      submitCode={(payloads) => {
        this.props.submitCode({
          ...payloads,
          collectionId,
        });
      }}
      getJudgeStatusEntity={(payloads) => {
        this.props.getJudgeStatusEntity({
          ...payloads,
          collectionId,
        });
      }}
      onShowJudgeStatusModel={this.showJudgeStatusModel}
      getJudgeStatusEntitySelector={JudgeStatusSelectors.getJudgeStatusEntity}
      onReceivedNewJudgeStatus={this.props.onReceivedNewJudgeStatus}
    />;
  }
}

export default SubmitCodeView;
