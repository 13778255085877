import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'antd';
import { get, indexOf } from 'lodash';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import ReactResizeDetector from 'react-resize-detector';
import { AntTableMiddleOutsideHeight } from 'scripts/common/constants/table';

class OrganizationListTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSelectRowKeys: PropTypes.func,
    paginationParams: PaginationPropTypes.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showTeamDetail: PropTypes.func.isRequired,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    onSelectRowKeys: null,
    teamGroups: [],
  };

  state = {
    tableHeight: 0,
    selectedRowKeys: [],
  };

  COLUMNS = [{
    title: '队名',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center',
  }, {
    title: '队长',
    dataIndex: 'leader',
    key: 'leader',
    align: 'center',
    width: 120,
  }, {
    title: '分组',
    dataIndex: 'group_name',
    key: 'group_name',
    align: 'center',
  }, {
    title: '人数',
    dataIndex: 'numbers',
    key: 'numbers',
    align: 'center',
    width: 120,
  }, {
    title: '开放性',
    dataIndex: 'teampublic',
    key: 'teampublic',
    align: 'center',
    width: 120,
  }, {
    title: '账户名',
    dataIndex: 'username',
    key: 'username',
    align: 'center',
    width: 120,
  }, {
    title: '密码',
    dataIndex: 'clear_password',
    key: 'clear_password',
    align: 'center',
    width: 120,
  }, {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 140,
  }];

  getGroupName = (gid) => {
    const rel = this.props.teamGroups.find(item => get(item, 'id') === gid);
    return get(rel, 'name', '未分组');
  };

  handleSelect = (record, selected) => {
    const selectedRowKeys = JSON.parse(JSON.stringify(this.state.selectedRowKeys));
    const idx = indexOf(selectedRowKeys, record.key);
    if (selected) {
      if (idx > -1) return;
      selectedRowKeys.push(record.key);
    } else {
      if (idx < 0) return;
      if (selectedRowKeys.length === 1) {
        selectedRowKeys.shift();
      } else {
        selectedRowKeys.splice(idx, 1);
      }
    }
    this.setState({ selectedRowKeys }, () => {
      this.props.onSelectRowKeys(selectedRowKeys);
    });
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  handleSelectAll = (selected, selectedRows, changeRows) => {
    const selectedRowKeys = JSON.parse(JSON.stringify(this.state.selectedRowKeys));
    for (let i = 0; i < changeRows.length; i++) {
      const record = changeRows[i];
      const idx = indexOf(selectedRowKeys, record.key);
      if (selected) {
        if (idx > -1) return;
        selectedRowKeys.push(record.key);
      } else {
        if (idx < 0) return;
        if (selectedRowKeys.length === 1) {
          selectedRowKeys.shift();
        } else {
          selectedRowKeys.splice(idx, 1);
        }
      }
    }
    this.setState({ selectedRowKeys }, () => {
      this.props.onSelectRowKeys(selectedRowKeys);
    });
  };

  renderListData = () => {
    if (!this.props.data) return null;
    return this.props.data.map((item) => {
      return ({
        key: get(item, 'id'),
        teamid: get(item, 'id'),
        nickname: get(item, 'nickname', ''),
        group_name: this.getGroupName(get(item, 'group_id')),
        leader: get(item, 'leader.realname'),
        numbers: `${get(item, 'members', []).length} / 3`,
        username: get(item, 'username', '未分配'),
        teampublic: get(item, 'public') ? '公开队伍' : '需要密码',
        clear_password: get(item, 'clear_password', '未分配'),
        action: <Button size="small" onClick={() => this.props.showTeamDetail(item)}>查看队伍</Button>,
      });
    });
  };

  render() {
    const data = this.renderListData();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 40),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.props.onPageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    return (
      <>
        <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
        <Table
          rowSelection={this.props.onSelectRowKeys && {
            selectedRowKeys: this.state.selectedRowKeys,
            onSelect: this.handleSelect,
            onSelectAll: this.handleSelectAll,
          }}
          scroll={{
            y: this.state.tableHeight - AntTableMiddleOutsideHeight,
            scrollToFirstRowOnChange: true,
          }}
          pagination={optPagination}
          size="middle"
          dataSource={data}
          columns={this.COLUMNS}
          loading={this.props.isLoading}
        />
      </>
    );
  }
}

export default OrganizationListTable;
