import 'styles/education/course/arrangement.scss';

import React from 'react';
import PropTypes from 'prop-types';
import  moment  from 'moment';
import {
  get, set, isObject, isEmpty, isEqual, noop,
} from 'lodash';
import {
  Form, Input, TimePicker, InputNumber, Button, Select, Row, Col 
} from 'antd';
import { ArrangementPropTypes } from 'scripts/common/prop-types/course';

class ArrangementEditorView extends React.Component {
  static initArrangement = (props) => {
    return isEmpty(props.arrangement) ? {
      name: '',
      day_of_week: 0,
      start_week: 1,
      end_week: 17,
      odd_even: 0,
      start_section: 1,
      end_section: 2,
      start_time: 0,
      end_time: 0,
    } : JSON.parse(JSON.stringify(props.arrangement));
  };

  static propTypes = {
    mode: PropTypes.string,
    courseId: PropTypes.number.isRequired,
    arrangement: ArrangementPropTypes,
    handleSubmitClick: PropTypes.func,
  };

  static defaultProps = {
    mode: 'new',
    handleSubmitClick: noop,
    arrangement: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      rawArrangement: props.arrangement,
      arrangement: ArrangementEditorView.initArrangement(props),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.arrangement, prevState.rawArrangement)) {
      return {
        rawArrangement: nextProps.arrangement,
        arrangement: ArrangementEditorView.initArrangement(nextProps),
      };
    }
    return null;
  }

  handleInputChange = key => (params) => {
    let value;
    if (isObject(params)) {
      value = get(params, 'target.value');
    } else {
      value = params;
    }
    this.setState(set({ arrangement: { ...this.state.arrangement } }, `arrangement.${key}`, value));
  };

  handleTimeChange = key => (time) => {
    const t = time ? time.format('X') : 0;
    this.setState(set({ arrangement: { ...this.state.arrangement } }, `arrangement.${key}`, t));
  };

  render() {
    const { arrangement } = this.state;
    const weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
    return (
      <div className="arrangement_editor_view">
        <Form.Item label="排课名称">
          <Input
            value={get(arrangement, 'name', '')}
            onChange={this.handleInputChange('name')}
            placeholder="排课名称，留空则显示详情"
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="教学周期">
              第 <InputNumber min={1} max={52} value={get(arrangement, 'start_week', 1)} onChange={this.handleInputChange('start_week')} /> 周 ～
              第 <InputNumber min={1} max={52} value={get(arrangement, 'end_week', 17)} onChange={this.handleInputChange('end_week')} /> 周
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="重复">
              <Select
                value={get(arrangement, 'odd_even', 0)}
                style={{ width: '100%' }}
                onChange={this.handleInputChange('odd_even')}
              >
                <Select.Option key={0} value={0}>单双周</Select.Option>
                <Select.Option key={1} value={1}>单周</Select.Option>
                <Select.Option key={2} value={2}>双周</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="周几">
              <Select
                value={get(arrangement, 'day_of_week', 0)}
                style={{ width: '100%' }}
                onChange={this.handleInputChange('day_of_week')}
              >
                {weekdays.map((item, ikey) => {
                  return <Select.Option key={item} value={ikey}>{item}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="上课节次">
              第 <InputNumber min={0} max={20} value={get(arrangement, 'start_section', 1)} onChange={this.handleInputChange('start_section')} /> 节 ～
              第 <InputNumber min={0} max={20} value={get(arrangement, 'end_section', 2)} onChange={this.handleInputChange('end_section')} /> 节
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="上课时间">
              <TimePicker
                value={moment(get(arrangement, 'start_time', 0) * 1000).utc()}
                onChange={this.handleTimeChange('start_time')}
              />
              ～
              <TimePicker
                value={moment(get(arrangement, 'end_time', 0) * 1000).utc()}
                onChange={this.handleTimeChange('end_time')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            type="primary"
            onClick={() => this.props.handleSubmitClick(this.state.arrangement)}
          >
            {this.props.mode === 'new' ? '确认' : '保存'}
          </Button>
        </Form.Item>
      </div>
    );
  }
}

export default ArrangementEditorView;
