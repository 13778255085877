import React from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import {
  get, noop, truncate, indexOf, remove, merge, clone, concat
} from 'lodash';
import { CollectionProblemPropTypes } from 'scripts/common/prop-types/collection';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { ProblemDifficultyEnum, ProblemTypeEnum } from 'scripts/common/enums/problem';
import { filterHTML } from 'scripts/common/utils/filter';
import { tagColorHash } from 'scripts/common/utils/tag_hash';

const TABLE_CELL_TRUNCATE_LENGTH = 32;

class ProblemsListTable extends React.PureComponent {
  static propTypes = {
    minorMode: PropTypes.bool,
    problemDatas: PropTypes.arrayOf(CollectionProblemPropTypes),
    onProblemClick: PropTypes.func,
    onSelectChanged: PropTypes.func,
    onPageChange: PropTypes.func,
    onDoubleClick: PropTypes.func,
    canManageCollection: PropTypes.bool.isRequired,
    paginationParams: PaginationPropTypes,
    problemChoosingOptions: PropTypes.shape({}),
    selectedProblemIds: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    minorMode: false,
    problemDatas: [],
    onProblemClick: noop,
    onSelectChanged: noop,
    onPageChange: noop,
    paginationParams: {},
    problemChoosingOptions: {},
    onDoubleClick: noop,
    selectedProblemIds: [],
  };

  MINOR_COLUMNS = [{
    title: '题号',
    width: 80,
    dataIndex: 'number',
  }, {
    title: '题目',
    dataIndex: 'title',
  }];

  NORMAL_COLUMNS = [{
    title: '题号',
    width: 80,
    dataIndex: 'number',
  }, {
    title: '题目',
    dataIndex: 'title',
  }, {
    title: '类型',
    width: 100,
    dataIndex: 'type',
  },  {
    title: '难度',
    width: 140,
    dataIndex: 'difficulty',
  }, {
    title: '正确率（当前题库）',
    width: 240,
    dataIndex: 'correct_ratio',
  }];

  rowSelection = {
    onChange: (selectedKeys) => {
      const { problemDatas } = this.props;
      const clearSelectedKeys = clone(this.props.selectedProblemIds);
      remove(clearSelectedKeys, (id) => {
        return problemDatas.find(pitem => pitem.id === id);
      });
      this.props.onSelectChanged(merge(clearSelectedKeys, selectedKeys));
    },
    getCheckboxProps: (record) => {
      const module = get(this.props.problemChoosingOptions, 'module', '');
      let disabled = false;
      if (module !== '') {
        const pids = get(this.props.problemChoosingOptions, 'currentPidMapping', {});
        const dpids = get(this.props.problemChoosingOptions, 'disabledProblemsIds', []);
        const pid = get(record, 'problem_entity_id', '');
        if (get(pids, pid, false) || indexOf(dpids, pid) > -1) {
          disabled = true;
        }
      }
      return {
        disabled,
      };
    },
  };

  isCodeProblem = (problemTypeId) => {
    const problemType = ProblemTypeEnum.enumValueOf(problemTypeId);
    return (problemType === ProblemTypeEnum.CODE)
      || (problemType === ProblemTypeEnum.CODE_FILL);
  };

  getRowClassName = (record) => {
    const module = get(this.props.problemChoosingOptions, 'module', '');
    if (module !== '') {
      const pids = get(this.props.problemChoosingOptions, 'selectedProblemIds', []);
      const pid = get(record, 'problem_entity_id', '');
      const spids = get(this.props.problemChoosingOptions, 'currentPidMapping', {});
      const dpids = get(this.props.problemChoosingOptions, 'disabledProblemsIds', []);
      if (indexOf(dpids, pid) > -1) {
        return 'problem-cannot-choice';
      }
      if (spids[pid]) {
        return 'problem-in-choice';
      }
      if (indexOf(pids, pid) > -1) {
        return 'problem-had-selected';
      }
    }
    return '';
  };

  onRow = (record) => {
    return {
      onDoubleClick: () => {
        this.props.onDoubleClick(record);
      },
    };
  };

  renderProblemTags = (tags) => {
    const tagsList = tags.length > 3 ? tags.slice(0, 3) : tags;
    return tagsList.map((tag) => {
      return <Tag color={tagColorHash(tag.name)} key={tag.id}>{tag.name}</Tag>;
    });
  };

  renderRowSelection = () => {
    const module = get(this.props.problemChoosingOptions, 'module', '');
    if (module !== '' || this.props.canManageCollection) {
      const pids = get(this.props.problemChoosingOptions, 'currentProblems', []).map(item => item.id);
      return {
        selectedRowKeys: concat(this.props.selectedProblemIds, pids),
        ...this.rowSelection,
      };
    }
    return null;
  };

  renderFooter = () => {
    let footerMsg = `共有${get(this.props.paginationParams, 'total', 0)}道题目`;
    if (this.props.selectedProblemIds.length > 0) {
      footerMsg += `，已选择${this.props.selectedProblemIds.length}条。`;
    }
    return footerMsg;
  };

  renderListData = () => {
    const getRatio = (a, b) => {
      return (b === 0) ? 0 : (((a * 1.0) / b) * 100.0).toFixed(2); // eslint-disable-line
    };
    const getTitle = (item) => {
      const type = parseInt(get(item, 'problem.problem_type', 0), 10);
      if (type > 3) {
        const desc = filterHTML(get(item, 'problem.description'));
        const defFilterHtml =  desc ? `（描述：${desc}）` : '';
        const t = `${get(item, 'problem.title', '')}${defFilterHtml}`;
        return truncate(t, {
          length: TABLE_CELL_TRUNCATE_LENGTH,
        });
      }
      return get(item, 'problem.title', '');
    };
    return this.props.problemDatas.map((item) => {
      if (!item || !item.problem) return null;
      const isCodeProblem = this.isCodeProblem(get(item, 'problem.problem_type'));
      const problemType = ProblemTypeEnum.enumValueOf(get(item, 'problem.problem_type'));
      return {
        key: get(item, 'id', ''),
        problem_entity_id: get(item, 'problem.id', ''),
        number: get(item, 'problem.number', '0'),
        title: <a onClick={() => this.props.onProblemClick(item)}>
          {this.renderProblemTags(get(item, 'problem.tags', []))}
          {getTitle(item)}
          {get(item, 'is_clone', false) && <LinkOutlined />}
        </a>,
        type: problemType ? <Tag color={problemType.color}>{problemType.desc}</Tag> : null,
        difficulty: get(ProblemDifficultyEnum.enumValueOf(get(item, 'problem.difficulty', 0)), 'desc'),
        correct_ratio: isCodeProblem
          ? `${getRatio(get(item, 'accepted', 0), get(item, 'submission', 0))}% (${get(item, 'accepted', 0)} / ${get(item, 'submission', 0)})`
          : '-',
      };
    }).filter(item => !!item);
  };

  render() {
    const datas = this.renderListData();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 1),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.props.onPageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    return (<Table
      className="problems_list_view"
      size="middle"
      rowSelection={this.renderRowSelection()}
      dataSource={datas}
      pagination={optPagination}
      columns={this.props.minorMode ? this.MINOR_COLUMNS : this.NORMAL_COLUMNS}
      rowClassName={this.getRowClassName}
      onRow={this.onRow}
      footer={this.renderFooter}
    />);
  }
}

export default ProblemsListTable;
