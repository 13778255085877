import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get, noop, set } from 'lodash';
import  moment  from 'moment';
import { formatTime } from 'scripts/common/utils/time_formatter';
import {
  Form, Input, DatePicker, Select, Row, Col, Button
} from 'antd';

import { AnnouncementLevelEnum } from 'scripts/common/enums/announcement';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';

const { Option } = Select;

const LevelTypeItems = [
  AnnouncementLevelEnum.NORMAL,
  AnnouncementLevelEnum.SECONDARY,
  AnnouncementLevelEnum.FIRST
];

const AnnouncementEditor = (props) => {

  const [announcement, setAnnouncement] = useState({
    title: '',
    expire_time: 0,
    content: '',
    level: 1,
    notice: false,
    ...props.announcement,
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleInputChange = keyName => (e) => {
    let value = e;
    if (keyName === 'title') {
      value = e.target.value;
    } else if (keyName === 'expire_time') {
      value = e ? e.format('X') : 0;
    }
    setAnnouncement((ann) => set({ ...ann }, keyName, value));
  };

  useEffect(() => {
    setAnnouncement(ann => ({
      ...ann,
      ...props.announcement,
    }));
  }, [props.announcement]);

  const expTime = get(announcement, 'expire_time', 0);

  const handleSave = () => {
    setIsSaving(true);
    const rel = props.onSave(announcement);
    if (rel instanceof Promise) {
      rel.then(() => setIsSaving(false)).catch(() => setIsSaving(false));
    } else {
      setIsSaving(false);
    }
  };

  return <div className="announcement-info-editor">
    <Row>
      <Col span={24}>
        <Form.Item label="标题" required>
          <Input
            value={announcement.title}
            placeholder="请输入公告标题"
            onChange={handleInputChange('title')}
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item label="级别" required>
          <Select
            value={announcement.level}
            onChange={handleInputChange('level')}
          >
            {LevelTypeItems.map(item => (
              <Option key={item.key} value={item.value}>{item.desc}</Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="过期时间">
          <DatePicker
            style={{ width: '100%' }}
            defaultValue={expTime ? moment(formatTime(expTime, 'LONT_DATETIME')) : ''}
            format="YYYY-MM-DD HH:mm:ss"
            placeholder="过期时间，留空为永不过期"
            onChange={handleInputChange('expire_time')}
          />
        </Form.Item>
      </Col>
    </Row>
    <Form.Item>
      <MarkdownEditor
        onChange={handleInputChange('content')}
        defaultValue={announcement.content}
      />
    </Form.Item>
    <Row gutter={16}>
      <Col flex="0">
        <Form.Item>
          <Button type="primary" onClick={handleSave} loading={isSaving}>保存并发布</Button>
        </Form.Item>
      </Col>
      <Col flex="1 1 auto">
        {/*<Form.Item>*/}
        {/*  <Switch */}
        {/*    onChange={handleInputChange('notice')} */}
        {/*    checked={get(announcement, 'notice', false)}*/}
        {/*  />*/}
        {/*  &nbsp;&nbsp;同步发送系统通知*/}
        {/*</Form.Item>*/}
      </Col>
    </Row>
  </div>;
};

AnnouncementEditor.propTypes = {
  onSave: PropTypes.func,
  announcement: PropTypes.shape({}),
};

AnnouncementEditor.defaultProps = {
  onSave: noop,
  announcement: {},
};

export default AnnouncementEditor;
