import 'styles/education/course/list.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { ClockCircleOutlined, HomeOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, Spin } from 'antd';
import {
  isEmpty, indexOf, get, isEqual, noop 
} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ClassifyPropType } from 'scripts/common/prop-types/collection';
import { CollectionActions, CollectionSelectors } from 'scripts/common/logic/collection/index';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge/index';

import CreateClassifySubMenu from '../classify/classify_menu';

export const NOT_CLASSIFY_ID = ['judge_status', 'statistics', 'management'];
const LOADING_STATUS_KEY = 'collection_classifies_treeview';

const mapStateToProps = (state, props) => {
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  return {
    isLoading,
    classifies: CollectionSelectors.collectionClassifiesDataSelector(state, props.collectionId),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getClassifiesData: CollectionActions.getClassifiesData,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

@connect(mapStateToProps, mapDispatchToProps)
class ProblemsListSider extends React.PureComponent {
  static propTypes = {
    classifies: ClassifyPropType,
    subItemKey: PropTypes.string.isRequired,
    collectionId: PropTypes.string.isRequired,
    classifyId: PropTypes.string.isRequired,
    onClassifyChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    getClassifiesData: PropTypes.func,
    setLoadingStatus: PropTypes.func,
    isLoading: PropTypes.bool,
    canManageCollection: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    classifies: {},
    isLoading: true,
    getClassifiesData: noop,
    setLoadingStatus: noop,
  };

  state = {
    collectionId: '',
    openKeys: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const shouldUpdate = !isEqual(nextProps.collectionId, prevState.collectionId);
    if (shouldUpdate) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getClassifiesData({
        collectionId: nextProps.collectionId,
        classifyId: nextProps.classifyId,
        init: true,
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        openKeys: [nextProps.classifyId],
        collectionId: nextProps.collectionId,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.classifies) && !isEmpty(this.props.classifies)) {
      const { classifies } = this.props;
      const dfs = (node) => {
        if (node.id === this.props.classifyId) {
          return {
            flag: true,
            rel: [node.id],
          };
        }
        const { children = [] } = node;
        let rel = null;
        for (let i = 0; i < children.length; i++) {
          rel = dfs(children[i]);
          if (rel.flag) {
            return {
              flag: true,
              rel: [node.id, ...rel.rel],
            };
          }
        }
        return {
          flag: false,
          rel: [],
        };
      };
      const { rel } = dfs(classifies);
      this.setState({   // eslint-disable-line
        openKeys: rel,
      });
    }
  }

  handleSelectChange = ({ key }) => {
    if (indexOf(NOT_CLASSIFY_ID, key) > -1) {
      this.props.onPageChange(key);
    } else {
      this.props.onClassifyChange(key);
    }
  };

  handleSubMenuOpen = ({ key }) => {
    const dfs = (node) => {
      if (node.id !== key) {
        const { children = [] } = node;
        let rel = null;
        for (let i = 0; i < children.length; i++) {
          rel = dfs(children[i]);
          if (rel) {
            break;
          }
        }
        return rel || null;
      }
      return node;
    };
    const target = dfs(this.props.classifies);
    if (target && get(target, 'has_children', false) && get(target, 'children', []).length === 0) {
      this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
      this.props.getClassifiesData({
        collectionId: this.props.collectionId,
        classifyId: key,
        onComplete: () => {
          this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
    }
  };

  render() {
    const { subItemKey, classifyId } = this.props;
    return (
      <Spin
        size="large"
        tip="加载中..."
        spinning={this.props.isLoading}
      >
        <Menu
          mode="inline"
          selectedKeys={[subItemKey || classifyId]}
          openKeys={this.state.openKeys}
          defaultSelectedKeys={['root']}
          onSelect={this.handleSelectChange}
          onOpenChange={(openKeys) => { this.setState({ openKeys }); }}
        >
          <Menu.Item key="root">
            <HomeOutlined /> 所有题目
          </Menu.Item>

          {get(this.props.classifies, 'children', []).map(item => CreateClassifySubMenu(
            item,
            this.props.classifyId,
            this.handleSubMenuOpen,
            this.handleSelectChange
          ))}

          <Menu.Divider />
          <Menu.Item key="judge_status">
            <ClockCircleOutlined /> 评测历史
          </Menu.Item>
          {this.props.canManageCollection && <Menu.Item key="management">
            <SettingOutlined /> 题库管理
          </Menu.Item>}
        </Menu>
      </Spin>
    );
  }
}
export default ProblemsListSider;
