import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, isEqual, noop } from 'lodash';

import {
  CheckOutlined,
  CloudUploadOutlined,
  EditFilled,
  FileDoneOutlined,
  LoadingOutlined,
  UploadOutlined
} from '@ant-design/icons';

import {
  Steps,
  Alert,
  Divider,
  Progress,
  Button,
  Tooltip,
  Modal,
  Badge,
  Upload,
  Popover,
  message
} from 'antd';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { filterHTML } from 'scripts/common/utils/filter';
import { diffTime, diffTimeSeconds, formatTime } from 'scripts/common/utils/time_formatter';
import { AsgnReportActions, AsgnActions } from 'scripts/common/logic/education/asgn/actions';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { buildResourcePath, openLink } from 'scripts/common/utils/location_helper';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { debounce } from 'scripts/common/utils/functional';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';
import RichtextContext from 'scripts/common/widgets/richtext_context';

const { Step } = Steps;

const ButtonGroup = Button.Group;

@connect(null, dispatch => bindActionCreators({
  modifyStudentReport: AsgnReportActions.modifyStudentReport,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  uploadFile: AsgnActions.uploadFile,
  getStudentReportsEntity: AsgnReportActions.getStudentReportsEntity,
  deleteAsgnReport: AsgnActions.deleteFile,
}, dispatch))

class AsgnOverviewComponent extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    asgn: AsgnPropTypes.isRequired,
    extraInfo: PropTypes.shape({}),
    report: PropTypes.shape({}),
    modifyStudentReport: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    getStudentReportsEntity: PropTypes.func,
    deleteAsgnReport: PropTypes.func,
    uploadFile: PropTypes.func,
  };

  static defaultProps = {
    extraInfo: {},
    report: {},
    modifyStudentReport: noop,
    viewSuccessMessage: noop,
    getStudentReportsEntity: noop,
    deleteAsgnReport: noop,
    uploadFile: noop,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.extraInfo, prevState.raw.extraInfo)) {
      const { asgn, extraInfo } = nextProps;
      const status = get(asgn, 'status', {});
      if (get(status, 'role') === 'student') {
        const report = get(extraInfo, 'report', {});
        let currentStep = 0;
        if (get(report, 'teacher_check', false)) {
          currentStep = 3;
        } else if (get(report, 'submitted', false)) {
          currentStep = 2;
        } else if (get(extraInfo, 'process', 0) >= 100) {
          currentStep = 1;
        }
        let fileList = [];
        if (get(report, 'attachments', []) !== 0) {
          fileList = get(report, 'attachments', []).map((item, index) => {
            return {
              uid: index.toString(),
              name: item.name + item.suffix,
              status: 'done',
              url: item.storage_path,
            };
          });
        }
        // const uploadList = prevState.fileList.filter(item => item.status === 'error');
        return {
          raw: {
            extraInfo: nextProps.extraInfo,
          },
          currentStep,
          report: get(extraInfo, 'report', {}),
          impressions: get(nextProps.extraInfo, 'report.impressions', ''),
          fileList: [...fileList],
        };
      }
    }
    return null;
  }

  state = {
    fileList: [],
    uploadList: [],
    uploading: false,
    raw: {
      extraInfo: null,
    },
    submitting: false,
    impressions: '',
    currentStep: 0,
  };

  submitReport = () => {
    const reportId = get(this.props.extraInfo, 'report.id');
    if (!reportId) return;
    const submitFunc = () => {
      this.setState({
        submitting: true,
      }, () => {
        this.props.modifyStudentReport({
          courseId: this.props.courseId,
          asgnId: this.props.asgnId,
          reportId,
          body: {
            impressions: this.state.impressions,
          },
          onSuccess: () => {
            this.props.viewSuccessMessage({
              message: '提交作业成功！',
            });
          },
          onComplete: () => {
            this.setState({
              submitting: false,
            });
          },
        });
      });
    };
    if (!get(this.props.extraInfo, 'report.submitted', false)) {
      Modal.confirm({
        title: '提交作业',
        content: '确认要提交作业吗？提交后非代码题的回答将不能更改，并可以查看系统评分。你也可以继续做代码题以及修改实验感想。',
        onOk: submitFunc,
      });
    } else {
      submitFunc();
    }
  };

  handleUpload = () => {
    const { uploadList } = this.state;
    this.setState({
      ...this.state,
      uploading: true,
    });
    this.props.uploadFile({
      courseId: this.props.courseId,
      asgnId: this.props.asgnId,
      reportId: get(this.props.extraInfo, 'report.id'),
      fileList: uploadList,
      uploadProgress: ({ total, loaded }) => {
        this.setState({
          ...this.state,
          percent: Math.round((loaded / total) * 100).toFixed(2),
        }, () => {
          if ((loaded / total) === 1) {
            this.setState({
              ...this.state,
              uploadList: [],
            });
          }
        });
      },
      onSuccess: () => {
        this.props.getStudentReportsEntity({
          asgnId: this.props.asgnId,
          courseId: this.props.courseId,
          id: get(this.props.extraInfo, 'report.id'),
          onComplete: () => {
            message.success('上传成功');
            this.setState({
              ...this.state,
              uploading: false,
            });
          },
        });
      },
    });
  };

  handleEditorChange = debounce((impressions) => {
    this.setState({ impressions });
  }, INPUT_CHANGE_DEBOUNDCE_TIME);

  renderPopConfirmContent = () => {
    const { fileList } = this.state;
    const props = {
      onDownload: (file) => {
        const vpath = file.url;
        const url = buildResourcePath(vpath, true);
        return openLink(url);
      },
      onPreview: (file) => {
        const vpath = file.url;
        const url = buildResourcePath(vpath, true);
        return openLink(url);
      },
      onRemove: (file) => {
        if (get(file, 'response', null)) {
          const fl = fileList.filter(item => item.status !== 'removed');
          this.setState({
            ...this.state,
            fileList: fl,
            uploadList: [],
          });
        } else {
          const str = file.name.split('.');
          let name = '';
          if (str.length > 2) {
            for (let i = 0; i < str.length; i++) {
              if (i === 0) {
                name = `${name}${str[i]}`;
              } else {
                name = `${name}.${str[i]}`;
              }
            }
          } else {
            name = str[0];
          }
          this.props.deleteAsgnReport({
            courseId: this.props.courseId,
            asgnId: this.props.asgnId,
            reportId: get(this.props.extraInfo, 'report.id'),
            names: [name],
            onSuccess: () => {
              this.props.getStudentReportsEntity({
                asgnId: this.props.asgnId,
                courseId: this.props.courseId,
                id: get(this.props.extraInfo, 'report.id'),
                onComplete: () => {
                  message.success('删除成功');
                  this.setState({
                    ...this.state,
                    uploading: false,
                  });
                },
              });
            },
          });
        }
      },
      beforeUpload: (file) => {
        const uFile = {
          name: `${file.name}(待上传)`,
          uid: file.uid,
          status: 'error',
          response: '待上传',
          ...file,
        };
        this.setState(state => ({
          ...this.state,
          fileList: [...state.fileList, uFile],
          uploadList: [file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <div style={{ height: '250px', width: '400px' }}>
        <Upload {...props}>
          <Button disabled={this.state.uploadList.length !== 0 || this.state.fileList.length >= 5}>
            <UploadOutlined /> 选择
          </Button>
        </Upload>
        {this.state.uploading &&  <Progress percent={this.state.percent} />}
        {this.state.uploadList.length !== 0 && <Button
          type="primary"
          style={{ marginTop: '36px' }}
          onClick={this.handleUpload}
        >
          上传
        </Button>}
      </div>
    );
  };

  renderBadgeCount = () => {
    if (this.state.uploadList.length !== 0) {
      return 1;
    }
    return this.state.fileList.length;
  };

  render() {
    const { asgn, extraInfo } = this.props;
    const desc = get(asgn, 'description', '');
    const status = get(asgn, 'status', {});
    const endTime = get(status, 'end_time', 0);
    const { currentStep } = this.state;
    const dlDays = diffTime('days')(endTime);
    const dlSeconds = diffTimeSeconds(endTime);
    const isStudent = get(status, 'role') === 'student';

    return (
      <div className="asgn_overview">
        {dlSeconds < 0 && dlDays >= -7 && dlDays <= 0 && <Alert
          message={`本次作业将于 ${formatTime(endTime, 'LONG_DATETIME')} 结束，请尽快完成`}
          type="warning"
          showIcon
        />}
        {isStudent ? <div className="asgn_overview_processbar">
          <Steps current={currentStep}>
            <Step
              title="作业练习"
              description="完成相应题目"
              icon={currentStep > 0 ? <CheckOutlined /> : <EditFilled />}
            />
            <Step
              title="提交作业"
              description="填写实验报告并提交"
              icon={currentStep > 1 ? <CheckOutlined /> : <CloudUploadOutlined />}
            />
            <Step
              title="教师批改"
              description="等待老师批改作业"
              icon={currentStep === 2 ? <LoadingOutlined /> : <CheckOutlined />}
            />
            <Step
              title="查看结果"
              description="查看最终批改结果"
              icon={<FileDoneOutlined />}
            />
          </Steps>
        </div> : <div className="asgn_overview_teacher_procbar">
          <div>
            <Progress
              className="progress"
              type="dashboard"
              strokeLinecap="round"
              percent={parseFloat(((get(extraInfo, 'submitted', 0) / get(extraInfo, 'reports', 0)) * 100).toFixed(2))}
              format={() => `${get(extraInfo, 'submitted', 0)} / ${get(extraInfo, 'reports', 0)}`}
            /><br />
            作业提交进度
          </div>
          <div>
            <Progress
              className="progress"
              type="dashboard"
              strokeLinecap="round"
              percent={parseFloat(((get(extraInfo, 'checked', 0) / get(extraInfo, 'reports', 0)) * 100).toFixed(2))}
              format={() => `${get(extraInfo, 'checked', 0)} / ${get(extraInfo, 'reports', 0)}`}
            /><br />
            作业批改进度
          </div>
          <div>
            <Progress
              className="progress"
              type="dashboard"
              strokeLinecap="round"
              percent={parseFloat((get(asgn, 'status.code_submission', 0) === 0 ? 0 : (get(asgn, 'status.code_accepted', 0) / get(asgn, 'status.code_submission', 0)) * 100).toFixed(2))}
            /><br />
            代码题判题通过率
          </div>
        </div> }
        <Divider />
        {filterHTML(desc).trim() && <>
          <div className="asgn_descriptions">
            <h3>作业说明：</h3>
            <RichtextContext content={desc} />
          </div>
          <Divider />
        </>}
        { isStudent && <div className="asgn_reports">
          <div className="impression_view">
            <div className="impression">
              {get(extraInfo, 'report.teacher_check', false) ? <div className="asgn_descriptions">
                <h3>实验感想：</h3>
                <RichtextContext content={this.state.impressions || '无'} />
              </div> : <MarkdownEditor
                height="100%"
                onChange={this.handleEditorChange}
                defaultValue={this.state.impressions}
                placeholder="对于这次作业，你有什么感想呢？不妨在这儿写下来..."
              />}
            </div>

            <div className="submit_area">
              <div className="info">
                <Tooltip
                  title={<span>
                    <strong>{get(extraInfo, 'report.teacher_check', false) ? '最终成绩' : '系统自动打分'}</strong>
                    <br />满分：{parseFloat(get(asgn, 'full_score', 0)).toFixed(1)}
                  </span>}
                >
                  <Progress
                    className="progress"
                    type="dashboard"
                    strokeLinecap="round"
                    percent={(get(status, 'score', 0) / get(asgn, 'full_score', 0)) * 100}
                    format={() => (get(status, 'score', null) === null ? '请先提交' : parseFloat(get(status, 'score', 0)).toFixed(1))}
                  />
                </Tooltip>
                <Tooltip title="题目完成进度">
                  <Progress
                    className="progress"
                    type="dashboard"
                    strokeLinecap="round"
                    percent={get(extraInfo, 'process', 0)}
                    format={() => `${get(extraInfo, 'problemsFinished', 0)} / ${get(extraInfo, 'problemsTotal', 0)}`}
                  />
                </Tooltip>
              </div>
              <div className="submit_btn">
                <ButtonGroup>
                  <Popover
                    placement="left"
                    title="查看附件"
                    trigger="click"
                    content={this.renderPopConfirmContent()}
                  >
                    <Badge
                      count={this.renderBadgeCount()}
                      style={this.state.uploadList.length === 0 ? { backgroundColor: '#52c41a', zIndex: '99' } : { zIndex: '99' }}
                    >
                      <Button>
                        上传附件
                      </Button>
                    </Badge>
                  </Popover>
                  <Button
                    loading={this.state.submitting}
                    disabled={get(extraInfo, 'report.teacher_check', false)}
                    onClick={this.submitReport}
                    type="primary"
                  >
                    {get(extraInfo, 'report.submitted', false) ? '修改感想' : '提交作业'}
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}

export default AsgnOverviewComponent;
