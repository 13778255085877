import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { CourseRoutes, AsgnRoutes, AsgnProblemRoutes } from 'scripts/apps/routes';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import CourseListView from './course/course_list';
import CourseView from './course/course_view';
import AsgnView from './asgn/asgn_view';
import AsgnCodeProblemView from './asgn/problems/code_problem_view';

const EducationAPP = () => (
  <BaseLayout>
    <Switch>
      <Route path={CourseRoutes.LIST} exact component={CourseListView} />
      <Route path={AsgnProblemRoutes.CONTENT} component={AsgnCodeProblemView} />
      <Route path={AsgnRoutes.ASGN} component={AsgnView} />
      <Route path={CourseRoutes.CONTENT} component={CourseView} />
      <Route component={WeJudge404NotFoundComponent} />
    </Switch>
  </BaseLayout>
);

export default EducationAPP;
