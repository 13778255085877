import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import {
  Modal, Avatar, Button, Statistic, Tooltip
} from 'antd';
import { withRouter } from 'react-router-dom';
import {
  get, isEqual, pick, noop, truncate 
} from 'lodash';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { JudgeStatusPropTypes } from 'scripts/common/prop-types/judge_status';
import { showModal } from 'scripts/common/widgets/modal';
import JudgeStatusFilter from 'scripts/apps/widgets/judge_status/filter';
import { AsgnJudgeStatusActions } from 'scripts/common/logic/education/asgn/actions/judge_status';
import JudgeStatusListTable from 'scripts/apps/widgets/judge_status/list_table';
import { AsgnJudgeStatusSelectors } from 'scripts/common/logic/education/asgn/selectors/judge_status';
import { createJudgeResultModal } from 'scripts/apps/widgets/judge_status/dialog';
import { AccountSelectors, AccountActions } from 'scripts/common/logic/account';
import { getQuery, buildPath, buildResourcePath } from 'scripts/common/utils/location_helper';
import { indexToChar } from 'scripts/common/utils/unit';
import { AccountRoutes, AsgnProblemRoutes, AsgnRoutes } from 'scripts/apps/routes';

const LOADING_STATUS_KEY = 'asgn_judge_status_list';

const JudgeResultModal = createJudgeResultModal(AsgnJudgeStatusActions, AsgnJudgeStatusSelectors, 'Asgn');

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnJudgeStatusList: AsgnJudgeStatusActions.getAsgnJudgeStatusList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getAccountInfo: AccountActions.getAccountInfo,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const { match } = props;
  const problemId  = get(match, 'params.problemId', null);
  const courseId = parseInt(get(match, 'params.courseId', 0), 10);
  const asgnId = parseInt(get(match, 'params.asgnId', 0), 10);
  const judgeStatusDatas = AsgnJudgeStatusSelectors.getJudgeStatusListData(state);
  const pager = AsgnJudgeStatusSelectors.AsgnJudgeStatusListViewPagerSelector(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const queryParams = {
    ...PaginationParams40,
    ...pick(queryset, [
      'limit', 'page', 'problem', 'order', 'author',
      'flag', 'problem_id', 'filter_owner'
    ]),
    courseId,
    asgnId,
  };
  const currentRoutePath = problemId ? AsgnProblemRoutes.JUDGE_STATUS : AsgnRoutes.JUDGE_HISTORY;
  return {
    isLoading,
    queryParams,
    courseId,
    asgnId,
    judgeStatusDatas,
    currentRoutePath,
    problemId,
    paginationParams: pager || PaginationParams40,
    account: AccountSelectors.getLoginAccount(state),
    state,
  };
};

@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class JudgeStatusView extends React.PureComponent {
  static fetchList = (nextProps) => {
    const isFilterOwner = nextProps.filterOwner || get(nextProps.queryParams, 'filter_owner') === '1';
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getAsgnJudgeStatusList({
      courseId: nextProps.courseId,
      asgnId: nextProps.asgnId,
      query: {
        ...pick(
          nextProps.queryParams,
          ['limit', 'page', 'problem', 'order', 'author', 'flag', 'problem_id', 'author_id']
        ),
        ...(isFilterOwner ? { author_id: get(nextProps, 'account.id') } : {}),
        ...(nextProps.problemId ? { problem_id: nextProps.problemId } : {}),
      },
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    getAsgnJudgeStatusList: PropTypes.func,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    setLoadingStatus: PropTypes.func,
    judgeStatusDatas: PropTypes.arrayOf(JudgeStatusPropTypes),
    currentRoutePath: PropTypes.string,
    size: PropTypes.string,
    problemId: PropTypes.string,
    isLoading: PropTypes.bool,
    paginationParams: PaginationPropTypes,
    showOwner: PropTypes.bool,
    filterOwner: PropTypes.bool,
  };

  static defaultProps = {
    isLoading: true,
    getAsgnJudgeStatusList: noop,
    setLoadingStatus: noop,
    judgeStatusDatas: [],
    currentRoutePath: '',
    size: 'normal',
    problemId: '',
    paginationParams: null,
    showOwner: false,
    filterOwner: false,
  };

  state = {
    queryParams: {},
    showModal: false,
    author: {},
    counter: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      JudgeStatusView.fetchList(nextProps, prevState);
      return {
        queryParams,
      };
    }
    return null;
  }

  refreshList = () => {
    JudgeStatusView.fetchList(this.props);
  };

  handleReload = () => {
    JudgeStatusView.fetchList(this.props);
  };

  handleShowModal = (item) => {
    this.props.getAccountInfo({
      accountId: item.author.id,
      onSuccess: () => {
        this.setState({
          showModal: true,
          author: {
            ...item,
            author: AccountSelectors.getAccountEntity(this.props.state)(item.author.id),
          },
        });
      },
    });
  };

  handleHideModal = () => {
    this.setState({
      showModal: false,
    });
  }

  handleProblemClick = (probelm) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnProblemRoutes.CONTENT,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemId: get(probelm, 'asgn_problem_item.id', ''),
      },
      match.params,
      {}
    ));
  };

  handleJudgeStatusClick = judgeStatus => () => {
    showModal(JudgeResultModal, {
      judgeStatusId: get(judgeStatus, 'id'),
      apiPayloads: {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
      },
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
      },
      match.params,
      {
        ...pick(this.state.queryParams, 'limit', 'problem', 'order', 'author', 'problem_id', 'flag', 'filter_owner'),
        limit: pageSize,
        page,
      }
    ));
  };

  handleFlagClick = (value) => {
    let key = value;
    if (value !== 'all') {
      key = parseInt(value, 10);
    }
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemId: this.props.problemId,
      },
      match.params,
      key === 'all'
        ? {
          ...pick(this.state.queryParams, 'limit', 'page', 'problem', 'order', 'author', 'problem_id', 'filter_owner'),
        } : {
          ...pick(this.state.queryParams, 'limit', 'page', 'problem', 'order', 'author', 'problem_id', 'filter_owner'),
          flag: key,
        }
    ));
  };

  handleAuthorSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemId: this.props.problemId,
      },
      match.params,
      value
        ? {
          ...pick(this.state.queryParams, 'limit', 'page', 'problem', 'order', 'flag', 'problem_id', 'filter_owner'),
          author: value,
        } : {
          ...pick(this.state.queryParams,  'limit', 'page', 'problem', 'order', 'flag', 'problem_id', 'filter_owner'),
          author: value,
        }
    ));
  };

  handleNameSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemId: this.props.problemId,
      },
      match.params,
      value === ''
        ? {
          ...pick(this.state.queryParams, 'limit', 'page', 'order', 'author', 'flag', 'problem_id', 'filter_owner'),
        } : {
          ...pick(this.state.queryParams,  'limit', 'page', 'author', 'order', 'flag', 'problem_id', 'filter_owner'),
          problem: value,
        }
    ));
  };

  handleOrderClick = (value) => {
    const num = value ? 1 : 0;
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemId: this.props.problemId,
      },
      match.params,
      {
        ...pick(this.state.queryParams, 'limit', 'page', 'author', 'flag', 'problem_id', 'problem', 'filter_owner'),
        order: num,
      }
    ));
  };

  handleClickAuthor = (accountId) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AccountRoutes.PROFILE.INDEX,
      {
        accountId,
      }
    ));
  };

  handleOwnerClick = (checked) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemId: this.props.problemId,
      },
      match.params,
      {
        ...pick(this.state.queryParams, 'limit', 'page', 'author', 'flag', 'problem_id', 'problem', 'order'),
        filter_owner: checked ? '1' : '0',
      }
    ));
  };

  renderProblemLabel = (item) => {
    return <Tooltip title={get(item, 'asgn_problem_item.problem.title', '')}>
      {`题目${indexToChar(get(item, 'asgn_problem_item.order', 0))}`}
    </Tooltip>;
  };

  renderAuthorLabel = (item) => {
    return <a onClick={() => this.handleShowModal(item)}> {get(item, 'student.realname', get(item, 'author.realname', '')) }</a>;
  };

  render() {
    const isFilterOwner = this.props.filterOwner || get(this.state.queryParams, 'filter_owner') === '1';
    return (
      <div className="judge_status_view">
        <JudgeStatusFilter
          onNameSearch={this.handleNameSearch}
          onAuthorSearch={this.handleAuthorSearch}
          onOrderClick={this.handleOrderClick}
          onFlagClick={this.handleFlagClick}
          onOwnerClick={this.handleOwnerClick}
          onReload={this.handleReload}
          filterOwner={isFilterOwner}
          problemId={this.props.problemId}
          showOwner={this.props.showOwner}
          hideSearch={this.props.size === 'minimal'}
          flag={get(this.state.queryParams, 'flag', 'all')}
          authorKw={get(this.state.queryParams, 'author', '')}
          problemKw={get(this.state.queryParams, 'problem', '')}
          orderAsc={get(this.state.queryParams, 'order', false) === '1'}
          problemOrder={get(this.state.queryParams, 'problem_order', 'all')}
        />
        <JudgeStatusListTable
          bordered
          loading={this.props.isLoading}
          columnStyle={this.props.size}
          onPageChange={this.handlePageChange}
          judgeStatusDatas={this.props.judgeStatusDatas}
          onProblemClick={this.handleProblemClick}
          onJudgeStatusClick={this.handleJudgeStatusClick}
          paginationParams={this.props.paginationParams}
          renderProblemLabel={this.renderProblemLabel}
          renderAuthorLabel={this.renderAuthorLabel}
        />
        <Modal
          title="个人简介"
          visible={this.state.showModal}
          onCancel={this.handleHideModal}
          footer={false}
        >
          <div className="author_modal">
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              <Avatar
                icon={<UserOutlined />}
                size={110}
                src={buildResourcePath(get(this.state, 'author.author.avator'))}
                style={{ marginRight: 8 }}
              />
              <div style={{ margin: '16px 0 4px 0', fontSize: '20px', fontWeight: 'bolder' }}>
                {get(this.state.author, 'author.nickname')}
              </div>
              {this.state.author.student &&  <div style={{ margin: ' 0 0 4px 0', fontSize: '20px', fontWeight: 'bolder' }}>
                {get(this.state.author, 'student.realname')}同学
              </div>}
              <div style={{ margin: '4px 0 16px 0', color: 'grey' }}>
                {truncate(get(this.state.author.author, 'motto'), { length: 60 }) || '这个人很懒，啥也没写┑(￣Д ￣)┍'}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '32px' }}>
                  <Statistic title="代码通过数" value={get(this.state.author, 'author.counter.code_accepted', 0)} suffix={`/ ${get(this.state.author, 'author.counter.code_visited', 0)}`} />
                </div>
                <Statistic title="提交评测" value={get(this.state.author, 'author.counter.judge_status_accepted', 0)} suffix={`/ ${get(this.state.author, 'author.counter.judge_status_submission', 0)}`} />
              </div>
              <div style={{ margin: '32px 0 16px 0' }}>
                <Button
                  type="primary"
                  onClick={() => this.handleClickAuthor(this.state.author.author.id)}
                >
                  个人空间
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default JudgeStatusView;
