import React from 'react';
import PropTypes from 'prop-types';
import {
  set, get, noop, isEmpty, pick, isEqual, uniq, indexOf 
} from 'lodash';
import {
  Table, Divider, Switch, DatePicker, Button, Select, Alert 
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { CourseActions, CourseSelectors } from 'scripts/common/logic/education/course';
import { ArrangementPropTypes } from 'scripts/common/prop-types/course';
import { StudentsActions, StudentsSelectors } from 'scripts/common/logic/education/students';
import { store } from 'scripts/common/logic/store';
import {
  createMoment,
  createMomentUTC,
  momentToUnixTimeStamp,
  nowTime
} from 'scripts/common/utils/time_formatter';
import { AsgnActions } from 'scripts/common/logic/education/asgn/actions/asgn';
import StudentPicker from 'scripts/apps/education/widgets/student_picker';

@connect((state) => {
  const arrangements = CourseSelectors.getArrangementList(state);
  const studentEntitySelector = StudentsSelectors.studentsEntitiesSelector(state);
  return {
    arrangements,
    studentEntitySelector,
  };
}, dispatch => bindActionCreators({
  editAsgn: AsgnActions.editAsgn,
  getArrangementsList: CourseActions.getArrangementsList,
  getStudentsEntities: StudentsActions.getStudentsEntities,
}, dispatch))
class AsgnAccessControlView extends React.Component {
  static propTypes = {
    asgnId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    asgn: AsgnPropTypes.isRequired,
    arrangements: PropTypes.arrayOf(ArrangementPropTypes),
    getArrangementsList: PropTypes.func,
    getStudentsEntities: PropTypes.func,
    editAsgn: PropTypes.func,
    studentEntitySelector: PropTypes.func,
  };

  static defaultProps = {
    arrangements: [],
    getArrangementsList: noop,
    editAsgn: noop,
    getStudentsEntities: noop,
    studentEntitySelector: noop,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let output = {};
    if (!prevState.fetchedArrangement && isEmpty(nextProps.arrangements)) {
      nextProps.getArrangementsList({
        courseId: nextProps.courseId,
      });
      return {
        fetchedArrangement: true,
      };
    }
    if (!isEqual(prevState.courseArrangements, nextProps.arrangements)) {
      const arrSettings = JSON.parse(JSON.stringify(get(nextProps.asgn, 'options.access_control.arrangement', {})));
      // 合并排课数据
      const { arrangements } = nextProps;
      const arrangement = {};
      Object.keys(arrangements).forEach((aid) => {
        const item = arrangements[aid];
        const arr = get(arrSettings, item.id, {
          start_time: 0,
          end_time: 0,
          enable: false,
        });
        const defaultValue = [
          nowTime().set(pick(createMomentUTC(get(item, 'start_time', 0)).toObject(), ['hours', 'minutes', 'seconds'])),
          nowTime().set(pick(createMomentUTC(get(item, 'end_time', 0)).toObject(), ['hours', 'minutes', 'seconds']))
        ];
        const startTime = moment(arr.start_time * 1000);
        const endTime = moment(arr.end_time * 1000);
        arrangement[item.id] = {
          start_time: arr.start_time ? startTime : defaultValue[0],
          end_time: arr.end_time ? endTime : defaultValue[1],
          enable: get(arr, 'enable', false),
        };
      });
      output = {
        courseArrangements: nextProps.arrangements,
        arrangement,
      };
    }
    if (!isEqual(prevState.asgn, nextProps.asgn)) {
      const courseChanging = JSON.parse(JSON.stringify(get(nextProps.asgn, 'options.access_control.course_changing', {})));
      const blackList = JSON.parse(JSON.stringify(get(nextProps.asgn, 'options.access_control.black_list', [])));
      const groupList = JSON.parse(JSON.stringify(get(nextProps.asgn, 'options.access_control.groups', [])));
      let updStudentIds = [];
      const studentInfoEntitySelector = StudentsSelectors.getStudentInfoEntity(store.getState());
      // 获取需要更新的学生列表
      Object.keys(courseChanging).forEach((sid) => { updStudentIds.push(sid); });
      blackList.forEach((sid) => { updStudentIds.push(sid); });
      groupList.forEach((g) => { get(g, 'students', []).forEach((sid) => { updStudentIds.push(sid); }); });
      updStudentIds = uniq(updStudentIds);
      updStudentIds = updStudentIds.filter(sid => !studentInfoEntitySelector(sid));
      if (!prevState.fetchedStudents && !isEmpty(updStudentIds)) {
        nextProps.getStudentsEntities({
          cid: nextProps.courseId,
          ids: updStudentIds,
        });
      }
      const preGroupIndex = prevState.groupIndex < 0 ? 0 : prevState.groupIndex;
      output = {
        ...output,
        fetchedStudents: true,
        asgn: nextProps.asgn,
        courseChanging,
        blackList,
        groupList,
        groupIndex: groupList.length <= 0 ? -1 : preGroupIndex,
      };
    }
    return isEmpty(output) ? null : output;
  }

  state = {
    asgn: null,
    arrangement: {},
    courseChanging: {},
    courseArrangements: {},
    blackList: [],
    groupList: [],
    groupIndex: -1,
    fetchedStudents: false,
    fetchedArrangement: false,
  };

  ARRANGEMENT_LIST_COLUMN = [
    {
      dataIndex: 'name',
      title: '排课名称',
    },
    {
      dataIndex: 'enable',
      title: '启用',
      width: 200,
      align: 'center',
    },
    {
      dataIndex: 'timepicker',
      title: '有效期间',
      width: 500,
      align: 'center',
    }
  ];

  COURSE_CHANGING_LIST_COLUMN = [
    {
      dataIndex: 'code',
      title: '学号',
      width: 250,
    },
    {
      dataIndex: 'name',
      title: '姓名',
      width: 200,
      align: 'center',
    },
    {
      dataIndex: 'target',
      title: '目标排课',
    },
    {
      dataIndex: 'action',
      title: '操作',
      align: 'center',
      width: 100,
    }
  ];

  BLACK_LIST_COLUMN = [
    {
      dataIndex: 'code',
      title: '学号',
      width: 250,
    },
    {
      dataIndex: 'name',
      title: '姓名',
      align: 'center',
    },
    {
      dataIndex: 'action',
      title: '操作',
      align: 'center',
      width: 100,
    }
  ];

  GROUP_STUDENT_LIST_COLUMN = [
    {
      dataIndex: 'code',
      title: '学号',
      width: 250,
    },
    {
      dataIndex: 'name',
      title: '姓名',
      align: 'center',
    },
    {
      dataIndex: 'action',
      title: '操作',
      align: 'center',
      width: 100,
    }
  ];

  handleSubmit = () => {
    const arr = this.state.arrangement;
    Object.keys(this.state.arrangement).forEach((key) => {
      arr[key].start_time = (arr[key].start_time instanceof moment)
        ? momentToUnixTimeStamp(arr[key].start_time) : arr[key].start_time;
      arr[key].end_time = (arr[key].end_time instanceof moment)
        ? momentToUnixTimeStamp(arr[key].end_time) : arr[key].end_time;
    });
    const groups = this.state.groupList.map((_item) => {
      const item = _item;
      item.start_time = (item.start_time instanceof moment)
        ? momentToUnixTimeStamp(item.start_time) : item.start_time;
      item.end_time = (item.end_time instanceof moment)
        ? momentToUnixTimeStamp(item.end_time) : item.end_time;
      return item;
    });
    const data = {
      courseId: this.props.courseId,
      asgnId: this.props.asgnId,
      asgn: {
        options: {
          access_control: {
            arrangement: arr,
            course_changing: this.state.courseChanging,
            black_list: this.state.blackList,
            groups,
          },
        },
      },
    };
    this.props.editAsgn(data);
  };

  handleArrangementChange = (pid, key, value) => {
    this.setState(set({ arrangement: { ...this.state.arrangement } }, `arrangement.${pid}.${key}`, value), () => {
    });
  };

  handleGroupInfoChange = (key, value) => {
    this.setState(set({ groupList: [...this.state.groupList] }, `groupList.${this.state.groupIndex}.${key}`, value));
  };

  handleCourseChangingAddStudent = (student) => {
    const studentInfoEntitySelector = StudentsSelectors.getStudentInfoEntity(store.getState());
    return new Promise((resolve) => {
      if (student) {
        const { courseChanging } = this.state;
        const studentId = student.id;
        courseChanging[studentId] = 0;
        if (!studentInfoEntitySelector(studentId)) {
          this.props.getStudentsEntities({
            cid: this.props.courseId,
            ids: [studentId],
          });
        }
        this.setState({
          courseChanging: { ...courseChanging },
        }, () => {
          resolve();
        });
      }
    });
  };

  handleCourseChangingArrangementChange = studentId => (value) => {
    const { courseChanging } = this.state;
    courseChanging[studentId] = value;
    this.setState({
      courseChanging: { ...courseChanging },
    });
  };

  handleCourseChangingArrangementDelete = studentId => () => {
    const { courseChanging } = this.state;
    this.setState({
      courseChanging: {
        ...pick(courseChanging, Object.keys(courseChanging).filter(sid => sid !== studentId)),
      },
    });
  };

  handleBlackListAddStudent = (student) => {
    const studentInfoEntitySelector = StudentsSelectors.getStudentInfoEntity(store.getState());
    return new Promise((resolve) => {
      if (student) {
        const { blackList } = this.state;
        const studentId = student.id;
        if (indexOf(blackList, studentId) === -1) {
          blackList.push(studentId);
        }
        if (!studentInfoEntitySelector(studentId)) {
          this.props.getStudentsEntities({
            cid: this.props.courseId,
            ids: [studentId],
          });
        }
        this.setState({
          blackList,
        }, () => {
          resolve();
        });
      }
    });
  };

  handleBlackListDeleteStudent = studentId => () => {
    const { blackList } = this.state;
    this.setState({
      blackList: blackList.filter(sid => sid !== studentId),
    });
  };

  handleGroupListAddStudent = (student) => {
    const studentInfoEntitySelector = StudentsSelectors.getStudentInfoEntity(store.getState());
    return new Promise((resolve) => {
      if (student) {
        const { groupList, groupIndex } = this.state;
        const students = get(groupList, `${groupIndex}.students`, []);
        const studentId = student.id;
        if (indexOf(students, studentId) === -1) {
          students.push(studentId);
        }
        if (!studentInfoEntitySelector(studentId)) {
          this.props.getStudentsEntities({
            cid: this.props.courseId,
            ids: [studentId],
          });
        }

        this.setState(set(
          { groupList: [...this.state.groupList] },
          `groupList.${this.state.groupIndex}.students`,
          students
        ), () => {
          resolve();
        });
      }
    });
  };

  handleGroupListDeleteStudent = studentId => () => {
    const { groupList, groupIndex } = this.state;
    const students = get(groupList, `${groupIndex}.students`, []).filter(sid => sid !== studentId);
    this.setState(set(
      { groupList: [...this.state.groupList] },
      `groupList.${this.state.groupIndex}.students`,
      students
    ));
  };

  handleAddGroup = () => {
    const { groupList } = this.state;
    groupList.push({
      students: [],
      start_time: nowTime().unix(),
      end_time: nowTime().unix(),
    });
    this.setState({
      groupIndex: groupList.length - 1,
      groupList,
    });
  };

  handleDeleteGroup = () => {
    let { groupList } = this.state;
    const { groupIndex } = this.state;
    if (groupIndex < 0 || groupList.length <= 0) return;
    groupList = groupList.filter((v, i) => i !== groupIndex);
    const newIndex = (groupIndex - 1 < 0) ? 0 : groupIndex - 1;
    this.setState({
      groupList,
      groupIndex: groupList.length === 0 ? -1 : newIndex,
    });
  };

  handleGroupSelectChange = (value) => {
    this.setState({
      groupIndex: value,
    });
  };

  renderArrangementSelector = (studentId, value) => {
    return <Select
      value={value}
      style={{ width: '100%' }}
      onChange={this.handleCourseChangingArrangementChange(studentId)}
    >
      {[
        <Select.Option key={0} value={0}>未安排</Select.Option>,
        ...this.props.arrangements.map((item) => {
          return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>;
        })
      ]}
    </Select>;
  };

  renderArrangements = () => {
    const { arrangements } = this.props;
    return arrangements.map((item) => {
      const arr = get(this.state.arrangement, item.id, {});
      let startTime = get(arr, 'start_time', 0);
      let endTime = get(arr, 'end_time', 0);
      if (!(startTime instanceof moment)) {
        startTime = moment(startTime * 1000);
      }
      if (!(endTime instanceof moment)) {
        endTime = moment(endTime * 1000);
      }
      return {
        key: item.id,
        name: item.name,
        timepicker: <DatePicker.RangePicker
          format="YYYY-MM-DD HH:mm:ss"
          showTime={{
            hideDisabledOptions: true,
            // defaultValue: [get(arr, 'start_time', 0), get(arr, 'end_time', 0)],
          }}
          style={{ width: '100%' }}
          value={[startTime, endTime]}
          onChange={(times) => {
            const st = momentToUnixTimeStamp(times[0]) || 0;
            const et = momentToUnixTimeStamp(times[1]) || 0;
            this.handleArrangementChange(item.id, 'start_time', times[0]);
            this.handleArrangementChange(item.id, 'end_time', times[1]);
          }}
        />,
        enable: <Switch
          onChange={(checked) => { this.handleArrangementChange(item.id, 'enable', checked); }}
          checked={arr.enable}
        />,
      };
    });
  };

  renderCourseChanging = () => {
    const { courseChanging } = this.state;
    const ccrec = {
      ...courseChanging,
      new: 0,
    };
    return Object.keys(ccrec).map((studentId) => {
      const arrId = get(ccrec, studentId, 0);
      if (studentId === 'new') {
        return {
          key: 'new',
          code: <StudentPicker
            onSelectChange={this.handleCourseChangingAddStudent}
            allowNew={false}
            schoolId={get(this.props.asgn, 'course.school.id', 0)}
            excludeStudentIds={Object.keys(courseChanging).map(id => parseInt(id, 10))}
          />,
        };
      }
      const student = this.props.studentEntitySelector(studentId);
      return {
        key: studentId,
        code: get(student, 'code', ''),
        name: get(student, 'realname', ''),
        target: this.renderArrangementSelector(studentId, parseInt(arrId, 10)),
        action: <Button
          type="danger"
          size="small"
          onClick={this.handleCourseChangingArrangementDelete(studentId)}
        >
          删除
        </Button>,
      };
    });
  };

  renderGroupList = () => {
    const { groupList, groupIndex } = this.state;
    const blist = [
      ...get(groupList, `${groupIndex}.students`, []),
      'new'
    ];
    return blist.map((studentId) => {
      if (studentId === 'new') {
        return {
          key: 'new',
          code: <StudentPicker
            onSelectChange={this.handleGroupListAddStudent}
            allowNew={false}
            schoolId={get(this.props.asgn, 'course.school.id', 0)}
            excludeStudentIds={get(groupList, `${groupIndex}.students`, [])}
          />,
        };
      }
      const student = this.props.studentEntitySelector(studentId);
      return {
        key: studentId,
        code: get(student, 'code', ''),
        name: get(student, 'realname', ''),
        action: <Button
          type="danger"
          size="small"
          onClick={this.handleGroupListDeleteStudent(studentId)}
        >
          删除
        </Button>,
      };
    });
  };

  renderBlackList = () => {
    const { blackList } = this.state;
    const blist = [
      ...blackList,
      'new'
    ];
    return blist.map((studentId) => {
      if (studentId === 'new') {
        return {
          key: 'new',
          code: <StudentPicker
            onSelectChange={this.handleBlackListAddStudent}
            allowNew={false}
            schoolId={get(this.props.asgn, 'course.school.id', 0)}
            excludeStudentIds={blackList}
          />,
        };
      }
      const student = this.props.studentEntitySelector(studentId);
      return {
        key: studentId,
        code: get(student, 'code', ''),
        name: get(student, 'realname', ''),
        action: <Button
          type="danger"
          size="small"
          onClick={this.handleBlackListDeleteStudent(studentId)}
        >
          删除
        </Button>,
      };
    });
  };

  renderGroupArea = () => {
    const { groupList, groupIndex } = this.state;
    const nowGroup = get(groupList, groupIndex, {});
    const startTime = createMoment(get(nowGroup, 'start_time', nowTime().unix()));
    const endTime = createMoment(get(nowGroup, 'end_time', nowTime().unix()));
    return (<>
      <div className="group_control_bar">
        <div className="group_selector">
          <Select
            value={this.state.groupIndex === -1 ? '请在右侧添加分组' : this.state.groupIndex}
            style={{ width: '100%' }}
            onChange={this.handleGroupSelectChange}
          >
            {this.state.groupList.map((item, key) => {
              return <Select.Option key={key} value={key}>分组 {key + 1}</Select.Option>;     // eslint-disable-line
            })}
          </Select>
        </div>
        <div className="time_settings">
          <DatePicker.RangePicker
            disabled={groupIndex === -1}
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [startTime, endTime],
            }}
            defaultValue={[startTime, endTime]}
            style={{ width: 500 }}
            onChange={(times) => {
              const st = momentToUnixTimeStamp(times[0]) || 0;
              const et = momentToUnixTimeStamp(times[1]) || 0;
              this.handleGroupInfoChange('start_time', st);
              this.handleGroupInfoChange('end_time', et);
            }}
          />
        </div>
        <div className="group_action">
          <Button
            type="primary"
            onClick={this.handleAddGroup}
          >
            增加
          </Button>
          <Button
            type="danger"
            disabled={groupIndex === -1}
            onClick={this.handleDeleteGroup}
          >
            删除
          </Button>
        </div>
      </div>
      <Table
        bordered
        size="middle"
        columns={this.GROUP_STUDENT_LIST_COLUMN}
        dataSource={this.renderGroupList()}
        pagination={false}
      />
    </>);
  };

  renderMessage = () => {
    return (<ul className="message_ul">
      <li>完成设置后，请点击【保存修改】以确认将设置保存到服务器并生效。</li>
      <li><strong>下列各个设置优先覆盖顺序：</strong>黑名单 &gt; 分组设置 &gt; 调课设置 &gt; 排课设置</li>
    </ul>);
  };

  render() {
    return (<div className="asgn_access_control_view">
      <div className="control_setting_content">
        <Alert message="操作说明" description={this.renderMessage()} />
        <Divider orientation="left">排课设置</Divider>
        <Table
          bordered
          size="middle"
          columns={this.ARRANGEMENT_LIST_COLUMN}
          dataSource={this.renderArrangements()}
          pagination={false}
        />
        <Divider orientation="left">调课设置</Divider>
        <Table
          bordered
          size="middle"
          columns={this.COURSE_CHANGING_LIST_COLUMN}
          dataSource={this.renderCourseChanging()}
          pagination={false}
        />
        <Divider orientation="left">分组设置</Divider>
        {this.renderGroupArea()}
        <Divider orientation="left">黑名单</Divider>
        <Table
          bordered
          size="middle"
          columns={this.BLACK_LIST_COLUMN}
          dataSource={this.renderBlackList()}
          pagination={false}
        />
      </div>
      <div className="submit_btn">
        <Button
          type="primary"
          onClick={this.handleSubmit}
        >
          保存修改
        </Button>
      </div>
    </div>);
  }
}

export default AsgnAccessControlView;
