import 'styles/contest/support/print.scss';

import React from 'react';
import { Button, Modal, PageHeader } from 'antd';
import PrinterListTable from 'scripts/apps/contest/support/widgets/printer_list_table';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, isEqual, pick } from 'lodash';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { ContestRoutes } from 'scripts/apps/routes';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ContestSupportActions, ContestSupportSelectors } from 'scripts/common/logic/contest/support';
import { PaginationParams } from 'scripts/common/constants/global';
import { WeJudgeSelectors, WeJudgeActions } from 'scripts/common/logic/wejudge';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { filterXss } from 'scripts/common/utils/filter';
import { PrintTool } from 'react-print-tool';
import PrinterBaseTemplates from 'scripts/apps/contest/support/widgets/printer_base_templates';

const LOADING_STATUS_KEY = 'contest_printer_list_view';

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const pager = ContestSupportSelectors.contestPrinterListViewPagerSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page']),
  };
  const currentRoutePath = ContestRoutes.SUPPORT.PRINT;

  return {
    paginationParams: pager || props.paginationParams,
    queryParams,
    isLoading,
    printerListData: ContestSupportSelectors.getContestPrinterListData(state),
    currentRoutePath,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getPrinterList: ContestSupportActions.getPrinterList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  addPrinter: ContestSupportActions.addPrinter,
  delPrinter: ContestSupportActions.delPrinter,
  confirmPrinter: ContestSupportActions.confirmPrinter,
  refusePrinter: ContestSupportActions.refusePrinter,
}, dispatch);

@withRouter
@ContestLoginRequire
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class PrintView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const query = pick(
      nextProps.queryParams,
      ['limit', 'page']
    );
    nextProps.getPrinterList({
      query,
      contestId: nextProps.contest.id,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    getPrinterList: PropTypes.func.isRequired,
    paginationParams: PaginationPropTypes.isRequired,
    queryParams: PropTypes.shape({}).isRequired,
    addPrinter: PropTypes.func.isRequired,
    confirmPrinter: PropTypes.func.isRequired,
    refusePrinter: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state= {
    mode: 'list',
    queryParams: null,
    content: '',
    printItem: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      PrintView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  handleAddClick = () => {
    this.props.addPrinter({
      contestId: this.props.contest.id,
      content: this.state.content,
      onSuccess: () => {
        PrintView.fetchList(this.props);
        this.setState({
          mode: 'list',
          content: '',
        });
      },
    });
  };

  handleDelPrinter = item => () => {
    Modal.confirm({
      okText: '确定',
      cancelText: '取消',
      content: '您确认删除这条信息吗？',
      onOk: () => {
        this.props.delPrinter({
          contestId: this.props.contest.id,
          printerId: item.id,
          onSuccess: () => {
            PrintView.fetchList(this.props);
          },
        });
      },
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleConfirmPrinter = () => {
    const { printItem } = this.state;
    if (!printItem) return;
    PrintTool.printFromReactComponent(<PrinterBaseTemplates
      item={printItem}
      contest={this.props.contest}
    />);
    this.props.confirmPrinter({
      ids: [printItem.id],
      contestId: this.props.contest.id,
      onSuccess: () => {
        PrintView.fetchList(this.props);
        this.handleBack();
      },
    });
  };

  handleRefusePrinter = () => {
    const { printItem } = this.state;
    if (!printItem) return;
    this.props.refusePrinter({
      ids: [printItem.id],
      contestId: this.props.contest.id,
      onSuccess: () => {
        PrintView.fetchList(this.props);
        this.handleBack();
      },
    });
  };

  handleChangeMode = key => () => {
    this.setState({ mode: key, printItem: null, content: '' });
  };

  handleEditorChange = (text) => {
    this.setState({
      content: text,
    });
  };

  handleDoProcess = (item) => {
    this.setState({
      mode: 'view',
      printItem: item,
    });
  };

  handleBack = () => {
    this.handleChangeMode('list')();
  };

  renderPrinterView = () => {
    const { printItem } = this.state;
    return <div className="printer-view-body">
      <RichtextContext>{filterXss(get(printItem, 'content', ''))}</RichtextContext>
    </div>;
  };

  render() {
    const role = get(this.props.account, 'role', 0);
    const isManager = role === ContestAccountRoleEnum.REFEREE.value;
    const titleMapping = { list: '资料打印', add: '申请打印资料', view: '预览内容' };
    const pendingProcess = get(this.state.printItem, 'status', 0) === 0;
    const extraButtons = {
      list: [<Button key="add" type="primary" onClick={this.handleChangeMode('add')}>申请打印资料</Button>],
      add: [<Button key="save" type="primary" onClick={this.handleAddClick}>申请打印</Button>],
      view: isManager ? [
        <Button key="reject" onClick={this.handleRefusePrinter} disabled={!pendingProcess}>拒绝打印</Button>,
        <Button key="confirm" type="primary" onClick={this.handleConfirmPrinter}>{pendingProcess ? '执行打印' : '再次打印'}</Button>
      ] : [],
    };
    return (
      <div className="contest-print-view">
        <PageHeader
          onBack={this.state.mode !== 'list' ? this.handleBack : false}
          title={titleMapping[this.state.mode]}
          extra={extraButtons[this.state.mode]}
        />
        {this.state.mode === 'list' && <PrinterListTable
          datas={this.props.printerListData}
          onPageChange={this.handlePageChange}
          isManager={isManager}
          paginationParams={this.props.paginationParams}
          onDelPrinter={this.handleDelPrinter}
          onDoProcess={this.handleDoProcess}
        />}
        {this.state.mode === 'add' && <div className="printer_editor">
          <MarkdownEditor
            height="100%"
            config={{
              view: {
                menu: true,
                md: true,
                html: true,
              },
            }}
            disablePlugins={['upload-image']}
            onChange={this.handleEditorChange}
            defaultValue={'```\n// 可在此处插入代码\n```'}
          />
        </div>}
        {this.state.mode === 'view' && this.state.printItem && this.renderPrinterView()}
      </div>
    );
  }
}

export default PrintView;
