import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Form, Input, Radio, Row, Col, Switch, Divider, Button, Cascader, Alert
} from 'antd';
import { CollectionTypeEnum } from 'scripts/common/enums/collection';

import { CollectionPropTypes } from 'scripts/common/prop-types/collection';
import JudgeLanguageCheckBox from 'scripts/common/widgets/language_checkbox';
import WeJudgeAvatarEditor from 'scripts/common/widgets/avatar';
import { bindActionCreators } from 'redux';
import { CategoryActions } from 'scripts/common/logic/category/action';
import { CategorySelectors } from 'scripts/common/logic/category/selector';
import { CategoryPropTypes } from 'scripts/common/prop-types/category';
import { connect } from 'react-redux';
import { CommonCategoryTypeEnum } from 'scripts/common/enums/common';

const { TextArea } = Input;
const RadioGroup = Radio.Group;

const mapDispatchToProps = dispatch => bindActionCreators({
  getCategoryTree: CategoryActions.getCategoryTree,
}, dispatch);

const mapStateToProps = (state) => {
  const categoryTree = CategorySelectors.categoryTreeViewSelector(
    CommonCategoryTypeEnum.COLLECTION.value
  )(state) || [];
  return {
    categoryTree,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class CollectionInfoEditor extends React.PureComponent {
  static propTypes = {
    mode: PropTypes.string,
    collection: CollectionPropTypes,
    editCollections: PropTypes.func,
    handleChange: PropTypes.func,
    getCategoryTree: PropTypes.func,
    categoryTree: PropTypes.arrayOf(CategoryPropTypes),
  };

  static defaultProps = {
    mode: 'new',
    collection: {},
    categoryTree: {},
    editCollections: noop,
    getCategoryTree: noop,
    handleChange: noop,
  };

  state = {
    collection: {
      type: CollectionTypeEnum.PRIVATE.value,
      code_language: 0,
      description: '',
      title: '',
      publish_private: false,
      ...JSON.parse(JSON.stringify(this.props.collection)),
      category: [
        get(this.props.collection, 'category.parent.id', get(this.props.collection, 'category.id', '')),
        get(this.props.collection, 'category.id', '')
      ],
      thumb_img: '',
    },
    processing: false,
  };

  componentDidMount() {
    this.props.getCategoryTree({
      type: 1,
    });
  }

  handleInputChange = keyName => ({ target }) => {
    this.setState({
      collection: {
        ...this.state.collection,
        [keyName]: target.value,
      },
    }, () => {
      this.props.handleChange(this.state.collection);
    });
  };

  handleCategoryChange = (e) => {
    this.setState({
      collection: {
        ...this.state.collection,
        category: e,
      },
    },  () => {
      this.props.handleChange(this.state.collection);
    });
  };

  handleEditCollection = () => {
    this.setState({
      processing: true,
    }, () => {
      this.props.editCollections({
        id: get(this.props.collection, 'id'),
        ...this.state.collection,
        category: get(this.state.collection, 'category.1', get(this.state.collection, 'category.0', 0)),
        onComplete: () => {
          this.setState({
            processing: false,
          });
        },
      });
    });
  };

  handleThumbConfirm = (imageData) => {
    this.handleInputChange('thumb_img')({
      target: {
        value: imageData,
      },
    });
  };

  renderInfoEditArea = () => {
    const TypeTabItems = [
      CollectionTypeEnum.PUBLIC,
      CollectionTypeEnum.SHARED,
      CollectionTypeEnum.PRIVATE
    ];
    const { collection } = this.state;
    get(this.props.collection, 'category', []);
    const typeSelected = CollectionTypeEnum.enumValueOf(collection.type);
    return <>
      <Row gutter={16}>
        <Col flex="1 1 auto">
          <Form.Item>
            <Input
              key="title"
              value={collection.title}
              onChange={this.handleInputChange('title')}
              placeholder="题库名称"
            />
          </Form.Item>
        </Col>
        <Col flex="0 0 auto" align="right">
          <Form.Item>
            <Switch
              onChange={(checked) => { this.handleInputChange('publish_private')({ target: { value: checked } }); }}
              checked={collection.publish_private}
            /> 他人可发布题目
          </Form.Item>
        </Col>
      </Row>
      <Form.Item>
        <TextArea
          key="description"
          rows={6}
          value={collection.description}
          onChange={this.handleInputChange('description')}
          placeholder="题库描述"
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="题库分类">
            <Cascader
              fieldNames={{ label: 'title', value: 'id', children: 'children' }}
              options={this.props.categoryTree}
              expandTrigger="hover"
              onChange={this.handleCategoryChange}
              placeholder="请选择分类"
              value={collection.category}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item>
            <RadioGroup
              key="type"
              value={collection.type}
              onChange={this.handleInputChange('type')}
            >
              {TypeTabItems.map(item => (
                <Radio
                  key={item.key}
                  value={item.value}
                >
                  &nbsp;{item.typeName}
                </Radio>
              ))}
            </RadioGroup>
          </Form.Item>
        </Col>
      </Row>
      <Alert message={get(typeSelected, 'typeTip', '')} type="info" showIcon />
      <Divider orientation="left">可用评测语言</Divider>
      <JudgeLanguageCheckBox
        language={collection.code_language}
        onChange={this.handleInputChange('code_language')}
      />
      {(this.props.mode === 'edit') && <>
        <Divider />
        <div>
          <Button
            loading={this.state.processing}
            type="primary"
            onClick={this.handleEditCollection}
          >
            保存设置
          </Button>
        </div>
      </>}
    </>;
  };

  render() {
    const thumbImg = get(this.state.collection, 'thumb_img', '');
    return (this.props.mode === 'edit') ? (<div className="collection-editor-grid">
      <div className="left-area">
        {this.renderInfoEditArea()}
      </div>
      <div className="right-area">
        { thumbImg ? <>
          <img alt="" className="preview-img" src={thumbImg} width={270} height={152} />
          <Button
            className="cancel-upload-btn"
            type="danger"
            onClick={() => { this.handleThumbConfirm(''); }}
          >
            <DeleteOutlined /> 取消上传
          </Button>
        </>
          : <WeJudgeAvatarEditor
            borderWidth={20}
            borderHeight={20}
            width={270}
            height={152}
            onUpload={this.handleThumbConfirm}
          />}
      </div>
    </div>) : this.renderInfoEditArea();
  }
}

export default CollectionInfoEditor;
