import React from 'react';
import { Spin, Empty } from 'antd';
import 'styles/education/course/lesson_management.scss';
import LessonTree from 'scripts/apps/education/course/widgets/lesson_tree';
import { get, noop, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LessonActions, LessonSelectors } from 'scripts/common/logic/education/lesson';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import LoginRequire from 'scripts/apps/wejudge/auth';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { lessonListViewSelector } from 'scripts/common/logic/education/lesson/selector';
import LessonVideoManage from 'scripts/apps/education/course/widgets/lesson_video_manage';
import LessonAsgnManage from 'scripts/apps/education/course/widgets/lesson_asgn_manage';
import LessonDescEditor from 'scripts/apps/education/lesson/widgets/lesson_desc_editor';
import LessonRepoManage from 'scripts/apps/education/course/widgets/lesson_repo_manage';
import { RepositoryAction } from 'scripts/common/logic/repository/action';
import { RepositorySelectors } from 'scripts/common/logic/repository/selector';
import { LessonPropTypes } from 'scripts/common/prop-types/course';
import { CourseManagementRoutes } from 'scripts/apps/routes';
import { buildPath } from 'scripts/common/utils/location_helper';

const LOADING_STATUS_KEY = 'course_lesson_manage_view';

const mapStateToProps = (state, props) => {
  const lessonList = lessonListViewSelector(state);
  const myRepository = RepositorySelectors.getMyRepositoryEntity(state);
  const lessonId = get(matchPath(props.location.pathname, {
    path: CourseManagementRoutes.LESSON_VIEW,
  }), 'params.lessonId', '');
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const lesson = LessonSelectors.getLessonEntity(state)(lessonId);
  return {
    lesson,
    lessonId,
    lessonList,
    isLoading,
    myRepository,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getLessonsList: LessonActions.getLessonsList,
  addLesson: LessonActions.addLesson,
  editLesson: LessonActions.editLesson,
  delLesson: LessonActions.delLesson,
  getLesson: LessonActions.getLesson,
  getMyRepositoryInfo: RepositoryAction.getMyRepositoryInfo,
}, dispatch);

@LoginRequire
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class LessonManagementView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getLessonsList({
      courseId: nextProps.courseId,
      onSuccess: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    lesson: LessonPropTypes,
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.string,
    isLoading: PropTypes.bool,
    lessonList: PropTypes.arrayOf(LessonPropTypes),
    addLesson: PropTypes.func,
    editLesson: PropTypes.func,
    delLesson: PropTypes.func,
    getLessonsList: PropTypes.func,
  };

  static defaultProps = {
    getLessonsList: noop,
    lesson: null,
    lessonId: '',
    isLoading: false,
    addLesson: noop,
    editLesson: noop,
    delLesson: noop,
    lessonList: [],
  };

  state = {
    hasFetchList: false,
    currentLessonId: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchList) {
      LessonManagementView.fetchList(nextProps);
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getMyRepositoryInfo({
        onSuccess: () => {
          nextProps.getLessonsList({
            courseId: nextProps.courseId,
            onSuccess: () => {
              nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
            },
          });
        },
      });
      return {
        hasFetchList: true,
      };
    } if (!isEqual(prevState.currentLessonId, nextProps.lessonId)) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getLesson({
        courseId: nextProps.courseId,
        id: nextProps.lessonId,
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        currentLessonId: nextProps.lessonId,
      };
    }
    return null;
  }

  handleFetchList = () => {
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.props.getLessonsList({
      courseId: this.props.courseId,
      onSuccess: () => {
        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  handleTreeNodeClick = (item) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CourseManagementRoutes.LESSON_VIEW,
      {
        lessonId: item,
      },
      match.params
    ));
  };

  handleAsgnChange = (result, payload = noop) => {
    this.props.editLesson({
      asgn: result,
      courseId: this.props.courseId,
      lessonId: this.props.lessonId,
      onSuccess: () => {
        payload();
      },
    });
  };

  handleRefreshLessonEntity = (cb = noop) => {
    // 触发刷新LessonEntity
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.props.getLesson({
      courseId: this.props.courseId,
      id: this.props.lessonId,
      onComplete: () => {
        cb();
        this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  render() {
    const isGroupNode = get(this.props.lesson, 'is_group', true);
    return (<div className="lesson_management_view">
      <div className="left_sider">
        <LessonTree
          lessonList={this.props.lessonList}
          addLesson={this.props.addLesson}
          editLesson={this.props.editLesson}
          courseId={this.props.courseId}
          lessonId={this.props.lessonId}
          delLesson={this.props.delLesson}
          onTreeNodeClick={this.handleTreeNodeClick}
          onFetchList={this.handleFetchList}
        />
      </div>
      <div className="right_content">
        <Spin spinning={this.props.isLoading}>
          {isGroupNode ? <Empty description="请选择课堂节点进行操作" /> : <>
            {!get(this.props.myRepository, 'id', null) ? <div>
              <Empty description="未开通教学资源仓库，无法使用本功能" />
            </div> : <LessonVideoManage
              lessonId={this.props.lessonId}
              courseId={this.props.courseId}
              videoId={get(this.props.lesson, 'video.id', 0)}
              onNeedRefreshLessonEntity={this.handleRefreshLessonEntity}
            />}
            <LessonAsgnManage
              lesson={this.props.lesson}
              courseId={this.props.courseId}
              lessonId={this.props.lessonId}
              onEditLessonAsgn={this.handleAsgnChange}
            />
            {!get(this.props.myRepository, 'id', null) ? <div>
              <Empty description="未开通教学资源仓库，无法使用本功能" />
            </div> : <LessonRepoManage
              lessonId={this.props.lessonId}
              courseId={this.props.courseId}
              repoId={get(this.props.myRepository, 'id')}
              lesson={this.props.lesson}
            />}
            <LessonDescEditor
              lesson={this.props.lesson}
              courseId={this.props.courseId}
              lessonId={this.props.lessonId}
              editLesson={this.props.editLesson}
            />
          </>}
        </Spin>
      </div>
    </div>);
  }
}

export default LessonManagementView;
