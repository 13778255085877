import 'styles/common/layout/base.scss';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Layout } from 'antd';

import TopBar from './topbar';

const { Content } = Layout;

class BaseLayout extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <>
        <Layout className={classnames(['base-layout', this.props.className])}>
          <TopBar />
          <Content className="base-layout-content" id="base-layout-content">
            {this.props.children}
          </Content>
        </Layout>
      </>
    );
  }
}

export default BaseLayout;
