import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { CollectionRoutes } from 'scripts/apps/routes';
import BaseLayout from 'scripts/apps/wejudge/layout/base';

import ListView from './collection_list';
import CollectionView from './collection_view';
import CodeProblemView from './problems/code_problem_view';
import { WeJudge404NotFoundComponent } from '../wejudge/not_found_page';
import ProblemChoosingWidget from './widgets/problem_choosing';

const CollectionAPP = () => (
  <BaseLayout>
    <Switch>
      <Route path={CollectionRoutes.LIST} component={ListView} exact />
      <Route path={CollectionRoutes.CODE_PROBLEM_VIEW} component={CodeProblemView} />
      {/* 别改顺序！*/}
      <Route path={CollectionRoutes.COLLETION_VIEW} component={CollectionView} />
      <Route component={WeJudge404NotFoundComponent} />
    </Switch>
    <ProblemChoosingWidget />
  </BaseLayout>
);

export default CollectionAPP;
