import React from 'react';
import { noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { AccountActions } from 'scripts/common/logic/account/action';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import WeJudgeAvatarEditor from 'scripts/common/widgets/avatar';

const mapDispatchToProps = dispatch => bindActionCreators({
  editAccountMe: AccountActions.editAccountMe,
}, dispatch);

@connect(null, mapDispatchToProps)
class AvatarView extends React.PureComponent {
  static propTypes = {
    account: AccountPropTypes.isRequired,
    editAccountMe: PropTypes.func,
  };

  static defaultProps = {
    editAccountMe: noop,
  };

  state = {
    newAvator: '',
    processing: false,
  };

  handleAvatorConfirm = (imageData) => {
    this.setState({
      newAvator: imageData,
    });
  };

  handleEditAccount = () => {
    this.setState({
      processing: true,
    }, () => {
      this.props.editAccountMe({
        account: {
          avatar: this.state.newAvator,
        },
        onComplete: () => {
          this.setState({
            processing: false,
          });
        },
      });
    });
  };

  render() {
    const { newAvator } = this.state;
    return (
      <div className="avatar_view">
        <div className="avatar_panel">
          { newAvator ? <>
            <div align="center"><h2>确认要更换成此头像吗？</h2></div>
            <img className="preview-img" src={newAvator} width={180} height={180} alt="" />
            <Button
              loading={this.state.processing}
              className="confirm-upload-btn"
              type="primary"
              onClick={this.handleEditAccount}
            >
              <UploadOutlined /> 确认上传
            </Button>
            <Button
              className="cancel-upload-btn"
              type="danger"
              disabled={this.state.processing}
              onClick={() => { this.handleAvatorConfirm(''); }}
            >
              <DeleteOutlined /> 重新选择
            </Button>
          </> : <WeJudgeAvatarEditor
            borderWidth={50}
            borderHeight={30}
            width={180}
            height={180}
            onUpload={this.handleAvatorConfirm}
          />}
        </div>
      </div>
    );
  }
}

export default AvatarView;
