import React from 'react';
import { Layout } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { ContestRoutes } from 'scripts/apps/routes';
import LoginView from './login_view';
import EnrollView from './enroll_view';

const { Content } = Layout;

const AccountApp = () => (
  <Content className="contest-layout-content">
    <Switch>
      <Route path={ContestRoutes.ACCOUNT.LOGIN} component={LoginView} />
      <Route path={ContestRoutes.ACCOUNT.ENROLL} component={EnrollView} />
    </Switch>
  </Content>
);
export default AccountApp;
