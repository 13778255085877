import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { WeChatRoutes } from 'scripts/apps/routes';
import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';
import WeChatQrConnectView from './qrconnect';

const AccountAPP = () => (
  <BaseLayout>
    <Switch>
      <Route path={WeChatRoutes.QRCONNECT} component={WeChatQrConnectView} />
      <Route component={WeJudge404NotFoundComponent} />
    </Switch>
  </BaseLayout>
);

export default AccountAPP;
