import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import VideoDemo from 'scripts/apps/example/video-demo';
import FileSelectorView from 'scripts/apps/repository/file_selector';
import { showModal } from 'scripts/common/widgets/modal';
import BaseLayout from '../wejudge/layout/base';
import { ExampleRoutes } from '../routes';
import RankList from './ranklist';
import ListComponent from '../widgets/demo/list_ad';
import CardComponent from '../widgets/demo/list_card';
// import DetailComponent from '../widgets/demo/list_info1';
// import Blog from '../widgets/demo/list_info1';
// import LoadMoreList from '../widgets/demo/list_info1';
// import Demo from '../widgets/demo/list_info1';

class DevExample extends React.PureComponent {
  // componentDidMount() {
  //   showModal(FileSelectorView, {
  //     onOk: (modal, state) => {
  //       console.log(state.selectedFiles);
  //     },
  //   });
  // }

  render() {
    return <BaseLayout>
      <Layout>
        <Menu
          mode="horizontal"
        >
          <Menu.Item>
            <Link to={ExampleRoutes.BLANK}>空白页</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={ExampleRoutes.RANK_LIST}>排行榜模块</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={ExampleRoutes.VIDEO}>视频模块</Link>
          </Menu.Item>
        </Menu>
        <Layout.Content>
          <Switch>
            <Route path={ExampleRoutes.RANK_LIST} component={RankList} />
            <Route path={ExampleRoutes.VIDEO} component={VideoDemo} />
            <Route  
              exact
              render={() => {
                return <div><ListComponent /></div>;
              }} 
            />
            
          </Switch>
        </Layout.Content>
      </Layout>
    </BaseLayout>;
  }
}

export default DevExample;
