import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { Button, Popconfirm, Input } from 'antd';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeJudgeActions, WeJudgeSelectors  } from 'scripts/common/logic/wejudge';
import OrganizationListTable from 'scripts/apps/contest/manage/widgets/organization_manage_list_table';
import { ContestOrganizationActions, ContestOrganizationSelectors } from 'scripts/common/logic/contest/organization';
import { get, isEqual, pick } from 'lodash';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { ContestRoutes } from 'scripts/apps/routes';
import TeamDrawer from 'scripts/apps/contest/organization/widgets/team_drawer';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import { PrintTool } from 'react-print-tool';
import PrinterBaseTemplates from '../../support/widgets/printer_base_templates';

const LOADING_STATUS_KEY = 'contest_organization_list_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  getOrganizationList: ContestOrganizationActions.getOrganizationList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  generateOrgAccount: ContestOrganizationActions.generateOrgAccount,
}, dispatch);

const mapStateToProps = (state, props) => {
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const queryset = getQuery(props.location);
  const pager = ContestOrganizationSelectors.contestOrganizationListViewPagerSelector(state);
  const status = ContestOrganizationSelectors.contestOrganizationStatusSelector(state);
  const queryParams = {
    ...PaginationParams40,
    ...pick(queryset, ['limit', 'page']),
  };
  const currentRoutePath = ContestRoutes.MANAGE.ORGANIZATION;
  const organizationList = ContestOrganizationSelectors.organizationListData(state);
  const teamGroups = ContestOrganizationSelectors.contestOrganizationGroupListSelector(state);
  return {
    status,
    isLoading,
    teamGroups,
    queryParams,
    paginationParams: pager || props.paginationParams,
    organizationList,
    currentRoutePath,
  };
};

@ContestLoginRequire
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class OrganizationManageListView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const query = pick(
      nextProps.queryParams,
      ['limit', 'page']
    );
    nextProps.getOrganizationList({
      query,
      contestId: nextProps.contest.id,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    isLoading: PropTypes.bool,
    getOrganizationList: PropTypes.func.isRequired,
    setLoadingStatus: PropTypes.func.isRequired,
    paginationParams: PaginationPropTypes,
    generateOrgAccount: PropTypes.func.isRequired,
    teamGroups: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    isLoading: false,
    paginationParams: { ...PaginationParams40 },
    teamGroups: [],
  };

  state = {
    queryParams: null,
    selectedRowKeys: [],
    drawerVisible: false,
    teamPrefix: 'team',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      OrganizationManageListView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  showTeamDetail = (item) => {
    this.setState({
      drawerVisible: true,
      drawerOptions: {
        teamId: item.id,
        detail: true,
      },
    });
  };

  handleGenerateAccount = () => {
    this.props.generateOrgAccount({
      contestId: this.props.contest.id,
      prefix: this.state.teamPrefix,
      ids: this.state.selectedRowKeys,
      onSuccess: () => {
        OrganizationManageListView.fetchList(this.props);
      },
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleDrawerClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  handleSelectRowKeysChanges = (selectedRowKeys) => {
    this.setState({
      selectedRowKeys,
    });
  };

  handlePrintAccount = () => {
    const item = this.props.organizationList.map((it) => {
      if (this.state.selectedRowKeys.includes(it.id)) {
        return it;
      }
      return null;
    }).filter(i => i !== null);
    PrintTool.printFromReactComponent(<PrinterBaseTemplates
      type="team_account"
      accounts={item}
      contest={this.props.contest}
    />);
  };

  render() {
    return (
      <div className="contest-organization-list-view">
        <div className="action-bar">
          <Popconfirm
            title={<span>
              操作说明：批量激活用户将会为所有的队伍分配对应的登录名称。<br />
              如果没有勾选项目，则该账户不会被分配到登录名称。<br />
              每次操作都将根据你的勾选来重新分配名称，原有分配的内容将被覆盖。<br /><br />
              <strong>登录名前缀：</strong>
              <Input
                value={this.state.teamPrefix}
                onChange={({ target }) => { this.setState({ teamPrefix: target.value }); }}
              />
            </span>}
            okType="info"
            onConfirm={this.handleGenerateAccount}
          >
            <Button type="primary">批量激活用户</Button>
          </Popconfirm>
          <Button
            disabled={this.state.selectedRowKeys.length === 0}
            onClick={this.handlePrintAccount}
          >
            打印
          </Button>
        </div>
        <div className="content_layer">
          <OrganizationListTable
            data={this.props.organizationList}
            teamGroups={this.props.teamGroups}
            onPageChange={this.handlePageChange}
            isLoading={this.props.isLoading}
            paginationParams={this.props.paginationParams}
            showTeamDetail={this.showTeamDetail}
            onSelectRowKeys={this.handleSelectRowKeysChanges}
          />
        </div>
        <TeamDrawer
          teamGroups={this.props.teamGroups}
          status={this.props.status}
          visible={this.state.drawerVisible}
          onClose={this.handleDrawerClose}
          emitRefeshList={() => OrganizationManageListView.fetchList(this.props)}
          {...this.state.drawerOptions}
          manage
        />
      </div>
    );
  }
}

export default OrganizationManageListView;
