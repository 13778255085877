import Markdowm from 'scripts/common/widgets/mdeditor';
import PropTypes from 'prop-types';
import { get, noop, set } from 'lodash';
import { CheckCircleOutlined, RocketOutlined } from '@ant-design/icons';
import { Tooltip, Button, Modal } from 'antd';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  CollectionProblemSolutionsActions,
  SolutionsTagsActions 
} from 'scripts/common/logic/collection';
import Tabselect from './tags_selector';

@connect((state, props) => {
  const { match } = props; 
  const { entities } = state;
  const { tags } = entities;
  const problemId = get(match.params, 'problemId', '');
  const collectionId = get(match.params, 'collectionId', '');
  return {
    problemId,
    collectionId,
    tags,
  };
}, dispatch => bindActionCreators({
  onSubmitSolution: CollectionProblemSolutionsActions.saveAddSolution,
  fetchTagsList: SolutionsTagsActions.getSolutionsTagsList,
  problemSolutions: CollectionProblemSolutionsActions.getCollectionProblemSolutionList,
}, dispatch))
class Editor extends PureComponent {
  static propTypes = {
    tags: PropTypes.shape({}),
    collectionId: PropTypes.string.isRequired,
    problemId: PropTypes.string.isRequired,
    onSubmitSolution: PropTypes.func,
    fetchTagsList: PropTypes.func,
  };

  static defaultProps = {
    tags: {},
    onSubmitSolution: noop,
    fetchTagsList: noop,
  };

  state ={
    solution: {
      tags: [],
      content: '',
      title: '',
    },
    tagsList: [],
  }

  componentWillMount() {
    this.props.fetchTagsList();
    if (localStorage.tags) {
      const savTags = (Object.values(JSON.parse(localStorage.tags))).map((item) => {
        return    { id: item[0], name: item[1], language: item[2] };
      }); 
      const content = { title: localStorage.title, tags: savTags, content: localStorage.content };
      this.setState({
        solution: content,
      });
    } else {
      const content = { title: localStorage.title, tags: [], content: localStorage.content };
      this.setState({
        solution: content,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tagsList: Object.values(nextProps.tags),
    });
  }

  confirm() {
    Modal.info({
      mask: false,
      title: 'WEJUDGE',
      content: '确认发布题解吗',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.handleSubmitBtn(),
    });
  }

  setTabsTitle = (title) => {
    this.setState(set({ solution: { ...this.state.solution } }, 'solution.title', title));
    localStorage.setItem('title', title);
  }

  setTabs = (tags) => {
    const tagsObj =  tags.map((item) => { return Object.values(item);  });
    this.setState(set({ solution: { ...this.state.solution } }, 'solution.tags', tags));
    localStorage.setItem('tags', JSON.stringify(tagsObj));
  }

   handleEditorSoultion = (text) => {
     this.setState(set({ solution: { ...this.state.solution } }, 'solution.content', text));
     localStorage.setItem('content', text);
   }

   handleSubmitBtn() {
     const tagsId = this.state.solution.tags.map((arr) => {
       return arr.id;
     });
     const success = this.props.onSubmitSolution({
       solution: {
         tags: tagsId,
         content: this.state.solution.content,
         title: this.state.solution.title,
       },
       collectionId: this.props.collectionId,
       problemId: this.props.problemId,
     });
     if (success.payload.solution.content
      && success.payload.solution.title) {
       const data = {
         ...this.state.solution,
         content: '',
         title: '',
         tags: [], 
       }; 
       this.setState({
         solution: data,
       }, () => {
         localStorage.clear();
       });
     } else {
       Modal.error({
         title: 'WEJUDGE',
         content: '正文部分或者题解不能为空',
       });
     }
   }

   render() {
     return (
       <div>
         <Tabselect
           selectTabs={this.setTabs} 
           setTitle={this.setTabsTitle}
           TabsValue={this.state.solution.tags}
           TitleValue={this.state.solution.title}
           Tab={this.state.tagsList}
         />
         <div className="problem_answer_editor">
           <Markdowm
             height="70vh"
             onChange={this.handleEditorSoultion} 
             defaultValue={this.state.solution.content}
           />
           <div className="footer_control_bar">
             <span className="status_text">
               <Tooltip title="自动保存启用，刷新页面不会丢失你的代码。仅对当前电脑的当前浏览器有效哦！">
                 <CheckCircleOutlined /> 本地自动保存启用
               </Tooltip>
             </span>
             <Button
               type="primary"
               onClick={() => this.confirm()}
             >
               <RocketOutlined /> 发布题解
             </Button>
           </div>
         </div>
       </div>
     );
   }
}
export default Editor;
