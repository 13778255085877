import React from 'react';
import {
  isEqual, get, noop, truncate 
} from 'lodash';
import { Modal, Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import { AsgnProblemPropTypes, AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { ProblemDifficultyEnum, ProblemTypeEnum } from 'scripts/common/enums/problem';
import { filterHTML } from 'scripts/common/utils/filter';
import { indexToChar } from 'scripts/common/utils/unit';
import { buildAntdTableDNDComponent } from 'scripts/apps/widgets/common/antd_table_dnd';
import AsgnProblemSettings from 'scripts/apps/education/asgn/widgets/problem_settings';
import { nowTime } from 'scripts/common/utils/time_formatter';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  AsgnJudgeStatusActions,
  AsgnProblemActions
} from 'scripts/common/logic/education/asgn/actions';

const TABLE_CELL_TRUNCATE_LENGTH = 32;

@connect(null, dispatch => bindActionCreators({
  modifyAsgnProblem: AsgnProblemActions.modifyAsgnProblem,
  rejudgeAsgnProblem: AsgnJudgeStatusActions.rejudgeAsgnProblem,
  delAsgnProblem: AsgnProblemActions.delAsgnProblem,
}, dispatch), null, { forwardRef: true })
class ProblemsListTable extends React.Component {
  static propTypes = {
    problemDatas: PropTypes.arrayOf(AsgnProblemPropTypes),
    asgn: AsgnPropTypes,
    asgnId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    onProblemClick: PropTypes.func,
    onProblemOrderChange: PropTypes.func,
    rejudgeAsgnProblem: PropTypes.func,
    delAsgnProblem: PropTypes.func,
    isManage: PropTypes.bool,
    codeProblemSolutionData: PropTypes.shape(),
    hasFetchList: PropTypes.func.isRequired,
  };

  static defaultProps = {
    problemDatas: [],
    onProblemClick: noop,
    rejudgeAsgnProblem: noop,
    delAsgnProblem: noop,
    onProblemOrderChange: noop,
    isManage: false,
    asgn: {},
    codeProblemSolutionData: {},
  };

  static renderCodeProblemStatus = (nextProps, problemId) => {
    const endTime = get(nextProps.asgn, 'status.end_time', 0);
    const nowDate = nowTime().toDate();
    const status = get(nextProps.codeProblemSolutionData, `${problemId}`, null);
    // console.log('代码题过题情况:', status);
    // console.log('作业结束时间:', endTime, '现在时间:', (nowDate.getTime() / 1000))
    if (status) {
      return status.accepted === 0 ? <Tag color="#1890ff"><strong>进行中</strong> ( {status.accepted} / {status.submission} )</Tag>
        : <Tag color="#52c41a"><strong>已完成</strong> ( {status.accepted} / {status.submission} )</Tag>;
    }
    if (endTime <= (nowDate.getTime() / 1000)) {
      return  <Tag color="#8c8c8c">未完成</Tag>;
    }
    return <Tag color="#e8e8e8"><strong style={{ color: '#595959' }}>待访问</strong></Tag>;
  };

  static isCodeProblem = (problemTypeId) => {
    const problemType = ProblemTypeEnum.enumValueOf(problemTypeId);
    return (problemType === ProblemTypeEnum.CODE)
      || (problemType === ProblemTypeEnum.CODE_FILL);
  };

  static renderListData = (nextProps) => {
    const getRatio = (a, b) => {
      return (b === 0) ? 0 : (((a * 1.0) / b) * 100.0).toFixed(2); // eslint-disable-line
    };
    const getTitle = (item) => {
      const type = parseInt(get(item, 'problem.problem_type', 0), 10);
      if (type > 3) {
        const desc = filterHTML(get(item, 'problem.description'));
        const defFilterHtml =  desc ? `（描述：${desc}）` : '';
        const t = `${get(item, 'problem.title', '')}${defFilterHtml}`;
        return truncate(t, {
          length: TABLE_CELL_TRUNCATE_LENGTH,
        });
      }
      return get(item, 'problem.title', '');
    };

    return nextProps.problemDatas.map((item) => {
      if (!item || !item.problem) return null;
      const isCodeProblem = ProblemsListTable.isCodeProblem(get(item, 'problem.problem_type'));
      const problemType = ProblemTypeEnum.enumValueOf(get(item, 'problem.problem_type'));
      const pTitle = <>
        {problemType && <Tag color={problemType.color}>{problemType.desc}</Tag>}
        {!get(item, 'require', false) && <Tag color="grey">选做题</Tag>}
        {getTitle(item)}
      </>;
      return {
        item,
        key: get(item, 'id', ''),
        number: indexToChar(get(item, 'order', 1)),
        title: isCodeProblem
          ? <a onClick={() => nextProps.onProblemClick(item)}>{pTitle}</a> : pTitle,
        score: get(item, 'score', 0),
        status: ProblemsListTable.renderCodeProblemStatus(nextProps, item.id),
        type: get(item, 'require', false) ? '必做' : '选做',
        cross_check: get(item, 'simple_cross_check', false) ? '启用' : '停用',
        difficulty: get(ProblemDifficultyEnum.enumValueOf(get(item, 'problem.difficulty', 0)), 'desc', '未知'),
        correct_ratio: isCodeProblem
          ? `${getRatio(get(item, 'accepted', 0), get(item, 'submission', 0))}% (${get(item, 'accepted', 0)} / ${get(item, 'submission', 0)})`
          : '-',
      };
    }).filter(item => !!item);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.problemDatas, prevState.problemDatasRaw)) {
      return {
        problemDatasRaw: nextProps.problemDatas,
        problemDatas: ProblemsListTable.renderListData(nextProps),
      };
    }
    return null;
  }

  state = {
    problemDatasRaw: null,
    problemDatas: [],
  };

  NORMAL_COLUMNS = [{
    title: '题号',
    width: 80,
    dataIndex: 'number',
    align: 'center',
  }, {
    title: '题目',
    dataIndex: 'title',
  }, {
    title: '要求',
    width: 80,
    dataIndex: 'type',
    align: 'center',
  }, {
    title: '分值',
    width: 80,
    dataIndex: 'score',
    align: 'center',
  }, {
    title: '难度',
    width: 80,
    dataIndex: 'difficulty',
    align: 'center',
  },
  ...(this.props.isManage ? [{
    title: '查重',
    width: 80,
    dataIndex: 'cross_check',
    align: 'center',
  }] : []),
  ...(!this.props.isManage
    ? [{
      title: '状态',
      width: 160,
      dataIndex: 'status',
      align: 'center',
    }] : []),
  {
    title: '正确率（当前作业）',
    width: 200,
    dataIndex: 'correct_ratio',
    align: 'center',
  }
  ];

  components = buildAntdTableDNDComponent();

  handleMoveRow = (dragIndex, hoverIndex) => {
    const { problemDatas } = this.state;
    const dragRow = problemDatas[dragIndex];

    this.setState(update(this.state, {
      problemDatas: {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
      },
    }), () => {
      this.props.onProblemOrderChange(this.state.problemDatas.map((item) => {
        return get(item, 'item.id', 0);
      }));
    });
  };

  handleResetProblemOrder = () => {
    const { problemDatas } = this.state;
    this.setState({
      problemDatas: problemDatas.sort((a, b) => {
        return a.item.order < b.item.order ? -1 : 1;
      }),
    });
  };

  handleProblemRejudgeClick = item => () => {
    Modal.confirm({
      content: '确定要重判该道题目吗，这将把该题所有的提交重置为待评测状态。',
      cancelText: '取消',
      okText: '确认',
      title: '重判题目',
      onOk: () => {
        this.props.rejudgeAsgnProblem({
          courseId: this.props.courseId,
          asgnId: this.props.asgnId,
          problemId: item.id,
          onSuccess: () => {
            this.props.hasFetchList(true);
          },
        });
        return false;
      },
    });
  };

  handleProblemDeleteClick = item => () => {
    Modal.confirm({
      content: '确定要删除这道题目吗？这个操作不会删除题目原始信息，但会删除当前作业内关于这个题目的设置、信息和所有提交，不可反悔！',
      cancelText: '取消',
      okText: '确认',
      title: '删除题目',
      okType: 'danger',
      onOk: () => {
        this.props.delAsgnProblem({
          courseId: this.props.courseId,
          asgnId: this.props.asgnId,
          problemId: item.id,
          onSuccess: () => {
            this.props.hasFetchList(true);
          },
        });
        return false;
      },
    });
  };

  renderExpandedRow = (record) => {
    return this.props.isManage && <>
      <AsgnProblemSettings
        asgn={this.props.asgn}
        asgnId={this.props.asgnId}
        courseId={this.props.courseId}
        asgnProblem={record.item}
        onProblemRejudgeClick={this.handleProblemRejudgeClick}
        onProblemDeleteClick={this.handleProblemDeleteClick}
      />
    </>;
  };

  render() {
    // const datas = this.renderListData();
    const adminDropingOptions = {
      components: this.components,
      onRow: (record, index) => ({
        index,
        moveRow: this.handleMoveRow,
      }),
    };

    return (this.props.isManage ? <DndProvider backend={HTML5Backend}>
      <Table
        bordered
        className="problems_list_view"
        size="middle"
        dataSource={this.state.problemDatas}
        columns={this.NORMAL_COLUMNS}
        pagination={false}
        {...adminDropingOptions}
        expandedRowRender={this.renderExpandedRow}
      />
    </DndProvider> : <Table
      bordered
      className="problems_list_view"
      size="middle"
      dataSource={this.state.problemDatas}
      columns={this.NORMAL_COLUMNS}
      pagination={false}
    />);
  }
}

export default ProblemsListTable;
