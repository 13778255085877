import 'styles/contest/support/faqs.scss';

import React from 'react';
import {
  Table, Button, Tag, PageHeader 
} from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import {
  get, pick, isEqual, noop 
} from 'lodash';
import { ContestSupportActions, ContestSupportSelectors } from 'scripts/common/logic/contest/support';
import { withRouter } from 'react-router-dom';
import { PaginationParams } from 'scripts/common/constants/global';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import { ContestRoutes } from 'scripts/apps/routes';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import ReactResizeDetector from 'react-resize-detector';
import { AntTableMiddleOutsideHeight } from 'scripts/common/constants/table';
import FaqEditor from './faq_editor';

const LOADING_STATUS_KEY = 'contest_faqs_list_view';

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const canManage = get(props.account, 'role', 0) === ContestAccountRoleEnum.REFEREE.value;
  const pager = ContestSupportSelectors.contestFaqsListViewPagerSelector(state);
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page', 'answer', 'key']),
  };
  return {
    paginationParams: pager || props.paginationParams,
    queryParams,
    contestFaqsList: ContestSupportSelectors.getContestFaqsListData(state),
    isLoading,
    currentRoutePath: ContestRoutes.SUPPORT.FAQ,
    canManage,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getFaqsList: ContestSupportActions.getFaqsList,
  addFaq: ContestSupportActions.addFaq,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);

@withRouter
@ContestLoginRequire
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class FaqList extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const query = pick(
      nextProps.queryParams,
      ['limit', 'page', 'answer', 'key']
    );
    nextProps.getFaqsList({
      query,
      contestId: nextProps.contest.id,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    addFaq: PropTypes.func,
    getFaqsList: PropTypes.func,
    getFaqsReplyList: PropTypes.func,
    ...RoutePropTypes,
    canManage: PropTypes.bool.isRequired,
    paginationParams: PaginationPropTypes,
    account: AccountPropTypes.isRequired,
    contest: ContestPropTypes.isRequired,
    contestFaqsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    queryParams: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    addFaq: noop,
    getFaqsList: noop,
    getFaqsReplyList: noop,
    paginationParams: { ...PaginationParams },
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      FaqList.fetchList(nextProps);
      return {
        answer: get(queryParams, 'answer', ''),
        queryParams,
      };
    }
    return null;
  }

  state = {
    showAddEditor: false,
    queryParams: null,
    tableHeight: 0,
  };

  COLUMNS = [
    {
      dataIndex: 'key',
      title: 'ID',
      width: 80,
    },
    {
      dataIndex: 'title',
      title: '标题',
    },
    {
      dataIndex: 'author',
      title: '提问者',
      width: 300,
    },
    {
      dataIndex: 'create_time',
      title: '提问时间',
      width: 140,
    },
    {
      dataIndex: 'status',
      title: '状态',
      width: 80,
    }
  ];

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      { contestId: this.props.contest.id },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'key', 'answer']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleAddClick = () => {
    this.setState({
      showAddEditor: true,
    });
  };

  handleBackIndex = () => {
    this.setState({
      showAddEditor: false,
    });
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  handleAddSave = faq => new Promise((resolve, reject) => {
    this.props.addFaq({
      contestId: this.props.contest.id,
      title: faq.title,
      content: faq.content,
      onSuccess: () => {
        this.setState({
          showAddEditor: false,
        });
        FaqList.fetchList(this.props);
        resolve();
      },
      onError: e => reject(e),
    });
  });

  handleFaqClick = item => () => {
    const { history } = this.props;
    history.push(buildPath(
      ContestRoutes.SUPPORT.FAQ_VIEW,
      {
        contestId: this.props.contest.id,
        faqId: item.id,
      }
    ));
  };

  renderList = () => {
    return this.props.contestFaqsList.map((item) => {
      return {
        key: item.id,
        title: <a onClick={this.handleFaqClick(item)}>{item.title} {item.public && <Tag color="blue">公开</Tag>}</a>,
        create_time: formatTimeFromNow(get(item, 'create_time', 0)),
        author: `${get(item, 'author.nickname', '')}(${get(item, 'author.username', '')})`,
        status: item.answer === 2 ? <Tag color="green">已回复</Tag> : (item.answer === 1 ? <Tag color="blue">待回复</Tag> : null),
      };
    });
  };

  render() {
    const optPagination = {
      simple: true,
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 1),
      onChange: this.handlePageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    const extraButtons = [<Button
      key="add"
      type="primary"
      onClick={this.handleAddClick}
    >
      发起新提问
    </Button>];
    return (<div className="contest-faqs contest-faqs-list">
      <PageHeader
        title={this.state.showAddEditor ? '发起新提问' : '比赛答疑'}
        onBack={this.state.showAddEditor ? this.handleBackIndex : false}
        extra={!this.state.showAddEditor ? extraButtons : []}
      />
      <div className="faqs-body">
        <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
        {this.state.showAddEditor
          ? <FaqEditor onSave={this.handleAddSave} />
          : <Table
            scroll={{
              y: this.state.tableHeight - AntTableMiddleOutsideHeight,
              scrollToFirstRowOnChange: true,
            }}
            loading={this.props.isLoading}
            columns={this.COLUMNS}
            dataSource={this.renderList()}
            pagination={optPagination}
          />}
      </div>
    </div>);
  }
}

export default FaqList;
