import 'styles/contest/manager/account.scss';

import React from 'react';
import PropTypes from 'prop-types';

import {
  DeleteOutlined,
  ExportOutlined,
  ImportOutlined,
  BarChartOutlined,
  UserOutlined,
  TeamOutlined,
  PlusOutlined
} from '@ant-design/icons';

import {
  Button, notification, Menu,
  Modal, Input, Space, Dropdown
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  get, isEqual, pick, noop 
} from 'lodash';
import selectFiles from 'select-files';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { PaginationParams } from 'scripts/common/constants/global';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import AccountManageListTable from 'scripts/apps/contest/manage/widgets/account_manage_list_table';
import { ContestAccountActions, ContestAccountSelectors } from 'scripts/common/logic/contest/account';
import { ContestRoutes } from 'scripts/apps/routes';
import AccountDrawer from 'scripts/apps/contest/manage/widgets/account_drawer';
import ContestLoginRequire from 'scripts/apps/contest/contest_auth';
import ContestManagePermissionCheck from 'scripts/apps/contest/manage_permission';
import { importContestAccountExcel } from 'scripts/common/utils/xlsx_importer';
import DownloadHelper from 'downloadjs';
import { ContestProblemSelectors } from 'scripts/common/logic/contest/problem';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';
import { ACCEPT_MIME_TYPES } from 'scripts/common/constants/mimes';

import AccountExportModal from './widgets/account_export_modal';

const { Search } = Input;

const LOADING_STATUS_KEY = 'contest_account_list_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  getContestAccountList: ContestAccountActions.getContestAccountList,
  deleteContestAccount: ContestAccountActions.deleteContestAccount,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  addContestAccountEntities: ContestAccountActions.addContestAccountEntities,
  exportContestAccount: ContestAccountActions.exportContestAccount,
  deleteContestAccountBatch: ContestAccountActions.deleteContestAccountBatch,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const pager = ContestAccountSelectors.contestAccountListViewPagerSelector(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const currentRoutePath = ContestRoutes.MANAGE.ACCOUNT;
  const queryParams = {
    ...PaginationParams,
    ...pick(queryset, ['limit', 'page', 'key']),
  };
  return {
    queryParams,
    isLoading,
    paginationParams: pager || props.paginationParams,
    currentRoutePath,
    contestAccountList: ContestAccountSelectors.contestAccountsListData(state),
    problemLists: ContestProblemSelectors.problemListData(state),

  };
};

@ContestLoginRequire
@ContestManagePermissionCheck()
@withRouter
@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class AccountManageView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    const query = pick(
      nextProps.queryParams,
      ['limit', 'page', 'key']
    );
    nextProps.getContestAccountList({
      query,
      contestId: nextProps.contest.id,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    deleteContestAccount: PropTypes.func,
    deleteContestAccountBatch: PropTypes.func,
    contest: ContestPropTypes.isRequired,
    problemLists: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    deleteContestAccount: noop,
    deleteContestAccountBatch: noop,
    problemLists: [],
  };

  state = {
    queryParams: {},
    drawerVisible: false,
    accountId: '',
    selectedKeys: [],
    exportLoading: false,
    importLoading: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      AccountManageView.fetchList(nextProps);
      return {
        queryParams,
        selectedKeys: [],
      };
    }
    return null;
  }

  showExportModal = () => {
    let rankValue = 30;
    let rankFlag = false;
    let crossValue = 80;
    let crossFlag = false;
    let excludeProblems = [];
    const changeRankValue = (_value) => {
      rankValue = _value;
    };
    const changeRankFlag = (_value) => {
      rankFlag = _value;
    };

    const changeCrossValue = (_value) => {
      crossValue = _value;
    };
    const changeCrossFlag = (_value) => {
      crossFlag = _value;
    };
    const changeExcludeProblems = (_value) => {
      excludeProblems = _value;
    };
    Modal.confirm({
      title: '请选择需要导出赛后统计数据',
      okText: '导出',
      width: 552,
      type: 'info',
      maskClosable: true,
      content: <AccountExportModal
        problemLists={this.props.problemLists}
        changeRankValue={changeRankValue}
        changeRankFlag={changeRankFlag}
        changeCrossValue={changeCrossValue}
        changeCrossFlag={changeCrossFlag}
        changeExcludeProblems={changeExcludeProblems}
      />,
      onOk: () => {
        this.handleOk({
          crossFlag, crossValue, rankFlag, rankValue, excludeProblems,
        });
      },
      cancelText: '取消',
    });
  };

  handleOk = (props) => {
    const {
      crossFlag,
      crossValue,
      rankFlag,
      rankValue,
      excludeProblems,
    } = props;
    const params = {
      threshold: rankFlag ? (rankValue / 100).toFixed(2) : null,
      cross: crossFlag ? (crossValue / 100).toFixed(2) : null,
      exclude_problem: excludeProblems.length > 0 ? excludeProblems.join(',') : null,
    };
    this.setState({
      exportLoading: true,
    }, () => {
      this.props.exportContestAccount({
        type: 'calc',
        params,
        contestId: this.props.contest.id,
        // configs,
        onSuccess: (result) => {
          DownloadHelper(
            result,
            `${get(this.props.contest, 'title')}-赛后数据导出.xls`,
            'application/octet-stream'
          );
        },
        onComplete: () => {
          this.setState({
            exportLoading: false,
          });
        },
      });
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'key']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleDrawerClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  handleAccountClick = (accountId) => {
    this.setState({
      accountId,
      drawerVisible: true,
    });
  };

  handleDeleteAccountClick = (accountId) => {
    this.props.deleteContestAccount({
      contestId: this.props.contest.id,
      accountId,
      onSuccess: () => {
        AccountManageView.fetchList(this.props);
        this.setState({
          selectedKeys: this.state.selectedKeys.filter(id => id !== accountId),
        });
      },
    });
  };
  // handlePrintAccount = () => {
  //   const item = {};
  //   item.accounts = this.props.contestAccountList.map((it) => {
  //     if (this.state.selectedKeys.includes(it.id)) {
  //       return it;
  //     }
  //     return null;
  //   }).filter(i => i !== null);
  //   PrintTool.printFromReactComponent(
  //   <PrinterBaseTemplates type='account' item={item} contest={this.props.contest}/>)
  // }

  handleNewAccountClick = () => {
    this.setState({
      accountId: '',
      drawerVisible: true,
    });
  };

  handleTableRowSelectChange = (selectedRowKeys) => {
    this.setState({
      selectedKeys: selectedRowKeys,
    });
  };

  handleBatchDelete = () => {
    Modal.confirm({
      title: '确定要删除这些用户吗？',
      content: '这些操作不可逆，请谨慎操作！',
      okType: 'danger',
      onOk: () => {
        this.props.deleteContestAccountBatch({
          contestId: this.props.contest.id,
          accountIds: this.state.selectedKeys,
          onSuccess: (res) => {
            const error = [];
            const success = [];
            const { status = [] } = res;
            Object.keys(status).forEach((key) => {
              if (status[key] === 2) {
                error.push(`${key}: 组队关联账号不能删除`);
              } else if (status[key] === 1) {
                success.push(key);
              } else {
                error.push(`${key}: 删除失败`);
              }
            });
            if (error.length > 0) {
              notification.error({
                message: '批量删除部分成功',
                duration: null,
                description: <div>
                  {error.map(item => <div key={item}>{item}</div>)}
                  {success.length > 0 && <div>其余{success.length}个账户删除成功。</div>}
                </div>,
              });
            } else {
              notification.success({
                message: '批量删除成功',
                duration: null,
                description: <div>
                  成功删除{success.length}个账户。
                </div>,
              });
            }
            AccountManageView.fetchList(this.props);
          },
        });
      },
    });
  };

  handleExportAccount = (role = null) => () => {
    this.setState({
      exportLoading: true,
    }, () => {
      this.props.exportContestAccount({
        type: 'account',
        params: {
          role,
        },
        contestId: this.props.contest.id,
        // configs,
        onSuccess: (result) => {
          DownloadHelper(
            result,
            `${get(this.props.contest, 'title')}-账号信息表.xls`,
            'application/octet-stream'
          );
        },
        onComplete: () => {
          this.setState({
            exportLoading: false,
          });
        },
      });
    });
  };

  handleAccountSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        contestId: this.props.contest.id,
      },
      match.params,
      value === '' ? {
        ...pick(this.state.queryParams, []),
        limit: 20,
        page: 1,
      } : {
        ...pick(this.state.queryParams, []),
        limit: 20,
        page: 1,
        key: value,
      }
    ));
  };

  handleXlsxUpload = () => {
    const getResult = (data) => {
      this.props.addContestAccountEntities({
        contestId: this.props.contest.id,
        accounts: data,
        onSuccess: (res) => {
          const error = [];
          const success = [];
          const { errmsg = [] } = res;
          Object.keys(errmsg).forEach((key) => {
            if (errmsg[key]) {
              error.push(`${key}: ${errmsg[key]}`);
            } else {
              success.push(key);
            }
          });
          if (error.length > 0) {
            notification.error({
              message: '部分成功',
              duration: null,
              description: <div>
                {error.map((item) => <div key={item}>{item}</div>)}
                {success.length > 0 && <div>其余{success.length}个账户成功导入。</div>}
              </div>,
            });
          } else {
            notification.success({
              message: '导入成功',
              duration: null,
              description: <div>
                成功导入{success.length}个账户。
              </div>,
            });
          }
          AccountManageView.fetchList(this.props);
        },
        onComplete: () => {
          this.setState({
            importLoading: false,
          });
        },
      });
    };
    selectFiles({ accept: ACCEPT_MIME_TYPES.EXCEL }).then(files => {
      this.setState({
        importLoading: true,
      }, () => {
        importContestAccountExcel(get(files, '0'))
          .then(data => getResult(data))
          .catch((ex) => {
            this.props.viewErrorMessage({
              message: '解析Excel文件失败',
            });
            console.error(ex);
            this.setState({
              importLoading: false,
            });
          });
      });
    });
  };

  handleDownloadTemplate = () => {
    window.location.href = '/static/excel-template/import_contest_account.xlsx';
  };

  renderExportMenu = () => {
    return <Menu>
      <Menu.Item
        key="1"
        icon={<UserOutlined />}
        onClick={this.handleExportAccount(ContestAccountRoleEnum.COMPETITOR.value)}
      >
        个人参赛者账号信息(赛前)
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<TeamOutlined />}
        onClick={this.handleExportAccount(ContestAccountRoleEnum.TEAM.value)}
      >
        团队账号信息(赛前)
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<BarChartOutlined />}
        onClick={this.showExportModal}
      >
        赛后统计数据
      </Menu.Item>
    </Menu>;
  };

  renderImportMenu = () => {
    return <Menu>
      <Menu.Item
        key="1"
        icon={<UserOutlined />}
        onClick={this.handleDownloadTemplate}
      >
        下载模板
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<TeamOutlined />}
        onClick={this.handleXlsxUpload}
      >
        导入账号
      </Menu.Item>

    </Menu>;
  };

  render() {
    return (
      <div className="contest_account_settings_view">
        <div className="action-bar">
          <div className="left-side">
            <Button.Group>
              <Button
                type="primary"
                onClick={this.handleNewAccountClick}
              >
                <PlusOutlined /> 新增账户
              </Button>
              <Button
                type="danger"
                disabled={this.state.selectedKeys.length <= 0}
                onClick={this.handleBatchDelete}
              >
                <DeleteOutlined /> 批量删除
              </Button>
              <Dropdown.Button
                overlay={this.renderImportMenu()}
                buttonsRender={([leftButton, rightButton]) => [
                  leftButton,
                  React.cloneElement(rightButton, { loading: this.state.importLoading })
                ]}
              >
                <ImportOutlined /> 导入账号
              </Dropdown.Button>
            </Button.Group>
          </div>
          <div className="right-side">
            <Space>
              <Search
                width={208}
                onSearch={this.handleAccountSearch}
              />
              <Dropdown.Button
                overlay={this.renderExportMenu()}
                buttonsRender={([leftButton, rightButton]) => [
                  leftButton,
                  React.cloneElement(rightButton, { loading: this.state.exportLoading })
                ]}
              >
                <ExportOutlined /> 数据导出
              </Dropdown.Button>
            </Space>
          </div>
        </div>
        <AccountManageListTable
          data={this.props.contestAccountList}
          onPageChange={this.handlePageChange}
          paginationParams={this.props.paginationParams}
          isLoading={this.props.isLoading}
          onAccountClick={this.handleAccountClick}
          onDeleteClick={this.handleDeleteAccountClick}
          onSelectedKeysChange={this.handleTableRowSelectChange}
          contest={this.props.contest}
          account={this.props.account}
        />
        <AccountDrawer
          accountId={this.state.accountId}
          visible={this.state.drawerVisible}
          onClose={this.handleDrawerClose}
          emitRefreshList={() => AccountManageView.fetchList(this.props)}
        />
      </div>
    );
  }
}

export default AccountManageView;
