import React from 'react';

export const ContestContext = React.createContext({
  contest: null, acocunt: null, loginStatus: { fetched: false, logined: false, id: null },
});

export const withContestContext = (Component) => {
  return (props) => {
    return <ContestContext.Consumer>
      {ctx => <Component {...props} {...ctx} />}
    </ContestContext.Consumer>;
  };
};
