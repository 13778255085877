import React from 'react';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { get, noop, indexOf } from 'lodash';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { AccountActions } from 'scripts/common/logic/account/action';
import { debounce } from 'scripts/common/utils/functional';
import { INPUT_SEARCH_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

const mapDispatchToProps = dispatch => bindActionCreators({
  getAccountInfoInEmail: AccountActions.getAccountInfoInEmail,
}, dispatch);

@connect(null, mapDispatchToProps)
class AccountPicker extends React.PureComponent {
  static propTypes = {
    getAccountInfoInEmail: PropTypes.func,
    placeholder: PropTypes.string,
    handleSelected: PropTypes.func.isRequired,
    style: PropTypes.shape({}),
    excludeAccountIds: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    getAccountInfoInEmail: noop,
    excludeAccountIds: [],
    placeholder: '',
    style: {},
  };

  state = {
    text: '',
    accountsSearchDatas: [],
  };

  debounceSearch = debounce((argv) => {
    const email = argv;
    if (email) {
      this.props.getAccountInfoInEmail({
        emails: [email],
        onSuccess: (e) => {
          if (this.state.text === '') {
            this.setState({
              accountsSearchDatas: [],
            });
          } else if (e.accounts.length === 0) {
            this.setState({
              accountsSearchDatas: [{ id: 'unknown', account: '查无此用户' }],
            });
          } else {
            const data = e.accounts.map((item) => {
              return { account: get(item, 'email', ''), realname: get(item, 'realname', ''), id: get(item, 'id', '') };
            });
            this.setState({
              accountsSearchDatas: data,
            });
          }
        },
      });
    } else {
      this.setState({
        accountsSearchDatas: [],
      });
    }
  }, INPUT_SEARCH_DEBOUNDCE_TIME);

  handleAccountSelect = (value) => {
    const svalue = value || this.state.text;
    this.setState({
      text: '',
      accountsSearchDatas: [],
    });
    if (svalue === '' || svalue === 'unknown' || svalue === '查无此用户') {
      return;
    }
    let account = null;
    this.state.accountsSearchDatas.forEach((item) => {
      if (item.account === svalue || item.id === svalue) {
        account = item;
      }
    });
    if (account) {
      this.props.handleSelected(account);
    }
  };

  handleAccountSearch = (value) => {
    if (value === '') {
      this.setState({
        accountsSearchDatas: [],
      });
    } else {
      this.debounceSearch(value);
    }
  };

  renderOption = () => {
    return this.state.accountsSearchDatas.map((item) => {
      if (indexOf(this.props.excludeAccountIds, get(item, 'id')) > -1) return null;
      return {
        value: item.id,
        label: <><span>{item.account}</span> <span style={{ color: 'grey', fontSize: '12px' }}>{item.realname}</span></>,
      };
    }).filter(item => !!item);
  };

  render() {
    return (
      <AutoComplete
        value={this.state.text}
        placeholder={this.props.placeholder || '通过Email查询并添加'}
        options={this.renderOption()}
        style={{ width: 300, ...this.props.style }}
        onSelect={this.handleAccountSelect}
        onSearch={this.handleAccountSearch}
        onChange={(value) => { this.setState({ text: value }); }}
      >
        <Input suffix={<SearchOutlined />} />
      </AutoComplete>
    );
  }
}

export default AccountPicker;
