// 全局上传监控工具
import { get, throttle } from 'lodash';
import Logger from 'scripts/common/utils/exceptions/logger';
import { store } from 'scripts/common/logic/store';
import { RepositoryCommonUploaderSelectors } from 'scripts/common/logic/repository/selector';
import {
  RepositoryAction as RFA,
  RepositoryFsAction as RFSA,
  RepositoryCommonUploadAction as RCUA
} from 'scripts/common/logic/repository/action';
import { RepositoryFileSystemDAO as RFSDAO } from 'scripts/common/logic/repository/dao';
import FileHashProcessor from 'scripts/apps/repository/utils/file_hash';
import UploadProcessor from 'scripts/apps/repository/utils/upload';

const DISPATCH_TIME = 200;    // 调度延迟
const worker = null;
const logger = Logger('REPO_UPD');
let isWorking = false;

const updateTask = throttle((task) => {
  store.dispatch(RCUA.updateRepositoryUploadFileTask({ taskId: task.id, task }));
}, 200);

const startUploadWork = async (_task) => {
  if (_task.status !== 0) return;
  const task = _task;
  try {
    const { file } = task;
    task.status = 1;
    const hashCrypto = new FileHashProcessor(file);
    // 计算哈希值
    task.hash = await hashCrypto.SHA1((p) => {
      task.hashProcess = (p * 100).toFixed(2);
      updateTask(task);
    });
    task.status = 2;
    // 执行上传
    const uploadToken = await RFSDAO.repoFsUploadFileAsync({
      repoId: task.repoId,
      fsId: task.fsId,
      fileHash: task.hash,
      fileSize: get(task, 'file.size', 0),
    });
    if (uploadToken.id > 0) {
      // 秒传成功
      task.status = 6;
      updateTask(task);
    } else {
      const {
        tokens,
        author_id: authorId,
      } = uploadToken;
      task.status = 2;
      updateTask(task);
      // 创建客户端
      const uploader = new UploadProcessor(task.file, authorId, tokens, task.hash);
      // 初始化上传
      await uploader.initUpload();
      // 发送分片数据
      await uploader.sendChunks((proc, chunkProcess, chunkIndex, chunkLength) => {
        task.sendProcess = proc;
        task.chunkProcess = chunkProcess;
        task.sendingChunk = chunkIndex;
        task.chunkLength = chunkLength;
        updateTask(task);
      });
      // 请求分片合并
      await uploader.finishUpload();
      // 保存文件信息到WeJudge服务器
      await RFSDAO.repoFsUploadFileAsync({
        repoId: task.repoId,
        fsId: task.fsId,
        fileHash: task.hash,
        fileSize: get(task, 'file.size', 0),
      });
      task.status = 3;
      updateTask(task);
    }
  } catch (e) {
    logger.error(e);
    task.status = 4;
    task.errmsg = e.toString();
    updateTask(task);
    return false;  // eslint-disable-line consistent-return
  }
  return true;  // eslint-disable-line consistent-return
};

const RepoCommonUploadWorker = () => {
  if (worker) return;
  // 获取上传队列
  const queue = RepositoryCommonUploaderSelectors.getUploadQueue(store.getState());
  if (queue.length) {
    // 取队头项目
    const taskId = queue[0];
    const task = RepositoryCommonUploaderSelectors.getUploadTask(store.getState(), taskId);
    if (!task) {
      // 如果没有正常获取到任务数据，则将当前任务出队(pop操作)
      store.dispatch(RCUA.popRepositoryUploadFileTask({ success: false }));
      setTimeout(RepoCommonUploadWorker, DISPATCH_TIME);
    } else {
      isWorking = true;
      startUploadWork(task).then((res) => {
        if (res) {
          // 刷新仓库数据
          store.dispatch(RFA.getRepositoryInfo({
            repoId: task.repoId,
          }));
          // 刷新文件列表
          store.dispatch(RFSA.getRepositoryFileList({
            repoId: task.repoId,
            fsId: task.fsId,
          }));
        }
        store.dispatch(RCUA.popRepositoryUploadFileTask({ success: !!res }));
        isWorking = false;
        setTimeout(RepoCommonUploadWorker, DISPATCH_TIME);
      }).catch(() => {
        isWorking = false;
      });
    }
  } else {
    setTimeout(RepoCommonUploadWorker, DISPATCH_TIME);
  }
};

let isInited = false;

// eslint-disable-next-line consistent-return
const rejectClose = function (e) {
  if (isWorking) {
    const confirmationMessage = '有文件正在上传！你确定要离开吗？';
    (e || window.event).returnValue = confirmationMessage; // 兼容 Gecko + IE
    return confirmationMessage; // 兼容 Gecko + Webkit, Safari, Chrome
  }
};

export const InitRepoCommonUploadWorker = () => {
  if (isInited) return;
  RepoCommonUploadWorker();
  isInited = true;
  window.addEventListener('beforeunload', rejectClose);
};
