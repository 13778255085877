import 'styles/education/asgn/statistics.scss';
import React from 'react';
import PropTypes from 'prop-types';
import BundleLoader from 'scripts/common/widgets/bundle_loader';

class AsgnStatisticsView extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
  };

  static defaultProps = {};

  state = {};

  render() {
    return <div className="asgn_statistics_view">
      <BundleLoader
        load={(cb) => {
          require.ensure([], (require) => {
            cb(require('scripts/apps/education/asgn/statistics/asgn'));
          }, 'desktop');
        }}
        courseId={this.props.courseId}
        asgnId={this.props.asgnId}
      />
    </div>;
  }
}

export default AsgnStatisticsView;
