import 'styles/account/auth/auth.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  LockOutlined, MailOutlined, ManOutlined, UserOutlined, WomanOutlined 
} from '@ant-design/icons';
import {
  Input, Button, Checkbox, Alert, Row, Col, Radio
} from 'antd';
import { AccountActions } from 'scripts/common/logic/account';
import { isEmail } from 'scripts/common/utils/validator';
import { buildPath, getQuery } from 'scripts/common/utils/location_helper';
import Vaptcha from 'scripts/common/widgets/vaptcha';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { WeChatActions } from 'scripts/common/logic/wechat';
import { AccountRoutes } from 'scripts/apps/routes';
import { APP_CONST } from 'scripts/common/constants/apps';

const mapDispatchToProps = dispatch => bindActionCreators({
  doRegister: AccountActions.register,
  getWechatQrconnectStatus: WeChatActions.getWechatQrconnectStatus,
}, dispatch);

@connect(null, mapDispatchToProps)
class RegisterView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    doRegister: PropTypes.func.isRequired,
    getWechatQrconnectStatus: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailAgain: '',
      password: '',
      nickname: '',
      realname: '',
      sex: 1,
      checkpassword: '',
      checksame: false,
      checkemail: false,
      agree: false,
      requireVapcha: false,
      submitting: false,
      checkEmailSame: false,
      isPassword: false,
      vapchaUserId: '',
      vaptchaPayloads: {},
    };
    this.getWXInfoStatus();
  }

  getWXInfoStatus = () => {
    this.props.getWechatQrconnectStatus({
      onSuccess: (QRInfos) => {
        const wxSex = ({
          1: 1,
          2: 0,
        })[get(QRInfos, 'sex', 1)];
        this.setState({
          wxOpenId: get(QRInfos, 'openid', ''),
          wxUserInfo: QRInfos,
          nickname: '' || get(QRInfos, 'nickname', ''),
          sex: get(QRInfos, 'sex', false) ? wxSex : 1,
        });
      },
    });
  };

  isSubmitDisabled = () => !this.state.agree || !this.state.checksame
    || !this.state.checkemail || !this.state.nickname || !this.state.realname
    || !this.state.checkEmailSame;

  handleChange = key => ({ target }) => {
    const {
      email, password,
    } = this.state;
    if (key === 'password' || key === 'checkpassword') {
      this.setState({
        [key]: target.value,
      }, () => {
        this.setState({
          checksame: this.state.password === this.state.checkpassword,
          isPassword: password.length >= APP_CONST.ACCOUNT.PASSWD_MINLENGTH
            && password.length <= APP_CONST.ACCOUNT.PASSWD_MAXLENGTH,
        });
      });
    } else if (key === 'email' || key === 'emailAgain') {
      this.setState({
        [key]: target.value,
      }, () => {
        this.setState({
          checkEmailSame: this.state.email === this.state.emailAgain,
          checkemail: isEmail(email),
        });
      });
    } else {
      this.setState({
        [key]: target.value,
      });
    }
  };

  handleRegisterSuccess = () => {
    const { history, location } = this.props;
    const query = getQuery(location);
    history.push(buildPath(
      AccountRoutes.AUTH.LOGIN,
      null,
      null,
      query
    ));
  };

  handleRegister = () => {
    const {
      vaptchaPayloads = {}, email,
      password, nickname, realname, sex,
    } = this.state;
    if (!this.isSubmitDisabled()) {
      this.setState({
        submitting: true,
      }, () => {
        this.props.doRegister({
          email,
          password,
          nickname,
          realname,
          sex,
          ...vaptchaPayloads,
          onSuccess: () => {
            // const query = getQuery(this.props.location);
            // window.location.href = get(query, 'from', '');
            this.handleRegisterSuccess();
          },
          onError: (e) => {
            this.setState({ submitting: false, vaptchaPayloads: {} });
            if (get(e, 'payload.action') === 'require_verification') {
              this.setState({
                requireVapcha: true,
                vapchaUserId: get(e, 'payload.user_id', ''),
              });
            }
          },
        });
      });
    }
  };

  handleValidate = (payloads) => {
    this.setState({
      requireVapcha: false,
      vaptchaPayloads: payloads,
    });
  };

  render() {
    const {
      wxOpenId, wxUserInfo, emailAgain, email, checkemail, sex,
      password, checkpassword, requireVapcha, checkEmailSame,
      submitting, nickname, isPassword, checksame, vapchaUserId,
      agree, realname,
    } = this.state;
    return (
      <div className="body-wrap">
        <div className="account-auth account-register">
          <div className="title">
            用户注册
          </div>
          {wxOpenId && <div className="wechat_connect_user_info">
            <img className="headimg" width={32} height={32} src={get(wxUserInfo, 'headimgurl')} alt="" />
            微信用户 <span className="nickname">{get(wxUserInfo, 'nickname')} </span> 待绑定
          </div>}
          <Input
            className="input"
            prefix={<MailOutlined />}
            placeholder="输入你的Email地址"
            size="large"
            value={email}
            onChange={this.handleChange('email')}
          />
          {(email  && !checkemail) ? <Alert message="请输入正确的邮件格式" type="error" /> : null }
          <Input
            className="input"
            prefix={<MailOutlined />}
            placeholder="确认你的Email地址"
            size="large"
            value={emailAgain}
            onChange={this.handleChange('emailAgain')}
          />
          {((email && emailAgain) && !checkEmailSame) ? <Alert message="两次输入的邮件地址不一致" type="error" /> : null }
          <Radio.Group
            className="account-sex-radio"
            value={sex}
            buttonStyle="solid"
            size="large"
            onChange={this.handleChange('sex')}
          >
            <Radio.Button value={1}><ManOutlined /> 男</Radio.Button>
            <Radio.Button value={0}><WomanOutlined /> 女</Radio.Button>
          </Radio.Group>
          <Input.Password
            className="input"
            prefix={<LockOutlined />}
            placeholder="输入你的密码"
            size="large"
            value={password}
            onChange={this.handleChange('password')}
          />
          {((checkpassword && password) && !isPassword)  ? <Alert message="密码长度请设为8-16位" type="error" /> : null }
          <Input.Password
            className="input"
            prefix={<LockOutlined />}
            placeholder="确认你的密码"
            size="large"
            value={checkpassword}
            onChange={this.handleChange('checkpassword')}
          />
          {((checkpassword && password) && !checksame) ? <Alert message="两次输入的密码不一致" type="error" /> : null }
          <Row gutter={16}>
            <Col span={12}>
              <Input
                className="input"
                prefix={<UserOutlined />}
                placeholder="昵称"
                size="large"
                value={nickname}
                onChange={this.handleChange('nickname')}
              />
            </Col>
            <Col span={12}>
              <Input
                className="input"
                prefix={<UserOutlined />}
                placeholder="真实姓名"
                size="large"
                value={realname}
                onChange={this.handleChange('realname')}
              />
            </Col>
          </Row>
          <div className="more">
            <div className="license">
              <Checkbox
                checked={agree}
                disabled={agree}
                onClick={({ target }) => this.setState({ agree: target.checked })}
              /> 我已阅读并接受
              <a target="_blank" rel="noreferrer" href="https://oj.bnuz.edu.cn/service.html">《用户服务协议》</a>和
              <a target="_blank" rel="noreferrer" href="https://oj.bnuz.edu.cn/privacy.html">《隐私协议》</a>
            </div>
          </div>
          {requireVapcha && <Vaptcha
            userId={vapchaUserId}
            onValidate={this.handleValidate}
          />}
          <Button
            size="large"
            type="primary"
            disabled={this.isSubmitDisabled() || requireVapcha}
            onClick={this.handleRegister}
            className="btn"
            loading={submitting}
          >
            注册
          </Button>
        </div>
      </div>
    );
  }
}
export default RegisterView;
