import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Empty, message, Spin 
} from 'antd';
import { get, noop, toUpper } from 'lodash';
import { showModal } from 'scripts/common/widgets/modal';
import FileSelectorView from 'scripts/apps/repository/file_selector';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { timeUsedFormatter } from 'scripts/common/utils/time_formatter';
import { LessonActions, LessonSelectors } from 'scripts/common/logic/education/lesson';
import { humannumbers } from 'scripts/common/utils/unit';

const mapStateToProps = (state, props) => {
  let videoData = null;
  if (props.videoId) {
    videoData = LessonSelectors.getVideoEntity(state)(props.videoId);
  }
  return {
    videoData,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getLessonVideo: LessonActions.getLessonVideo,
  addLessonVideo: LessonActions.addLessonVideo,
  delLessonVideo: LessonActions.delLessonVideo,
}, dispatch);

const LessonVideoManage = (props) => {
  const {
    lessonId,
    courseId,
    videoId,
    getLessonVideo,
    videoData,
  } = props;

  const [hasChange, setHasChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (videoId) {
      getLessonVideo({
        courseId,
        lessonId,
        onSuccess: () => {
          setIsLoading(false);
        },
      });
    }
  }, [lessonId, hasChange]);

  const fetchEntity = () => {
    setHasChange(!hasChange);
    setIsLoading(true);
  };

  const handleVideoAdd = () => {
    showModal(FileSelectorView, {
      onlyVideo: true,
      onOk: (modal, state) => {
        props.addLessonVideo({
          rid: state.selectedFiles[0],
          meta: '',
          courseId: props.courseId,
          lessonId: props.lessonId,
          onSuccess: () => {
            message.success('绑定视频成功');
            props.onNeedRefreshLessonEntity(() => {
              fetchEntity();
            });
          },
        });
      },
    });
  };

  const handleVideoDel = () => {
    props.delLessonVideo({
      courseId: props.courseId,
      lessonId: props.lessonId,
      onSuccess: () => {
        message.success('解除绑定成功');
        props.onNeedRefreshLessonEntity();
      },
    });
  };

  return (
    <div className="group">
      <div className="header">
        <div className="title">在线视频</div>
        <div className="extra_btn">
          {videoId ? <Button
            type="danger"
            onClick={handleVideoDel}
          >
            解除绑定
          </Button> : <Button
            type="primary"
            onClick={handleVideoAdd}
          >
            <PlusOutlined /> 从资源仓库选择
          </Button>}
        </div>
      </div>
      <Spin spinning={isLoading}>
        <div className="lesson-video-manage-view">
          {!videoData ? <Empty description="暂未绑定视频" /> : <div className="video-card">
            <div className="video-thumb">
              <img src={`data:image/jpeg;base64,${get(videoData, 'meta.video_thumb_img', '')}`} alt="" />
            </div>
            <div className="video-info">
              <div className="video-title">
                {get(videoData, 'title', '')}
              </div>
              <div className="video-description">
                {toUpper(get(videoData, 'meta.video.codec_name', ''))}&nbsp;/&nbsp;
                {get(videoData, 'meta.video.width', 0)} x {get(videoData, 'meta.video.height', 0)}&nbsp;/&nbsp;
                {get(videoData, 'meta.video.frame_rate', 0)} FPS&nbsp;/&nbsp;
                {humannumbers(get(videoData, 'meta.video.bit_rate', 0), 2)}ps&nbsp;/&nbsp;
                {toUpper(get(videoData, 'meta.audio.codec_name', ''))}&nbsp;/&nbsp;
                {humannumbers(get(videoData, 'meta.audio.sample_rate', 0), 1)}Hz&nbsp;/&nbsp;
                声道：{get(videoData, 'meta.audio.channels', 0)} ({get(videoData, 'meta.audio.channel_layout', '')})
              </div>
              <div className="video-meta">
                视频长度：{timeUsedFormatter(get(videoData, 'meta.video.duration'))}
              </div>
            </div>
          </div>}
        </div>
      </Spin>
    </div>
  );
};

LessonVideoManage.propTypes = {
  lessonId: PropTypes.string.isRequired,
  courseId: PropTypes.number.isRequired,
  videoId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  videoData: PropTypes.shape({}),
  getLessonVideo: PropTypes.func,
  delLessonVideo: PropTypes.func,
  addLessonVideo: PropTypes.func,
  onNeedRefreshLessonEntity: PropTypes.func,
};

LessonVideoManage.defaultProps = {
  videoData: null,
  videoId: '',
  addLessonVideo: noop,
  getLessonVideo: noop,
  delLessonVideo: noop,
  onNeedRefreshLessonEntity: noop,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonVideoManage);
