import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { get,  noop, set } from 'lodash';
import DataKeys from 'scripts/common/constants/data_keys';
import { AsgnReportPropTypes } from 'scripts/common/prop-types/asgn';
import { AsgnReportActions } from 'scripts/common/logic/education/asgn/actions/report';
import { AsgnReportSelectors } from 'scripts/common/logic/education/asgn/selectors/report';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';

import { showModal } from 'scripts/common/widgets/modal';
import { CourseSelectors, CourseActions } from 'scripts/common/logic/education/course';

import ReportListTable from './report_list_table';
import ReportListBar from './report_list_bar';
import { CheckupReportModal } from './widgets/checkup_report';
import { AsgnActions } from '../../../../common/logic/education/asgn/actions/asgn';
import { AsgnJudgeStatusActions } from '../../../../common/logic/education/asgn/actions/judge_status';
import { openLink } from '../../../../common/utils/location_helper';

const mapDispatchToProps = dispatch => bindActionCreators({
  getStudentReportsList: AsgnReportActions.getStudentReportsList,
  getZipCodes: AsgnJudgeStatusActions.getZipCodes,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  checkupStudentReport: AsgnReportActions.checkupStudentReport,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  getStatistics: AsgnActions.getStatistics,
  getArrangementsList: CourseActions.getArrangementsList,
  getNotVisitedList: AsgnReportActions.getNotVisitedList,
}, dispatch);

const LOADING_STATUS_KEY = 'report_list_view';

const mapStateToProps = (state, props) => {
  const courseId = get(props, 'courseId');
  const asgnId = get(props, 'asgnId');
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);

  const reportDatas = AsgnReportSelectors.getAsgnReportsList(DataKeys.TEACHER)(state);
  const studentsList = AsgnReportSelectors.getNotVisitedList(state);
  return {
    reportDatas,
    courseId,
    asgnId,
    isLoading,
    studentsList,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ReportListView extends React.PureComponent {
  static propTypes = {
    reportDatas: PropTypes.arrayOf(AsgnReportPropTypes),
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    checkupStudentReport: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
    getZipCodes: PropTypes.func,
    getStatistics: PropTypes.func,
    studentsList: PropTypes.arrayOf({}),
  };

  static defaultProps = {
    reportDatas: null,
    checkupStudentReport: noop,
    viewSuccessMessage: noop,
    getZipCodes: noop,
    getStatistics: noop,
  };

  state = {
    selectedReportIds: [],
    hasfetchList: true,

  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.hasfetchList) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getStudentReportsList({
        courseId: nextProps.courseId,
        asgnId: nextProps.asgnId,
        mode: 'all',
        onComplete: () => {
          nextProps.getArrangementsList({
            courseId: nextProps.courseId,
            onComplete: () => {
              nextProps.getNotVisitedList({
                courseId: nextProps.courseId,
                asgnId: nextProps.asgnId,
                onComplete: () => {
                  nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
                },
              });
            },
          });
        },
      });

      return {
        ...prevState,
        hasfetchList: false,
      };
    }
    return null;
  }

  handleSelectChanged = (selectedKeys) => {
    const selectedItems = selectedKeys.map((item) => {
      let data = {};
      this.props.reportDatas.forEach((i) => {
        if (item === i.id) {
          data = {
            id: i.id,
            finally_score: i.judge_score,
            teacher_remark: i.teacher_remark,
          };
        }
      });
      return data;
    });
    this.setState({
      selectedReportIds: selectedItems,
    });
  };

  // handleCodeZipClick = () => {
  //   this.props.getZipCodes({
  //     asgnId: this.props.asgnId,
  //     courseId: this.props.courseId,
  //     onSuccess: (r) => {
  //       console.log('12421213')
  //       openLink(r + '?download=1')
  //     }
  //   });
  // };
  handleStatisticsClick = () => {
    this.props.getStatistics({
      asgnId: this.props.asgnId,
      courseId: this.props.courseId,
    });
  };

  handleCheckupClick = () => {
    const modalBox = showModal(CheckupReportModal, {
      width: 760,
      selectedReportDatas: this.state.selectedReportIds,
      onOk: (modal, state) => {
        let datas = [];
        if (state.finally_score) {
          datas = state.selectedReportDatas.map((item) => {
            set(item, 'finally_score', state.finally_score);
            return item;
          });
        }
        this.props.checkupStudentReport({
          reports: state.finally_score ? datas : state.selectedReportDatas,
          courseId: this.props.courseId,
          asgnId: this.props.asgnId,
          onSuccess: () => {
            modalBox.close();
          },
          onComplete: () => {
            this.setState({
              ...this.state,
              hasfetchList: true,
            });
            this.props.viewSuccessMessage({
              message: '批改作业成功',
            });
          },
        });
        return false;
      },
    });
  };

  render() {
    return (
      <>
        <Spin
          size="large"
          tip="加载中..."
          spinning={this.props.isLoading}
        >
          <ReportListBar
            onCheckupClick={this.handleCheckupClick}
            // onCodeZipClick={this.handleCodeZipClick}
            onStatisticsClick={this.handleStatisticsClick}
          />
          <ReportListTable
            reportDatas={this.props.reportDatas}
            onSelectorChanged={this.handleSelectChanged}
            studentsList={this.props.studentsList}
          />
        </Spin>
      </>
    );
  }
}

export default ReportListView;
