import 'styles/collection/judge_status.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  get, isEqual, pick, noop, isEmpty 
} from 'lodash';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { JudgeStatusActions } from 'scripts/common/logic/collection/actions/judge_status';
import { JudgeStatusSelectors } from 'scripts/common/logic/collection/selectors/judge_status';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { JudgeStatusPropTypes } from 'scripts/common/prop-types/judge_status';
import { showModal } from 'scripts/common/widgets/modal';
import JudgeStatusFilter from 'scripts/apps/widgets/judge_status/filter';
import JudgeStatusListTable from 'scripts/apps/widgets/judge_status/list_table';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { AccountSelectors } from 'scripts/common/logic/account';
import { createJudgeResultModal } from 'scripts/apps/widgets/judge_status/dialog';
import { CollectionViewsRoutes, CollectionProblemViewRoutes } from 'scripts/apps/routes';
import { AccountPropTypes } from 'scripts/common/prop-types/account';

const LOADING_STATUS_KEY = 'judge_status_list';

const JudgeResultModal = createJudgeResultModal(JudgeStatusActions, JudgeStatusSelectors);

const mapDispatchToProps = dispatch => bindActionCreators({
  getJudgeStatusList: JudgeStatusActions.getJudgeStatusList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch);
const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const { match } = props;
  const CollectionProblemId = get(match, 'params.problemId');
  const collectionId = get(match, 'params.collectionId', '');
  const judgeStatusDatas = JudgeStatusSelectors.getJudgeStatusListData(state);
  const pager = JudgeStatusSelectors.judgeStatusListViewPagerSelector(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const queryParams = {
    ...PaginationParams40,
    ...pick(queryset, ['limit', 'page', 'problem', 'order', 'author', 'flag', 'filter_owner']),
    collectionId,
  };
  const currentRoutePath = props.problemId
    ? CollectionProblemViewRoutes.JUDGE_STATUS : CollectionViewsRoutes.JUDGE_STATUS;
  return {
    isLoading,
    queryParams,
    collectionId,
    judgeStatusDatas,
    currentRoutePath,
    CollectionProblemId,
    paginationParams: pager || PaginationParams40,
    account: AccountSelectors.getLoginAccount(state),
  };
};

const FILTER_KEY_PICKUP = ['limit', 'page', 'problem', 'order', 'author', 'flag', 'filter_owner'];

@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class JudgeStatusView extends React.PureComponent {
  static fetchList = (nextProps) => {
    const isFilterOwner = nextProps.filterOwner || get(nextProps.queryParams, 'filter_owner') === '1';
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getJudgeStatusList({
      collectionId: nextProps.collectionId,
      query: {
        ...pick(
          nextProps.queryParams,
          FILTER_KEY_PICKUP
        ),
        ...(isFilterOwner ? { author_id: get(nextProps, 'account.id') } : {}),
        ...(nextProps.problemId ? { problem_id: nextProps.problemId } : {}),
      },
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    ...RoutePropTypes,
    account: AccountPropTypes,
    columnStyle: PropTypes.string,
    getJudgeStatusList: PropTypes.func,
    isLoading: PropTypes.bool,
    problemId: PropTypes.string,
    collectionId: PropTypes.string.isRequired,
    setLoadingStatus: PropTypes.func,
    judgeStatusDatas: PropTypes.arrayOf(JudgeStatusPropTypes),
    currentRoutePath: PropTypes.string,
    paginationParams: PaginationPropTypes,
    filterOwner: PropTypes.bool,
    showOwner: PropTypes.bool,
  };
  
  static defaultProps = {
    account: null,
    columnStyle: 'normal',
    isLoading: true,
    getJudgeStatusList: noop,
    setLoadingStatus: noop,
    judgeStatusDatas: [],
    problemId: '',
    currentRoutePath: '',
    paginationParams: null,
    filterOwner: false,
    showOwner: false,
  };

  state = {
    queryParams: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      if (get(nextProps.queryParams, 'filter_owner') === '1' && isEmpty(nextProps.account)) {
        return null;
      }
      const { queryParams } = nextProps;
      JudgeStatusView.fetchList(nextProps);
      return {
        queryParams,
      };
    }
    return null;
  }

  refreshList = () => {
    JudgeStatusView.fetchList(this.props);
  };

  handleReload = () => {
    JudgeStatusView.fetchList(this.props);
  };

  handleProblemClick = (item) => {
    const { history, match } = this.props;
    history.push(buildPath(
      CollectionProblemViewRoutes.CONTENT,
      {
        collectionId: this.props.collectionId,
        problemId: get(item, 'collection_item.id', ''),
      },
      match.params,
      {}
    ));
  };

  handleJudgeStatusClick = judgeStatus => () => {
    showModal(JudgeResultModal, {
      judgeStatusId: get(judgeStatus, 'id'),
      apiPayloads: {
        collectionId: this.props.collectionId,
      },
    });
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        collectionId: this.props.collectionId,
        problemId: this.props.CollectionProblemId ? this.props.CollectionProblemId : '',
      },
      match.params,
      {
        ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
        limit: pageSize,
        page,
      }
    ));
  };

  handleNameSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        collectionId: this.props.collectionId,
        problemId: this.props.CollectionProblemId ? this.props.CollectionProblemId : '',
      },
      match.params,
      value === ''
        ? {
          ...pick(this.state.queryParams, 'limit', 'page', 'order', 'author', 'filter_owner'),
        } : {
          ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
          problem: value,
        }
    ));
  };

  handleAuthorSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        collectionId: this.props.collectionId,
        problemId: this.props.CollectionProblemId ? this.props.CollectionProblemId : '',
      },
      match.params,
      value === ''
        ? {
          ...pick(this.state.queryParams, 'limit', 'page', 'problem', 'order', 'filter_owner'),
        } : {
          ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
          author: value,
        }
    ));
  };

  handleFlagClick = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        collectionId: this.props.collectionId,
        problemId: this.props.CollectionProblemId ? this.props.CollectionProblemId : '',
      },
      match.params,
      value !== 'all' ? {
        ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
        flag: parseInt(value, 10),
      } : {
        ...pick(this.state.queryParams, 'limit', 'page', 'problem', 'order', 'author', 'filter_owner'),
      }
    ));
  };

  handleOrderClick = (value) => {
    const num = value ? 1 : 0;
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        collectionId: this.props.collectionId,
        problemId: this.props.CollectionProblemId ? this.props.CollectionProblemId : '',
      },
      match.params,
      {
        ...pick(this.state.queryParams, FILTER_KEY_PICKUP),
        order: num,
      }
    ));
  };

  handleOwnerClick = (checked) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      {
        collectionId: this.props.collectionId,
        problemId: this.props.CollectionProblemId ? this.props.CollectionProblemId : '',
      },
      match.params,
      {
        ...pick(this.state.queryParams, 'limit', 'page', 'problem', 'order', 'author', 'flag'),
        filter_owner: checked ? '1' : '0',
      }
    ));
  };

  render() {
    const isFilterOwner = this.props.filterOwner || get(this.props.queryParams, 'filter_owner') === '1';
    return <div className="judge_status_view">
      <JudgeStatusFilter
        onNameSearch={this.handleNameSearch}
        onAuthorSearch={this.handleAuthorSearch}
        onOrderClick={this.handleOrderClick}
        onFlagClick={this.handleFlagClick}
        onOwnerClick={this.handleOwnerClick}
        onReload={this.handleReload}
        filterOwner={isFilterOwner}
        problemId={this.props.problemId}
        showOwner={this.props.showOwner}
        hideSearch={this.props.columnStyle === 'minimal'}
        flag={get(this.state.queryParams, 'flag', 'all')}
        authorKw={get(this.state.queryParams, 'author', '')}
        problemKw={get(this.state.queryParams, 'problem', '')}
        orderAsc={get(this.state.queryParams, 'order', false) === '1'}
      />
      <JudgeStatusListTable
        loading={this.props.isLoading}
        columnStyle={this.props.columnStyle}
        onPageChange={this.handlePageChange}
        judgeStatusDatas={this.props.judgeStatusDatas}
        onProblemClick={this.handleProblemClick}
        onJudgeStatusClick={this.handleJudgeStatusClick}
        onSelectChanged={noop}
        paginationParams={this.props.paginationParams}
      />
    </div>;
  }
}

export default JudgeStatusView;
