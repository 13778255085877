import React from 'react';
import PropTypes from 'prop-types';
import { get, noop, debounce } from 'lodash';
import { Collapse } from 'antd';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { INPUT_CHANGE_DEBOUNDCE_TIME } from 'scripts/common/constants/global';

const { Panel } = Collapse;

class CourseInfoCollapse extends React.PureComponent {
  static propTypes = {
    course: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: noop,
  };

  state = {
    description: get(this.props.course, 'description'),
    teach_plan: get(this.props.course, 'teach_plan'),
  };

  handleEditorChange = keyName => debounce((text) => {
    this.setState({
      [keyName]: text,
    }, () => {
      this.props.onChange({
        [keyName]: text,
      });
    });
  }, INPUT_CHANGE_DEBOUNDCE_TIME);

  render() {
    const courseDescription = get(this.state, 'description');
    const courseTeachPlain = get(this.state, 'teach_plan');

    return (
      <Collapse className="course_info_editor_collapse" defaultActiveKey={['desc', 'teach_plan', 'exam_scope', 'reference_book', 'assistant']}>
        <Panel header="课程简介" key="desc">
          <MarkdownEditor
            height="15em"
            onChange={this.handleEditorChange('description')}
            defaultValue={courseDescription}
          />
        </Panel>
        <Panel header="教学大纲" key="teach_plan">
          <MarkdownEditor
            height="15em"
            onChange={this.handleEditorChange('teach_plan')}
            defaultValue={courseTeachPlain}
          />
        </Panel>
      </Collapse>
    );
  }
}

export default CourseInfoCollapse;
