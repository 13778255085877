import 'styles/account/account-editor.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { noop, pick } from 'lodash';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import {
  Form, Input, Radio, Row, Col, Divider, Button 
} from 'antd';
import { AccountPropTypes } from 'scripts/common/prop-types/account';

const { TextArea } = Input;

class AccountEditor extends React.PureComponent {
  static propTypes = {
    account: AccountPropTypes,
    editAccount: PropTypes.func,
    handleChange: PropTypes.func,
    block: PropTypes.bool,
    showSaveButton: PropTypes.bool,
    disabledEmail: PropTypes.bool,
  };

  static defaultProps = {
    account: {},
    block: false,
    showSaveButton: false,
    disabledEmail: true,
    editAccount: noop,
    handleChange: noop,
  };

  state = {
    account: {
      ...JSON.parse(JSON.stringify(this.props.account)),
    },
    processing: false,
  };

  handleEditAccount = () => {
    this.setState({
      processing: true,
    }, () => {
      this.props.editAccount({
        account: pick(this.state.account, [
          'email', 'sex', 'nickname', 'realname', 'motto'
        ]),
        onComplete: () => {
          this.setState({
            processing: false,
          });
        },
      });
    });
  };

  handleInputChange = keyName => ({ target }) => {
    const { account }  = this.state;
    this.setState({
      account: {
        ...account,
        [keyName]: target.value,
      },
    }, () => {
      this.props.handleChange(this.state.account);
    });
  };

  renderAccountEditArea = () => {
    const { account } = this.state;
    return (
      <div className="account-profile-editor">
        <Row gutter={this.props.block ? 0 : 16}>
          <Col span={this.props.block ? 24 : 12}>
            <Form.Item label="昵称" required>
              <Input
                size="large"
                key="nickname"
                value={account.nickname}
                onChange={this.handleInputChange('nickname')}
                placeholder="昵称"
              />
            </Form.Item>
          </Col>
          <Col span={this.props.block ? 24 : 12}>
            <Form.Item label="真实姓名">
              <Input
                size="large"
                key="realname"
                value={account.realname}
                onChange={this.handleInputChange('realname')}
                placeholder="真实姓名"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={this.props.block ? 0 : 16}>
          <Col span={this.props.block ? 24 : 12}>
            {!this.props.disabledEmail && <Form.Item label="邮箱" required>
              <Input
                size="large"
                key="email"
                value={account.email}
                onChange={this.handleInputChange('email')}
                placeholder="邮箱"
              />
            </Form.Item>}
          </Col>
          <Col span={this.props.block ? 24 : 12}>
            <Form.Item label="性别" required>
              <Radio.Group
                className="account-sex-radio"
                value={this.state.account.sex}
                buttonStyle="solid"
                size="large"
                onChange={this.handleInputChange('sex')}
              >
                <Radio.Button value={1}><ManOutlined /> 男</Radio.Button>
                <Radio.Button value={0}><WomanOutlined /> 女</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="个人描述">
          <TextArea
            key="motto"
            rows={6}
            value={account.motto}
            onChange={this.handleInputChange('motto')}
            placeholder="个人描述"
          />
        </Form.Item>
        {(this.props.showSaveButton) && <>
          <Divider />
          <div>
            <Button
              block={this.props.block}
              loading={this.state.processing}
              type="primary"
              size="large"
              onClick={this.handleEditAccount}
            >
              保存设置
            </Button>
          </div>
        </>}
      </div>
    );
  };

  render() {
    return this.renderAccountEditArea();
  }
}

export default AccountEditor;
