import 'styles/contest/rank/ranklist.scss';

import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { message } from 'antd';
import { ContestRankListSelectors, ContestRankListActions } from 'scripts/common/logic/contest/ranklist';
import { ContestPropTypes, ContestProblemPropTypes } from 'scripts/common/prop-types/contest';
import { ContestProblemSelectors } from 'scripts/common/logic/contest/problem';
import TimeLineRankList  from 'scripts/common/widgets/ranklist/timeline_ranklist';
import { RegisterTypeEnum } from 'scripts/common/enums/contest';
import PathToRegexp from 'path-to-regexp';
import { buildApiPath, openLink, buildPath } from 'scripts/common/utils/location_helper';
import apis from 'scripts/common/constants/apis';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { ContestRoutes } from '../../routes';
import { withContestContext } from '../contest_provider';

const mapStateToProps = (state) => {
  const rkd = ContestRankListSelectors.contestRankListSelector(state);
  const problems = ContestProblemSelectors.problemListData(state);
  return {
    ranklist_datas: rkd,
    problems,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getContestRankListDatas: ContestRankListActions.getContestRankListDatas,
}, dispatch);

@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class RankListView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    contest: ContestPropTypes.isRequired,
    problems: PropTypes.arrayOf(ContestProblemPropTypes),
    getContestRankListDatas: PropTypes.func.isRequired,
    ranklist_datas: PropTypes.shape({}),
    account: AccountPropTypes.isRequired,
  };

  static defaultProps = {
    ranklist_datas: null,
    problems: [],
  };

  state = {
    isLoading: false,
    listMode: 'full',
  };

  componentDidMount() {
    this.fetchRankList();
  }

  fetchRankList = (ts = null) => {
    if (this.state.isLoading) return;
    this.setState({
      isLoading: true,
    }, () => {
      this.props.getContestRankListDatas({
        contestId: this.props.contest.id,
        timestamp: ts,
        onSuccess: () => {
          this.setState({
            isLoading: false,
          });
        },
      });
    });
  };

  calcProblemStats = () => {
    const stats = {};
    this.props.problems.forEach((problem) => {
      stats[get(problem, 'problem.id')] = {
        ac: get(problem, 'accepted', 0),
        sub: get(problem, 'submission', 0),
      };
    });
    return stats;
  };

  handleTimeSliderChange = (ts) => {
    this.fetchRankList(ts);
  };

  handleListModeChange = (v) => {
    this.setState({
      listMode: v,
    });
  };

  handleDownloadRankClick = () => {
    if (get(this.props, 'account.id', 0) === get(this.props, 'contest.author_id', -1)) {
      const pattern = PathToRegexp.compile(apis.CONTEST.RANK.DOWNLOAD);
      openLink(buildApiPath(pattern({ cid: this.props.contest.id })), true);
    } else {
      message.error('此操作仅限比赛发起者，如若需要请联系比赛工作人员');
    }
  };

  handleProblemClick = (value) => {
    const { history } = this.props;
    history.push(buildPath(ContestRoutes.PROBLEM.CONTENT, {
      contestId: this.props.contest.id,
      problemId: value,
    }));
  };

  handleRefresh = () => {
    this.fetchRankList();
  };

  render() {
    const customAwards = get(this.props.contest, 'configs.custom_awards', false);
    const contestTeamType = get(this.props.contest, 'team_type', 1);
    return <div className="wejudge-contest-ranklist">
      <TimeLineRankList
        accountId={get(this.props, 'account.id', '')}
        startTime={this.props.contest.start_time}
        endTime={this.props.contest.end_time}
        listMode={this.state.listMode}
        onProblemClick={this.handleProblemClick}
        onListModeChange={this.handleListModeChange}
        onTimeSliderChange={this.handleTimeSliderChange}
        rankListDatas={this.state.isLoading ? null : this.props.ranklist_datas}
        problemStats={this.calcProblemStats()}
        hideId={contestTeamType === RegisterTypeEnum.SINGLE.value}
        onRefresh={this.handleRefresh}
        awardsOptions={{
          gold: !customAwards ? 0.1 :  get(this.props.contest, 'configs.awards_gold'),
          silver: !customAwards ? 0.2 :  get(this.props.contest, 'configs.awards_silver'),
          bronze: !customAwards ? 0.3 :  get(this.props.contest, 'configs.awards_bronze'),
        }}
        handleDownloadClick={this.handleDownloadRankClick}
      />
    </div>;
  }
}

export default RankListView;
