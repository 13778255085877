import React from 'react';
import PropTypes from 'prop-types';
import {
  get, noop, isEmpty, pick 
} from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  CloseCircleTwoTone,
  FileSyncOutlined,
  PlusOutlined
} from '@ant-design/icons';

import {
  Form, Table, Button, Spin, Modal 
} from 'antd';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { showModal } from 'scripts/common/widgets/modal';
import TestCasesEditor from 'scripts/apps/problem/widgets/dialog/test_cases';
import { CollectionProblemActions } from 'scripts/common/logic/collection/actions';
import { CollectionProblemsSelectors } from 'scripts/common/logic/collection/selectors';
import { store } from 'scripts/common/logic/store';

import TestCasesMaker from '../widgets/dialog/test_case_maker';
import TestCasesMakerHistory from '../widgets/dialog/test_case_maker_history';

@connect(null, dispatch => bindActionCreators({
  getTestCaseDatas: CollectionProblemActions.getTestCaseDatas,
  addTestCases: CollectionProblemActions.addTestCases,
  modifyTestCases: CollectionProblemActions.modifyTestCases,
  deleteTestCases: CollectionProblemActions.deleteTestCases,
  saveTestCaseDatasTemplate: CollectionProblemActions.saveTestCaseDatasTemplate,
  startTestCasesMaker: CollectionProblemActions.startTestCasesMaker,
  downloadTestCases: CollectionProblemActions.downloadTestCases,
}, dispatch))
class TestCasesEditorView extends React.PureComponent {
  static propTypes  = {
    problem: ProblemPropTypes.isRequired,
    collectionId: PropTypes.string.isRequired,
    addTestCases: PropTypes.func,
    modifyTestCases: PropTypes.func,
    deleteTestCases: PropTypes.func,
    getTestCaseDatas: PropTypes.func,
    startTestCasesMaker: PropTypes.func,
    saveTestCaseDatasTemplate: PropTypes.func,
    downloadTestCases: PropTypes.func,
  };

  static defaultProps = {
    addTestCases: noop,
    modifyTestCases: noop,
    deleteTestCases: noop,
    getTestCaseDatas: noop,
    startTestCasesMaker: noop,
    saveTestCaseDatasTemplate: noop,
    downloadTestCases: noop,
  };

  state = {
    loading: false,
    handleSelected: [],
  };

  NORMAL_COLUMNS = [{
    title: '标识',
    width: 160,
    dataIndex: 'handle',
  }, {
    title: '名称',
    dataIndex: 'name',
  }, {
    title: '更新时间',
    width: 200,
    align: 'center',
    dataIndex: 'update_time',
  }, {
    title: '公开数据',
    width: 100,
    align: 'center',
    dataIndex: 'visible',
  }, {
    title: '参与评测',
    width: 100,
    align: 'center',
    dataIndex: 'available',
  }, {
    title: '操作',
    width: 140,
    align: 'center',
    dataIndex: 'operation',
  }];

  handleCreateClick = () => {
    this.props.saveTestCaseDatasTemplate({});
    const dlg = showModal(TestCasesEditor, {
      collectionId: this.props.collectionId,
      problemId: this.props.problem.id,
      mode: 'new',
      onOk: (m, state) => {
        dlg.setLoading(true);
        this.props.addTestCases({
          collectionId: this.props.collectionId,
          problemId: this.props.problem.id,
          testCase: {
            nodata: false,
            ...pick(state.testCase, ['name', 'visible', 'available']),
            ...pick(state.testCaseDataTemp, ['stdin', 'stdout']),
          },
          onSuccess: () => {
            dlg.close();
          },
          onComplete: () => {
            dlg.setLoading(false);
          },
        });
        return false;
      },
    });
  };

  handleEditorClick = testCase => () => {
    const {
      getTestCaseDatas,
      problem,
      collectionId,
      modifyTestCases,
      downloadTestCases,
    } = this.props;
    this.setState({ loading: true });
    getTestCaseDatas({
      collectionId,
      problemId: problem.id,
      testCaseId: get(testCase, 'handle', ''),
      onSuccess: () => {
        const t = CollectionProblemsSelectors.getTestCasesDatasTemplateSelector(store.getState());
        const dlg = showModal(TestCasesEditor, {
          collectionId: this.props.collectionId,
          problemId: this.props.problem.id,
          mode: 'modify',
          testCase,
          testCaseDataTemp: t,
          getTestCaseDatas,
          downloadTestCases,
          onOk: (m, state) => {
            dlg.setLoading(true);
            modifyTestCases({
              collectionId: this.props.collectionId,
              problemId: this.props.problem.id,
              testCase: {
                nodata: get(state.testCaseDataTemp, 'overload', true),
                ...pick(state.testCase, ['handle', 'name', 'visible', 'available']),
                ...(state.testCaseDataTemp.overload ? {} : pick(state.testCaseDataTemp, ['stdin', 'stdout'])),
              },
              onSuccess: () => {
                dlg.close();
              },
              onComplete: () => {
                dlg.setLoading(false);
              },
            });
            return false;
          },
        });
      },
      onComplete: () => { this.setState({ loading: false }); },
    });
  };

  handleDeleteClick = testCase => () => {
    Modal.confirm({
      title: '删除测试数据',
      content: '删除的数据将不可恢复，是否继续？',
      okType: 'danger',
      onOk: () => {
        this.props.deleteTestCases({
          collectionId: this.props.collectionId,
          problemId: this.props.problem.id,
          testCase: {
            ...pick(testCase, ['handle']),
          },
        });
      },
    });
  };

  handleSelectorChanged = (selectedKeys) => {
    this.setState({
      handleSelected: selectedKeys,
    });
  };

  handleStartTCMaker = () => {
    showModal(TestCasesMaker, {
      width: 500,
      handles: this.state.handleSelected,
      onOk: (modal, state) => {
        const answerCode = get(this.props.problem, `options.judge_options.answer_cases.${state.language}`, '').trim();
        if (!answerCode) {
          Modal.error({
            title: '错误',
            content: '所选语言没有设置参考答案，无法进行生成操作。',
          });
          return true;
        }
        this.props.startTestCasesMaker({
          collectionId: this.props.collectionId,
          problemId: this.props.problem.id,
          language: state.language,
          handles: this.state.handleSelected,
          onSuccess: () => {
            this.handleTCMakerHistory();
          },
        });
        return true;
      },
    });
  };

  handleTCMakerHistory = () => {
    showModal(TestCasesMakerHistory, {
      collectionId: this.props.collectionId,
      problem: this.props.problem,
      okButtonProps: false,
    });
  };

  renderTestCasesRow = () => {
    const { problem } = this.props;
    const { options } = problem;
    const testCases = Object.assign([], get(options, 'judge_options.test_cases', []));
    testCases.sort((a, b) => a.order > b.order);
    return testCases.map((item) => {
      return {
        key: item.handle,
        handle: item.handle,
        name: item.name,
        update_time: formatTime(get(item, 'update_time', 0), 'LONG_DATETIME'),
        visible: item.visible ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#f5222d" />,
        available: item.available ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#f5222d" />,
        operation: (<>
          <Button type="primary" size="small" onClick={this.handleEditorClick(item)}>编辑</Button>
          <Button type="danger" size="small" onClick={this.handleDeleteClick(item)}>删除</Button>
        </>),
      };
    });
  };

  render() {
    const { problem } = this.props;
    if (isEmpty(problem)) return null;

    return (
      <>
        <Form className="test_cases_editor">
          <div className="control_bar">
            <div className="bar_left">
              <Button
                onClick={this.handleCreateClick}
                type="primary"
              >
                <PlusOutlined /> 新增测试单元
              </Button>
            </div>
            <div className="bar_right">
              <Button.Group>
                <Button
                  disabled={!this.state.handleSelected.length > 0}
                  onClick={this.handleStartTCMaker}
                >
                  <FileSyncOutlined /> 自动生成数据
                </Button>
                <Button onClick={this.handleTCMakerHistory}>
                  <ClockCircleOutlined /> 生成操作历史
                </Button>
              </Button.Group>
            </div>
          </div>
          <div className="case-wrap">
            <Table
              className="test_cases_tables"
              size="middle"
              dataSource={this.renderTestCasesRow()}
              loading={this.state.loading}
              rowSelection={{
                selectedRowKeys: this.state.handleSelected,
                onChange: this.handleSelectorChanged,
              }}
              pagination={false}
              columns={this.NORMAL_COLUMNS}
            />
          </div>
        </Form>
      </>
    );
  }
}

export default TestCasesEditorView;
