import 'styles/education/asgn/report_list.scss';

import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

class ReportListBar extends React.PureComponent {
  static propTypes = {
    onCheckupClick: PropTypes.func.isRequired,
    // onCodeZipClick: PropTypes.func,
    onStatisticsClick: PropTypes.func.isRequired,
  };

  state= {

  };

  render() {
    return (
      <div className="report-list-bar">
        <div className="control-btns">
          <Button type="primary" onClick={this.props.onCheckupClick}>
            快速批改
          </Button>
        </div>
        <div className="control-btns">
          {/*<Button onClick={this.props.onCodeZipClick}>*/}
          {/*代码打包*/}
          {/*</Button>*/}
          <Button onClick={this.props.onStatisticsClick}>
            数据分析
          </Button>
        </div>
      </div>
    );
  }
}

export default ReportListBar;
