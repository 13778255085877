import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { connect } from 'react-redux';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import SubmitCodeProblemWidget from 'scripts/apps/problem/judge_status/submit_code_problem';
import { ContestProblemPropTypes, ContestPropTypes } from 'scripts/common/prop-types/contest';
import { ContestJudgeStatusActions, ContestJudgeStatusSelectors } from 'scripts/common/logic/contest/judge_status';
import { bindActionCreators } from 'redux';
import { showModal } from 'scripts/common/widgets/modal';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { createJudgeResultModal } from 'scripts/apps/widgets/judge_status/dialog';

const JudgeResultModal = createJudgeResultModal(ContestJudgeStatusActions, ContestJudgeStatusSelectors, 'Contest');

const mapDispatchToProps = dispatch => bindActionCreators({
  submitContestCode: ContestJudgeStatusActions.submitContestCode,
  getContestJudgeStatusEntity: ContestJudgeStatusActions.getContestJudgeStatusEntity,
  viewErrorMessage: WeJudgeActions.viewErrorMessage,
}, dispatch);

@withContestContext
@connect(null, mapDispatchToProps)
class SubmitCodeView extends React.PureComponent {
  static propTypes ={
    contest: ContestPropTypes,
    problem: ProblemPropTypes.isRequired,
    contestProblem: ContestProblemPropTypes.isRequired,
    submitContestCode: PropTypes.func,
    getContestJudgeStatusEntity: PropTypes.func,
    viewErrorMessage: PropTypes.func,
  };

  static defaultProps = {
    contest: null,
    submitContestCode: noop,
    getContestJudgeStatusEntity: noop,
    viewErrorMessage: noop,
  };

  state = {};

  getLanguageMask = () => {
    const plang = get(this.props.contestProblem, 'code_language', -1);
    if (plang < 0) {
      return get(this.props.contest, 'code_language', 0);
    }
    return plang;
  };

  showJudgeStatusModel = (status) => {
    const contestId = get(this.props.contest, 'id');
    showModal(JudgeResultModal, {
      judgeStatusId: get(status, 'id'),
      apiPayloads: {
        contestId,
      },
    });
  };

  render() {
    return <SubmitCodeProblemWidget
      disableDrafts
      problem={this.props.problem}
      languageMask={this.getLanguageMask()}
      submitCode={(payload) => {
        this.props.submitContestCode({
          ...payload,
          contestId: get(this.props.contest, 'id'),
          problemId: get(this.props.contestProblem, 'id'),
        });
      }}
      getJudgeStatusEntity={(payloads) => {
        this.props.getContestJudgeStatusEntity({
          ...payloads,
          contestId: get(this.props.contest, 'id'),
        });
      }}
      onShowJudgeStatusModel={this.showJudgeStatusModel}
      getJudgeStatusEntitySelector={ContestJudgeStatusSelectors.getContestJudgeStatusEntity}
    />;
  }
}
export default SubmitCodeView;
