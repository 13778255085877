import 'styles/account/auth/auth.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { UserOutlined, WechatOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { bindActionCreators } from 'redux';
import {
  Switch, Route, matchPath, withRouter 
} from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AccountActions } from 'scripts/common/logic/account';
import { AccountRoutes } from 'scripts/apps/routes';
import { get } from 'lodash';
import { buildPath, getQuery } from 'scripts/common/utils/location_helper';
import LoginForm from './widgets/login_form';
import LoginWechatQrconnect from './widgets/wechat_qrconnect';
import ForgetPassword from './widgets/forget_password';

const mapDispatchToProps = dispatch => bindActionCreators({
  doLogin: AccountActions.login,
  doSendEmailForget: AccountActions.doSendEmailForget,
}, dispatch);

@withRouter
@connect((state, props) => {
  const matchItem = matchPath(
    props.location.pathname,
    { path: AccountRoutes.AUTH.SUBITEMS, exact: true }
  );
  const actionKey = get(matchItem, 'params.subItemName', '');
  return {
    actionKey,
  };
}, mapDispatchToProps)
class LoginView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    actionKey: PropTypes.string.isRequired,
    doLogin: PropTypes.func.isRequired,
    doSendEmailForget: PropTypes.func.isRequired,
  };

  changePage = (key) => {
    this.props.history.push(buildPath(
      AccountRoutes.AUTH.SUBITEMS,
      { subItemName: key },
      null,
      getQuery(this.props.location)
    ));
  };

  handleChangePage = ({ key }) => {
    this.changePage(key);
  };

  render() {
    const isLogin = this.props.actionKey === 'wechat' || this.props.actionKey === 'login';
    const isForget = this.props.actionKey === 'forget';
    return (
      <div className="body-wrap">
        <div className="account-auth account-login">
          <div className="title">{isLogin && '欢迎使用WeJudge'}{isForget && '重置密码'}</div>
          {isLogin && <Menu
            onSelect={this.handleChangePage}
            selectedKeys={[this.props.actionKey]}
            mode="horizontal"
            style={{ marginBottom: 16 }}
          >
            <Menu.Item key="login">
              <UserOutlined /> 账号登录
            </Menu.Item>
            <Menu.Item key="wechat">
              <WechatOutlined /> 微信登录
            </Menu.Item>
          </Menu>}
          <Switch>
            <Route
              path={AccountRoutes.AUTH.LOGIN}
              render={({ ...rest }) => <LoginForm
                {...rest}
                changePage={this.changePage}
                doLogin={this.props.doLogin}
              />}
            />
            <Route
              path={AccountRoutes.AUTH.WECHAT}
              render={({ ...rest }) => <LoginWechatQrconnect
                {...rest}
                changePage={this.changePage}
              />}
            />
            <Route
              path={AccountRoutes.AUTH.FORGET}
              render={({ ...rest }) => <ForgetPassword
                {...rest}
                changePage={this.changePage}
                doSendEmailForget={this.props.doSendEmailForget}
              />}
            />
            <Route />
          </Switch>
        </div>
      </div>
    );
  }
}

export default LoginView;
