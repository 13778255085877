import React from 'react';
// import { UserOutlined } from '@ant-design/icons';
// import { Avatar } from 'antd';
import { get, has } from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export const indexToChar = (index) => {
  const indexParsed = parseInt(index, 10);
  return (indexParsed > 0 && indexParsed <= 26)
    ? String.fromCharCode(65 + (indexParsed - 1)) : '-';
};

class FancyRankItem extends React.Component {
  static propTypes = {
    top: PropTypes.number,
    isCurrent: PropTypes.bool,
    awardType: PropTypes.number,
    number: PropTypes.number,
    rankCount: PropTypes.number,
    problems: PropTypes.arrayOf(PropTypes.string),
    problemInfos: PropTypes.shape({}),
    accountInfo: PropTypes.shape({}),
    diffSolutions: PropTypes.shape({}),   // Solution变化数据
    rollingStatus: PropTypes.shape({}),   // 滚榜判定情况
    transitionEnd: PropTypes.func.isRequired,
  };

  static defaultProps = {
    top: 0,
    isCurrent: false,
    awardType: 0,
    number: 0,
    problems: [],
    problemInfos: {},
    accountInfo: {},
    diffSolutions: {},
    rollingStatus: {},
  };

  static getDerivedStateFromProps(nextProps) {
    const { 
      accountInfo,
      problems,
      rollingStatus,
      diffSolutions,
    } = nextProps;
    let solved = 0;
    let totalUsedTime = 0;
    problems.forEach((pid) => {
      const hasNewStatus = has(rollingStatus, pid);
      const sol = get(accountInfo, 'solutions', {});
      const hasStatus = has(sol, pid);
      let accepted = 0;
      if (hasNewStatus) {
        // 有diff记录的时候用diff里边的时间
        accepted = get(diffSolutions, `${pid}.accepted`, 0);
        const timeUsed = get(diffSolutions, `${pid}.time_used`, 0);
        if (rollingStatus[pid] === 2) {
          // 滚动过，只有过题了才记录时间
          solved += (accepted > 0 ? 1 : 0);
          totalUsedTime += (accepted > 0 ? timeUsed : 0);
        }
      } else if (hasStatus) {
        // 否则用account里边的时间
        accepted = get(accountInfo, `solutions.${pid}.accepted`, 0);
        const timeUsed = get(accountInfo, `solutions.${pid}.time_used`, 0);
        solved += (accepted > 0 ? 1 : 0);
        totalUsedTime += (accepted > 0 ? timeUsed : 0);
      }
    });
    return {
      solved,
      totalUsedTime,
    };
  }

  state = {
    solved: 0,
    totalUsedTime: 0,
  };

  componentDidMount() {
    this.itemRef.current.addEventListener('transitionend', this.props.transitionEnd);
  }

  itemRef = React.createRef();

  render() {
    const {
      accountInfo, problems, problemInfos, rollingStatus, diffSolutions, 
    } = this.props;
    const ignoreRank = get(accountInfo, 'account.ignore_rank', false);
    return (
      <div
        className={classnames('fancy-rank-item', {
          active: this.props.isCurrent,
          gold: this.props.awardType === 1,
          silver: this.props.awardType === 2,
          bronze: this.props.awardType === 3,
        })}
        style={{ top: this.props.top }}
        ref={this.itemRef}
      >
        <div className="rank-item-number">{ignoreRank ? '-' : this.props.rankCount}</div>
        {/*<div className="rank-item-logo">*/}
        {/*  <Avatar shape="circle" icon={<UserOutlined />} size={64} />*/}
        {/*</div>*/}
        <div className="rank-item-content">
          <div className="rank-item-content-team-name">
            <span style={get(accountInfo, 'account.sex', 1) === 0 ? { color: '#ff7875' } : {}}>
              {ignoreRank && '*'}{get(accountInfo, 'account.nickname')}
              {get(accountInfo, 'account.schoolname') ? <i style={{ fontSize: '16px', marginLeft: '8px', color: '#fff566' }}>{get(accountInfo, 'account.schoolname')}</i> : null}
            </span>
          </div>
          <div className="rank-item-content-problems">
            {problems.map((pid) => {
              const hasNewStatus = has(rollingStatus, pid);     // 是否有diff记录
              const problem = problemInfos[pid];

              let submission = 0;
              let accepted = 0;
              let lastSubmitTime = 0;
              if (hasNewStatus) {
                // 有diff记录的时候用diff里边的时间
                submission = get(diffSolutions, `${pid}.submission`, 0);
                accepted = get(diffSolutions, `${pid}.accepted`, 0);
                lastSubmitTime = Math.floor(get(diffSolutions, `${pid}.last_submit_time`, 0) / 60);
              } else {
                // 否则用account里边的时间
                submission = get(accountInfo, `solutions.${pid}.submission`, 0);
                accepted = get(accountInfo, `solutions.${pid}.accepted`, 0);
                lastSubmitTime = Math.floor(get(accountInfo, `solutions.${pid}.last_submit_time`, 0) / 60);
              }

              const isAccepted = accepted > 0;
              const hasSubmitted = submission > 0;
              // 颜色设置
              const classMapping = {};
              if (hasNewStatus) {
                if (rollingStatus[pid] === 2) {
                  // 有滚动过
                  classMapping.accepted = isAccepted;
                  classMapping.failed = hasSubmitted && !isAccepted;
                } else if (rollingStatus[pid] === 1) {
                  // 滚动中
                  classMapping.pending = true;
                } else {
                  // 没有滚动过
                  classMapping.changed = true;
                }
              } else {
                classMapping.accepted = isAccepted;
                classMapping.failed = hasSubmitted && !isAccepted;
              }
              // 文本设置
              const buildText = () => {
                if (submission > 1) {
                  return `${submission} - ${lastSubmitTime}`;
                } if (submission === 1) {
                  return `${lastSubmitTime}`;
                } 
                return indexToChar(get(problem, 'order', 0));
              };
              return <div key={pid} className={classnames('rank-item-content-problems-tag', classMapping)}>
                {buildText()}
              </div>;
            })}
          </div>
        </div>
        <div className="rank-item-solved">
          <span>{this.state.solved}</span>
        </div>
        <div className="rank-item-times">
          <span>{(this.state.totalUsedTime / 60).toFixed(0)}</span>
        </div>
      </div>
    );
  }
}

export default FancyRankItem;
