import React from 'react';
import {
  Form, Drawer, Input, Button, Switch, Row, Col, Select 
} from 'antd';
import {
  get, isEqual, noop, isEmpty, pick 
} from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ContestAccountActions, ContestAccountSelectors } from 'scripts/common/logic/contest/account';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ContestPropTypes } from 'scripts/common/prop-types/contest';
import { ContestAccountRoleEnum } from 'scripts/common/enums/contest';

const { Option } = Select;

const accountFields = [
  'realname', 'nickname', 'username', 'role', 'password', 'sex',
  'schoolname', 'coachname', 'ignore_rank', 'banned_rank', 'is_enrolled'
];

const mapStateToProps = (state, props) => {
  const { accountId } = props;
  if (!accountId) return {};
  return {
    accounts: ContestAccountSelectors.getContestAccountEntity(state)(accountId),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  editContestAccount: ContestAccountActions.editContestAccount,
  addContestAccount: ContestAccountActions.addContestAccount,
}, dispatch);

@withContestContext
@connect(mapStateToProps, mapDispatchToProps)
class AccountDrawer extends React.PureComponent {
  static propTypes = {
    accountId: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    accounts: PropTypes.shape({}),
    emitRefreshList: PropTypes.func,
    addContestAccount: PropTypes.func,
    editContestAccount: PropTypes.func,
    contest: ContestPropTypes,
  };

  static defaultProps = {
    accountId: null,
    contest: {},
    accounts: {
      realname: '',
      nickname: '',
      username: '',
      role: 0,
      password: '',
      sex: 1,
      schoolname: '',
      coachname: '',
      ignore_rank: false,
      banned_rank: false,
    },
    emitRefreshList: noop,
    addContestAccount: noop,
    editContestAccount: noop,
  };

  state = {
    accounts: {},
    accountRaw: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'accounts'), prevState.accountRaw)) {
      return {
        accounts: get(nextProps, 'accounts'),
        accountRaw: get(nextProps, 'accounts'),
      };
    }
    return null;
  }

  handleInputChange = (keyName, extraFields = false) => ({ target }) => {
    if (extraFields) {
      this.setState({
        accounts: {
          ...this.state.accounts,
          extra_fields: {
            ...this.state.accounts.extra_fields,
            [keyName]: target.value,
          },
        },
      });
    } else {
      this.setState({
        accounts: {
          ...this.state.accounts,
          [keyName]: target.value,
        },
      });
    }
  };

  handleSelectChange = keyName => (value) => {
    this.setState({
      accounts: {
        ...this.state.accounts,
        [keyName]: value,
      },
    });
  };

  handleSubmitClick = () => {
    if (this.props.accountId) {
      this.props.editContestAccount({
        contestId: this.props.contest.id,
        accountId: this.props.accountId,
        ...pick(this.state.accounts, accountFields),
        account_fields: get(this.state.accounts, 'extra_fields', {}),
        onSuccess: () => {
          this.props.emitRefreshList();
          this.props.onClose();
        },
      });
    } else {
      this.props.addContestAccount({
        contestId: this.props.contest.id,
        ...pick(this.state.accounts, accountFields),
        account_fields: get(this.state.accounts, 'extra_fields', {}),
        onSuccess: () => {
          this.props.emitRefreshList();
          this.props.onClose();
        },
      });
    }
  };

  render() {
    const { accounts = {} } = this.state;
    const isAdmin = get(this.props.accounts, 'id') === get(this.props.contest, 'author_id');
    const fields = get(this.props.contest, 'configs.account_fields', null);
    return (
      <Drawer
        title="账户信息"
        width={720}
        visible={this.props.visible}
        onClose={this.props.onClose}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="登录名称">
              <Input
                placeholder={!isEmpty(accounts.wejudge_account) ? '通过WeJudge账户登录' : '登录名称'}
                value={get(accounts, 'username')}
                disabled={!isEmpty(accounts.wejudge_account)}
                onChange={this.handleInputChange('username')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="登录密码">
              <Input.Password
                placeholder="留空则不会修改"
                value={get(accounts, 'password')}
                disabled={!isEmpty(accounts.wejudge_account)}
                onChange={this.handleInputChange('password')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="账号角色">
              <Select
                disabled={isAdmin}
                value={get(accounts, 'role')}
                onChange={this.handleSelectChange('role')}
                style={{ width: '100%' }}
              >
                <Option value={0}>参赛者</Option>
                <Option value={1}>团队</Option>
                <Option value={2}>裁判</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            {get(accounts, 'role') !== ContestAccountRoleEnum.REFEREE.value ? <Form.Item label="性别组成">
              <Select
                value={get(accounts, 'sex')}
                onChange={this.handleSelectChange('sex')}
                style={{ width: '100%' }}
              >
                <Option value={1}>男队（混队）</Option>
                <Option value={0}>女队</Option>
              </Select>
            </Form.Item> : <Form.Item label="裁判昵称">
              <Input
                placeholder="裁判昵称"
                value={get(accounts, 'nickname')}
                onChange={this.handleInputChange('nickname')}
              />
            </Form.Item>}
          </Col>
        </Row>
        {get(accounts, 'role') !== ContestAccountRoleEnum.REFEREE.value && <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="队伍名称">
              <Input
                placeholder="队伍名称"
                value={get(accounts, 'nickname')}
                onChange={this.handleInputChange('nickname')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="真实姓名">
              <Input
                placeholder="真实姓名"
                value={get(accounts, 'realname')}
                onChange={this.handleInputChange('realname')}
              />
            </Form.Item>
          </Col>
        </Row>}
        {accounts.role !== ContestAccountRoleEnum.REFEREE.value && <>
          <Row gutter={16}>
            {Object.keys(fields).map((item) => {
              const placename = get(fields[item], 'placename', '');
              const placeholder = get(fields[item], 'placeholder', '');
              if (item === '教练名') {
                return (<Col key={item} span={12}>
                  <Form.Item label={placename || item}>
                    <Input
                      placeholder={placeholder || '请输入教练姓名'}
                      value={get(accounts, 'coachname')}
                      onChange={this.handleInputChange('coachname')}
                    />
                  </Form.Item>
                </Col>);
              } if (item === '学校名') {
                return (<Col key={item} span={12}>
                  <Form.Item label={placename || item}>
                    <Input
                      placeholder={placeholder || '请输入学校名称'}
                      value={get(accounts, 'schoolname')}
                      onChange={this.handleInputChange('schoolname')}
                    />
                  </Form.Item>
                </Col>);
              } 
              return (<Col key={item} span={12}>
                <Form.Item label={placename || item}>
                  <Input
                    placeholder={placeholder || `请输入${item}`}
                    value={get(accounts, `extra_fields.${item}`, '')}
                    onChange={this.handleInputChange(item, true)}
                  />
                </Form.Item>
              </Col>);
            })}
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="不参与最终排行">
                <Switch
                  onChange={this.handleSelectChange('ignore_rank')}
                  checked={get(accounts, 'ignore_rank')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="从排行榜除名">
                <Switch
                  onChange={this.handleSelectChange('banned_rank')}
                  checked={get(accounts, 'banned_rank')}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="已报名">
                <Switch
                  onChange={this.handleSelectChange('is_enrolled')}
                  checked={get(accounts, 'is_enrolled')}
                />
              </Form.Item>
            </Col>
          </Row>
        </>}
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Button onClick={this.handleSubmitClick} type="primary">
            保存
          </Button>
        </div>
      </Drawer>
    );
  }
}

export default AccountDrawer;
