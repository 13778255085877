import React, {  PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Input, Tag, Tooltip, Switch, Button, Alert, Divider, Modal 
} from 'antd';
import { SolutionsTagsActions } from 'scripts/common/logic/collection';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {  noop, size  } from 'lodash';

@connect((state, props) => {
  const { entities } = state;
  const { tags } = entities;
  return {
    tags,
  };
}, dispatch => bindActionCreators({
  onSubmitTags: SolutionsTagsActions.addSolutionsTags,
  fetchTagsList: SolutionsTagsActions.getSolutionsTagsList,
  editTags: SolutionsTagsActions.editSolutionsTags,
  delTags: SolutionsTagsActions.delSolutionsTags,
}, dispatch))
class TabsSelector extends PureComponent {
  static propTypes = {
    tags: PropTypes.shape({}),
    onSubmitTags: PropTypes.func,
    editTags: PropTypes.func,
    fetchTagsList: PropTypes.func,
    delTags: PropTypes.func,
  };

  static defaultProps = {
    tags: {},
    onSubmitTags: noop,
    fetchTagsList: noop,
    delTags: noop,
    editTags: noop,
  };

  state = {
    tags: [],
    editId: '',
    inputVisible: false,
    editVisible: false,
    inputValue: '',
    switchType: true,
    editValue: '',
    //editTagName: '',
  };

  componentWillMount() {
    this.props.fetchTagsList();
    this.setState({
      tags: Object.values(this.props.tags),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      tags: Object.values(nextProps.tags),
    });
  }
 
  saveInputRef = span => (this.span = span);

  saveEditRef = span => (this.span = span);

  showEditor = (tag) => {
    this.setState({
      editId: tag.id,
      editVisible: true,
      editValue: tag.name,
      switchType: tag.language,
    });
  }

  confirmDel(tagName, tagId, e) {
    e.stopPropagation();
    Modal.confirm({
      title: 'WEJUDGE',
      content: '确认删除标签吗，删除之后所有包含该标签的题解的此标签将会消失，操作不可逆！！',
      okText: '确认',
      cancelText: '取消',
      onOk: () => this.handleClose(tagName, tagId),
    });
  }

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.span.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleEditChange = (e) => {
    this.setState({ editValue: e.target.value });
    this.props.fetchTagsList();
  };

  handleSwitchChange = (checked) => {
    this.setState({ switchType: checked });
  }

  handleClose = (removedTag, id) => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    this.props.delTags({
      tagId: id,
    });
    this.setState({ tags });
    this.props.fetchTagsList();
  };

  handleCancel = () => {
    this.setState({
      inputVisible: false,
    });
  };

  handleCancelEdit = () => {
    this.setState({
      editVisible: false,
    });
  };

  handleEditConfirm = () => {
    const { editValue } = this.state;
    const { switchType } = this.state;
    const { editId } = this.state;
    this.props.editTags({
      tagId: editId,
      tagName: editValue,
      tagLanguage: switchType,
    });
    this.props.fetchTagsList();
    this.setState({
      editVisible: false,
      editValue: '',
    });
  }

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    const { switchType } = this.state;
    let { tags } = this.state;
    tags = [...tags, { name: inputValue, language: switchType }];
    this.props.onSubmitTags({
      tagName: inputValue,
      tagLanguage: switchType,
    });
    this.props.fetchTagsList();
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  };

  render() {
    const {
      tags, inputVisible, inputValue, editVisible, editValue, 
    } = this.state;
    return (
      <>
        <Alert
          message="提示"
          description="删除后需要重新加载页面哦，左键双击标签即可修改标签哦！！！！"
          type="error"
        />
        <div style={{
          marginLeft: 10,
        }}
        ><div className="solutions-tags-edit-container">
          <Divider orientation="left">语言类</Divider>
          <div>
            {tags.filter((item) => { return item.language; }).map((tag, index) => {
              const isLongTag = tag.name.length > 20;
              const tagElem = (
                <Tag
                  className="solutions-edit-tags-language"
                  color="green"
                  key={tag.name}
                  onClick={() =>  this.showEditor(tag)}
                >
                  {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
                  <CloseOutlined onClick={e => this.confirmDel(tag.name, tag.id, e)} /> 
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag.name} key={tag.name}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          </div>
          {editVisible && this.state.switchType && (
          <div>
            <Input
              className="solutions-tags-edit-input"
              ref={this.saveEditRef}
              type="text"
              value={editValue}
              style={{ width: '10vw' }}
              onChange={this.handleEditChange}
              onPressEnter={this.handleInputConfirm}
            />
            <span>
              <Switch
                className="solutions-tags-edit-switch"
                checked={this.state.switchType}
                checkedChildren="语言类"
                unCheckedChildren="方法类" 
                onChange={this.handleSwitchChange} 
                defaultChecked
              />
            </span>
            <Button className="solutions-tags-onsubmit" onClick={this.handleEditConfirm}>确认修改</Button>
            <Button className="solutions-tags-oncancel" onClick={this.handleCancelEdit}>取消</Button>
          </div>
          )}
          <Divider orientation="left" plain>方法类</Divider>
          <div>
            {tags.filter((item) => { return !item.language; }).map((tag, index) => {
              const isLongTag = tag.name.length > 20;
              const tagElem = (
                <Tag
                  className="solutions-edit-tags-method"
                  color="blue"
                  key={tag.name}
              //closable={index !== -1} 
                  onClick={() =>  this.showEditor(tag)}
                >
                  {isLongTag ? `${tag.name.slice(0, 20)}...` : tag.name}
                  <CloseOutlined onClick={e => this.confirmDel(tag.name, tag.id, e)} /> 
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag.name} key={tag.name}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          </div>
          {editVisible && !this.state.switchType && (
          <div>
            <Input
              className="solutions-tags-edit-input"
              ref={this.saveEditRef}
              type="text"
              style={{ width: '10vw' }}
              value={editValue}
              onChange={this.handleEditChange}
              onPressEnter={this.handleInputConfirm}
            />
            <span>
              <Switch
                className="solutions-tags-edit-switch"
                checked={this.state.switchType}
                checkedChildren="语言类"
                unCheckedChildren="方法类" 
                onChange={this.handleSwitchChange} 
                defaultChecked
              />
            </span>
            <Button className="solutions-tags-onsubmit" onClick={this.handleEditConfirm}>确认修改</Button>
            <Button className="solutions-tags-oncancel" onClick={this.handleCancelEdit}>取消</Button>
          </div>
          )}
          {!inputVisible && (
            <div>
              <Divider />
              <Tag
                onClick={this.showInput}
                style={{
                  background: '#fff', borderStyle: 'dashed', width: '6vw',
                }}
              >
                <PlusOutlined /> 
                添加标签
              </Tag>
            </div>
          )}
          {inputVisible && (
          <div>
            <Input
              className="solutions-tags-edit-input"
              ref={this.saveInputRef}
              type="text"
              style={{ width: '10vw' }}
              value={inputValue}
              onChange={this.handleInputChange}
              onPressEnter={this.handleInputConfirm}
            />
            <span>
              <Switch
                className="solutions-tags-edit-switch"
                checked={this.state.switchType}
                checkedChildren="语言类"
                unCheckedChildren="方法类" 
                onChange={this.handleSwitchChange} 
                defaultChecked
              />
            </span>
            <Button className="solutions-tags-onsubmit" onClick={this.handleInputConfirm}>确认添加</Button>
            <Button className="solutions-tags-oncancel" onClick={this.handleCancel}>取消</Button>
          </div>
          )}
        </div>
        </div>
      </>
    );
  }
}
export default TabsSelector;
