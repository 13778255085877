import 'styles/widgets/demo/list_ad.scss';

import React from 'react';

const ListComponent = () => {
  return <div className="list_cp">
    <div>实验室</div>
  </div>;
};

export default ListComponent;
