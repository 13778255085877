import React, { useState, useEffect } from 'react';
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import { Collapse, Button } from 'antd';
import ArrangementStudentsList from 'scripts/apps/education/course/widgets/arrangement_students';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import ArrangementEditorView from 'scripts/apps/education/course/widgets/arrangement_editor';
import moment from 'moment';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { WeJudgeActions } from 'scripts/common/logic/wejudge';
import { CourseActions } from 'scripts/common/logic/education/course';
import { importStudentExcel } from 'scripts/common/utils/xlsx_importer';

const { Panel } = Collapse;
const mapDispatchToProps = dispatch => bindActionCreators({
  viewWarnMessage: WeJudgeActions.viewWarnMessage,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
  viewErrorMessage: WeJudgeActions.viewErrorMessage,
  getArrangementsList: CourseActions.getArrangementsList,
  addArrangementStudents: CourseActions.addArrangementStudents,
}, dispatch);

@connect(null, mapDispatchToProps)
class ArrangementCollapse extends React.PureComponent {
  static propTypes = {
    handleDelArrangement: PropTypes.func.isRequired,
    handleEditArrangement: PropTypes.func.isRequired,
    schoolId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    getArrangement: PropTypes.func.isRequired,
    arrangements: PropTypes.shape([]).isRequired,
    handleSubmitClick: PropTypes.func.isRequired,
    addArrangementStudents: PropTypes.func,
    viewWarnMessage: PropTypes.func,
    viewErrorMessage: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
  };

  static defaultProps = {
    viewSuccessMessage: noop,
    viewErrorMessage: noop,
    viewWarnMessage: noop,
    addArrangementStudents: noop,

  };

  state = {
    editArr: [],
    hasChange: false,
  };

  changeMode = (key) => {
    const { editArr } = this.state;
    editArr.includes(key) ? editArr.splice(editArr.indexOf(key), 1) : editArr.push(key);
    this.setState({
      editArr,
      hasChange: !this.state.hasChange,
    });
  };

  genExtra = ({ id }) => {
    return (
      <>
        {this.state.editArr.includes(id) ? <span onClick={(event) => { event.stopPropagation(); }}>
          <Button
            size="small"
            onClick={() => this.changeMode(id)}
          >
            返回
          </Button>
        </span>
          : <>
            <span onClick={(event) => {
              event.stopPropagation();
              this.changeMode(id);
            }}
            >
              <SettingOutlined />
              <span style={{ margin: '0 4px'  }}>设置</span>
            </span>
            <span onClick={(event) => {
              event.stopPropagation();
              this.props.handleDelArrangement(id);
            }}
            >
              <DeleteOutlined />
              <span style={{ margin: '0 4px'  }}>删除</span>
            </span>
          </>}
      </>
    );
  };

  renderHeader = (item) => {
    const oddEven = {
      0: '单双',
      1: '单',
      2: '双',
    };
    const odd = oddEven[item.odd_even];
    const weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
    return <>
      <span>{item.name} </span>
      <span>({item.start_week}-{item.end_week}{odd}周 {weekdays[item.day_of_week]} {item.start_section}-{item.end_section}节 {moment(get(item, 'start_time', 0) * 1000).utc().format('hh:mm:ss')}-{moment(get(item, 'end_time', 0) * 1000).utc().format('hh:mm:ss')})</span>
    </>;
  };

  render() {
    const activeKey = this.props.arrangements.map(item => item.id);
    return (
      <Collapse
        defaultActiveKey={[...activeKey]}
      >
        {this.props.arrangements.map((item) => {
          return (
            <Panel key={item.id} header={this.renderHeader(item)} extra={this.genExtra(item)}>
              {this.state.editArr.includes(item.id) ? <ArrangementEditorView
                arrangement={item}
                courseId={this.props.courseId}
                mode="edit"
                handleSubmitClick={this.props.handleEditArrangement}
              /> : <ArrangementStudentsList
                schoolId={this.props.schoolId}
                courseId={this.props.courseId}
                arrangement={item}
              />}
            </Panel>
          );
        })}
        <Panel key="new" header="新建">
          <ArrangementEditorView
            arrangement={this.state.arrangement}
            courseId={this.props.courseId}
            mode={this.state.aieMode}
            handleSubmitClick={this.props.handleSubmitClick}
          />
        </Panel>
      </Collapse>
    );
  }
}

export default ArrangementCollapse;
