import React from 'react';
import { Menu } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import {
  withRouter, Route, Switch, matchPath, Redirect 
} from 'react-router-dom';
import { connect } from 'react-redux';
import { AsgnRoutes } from 'scripts/apps/routes';
import { buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
// import AsgnMossCrossCheckView from './moss';
import AsgnSimpleCrossCheckView from './simple';

const mapStateToProps = (state, props) => {
  const { location } = props;
  const matchItem = matchPath(
    location.pathname,
    { path: `${AsgnRoutes.CROSS_CHECK}/:subItemName`, exact: true }
  );
  const ItemKey = get(matchItem, 'params.subItemName', '');
  const subItemKey = (ItemKey === '' ? 'simple' : ItemKey);
  return {
    subItemKey,
  };
};

@withRouter
@connect(mapStateToProps, null)
class AsgnCrossCheckView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    subItemKey: PropTypes.string,
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    subItemKey: 'simple',
  };

  state = {};

  handleSubChange = ({ key }) => {
    const { history, match } = this.props;
    history.push(buildPath(
      `${AsgnRoutes.CROSS_CHECK}/:subItemName`,
      {
        subItemName: key,
      },
      match.params
    ));
  };

  render() {
    return (<div className="asgn_cross_check_view">
      <Menu onSelect={this.handleSubChange} selectedKeys={[this.props.subItemKey]} mode="horizontal">
        <Menu.Item key="simple">
          常规查重
        </Menu.Item>
        {/*<Menu.Item key="moss" disabled>*/}
        {/*  Moss查重 (未来开放)*/}
        {/*</Menu.Item>*/}
      </Menu>
      <Switch>
        <Route
          path={AsgnRoutes.CROSS_CHECK_SIMPLE}
          render={props => <AsgnSimpleCrossCheckView
            {...props}
            asgnId={this.props.asgnId}
            courseId={this.props.courseId}
          />}
          exact
        />
        {/*<Route*/}
        {/*  path={AsgnRoutes.CROSS_CHECK_MOSS}*/}
        {/*  render={props =>*/}
        {/*    <AsgnMossCrossCheckView*/}
        {/*      {...props}*/}
        {/*      asgnId={this.props.asgnId}*/}
        {/*      courseId={this.props.courseId}*/}
        {/*    />*/}
        {/*  }*/}
        {/*  exact*/}
        {/*/>*/}
        <Route
          render={() => <Redirect
            to={buildPath(AsgnRoutes.CROSS_CHECK_SIMPLE, null, this.props.match.params)}
          />}
        />
      </Switch>
    </div>);
  }
}

export default AsgnCrossCheckView;
