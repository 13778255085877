import { get } from 'lodash';
import Logger from 'scripts/common/utils/exceptions/logger';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ContestRoutes } from 'scripts/apps/routes';

const logger = Logger('ContestNotification');

const ContestPersonalMessager = (sender, messageType, payload) => {
  switch (messageType) {
    case 'hello':
      logger.log('info', payload);
      break;
    case 'faq.reply':
      window.wejudge.notification.show({
        title: `【新消息】${get(sender.props.contest, 'title', '')}`,
        body: '你的提问有一个新的回复，点击此消息查看',
        onClick: () => {
          sender.props.history.push(buildPath(ContestRoutes.SUPPORT.FAQ_VIEW, {
            contestId: sender.props.contest.id,
            faqId: get(payload, 'faq_id', 0),
          }, {}, {}, get(payload, 'reply_id', 0)));
        },
      });
      break;
    case 'faq.new':
      window.wejudge.notification.show({
        title: `【新消息】${get(sender.props.contest, 'title', '')}`,
        body: '参赛选手有新的问题需要你来解答，点击此消息查看',
        onClick: () => {
          sender.props.history.push(buildPath(ContestRoutes.SUPPORT.FAQ_VIEW, {
            contestId: sender.props.contest.id,
            faqId: get(payload, 'faq_id', 0),
          }));
        },
      });
      break;
    default:
      break;
  }
};

export const ContestWebSocketMessageRouter = (sender, message) => {
  const type = get(message, 'type', '');
  const messageType = get(message, 'message_type', '');
  const payload = get(message, 'payload', null);
  switch (type) {
    case 'contest.judge.notification':      // 评测消息通知
      break;
    case 'contest.system.notification':     // 系统广播消息
      window.wejudge.notification.show({
        title: `【系统公告】${get(sender.props.contest, 'title', '')}`,
        body: '你有一个新的公告可以查看啦',
        onClick: () => {
          sender.showNoticeModel();
        },
      });
      break;
    case 'contest.personal.notification':   // 指向性消息
      ContestPersonalMessager(sender, messageType, payload);
      break;
    default:

      break;
  }
};
