import React from 'react';
import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import { get, noop, truncate } from 'lodash';
import { JudgeStatusPropTypes } from 'scripts/common/prop-types/judge_status';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import { JudgeLanguageEnum, JudgeFlagEnum } from 'scripts/common/enums/judge';
import { withRouter } from 'react-router';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AccountRoutes } from 'scripts/apps/routes';
import ReactResizeDetector from 'react-resize-detector';
import { AntTableMiddleOutsideHeight } from 'scripts/common/constants/table';

@withRouter
class JudgeStatusListTable extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    judgeStatusDatas: PropTypes.arrayOf(JudgeStatusPropTypes),
    onProblemClick: PropTypes.func,
    onJudgeStatusClick: PropTypes.func,
    onPageChange: PropTypes.func,
    paginationParams: PaginationPropTypes,
    loading: PropTypes.bool,
    bordered: PropTypes.bool,
    columnStyle: PropTypes.string,
    renderProblemLabel: PropTypes.func,
    renderAuthorLabel: PropTypes.func,
  };

  static defaultProps = {
    judgeStatusDatas: [],
    onProblemClick: noop,
    onPageChange: noop,
    onJudgeStatusClick: noop,
    renderProblemLabel: noop,
    renderAuthorLabel: noop,
    paginationParams: {},
    loading: false,
    bordered: false,
    columnStyle: 'normal',
  };

  state = {
    tableHeight: 0,
  };

  NORMAL_COLUMNS = [{
    title: 'ID',
    width: 80,
    dataIndex: 'id',
    align: 'center',
  }, {
    title: '题目',
    dataIndex: 'problem',
    align: 'center',
  }, {
    title: '评测状态',
    width: 140,
    dataIndex: 'flag',
    align: 'center',
  }, {
    title: '提交者',
    dataIndex: 'author',
    align: 'center',
  }, {
    title: '最大运行时长',
    width: 120,
    dataIndex: 'exe_time',
    align: 'center',
  }, {
    title: '最高内存占用',
    width: 120,
    dataIndex: 'exe_mem',
    align: 'center',
  }, {
    title: '代码长度',
    width: 100,
    dataIndex: 'code_length',
    align: 'center',
  }, {
    title: '代码语言',
    width: 100,
    dataIndex: 'code_lang',
    align: 'center',
  }, {
    title: '提交时间',
    width: 100,
    dataIndex: 'update_time',
    align: 'center',
  }];

  MINIMAL_COLUMNS = [
    // {
    //   title: '提交者',
    //   width: 140,
    //   dataIndex: 'author',
    //   align: 'center',
    // },
    {
      title: '提交时间',
      dataIndex: 'update_time',
      align: 'center',
    }, {
      title: '评测状态',
      dataIndex: 'flag',
      align: 'center',
    }, {
      title: '代码语言',
      dataIndex: 'code_lang',
      align: 'center',
    }, {
      title: '最大运行时长',
      dataIndex: 'exe_time',
      align: 'center',
    }, {
      title: '最高内存占用',
      dataIndex: 'exe_mem',
      align: 'center',
    }];

  canViewMeta = (item) => {
    const flag = get(item, 'flag');
    return flag === JudgeFlagEnum.AC.value || flag === JudgeFlagEnum.PE.value
      || flag === JudgeFlagEnum.WA.value || flag === JudgeFlagEnum.OLE.value;
  };

  handleShowUserSpace = item => () => {
    const { history } = this.props;
    history.push(buildPath(AccountRoutes.SPACE.CODE_SOL, {
      accountId: get(item, 'author.id'),
    }));
  };

  handleContainerHeightChange = (width, height) => {
    this.setState({
      tableHeight: height,
    });
  };

  renderJudgeFlag = (flag) => {
    const flagEnum = JudgeFlagEnum.enumValueOf(flag);
    return <Tag color={get(flagEnum, 'color')}>{get(flagEnum, 'desc', '未知')}</Tag>;
  };

  renderProblemLabel = (item) => {
    return `[${get(item, 'problem.number')}] ${truncate(get(item, 'problem.title', ''), 10)}`;
  };

  renderAuthorLabel = (item) => {
    return <a onClick={this.handleShowUserSpace(item)}> {get(item, 'author.nickname', '')} </a>;
  };

  renderExeTime = (item) => {
    if (this.canViewMeta(item)) {
      const exetime = get(item, 'exe_time', 0);
      return `${exetime} MS`;
    }
    return '---';
  };

  renderExeMem = (item) => {
    if (this.canViewMeta(item)) {
      const exemem = get(item, 'exe_mem', 0);
      return `${exemem} KB`;
    }
    return '---';
  };

  renderListData = () => {
    return this.props.judgeStatusDatas.map((item) => {
      if (!item) return {};
      return {
        key: get(item, 'id', ''),
        id: get(item, 'id', ''),
        problem: <a onClick={() => this.props.onProblemClick(item)}>
          {this.props.renderProblemLabel(item) || this.renderProblemLabel(item)}
        </a>,
        author: this.props.renderAuthorLabel(item) || this.renderAuthorLabel(item),
        flag: <a onClick={this.props.onJudgeStatusClick(item)}>{this.renderJudgeFlag(get(item, 'flag'))}</a>,
        exe_time: this.renderExeTime(item),
        exe_mem: this.renderExeMem(item),
        code_length: `${get(item, 'code_length', 0)}B`,
        code_lang: get(JudgeLanguageEnum.enumValueOf(get(item, 'code_lang', -100)), 'desc', '未知'),
        update_time: formatTimeFromNow(get(item, 'create_time', 0)),
      };
    });
  };

  render() {
    const styleMapping = {
      minimal: this.MINIMAL_COLUMNS,
      asgn_minimal: this.ASGN_MINIMAL_COLUMNS,
    };
    const datas = this.renderListData();
    const optPagination = {
      current: get(this.props.paginationParams, 'page', 1),
      pageSize: get(this.props.paginationParams, 'limit', 40),
      showQuickJumper: true,
      showSizeChanger: true,
      pageSizeOptions: ['20', '40', '60'],
      onChange: this.props.onPageChange,
      total: get(this.props.paginationParams, 'total', 0),
    };
    return <div className="judge_status_list_body">
      <ReactResizeDetector handleHeight onResize={this.handleContainerHeightChange} />
      <Table
        loading={this.props.loading}
        className="judge_status_list_table"
        size="middle"
        dataSource={this.props.loading ? [] : datas}
        pagination={optPagination}
        columns={get(styleMapping, this.props.columnStyle, this.NORMAL_COLUMNS)}
        scroll={{
          y: this.state.tableHeight - AntTableMiddleOutsideHeight,
          scrollToFirstRowOnChange: true,
        }}
        bordered={this.props.bordered}
      />
    </div>;
  }
}

export default JudgeStatusListTable;
