import React from 'react';
import PropTypes from 'prop-types';

import 'styles/education/course/arrangement.scss';
import { Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { get, isEqual, noop } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CourseSelectors } from 'scripts/common/logic/education/course/selector';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { ArrangementPropTypes } from 'scripts/common/prop-types/course';
import { showModal } from 'scripts/common/widgets/modal';

import DelArrangementModal from 'scripts/apps/education/course/widgets/del_arrangement';
import ArrangementCollapse from 'scripts/apps/education/course/widgets/arrangement_collapse';

const LOADING_STATUS_KEY = 'course_arrangement_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  getArrangementsList: CourseActions.getArrangementsList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  addArrangements: CourseActions.addArrangements,
  editArrangements: CourseActions.editArrangements,
  delArrangements: CourseActions.delArrangements,
  viewSuccessMessage: WeJudgeActions.viewSuccessMessage,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { match } = props;
  const courseId = parseInt(get(match, 'params.courseId', 0), 10);
  const arrangements = CourseSelectors.getArrangementList(state);
  const loading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  return {
    loading,
    courseId,
    arrangements,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CourseArrangementView extends React.PureComponent {
  static fetchList = (nextProps) => {
    nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
    nextProps.getArrangementsList({
      courseId: nextProps.courseId,
      onComplete: () => {
        nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
      },
    });
  };

  static propTypes = {
    loading: PropTypes.bool,
    schoolId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    getArrangementsList: PropTypes.func,
    arrangements: PropTypes.arrayOf(ArrangementPropTypes),
    addArrangements: PropTypes.func,
    editArrangements: PropTypes.func,
    delArrangements: PropTypes.func,
    viewSuccessMessage: PropTypes.func,
  };

  static defaultProps = {
    loading: false,
    arrangements: [],
    getArrangementsList: noop,
    addArrangements: noop,
    editArrangements: noop,
    delArrangements: noop,
    viewSuccessMessage: noop,
  };

  state = {};

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(get(nextProps, 'courseId'), get(prevState, 'courseId'))) {
      const { courseId } = nextProps;
      CourseArrangementView.fetchList(nextProps);
      return {
        courseId,
      };
    }
    return null;
  }

  getArrangement = (id) => {
    const { arrangements } = this.props;
    for (let i = 0; i < arrangements.length; i++) {
      const item = arrangements[i];
      if (item.id.toString() === id.toString()) {
        return item;
      }
    }
    return null;
  };

  handleSubmitClick = (payload) => {
    this.props.addArrangements({
      ...payload,
      courseId: this.props.courseId,
      onSuccess: () => {
        this.props.viewSuccessMessage({
          message: '新增排课成功',
        });
      },
      onComplete: () => {
        this.props.getArrangementsList({ courseId: this.props.courseId });
      },
    });
  };

  handleEditArrangement = (payload) => {
    this.props.editArrangements({
      ...payload,
      arrangementId: payload.id,
      courseId: this.props.courseId,
      onSuccess: () => {
        this.props.viewSuccessMessage({
          message: '修改排课成功',
        });
      },
      onComplete: () => {
        this.props.getArrangementsList({ courseId: this.props.courseId });
      },
    });
  };

  handleDelArrangement = (arrangementId) => {
    const modalBox = showModal(DelArrangementModal, {
      width: 400,
      arrangement: this.getArrangement(arrangementId),
      onOk: () => {
        this.props.delArrangements({
          courseId: this.props.courseId,
          arrangementId,
          onSuccess: () => {
            this.props.viewSuccessMessage({
              message: '删除排课成功',
            });
          },
          onComplete: () => {
            modalBox.close();
            this.props.getArrangementsList({ courseId: this.props.courseId });
          },
        });
        return false;
      },
    });
  };

  render() {
    return (
      <div className="course_arrangement_view">
        <Spin
          spinning={this.props.loading}
          tip="排课数据加载中..."
        >
          {!this.props.loading && <ArrangementCollapse
            courseId={this.props.courseId}
            schoolId={this.props.schoolId}
            getArrangement={this.getArrangement}
            handleDelArrangement={this.handleDelArrangement}
            handleEditArrangement={this.handleEditArrangement}
            arrangements={this.props.arrangements}
            handleSubmitClick={this.handleSubmitClick}
          />}
        </Spin>
      </div>
    );
  }
}

export default CourseArrangementView;
