import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Menu, Spin } from 'antd';
import { bindActionCreators } from 'redux';
import { matchPath, withRouter } from 'react-router-dom';
import localStorage from 'localStorage';
import { connect } from 'react-redux';
import jQuery from 'jquery';
import {
  get, isEqual, pick, noop, isEmpty 
} from 'lodash';
import { AccountSelectors } from 'scripts/common/logic/account';
import { ProblemPermissionEnum, ProblemTypeEnum } from 'scripts/common/enums/problem';
import ProblemsListControlBar from 'scripts/apps/collection/widgets/problems_list_bar';
import DataKeys from 'scripts/common/constants/data_keys';
import {
  CollectionProblemsSelectors,
  CollectionProblemActions, CollectionActions
} from 'scripts/common/logic/collection';
import { ProblemTagsActions } from 'scripts/common/logic/problem/actions';
import { CollectionViewsRoutes, CollectionProblemViewRoutes } from 'scripts/apps/routes';
import { getQuery, buildPath } from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { PaginationParams40 } from 'scripts/common/constants/global';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { PaginationPropTypes } from 'scripts/common/prop-types/pagination';
import DelProblemIcon from 'scripts/apps/problem/widgets/dialog/del_problem_icon';
import { store as APPStore } from 'scripts/common/logic/store';
import { showModal } from 'scripts/common/widgets/modal';

import { AccountPropTypes } from 'scripts/common/prop-types/account';
import ProblemsListTable from '../widgets/problems_list_table';
import NormalProblemView from './normal_problem_view';
import ModifyProblemIcon from '../../problem/widgets/modify_problem';

import ProblemTagFilterDialog from '../widgets/problem_tags_dialog';

const LOADING_STATUS_KEY = 'collection_problems_list';

const mapDispatchToProps = dispatch => bindActionCreators({
  getTagsList: CollectionActions.getTagsList,
  searchTags: ProblemTagsActions.searchTags,
  getCollectionProblemsList: CollectionProblemActions.getCollectionProblemsList,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  modifyProblems: CollectionProblemActions.saveModifyProblems,
}, dispatch);

const mapStateToProps = (state, props) => {
  const queryset = getQuery(props.location);
  const { match, location } = props;
  const collectionId = get(match, 'params.collectionId', '');
  const pager = CollectionProblemsSelectors.collectionProblemsListViewPagerSelector(state);
  const problemDatas = CollectionProblemsSelectors.getCollectionProblemsListData(state);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const matchProblemParams = matchPath(location.pathname, {
    path: CollectionViewsRoutes.PROBLEMS_LIST_WITH_PROBLEM,
    exact: true,
  });
  const canCreateProblem = get(
    AccountSelectors.getAccountBasePermission(state),
    `problem.${ProblemPermissionEnum.CREATE.value}`,
    false
  );
  const problemId = get(matchProblemParams, 'params.problemId', '');
  const currentRoutePath = matchProblemParams
    ? CollectionViewsRoutes.PROBLEMS_LIST_WITH_PROBLEM : CollectionViewsRoutes.PROBLEMS_LIST;
  const queryParams = {
    ...PaginationParams40,
    ...pick(queryset, ['limit', 'page', 'keyword', 'classify_id', 'type', 'tags']),
    collectionId,
  };
  const tagKeys = get(queryset, 'tags', '').split(',').filter((i, j) => !!i || j > 9);
  return {
    tagKeys,
    problemId,
    isLoading,
    queryParams,
    collectionId,
    problemDatas,
    currentRoutePath,
    canCreateProblem,
    currentAccount: AccountSelectors.getLoginAccount(state),
    paginationParams: pager || props.paginationParams,
  };
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class ProblemsListContent extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    isLoading: PropTypes.bool,
    canCreateProblem: PropTypes.bool,
    collectionId: PropTypes.string.isRequired,
    canManageCollection: PropTypes.bool.isRequired,
    problemId: PropTypes.string,
    currentRoutePath: PropTypes.string,
    setLoadingStatus: PropTypes.func,
    searchTags: PropTypes.func,
    getTagsList: PropTypes.func,
    paginationParams: PaginationPropTypes,
    currentAccount: AccountPropTypes,
    tagKeys: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    problemId: '',
    canCreateProblem: false,
    isLoading: true,
    currentRoutePath: '',
    setLoadingStatus: noop,
    searchTags: noop,
    getTagsList: noop,
    paginationParams: {},
    currentAccount: {},
    tagKeys: [],
  };

  state = {
    selectedProblemIds: [],
    problemChoosingOptions: {},
    queryParams: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // 如果因为路由的Query发生改变，则发起列表请求
    if (!isEqual(get(nextProps, 'queryParams'), get(prevState, 'queryParams'))) {
      const { queryParams } = nextProps;
      // 持久化query参数，提供problem页面的返回按键用
      localStorage.setItem(
        DataKeys.LOCAL_STORAGE.COLLECTION_PROBLEMS_LIST_QUERYS,
        JSON.stringify(queryParams)
      );
      const type = get(ProblemTypeEnum.enumValueOf(get(queryParams, 'type', -1)), 'value', null);
      let classifyId = get(queryParams, 'classify_id', 'root');
      if (!classifyId || classifyId === 'root') classifyId = null;
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getCollectionProblemsList({
        collectionId: nextProps.collectionId,
        query: {
          ...pick(queryParams, ['limit', 'keyword', 'page', 'tags']),
          classify_id: classifyId,
          type,
        },
        onComplete: () => {
          nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
        },
      });
      return {
        queryParams,
      };
    }
    return null;
  }

  componentDidMount() {
    this.loadFromLocalStorage();
    jQuery(window).on('ChooseProblem', this.loadFromLocalStorage);
  }

  componentWillUnmount() {
    jQuery(window).off('ChooseProblem', this.loadFromLocalStorage);
  }

  loadFromLocalStorage = () => {
    let options = localStorage.getItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING);
    try {
      options =        JSON.parse(options);
    } catch (e) {
      options = {};
    }
    this.setState({ problemChoosingOptions: options });
  };

  doProblemChooseAnimate = () => {
    const $ = window.jQuery;
    const srcPos = $('#do-problem-choosing').offset();
    const decPos = $('#problem-choosing-btn').offset();
    const flyBox = $('#problem-chossing-fly-icon').clone().css('display', 'block');

    flyBox.fly({
      start: {
        left: srcPos.left,
        top: srcPos.top,
      },
      end: {
        left: decPos.left + 10,
        top: decPos.top + 10,
        width: 0,
        height: 0,
      },
    });
  };

  handleCreateProblemSuccess = (cpid, problemType) => {
    if (problemType === ProblemTypeEnum.CODE || problemType === ProblemTypeEnum.CODE_FILL) {
      const { history, collectionId } = this.props;
      // 跳转到题目编辑页面
      history.push(buildPath(CollectionProblemViewRoutes.MANAGEMENT_EDIT, {
        collectionId,
        problemId: cpid,
      }));
    } else {
      // 触发列表刷新
      this.setState({
        queryParams: {},
      });
    }
  };

  handleProblemsTypeFilterChange = ({ key }) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      { problemId: this.props.problemId },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'keyword', 'classify_id', 'tags']),
        type: key,
      }
    ));
  };

  handleSearch = (value) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      { problemId: this.props.problemId },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'classify_id', 'type', 'tags']),
        keyword: value,
      }
    ));
  };

  handleProblemClick = (item) => {
    const { problem } = item;
    const { history, match, collectionId } = this.props;
    const problemType = ProblemTypeEnum.enumValueOf(problem.problem_type);
    if (problemType === ProblemTypeEnum.CODE || problemType === ProblemTypeEnum.CODE_FILL) {
      history.push(buildPath(CollectionProblemViewRoutes.CONTENT, {
        collectionId,
        problemId: item.id,
      }));
    } else {
      history.push(buildPath(
        CollectionViewsRoutes.PROBLEMS_LIST_WITH_PROBLEM,
        {
          problemId: item.id,
        },
        match.params,
        {
          ...pick(this.state.queryParams, ['page', 'limit', 'keyword', 'classify_id', 'type', 'tags']),
        }
      ));
    }
  };

  handleCloseProblemClick = () => {
    const { history, match } = this.props;
    history.push(buildPath(
      CollectionViewsRoutes.PROBLEMS_LIST,
      { problemId: this.props.problemId },
      match.params,
      {
        ...pick(this.state.queryParams, ['page', 'limit', 'keyword', 'classify_id', 'type', 'tags']),
      }
    ));
  };

  handlePageChange = (page, pageSize) => {
    const { history, match } = this.props;
    history.push(buildPath(
      this.props.currentRoutePath,
      { problemId: this.props.problemId },
      match.params,
      {
        ...pick(this.state.queryParams, ['limit', 'keyword', 'classify_id', 'type', 'tags']),
        limit: pageSize,
        page,
      }
    ));
  };

  handleSelectChanged = (selectedKeys) => {
    const options = get(this.state, 'problemChoosingOptions', {});
    const module = get(options, 'module', '');
    if (module) {
      this.handleProblemChoosing(selectedKeys)();
    } else {
      this.setState({
        selectedProblemIds: selectedKeys,
      });
    }
  };

  handleClearSelection = () => {
    this.setState({
      selectedProblemIds: [],
    });
  };

  handleProblemChoosing = (ids) => {
    const cpids = isEmpty(ids) ? this.state.selectedProblemIds : ids;
    return () => {
      const options = get(this.state, 'problemChoosingOptions', {});
      const currentProblems = get(options, 'currentProblems', []);
      let cnt = 0;
      const currentPidMapping = get(options, 'currentPidMapping', {});
      const store = APPStore.getState();
      cpids.forEach((cpid) => {
        const cproblem = CollectionProblemsSelectors.getCollectionProblemEntity(store)(cpid);
        const pid = get(cproblem, 'problem.id', '');
        if (get(currentPidMapping, pid, false)) return;
        currentProblems.push({
          id: pid,
          title: get(cproblem, 'problem.title', ''),
          number: get(cproblem, 'problem.number', ''),
          score: 0,
          difficulty: get(cproblem, 'problem.difficulty', 0),
          require: true,
          collection_id: get(cproblem, 'collection.id', ''),
          collection_item: get(cproblem, 'id', ''),
        });
        currentPidMapping[pid] = true;
        cnt++;
      });

      options.currentProblems = currentProblems;
      options.currentPidMapping = currentPidMapping;
      if (cnt > 0) {
        localStorage.setItem(DataKeys.LOCAL_STORAGE.PROBLEM_CHOOSING, JSON.stringify(options));
        this.setState({
          problemChoosingOptions: options,
        });
        jQuery(window).trigger('ChooseProblem');
        // this.doProblemChooseAnimate();
      }
    };
  };
  //
  // handleProblemListDoubleClick = (record) => {
  //   const options = get(this.state, 'problemChoosingOptions', {});
  //   const module = get(options, 'module');
  //   if (module !== '') {
  //     const currentPidMapping = get(options, 'currentPidMapping', {});
  //     if (get(currentPidMapping, record.key, false)) return;     // 如果已经有题目了
  //     this.handleProblemChoosing([record.key])();
  //   }
  // };

  handleOpenTagsFilterDialog = () => {
    showModal(ProblemTagFilterDialog, {
      getTagsList: this.props.getTagsList,
      searchTags: this.props.searchTags,
      collectionId: this.props.collectionId,
      defaultKeys: this.props.tagKeys,
      onOk: (modal, state) => {
        const { selectedKeys = [] } = state;
        const { history, match } = this.props;
        history.push(buildPath(
          this.props.currentRoutePath,
          { problemId: this.props.problemId },
          match.params,
          {
            ...pick(this.state.queryParams, ['limit', 'classify_id', 'type', 'keyword']),
            tags: selectedKeys.join(','),
          }
        ));
      },
    });
  };

  render() {
    return (
      <>
        <Menu
          defaultSelectedKeys={[get(ProblemTypeEnum.enumValueOf(
            get(this.props.queryParams, 'type', -1)
          ), 'key', 'all')]}
          mode="horizontal"
          onSelect={this.handleProblemsTypeFilterChange}
        >
          <Menu.Item key="all">
            全部
          </Menu.Item>
          {ProblemTypeEnum.enumValues.map(item => <Menu.Item
            key={item.key}
          >
            {item.desc}
          </Menu.Item>)}
        </Menu>
        <div className="content-body">
          <div className="pl_list_view">
            <ProblemsListControlBar
              minorMode={!!this.props.problemId}
              canCreateProblem={this.props.canCreateProblem}
              canManageCollection={this.props.canManageCollection}
              collectionId={this.props.collectionId}
              selectedProblemIds={this.state.selectedProblemIds}
              searchKeyword={get(this.state.queryParams, 'keyword', '')}
              onSearch={this.handleSearch}
              onCreateProblemSuccess={this.handleCreateProblemSuccess}
              fetchList={() => this.setState({ queryParams: {} })}
              problemChoosingOptions={this.state.problemChoosingOptions}
              onChoiceProblem={this.handleProblemChoosing(this.state.selectedProblemIds)}
              onOpenTagsFilterDialog={this.handleOpenTagsFilterDialog}
              onClearSelection={this.handleClearSelection}
              tagKeys={this.props.tagKeys}
            />
            <div className="pl_list_scroll_view">
              <Spin
                size="large"
                tip="加载中..."
                spinning={this.props.isLoading}
              >
                {!this.props.isLoading ? <ProblemsListTable
                  onPageChange={this.handlePageChange}
                  minorMode={!!this.props.problemId}
                  problemDatas={this.props.problemDatas}
                  onProblemClick={this.handleProblemClick}
                  onSelectChanged={this.handleSelectChanged}
                  selectedProblemIds={this.state.selectedProblemIds}
                  paginationParams={this.props.paginationParams}
                  canManageCollection={this.props.canManageCollection}
                  problemChoosingOptions={this.state.problemChoosingOptions}
                  // onDoubleClick={this.handleProblemListDoubleClick}
                /> : <div style={{ width: '100%', height: 100 }} />}
              </Spin>
            </div>
          </div>
          { this.props.problemId ? <div className="pl_problem_view">
            {(get(this.props.currentAccount, 'role', 1) !== 1 && get(this.props.currentAccount, 'role', 0) !== 0) && <div className="view_header">
              <DelProblemIcon
                problemId={this.props.problemId}
                collectionId={this.props.collectionId}
              />
              <ModifyProblemIcon
                problemId={this.props.problemId}
                collectionId={this.props.collectionId}
              />
              <a onClick={this.handleCloseProblemClick}><CloseOutlined /></a>
            </div>}
            <NormalProblemView
              className="view_content"
              key={this.props.problemId}
              problemId={this.props.problemId}
              collectionId={this.props.collectionId}
            />
          </div> : null }
        </div>
      </>
    );
  }
}

export default ProblemsListContent;
