import React from 'react';
import { noop } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AccountPropTypes } from 'scripts/common/prop-types/account';
import { AccountActions } from 'scripts/common/logic/account/action';

import AccountEditor from 'scripts/apps/account/widgets/account_editor';
import AvatarEditor from 'scripts/apps/account/widgets/avatar_view';

const mapDispatchToProps = dispatch => bindActionCreators({
  editAccountMe: AccountActions.editAccountMe,
}, dispatch);

@connect(null, mapDispatchToProps)
class SpaceProfileView extends React.PureComponent {
  static propTypes = {
    account: AccountPropTypes.isRequired,
    editAccountMe: PropTypes.func,
  };

  static defaultProps = {
    editAccountMe: noop,
  };

  render() {
    const { account } = this.props;
    return (
      <div className="account-space-profile">
        <div className="info-wrap">
          <AccountEditor
            block
            account={account}
            editAccount={this.props.editAccountMe}
            showSaveButton
          />
        </div>
        <div className="avatar-wrap">
          <h3>修改头像</h3>
          <AvatarEditor account={this.props.account} />
        </div>
      </div>
    );
  }
}

export default SpaceProfileView;
