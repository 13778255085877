import 'styles/education/lesson/notebook.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Input, Empty, Spin, Modal 
} from 'antd';
import { get, set, noop } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NoteSelectors, NoteActions } from 'scripts/common/logic/education/note';
import { formatTimeFromNow } from 'scripts/common/utils/time_formatter';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import MarkdownEditor from 'scripts/common/widgets/mdeditor';
import { LessonSelectors } from 'scripts/common/logic/education/lesson';
import { CoursePropTypes, LessonPropTypes } from 'scripts/common/prop-types/course';
import { CourseSelectors } from 'scripts/common/logic/education/course';

const mapDispatchToProps = dispatch => bindActionCreators({
  getNoteList: NoteActions.getNoteList,
  addNote: NoteActions.addNote,
  editNote: NoteActions.editNote,
}, dispatch);

const mapStateToProps = (state, props) => {
  const noteList = NoteSelectors.getNotesList(props.courseId, props.lessonId)(state);
  const lesson = LessonSelectors.getLessonEntity(state)(props.lessonId);
  const course = CourseSelectors.getCourseEntity(state)(props.courseId);
  return {
    course,
    lesson,
    noteList,
  };
};

@connect(mapStateToProps, mapDispatchToProps)
class CourseNoteBook extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    lessonId: PropTypes.string.isRequired,
    course: CoursePropTypes,
    lesson: LessonPropTypes,
    noteList: PropTypes.arrayOf(PropTypes.shape({})),
    getNoteList: PropTypes.func,
    addNote: PropTypes.func,
    editNote: PropTypes.func,
  };

  static defaultProps = {
    noteList: [],
    course: null,
    lesson: null,
    getNoteList: noop,
    addNote: noop,
    editNote: noop,
  };

  state = {
    isLoading: true,
    mode: 'normal',
    currentId: null,
    notebook: {
      title: '',
      content: '',
    },
    content_editor: null,
  };

  componentDidMount() {
    if (this.props.noteList.length <= 0) {
      this.props.getNoteList({
        params: {
          limit: 1,
          page: 1,
          courseId: this.props.courseId,
          lessonId: this.props.lessonId,
        },
        onSuccess: (result) => {
          const ids = result.map(item => item.id);
          if (ids.length > 0) {
            this.setState({
              currentId: ids[0],
              isLoading: false,
            });
          } else {
            this.setState({
              isLoading: false,
              currentId: null,
            });
          }
        },
      });
    } else {
      // eslint-disable-next-line
      this.setState({
        isLoading: false,
      });
    }
  }

  handleInputChange = keyName => ({ target }) => {
    this.setState(set({ notebook: { ...this.state.notebook } }, `notebook.${keyName}`, target.value));
  };

  handleEditorChange = (text) => {
    this.setState(set({
      notebook: { ...this.state.notebook },
    }, 'notebook.content', text));
  };

  handleSaveClick = () => {
    if (!this.state.currentId) {
      this.props.addNote({
        title: this.state.notebook.title,
        content: this.state.notebook.content,
        params: {
          courseId: this.props.courseId,
          lessonId: this.props.lessonId,
        },
        onSuccess: () => {
          this.setState({
            mode: 'normal',
          });
        },
      });
    } else {
      this.props.editNote({
        noteId: this.state.currentId,
        title: this.state.notebook.title,
        content: this.state.notebook.content,
        params: {
          courseId: this.props.courseId,
          lessonId: this.props.lessonId,
        },
        onSuccess: () => {
          this.setState({
            mode: 'normal',
          });
        },
      });
    }
  };

  handleShowEditor = () => {
    const { course, lesson } = this.props;
    this.setState({
      notebook: {
        title: `${get(course, 'name', '')}_${get(lesson, 'title', '')}_课堂笔记`,
        content: '',
        ...get(this.props.noteList, '0', {}),
      },
      mode: 'editor',
    });
  };

  handleBackToView = () => {
    Modal.confirm({
      title: '操作提示',
      content: '操作将会丢失现有改动，是否继续？',
      onOk: () => {
        this.setState({
          mode: 'normal',
        });
      },
    });
  };

  renderWriteNoteLayer = () => {
    // 稍后改成Markdown
    return  <div className="note_editor">
      <div>
        <Input
          value={get(this.state.notebook, 'title', '')}
          placeholder="笔记标题"
          onChange={this.handleInputChange('title')}
        />
      </div>
      <MarkdownEditor
        height="100%"
        onChange={this.handleEditorChange}
        defaultValue={get(this.state, 'notebook.content', '')}
      />
      <div className="item item-btns">
        <Button type="primary" onClick={this.handleSaveClick}>保存</Button>
        <Button type="default" onClick={this.handleBackToView}>取消</Button>
      </div>
    </div>;
  };

  render() {
    const { noteList = [] } = this.props;
    const note = get(noteList, '0', null);
    const updateTime = get(note, 'update_time', 0);
    const createTime = get(note, 'create_time', 0);
    if (this.state.isLoading) {
      return <div className="course_notebook">
        <Spin className="drawer_loading" tip="加载中" />
      </div>;
    }
    return (
      <div className="course_notebook">
        {this.state.mode === 'editor' ? this.renderWriteNoteLayer() : <div className="course_note_body">
          {!note ? <Empty description="暂无笔记" /> : <div className="content">
            <RichtextContext>{get(note, 'content', '')}</RichtextContext>
            <div className="meta_area">
              编辑于：{formatTimeFromNow(updateTime > 0 ? updateTime : createTime)}
            </div>
          </div>}
          <div className="footer">
            <Button
              block
              className="save_button"
              type="primary"
              size="large"
              onClick={this.handleShowEditor}
            >
              记笔记
            </Button>
          </div>
        </div>}
      </div>
    );
  }
}

export default CourseNoteBook;
