import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import {
  Form, Modal, Input, Checkbox 
} from 'antd';
import { StudentPropTypes } from 'scripts/common/prop-types/account';

class AddStudentModal extends React.PureComponent {
  static propTypes = {
    addStudentCode: PropTypes.string,
    mode: PropTypes.string.isRequired,
    student: StudentPropTypes,
  };

  static defaultProps = {
    addStudentCode: '',
    student: null,
  };

  state = {
    student: this.props.student
      ? JSON.parse(JSON.stringify(this.props.student))
      : {
        code: this.props.addStudentCode,
        realname: '',
      },
    resetBind: false,
  };

  handleCodeChange = (e) => {
    this.setState({
      student: {
        ...this.state.student,
        code: e.target.value,
      },
    });
  };

  handleNameChange = (e) => {
    this.setState({
      student: {
        ...this.state.student,
        realname: e.target.value,
      },
    });
  };

  render() {
    return (
      <Modal
        {...this.props}
        title={this.props.mode === 'edit' ? '修改学生信息' : '添加学生信息'}
      >
        <Form>
          <Form.Item>
            <Input placeholder="请输入学生学号" onChange={this.handleCodeChange} value={this.state.student.code} style={{ width: 300 }} />
          </Form.Item>
          <Form.Item>
            <Input placeholder="请输入学生真实姓名" onChange={this.handleNameChange} value={this.state.student.realname} style={{ width: 300 }} />
          </Form.Item>
          {this.props.mode === 'edit' && <>
            <Checkbox
              disabled={!get(this.state.student, 'master')}
              checked={this.state.resetBind}
              onChange={e => this.setState({ resetBind: e.target.checked })}
            /> 解除绑定
          </>}
        </Form>
      </Modal>
    );
  }
}

export default AddStudentModal;
