import 'styles/education/asgn/rank_list.scss';
import React from 'react';
import {
  Select, Empty, Spin, message 
} from 'antd';
import { get, isEmpty, truncate } from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PathToRegexp from 'path-to-regexp';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AsgnRankListActions } from 'scripts/common/logic/education/asgn/actions/rank_list';
import { AsgnRankListSelectors, AsgnProblemsSelectors } from 'scripts/common/logic/education/asgn/selectors';
import { buildPath, buildApiPath, openLink } from 'scripts/common/utils/location_helper';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { ArrangementPropTypes } from 'scripts/common/prop-types/course';
import { CourseSelectors } from 'scripts/common/logic/education/course/selector';
import { AsgnProblemRoutes, AsgnRoutes } from 'scripts/apps/routes';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { AsgnProblemPropTypes } from 'scripts/common/prop-types/asgn';
import TimeLineRankList from 'scripts/common/widgets/ranklist/timeline_ranklist';
import { nowTime } from 'scripts/common/utils/time_formatter';
import apis from 'scripts/common/constants/apis';

const mapStateToProps = (state) => {
  const rankListDatas = AsgnRankListSelectors.asgnRankListSelector(state);
  const problemsDatas = AsgnProblemsSelectors.getAsgnCodeProblemDatas(state);
  const arrangements = CourseSelectors.getArrangementList(state);

  return {
    rankListDatas,
    problemsDatas,
    arrangements,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnRankList: AsgnRankListActions.getAsgnRankList,
  getArrangementsList: CourseActions.getArrangementsList,
}, dispatch);

const REFRESH_DURATION = 60 * 1000;

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class RankListView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    problemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes),
    rankListDatas: PropTypes.shape({
      replay: PropTypes.bool,
      mode: PropTypes.string,
      first_submit_time: PropTypes.number,
      server_time: PropTypes.number,
      ranklist: PropTypes.arrayOf(PropTypes.shape({})),
      problems: PropTypes.arrayOf(PropTypes.shape({})),
      process_time: PropTypes.number,
    }),
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    isManage: PropTypes.bool.isRequired,
    arrangements: PropTypes.arrayOf(ArrangementPropTypes),
  };

  static defaultProps = {
    rankListDatas: null,
    problemsDatas: null,
    arrangements: [],
  };

  state = {
    isLoading: false,
    hasFetchedArrangement: false,
    arrangementValue: 0,
    timestamp: -1,
    listMode: 'simple',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchedArrangement) {
      nextProps.getArrangementsList({
        courseId: nextProps.courseId,
      });
      return {
        hasFetchedArrangement: true,
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.props.isManage && !this.refreshWorker) {
      this.refreshWorker = setInterval(() => {
        if (this.state.timestamp === -1) {
          this.handleRefreshList();
        }
      }, REFRESH_DURATION);
    }
    this.handleRefreshList();
  }

  componentWillUnmount() {
    if (this.refreshWorker) {
      clearInterval(this.refreshWorker);
      this.refreshWorker = null;
    }
  }

  refreshWorker = null;

  calcProblemStats = () => {
    const stats = {};
    this.props.problemsDatas.forEach((problem) => {
      stats[get(problem, 'problem.id')] = {
        ac: get(problem, 'accepted', 0),
        sub: get(problem, 'submission', 0),
      };
    });
    return stats;
  };

  handleProblemClick = (id) => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnProblemRoutes.CONTENT,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        problemId: id,
      },
      match.params,
      {}
    ));
  };

  handleStudentClick = id => () => {
    const { history, match } = this.props;
    history.push(buildPath(
      AsgnRoutes.CHECKUP_TEACHER,
      {
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        reportId: id,
      },
      match.params,
      {}
    ));
  };

  handleArrangementChange = (value) => {
    this.setState({
      arrangementValue: value,
    }, () => {
      this.handleRefreshList();
    });
  };

  handleRefreshList = () => {
    if (this.state.isLoading) return;
    this.setState({
      isLoading: true,
    }, () => {
      this.props.getAsgnRankList({
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        query: {
          timestamp: this.state.timestamp,
          arrangement_id: this.state.arrangementValue,
        },
        onSuccess: () => {
          this.setState({
            isLoading: false,
          });
        },
      });
    });
  };

  handleListModeChange = (v) => {
    this.setState({
      listMode: v,
    });
  };

  handleTimeSliderChange = (ts) => {
    const firstSubmitTime = get(this.props.rankListDatas, 'first_submit_time', -1);
    if (firstSubmitTime > 0) {
      this.setState({
        timestamp: Math.max(0, ts - firstSubmitTime),
      }, () => {
        this.handleRefreshList();
      });
    }
  };

  renderExtraToolOptions = () => {
    return (<div>
      <p><strong>按排课筛选：</strong></p>
      <Select
        style={{ width: 140 }}
        defaultValue={this.state.arrangementValue}
        onChange={this.handleArrangementChange}
      >
        <Select.Option value={0} key={0}>全部</Select.Option>
        {this.props.arrangements.map((item) => {
          return (
            <Select.Option value={item.id} key={item.id}>
              {get(item, 'name', '')}
            </Select.Option>
          );
        })}
      </Select>
    </div>);
  };

  renderAccountLabel = (account) => {
    return <a onClick={this.handleStudentClick(get(account, 'report_id', ''))}>
      {truncate(get(account, 'nickname', ''), {
        length: 14,
      })}
    </a>;
  };

  handleDownloadClick = () => {
    if (this.props.isManage) {
      const pattern = PathToRegexp.compile(apis.EDUCATION.ASGN.RANK_DOWNLOAD);
      openLink(buildApiPath(pattern({
        cid: this.props.courseId,
        aid: this.props.asgnId,
      })), true, true);
    } else {
      message.error('此操作仅限课程管理人员，如有需要请联系课程管理人员');
    }
  };

  render() {
    const firstSubmitTime = Math.floor(get(this.props.rankListDatas, 'first_submit_time', -1));
    const lastSubmitTime = Math.floor(get(this.props.rankListDatas, 'last_submit_fime', -1));
    const timeSeconds = 1000;
    const stepSeconds = 60;
    const endTime = lastSubmitTime > 0 ? lastSubmitTime : Math.floor(
      (+nowTime().toDate()) / timeSeconds
    );
    if (isEmpty(this.props.rankListDatas) || firstSubmitTime <= 0) {
      if (!isEmpty(this.props.rankListDatas) && firstSubmitTime <= 0) {
        return <div style={{ marginTop: 40 }}><Empty /></div>;
      }
      return <div style={{ marginTop: 40, textAlign: 'center' }}><Spin tip="载入中" /></div>;
    }
    return (
      <div className="wejudge-asgn-ranklist">
        <TimeLineRankList
          hideAwards
          extraToolOptions={this.renderExtraToolOptions()}
          startTime={firstSubmitTime - (firstSubmitTime % stepSeconds)}
          endTime={endTime + (stepSeconds - (endTime % stepSeconds))}
          listMode={this.state.listMode}
          onListModeChange={this.handleListModeChange}
          onTimeSliderChange={this.handleTimeSliderChange}
          rankListDatas={this.state.isLoading ? null : this.props.rankListDatas}
          problemStats={this.calcProblemStats()}
          renderAccountLabel={this.renderAccountLabel}
          handleDownloadClick={this.handleDownloadClick}
        />
      </div>
    );
  }
}

export default RankListView;
