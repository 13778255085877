import 'styles/repository/file_selector.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {
  Modal, Spin, Empty, Button 
} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  RepositoryAction,
  RepositoryFsAction,
  RepositorySelectors
} from 'scripts/common/logic/repository';
import { RepositoryPropType } from 'scripts/common/prop-types/repository';
import FileView from 'scripts/apps/repository/file_view';
import { buildPath, openLink } from 'scripts/common/utils/location_helper';
import { RepositoryRoutes } from 'scripts/apps/routes';
import TreeView from './tree_view';

const mapStateToProps = (state) => {
  const repository = RepositorySelectors.getMyRepositoryEntity(state);
  return {
    repository,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getRepositoryFsDirectory: RepositoryFsAction.getRepositoryFsDirectory,
  createRepository: RepositoryAction.createRepository,
  getMyRepositoryInfo: RepositoryAction.getMyRepositoryInfo,
}, dispatch);

// 这个页面用来管理一些没有课程的
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class FileSelectorView extends React.PureComponent {
  static propTypes = {
    repository: RepositoryPropType,
    initFileSystemIds: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.string, PropTypes.number
    ])),
    createRepository: PropTypes.func,
    getMyRepositoryInfo: PropTypes.func,
    getRepositoryFsDirectory: PropTypes.func,
    onlyVideo: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    repository: null,
    initFileSystemIds: [],
    createRepository: noop,
    getMyRepositoryInfo: noop,
    getRepositoryFsDirectory: noop,
    onlyVideo: false,
  };

  state = {
    hasFetch: false,    // 是否已获取过仓库
    noRepo: false,      // 未创建仓库
    repoPathArray: [],
    selectedFiles: this.props.initFileSystemIds,
  };

  componentDidMount() {
    this.props.getMyRepositoryInfo({
      onSuccess: (repoId) => {
        if (repoId) {
          // 载入文件目录
          this.props.getRepositoryFsDirectory({
            init: true,
            repoId,
            fsId: 'root',
            onComplete: () => {
              this.setState({
                hasFetch: true,
              });
            },
          });
        } else {
          this.setState({
            hasFetch: true,
            noRepo: true,
          });
        }
      },
    });
  }

  treeView = React.createRef();

  handleCreateMyRepo = () => {
    Modal.confirm({
      title: '操作确认',
      content: '确认要激活教学资源仓库吗？',
      onOk: () => {
        this.props.createRepository({
          onSuccess: (repoId) => {
            setTimeout(() => {
              openLink(buildPath(RepositoryRoutes.INDEX, { repoId }), true);
            }, 1000);
          },
        });
      },
    });
  };

  handlePathChange = (vpath) => {
    this.setState({
      repoPathArray: vpath.split('-'),
    });
  };

  handleFileSelectChange = (selKeys) => {
    this.setState({
      selectedFiles: selKeys,
    });
  };

  renderContent = () => {
    const { repository } = this.props;
    const { hasFetch, noRepo } = this.state;
    if (!hasFetch) {
      return <Spin />;
    }
    if (noRepo) {
      return <Empty style={{ marginTop: 16 }} description="你还没有激活自己的仓库">
        <Button type="primary" onClick={this.handleCreateMyRepo}>激活我的仓库</Button>
      </Empty>;
    }
    return <div className="rv-content">
      <div className="rv-treeview">
        <TreeView
          disabledEditActionBar
          repoPath={this.state.repoPathArray.join('-') || 'root'}
          repoId={repository.id.toString()}
          onPathChange={this.handlePathChange}
          ref={this.treeView}
        />
      </div>
      <div className="rv-fileview">
        <FileView
          repoPathArray={this.state.repoPathArray}
          repoId={repository.id.toString()}
          rowSelectedKeys={this.state.selectedFiles}
          handleSelectChange={this.handleFileSelectChange}
          noNewFolderGuide
          noFileAction
          onlyVideo={this.props.onlyVideo}
        />
      </div>
    </div>;
  };

  render() {
    const { hasFetch, noRepo } = this.state;
    const hasFileSelected = this.state.selectedFiles.length > 0;
    return <Modal
      {...this.props}
      className="repository-file-selector-dialog"
      title={`请选择文件${hasFileSelected ? `(已选${this.state.selectedFiles.length}个)` : ''}`}
      width={1152}
      okButtonProps={{
        disabled: !hasFetch || noRepo || !hasFileSelected,
      }}
    >
      {this.renderContent()}
    </Modal>;
  }
}

export default FileSelectorView;
