import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Tree, TreeSelect } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { showModal } from 'scripts/common/widgets/modal';
import LessonModal from 'scripts/apps/education/lesson/widgets/lesson_modal';

const { TreeNode } = TreeSelect;

class LessonSelectTree extends React.PureComponent {
  static propTypes = {
    lessonList: PropTypes.arrayOf(),
    onParentChange: PropTypes.func,
    defaultKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    lessonList: [],
  };

  state = {
    selectedKey: this.props.defaultKey,
  };

  handleSelect = (value) => {
    this.setState({
      selectedKey: value,
    });
    this.props.onParentChange({
      parent: value,
    });
  };

  renderLessonTreeNodes = (lessonList) => {
    return (
      lessonList.map((item) => {
        const ch = get(item, 'children', []);
        const hasChildren = (ch.length !== 0);
        if (item.is_group) {
          return {
            key: item.id,
            value: item.id,
            title: item.title,
            children: hasChildren ? this.renderLessonTreeNodes(ch) : [],
          };
        } return null;
      }).filter(item => !!item)
    );
  };

  render() {
    return (
      <div className="lesson_tree">
        <TreeSelect
          showLine
          style={{ width: 280 }}
          value={this.state.selectedKey}
          blockNode
          switcherIcon={<DownOutlined />}
          onChange={this.handleSelect}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          allowClear
          treeData={this.renderLessonTreeNodes(this.props.lessonList)}
        />
      </div>
    );
  }
}

export default LessonSelectTree;
