import React from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AsgnProblemPropTypes, AsgnPropTypes, AsgnReportPropTypes } from 'scripts/common/prop-types/asgn';
import {
  AsgnProblemsSelectors,
  AsgnReportSelectors,
  AsgnSolutionSelectors
} from 'scripts/common/logic/education/asgn/selectors';
import { AsgnSolutionActions } from 'scripts/common/logic/education/asgn/actions';
import DataKeys from 'scripts/common/constants/data_keys';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import StudentReport from './widgets/asgn_report_widgets';

const LOADING_STATUS_KEY = 'asgn_report_view';

@connect((state, props) => {
  const report = AsgnReportSelectors.getAsgnReportEntity(state)(get(props.asgn, 'status.report_id'));
  const normalSolutions =    AsgnSolutionSelectors.getAsgnProblemSolutions(DataKeys.NORMAL_PROBLEMS)(state)();
  const codeSolutions =    AsgnSolutionSelectors.getAsgnProblemSolutions(DataKeys.CODE_PROBLEMS)(state)();
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const codeProblemsDatas = AsgnProblemsSelectors.getAsgnCodeProblemDatas(state);
  const objectiveProblemsDatas = AsgnProblemsSelectors.getAsgnObjectiveProblemDatas(state);
  return {
    report,
    isLoading,
    codeSolutions,
    normalSolutions,
    codeProblemsDatas,
    objectiveProblemsDatas,
  };
}, dispatch => bindActionCreators({
  getCodeProblemSolutions: AsgnSolutionActions.getCodeProblemSolutions,
  getNormalProblemSolutions: AsgnSolutionActions.getNormalProblemSolutions,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
}, dispatch))
class MyReportView extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    asgn: AsgnPropTypes.isRequired,
    report: AsgnReportPropTypes,
    normalSolutions: PropTypes.shape({}),
    codeSolutions: PropTypes.shape({}),
    codeProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    objectiveProblemsDatas: PropTypes.arrayOf(AsgnProblemPropTypes).isRequired,
    getNormalProblemSolutions: PropTypes.func,
    isLoading: PropTypes.bool,
    setLoadingStatus: PropTypes.func,
    getCodeProblemSolutions: PropTypes.func,
  };

  static defaultProps = {
    report: null,
    isLoading: false,
    codeSolutions: null,
    normalSolutions: null,
    setLoadingStatus: noop,
    getCodeProblemSolutions: noop,
    getNormalProblemSolutions: noop,
  };

  state = {
    hasFetchProblemSolutions: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchProblemSolutions || !nextProps.report) {
      nextProps.setLoadingStatus(LOADING_STATUS_KEY, true);
      nextProps.getCodeProblemSolutions({
        courseId: nextProps.courseId,
        asgnId: nextProps.asgnId,
        authorId: get(nextProps, 'report.author.id'),
        onComplete: () => {
          nextProps.getNormalProblemSolutions({
            courseId: nextProps.courseId,
            asgnId: nextProps.asgnId,
            author_id: get(nextProps, 'report.author.id'),
            onComplete: () => {
              nextProps.setLoadingStatus(LOADING_STATUS_KEY, false);
            },
          });
        },
      });
      return {
        hasFetchProblemSolutions: true,
      };
    }
    return null;
  }

  render() {
    const {
      report,
      asgn,
      isLoading,
      codeSolutions,
      normalSolutions,
      codeProblemsDatas,
      objectiveProblemsDatas,
    } = this.props;
    return <div className="asgn_report_view">
      {report && <StudentReport
        asgn={asgn}
        report={report}
        codeSolutions={codeSolutions}
        normalSolutions={normalSolutions}
        codeProblemsDatas={codeProblemsDatas}
        objectiveProblemsDatas={objectiveProblemsDatas}
        spinning={isLoading}
      />}
    </div>;
  }
}

export default MyReportView;
