import 'styles/education/course/course.scss';
import 'styles/common/layout/general_view.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Menu, Spin, Layout, Button, PageHeader, Typography, Statistic 
} from 'antd';
import {
  withRouter, Route, Switch, matchPath, Redirect 
} from 'react-router-dom';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath, buildResourcePath } from 'scripts/common/utils/location_helper';
import CourseManagementView from 'scripts/apps/education/course/course_management';
import CourseIconImage from 'images/course_icon.jpg';
import { WeJudgeActions, WeJudgeSelectors } from 'scripts/common/logic/wejudge';
import { CourseSelectors } from 'scripts/common/logic/education/course/selector';
import { CourseActions } from 'scripts/common/logic/education/course/action';
import { CourseRoutes, LessonRoutes } from 'scripts/apps/routes';
import { CoursePropTypes } from 'scripts/common/prop-types/course';
import { formatTime } from 'scripts/common/utils/time_formatter';
import { timeStatusJudge } from 'scripts/common/utils/course_status_judge';
import { LoginRequire } from 'scripts/apps/wejudge/auth';
import { createPermissionChecker } from 'scripts/common/utils/validator';
import { CoursePermissionsEnum } from 'scripts/common/enums/course';
import CourseStatistics from 'scripts/apps/education/course/statistic/index';
import RichtextContext from 'scripts/common/widgets/richtext_context';
import { LessonActions } from 'scripts/common/logic/education/lesson';
import LessonView from 'scripts/apps/education/lesson/index';

import CourseOverviewView from './course_overview';
import AsgnListContent from './course_asgn_list';

const LOADING_STATUS_KEY = 'course_view';

const { Content } = Layout;

const mapDispatchToProps = dispatch => bindActionCreators({
  getCourseEntity: CourseActions.getCourseEntity,
  setLoadingStatus: WeJudgeActions.setLoadingStatus,
  getLessonsList: LessonActions.getLessonsList,
  delLesson: LessonActions.delLesson,
  addLesson: LessonActions.addLesson,
  getCourseExtraInfo: CourseActions.getCourseExtraInfo,
}, dispatch);

const mapStateToProps = (state, props) => {
  const { match, location } = props;
  const matchItem = matchPath(
    location.pathname,
    { path: CourseRoutes.TABITEMS }
  );
  const matchOverview = matchPath(
    location.pathname,
    { path: CourseRoutes.CONTENT_OVERVIEW  }
  );
  const ItemKey = get(matchItem, 'params.tabItemName', '');
  const tabItemKey = (ItemKey === '' ? 'desc' : ItemKey);
  const courseId = parseInt(get(match.params, 'courseId', 0), 0);
  const isLoading = WeJudgeSelectors.uiLoadingStatusSelector(state, LOADING_STATUS_KEY);
  const isOverview = !!matchOverview;
  const course = CourseSelectors.getCourseEntity(state)(courseId);
  const coursePermission = createPermissionChecker(get(course, 'permissions_calculated', {}));
  const hasManageCoursePermission = coursePermission(CoursePermissionsEnum.MANAGE);
  const hasModifyCoursePermission = coursePermission(CoursePermissionsEnum.MODIFY);
  const hasDeleteCoursePermission = coursePermission(CoursePermissionsEnum.DELETE);
  const hasViewCoursePermission = coursePermission(CoursePermissionsEnum.VIEW);
  const hasCreateAsgnPermission = get(course, 'create_asgn_permissions', false);
  return {
    tabItemKey,
    courseId,
    course,
    isLoading,
    isOverview,
    hasManageCoursePermission,
    hasModifyCoursePermission,
    hasDeleteCoursePermission,
    hasCreateAsgnPermission,
    hasViewCoursePermission,
  };
};

@LoginRequire
@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class CourseView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    courseId: PropTypes.number.isRequired,
    tabItemKey: PropTypes.string.isRequired,
    getCourseEntity: PropTypes.func.isRequired,
    course: CoursePropTypes,
    isLoading: PropTypes.bool,
    isOverview: PropTypes.bool,
    hasManageCoursePermission: PropTypes.bool,
    hasModifyCoursePermission: PropTypes.bool,
    hasDeleteCoursePermission: PropTypes.bool,
    hasCreateAsgnPermission: PropTypes.bool,
    hasViewCoursePermission: PropTypes.bool,
  };

  static defaultProps = {
    course: null,
    isLoading: true,
    isOverview: false,
    hasManageCoursePermission: false,
    hasModifyCoursePermission: false,
    hasDeleteCoursePermission: false,
    hasCreateAsgnPermission: false,
    hasViewCoursePermission: false,
  };

  state = {
    hasFetchEntity: false,
  };

  componentDidMount() {
    this.props.setLoadingStatus(LOADING_STATUS_KEY, true);
    this.props.getCourseEntity({
      id: this.props.courseId,
      onSuccess: () => {},
      onError: () => {
        this.props.history.push(buildPath(CourseRoutes.LIST));
      },
      onComplete: () => {
        this.setState({
          hasFetchEntity: true,
        });
        this.props.getCourseExtraInfo({
          courseId: this.props.courseId,
          onSuccess: () => {
            this.props.setLoadingStatus(LOADING_STATUS_KEY, false);
          },
        });
      },
    });
  }

  componentDidUpdate() {
    if (this.state.hasFetchEntity && !this.props.isLoading && !this.props.course) {
      this.props.history.push(buildPath(CourseRoutes.LIST));
    }
    if (this.props.course && !this.props.hasViewCoursePermission) {
      this.props.history.push(buildPath(CourseRoutes.LIST));
    }
  }

  handleCourseTabClick = ({ key }) => {
    const { history, match } = this.props;
    const TAB_MAPPING = {
      lesson: CourseRoutes.LESSON,
      overview: CourseRoutes.CONTENT_OVERVIEW,
      asgn: CourseRoutes.CONTENT_ASGN,
      test: CourseRoutes.CONTENT_TEST,
      notebook: CourseRoutes.CONTENT_NOTEBOOK,
      discuss: CourseRoutes.CONTENT_DISCUSS,
      management: CourseRoutes.CONTENT_MANAGEMENT,
      statistics: CourseRoutes.CONTENT_STATISTICS,
    };
    history.push(buildPath(
      get(TAB_MAPPING, key, CourseRoutes.CONTENT),
      null,
      match.params
    ));
  };

  renderHeader = () => {
    const  { course }  = this.props;
    const courseIcon = get(course, 'author.avator', '');
    const schoolName = get(course, 'school.name', '');
    const teacherName = get(course, 'author.realname', '');
    const courseDescription = get(course, 'description', '');
    const hasCourse = get(course, 'has_lesson', false);
    const TypeTabItems = [
      {
        key: 'overview',
        desc: '课程介绍',
      },
      ...(hasCourse ? [{
        key: 'lesson',
        desc: '在线教学',
      }] : []),
      {
        key: 'asgn',
        desc: '课程作业',
      },
      ...(this.props.hasManageCoursePermission ? [{
        key: 'management',
        desc: '课程设置',
      }, {
        key: 'statistics',
        desc: '比赛统计',
      }] : [])
    ];
    const startTime = get(course, 'start_time', 0);
    const endTime = get(course, 'end_time', 0);
    return <PageHeader
      className={classnames('course_view_header', { course_view_header_lesson: !this.props.isOverview })}
      title={get(course, 'name', '')}
      subTitle={`${teacherName} · ${schoolName}`}
      avatar={{ src: courseIcon ? buildResourcePath(courseIcon) : CourseIconImage }}
      footer={<Menu
        onSelect={this.handleCourseTabClick}
        selectedKeys={[this.props.tabItemKey]}
        mode="horizontal"
      >
        {TypeTabItems.map(item => (
          <Menu.Item key={item.key}>
            {item.desc}
          </Menu.Item>
        ))}
      </Menu>}
    >
      {this.props.isOverview && <div className="content">
        <div className="left">
          <Typography.Paragraph>
            <strong>课程介绍：</strong>
            <RichtextContext
              textMode
              truncateLength={120}
              viewEmpty="欢迎参与本次课程，祝你学习进步！"
              content={courseDescription}
            />
          </Typography.Paragraph>
          {startTime && endTime ? <Typography.Paragraph>
            <strong>开课时间：</strong> {formatTime(startTime, 'LONG_DATE')} ～ {formatTime(endTime, 'LONG_DATE')}
          </Typography.Paragraph> : <Typography.Paragraph>此课程长期有效</Typography.Paragraph>}
        </div>
        <div className="right">
          <Statistic
            title={this.props.hasManageCoursePermission ? '学生' : '同学'}
            value={get(course, 'student', 0)}
          />
          <Statistic
            title="状态"
            value={timeStatusJudge(get(course, 'start_time', 0), get(course, 'end_time', 0))}
          />
        </div>
      </div>}
    </PageHeader>;
  };

  render() {
    const { course } = this.props;
    return (this.state.hasFetchEntity && !this.props.isLoading) ? (
      <>
        <div className="course_view">
          {this.renderHeader()}
          <Switch>
            <Route
              path={LessonRoutes.LESSON}
              render={props => <LessonView
                {...props}
                courseId={this.props.courseId}
                course={course}
                hasManageCoursePermission={this.props.hasManageCoursePermission}
              />}
            />
            <Route
              path={CourseRoutes.CONTENT_OVERVIEW}
              render={() => <CourseOverviewView
                courseId={this.props.courseId}
                course={course}
                hasManageCoursePermission={this.props.hasManageCoursePermission}
              />}
              exact
            />
            <Route
              path={CourseRoutes.CONTENT_ASGN}
              render={() => <AsgnListContent
                courseId={this.props.courseId}
                hasCreateAsgnPermission={this.props.hasCreateAsgnPermission}
              />}
              exact
            />
            {/*<Route*/}
            {/*path={CourseRoutes.CONTENT_TEST}*/}
            {/*render={() => <div>测试</div>}*/}
            {/*exact*/}
            {/*/>*/}
            {/*<Route*/}
            {/*path={CourseRoutes.CONTENT_NOTEBOOK}*/}
            {/*render={() => <div>课堂笔记</div>}*/}
            {/*exact*/}
            {/*/>*/}
            {/*<Route*/}
            {/*path={CourseRoutes.CONTENT_DISCUSS}*/}
            {/*render={() => <div>交流讨论</div>}*/}
            {/*exact*/}
            {/*/>*/}
            {/*{this.props.hasManageCoursePermission && <Route*/}
            {/*path={CourseRoutes.CONTENT_ARRANGEMENTS}*/}
            {/*render={() => <CourseArrangementsView*/}
            {/*courseId={this.props.courseId}*/}
            {/*schoolId={get(this.props.course, 'school.id')}*/}
            {/*/>}*/}
            {/*exact*/}
            {/*/>}*/}
            {this.props.hasModifyCoursePermission && <Route
              path={CourseRoutes.CONTENT_MANAGEMENT}
              render={props => <CourseManagementView
                {...props}
                courseId={this.props.courseId}
                hasDeleteCoursePermission={this.props.hasDeleteCoursePermission}
              />}
            />}
            {this.props.hasManageCoursePermission && <Route
              path={CourseRoutes.CONTENT_STATISTICS}
              render={props => <CourseStatistics
                {...props}
                courseId={this.props.courseId}
              />}
            />}
            <Route
              render={() => <Redirect
                to={buildPath(
                  CourseRoutes.CONTENT_OVERVIEW,
                  null,
                  this.props.match.params
                )}
              />}
              exact
            />
          </Switch>
        </div>
      </>)
      : (
        <Layout className="wj_general_view collection_problems_list">
          <Content className="wj_layout_content_with_padding">
            <Spin
              size="large"
              tip="加载中..."
              spinning={this.props.isLoading}
            />
          </Content>
        </Layout>
      );
  }
}

export default CourseView;
