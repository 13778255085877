const prefix = window.WEJUDGE_ROOT_PREFIX || '';

export const WeJudgeRoutes = {
  SHORT_NAME: `${prefix}/:shortName`,
  WEJUDGE_ROOT: `${prefix}/`,
  ACCOUNT_ROOT: `${prefix}/accounts`,
  COLLECTION_ROOT: `${prefix}/collections`,
  EDUCATION_ROOT: `${prefix}/education`,
  REPOSITORY_ROOT: `${prefix}/repository/:repoId`,
  HELPER_ROOT: `${prefix}/helper`,
  EXAMPLE_ROOT: `${prefix}/example`,
  MANAGEMENT_ROOT: `${prefix}/management`,
  WECHAT_ROOT: `${prefix}/wechat`,
  CONTEST_ROOT_JUMP: `${prefix}/contest`,
  CONTEST_ROOT: `${prefix}/contest/:contestId`,
  CONTEST_LIST: `${prefix}/contest/list`,
  ANNOUNCE_ROOT: `${prefix}/announcement`,
  NOTICE_ROOT: `${prefix}/notice`,
};

// 公告路由
export const AnnounceRoutes = {
  LIST: `${WeJudgeRoutes.ANNOUNCE_ROOT}/list`,
  NEW: `${WeJudgeRoutes.ANNOUNCE_ROOT}/new`,
  BODY_VIEW: `${WeJudgeRoutes.ANNOUNCE_ROOT}/:aid`,
  BODY_EDIT: `${WeJudgeRoutes.ANNOUNCE_ROOT}/:aid/edit`,
};

export const NoticeRoutes = {
  LIST: `${WeJudgeRoutes.NOTICE_ROOT}/list`,
  NOTICE_VIEW: `${WeJudgeRoutes.NOTICE_ROOT}/:nid`,
};

// 主系统和教学服路由
export const AccountRoutes = {
  AUTH: {
    LOGIN: `${WeJudgeRoutes.ACCOUNT_ROOT}/auth/login`,
    LOGOUT: `${WeJudgeRoutes.ACCOUNT_ROOT}/auth/logout`,
    REGISTER: `${WeJudgeRoutes.ACCOUNT_ROOT}/auth/register`,
    FORGET: `${WeJudgeRoutes.ACCOUNT_ROOT}/auth/forget`,
    WECHAT: `${WeJudgeRoutes.ACCOUNT_ROOT}/auth/wechat`,
    SUBITEMS: `${WeJudgeRoutes.ACCOUNT_ROOT}/auth/:subItemName`,
  },
  PROFILE: {
    INDEX: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/profile/home`,
    PROFILE: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/profile/profile`,
    WECHAT: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/profile/wechat`,
    SAFETY: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/profile/safety`,
    SUBITEMS: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/profile/:subItemKey?`,
  },
  DASHBOARD: {
    INDEX: `${WeJudgeRoutes.ACCOUNT_ROOT}/dashboard`,
  },
  SPACE: {
    INDEX: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space`,                         // 首页
    HOME: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space/index`,                         // 首页
    COURSES: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space/courses`,               // 我的课程
    CODE_SOL: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space/code_solution`,     // 做题记录
    PROFILE: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space/profile`,               // 个人设置
    SAFETY: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space/safety`,                 // 安全设置(密码设置、微信绑定等)
    PAY_ORDER: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space/payment_order`,       // 支付订单
    SUBITEMS: `${WeJudgeRoutes.ACCOUNT_ROOT}/:accountId/space/:subItemKey?`,       // 支付订单
  },
  EMAIL: {
    VERIFICATION: `${WeJudgeRoutes.ACCOUNT_ROOT}/email/verification`,
    FORGET_PASSWORD: `${WeJudgeRoutes.ACCOUNT_ROOT}/public/password/forget`,
  },
};

export const CollectionRoutes = {
  LIST: `${WeJudgeRoutes.COLLECTION_ROOT}/list/:categoryId?`,
  COLLETION_VIEW: `${WeJudgeRoutes.COLLECTION_ROOT}/:collectionId`,
  CODE_PROBLEM_VIEW: `${WeJudgeRoutes.COLLECTION_ROOT}/:collectionId/programming/:problemId`,
};

export const CollectionViewsRoutes = {
  PROBLEMS_LIST: `${CollectionRoutes.COLLETION_VIEW}/problems`,
  PROBLEMS_LIST_WITH_PROBLEM: `${CollectionRoutes.COLLETION_VIEW}/problems/:problemId`,
  JUDGE_STATUS: `${CollectionRoutes.COLLETION_VIEW}/judge_status`,
  STATISTICS: `${CollectionRoutes.COLLETION_VIEW}/statistics`,
  MANAGEMENT: `${CollectionRoutes.COLLETION_VIEW}/management`,
  SUBITEMS: `${CollectionRoutes.COLLETION_VIEW}/:subItemName`,
};

export const CollectionProblemViewRoutes = {
  CONTENT: CollectionRoutes.CODE_PROBLEM_VIEW,
  SUBMIT_CODE: `${CollectionRoutes.CODE_PROBLEM_VIEW}/submit`,
  JUDGE_STATUS: `${CollectionRoutes.CODE_PROBLEM_VIEW}/judge_status`,
  SHARELIST: `${CollectionRoutes.CODE_PROBLEM_VIEW}/solution/list`,
  SOLUTIONSTAGS: `${CollectionRoutes.CODE_PROBLEM_VIEW}/solution/tags`,
  STATISTICS: `${CollectionRoutes.CODE_PROBLEM_VIEW}/statistics`,
  SUBITEMS: `${CollectionRoutes.CODE_PROBLEM_VIEW}/:subItemName`,  // 映射上边子项目的地址
  MANAGEMENT: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management`,
  MANAGEMENT_EDIT: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management/edit`,
  MANAGEMENT_JUDGE: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management/judge`,
  MANAGEMENT_TESTCASES: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management/test_cases`,
  MANAGEMENT_DEMOCASES: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management/demo_cases`,
  MANAGEMENT_SPECIAL_JUDGE: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management/special_judge`,
  MANAGEMENT_DELETE: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management/delete`,
  MANAGEMENT_SUBITEMS: `${CollectionRoutes.CODE_PROBLEM_VIEW}/management/:subItemName`, // 映射上边子项目的地址
};

export const RepositoryRoutes = {
  INDEX: `${WeJudgeRoutes.REPOSITORY_ROOT}`,
  TREE_VIEW: `${WeJudgeRoutes.REPOSITORY_ROOT}/tree`,
  TREE_VIEW_PATH: `${WeJudgeRoutes.REPOSITORY_ROOT}/tree/:path`,
  SETTINGS_VIEW: `${WeJudgeRoutes.REPOSITORY_ROOT}/settings`,
};

export const CourseRoutes = {
  LIST: `${WeJudgeRoutes.EDUCATION_ROOT}/course/list/:categoryId?`,
  CONTENT: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId`,
  CONTENT_DESCRIPTION: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/description`,
  CONTENT_OVERVIEW: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/overview`,
  CONTENT_ASGN: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/asgn`,
  CONTENT_RESOURCE: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/resource`,
  CONTENT_TEST: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/test`,
  CONTENT_NOTEBOOK: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/notebook`,
  CONTENT_DISCUSS: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/discuss`,
  CONTENT_ARRANGEMENTS: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/arrangements`,
  CONTENT_MANAGEMENT: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/management`,
  CONTENT_STATISTICS: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/statistics`,
  STATISTICS_CONTEST_LIST: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/statistics/contest`,
  STATISTICS_CONTEST_VIEW: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/statistics/contest/:contestId`,
  LESSON: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/lesson`,
  TABITEMS: `${WeJudgeRoutes.EDUCATION_ROOT}/course/:courseId/:tabItemName`, // 映射上边子项目的地址
};

export const CourseManagementRoutes = {
  INFO: `${CourseRoutes.CONTENT_MANAGEMENT}/info`,
  LESSON: `${CourseRoutes.CONTENT_MANAGEMENT}/lesson`,
  LESSON_VIEW: `${CourseRoutes.CONTENT_MANAGEMENT}/lesson/:lessonId?`,
  ARRANGEMENT: `${CourseRoutes.CONTENT_MANAGEMENT}/arrangement`,
  CHOSSING: `${CourseRoutes.CONTENT_MANAGEMENT}/choosing`,
  DELETE: `${CourseRoutes.CONTENT_MANAGEMENT}/delete`,
  TABITEMS: `${CourseRoutes.CONTENT_MANAGEMENT}/:tabItemName`,
};

export const LessonRoutes = {
  LESSON: `${CourseRoutes.LESSON}/:lessonId?`,
  TABITEMS: `${CourseRoutes.LESSON}/:lessonId/:tabItemName`,
};

export const AsgnRoutes = {
  ASGN: `${CourseRoutes.CONTENT}/asgn/:asgnId`,
  CODE_PROBLEMS: `${CourseRoutes.CONTENT}/asgn/:asgnId/problems/:problemId`,
  OVERVIEW: `${CourseRoutes.CONTENT}/asgn/:asgnId/overview`,
  JUDGE_HISTORY: `${CourseRoutes.CONTENT}/asgn/:asgnId/judge_history`,
  RANK: `${CourseRoutes.CONTENT}/asgn/:asgnId/rank`,
  REPORT: `${CourseRoutes.CONTENT}/asgn/:asgnId/report`,
  CHECKUP: `${CourseRoutes.CONTENT}/asgn/:asgnId/checkup`,
  MANAGEMENT: `${CourseRoutes.CONTENT}/asgn/:asgnId/management`,
  STATISTICS: `${CourseRoutes.CONTENT}/asgn/:asgnId/statistics`,
  CROSS_CHECK: `${CourseRoutes.CONTENT}/asgn/:asgnId/cross_check`,
  CROSS_CHECK_SIMPLE: `${CourseRoutes.CONTENT}/asgn/:asgnId/cross_check/simple`,
  CROSS_CHECK_MOSS: `${CourseRoutes.CONTENT}/asgn/:asgnId/cross_check/moss`,
  SUBITEMS: `${CourseRoutes.CONTENT}/asgn/:asgnId/:subItemName`, // 映射上边子项目的地址
  CHECKUP_TEACHER: `${CourseRoutes.CONTENT}/asgn/:asgnId/checkup/:reportId`,
};

export const AsgnProblemRoutes = {
  CONTENT: AsgnRoutes.CODE_PROBLEMS,
  SUBMIT_CODE: `${AsgnRoutes.CODE_PROBLEMS}/submit`,
  JUDGE_STATUS: `${AsgnRoutes.CODE_PROBLEMS}/judge_status`,
  CORRECT_ANSWER: `${AsgnRoutes.CODE_PROBLEMS}/correct_answer`,
  CORRECT_ANSWER_DETAIL: `${AsgnRoutes.CODE_PROBLEMS}/correct_answer/:lang`,
  SUBITEMS: `${AsgnRoutes.CODE_PROBLEMS}/:subItemName`, // 映射上边子项目的地址
};

export const HelperRoutes = {
  DESC: `${WeJudgeRoutes.HELPER_ROOT}/desc`,
  FAQ: `${WeJudgeRoutes.HELPER_ROOT}/faq`,
  TOOLS: `${WeJudgeRoutes.HELPER_ROOT}/tools`,
  CONTRIBUTION: `${WeJudgeRoutes.HELPER_ROOT}/contribution`,
  SUBITEMS: `${WeJudgeRoutes.HELPER_ROOT}/:subItemName`,
};
export const ManagementRoutes = {
  INDEX: `${WeJudgeRoutes.MANAGEMENT_ROOT}/index`,
  ACCOUNT_MANAGEMENT: `${WeJudgeRoutes.MANAGEMENT_ROOT}/account`,
  SCHOOL_LIST: `${WeJudgeRoutes.MANAGEMENT_ROOT}/school`,
  STUDENT_MANAGEMENT: `${WeJudgeRoutes.MANAGEMENT_ROOT}/school/:schoolId/student`,
  MONITOR: {
    INDEX: `${WeJudgeRoutes.MANAGEMENT_ROOT}/monitor`,
    JUDGE_QUEUE: `${WeJudgeRoutes.MANAGEMENT_ROOT}/monitor/judge_queue`,
    JUDGE_QUEUE_WITH_TYPE: `${WeJudgeRoutes.MANAGEMENT_ROOT}/monitor/judge_queue/:type`,
    SUBITEMS: `${WeJudgeRoutes.MANAGEMENT_ROOT}/monitor/:subItemName?`,
  },
  SUBITEMS: `${WeJudgeRoutes.MANAGEMENT_ROOT}/:subItemName`, // 映射上边子项目的地址
};
export const ExampleRoutes = {
  RANK_LIST: `${WeJudgeRoutes.EXAMPLE_ROOT}/rank_list`,
  BLANK: `${WeJudgeRoutes.EXAMPLE_ROOT}/blank`,
  VIDEO: `${WeJudgeRoutes.EXAMPLE_ROOT}/video`,
  COMMENT: `${WeJudgeRoutes.EXAMPLE_ROOT}/comment`,
  ANSWER: `${WeJudgeRoutes.EXAMPLE_ROOT}/answer`,
};

export const WeChatRoutes = {
  QRCONNECT: `${WeJudgeRoutes.WECHAT_ROOT}/qrconnect`,
};

//比赛服路由
export const ContestRootRoutes = {
  HOME: `${WeJudgeRoutes.CONTEST_ROOT}/home`,
  ACCOUNT_ROOT: `${WeJudgeRoutes.CONTEST_ROOT}/accounts`,
  ORGANIZATION_ROOT: `${WeJudgeRoutes.CONTEST_ROOT}/organization`,
  SUPPORT_ROOT: `${WeJudgeRoutes.CONTEST_ROOT}/supports`,
  JUDGE_STATUS_ROOT: `${WeJudgeRoutes.CONTEST_ROOT}/judge_status`,
  RANK_ROOT: `${WeJudgeRoutes.CONTEST_ROOT}/rank`,
  MANAGE_ROOT: `${WeJudgeRoutes.CONTEST_ROOT}/manage`,
  PROBLEM_ROOT: `${WeJudgeRoutes.CONTEST_ROOT}/problems`,
  SUBITEMS: `${WeJudgeRoutes.CONTEST_ROOT}/:subItemName`,
  SUBITEMS2: `${WeJudgeRoutes.CONTEST_ROOT}/:subItemName/:subItemName2`,
  FANCY_RANK: `${WeJudgeRoutes.CONTEST_ROOT}/fancy_rank`,
};

export const ContestRoutes = {
  ACCOUNT: {
    LOGIN: `${ContestRootRoutes.ACCOUNT_ROOT}/login`,
    ENROLL: `${ContestRootRoutes.ACCOUNT_ROOT}/enroll`,
  },
  ORGANIZATION: {
    LIST: `${ContestRootRoutes.ORGANIZATION_ROOT}/list`,
  },
  SUPPORT: {
    FAQ: `${ContestRootRoutes.SUPPORT_ROOT}/faq`,
    FAQ_VIEW: `${ContestRootRoutes.SUPPORT_ROOT}/faq/:faqId`,
    CROSS_CHECK: `${ContestRootRoutes.SUPPORT_ROOT}/cross_check`,
    PRINT: `${ContestRootRoutes.SUPPORT_ROOT}/print`,
    WJ_INFO: `${ContestRootRoutes.SUPPORT_ROOT}/wj_info`,
  },
  JUDGE_STATUS: {
    LIST: `${ContestRootRoutes.JUDGE_STATUS_ROOT}/list`,
    SOLUTIONS: `${ContestRootRoutes.JUDGE_STATUS_ROOT}/solutions`,
  },
  RANK: {
    LIST: `${ContestRootRoutes.RANK_ROOT}/list`,
  },
  PROBLEM: {
    LIST: `${ContestRootRoutes.PROBLEM_ROOT}/list`,
    CONTENT: `${ContestRootRoutes.PROBLEM_ROOT}/:problemId/content`,
    SUBMIT: `${ContestRootRoutes.PROBLEM_ROOT}/:problemId/submit`,
    SUBITEMS: `${ContestRootRoutes.PROBLEM_ROOT}/:problemId/:subItemName`,
  },
  MANAGE: {
    CONTEST: `${ContestRootRoutes.MANAGE_ROOT}/contest`,
    ACCOUNT: `${ContestRootRoutes.MANAGE_ROOT}/account`,
    ORGANIZATION: {
      LIST: `${ContestRootRoutes.MANAGE_ROOT}/organization/list`,
      GROUPS: `${ContestRootRoutes.MANAGE_ROOT}/organization/groups`,
      SUBITEMS: `${ContestRootRoutes.MANAGE_ROOT}/organization/:subItemKey`,
    },
    NOTICE: `${ContestRootRoutes.MANAGE_ROOT}/notice`,
    DEL_CONTEST: `${ContestRootRoutes.MANAGE_ROOT}/del_contest`,
  },
};
