import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { get, set } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ProblemPropTypes } from 'scripts/common/prop-types/problem';
import { AsgnProblemPropTypes, AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import CodeEditor from 'scripts/common/widgets/code_mirror';
import { ProblemEntitySelectors } from 'scripts/common/logic/problem/selectors';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AsgnProblemRoutes } from 'scripts/apps/routes';

const mapStateToProps = (state, props) => {
  const problem = ProblemEntitySelectors.getProblemEntity(state)(props.problemId);
  const answerCases = problem.options.judge_options.answer_cases;
  const demoCases = problem.options.judge_options.demo_cases;
  const matchLang = get(props.match.params, 'lang', '1');
  return {
    matchLang,
    answerCases,
    problem,
    demoCases,
  };
};

@withRouter
@connect(mapStateToProps, null)
class CorrectAnswerView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    matchLang: PropTypes.string,
    asgn: AsgnPropTypes,
    asgnProblem: AsgnProblemPropTypes.isRequired,
    answerCases: PropTypes.shape(),
    demoCases: PropTypes.shape(),
    problem: ProblemPropTypes,
    hasFetchAnswerCases: PropTypes.bool,
  };

  static defaultProps = {
    asgn: null,
    matchLang: '1',
    answerCases: {},
    demoCases: {},
    problem: {},
    hasFetchAnswerCases: false,
  };

  state = {
    language: 1,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.hasFetchAnswerCases) {
      return {
        ...prevState,
        hasFetchAnswerCases: true,
      };
    }
    return null;
  }

  getLanguageMask = () => {
    if (this.props.asgn && this.props.asgnProblem) {
      const plang = get(this.props.asgnProblem, 'code_language', -1);
      if (plang < 0) {
        this.setState({
          hasFetchAnswerCases: false,
        });
        return get(this.props.asgn, 'code_language', 0);
      }
      this.setState({
        hasFetchAnswerCases: false,

      });
      return plang;
    }
    this.setState({
      hasFetchAnswerCases: false,
    });
    return 0;
  };

  handleBack = () => {
    this.props.history.push(buildPath(
      AsgnProblemRoutes.CORRECT_ANSWER,
      null,
      this.props.match.params
    ));
  };

  renderCodeFillAnswer = () => {
    const demoCases = JSON.parse(JSON.stringify(this.props.demoCases)); //答案
    const answerCases = JSON.parse(JSON.stringify(this.props.answerCases)); //代码模版
    /* eslint-disable */
    for (let i in demoCases) {
      if (get(answerCases, `${i}`, null)) {
        let answerCasesTemp = '';
        get(answerCases, `${i}`, null).split('\n').forEach((item, index) => {
          let lineAnswer = null;
          get(demoCases, `${i}`, []).forEach((it) => {
            if (it.line === index + 1) {
              lineAnswer = it.answer;
            }
          });
          if (lineAnswer) {
            answerCasesTemp += lineAnswer;
          } else {
            answerCasesTemp += item;
          }
          answerCasesTemp += '\n';
        });
        set(answerCases, `${i}`, answerCasesTemp);
      }
    }
    return answerCases;
  };

  render() {
    return (<div className="correct_answer_view">
      <div className="nav_bar">
        <Button type="default" onClick={this.handleBack}>关闭</Button>
      </div>
      <div className="code_content">
        {this.props.answerCases && <CodeEditor
          key={this.props.matchLang}
          mode="normal"
          readOnly
          topControl={false}
          footerControl={false}
          languageMask={this.getLanguageMask()}
          defaultLanguage={parseInt(this.props.matchLang, 10)}
          defaultValue={this.props.problem.problem_type === 2 ?
            this.renderCodeFillAnswer() : this.props.answerCases}
        />}
      </div>
    </div>);
  }
}

export default CorrectAnswerView;
