import React from 'react';
import { Spin, Statistic } from 'antd';
import DataSet from '@antv/data-set';
import { noop, get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AsgnStatisticsActions } from 'scripts/common/logic/education/asgn/actions';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { GradesPieChart, GradesDistributionAreaChart, GradesDistributionPointChart } from 'scripts/apps/education/asgn/statistics/widgets/grades';
import { JudgeStatusPieChart, JudgeStatusTimeLineChart } from 'scripts/apps/education/asgn/statistics/widgets/status';

const { DataView } = DataSet;

const mapDispatchToProps = dispatch => bindActionCreators({
  getAsgnStatisticsOverview: AsgnStatisticsActions.getAsgnStatisticsOverview,
}, dispatch);

@connect(null, mapDispatchToProps)
class StatisticsAsgnOverView extends React.PureComponent {
  static propTypes = {
    courseId: PropTypes.number.isRequired,
    asgnId: PropTypes.number.isRequired,
    getAsgnStatisticsOverview: PropTypes.func,
  };

  static defaultProps = {
    getAsgnStatisticsOverview: noop,
  };

  state = {
    isLoading: true,
    datas: {},
    gradesLinesContainerWidth: 0,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({
      isLoading: true,
    }, () => {
      this.props.getAsgnStatisticsOverview({
        asgnId: this.props.asgnId,
        courseId: this.props.courseId,
        onSuccess: (result) => {
          this.setState({
            isLoading: false,
            datas: result,
          });
        },
      });
    });
  };

  handleGradesLinesResize = (width) => {
    this.setState({
      gradesLinesContainerWidth: width,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <div style={{ marginTop: 16, textAlign: 'center' }}>
        <Spin tip="正在载入作业分析数据" />
      </div>;
    }
    const grades = get(this.state.datas, 'grades', {});
    const reports = get(this.state.datas, 'reports', []);
    const judgeStatus = get(this.state.datas, 'judge_status.distribution', []);
    const jsTimeline = get(this.state.datas, 'judge_status.timeline', []);
    const jsTimeDuration = get(this.state.datas, 'judge_status.time', {});
    return (
      <div className="stats_asgn_overview">
        <div className="asgn_ov_container">
          <div className="left_chart">
            {!isEmpty(grades) && <GradesPieChart {...grades} />}
          </div>
          <div className="asgn_base_info">
            <div className="info_group">
              <ReactResizeDetector handleWidth onResize={this.handleGradesLinesResize} />
              <Statistic title="参与学生" value={get(this.state.datas, 'counter.reports', 0)} />
              <Statistic title="总提交数" value={get(this.state.datas, 'counter.submission', 0)} />
              <Statistic title="总通过数" value={get(this.state.datas, 'counter.accepted', 0)} />
            </div>
            <div className="grades_lines">
              {/*{!isEmpty(grades) && <GradesDistributionAreaChart*/}
              {/*  {...grades}*/}
              {/*  width={this.state.gradesLinesContainerWidth}*/}
              {/*/>}*/}
              {!isEmpty(grades) && <GradesDistributionPointChart
                reports={reports}
                width={this.state.gradesLinesContainerWidth}
              />}
            </div>
          </div>
        </div>
        <div className="asgn_ov_container">
          <div className="left_chart">
            {!isEmpty(judgeStatus) && <JudgeStatusPieChart data={judgeStatus} />}
          </div>
          <div className="right_chart">
            {!isEmpty(judgeStatus) && <JudgeStatusTimeLineChart
              timeline={jsTimeline}
              timeDuration={jsTimeDuration}
              width={this.state.gradesLinesContainerWidth}
            />}
          </div>
        </div>
      </div>
    );
  }
}

export default StatisticsAsgnOverView;
