import React from 'react';
import {
  set, get, isEqual, indexOf, noop, pick 
} from 'lodash';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Dropdown,
  Menu,
  Button,
  Row,
  Col,
  Switch,
  InputNumber,
  Alert,
  Card,
  Tooltip
} from 'antd';
import JudgeLanguageCheckBox from 'scripts/common/widgets/language_checkbox';
import { AsgnProblemPropTypes, AsgnPropTypes } from 'scripts/common/prop-types/asgn';
import { ProblemTypeEnum } from 'scripts/common/enums/problem';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AsgnProblemActions } from 'scripts/common/logic/education/asgn/actions';

const CODE_PROBLEM_TYPES = [ProblemTypeEnum.CODE.value,  ProblemTypeEnum.CODE_FILL.value];

@connect(null, dispatch => bindActionCreators({
  modifyAsgnProblem: AsgnProblemActions.modifyAsgnProblem,
}, dispatch))
class AsgnProblemSettings extends React.PureComponent {
  static propTypes = {
    asgn: AsgnPropTypes,
    asgnId: PropTypes.number.isRequired,
    courseId: PropTypes.number.isRequired,
    asgnProblem: AsgnProblemPropTypes.isRequired,
    modifyAsgnProblem: PropTypes.func,
    onProblemRejudgeClick: PropTypes.func,
    onProblemDeleteClick: PropTypes.func,
  };

  static defaultProps = {
    asgn: null,
    modifyAsgnProblem: noop,
    onProblemRejudgeClick: noop,
    onProblemDeleteClick: noop,
  };

  state = {
    raw: null,
    isSaving: false,
    asgnProblem: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!isEqual(nextProps.asgnProblem, prevState.raw)) {
      return {
        raw: nextProps.asgnProblem,
        asgnProblem: JSON.parse(JSON.stringify(nextProps.asgnProblem)),
      };
    }
    return null;
  }

  isCodeProblem = () => {
    const pt = get(this.props.asgnProblem, 'problem.problem_type', -1);
    return (indexOf(CODE_PROBLEM_TYPES, pt) > -1);
  };

  handleSaveSettings = () => {
    this.setState({
      isSaving: true,
    }, () => {
      const { asgnProblem } = this.state;
      const fields =  [
        'id', 'score', 'problem', 'max_score_for_wrong', 'require',
        'strict_mode', 'hidden_answer', 'code_language', 'simple_cross_check'
      ];
      this.props.modifyAsgnProblem({
        courseId: this.props.courseId,
        asgnId: this.props.asgnId,
        asgnProblem: pick(asgnProblem, fields),
        onComplete: () => {
          this.setState({
            isSaving: false,
          });
        },
      });
    });
  };

  handleInputChange = key => (value) => {
    this.setState(set({
      asgnProblem: { ...this.state.asgnProblem },
    }, `asgnProblem.${key}`, value));
  };

  handleMenuClick = (e) => {
    const { asgnProblem } = this.state;
    switch (e.key) {
      case 'rejudge':
        this.props.onProblemRejudgeClick(asgnProblem)();
        break;
      default:
        break;
    }
  };

  renderExtraMenu = () => (
    <Menu onClick={this.handleMenuClick}>
      <Menu.Item key="rejudge">发起重判</Menu.Item>
      <Menu.Item key="cross_check">发起查重</Menu.Item>
    </Menu>
  );

  render() {
    const { asgnProblem } = this.state;
    const isCodeProblem = this.isCodeProblem();
    return (
      <div className="asgn_problem_settings_view">
        <Row className="settings_content" gutter={16}>
          <Col span={8}>
            <Card title="分数设定" type="inner">
              <div className="settings_group">
                <strong>分值：</strong>
                <InputNumber
                  step={5}
                  min={0}
                  max={get(this.props.asgn, 'full_score', 100)}
                  value={get(asgnProblem, 'score', 0)}
                  onChange={this.handleInputChange('score')}
                />
              </div>
              {isCodeProblem && <div className="settings_group">
                <div className="settings_group">
                  <strong>错答补偿：</strong>
                  <InputNumber
                    step={5}
                    min={0}
                    max={100}
                    disabled={get(asgnProblem, 'strict_mode', true)}
                    value={get(asgnProblem, 'max_score_for_wrong', 100)}
                    onChange={this.handleInputChange('max_score_for_wrong')}
                  /> %
                </div>
                <Alert
                  message={`(答对行数 / 总行数) * ${get(asgnProblem, 'max_score_for_wrong', 100)} % * 分数 `}
                  type="info"
                  showIcon
                />
                d
                <Alert
                  style={{ margin: '8px 0 0 0 ' }}
                  message="给分样例：小明这题WA，他答对了10行测试数据中的6行，错答补偿是 80%，此题总分10分，那么他最终得分是 (6 / 10) * 0.8 * 10 =  4.8分"
                  type="warning"
                  showIcon
                />
              </div>}
            </Card>
          </Col>
          <Col span={6}>
            <Card title="作业选项" type="inner">
              <div className="option_grid">
                <p>
                  <Switch
                    checked={get(asgnProblem, 'require', true)}
                    onChange={this.handleInputChange('require')}
                  /> 必做题
                </p>
                <p>
                  <Tooltip title="严格模式下，PE将不再被视作AC，非AC结果直接0分">
                    <Switch
                      disabled={!isCodeProblem}
                      checked={get(asgnProblem, 'strict_mode', false)}
                      onChange={this.handleInputChange('strict_mode')}
                    /> 严格模式 <QuestionCircleOutlined />
                  </Tooltip>
                </p>
                <p>
                  <Tooltip title="常规查重将对评测通过的代码采用sim工具进行比较，能在一定程度上识别抄袭内容。注意，如果代码量非常少，会产生大量误判，此时不建议开启查重。">
                    <Switch
                      disabled={!isCodeProblem}
                      checked={get(asgnProblem, 'simple_cross_check', false)}
                      onChange={this.handleInputChange('simple_cross_check')}
                    /> 开启查重 <QuestionCircleOutlined />
                  </Tooltip>
                </p>
                <p>
                  <Switch
                    checked={get(asgnProblem, 'hidden_answer', false)}
                    onChange={this.handleInputChange('hidden_answer')}
                  /> 隐藏答案
                </p>
              </div>
            </Card>
          </Col>
          <Col span={10}>
            {isCodeProblem && <Card title="评测语言" type="inner">
              <JudgeLanguageCheckBox
                allowInherit
                language={get(asgnProblem, 'code_language', 0)}
                onChange={({ target }) => { this.handleInputChange('code_language')(target.value); }}
              />
            </Card>}
          </Col>
        </Row>
        <Alert
          style={{ marginBottom: 16 }}
          showIcon
          type="warning"
          message="修改了和分数判断有关的设置，或修改题目设置后评分数据不正确，请使用【数据重算】功能重新计算。"
        />
        <div className="settings_buttons">
          <Button type="primary" loading={this.state.isSaving} onClick={this.handleSaveSettings}>保存设置</Button>
          &nbsp;
          <Dropdown overlay={this.renderExtraMenu()}>
            <Button>
              更多功能 <DownOutlined />
            </Button>
          </Dropdown>
          &nbsp;
          <Button type="danger" onClick={this.props.onProblemDeleteClick(asgnProblem)}>删除题目</Button>
        </div>
      </div>
    );
  }
}

export default AsgnProblemSettings;
