import 'styles/contest/account/auth_view.scss';
import LoginForm from 'scripts/apps/account/auth/widgets/login_form';

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withContestContext } from 'scripts/apps/contest/contest_provider';
import { ContestAccountActions } from 'scripts/common/logic/contest/account/action';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import {
  ContestAccountPropTypes,
  ContestAuthPropTypes,
  ContestPropTypes
} from 'scripts/common/prop-types/contest';
import { buildPath } from 'scripts/common/utils/location_helper';
import { ContestRootRoutes } from 'scripts/apps/routes';

const mapDispatchToProps = dispatch => bindActionCreators({
  doLogin: ContestAccountActions.contestLogin,
}, dispatch);

@withRouter
@withContestContext
@connect(null, mapDispatchToProps)
class LoginView extends React.PureComponent {
  static propTypes = {
    account: ContestAccountPropTypes,
    contest: ContestPropTypes,
    loginStatus: ContestAuthPropTypes,
    doLogin: PropTypes.func.isRequired,
    ...RoutePropTypes,
  };

  static defaultProps = {
    account: {},
    contest: {},
    loginStatus: {},
  };

  state = {

  };

  componentDidMount() {
    if (this.props.loginStatus.logined) {
      const { history } = this.props;
      history.push(buildPath(
        ContestRootRoutes.HOME,
        { contestId: this.props.contest.id }
      ));
    }
  }

  render() {
    return (
      <div className="contest_auth_view">
        <div className="auth_body">
          <Alert
            showIcon
            type="info"
            message="登录须知：除非主办方要求使用其提供的专用比赛账号外，应当使用WeJudge账号的邮箱和密码登录。"
          />
          <div className="account-auth account-login">
            <div className="title">登录</div>
            <LoginForm
              module="contest"
              doLogin={this.props.doLogin}
              payloads={{ contestId: this.props.contest.id }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginView;
