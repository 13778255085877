import 'styles/repository/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  PageHeader, Button, Row, Statistic, Spin, Progress 
} from 'antd';
import BaseLayout from 'scripts/apps/wejudge/layout/base';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AccountRoutes, RepositoryRoutes } from 'scripts/apps/routes';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { buildPath } from 'scripts/common/utils/location_helper';
import { bindActionCreators } from 'redux';
import { RepositoryAction, RepositorySelectors } from 'scripts/common/logic/repository';
import { WeJudge404NotFoundComponent } from 'scripts/apps/wejudge/not_found_page';
import { RepositoryPropType } from 'scripts/common/prop-types/repository';
import { humanbytes } from 'scripts/common/utils/unit';

import MainView from 'scripts/apps/repository/main_view';

const mapStateToProps = (state, props) => {
  const { match } = props;
  const repoId = get(match, 'params.repoId', '0');
  const repository = RepositorySelectors.getRepositoryEntity(state)(repoId);
  return {
    repoId,
    repository,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getRepositoryInfo: RepositoryAction.getRepositoryInfo,
}, dispatch);

// 这个页面用来管理一些没有课程的
@connect(mapStateToProps, mapDispatchToProps)
class RepositoryIndex extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    getRepositoryInfo: PropTypes.func.isRequired,
    repoId: PropTypes.string.isRequired,
    repository: RepositoryPropType,
  };

  static defaultProps = {
    repository: null,
  };

  state = {
    isLoading: true,
    isNotFound: false,
  };

  componentDidMount() {
    this.props.getRepositoryInfo({
      repoId: this.props.repoId,
      onSuccess: () => {
        this.setState({ isLoading: false });
      },
      onError: () => {
        this.setState({ isNotFound: true, isLoading: false });
      },
    });
  }

  handleBack = () => {
    this.props.history.push(buildPath(AccountRoutes.DASHBOARD));
  };

  renderView = () => {
    if (this.state.isLoading) {
      return <div className="repository-view" style={{ align: 'center' }}>
        <Spin tip="加载中..." />
      </div>;
    }
    if (this.state.isNotFound) {
      return <WeJudge404NotFoundComponent />;
    }
    const { repository } = this.props;
    const maxSize = get(repository, 'max_size', 0);
    const curSize = get(repository, 'cur_size', 0);
    return <div className="repository-view">
      <PageHeader
        className="repository-view-header"
        title="我的教学资源仓库"
        onBack={this.handleBack}
      >
        <Row type="flex" className="header-statistics">
          <div className="main" style={{ flex: 1 }}>
            <Row className="content" type="flex">
              <Statistic title="仓库容量" value={humanbytes(get(repository, 'max_size', 0))} />
              <Statistic title="当前使用" value={humanbytes(get(repository, 'cur_size', 0))} />
            </Row>
          </div>
          <div className="extra">
            <Progress
              type="circle"
              percent={maxSize > 0 ? Number(((curSize / maxSize) * 100).toFixed(2)) : 0}
              width={64}
            />
          </div>
        </Row>
      </PageHeader>
      <Switch>
        <Route
          strict
          path={RepositoryRoutes.TREE_VIEW}
          render={props => <MainView {...props} repoId={this.props.repoId} />}
        />
        <Route
          path={RepositoryRoutes.SETTINGS_VIEW}
          render={() => <div>设置</div>}
          exact
        />
        <Route
          render={() => <Redirect
            to={buildPath(
              RepositoryRoutes.TREE_VIEW,
              null,
              this.props.match.params
            )}
          />}
        />
      </Switch>
    </div>;
  };

  render() {
    return <BaseLayout>
      {this.renderView()}
    </BaseLayout>;
  }
}

export default RepositoryIndex;
