import React from 'react';
// import { rankdata } from './data';
import TimeLineRankList  from 'scripts/common/widgets/ranklist/timeline_ranklist';

const rankdata = {
  data: {},
};

class RankList extends React.PureComponent {
  render() {
    return <div
      style={{
        width: 1280,
        height: '100%',
        margin: '0 auto',
        boxShadow: '1px 1px 1px rgba(0,0,0, 0.5)',
        background: '#FFF',
      }}
    >
      <TimeLineRankList
        listMode="simple"
        rankListDatas={rankdata.data}
      />
    </div>;
  }
}

export default RankList;
