import 'styles/account/auth/auth.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LockOutlined } from '@ant-design/icons';
import {
  Input, Button, Alert, Modal 
} from 'antd';
import { AccountActions } from 'scripts/common/logic/account';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import { getQueryString } from 'scripts/common/utils/get_query_string';
import { buildPath } from 'scripts/common/utils/location_helper';
import { AccountRoutes } from 'scripts/apps/routes';
import { APP_CONST } from 'scripts/common/constants/apps';

const mapDispatchToProps = dispatch => bindActionCreators({
  renewPassword: AccountActions.renewPassword,
}, dispatch);

@connect(null, mapDispatchToProps)
class ForgetPasswordView extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    renewPassword: PropTypes.func.isRequired,
  };

  state = {
    newpassword: '',
    renewpassword: '',
    submitting: false,
    checksame: false,
    isPassword: false,
  };

  handleChange = key => ({ target }) => {
    const { newpassword, renewpassword } = this.state;
    if (key === 'newpassword' || key === 'renewpassword') {
      this.setState({
        [key]: target.value,
      }, () => {
        this.setState({
          checksame: newpassword === renewpassword,
          isPassword: newpassword.length >= APP_CONST.PASSWD_MINLENGTH
            && newpassword.length <= APP_CONST.PASSWD_MAXLENGTH,
        });
      });
    }
  };

  handleRenewPasswordClick = () => {
    const { location, history } = this.props;
    const email = getQueryString('email', location);
    const token = getQueryString('token', location);
    this.setState({
      submitting: true,
    }, () => {
      this.props.renewPassword({
        newpassword: this.state.newpassword,
        renewpassword: this.state.renewpassword,
        email,
        token,
        onSuccess: () => {
          Modal.info({
            title: '修改密码',
            content: '修改密码成功',
            okType: 'primary',
            okText: '确认',
            onOk: () => {
              history.push(buildPath(AccountRoutes.AUTH.LOGIN));
            },
          });
        },
        onError: () => {
          this.setState({
            submitting: false,
          });
        },
      });
    });
  };

  render() {
    return (
      <div className="account-auth account-resetpwd">
        <div className="title">
          重置密码
        </div>
        <Input.Password
          className="input"
          prefix={<LockOutlined />}
          placeholder="输入你的新密码"
          size="large"
          value={this.state.newpassword}
          onChange={this.handleChange('newpassword')}
        />
        {((this.state.renewpassword && this.state.newpassword) && !this.state.isPassword)  ? <Alert message="密码长度请设为8-16位" type="error" /> : null }

        <Input.Password
          className="input"
          prefix={<LockOutlined />}
          placeholder="确认你的新密码"
          size="large"
          value={this.state.renewpassword}
          onChange={this.handleChange('renewpassword')}
        />
        {((this.state.renewpassword && this.state.newpassword) && !this.state.checksame) ? <Alert message="两次密码输入不一致" type="error" /> : null }
        <Button
          size="large"
          type="primary"
          onClick={this.handleRenewPasswordClick}
          className="action-btn"
          disabled={!this.state.checksame}
          loading={this.state.submitting}
        >
          确认
        </Button>
      </div>
    );
  }
}
export default ForgetPasswordView;
