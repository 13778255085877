import React from 'react';
import { DownOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { TreeSelect } from 'antd';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { CategoryPropTypes } from 'scripts/common/prop-types/category';

class CategorySelectTree extends React.PureComponent {
  static propTypes = {
    categoryTree: PropTypes.arrayOf(CategoryPropTypes).isRequired,
    onParentChange: PropTypes.func,
    defaultKey: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onParentChange: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedKey: props.defaultKey,
    };
  }

  handleSelect = (value) => {
    this.setState({
      selectedKey: value,
    });
    this.props.onParentChange({
      parent: value,
    });
  };

  renderCategoryTreeNodes = (categoryTree) => {
    return (
      categoryTree.map((item) => {
        // 只需要显示第一级内容
        return {
          key: item.id,
          value: item.id,
          title: item.title,
          switcherIcon: <FolderOpenOutlined />,
        };
      }).filter(item => !!item)
    );
  };

  render() {
    return (
      <div className="category_tree">
        <TreeSelect
          showLine
          style={{ width: 280 }}
          value={this.state.selectedKey}
          blockNode
          switcherIcon={<DownOutlined />}
          onChange={this.handleSelect}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          allowClear
          treeData={this.renderCategoryTreeNodes(this.props.categoryTree)}
        />
      </div>
    );
  }
}

export default CategorySelectTree;
