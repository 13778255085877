import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty, noop } from 'lodash';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  ExperimentFilled,
  EyeFilled,
  LoadingOutlined,
  NotificationFilled,
  ProjectFilled,
  QuestionCircleFilled,
  ReadFilled,
  SettingFilled,
  TrophyFilled,
  UserOutlined
} from '@ant-design/icons';

import {
  Badge, Layout, Menu, Avatar, Modal 
} from 'antd';
import {
  getQuery, buildPath, getCurrentURL, buildAvatarPath 
} from 'scripts/common/utils/location_helper';
import { RoutePropTypes } from 'scripts/common/prop-types/route';
import {
  AccountRoutes,
  CollectionRoutes,
  WeJudgeRoutes,
  CourseRoutes,
  HelperRoutes,
  ManagementRoutes,
  RepositoryRoutes,
  AnnounceRoutes
} from 'scripts/apps/routes';

import { RepositoryAction } from 'scripts/common/logic/repository/action';
import { AccountActions, AccountSelectors } from 'scripts/common/logic/account';
import { AccountPropTypes } from 'scripts/common/prop-types/account';
import WeJudgeLogoHorizontal from 'svgs/logo_horizontal.svg';
import 'styles/common/layout/topbar.scss';
import { RepositorySelectors } from 'scripts/common/logic/repository/selector';
// import AccountNotice from '../../account/notice/notice';

const { Header } = Layout;
const { SubMenu, Divider } = Menu;
const API_MAPPING_NAME = {
  local: '本地',
  online: 'DevOps',
  production: '生产',
};

const mapStateToProps = (state) => {
  return {
    account: AccountSelectors.getLoginAccount(state),
    loginStatus: AccountSelectors.getLoginStatus(state),
    myRepository: RepositorySelectors.getMyRepositoryEntity(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  doLogout: AccountActions.logout,
  doLogoutShadow: AccountActions.logoutShadow,
  getMyRepositoryInfo: RepositoryAction.getMyRepositoryInfo,
  createRepository: RepositoryAction.createRepository,
}, dispatch);

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class TopBar extends React.PureComponent {
  static propTypes = {
    ...RoutePropTypes,
    account: AccountPropTypes,
    doLogout: PropTypes.func,
    doLogoutShadow: PropTypes.func,
    loginStatus: PropTypes.shape({}),
    getMyRepositoryInfo: PropTypes.func,
    myRepository: PropTypes.shape({}),
  };

  static defaultProps = {
    account: null,
    doLogout: noop,
    doLogoutShadow: noop,
    loginStatus: null,
    getMyRepositoryInfo: noop,
    myRepository: null,
  };

  doLogout = () => {
    const isShadow = get(this.props.loginStatus, 'shadow', false);
    if (isShadow) {
      this.props.doLogoutShadow({
        onSuccess: () => {
          window.location.reload();
        },
      });
    } else {
      this.props.doLogout({
        onSuccess: () => {
          this.handleNavItemClick(AccountRoutes.AUTH.LOGIN, 'login')();
        },
      });
    }
  };

  publicItems = [
    {
      root: AccountRoutes.AUTH.REGISTER,
      title: '注册',
      route: AccountRoutes.AUTH.REGISTER,
      key: 'register',
    },
    {
      title: '登录',
      route: AccountRoutes.AUTH.LOGIN,
      root: AccountRoutes.AUTH.LOGIN,
      key: 'login',
    }
  ];

  topbarMenuItems = [
    {
      title: <><ProjectFilled />题库</>,
      route: buildPath(CollectionRoutes.LIST),
      root: WeJudgeRoutes.COLLECTION_ROOT,
      key: 'problem',
    },
    {
      title: <><ReadFilled />课程</>,
      route: buildPath(CourseRoutes.LIST),
      root: [WeJudgeRoutes.EDUCATION_ROOT, WeJudgeRoutes.REPOSITORY_ROOT],
      key: 'course',
    },
    {
      title: <><TrophyFilled />比赛</>,
      route: buildPath(WeJudgeRoutes.CONTEST_LIST),
      root: WeJudgeRoutes.CONTEST_LIST,
      key: 'contest',
    },
    {
      title: <><QuestionCircleFilled />帮助</>,
      route: buildPath(HelperRoutes.DESC),
      root: WeJudgeRoutes.HELPER_ROOT,
      key: 'helper',
    }, {
      title: <><NotificationFilled />公告</>,
      route: buildPath(AnnounceRoutes.LIST),
      root: WeJudgeRoutes.ANNOUNCE_ROOT,
      key: 'anouncement',
    }, {
      title: <><SettingFilled />管理</>,
      route: buildPath(ManagementRoutes.INDEX),
      root: WeJudgeRoutes.MANAGEMENT_ROOT,
      key: 'management',
    }, (process.env.NODE_ENV === 'development') ? {
      title: <>
        <ExperimentFilled />
        实验室({get(API_MAPPING_NAME, get(process.env, 'API_MAPPING'), 'API')}
        {get(process.env, 'API_MAPPING') === 'online' ?  `：${get(process.env, 'API_BRANCH', 'unknown')}` : ''})
      </>,
      route: WeJudgeRoutes.EXAMPLE_ROOT,
      root: WeJudgeRoutes.EXAMPLE_ROOT,
      key: 'example',
    } : null
  ];

  handleNavItemClick = (path, key) => (e) => {
    if (e) {
      e.preventDefault && e.preventDefault();
      e.stopPropagation && e.stopPropagation();
      e.returnValue = false;
    }
    const { history, location } = this.props;
    if (key === 'register' || key === 'login') {
      const currentUrl = getCurrentURL();
      const query = getQuery(location);
      if (!query.from) {
        history.push(buildPath(path, null, null, { from: currentUrl }));
      } else {
        history.push(buildPath(path, null, null, query));
      }
    } else if (key === 'my_repo') {
      this.props.getMyRepositoryInfo({
        onSuccess: (result) => {
          if (!result) {
            this.handleCreateMyRepo();
          } else {
            history.push(buildPath(RepositoryRoutes.INDEX, { repoId: result }));
          }
        },
      });
    } else {
      history.push(path);
    }
    return false;
  };

  handleCreateMyRepo = () => {
    const { history } = this.props;
    Modal.confirm({
      title: '操作确认',
      content: '确认要激活教学资源仓库吗？',
      onOk: () => {
        this.props.createRepository({
          onSuccess: (result) => {
            history.push(buildPath(RepositoryRoutes.INDEX, { repoId: result }));
          },
        });
      },
    });
  };

  // renderNoticeBar(isLogined) {
  //   if (!isLogined) return null;
  //   return <Menu.Item
  //     className="topbar-nav-item-notice"
  //     key="notice"
  //   >
  //     <AccountNotice
  //       trigger="click"
  //     />
  //   </Menu.Item>;
  // }

  renderAccountBar(isLogined) {
    if (!isLogined) return null;
    const { loginStatus, account, myRepository } = this.props;
    if (!get(loginStatus, 'fetched', false)) return null;
    if (get(loginStatus, 'logined', false) && isEmpty(account)) return null;
    const privateItems = [
      {
        title: '个人中心',
        route: buildPath(AccountRoutes.SPACE.INDEX, {
          accountId: 'my',
        }),
        key: 'space',
      }, (parseInt(get(account, 'role', 0), 10) > 1) ? {
        title: '个人仓库',
        route: buildPath(RepositoryRoutes.INDEX, { repoId: get(myRepository, 'id', 0) }),
        key: 'my_repo',
      } : null
    ].filter(item => !!item);

    const isShadow = get(loginStatus, 'shadow', false);
    const avatarImg = <Avatar
      shape="square"
      icon={<UserOutlined />}
      size={32}
      src={buildAvatarPath(get(account, 'avator'))}
      style={{ marginRight: 8 }}
    />;

    return (
      <SubMenu
        className="topbar-nav-item-account"
        title={<div className="topbar-user-area">
          {isShadow ? <Badge count={<EyeFilled style={{ color: '#ffd666' }} />} offset={[-8, 0]}>
            {avatarImg}
          </Badge> : avatarImg} {account.nickname}
        </div>}
      >
        {privateItems.map(item => <Menu.Item
          key={item.key}
          onClick={this.handleNavItemClick(item.route, item.key)}
        >
          {item.title}
        </Menu.Item>)}
        <Divider />
        <Menu.Item key="logout" onClick={this.doLogout}>{isShadow ? '退出影子账户' : '退出登录'}</Menu.Item>
      </SubMenu>
    );
  }

  render() {
    const currentUrl = this.props.location.pathname;
    const { loginStatus } = this.props;
    const isLogined = get(loginStatus, 'logined', false);
    const isFetched = get(loginStatus, 'fetched', false);
    const LOADING = {
      key: 'loading',
    };
    const renderItems = [
      ...this.topbarMenuItems,
      ...(isFetched ? (isLogined ? [] : this.publicItems) : [LOADING])
    ];
    const matchedTopbarItem = renderItems.filter(item => !!item)
      .filter((item) => {
        let ans = false;
        if (item.root instanceof Array) {
          ans = ans || !!matchPath(currentUrl, {
            path: item.root,
            exact: false,
            strict: false,
          });
        } else {
          ans = !!matchPath(currentUrl, {
            path: item.root,
            exact: false,
            strict: false,
          });
        }
        return ans;
      });
    return (
      <Header>
        <div className="topbar-header">
          <Link className="topbar-logo" to={WeJudgeRoutes.WEJUDGE_ROOT}>
            <svg width={160} height={32}><use xlinkHref={`#${WeJudgeLogoHorizontal.id}`} /></svg>
          </Link>
          <div className="topbar-menu">
            <Menu
              key="left-menu"
              theme="dark"
              mode="horizontal"
              selectedKeys={matchedTopbarItem.map(item => item.key)}
            >
              {renderItems.filter(item => !!item).map((item) => {
                if ((get(this.props, 'account.role') !== 99 && get(this.props, 'account.role') !== 3) && item.key === 'management') {
                  return null;
                }
                if (item.key === 'loading') {
                  return (
                    <Menu.Item className={`topbar-nav-item topbar-nav-item-${item.key}`} key={item.key}>
                      <LoadingOutlined style={{ color: 'white' }} />
                    </Menu.Item>
                  );
                }
                return (<Menu.Item className={`topbar-nav-item topbar-nav-item-${item.key}`} key={item.key}>
                  {(item.href) ? <a href={item.href}>{item.title}</a> : <a
                    href={buildPath(item.route)}
                    onClick={this.handleNavItemClick(item.route, item.key)}
                  >{item.title}</a>}
                </Menu.Item>);
              })}
              {/*{this.renderNoticeBar(isLogined)}*/}
              {this.renderAccountBar(isLogined)}
            </Menu>
          </div>
        </div>
      </Header>
    );
  }
}
export default TopBar;
